import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
    IApiResponse,
    IPaginatedApiResponse,
} from "../../../utils/types/api-response";
import {
    IGetMessages,
    INote,
    INoteAuditDashboardResponse,
    INotesListFilterTypes,
    IRequestDetails,
    ISuperviseeNote,
    ISuperviseeNotesListFilters,
} from "../types/notes.types";
import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";
import {
    fetchMessages,
    fetchNoteAuditDashboardList,
    fetchNotesChangesRequest,
    fetchNotesList,
    fetchSuperviseeNotesList,
} from "../services/notes.services";

export function useFetchNotesList(
    filters: INotesListFilterTypes,
    providerId: string
) {
    return useQuery<
        IPaginatedApiResponse<INote[]>,
        AxiosError<IApiResponse<INote[]>>
    >({
        queryKey: ["notes", handleRemoveEmptyParamFromQueryParams(filters)],
        queryFn: () => fetchNotesList(filters, providerId),
    });
}

// Fetch supervisee note list hook
export function useFetchSuperviseeNotesList(
    providerId: string,
    filters: ISuperviseeNotesListFilters
) {
    return useQuery<
        IPaginatedApiResponse<ISuperviseeNote[]>,
        AxiosError<IApiResponse<ISuperviseeNote[]>>
    >({
        queryKey: [
            "supervisee",
            providerId,
            handleRemoveEmptyParamFromQueryParams(filters),
        ],
        queryFn: () => fetchSuperviseeNotesList(providerId, filters),
    });
}

// Function to fetch note messages
export function useFetchMessages(noteId: string, search_keyword?: string) {
    return useQuery<
        IApiResponse<IGetMessages[]>,
        AxiosError<IApiResponse<IGetMessages[]>>
    >({
        queryKey: [noteId, `get-messages`, search_keyword],
        queryFn: () => fetchMessages(noteId, search_keyword),
    });
}

// Hook for getting notes audit dashboard list
export function useFetchNoteAuditDashboardList(
    custom_date: string,
    sort_attribute?: string,
    sort_order?: string
) {
    return useQuery<
        IApiResponse<INoteAuditDashboardResponse>,
        AxiosError<IApiResponse<INoteAuditDashboardResponse[]>>
    >({
        queryKey: [
            "notes-audit-dashboard",
            custom_date,
            sort_attribute,
            sort_order,
        ],
        queryFn: () =>
            fetchNoteAuditDashboardList(
                custom_date,
                sort_attribute,
                sort_order
            ),
    });
}

// Function to fetch changes requested for a note
export function useFetchNotesChangesRequest(
    noteId: string,
    limit: number,
    page: number
) {
    return useQuery<
        IPaginatedApiResponse<IRequestDetails[]>,
        AxiosError<IApiResponse<IRequestDetails[]>>
    >({
        queryKey: [noteId, `change-request`, limit, page],
        queryFn: () => fetchNotesChangesRequest(noteId, limit, page),
    });
}
