/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import {
    Button,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Table,
} from "@jhool-io/fe-components";
import { Navigate, useSearchParams } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";
import { ref, remove } from "firebase/database";
import usePractice from "../../../../hooks/usePractice";
import { UserRole } from "../../../user-management/types/user-management.types";
import NewAnnouncementForm from "../NewAnnouncementForm/NewAnnouncementForm";
import { IAnnouncement } from "../../types/announcement";
import { convertArrayToString, formatDate } from "../../../../utils/helpers";
import useAnnouncement from "../../../../hooks/useAnnouncement";
import ListState from "../../../../components/ListState/ListState";
import { useFetchAllPractices } from "../../../practices/hooks/practices.queries";
import MoreButton from "../../../../shared-ui/Buttons/MoreButton/MoreButton";
import useToast from "../../../../hooks/useToast";
import { rtdb } from "../../../../firebase";

type DialogTypes = "add" | "delete";

export default function AnnouncementsList() {
    const [dialogInView, setDialogInView] = React.useState<DialogTypes | null>(
        null
    );
    const [announcementInViewId, setAnnouncementInViewId] = React.useState<
        string | null
    >(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const addingAnnouncement = searchParams.get("adding_announcement");

    const { practice } = usePractice();

    const { announcements } = useAnnouncement();

    const { data, error, isLoading } = useFetchAllPractices(
        {},
        practice?.roles?.includes(UserRole.SUPER_ADMIN)
    );

    const { toast } = useToast();

    if (!practice?.roles.includes(UserRole.SUPER_ADMIN)) {
        Navigate({ to: "/", replace: true });
    }

    const handleCloseDialog = () => {
        setDialogInView(null);

        searchParams.delete("adding_announcement");
        setSearchParams(searchParams);
    };

    const handleDeleteAnnouncement = async () => {
        try {
            const dbRef = ref(rtdb, `/announcements/${announcementInViewId}`);

            await remove(dbRef);
            toast({
                mode: "success",
                message: "Announcement deleted successfully",
            });
            setDialogInView(null);
            setAnnouncementInViewId(null);
        } catch {
            toast({
                mode: "error",
                message:
                    "Could not delete announcement at this time, please try again later",
            });
        }
    };

    const columns: ColumnDef<IAnnouncement>[] = React.useMemo(
        () => [
            {
                accessorKey: "details",
                header: "Details",
                cell: ({ row }) => (
                    <span className="text-wrap">{row.original.details}</span>
                ),
            },
            {
                accessorKey: "roles",
                header: "Roles",
                cell: ({ row }) => (
                    <span className="text-wrap capitalize">
                        {convertArrayToString(row.original.roles, `, `)}
                    </span>
                ),
            },
            {
                accessorKey: "practice_ids",
                header: "Practices",
                cell: ({ row }) => {
                    let practices: string[];

                    if (isLoading || error) {
                        practices = [];
                    } else if (row.original.practice_ids.includes("all")) {
                        practices = ["All Practices"];
                    } else {
                        practices =
                            data?.data
                                .filter((prac) =>
                                    row.original.practice_ids.includes(
                                        prac.practice_id
                                    )
                                )
                                .map((prac) => prac?.practice_name) || [];
                    }

                    return (
                        <span className="text-wrap capitalize">
                            {convertArrayToString(practices, `, `)}
                        </span>
                    );
                },
            },
            {
                accessorKey: "has_redirect",
                header: "Has redirect",
                cell: ({ row }) => (row.original.has_redirect ? "Yes" : "No"),
            },
            {
                accessorKey: "redirect_url",
                header: "Redirect url",
                cell: ({ row }) => row.original.redirect_url || "--",
            },
            {
                accessorKey: "created_at",
                header: "Created at",
                cell: ({ row }) =>
                    row.original.created_at
                        ? formatDate(
                              row.original.created_at,
                              false,
                              "MM/dd/yyyy, hh:mmaaa"
                          )
                        : "--",
            },
            {
                accessorKey: "id",
                header: "",
                cell: ({ row }) => (
                    <Dropdown>
                        <DropdownTrigger>
                            <MoreButton />
                        </DropdownTrigger>
                        <DropdownContent width="auto" align="end">
                            <DropdownItem
                                onClick={() => {
                                    setAnnouncementInViewId(row.original.id);
                                    setDialogInView("delete");
                                }}
                            >
                                Delete
                            </DropdownItem>
                        </DropdownContent>
                    </Dropdown>
                ),
            },
        ],
        [data?.data, error, isLoading]
    );

    return (
        <>
            <Dialog open={dialogInView === "delete"}>
                <DialogContent
                    handleCloseDialog={() => {
                        setDialogInView(null);
                        setAnnouncementInViewId(null);
                    }}
                    variant="center"
                    title="Delete announcement"
                    saveText="Delete announcement"
                    onSaveClick={handleDeleteAnnouncement}
                    isDeleting
                >
                    <div>
                        <h3 className="font-medium text-xl mb-8">
                            Are you sure you want to delete announcement?
                        </h3>
                        <p className="text-gray font-normal text-base">
                            This will delete this announcement from the list and
                            cannot be reversed
                        </p>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog open={dialogInView === "add"}>
                <DialogContent
                    handleCloseDialog={handleCloseDialog}
                    variant="center"
                    title="Add announcement"
                    saveText="Add announcement"
                    submitBtnFormValue="add-announcement"
                    isSubmitBtnDisabled={Boolean(addingAnnouncement)}
                    isCancelBtnDisabled={Boolean(addingAnnouncement)}
                >
                    <NewAnnouncementForm onFormSubmit={handleCloseDialog} />
                </DialogContent>
            </Dialog>
            <div>
                <div className="mb-32 bg-white p-16 rounded-r8 flex justify-end items-center flex-wrap gap-y-16">
                    <Button onClick={() => setDialogInView("add")}>
                        Add new
                    </Button>
                </div>
                {announcements && (
                    <div>
                        {announcements.length === 0 && (
                            <ListState
                                isError={false}
                                stateHelperText="No announcement added yet, add one to get started"
                                emptyMessage="No announcement"
                            />
                        )}
                        {announcements.length > 0 && (
                            <Table
                                columns={columns}
                                data={announcements || []}
                            />
                        )}
                    </div>
                )}
            </div>
        </>
    );
}
