import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
    fetchClientBasicAndPersonalInfo,
    fetchClientInsuranceHistory,
    fetchClientDocuments,
    fetchClientCreditCards,
    fetchClientList,
    fetchClientFees,
    fetchInsuranceProviders,
    fetchCardTransactionHistory,
} from "../../services/client";
import { handleRemoveEmptyParamFromQueryParams } from "../../utils/helpers";
import {
    IApiResponse,
    IPaginatedApiResponse,
} from "../../utils/types/api-response";
import {
    IClientCreditCardDetails,
    IClient,
    IClientDocument,
    IClientInsuranceDetails,
    IClientFee,
    IClientBasicInfo,
    IClientPersonalInfo,
    IClientInsuranceProvider,
    IClientFilterTypes,
    IClientDocumentFilters,
    ICardFilterTypes,
    ICardHistory,
} from "../../utils/types/client";
import { useDoesUserHavePermission } from "../permissions";
import { UserPermisions } from "../../utils/types/user";

// Fetch client basic and personal info hook
export function useFetchClientBasicAndPersonalInfo(
    clientId: string,
    isEnabled?: boolean
) {
    return useQuery<
        IApiResponse<IClientBasicInfo & IClientPersonalInfo>,
        AxiosError<IApiResponse<IClientBasicInfo & IClientPersonalInfo>>
    >({
        queryKey: [clientId, "basic-personal-info"],
        queryFn: () => fetchClientBasicAndPersonalInfo(clientId),
        enabled: isEnabled,
    });
}
// Fetch clients list hook
export function useFetchClientList(
    filters: IClientFilterTypes,
    isEnabled?: boolean
) {
    return useQuery<
        IPaginatedApiResponse<IClient[]>,
        AxiosError<IApiResponse<IClient[]>>
    >({
        queryKey: ["clients", handleRemoveEmptyParamFromQueryParams(filters)],
        queryFn: () =>
            fetchClientList(handleRemoveEmptyParamFromQueryParams(filters)),
        enabled: isEnabled,
    });
}

// Fetch card transaction history
export function useFetchCardTransactionHistory(
    clientId: string,
    paymentId: string,
    filters: ICardFilterTypes
) {
    return useQuery<
        IPaginatedApiResponse<ICardHistory[]>,
        AxiosError<IApiResponse<ICardHistory[]>>
    >({
        queryKey: [
            "card_history",
            handleRemoveEmptyParamFromQueryParams(filters),
            paymentId,
        ],
        queryFn: () =>
            fetchCardTransactionHistory(
                clientId,
                paymentId,
                handleRemoveEmptyParamFromQueryParams(filters)
            ),
    });
}

// Fetch client insurance history hook
export function useFetchClientInsuranceHistory(
    clientId: string,
    page: number,
    limit: number
) {
    return useQuery<
        IPaginatedApiResponse<IClientInsuranceDetails[]>,
        AxiosError<IApiResponse<IClientInsuranceDetails[]>>
    >({
        queryKey: [clientId, `insurance`, page, limit],
        queryFn: () => fetchClientInsuranceHistory(clientId, page, limit),
    });
}

// Fetch client documents list hook
export function useFetchClientDocuments(filters: IClientDocumentFilters) {
    return useQuery<
        IPaginatedApiResponse<IClientDocument[]>,
        AxiosError<IApiResponse<IClientDocument[]>>
    >({
        queryKey: [filters.client_id, `documents`, filters],
        queryFn: () => fetchClientDocuments(filters),
    });
}

// Fetch client credit cards list hook
export function useFetchClientCreditCards(clientId: string) {
    return useQuery<
        IPaginatedApiResponse<IClientCreditCardDetails[]>,
        AxiosError<IApiResponse<IClientCreditCardDetails[]>>
    >({
        queryKey: [clientId, "cards"],
        queryFn: () => fetchClientCreditCards(clientId),
    });
}

// Fetch client fees list hook
export function useFetchClientFees(
    clientId: string,
    limit: number,
    enabled?: boolean
) {
    return useQuery<
        IPaginatedApiResponse<IClientFee[]>,
        AxiosError<IApiResponse<IClientFee[]>>
    >({
        queryKey: [clientId, `fees`, limit],
        queryFn: () => fetchClientFees(clientId, limit),
        enabled,
    });
}

// Fetch insurance providers list
export function useFetchInsuranceProviders() {
    const canViewInsuranceProvidersList = useDoesUserHavePermission([
        UserPermisions.CLIENT_INFO_INSURANCE_READ,
        UserPermisions.CLIENT_INFO_INSURANCE_WRITE,
        UserPermisions.CLIENT_INFO_ALL_ADMIN,
    ]);

    return useQuery<
        IApiResponse<IClientInsuranceProvider[]>,
        AxiosError<IApiResponse<IClientInsuranceProvider>>
    >({
        queryKey: ["insurance-providers"],
        queryFn: fetchInsuranceProviders,
        enabled: canViewInsuranceProvidersList,
    });
}
