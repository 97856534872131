import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Input, Select, TextArea } from "@jhool-io/fe-components";
import useToast from "../../../../../hooks/useToast";
import { STATES } from "../../../../../utils/helpers/us-states/us-states";
import { INewCreditCardDetails } from "../../../types/clients.types";
import { useAddPaymentCard } from "../../../hooks/clients.mutations";

interface AddCardProps {
    // Function to call when form submit button is clicked
    onFormSubmit(): void;
}

const schema = yup.object({
    card_number: yup.string().required("Card number is required"),
    card_cvv: yup.string().required("Card CVV is required"),
    expiration_date: yup.string().required("Expiration date is required"),
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    state: yup.string(),
    country: yup.string(),
    billing_address: yup.string().required("Billing address is required"),
    zip_code: yup.string().required("Zip code is required"),
    card_label: yup.string(),
});

interface PaymentCardDetails {
    card_number: string;
    card_cvv: string;
    expiration_date: string;
    first_name: string;
    last_name: string;
    billing_address: string;
    state: string;
    zip_code: string;
    country: string;
    card_label: string;
}

type Option = {
    label: React.ReactNode;
    value: boolean;
};

export default function AddCardDetails({ onFormSubmit }: AddCardProps) {
    // Get states as select options
    const getClientStateFieldSelectOptions = STATES.map((state) => ({
        label: state.name,
        value: state.abbrv,
    }));

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<PaymentCardDetails>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    // To initialize clientId constant
    const params = useParams();
    const clientId = params.clientId as string;

    const { mutate } = useAddPaymentCard(clientId);

    const { toast } = useToast();

    // Query client
    const queryClient = useQueryClient();

    const onSubmit = (payload: PaymentCardDetails) => {
        const clientCountry = payload.country === "" ? "USA" : payload.country;

        const dataToSend: INewCreditCardDetails = {
            card_number: payload.card_number,
            card_cvv: payload.card_cvv,
            country: clientCountry,
            expiration_month: parseInt(
                payload.expiration_date.split("/")[0],
                10
            ),
            expiration_year: parseInt(
                `20${payload.expiration_date.split("/")[1]}`,
                10
            ),
            first_name: payload.first_name,
            last_name: payload.last_name,
            billing_address: payload.billing_address,
            state: payload.state,
            zip_code: payload.zip_code,
            card_label: payload.card_label,
        };

        mutate(dataToSend, {
            onSuccess: (res) => {
                queryClient.invalidateQueries({
                    queryKey: [clientId, "cards"],
                });
                toast({
                    mode: "success",
                    message: res.message || "Payment card added successfully",
                });
                onFormSubmit();
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not add payment card at this time",
                });
            },
        });
    };

    return (
        <form id="add-card" onSubmit={handleSubmit(onSubmit)}>
            <div className="fg fg-space-between two flex">
                <Input
                    {...register("first_name")}
                    label="First Name"
                    placeholder="First Name"
                    hasError={!!errors.first_name}
                    errorText={errors.first_name?.message}
                />
                <Input
                    {...register("last_name")}
                    label="Last Name"
                    placeholder="Last Name"
                    hasError={!!errors.last_name}
                    errorText={errors.last_name?.message}
                />
            </div>
            <div className="fg">
                <Input
                    {...register("card_number")}
                    label="Card number"
                    placeholder="Card number"
                    hasError={!!errors.card_number}
                    errorText={errors.card_number?.message}
                />
            </div>
            <div className="fg">
                <Input
                    {...register("expiration_date")}
                    maxLength={5}
                    label="Expiration date(mm/yy)"
                    placeholder="Expiration date(mm/yy)"
                    hasError={!!errors.expiration_date}
                    errorText={errors.expiration_date?.message}
                />
            </div>
            <div className="fg">
                <Input
                    {...register("card_cvv")}
                    maxLength={3}
                    label="Security code (CVV)"
                    placeholder="Security code (CVV)"
                    hasError={!!errors.card_cvv}
                    errorText={errors.card_cvv?.message}
                />
            </div>
            <div className="fg">
                <Input
                    {...register("billing_address")}
                    label="Billing address"
                    placeholder="Billing address"
                    hasError={!!errors.billing_address}
                    errorText={errors.billing_address?.message}
                />
            </div>
            <div className="fg">
                <Input
                    {...register("zip_code")}
                    label="Zip code"
                    placeholder="Zip code"
                    hasError={!!errors.zip_code}
                    errorText={errors.zip_code?.message}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Controller
                    name="state"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="State"
                            options={getClientStateFieldSelectOptions}
                            onChange={(val) =>
                                field.onChange((val as Option).value)
                            }
                        />
                    )}
                />
                <Input
                    {...register("country")}
                    label="Country"
                    placeholder="Country"
                    hasError={!!errors.country}
                    errorText={errors.country?.message}
                />
            </div>
            <div className="fg">
                <TextArea
                    {...register("card_label")}
                    label="Label"
                    placeholder="Enter your texts here"
                    hasError={!!errors.card_label}
                    errorText={errors.card_label?.message}
                />
            </div>
        </form>
    );
}
