import * as React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import {
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Input,
    RadioInput,
    Select,
    TextArea,
} from "@jhool-io/fe-components";
import {
    TypeOfDisclosure,
    MethodOfDisclosure,
    INewNoteAppoinmentDetails,
    IGetClientNotes,
} from "../../../../utils/types/notes";
import { useFetchDiagnosisCodes } from "../../../../hooks/queries";
import {
    formatDate,
    getTimeDuration,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../../utils/helpers";
import NoteFormInfoBox from "../../NoteFormInfoBox/NoteFormInfoBox";
import { useFetchClientNotesList } from "../../../../hooks/queries/note";
import LoadPreviousSessionNotificationModal from "../../LoadPreviousSessionNotificatiModal/LoadPreviousSessionNotificationModal";
import { RECORD_OF_DISCLOSURE_NOTE_LABELS } from "../../../../utils/constants";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";

type Option = {
    label: React.ReactNode;
    value: string;
};
export interface ICreateRecordOfDisclosurePayload {
    duration: string;
    diagnosis_codes: string[];
    disclosure_to_whom: string;
    disclosure_type: string;
    purpose_of_disclosure: string;
    type_disclosed: string;
    method_of_disclosure: string;
    signature: string;
}
interface RecordOfDisclosureProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formValues?: { [key: string]: any };
    currentAction?: string | null;
    onSubmit?: (data: ICreateRecordOfDisclosurePayload) => void;
    appointmentDetails?: INewNoteAppoinmentDetails | null;
}

export default function RecordOfDisclosure({
    formValues,
    currentAction,
    onSubmit,
    appointmentDetails,
}: RecordOfDisclosureProps) {
    // Local component state
    const [searchValue, setSearchValue] = React.useState("");

    // Fetch diagonis codes
    const { data, isLoading, error } = useFetchDiagnosisCodes();
    const [showPrompt, setShowPrompt] = React.useState(false);
    const [selectedNote, setSelectedNote] = React.useState(
        {} as IGetClientNotes
    );
    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors },
    } = useForm<ICreateRecordOfDisclosurePayload>({
        resolver: yupResolver(
            yup.object({
                duration: yup.string(),
                diagnosis_codes: yup.array().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup
                        .array()
                        .max(3, "You can only select 3 diagnosis")
                        .min(1, "Diagnosis is required")
                        .required("Diagnosis is required"),
                    otherwise: yup
                        .array()
                        .nullable()
                        .max(3, "You can only select 3 diagnosis"),
                }),
                disclosure_to_whom: yup.string(),
                disclosure_type: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup.string().required("Disclosure type is required"),
                    otherwise: yup.string().nullable(),
                }),
                purpose_of_disclosure: yup.string(),
                type_disclosed: yup.string(),
                method_of_disclosure: yup.string(),
                signature: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup.string().required("Signature is required"),
                    otherwise: yup.string(),
                }),
            })
        ),
        mode: "onChange",
        defaultValues: formValues || undefined,
    });

    const diagnosisCodes = watch("diagnosis_codes");

    // Get diagnosis codes for select
    const DiagnosisCodesForSelect = data?.data.map((diagnosis) => ({
        label: `${diagnosis.code} ${diagnosis.description}`,
        value: diagnosis.code,
    }));

    const clientNoteParams = {
        type: appointmentDetails?.note_type as string,
        load_previous_notes: true,
        provider_id: appointmentDetails?.provider_id as string,
    };

    const { data: notes } = useFetchClientNotesList(
        appointmentDetails?.client_id as string,
        clientNoteParams
    );

    // Get methods of disclosure for select
    const MethodsOfDisclosureForSelect = Object.values(MethodOfDisclosure).map(
        (method) => {
            return {
                label: method,
                value: method,
            };
        }
    );

    // Get types of disclosure for select
    const TypesOfDisclosureForSelect = Object.values(TypeOfDisclosure).map(
        (form) => {
            return {
                label: form,
                value: form,
            };
        }
    );

    // Get filter options for diagnosis list
    const filterDiagnosisOptions = (phrases: string[]) => {
        const filteredOptions = DiagnosisCodesForSelect?.filter((option) =>
            phrases.every(
                (phrase) =>
                    option &&
                    option.label.toLowerCase().includes(phrase.toLowerCase())
            )
        );
        return filteredOptions;
    };

    // Function to handle item click and set the selected note content

    const handleLoadPreviousSession = (note: IGetClientNotes) => {
        const fieldsToMap: (keyof ICreateRecordOfDisclosurePayload)[] = [
            "diagnosis_codes",
            "disclosure_to_whom",
            "disclosure_type",
            "method_of_disclosure",
            "purpose_of_disclosure",
            "type_disclosed",
        ];
        fieldsToMap.forEach((fieldName) => {
            setValue(fieldName, note.note_content[fieldName]);
        });
        setShowPrompt(false);
    };
    return (
        <>
            <LoadPreviousSessionNotificationModal
                showPrompt={showPrompt}
                setShowPrompt={setShowPrompt}
                handleLoadPreviousSession={() =>
                    handleLoadPreviousSession(selectedNote)
                }
            />
            {notes && notes.data.length > 0 && (
                <div className="flex justify-end mb-24">
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <FilterButton text="Load previous session note" />
                        </DropdownTrigger>
                        <DropdownContent width="auto" align="end">
                            {notes &&
                                notes?.data?.map((note) => (
                                    <DropdownItem
                                        key={note.note_id}
                                        onClick={() => {
                                            setShowPrompt(true);
                                            setSelectedNote(note);
                                        }}
                                    >
                                        <div>
                                            {makeStringFirstLetterCapital(
                                                removeEnumUnderscore(
                                                    note.note_type
                                                )
                                            )}{" "}
                                            - Note content Details -{" "}
                                            {formatDate(note.date_of_service)}
                                        </div>
                                    </DropdownItem>
                                ))}
                        </DropdownContent>
                    </Dropdown>
                </div>
            )}

            <form
                className="pb-[60px] md:pb-[150px]"
                onSubmit={onSubmit && handleSubmit(onSubmit)}
                aria-label="record-form"
                id="create-note-form"
            >
                <div className="mb-24">
                    <NoteFormInfoBox />
                </div>

                <div className="fg">
                    <Input
                        {...register("duration")}
                        label={RECORD_OF_DISCLOSURE_NOTE_LABELS.duration}
                        placeholder={RECORD_OF_DISCLOSURE_NOTE_LABELS.duration}
                        disabled
                        defaultValue={getTimeDuration(
                            formatDate(
                                appointmentDetails?.session_start_time || "",
                                false,
                                "HH:mm"
                            ),
                            formatDate(
                                appointmentDetails?.session_end_time || "",
                                false,
                                "HH:mm"
                            )
                        )}
                    />
                </div>

                <div className="fg">
                    <Controller
                        name="diagnosis_codes"
                        control={control}
                        render={({ field }) => (
                            <Select
                                label={
                                    RECORD_OF_DISCLOSURE_NOTE_LABELS.diagnosis_codes
                                }
                                isRequired
                                placeholder={
                                    (!data && isLoading && "Loading...") ||
                                    (error &&
                                        !isLoading &&
                                        "Error loading diagnosis") ||
                                    (data &&
                                        !isLoading &&
                                        RECORD_OF_DISCLOSURE_NOTE_LABELS.diagnosis_codes)
                                }
                                isSearchable
                                onInputChange={(value) => setSearchValue(value)}
                                filterOption={() => true} // Disable default filtering
                                onChange={(val) => {
                                    field.onChange(
                                        (val as Option[]).map(
                                            (code) => code.value
                                        )
                                    );
                                }}
                                options={filterDiagnosisOptions(
                                    searchValue.split(" ")
                                )}
                                isDisabled={isLoading || Boolean(error)}
                                hasError={!!errors.diagnosis_codes}
                                value={
                                    field.value &&
                                    DiagnosisCodesForSelect?.filter(
                                        (diagnosis) =>
                                            field.value.includes(
                                                diagnosis.value
                                            )
                                    )
                                }
                                errorText={
                                    errors.diagnosis_codes?.type === "typeError"
                                        ? "Diagnosis codes are required"
                                        : errors.diagnosis_codes?.message
                                }
                                isMulti
                                multiHasValues={
                                    diagnosisCodes && diagnosisCodes.length > 0
                                }
                                hideSelectedOptions
                                onBlur={onSubmit && handleSubmit(onSubmit)}
                            />
                        )}
                    />
                </div>
                <div className="fg">
                    <Input
                        {...register("disclosure_to_whom")}
                        label={
                            RECORD_OF_DISCLOSURE_NOTE_LABELS.disclosure_to_whom
                        }
                        placeholder={
                            RECORD_OF_DISCLOSURE_NOTE_LABELS.disclosure_to_whom
                        }
                        defaultValue={formValues?.disclosure_to_whom}
                        onBlur={onSubmit && handleSubmit(onSubmit)}
                    />
                </div>
                <p className="note-label-req">
                    {RECORD_OF_DISCLOSURE_NOTE_LABELS.disclosure_type}
                </p>
                <div className="fg fg-space-between two flex">
                    <RadioInput
                        {...register("disclosure_type")}
                        label="Authorized disclosure"
                        value="Authorized disclosure"
                        defaultChecked={
                            formValues?.disclosure_type ===
                            "Authorized disclosure"
                        }
                        onBlur={onSubmit && handleSubmit(onSubmit)}
                    />

                    <RadioInput
                        {...register("disclosure_type")}
                        label="Unauthorized disclosure"
                        value="Unauthorized disclosure"
                        defaultChecked={
                            formValues?.disclosure_type ===
                            "Unauthorized disclosure"
                        }
                        onBlur={onSubmit && handleSubmit(onSubmit)}
                    />
                </div>
                {errors.disclosure_type ? (
                    <div className="note-error">
                        <p>Disclosure type is required</p>
                    </div>
                ) : null}
                <div className="fg">
                    <TextArea
                        {...register("purpose_of_disclosure")}
                        placeholder={
                            RECORD_OF_DISCLOSURE_NOTE_LABELS.purpose_of_disclosure
                        }
                        label={
                            RECORD_OF_DISCLOSURE_NOTE_LABELS.purpose_of_disclosure
                        }
                        defaultValue={formValues?.purpose_of_disclosure}
                        onBlur={onSubmit && handleSubmit(onSubmit)}
                    />
                </div>
                <div className="fg">
                    <Controller
                        name="type_disclosed"
                        control={control}
                        render={({ field }) => (
                            <Select
                                label={
                                    RECORD_OF_DISCLOSURE_NOTE_LABELS.type_disclosed
                                }
                                placeholder={
                                    RECORD_OF_DISCLOSURE_NOTE_LABELS.type_disclosed
                                }
                                options={TypesOfDisclosureForSelect}
                                onChange={(val) =>
                                    field.onChange((val as Option).value)
                                }
                                value={TypesOfDisclosureForSelect?.find(
                                    (disclosed) =>
                                        disclosed.value === field.value
                                )}
                                onBlur={onSubmit && handleSubmit(onSubmit)}
                            />
                        )}
                    />
                </div>
                <div className="fg">
                    <Controller
                        name="method_of_disclosure"
                        control={control}
                        render={({ field }) => (
                            <Select
                                label={
                                    RECORD_OF_DISCLOSURE_NOTE_LABELS.method_of_disclosure
                                }
                                placeholder={
                                    RECORD_OF_DISCLOSURE_NOTE_LABELS.method_of_disclosure
                                }
                                options={MethodsOfDisclosureForSelect}
                                onChange={(val) =>
                                    field.onChange((val as Option).value)
                                }
                                value={MethodsOfDisclosureForSelect.find(
                                    (method) => method.value === field.value
                                )}
                                onBlur={onSubmit && handleSubmit(onSubmit)}
                            />
                        )}
                    />
                </div>

                <div className="fg-info fg-line">
                    <p>Sign note here</p>
                    <div className="fg">
                        <Input
                            {...register("signature")}
                            hasError={!!errors.signature}
                            errorText={errors.signature?.message}
                            label="Provider's Initials"
                            placeholder="Provider's Initials"
                            autoComplete="off"
                            isRequired
                        />
                    </div>
                </div>
            </form>
        </>
    );
}
