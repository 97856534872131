import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import cn from "classnames";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Button } from "@jhool-io/fe-components";
import {
    CreateNoteSteps,
    FamilyIntakeNotePayload,
    INewNoteAppoinmentDetails,
    INewSessionNote,
    NoteTypes,
} from "../../../../utils/types/notes";
import AppointmentDetails from "../../AppointmentDetails/AppointmentDetails";
import AddNoteMetadata from "../AddNoteMetadata/AddNoteMetadata";
import NoteCancellation, {
    ICreateCancellationNotePayload,
} from "../Cancellation/NoteCancellation";
import RecordOfDisclosure, {
    ICreateRecordOfDisclosurePayload,
} from "../RecordOfDisclosure/RecordOfDisclosure";
import SoapNote, { ICreateSoapNote } from "../SoapNote/SoapNote";
import Supervision, {
    ICreateSupervisionNotePayload,
} from "../Supervision/Supervision";
import Termination, {
    ICreateTerminationNotePayload,
} from "../Termination/Termination";
import styles from "./CreateNoteFormsWrapper.module.scss";
import SafetyPlan, { ICreateSafetyPlanPayload } from "../SafetyPlan/SafetyPlan";
import TreatmentReview, {
    ICreateTreatmentReviewNotePayload,
} from "../TreatmentReview/TreatmentReview";
import IndividualIntake, {
    ICreateIndividualIntakeNote,
} from "../IndividualIntake/IndividualIntake";
import FamilyIntake, {
    ICreateFamilyIntakeNote,
} from "../FamilyIntake/FamilyIntake";
import { useFetchUserDetails } from "../../../../hooks/queries/user";
import {
    useEditDraftNote,
    useSignSessionNote,
} from "../../../../hooks/mutations/note";
import useToast from "../../../../hooks/useToast";
import {
    showUserLocalTime,
    formatZonedTimeToUtc,
    handleFormatDatePickerValue,
} from "../../../../utils/helpers";
import {
    AppointmentLocation,
    AppointmentLocationTeletherapy,
    SessionPlaceOfServiceCode,
    SessionServiceType,
} from "../../../../utils/types/session";
import { useFetchSessionNote } from "../../../../hooks/queries/note";
import InfoIcon from "../../../Icons/InfoIcon";
import GeneralUpdateNote, {
    ICreateGeneralUpdateNote,
} from "../GeneralUpdateNote/GeneralUpdateNote";
import SupervisorCheckInNote, {
    ISupervisorCheckInNote,
} from "../SupervisorCheckInNote/SupervisorCheckInNote";
import { getNoteListNavigateUrl } from "../../../../modules/notes/helpers/notes.helpers";
import usePractice from "../../../../hooks/usePractice";
import { useAppSelector } from "../../../../hooks/useRedux";

interface CreateNoteFormWrapperProps {
    currentCreateNoteStepInView?: CreateNoteSteps;
    currentNoteAppointmentDetails?: INewNoteAppoinmentDetails;
}

type Buttons = "draft" | "with-signature";

export default function CreateNoteFormWrapper({
    currentCreateNoteStepInView,
    currentNoteAppointmentDetails,
}: CreateNoteFormWrapperProps) {
    const [noteAppointmentDetails, setNoteAppointmentDetails] =
        React.useState<INewNoteAppoinmentDetails | null>(
            currentNoteAppointmentDetails || null
        );
    const [createNoteStepInView, setCreateNoteStepInView] =
        React.useState<CreateNoteSteps>(
            currentCreateNoteStepInView || "appointment-details"
        );
    const [submitBtnClicked, setSubmitBtnClicked] =
        React.useState<Buttons | null>(null);
    const [timeLastSaved, setTimeLastSaved] = React.useState<string | null>(
        null
    );

    // Get logged in user
    const userDetails = useFetchUserDetails();

    const { practice } = usePractice();

    const { isOpen } = useAppSelector((state) => state.nav);

    // Query client
    const queryClient = useQueryClient();

    const { toast } = useToast();

    // React router navigation hook
    const navigate = useNavigate();

    // Get currentAction search param from url
    // helps us to determine what type of note is been created
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);

    const currentAction = searchParams.get("action");

    const noteId = searchParams.get("note_id") as string;

    const noteType = searchParams?.get("note_type") as NoteTypes;

    // Hooks for creating and signing notes
    const signSessionNote = useSignSessionNote();

    // Hook for editing note
    const editDraftNote = useEditDraftNote();

    // To determine if mutation is currently running, returns 0 || 1
    const isCurrentlyEditingNote = useIsMutating(["edit-note"]);

    // Function for setting createNoteInView state
    const handleCreateNoteStepInView = (step: CreateNoteSteps) => {
        setCreateNoteStepInView(() => step);
        window.scrollTo(0, 0);
    };

    // Function for setting noteAppointmentDetails state
    const handleSetAppointmentDetailsInView = (
        appointmentDetails: INewNoteAppoinmentDetails | null
    ) => {
        setNoteAppointmentDetails(() => appointmentDetails);
    };

    // Function to call when one of the submit button is called
    const handleSetButtonClicked = (button: Buttons) => {
        if (button === "draft") {
            navigate(
                `/add-note?note_id=${noteId}&note_type=${noteType}&action=draft`
            );
        } else {
            navigate(
                `/add-note?note_id=${noteId}&note_type=${noteType}&action=with_signature`
            );
        }

        setSubmitBtnClicked(() => button);
    };

    const noteDetails = useFetchSessionNote(
        noteAppointmentDetails?.client_id as string,
        noteId,
        Boolean(noteId && noteAppointmentDetails?.client_id)
    );

    // function to disable button when dos is in the future
    const isTheDateOfServiceInTheFuture = () => {
        if (noteDetails?.data?.data?.date_of_service) {
            const formattedDateOfService = handleFormatDatePickerValue(
                noteDetails?.data?.data?.date_of_service as string
            );
            const today = new Date();
            const formattedCurrentDate = today.toISOString().split("T")[0];
            if (formattedDateOfService > formattedCurrentDate) {
                return true;
            }
        }
        return false;
    };

    // Onsubmit function for all note types
    const onSubmit = (
        data:
            | ICreateCancellationNotePayload
            | ICreateSupervisionNotePayload
            | ICreateRecordOfDisclosurePayload
            | ICreateSafetyPlanPayload
            | ICreateTerminationNotePayload
            | ICreateTreatmentReviewNotePayload
            | ICreateSoapNote
            | ICreateIndividualIntakeNote
            | ICreateFamilyIntakeNote
            | ICreateGeneralUpdateNote
            | ISupervisorCheckInNote
    ) => {
        let newSessionNotePayload: INewSessionNote;
        let diagnosisCodes: string[] | null = null;
        // we have this field in safety plan note
        let whenShouldFollowUpBeDoneForThisClient: string | null = null;
        // we have this field in termination note
        let lastSessionDate: string | null = null;
        let firstSessionDate: string | null = null;
        let dateOfNextSession: string | null = null;
        let dateOfLastExam: string | null = null;
        let expectedTerminationDate: string | null = null;
        let detailsForDiminishedPoorLimitedQualityPatientOrientedEvidence = "";
        let arrayOfdetailsForDiminishedPoorLimitedQualityPatientOrientedEvidences: string[] =
            [];
        let family: FamilyIntakeNotePayload[] = [];
        let deliveryOption: string | undefined;
        let otherDeliveryOption: string | undefined;
        let otherFrequencyOfSession: string | undefined;
        let providerLocation: string | undefined;
        let otherProviderLocation: string | undefined;
        let consentWasReceivedForTheTeletherapySession: boolean | undefined;
        let theTeletherapySessionWasConductedViaVideo: boolean | undefined;
        let posCode: string | undefined;
        const dateOfService = noteAppointmentDetails?.date_of_service
            ? (noteAppointmentDetails.session_start_time as string)
            : undefined;
        if ("diagnosis_codes" in data) {
            if (data.diagnosis_codes && data.diagnosis_codes.length > 0) {
                diagnosisCodes = data.diagnosis_codes;
            }
        }
        if ("appointment_location" in data) {
            if (data.appointment_location === AppointmentLocation.IN_PERSON) {
                deliveryOption = undefined;
                posCode = SessionPlaceOfServiceCode.OFFICE;
                otherDeliveryOption = undefined;
                consentWasReceivedForTheTeletherapySession = undefined;
                theTeletherapySessionWasConductedViaVideo = undefined;
                providerLocation = undefined;
                otherProviderLocation = undefined;
            } else if (
                data.appointment_location === AppointmentLocation.TELETHERAPY
            ) {
                deliveryOption = data.delivery_option;
                otherDeliveryOption = data.other_delivery_option;
                consentWasReceivedForTheTeletherapySession =
                    data.consent_was_received_for_the_teletherapy_session;
                theTeletherapySessionWasConductedViaVideo =
                    data.the_teletherapy_session_was_conducted_via_video;
                providerLocation = data.provider_location;
                otherProviderLocation = data.other_provider_location;
                if (
                    data.delivery_option === AppointmentLocationTeletherapy.HOME
                ) {
                    posCode =
                        SessionPlaceOfServiceCode.TELEHEALTH_PROVIDED_IN_PATIENTS_HOME;
                } else if (
                    data.delivery_option ===
                        AppointmentLocationTeletherapy.OFFICE ||
                    data.delivery_option ===
                        AppointmentLocationTeletherapy.OTHER
                ) {
                    posCode =
                        SessionPlaceOfServiceCode.TELEHEALTH_PROVIDED_OTHER_THAN_IN_PATIENTS_HOME;
                }
                if (
                    data.delivery_option !==
                    AppointmentLocationTeletherapy.OTHER
                ) {
                    otherDeliveryOption = undefined;
                }
                if (
                    data.provider_location !==
                    AppointmentLocationTeletherapy.OTHER
                ) {
                    otherProviderLocation = undefined;
                }
            }
        }
        if ("frequency_of_sessions" in data) {
            if (data.frequency_of_sessions !== "Other") {
                otherFrequencyOfSession = undefined;
            } else {
                otherFrequencyOfSession = data.other_frequency_of_sessions;
            }
        }
        if ("when_should_follow_up_be_done_for_this_client" in data) {
            whenShouldFollowUpBeDoneForThisClient =
                data.when_should_follow_up_be_done_for_this_client
                    ? `${handleFormatDatePickerValue(
                          data.when_should_follow_up_be_done_for_this_client
                      )}T00:00:00.000`
                    : null;
        }
        if ("last_session_date" in data) {
            lastSessionDate = data.last_session_date
                ? formatZonedTimeToUtc(data.last_session_date)
                : null;
        }
        if ("first_session_date" in data) {
            firstSessionDate = data?.first_session_date
                ? formatZonedTimeToUtc(data?.first_session_date)
                : null;
        }
        if ("date_of_next_session" in data) {
            dateOfNextSession = data?.date_of_next_session
                ? formatZonedTimeToUtc(data?.date_of_next_session)
                : null;
        }
        if ("date_of_last_exam" in data) {
            dateOfLastExam = data?.date_of_last_exam
                ? formatZonedTimeToUtc(data?.date_of_last_exam)
                : null;
        }
        if ("expected_termination_date" in data) {
            expectedTerminationDate = data?.expected_termination_date
                ? formatZonedTimeToUtc(data?.expected_termination_date)
                : null;
        }
        if ("patients_ability_and_capability_to_respond_to_treatment" in data) {
            const shouldHaveDetails =
                data.patients_ability_and_capability_to_respond_to_treatment
                    ? data.patients_ability_and_capability_to_respond_to_treatment?.includes(
                          "Diminished/poor or limited-quality patient-oriented evidence"
                      )
                    : false;
            detailsForDiminishedPoorLimitedQualityPatientOrientedEvidence =
                shouldHaveDetails
                    ? data.details_for_diminished_poor_limited_quality_patient_oriented_evidence
                    : "";
        }
        if ("family" in data) {
            const arrayOfShouldHaveDetails = data.family.map((fam) => {
                return (
                    fam.patients_ability_and_capability_to_respond_to_treatment?.includes(
                        "Diminished/poor or limited-quality patient-oriented evidence"
                    ) || false
                );
            });
            arrayOfdetailsForDiminishedPoorLimitedQualityPatientOrientedEvidences =
                data.family.map((fam, i) =>
                    arrayOfShouldHaveDetails[i]
                        ? fam.details_for_diminished_poor_limited_quality_patient_oriented_evidence
                        : ""
                );
            family = data.family.map(
                (fam: FamilyIntakeNotePayload, i: number) => ({
                    ...fam,
                    details_for_diminished_poor_limited_quality_patient_oriented_evidence:
                        arrayOfdetailsForDiminishedPoorLimitedQualityPatientOrientedEvidences[
                            i
                        ],
                    family_id: fam?.family_id || uuidv4(),
                })
            );
        }
        if (noteAppointmentDetails) {
            newSessionNotePayload = {
                session_id: noteAppointmentDetails?.session_id,
                appointment_type:
                    noteAppointmentDetails.appointment_type as SessionServiceType,
                session_history_id:
                    noteAppointmentDetails.session_history_id || null,
                client_id: noteAppointmentDetails.client_id,
                provider_id: noteAppointmentDetails.provider_id as string,
                date_of_service:
                    dateOfService !== undefined
                        ? formatZonedTimeToUtc(
                              noteAppointmentDetails.session_start_time as string
                          )
                        : null,
                session_start_time: formatZonedTimeToUtc(
                    noteAppointmentDetails.session_start_time as string
                ),
                session_end_time: formatZonedTimeToUtc(
                    noteAppointmentDetails.session_end_time as string
                ),
                reason_for_short_duration:
                    noteAppointmentDetails.reason_for_short_duration,
                pos_code: posCode,
                note_content: {
                    ...data,
                    diagnosis_codes: diagnosisCodes || undefined,
                    when_should_follow_up_be_done_for_this_client:
                        whenShouldFollowUpBeDoneForThisClient,
                    first_session_date: firstSessionDate,
                    last_session_date: lastSessionDate,
                    date_of_next_session: dateOfNextSession,
                    expected_termination_date: expectedTerminationDate,
                    date_of_last_exam: dateOfLastExam,
                    other_frequency_of_sessions: otherFrequencyOfSession,
                    delivery_option: deliveryOption,
                    other_delivery_option: otherDeliveryOption,
                    provider_location: providerLocation,
                    other_provider_location: otherProviderLocation,
                    consent_was_received_for_the_teletherapy_session:
                        consentWasReceivedForTheTeletherapySession,
                    the_teletherapy_session_was_conducted_via_video:
                        theTeletherapySessionWasConductedViaVideo,
                    details_for_diminished_poor_limited_quality_patient_oriented_evidence:
                        detailsForDiminishedPoorLimitedQualityPatientOrientedEvidence,
                    family,
                },
                note_type: noteAppointmentDetails.note_type,
                cancelled_by:
                    noteAppointmentDetails.note_type ===
                    NoteTypes.CANCELLATION_NOTE
                        ? userDetails.data?.user_id
                        : undefined,
            };
            if (noteAppointmentDetails.note_type === NoteTypes.SAFETY_PLAN) {
                newSessionNotePayload.note_content.when_should_follow_up_be_done_for_this_client =
                    whenShouldFollowUpBeDoneForThisClient;
            }
            if (
                noteAppointmentDetails.note_type === NoteTypes.TERMINATION_NOTE
            ) {
                newSessionNotePayload.note_content.first_session_date =
                    firstSessionDate;
                newSessionNotePayload.note_content.last_session_date =
                    lastSessionDate;
            }
            if (
                noteAppointmentDetails.note_type ===
                    NoteTypes.INDIVIDUAL_INTAKE_NOTE ||
                noteAppointmentDetails.note_type ===
                    NoteTypes.INDIVIDUAL_TREATMENT_REVIEW ||
                noteAppointmentDetails.note_type ===
                    NoteTypes.FAMILY_TREATMENT_REVIEW
            ) {
                newSessionNotePayload.note_content.details_for_diminished_poor_limited_quality_patient_oriented_evidence =
                    detailsForDiminishedPoorLimitedQualityPatientOrientedEvidence;
            }
            if (
                noteAppointmentDetails.note_type ===
                NoteTypes.FAMILY_INTAKE_NOTE
            ) {
                newSessionNotePayload.note_content.family =
                    newSessionNotePayload.note_content.family.map(
                        (fam: FamilyIntakeNotePayload, i: number) => ({
                            ...fam,
                            details_for_diminished_poor_limited_quality_patient_oriented_evidence:
                                arrayOfdetailsForDiminishedPoorLimitedQualityPatientOrientedEvidences[
                                    i
                                ],
                        })
                    );
            }

            if (noteId && isCurrentlyEditingNote === 0) {
                const payload = {
                    noteId: noteId as string,
                    data: {
                        note_content: newSessionNotePayload.note_content,
                        date_of_service:
                            newSessionNotePayload.date_of_service as string,
                        session_start_time:
                            newSessionNotePayload.session_start_time as string,
                        session_end_time:
                            newSessionNotePayload.session_end_time as string,
                        reason_for_short_duration:
                            newSessionNotePayload.reason_for_short_duration,
                        pos_code: posCode,
                    },
                };
                editDraftNote.mutate(payload, {
                    onSuccess: async (res) => {
                        await queryClient.invalidateQueries({
                            queryKey: [
                                noteAppointmentDetails.client_id,
                                `session-note`,
                                noteId,
                            ],
                        });
                        queryClient.invalidateQueries({
                            queryKey: [`notes`],
                        });
                        setTimeLastSaved(showUserLocalTime());
                        if (submitBtnClicked === "draft") {
                            toast({
                                mode: "success",
                                message:
                                    res.message ||
                                    "Draft note updated successfully",
                            });
                            navigate(
                                `/notes/${noteAppointmentDetails?.client_id}/${noteId}`,
                                { replace: true }
                            );
                        }
                        if (
                            data.signature &&
                            submitBtnClicked === "with-signature"
                        ) {
                            signSessionNote.mutate(
                                {
                                    noteId: res.data.note_id,
                                    data: {
                                        signature: data.signature as string,
                                        signature_date_time: new Date(
                                            Date.now()
                                        ).toISOString(),
                                    },
                                },
                                {
                                    onSuccess: async (response) => {
                                        toast({
                                            mode: "success",
                                            message:
                                                response.message ||
                                                "Note signed successfully",
                                        });
                                        await queryClient.invalidateQueries({
                                            queryKey: [
                                                practice?.is_supervisor
                                                    ? `notes`
                                                    : noteAppointmentDetails.client_id,
                                                `session-note`,
                                                noteId,
                                            ],
                                        });
                                        queryClient.invalidateQueries({
                                            queryKey: [`notes`],
                                        });
                                        navigate(
                                            practice?.is_supervisor
                                                ? getNoteListNavigateUrl(
                                                      practice?.roles || []
                                                  )
                                                : `/notes/${noteAppointmentDetails?.client_id}/${noteId}`,
                                            { replace: true }
                                        );
                                    },
                                    onError: (err) => {
                                        // Do not show autosave error
                                        if (submitBtnClicked) {
                                            toast({
                                                mode: "error",
                                                message:
                                                    err.response?.data
                                                        .message ||
                                                    "Could not sign note at this time",
                                            });
                                        }
                                    },
                                }
                            );
                        }
                    },
                    onError: (err) => {
                        // Do not show autosave error
                        if (submitBtnClicked) {
                            toast({
                                mode: "error",
                                message:
                                    err.response?.data.message ||
                                    "Could not edit draft note at this time",
                            });
                        }
                    },
                });
            }
        }
    };

    // Decide createnoteform to display
    const handleCreateNoteFormToDisplay = () => {
        switch (
            currentNoteAppointmentDetails?.note_type ||
            noteAppointmentDetails?.note_type
        ) {
            case NoteTypes.CANCELLATION_NOTE:
                return (
                    <NoteCancellation
                        currentAction={currentAction}
                        onSubmit={onSubmit}
                        appointmentDetails={noteAppointmentDetails}
                        formValues={noteDetails.data?.data.note_content}
                    />
                );
            case NoteTypes.RECORD_OF_DISCLOSURE:
                return (
                    <RecordOfDisclosure
                        currentAction={currentAction}
                        onSubmit={onSubmit}
                        appointmentDetails={noteAppointmentDetails}
                        formValues={noteDetails.data?.data.note_content}
                    />
                );
            case NoteTypes.SUPERVISION_NOTE:
                return (
                    <Supervision
                        currentAction={currentAction}
                        onSubmit={onSubmit}
                        appointmentDetails={noteAppointmentDetails}
                        formValues={noteDetails.data?.data.note_content}
                    />
                );
            case NoteTypes.FAMILY_SOAP_NOTE:
                return (
                    <SoapNote
                        appointmentDetails={noteAppointmentDetails}
                        currentAction={currentAction}
                        onSubmit={onSubmit}
                        formValues={noteDetails.data?.data.note_content}
                    />
                );
            case NoteTypes.INDIVIDUAL_SOAP_NOTE:
                return (
                    <SoapNote
                        appointmentDetails={noteAppointmentDetails}
                        currentAction={currentAction}
                        onSubmit={onSubmit}
                        formValues={noteDetails.data?.data.note_content}
                    />
                );
            case NoteTypes.TERMINATION_NOTE:
                return (
                    <Termination
                        appointmentDetails={noteAppointmentDetails}
                        currentAction={currentAction}
                        onSubmit={onSubmit}
                        formValues={noteDetails.data?.data.note_content}
                    />
                );
            case NoteTypes.SAFETY_PLAN:
                return (
                    <SafetyPlan
                        appointmentDetails={noteAppointmentDetails}
                        currentAction={currentAction}
                        onSubmit={onSubmit}
                        formValues={noteDetails.data?.data.note_content}
                    />
                );
            case NoteTypes.INDIVIDUAL_TREATMENT_REVIEW:
                return (
                    <TreatmentReview
                        appointmentDetails={noteAppointmentDetails}
                        currentAction={currentAction}
                        onSubmit={onSubmit}
                        formValues={noteDetails.data?.data.note_content}
                    />
                );
            case NoteTypes.FAMILY_TREATMENT_REVIEW:
                return (
                    <TreatmentReview
                        appointmentDetails={noteAppointmentDetails}
                        currentAction={currentAction}
                        onSubmit={onSubmit}
                        formValues={noteDetails.data?.data.note_content}
                    />
                );
            case NoteTypes.INDIVIDUAL_INTAKE_NOTE:
                return (
                    <IndividualIntake
                        appointmentDetails={noteAppointmentDetails}
                        currentAction={currentAction}
                        onSubmit={onSubmit}
                        formValues={noteDetails.data?.data.note_content}
                        isMinor={false}
                    />
                );
            case NoteTypes.MINOR_INTAKE_NOTE:
                return (
                    <IndividualIntake
                        appointmentDetails={noteAppointmentDetails}
                        currentAction={currentAction}
                        onSubmit={onSubmit}
                        formValues={noteDetails.data?.data.note_content}
                        isMinor
                    />
                );
            case NoteTypes.FAMILY_INTAKE_NOTE:
                return (
                    <FamilyIntake
                        appointmentDetails={noteAppointmentDetails}
                        currentAction={currentAction}
                        onSubmit={onSubmit}
                        formValues={noteDetails.data?.data.note_content}
                    />
                );
            case NoteTypes.GENERAL_UPDATE_NOTE:
                return (
                    <GeneralUpdateNote
                        appointmentDetails={noteAppointmentDetails}
                        onSubmit={onSubmit}
                        currentAction={currentAction}
                    />
                );
            case NoteTypes.SUPERVISOR_CHECK_IN:
                return (
                    <SupervisorCheckInNote
                        appointmentDetails={noteAppointmentDetails}
                        onSubmit={onSubmit}
                        currentAction={currentAction}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className={styles.wrapper}>
            {editDraftNote.isLoading || timeLastSaved ? (
                <div
                    className={cn(styles.autosave, {
                        [styles.autosave_full]: !isOpen,
                    })}
                >
                    {(editDraftNote.isLoading && (
                        <span className={styles.saving}>Autosaving...</span>
                    )) || (
                        <span className={styles.saved}>
                            <InfoIcon /> Autosaved at {timeLastSaved}
                        </span>
                    )}
                </div>
            ) : null}
            {createNoteStepInView === "appointment-details" && (
                <AddNoteMetadata
                    noteAppointmentDetails={noteAppointmentDetails}
                    handleCreateNoteStepInView={handleCreateNoteStepInView}
                    handleSetAppointmentDetailsInView={
                        handleSetAppointmentDetailsInView
                    }
                />
            )}
            {createNoteStepInView === "add-note" && noteAppointmentDetails && (
                <div className={styles.wrapform}>
                    <div className={styles.appointment}>
                        <AppointmentDetails
                            details={noteAppointmentDetails}
                            noteType={noteAppointmentDetails.note_type}
                            showEditButton
                            editButtonClicked={() =>
                                handleCreateNoteStepInView(
                                    "appointment-details"
                                )
                            }
                        />
                    </div>
                    <div className={styles.forms}>
                        {handleCreateNoteFormToDisplay()}
                        <div
                            className={cn(styles.footer, styles.footer_three, {
                                [styles.footer_full]: !isOpen,
                            })}
                        >
                            <Button
                                className={(styles.cancelbtn, "!min-h-32")}
                                type="button"
                                variant="secondary"
                                onClick={() =>
                                    handleCreateNoteStepInView?.(
                                        "appointment-details"
                                    )
                                }
                                aria-label="cancel button"
                                disabled={editDraftNote.isLoading}
                            >
                                Back
                            </Button>
                            <Button
                                className={styles.cancelbtn}
                                type="submit"
                                variant="secondary"
                                form="create-note-form"
                                onClick={() => handleSetButtonClicked("draft")}
                                disabled={
                                    signSessionNote.isLoading ||
                                    editDraftNote.isLoading
                                }
                            >
                                Save as draft
                            </Button>
                            <Button
                                disabled={
                                    signSessionNote.isLoading ||
                                    editDraftNote.isLoading ||
                                    isTheDateOfServiceInTheFuture()
                                }
                                form="create-note-form"
                                type="submit"
                                onClick={() =>
                                    handleSetButtonClicked("with-signature")
                                }
                            >
                                Submit for review
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
