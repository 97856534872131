import * as React from "react";
import {
    Button,
    Card,
    DatePicker,
    // Dialog,
    // DialogContent,
    // DialogTrigger,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Popover,
    PopoverContent,
    PopoverTrigger,
    SearchInput,
} from "@jhool-io/fe-components";
import { useSearchParams } from "react-router-dom";
import { format } from "date-fns";
// import PlusIcon from "../../../components/Icons/Plus";
import ChevronDownIcon from "../../../components/Icons/ChevronDown";
// import AddAvailability from "../components/ModalForms/AddAvailability/AddAvailability";
// import ReserveWindow from "../components/ModalForms/ReserveWindow/ReserveWindow";
// import BookTimeOff from "../components/ModalForms/BookTimeOff/BookTimeOff";
import StatCards from "../components/StatsCards/StatsCards";
import CalendarViewWrapper from "../components/CalendarViewWrapper/CalendarViewWrapper";
import { APP_COLORS } from "../../../utils/constants";
import ClipboardIcon from "../../../components/Icons/Clipboard";
import CalendarDay from "../../../components/Icons/CalendarDay";
import Navbar from "../../../components/Navbar/Navbar";
import {
    cn,
    convertDateFilterStringToDate,
    formatDate,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../utils/helpers";
import { IAppCustomDates } from "../../../utils/types";
import PendingTasksWrapper from "../components/PendingTasksWrapper/PendingTasksWrapper";
import { useAppSelector } from "../../../hooks/useRedux";
import FilterButton from "../../../shared-ui/Buttons/FilterButton/FilterButton";

// type Modals = "add_availability" | "reserve_time" | "book_time_off";

export default function CliniciansDashboard() {
    // const [modalInView, setModalInView] = React.useState<Modals | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const { isOpen } = useAppSelector((state) => state.nav);

    const dateFilter = searchParams.get("date") || "this_week";
    const clientName = searchParams.get("client_name") || "";
    const fromDateFilter = searchParams.get("from_date");
    const toDateFilter = searchParams.get("to_date");

    const fromDateFilterToDate = convertDateFilterStringToDate(fromDateFilter);
    const toDateFilterToDate = convertDateFilterStringToDate(toDateFilter);

    const useSmallScreenStyle =
        (isOpen && window.matchMedia("(max-width: 1320px)").matches) ||
        (!isOpen && window.matchMedia("(max-width: 1000px)").matches);

    const dateFilters = ["today", ...Object.values(IAppCustomDates)];

    const handleSetSessionSummaryFilter = (filter: string) => {
        searchParams.set("date", filter);
        setSearchParams(searchParams);
    };

    const handleClientNameFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") searchParams.delete("client_name");
        else searchParams.set("client_name", e.target.value);
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleFromDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("from_date");
        else searchParams.set("from_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleToDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("to_date");
        else searchParams.set("to_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    return (
        <>
            <Navbar />
            <div className="page">
                <Card classNames="px-12 py-12 md:py-32 md:px-24">
                    <div className="flex justify-between items-center gap-x-[18px]">
                        <div className="flex justify-between items-center">
                            <div className="flex flex-col gap-y-12">
                                <h1 className="font-bold text-xl md:text-xxl">
                                    Let’s get you started 🚀
                                </h1>
                                <p className="text-sm font-normal text-[rgba(11,_19,_43,_0.64)]">
                                    You can manage your caseload and
                                    availability here
                                </p>
                            </div>
                        </div>
                        {/* <Dropdown>
                            <DropdownTrigger asChild>
                                <Button
                                    iconLeft={
                                        !isSmallScreen() ? (
                                            <PlusIcon />
                                        ) : undefined
                                    }
                                    iconRight={
                                        !isSmallScreen ? (
                                            <ChevronDownIcon />
                                        ) : undefined
                                    }
                                    className="px-16 py-12 h-32 gap-x-8"
                                >
                                    {isSmallScreen() ? (
                                        <PlusIcon />
                                    ) : (
                                        "Manage availability"
                                    )}
                                </Button>
                            </DropdownTrigger>
                            <DropdownContent
                                variant="primary"
                                width={isSmallScreen() ? "206px" : undefined}
                                align={isSmallScreen() ? "end" : "start"}
                            >
                                <Dialog
                                    open={modalInView === "add_availability"}
                                >
                                    <DialogTrigger asChild>
                                        <DropdownItem
                                            variant="primary"
                                            onClick={(
                                                e: React.MouseEvent<
                                                    HTMLDivElement,
                                                    MouseEvent
                                                >
                                            ) => {
                                                e.preventDefault();
                                                handleModalInView(
                                                    "add_availability"
                                                );
                                            }}
                                        >
                                            Add Availability
                                        </DropdownItem>
                                    </DialogTrigger>
                                    <DialogContent
                                        title="Add Availability"
                                        variant="center"
                                        handleCloseDialog={() =>
                                            handleModalInView(null)
                                        }
                                        classNames="px-0"
                                        saveText="Save availability"
                                    >
                                        <AddAvailability />
                                    </DialogContent>
                                </Dialog>
                                <Dialog open={modalInView === "reserve_time"}>
                                    <DialogTrigger asChild>
                                        <DropdownItem
                                            variant="primary"
                                            onClick={(
                                                e: React.MouseEvent<
                                                    HTMLDivElement,
                                                    MouseEvent
                                                >
                                            ) => {
                                                e.preventDefault();
                                                handleModalInView(
                                                    "reserve_time"
                                                );
                                            }}
                                        >
                                            Reserve Time
                                        </DropdownItem>
                                    </DialogTrigger>
                                    <DialogContent
                                        title="Reserve Window"
                                        variant="center"
                                        handleCloseDialog={() =>
                                            handleModalInView(null)
                                        }
                                        saveText="Save time window"
                                    >
                                        <ReserveWindow />
                                    </DialogContent>
                                </Dialog>
                                <Dialog open={modalInView === "book_time_off"}>
                                    <DialogTrigger asChild>
                                        <DropdownItem
                                            variant="primary"
                                            onClick={(
                                                e: React.MouseEvent<
                                                    HTMLDivElement,
                                                    MouseEvent
                                                >
                                            ) => {
                                                e.preventDefault();
                                                handleModalInView(
                                                    "book_time_off"
                                                );
                                            }}
                                        >
                                            Book Time Off
                                        </DropdownItem>
                                    </DialogTrigger>
                                    <DialogContent
                                        title="Book time off"
                                        variant="center"
                                        handleCloseDialog={() =>
                                            handleModalInView(null)
                                        }
                                        saveText="Book time off"
                                    >
                                        <BookTimeOff />
                                    </DialogContent>
                                </Dialog>
                            </DropdownContent>
                        </Dropdown> */}
                    </div>
                    <div className="mt-24">
                        <Dropdown>
                            <DropdownTrigger asChild>
                                <Button
                                    size="auto"
                                    variant="normal"
                                    className="flex flex-row gap-x-8 items-center p-[2px] data-[state=open]:border-primary border-[0.2px]"
                                >
                                    <CalendarDay />
                                    <span className="text-sm text-gray font-normal capitalize">
                                        {dateFilter === "today"
                                            ? formatDate(
                                                  new Date().toISOString(),
                                                  false,
                                                  "EEEE, LLL d yyyy"
                                              )
                                            : removeEnumUnderscore(dateFilter)}
                                    </span>
                                    <ChevronDownIcon
                                        stroke={APP_COLORS.COLOR_GRAY}
                                    />
                                </Button>
                            </DropdownTrigger>
                            <DropdownContent
                                align="start"
                                maxHeight={216}
                                width={200}
                                className="overflow-y-auto"
                            >
                                {dateFilters.map((filter) => (
                                    <DropdownItem
                                        key={filter}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleSetSessionSummaryFilter(
                                                filter
                                            );
                                        }}
                                    >
                                        {makeStringFirstLetterCapital(
                                            removeEnumUnderscore(filter)
                                        )}
                                    </DropdownItem>
                                ))}
                            </DropdownContent>
                        </Dropdown>
                    </div>
                    <div className="mt-24">
                        <StatCards />
                    </div>
                </Card>

                <div
                    className={cn("flex flex-row mt-32 w-[100%] gap-16", {
                        "flex-col gap-y-16 mt-20": useSmallScreenStyle,
                    })}
                >
                    <div
                        className={cn("w-[50%]", {
                            "w-full": useSmallScreenStyle,
                        })}
                    >
                        <Card
                            classNames={cn(
                                "h-[709px] overflow-y-auto border border-strokedark p-0",
                                {
                                    "h-auto": useSmallScreenStyle,
                                }
                            )}
                        >
                            <CalendarViewWrapper />
                        </Card>
                    </div>
                    <div
                        className={cn("w-[50%]", {
                            "w-full": useSmallScreenStyle,
                        })}
                    >
                        <Card
                            classNames={cn(
                                "h-[709px] overflow-y-auto border border-strokedark p-0",
                                {
                                    "h-auto": useSmallScreenStyle,
                                }
                            )}
                        >
                            <div
                                className={cn(
                                    "flex items-center border-b border-b-strokedark px-24 h-[79px] sticky top-0 w-full z-10 bg-white",
                                    {
                                        "flex-row justify-between p-16 h-[54px] items-center":
                                            useSmallScreenStyle,
                                    }
                                )}
                            >
                                {!useSmallScreenStyle && <ClipboardIcon />}
                                <h2 className="ml-8 text-base font-bold mr-8">
                                    Pending tasks
                                </h2>
                                <div
                                    className={cn(
                                        "ml-auto flex items-center gap-x-8",
                                        {
                                            hidden: useSmallScreenStyle,
                                        }
                                    )}
                                >
                                    <SearchInput
                                        placeholder="Search by client name"
                                        onChange={handleClientNameFilter}
                                        defaultValue={clientName}
                                    />
                                    <Popover>
                                        <PopoverTrigger asChild>
                                            <FilterButton
                                                text=" Date"
                                                stroke={APP_COLORS.COLOR_BLACK}
                                            />
                                        </PopoverTrigger>
                                        <PopoverContent
                                            align="end"
                                            className="p-16 gap-y-12"
                                        >
                                            <DatePicker
                                                label="From"
                                                placeholderText="MM/DD/YYYY"
                                                selected={fromDateFilterToDate}
                                                onChange={
                                                    handleFromDateFilterChange
                                                }
                                                maxDate={
                                                    toDateFilterToDate ||
                                                    new Date(Date.now())
                                                }
                                                isClearable
                                            />
                                            <DatePicker
                                                label="To"
                                                placeholderText="MM/DD/YYYY"
                                                selected={toDateFilterToDate}
                                                onChange={
                                                    handleToDateFilterChange
                                                }
                                                minDate={
                                                    fromDateFilterToDate ||
                                                    new Date(Date.now())
                                                }
                                                maxDate={new Date(Date.now())}
                                                isClearable
                                            />
                                        </PopoverContent>
                                    </Popover>
                                </div>
                            </div>
                            {useSmallScreenStyle && (
                                <div className="pt-24 px-16 w-[400px] max-w-full">
                                    <SearchInput
                                        placeholder="Search by client name"
                                        containerClass="h-32 text-sm shadow-active has-[:focus-visible]:border-primary"
                                        onChange={handleClientNameFilter}
                                        defaultValue={clientName}
                                    />
                                </div>
                            )}
                            <div
                                className={cn(
                                    "py-32 px-24 h-[calc(709px-79px-32px)]",
                                    {
                                        "py-20 px-16 pt-30 h-full":
                                            useSmallScreenStyle,
                                    }
                                )}
                            >
                                <PendingTasksWrapper />
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
}
