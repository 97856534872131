/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
    Button,
    Card,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Pagination,
    SearchInput,
    Table,
    Dialog,
    DialogContent,
    Popover,
    PopoverTrigger,
    PopoverContent,
    DatePicker,
    Tooltip,
    Tag,
} from "@jhool-io/fe-components";
import { ColumnDef, Row } from "@tanstack/react-table";
import { format } from "date-fns";
import { useQueryClient } from "@tanstack/react-query";
import {
    useFetchBatchRemits,
    useFetchRemitClaims,
} from "../../hooks/remits.queries";
import BackLink from "../../../../components/BackLink/BackLink";
import ListState from "../../../../components/ListState/ListState";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import CheckPrimaryColorIcon from "../../../../components/Icons/CheckPrimaryColor";
import {
    cn,
    convertDateFilterStringToDate,
    formatDate,
    removeEnumUnderscore,
    showMoneyInAppFormat,
    truncateString,
} from "../../../../utils/helpers";
import { ClaimStatus } from "../../../claims/types/claims.types";
import {
    IInsurancePayments,
    IRemit,
    RemitStatus,
} from "../../types/remits.types";
import { getStatusTag } from "../../../claims/helpers/claims.helper";
import { useDebounce } from "../../../../hooks/helpers";
import SearchRemitClaims from "../SearchRemitClaims/SearchRemitClaims";
import { IAppCustomDates } from "../../../../utils/types";
import InfoCircleIcon from "../../../../components/Icons/InfoCircle";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";
import { APP_COLORS, LISTS_DEFAULT_LIMIT } from "../../../../utils/constants";
import { getRemitsListNavigateUrl } from "../../helpers/helpers.remits";
import { useUpdateRemitStatus } from "../../hooks/remits.mutations";
import useToast from "../../../../hooks/useToast";

const getPaymentStatusForSingleRemitClaim = (
    remitClaims: IInsurancePayments[]
) => {
    const hasMatchedPayment = remitClaims.some(
        (claim) => claim.payment_status === "completed"
    );

    if (hasMatchedPayment) {
        return (
            <Tag
                title="completed"
                textColor="#00563E"
                bgColor="rgba(204, 250, 233, 0.50)"
                className="capitalize font-normal px-8 py-4 h-24 rounded-r4"
            />
        );
    }

    return (
        <Tag
            title="pending"
            bgColor="rgba(247, 229, 164, 0.50)"
            textColor="#634D17"
            className="capitalize font-normal px-8 py-4 h-24 rounded-r4"
        />
    );
};

export default function RemitClaims() {
    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    // Get id from url params
    const params = useParams();
    const remitId = params.remitId || "";

    const search = searchParams.get("search_string") || "";

    const searchString = useDebounce(search, 500);
    const claimStatusFilter = searchParams.get("claim_status") || "";
    const pageFilter = Number(searchParams.get("page") || 1);
    const limitFilter =
        Number(searchParams.get("limit")) || LISTS_DEFAULT_LIMIT;
    const fromDateFilter = searchParams.get("from_date");
    const toDateFilter = searchParams.get("to_date");
    const customDateFilter =
        (searchParams.get("date_range") as IAppCustomDates) || "";
    const [showAddRemitModal, setShowAddRemitModal] = React.useState(false);
    const [sortConfig, setSortConfig] = React.useState<{
        key: string;
        direction: string;
    }>({
        key: "client_name",
        direction: "ASC",
    });

    const fromDateFilterToDate = convertDateFilterStringToDate(fromDateFilter);
    const toDateFilterToDate = convertDateFilterStringToDate(toDateFilter);

    const remitsListUrl = getRemitsListNavigateUrl();

    const sortableColumns = [
        "remit_payment_date",
        "date_of_service",
        "claim_number",
        "cpt_code",
        "charged",
        "paid_amount",
        "outstanding",
        "claim_status",
        "expected_amount",
        "client_name",
    ];

    const handleConfigAttribute = () => {
        if (sortableColumns.includes(sortConfig.key)) {
            return sortConfig.key;
        }

        return undefined;
    };

    const { data, error, isLoading, isSuccess } = useFetchRemitClaims(
        {
            batch_remit_id: remitId,
            search_string: searchString,
            claim_status: claimStatusFilter,
            page: pageFilter,
            limit: limitFilter,
            sort_attribute:
                searchParams.get("sort_attr") || handleConfigAttribute(),
            sort_order: searchParams.get("sort_order") || sortConfig.direction,
            custom_date: customDateFilter,
            from_date: fromDateFilter,
            to_date: toDateFilter,
        },
        Boolean(remitId)
    );

    const batchRemitData = useFetchBatchRemits(
        {
            batch_remit_id: remitId,
        },
        Boolean(remitId)
    );

    // this is to check that you can still add more claims to the remit
    const canAddClaims =
        (batchRemitData?.data?.data?.[0]?.no_of_claims ?? 0) <
        (batchRemitData?.data?.data?.[0]?.no_of_claims_payments_from_file ?? 0);

    const isManualRemit =
        batchRemitData?.data?.data[0]?.remit_source === "Manual" &&
        canAddClaims;

    const { toast } = useToast();

    const queryClient = useQueryClient();

    const updateRemitStatus = useUpdateRemitStatus();

    // function to change remit status
    const handleChangeStatus = (remitStatus: string) => {
        const changeStatusPayload = {
            remittance_batch_ids: [remitId],
            status: remitStatus,
        };
        updateRemitStatus.mutate(changeStatusPayload, {
            onSuccess: (res) => {
                queryClient.invalidateQueries([
                    "batch-remits",
                    { batch_remit_id: remitId },
                ]);
                toast({
                    mode: "success",
                    message:
                        res.message || "Remit status updated successfully.",
                });
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not update remit status.",
                });
            },
        });
    };

    const getBatchRemitDataStates = () => {
        if (batchRemitData?.error) {
            return "0";
        }

        if (batchRemitData?.isLoading) {
            return (
                <Skeleton
                    containerTestId="claims-payments-loader"
                    height={20}
                    width={20}
                />
            );
        }

        return "";
    };

    const statusOptionsForSelect = [
        ...Object.values(ClaimStatus).map((status) => {
            return status;
        }),
    ];

    const customDateForSelect = [
        ...Object.values(IAppCustomDates).map((item) => {
            return item;
        }),
    ];

    // onChange handler for page
    const handlePageChange = (page: number) => {
        searchParams.set("page", String(page));
        setSearchParams(searchParams);
    };

    const handleLimitChange = (limit: number) => {
        searchParams.set("limit", String(limit));
        setSearchParams(searchParams);
    };

    // onChange handler for search input
    const handleSetSearchString = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") searchParams.delete("search_string");
        else searchParams.set("search_string", e.target.value);

        setSearchParams(searchParams);
    };

    const handleStatusChange = (value: string) => {
        if (value === "") searchParams.delete("claim_status");
        else searchParams.set("claim_status", value);
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleFromDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("from_date");
        else searchParams.set("from_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleToDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("to_date");
        else searchParams.set("to_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleCustomDateChange = (value: string) => {
        if (value === "") searchParams.delete("date_range");
        else searchParams.set("date_range", value);
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const columns: ColumnDef<IRemit>[] = [
        {
            accessorKey: "remit_payment_date",
            header: "PAYMENT DATE",
            cell: ({ row }) =>
                `${formatDate(row.original.remit_payment_date, true)}`,
        },
        {
            accessorKey: "date_of_service",
            header: "DATE OF SERVICE",
            cell: ({ row }) => formatDate(row.original.date_of_service),
        },
        {
            accessorKey: "client_name",
            header: "CLIENT NAME",
            cell: ({ row }) =>
                `${row.original.client.last_name} ${row.original.client.first_name}`,
        },
        {
            accessorKey: "claim_number",
            header: "CLAIM NUMBER",
        },
        {
            accessorKey: "insurance_provider_name",
            header: "INSURANCE PROVIDER",
            cell: ({ row }) => (
                <span className="uppercase">
                    {row.original.insurance_provider_name}
                </span>
            ),
        },
        {
            accessorKey: "cpt_code",
            header: "CPT CODE",
        },
        {
            accessorKey: "charged",
            header: "CHARGED",
            cell: ({ row }) => showMoneyInAppFormat(row.original.charged),
        },
        {
            accessorKey: "paid_amount",
            header: "PAID",
            cell: ({ row }) => showMoneyInAppFormat(row.original.paid_amount),
        },
        {
            accessorKey: "outstanding",
            header: "OUTSTANDING",
            cell: ({ row }) => showMoneyInAppFormat(row.original.outstanding),
        },
        {
            accessorKey: "insurance_expected",
            header: "EXPECTED",
            cell: ({ row }) =>
                !row.original.insurance_expected ? (
                    "--"
                ) : (
                    <span className="flex items-center">
                        {showMoneyInAppFormat(row.original.insurance_expected)}{" "}
                        {row.original.insurance_expected >
                            row.original.paid_amount && (
                            <Tooltip content="Expected amount is higher than paid amount">
                                <Button variant="normal" size="auto">
                                    <InfoCircleIcon
                                        stroke={APP_COLORS.COLOR_DANGER}
                                        fill={APP_COLORS.COLOR_DANGER}
                                    />
                                </Button>
                            </Tooltip>
                        )}
                    </span>
                ),
        },
        {
            accessorKey: "claim_status",
            header: "STATUS",
            cell: ({ row }) => {
                return getStatusTag(row.original.claim_status);
            },
        },
        {
            accessorKey: "insurance_payments",
            header: "Payment status",
            cell: ({ row }) =>
                getPaymentStatusForSingleRemitClaim(
                    row.original.insurance_payments
                ),
        },
    ];

    const handleRowClick = (row: Row<IRemit>) => {
        navigate(
            `/remits/${row.original.remittance_id}/${row.original.claim_id}/${row.original.client.client_id}/${row.original.note_id}?tab=remit&remit_in_view=${row.original.remittance_id}`
        );
    };

    return (
        <>
            <Dialog open={showAddRemitModal}>
                <DialogContent
                    variant="center"
                    handleCloseDialog={() => setShowAddRemitModal(false)}
                    wrapperClass="w-[850px]"
                    classNames="px-0"
                    showFooter={false}
                >
                    <SearchRemitClaims
                        setShowAddRemitModal={setShowAddRemitModal}
                    />
                </DialogContent>
            </Dialog>

            <BackLink onClick={() => navigate(remitsListUrl)} />

            <div className="mt-16">
                <div className=" w-full bg-white p-24 mb-32 rounded-r8 border border-[#C0C7CF33]">
                    <div className="flex justify-between items-center mb-16">
                        <div className="flex gap-x-8 items-center">
                            <h3 className=" text-xxl font-extrabold">
                                Remit Summary
                            </h3>
                            {batchRemitData?.data?.data[0] && (
                                <Dropdown>
                                    <DropdownTrigger asChild>
                                        <FilterButton
                                            text={`Status: ${
                                                getBatchRemitDataStates() ||
                                                batchRemitData?.data?.data[0]
                                                    ?.remit_batch_status
                                            }`}
                                            stroke={
                                                batchRemitData?.data?.data[0]
                                                    ?.remit_batch_status ===
                                                RemitStatus.POSTED
                                                    ? "#00563E"
                                                    : "#0B132B"
                                            }
                                            className={cn(
                                                "capitalize !rounded-r4 px-8 py-4 h-24 text- text-xs bg-[rgba(245,_245,_245,_0.50)]",
                                                {
                                                    "bg-[rgba(204,_250,_233,_0.50)]":
                                                        batchRemitData?.data
                                                            ?.data[0]
                                                            ?.remit_batch_status ===
                                                        RemitStatus.POSTED,
                                                }
                                            )}
                                        />
                                    </DropdownTrigger>
                                    <DropdownContent width="auto" align="end">
                                        {batchRemitData?.data?.data[0]
                                            ?.remit_batch_status ===
                                            RemitStatus.POSTED && (
                                            <DropdownItem
                                                onClick={() => {
                                                    handleChangeStatus(
                                                        RemitStatus.UN_POSTED
                                                    );
                                                }}
                                            >
                                                Mark as Unposted
                                            </DropdownItem>
                                        )}
                                        {batchRemitData?.data?.data[0]
                                            ?.remit_batch_status ===
                                            RemitStatus.UN_POSTED && (
                                            <DropdownItem
                                                onClick={() => {
                                                    handleChangeStatus(
                                                        RemitStatus.POSTED
                                                    );
                                                }}
                                            >
                                                Mark as Posted
                                            </DropdownItem>
                                        )}
                                    </DropdownContent>
                                </Dropdown>
                            )}
                        </div>
                        {batchRemitData?.data?.data[0]?.remit_batch_eob_url && (
                            <a
                                href={`/remits/${remitId}/claims/EOB`}
                                target="_blank"
                                rel="noreferrer"
                                className="bg-primary text-white text-sm font-medium px-16 py-12 rounded-r8"
                            >
                                View EOB
                            </a>
                        )}
                    </div>

                    <div className="grid grid-cols-[repeat(5,minmax(256px,1fr))] overflow-x-auto overflow-y-hidden gap-x-16">
                        <Card classNames="h-[100px]">
                            <div className="flex flex-col gap-y-8">
                                <div className="flex items-center gap-8">
                                    <span className="font-medium capitalize text-xs">
                                        CLAIMS WITH PAYMENT
                                    </span>

                                    <Tooltip content="Number of claims submitted on Mantle paid in this remit">
                                        <Button variant="normal">
                                            <InfoCircleIcon
                                                stroke="#858CA0"
                                                fill="#858CA0"
                                            />
                                        </Button>
                                    </Tooltip>
                                </div>
                                <div className="font-bold text-xxl">
                                    {getBatchRemitDataStates() ||
                                        batchRemitData?.data?.data[0]
                                            ?.no_of_claims_payments ||
                                        0}
                                </div>
                            </div>
                        </Card>
                        <Card classNames="h-[100px]">
                            <div className="flex flex-col gap-y-8">
                                <div className="font-medium capitalize text-xs">
                                    CHECK AMOUNT
                                </div>
                                <div className="font-bold text-xxl">
                                    {getBatchRemitDataStates() ||
                                        showMoneyInAppFormat(
                                            batchRemitData?.data?.data[0]
                                                ?.remit_amount || 0
                                        ) ||
                                        0}
                                </div>
                            </div>
                        </Card>
                        <Card classNames="h-[100px]">
                            <div className="flex flex-col gap-y-8">
                                <div className="font-medium capitalize text-xs">
                                    MATCHED PAYMENTS
                                </div>
                                <div className="font-bold text-xxl">
                                    {getBatchRemitDataStates() ||
                                        batchRemitData?.data?.data[0]
                                            ?.no_of_matched_payments ||
                                        0}
                                </div>
                            </div>
                        </Card>
                        <Card classNames="h-[100px]">
                            <div className="flex flex-col gap-y-8">
                                <div className="font-medium capitalize text-xs">
                                    UNMATCHED PAYMENTS
                                </div>
                                <div className="font-bold text-xxl">
                                    {getBatchRemitDataStates() ||
                                        batchRemitData?.data?.data[0]
                                            ?.no_of_unmatched_payments ||
                                        0}
                                </div>
                            </div>
                        </Card>
                        <Card classNames="h-[100px]">
                            <div className="flex flex-col gap-y-8">
                                <div className="font-medium capitalize text-xs">
                                    UNMATCHED AMOUNT
                                </div>
                                <div className="font-bold text-xxl">
                                    {getBatchRemitDataStates() ||
                                        showMoneyInAppFormat(
                                            batchRemitData?.data?.data[0]
                                                ?.amount_of_unmatched_payments ||
                                                0
                                        )}
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>

            <div className="rounded-r8 border border-[#C0C7CF33] bg-white p-24">
                <h3 className="text-sm font-bold mb-24">Individual claims</h3>
                <div className="flex items-center justify-between mb-24 flex-wrap gap-16">
                    <SearchInput
                        containerClass="w-[400px]"
                        placeholder="Search by client or claim no"
                        onChange={handleSetSearchString}
                    />

                    <div className="flex items-center gap-16">
                        <Dropdown>
                            <DropdownTrigger asChild>
                                <FilterButton
                                    text={
                                        claimStatusFilter
                                            ? truncateString(
                                                  removeEnumUnderscore(
                                                      claimStatusFilter
                                                  )
                                              )
                                            : "Status"
                                    }
                                />
                            </DropdownTrigger>
                            <DropdownContent
                                align="end"
                                width={200}
                                maxHeight={216}
                                className="overflow-y-auto"
                            >
                                <DropdownItem
                                    className={cn(
                                        "flex justify-between items-center text-xs font-medium",
                                        {
                                            "text-primary":
                                                claimStatusFilter === "",
                                        }
                                    )}
                                    onClick={() => handleStatusChange("")}
                                >
                                    All
                                    {!claimStatusFilter ? (
                                        <CheckPrimaryColorIcon />
                                    ) : null}
                                </DropdownItem>
                                {statusOptionsForSelect.map((status) => (
                                    <DropdownItem
                                        className={cn(
                                            "flex justify-between items-center capitalize text-xs font-medium",
                                            {
                                                "text-primary":
                                                    claimStatusFilter ===
                                                    status,
                                            }
                                        )}
                                        key={status}
                                        onClick={() =>
                                            handleStatusChange(status)
                                        }
                                    >
                                        {removeEnumUnderscore(status)}
                                        {claimStatusFilter === status ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>
                                ))}
                            </DropdownContent>
                        </Dropdown>
                        <Dropdown>
                            <DropdownTrigger asChild>
                                <FilterButton
                                    text={
                                        customDateFilter
                                            ? truncateString(
                                                  removeEnumUnderscore(
                                                      customDateFilter
                                                  )
                                              )
                                            : "Custom Date"
                                    }
                                />
                            </DropdownTrigger>
                            <DropdownContent
                                align="start"
                                width={200}
                                maxHeight={216}
                                className="overflow-y-auto"
                            >
                                {customDateForSelect.map((range) => (
                                    <DropdownItem
                                        className={cn(
                                            "flex justify-between items-center capitalize text-xs font-medium",
                                            {
                                                "text-primary":
                                                    customDateFilter === range,
                                            }
                                        )}
                                        key={range}
                                        onClick={() =>
                                            handleCustomDateChange(range)
                                        }
                                    >
                                        {removeEnumUnderscore(range)}{" "}
                                        {customDateFilter === range ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>
                                ))}
                            </DropdownContent>
                        </Dropdown>

                        <Popover>
                            <PopoverTrigger asChild>
                                <FilterButton text="Date" />
                            </PopoverTrigger>
                            <PopoverContent
                                align="end"
                                className="p-16 gap-y-12"
                            >
                                <DatePicker
                                    label="From"
                                    placeholderText="MM/DD/YYYY"
                                    selected={fromDateFilterToDate}
                                    onChange={handleFromDateFilterChange}
                                    maxDate={
                                        toDateFilterToDate ||
                                        new Date(Date.now())
                                    }
                                    isClearable
                                />

                                <DatePicker
                                    label="To"
                                    placeholderText="MM/DD/YYYY"
                                    selected={toDateFilterToDate}
                                    onChange={handleToDateFilterChange}
                                    className="hover:border-primary"
                                    minDate={
                                        fromDateFilterToDate ||
                                        new Date(Date.now())
                                    }
                                    maxDate={new Date(Date.now())}
                                    isClearable
                                />
                            </PopoverContent>
                        </Popover>

                        {isManualRemit ? (
                            <Button onClick={() => setShowAddRemitModal(true)}>
                                Search &amp; add claims
                            </Button>
                        ) : null}
                    </div>
                </div>

                {error && error?.response?.status !== 404 && (
                    <ListState
                        isError
                        stateHelperText="Try reloading this page to solve this issue"
                        errorMsg={
                            error.response?.data.message ||
                            "Cannot display claims at this time please try again later"
                        }
                    />
                )}
                {isLoading && (
                    <Skeleton
                        containerTestId="individual-claims-loader"
                        type="table"
                        count={6}
                    />
                )}

                {data && isSuccess && (
                    <>
                        {data.data.length === 0 && (
                            <div className="flex items-center justify-center flex-col my-[90px] gap-8 max-w-[180px] mx-auto">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="22"
                                    viewBox="0 0 22 22"
                                    fill="none"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M15.0231 16.6187C13.4986 17.8041 11.583 18.5101 9.50251 18.5101C4.53056 18.5101 0.5 14.4784 0.5 9.50503C0.5 4.53169 4.53056 0.5 9.50251 0.5C14.4745 0.5 18.505 4.53169 18.505 9.50503C18.505 11.5859 17.7994 13.5019 16.6145 15.0267L21.1699 19.578C21.6097 20.0173 21.6101 20.73 21.1709 21.1698C20.7317 21.6097 20.0192 21.6101 19.5794 21.1708L15.0231 16.6187ZM16.2544 9.50503C16.2544 13.235 13.2315 16.2588 9.50251 16.2588C5.77355 16.2588 2.75063 13.235 2.75063 9.50503C2.75063 5.77502 5.77355 2.75126 9.50251 2.75126C13.2315 2.75126 16.2544 5.77502 16.2544 9.50503Z"
                                        fill="#BCC2CE"
                                    />
                                </svg>
                                <p className="text-sm font-medium leading-8 text-center text-gray tracking-wide">
                                    Search for a client to add a claims manually
                                </p>
                            </div>
                        )}

                        {data.data.length > 0 && (
                            <Table
                                columns={columns}
                                data={data.data}
                                hasPagination={data.total_count > 20}
                                handleRowClick={handleRowClick}
                                sortableColumns={sortableColumns}
                                sortConfig={sortConfig}
                                setSortConfig={setSortConfig}
                                pagination={
                                    <Pagination
                                        totalCount={data.total_count}
                                        totalPages={data.total_pages}
                                        currentPage={pageFilter}
                                        onPageChange={handlePageChange}
                                        limit={limitFilter}
                                        onLimitChange={handleLimitChange}
                                    />
                                }
                            />
                        )}
                    </>
                )}
            </div>
        </>
    );
}
