import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Input, Select, TextArea } from "@jhool-io/fe-components";
import { child, push, ref, update } from "firebase/database";
import { useSearchParams } from "react-router-dom";
import { UserRole } from "../../../user-management/types/user-management.types";
import { rtdb } from "../../../../firebase";
import useToast from "../../../../hooks/useToast";
import { useFetchAllPractices } from "../../../practices/hooks/practices.queries";
import { ICreateAnnouncementPayload } from "../../types/announcement";

interface NewAnnouncementFormProps {
    onFormSubmit: () => void;
}

const schema = yup.object().shape({
    details: yup.string().required("Details is required"),
    has_redirect: yup.boolean().required("Field is required"),
    practice_ids: yup
        .array()
        .min(1, "Practice is required")
        .required("Practice is required"),
    redirect_url: yup
        .string()
        .when("has_redirect", (has_redirect, field) =>
            has_redirect ? field.required("Redirect url is required") : field
        )
        .nullable(),
    roles: yup.array().min(1, "Role is required").required("Role is required"),
});

const rolesForSelect = [
    {
        value: "allusers",
        label: "All Users",
    },
    {
        value: UserRole.SUPER_ADMIN,
        label: "Super Admin",
    },
    {
        value: UserRole.AUDITOR,
        label: "Auditor",
    },
    {
        value: UserRole.BILLER,
        label: "Biller",
    },
    {
        value: UserRole.BILLER_ADMIN,
        label: "Biller Admin",
    },
    {
        value: UserRole.CLIENT_SUPPORT,
        label: "Client Support",
    },
    {
        value: UserRole.CODER,
        label: "Coder",
    },
    {
        value: UserRole.PROVIDER,
        label: "Provider",
    },
    {
        value: UserRole.USER_SUPPORT,
        label: "User Support",
    },
];

const isLinkOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
];

export default function NewAnnouncementForm({
    onFormSubmit,
}: NewAnnouncementFormProps) {
    const [searchParams, setSearchParams] = useSearchParams();

    const {
        control,
        handleSubmit,
        register,
        watch,
        formState: { errors },
    } = useForm<ICreateAnnouncementPayload>({
        resolver: yupResolver(schema),
    });

    const roles = watch("roles");
    const practiceIds = watch("practice_ids");

    const { toast } = useToast();

    const { data } = useFetchAllPractices({});

    const practicesForSelect = [
        { value: "all", label: "All practices" },
        ...(data?.data.map((prac) => ({
            value: prac.practice_id,
            label: prac.practice_name,
        })) || []),
    ];

    const onSubmit = async (payload: ICreateAnnouncementPayload) => {
        searchParams.set("adding_announcement", "true");
        setSearchParams(searchParams);

        const newAnnouncementKey = push(child(ref(rtdb), "announcements")).key;

        try {
            await update(ref(rtdb, `/announcements/${newAnnouncementKey}`), {
                details: payload.details,
                roles: payload.roles,
                has_redirect: payload.has_redirect,
                redirect_url: payload.redirect_url || null,
                created_at: new Date().toISOString(),
                practice_ids: payload.practice_ids,
            });

            toast({
                mode: "success",
                message: "Announcement added successfully",
            });

            onFormSubmit();
        } catch {
            toast({
                mode: "error",
                message:
                    "Could not add announcement at this time, please try again later",
            });
        }
    };

    return (
        <form id="add-announcement" onSubmit={handleSubmit(onSubmit)}>
            <div className="fg">
                <TextArea
                    {...register("details")}
                    label="Details"
                    hasError={!!errors.details}
                    errorText={errors.details?.message}
                />
            </div>
            <div className="fg">
                <Controller
                    name="roles"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Assign Role"
                            placeholder="Assign Role"
                            options={rolesForSelect}
                            onChange={(val) => {
                                field.onChange(
                                    (
                                        val as {
                                            value: string;
                                            label: string;
                                        }[]
                                    ).map((role) => role.value)
                                );
                            }}
                            hasError={!!errors?.roles}
                            errorText={
                                errors?.roles?.type === "typeError"
                                    ? "Role(s) are required"
                                    : errors?.roles?.message
                            }
                            multiHasValues={roles && roles.length > 0}
                            isMulti
                            hideSelectedOptions
                        />
                    )}
                />
            </div>
            <div className="fg">
                <Controller
                    name="practice_ids"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Practice(s)"
                            placeholder="Practice(s)"
                            options={practicesForSelect}
                            onChange={(val) => {
                                field.onChange(
                                    (
                                        val as {
                                            value: string;
                                            label: string;
                                        }[]
                                    ).map((practice) => practice.value)
                                );
                            }}
                            hasError={!!errors?.practice_ids}
                            errorText={
                                errors?.roles?.type === "typeError"
                                    ? "Practice(s) are required"
                                    : errors?.practice_ids?.message
                            }
                            multiHasValues={
                                practiceIds && practiceIds.length > 0
                            }
                            isMulti
                            hideSelectedOptions
                        />
                    )}
                />
            </div>
            <div className="fg">
                <Controller
                    name="has_redirect"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Has Redirect"
                            options={isLinkOptions}
                            onChange={(val) =>
                                field.onChange(
                                    (
                                        val as {
                                            value: boolean;
                                            label: string;
                                        }
                                    ).value
                                )
                            }
                            hasError={!!errors.has_redirect}
                            errorText={errors.has_redirect?.message}
                        />
                    )}
                />
            </div>
            <Input
                {...register("redirect_url")}
                label="Redirect URL"
                hasError={!!errors.redirect_url}
                errorText={errors.redirect_url?.message}
            />
        </form>
    );
}
