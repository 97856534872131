import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { Tabs } from "@jhool-io/fe-components";
import NoteList from "../NoteList/NoteList";
import SuperviseeNoteList from "../SuperviseeNoteList/SuperviseeNoteList";
import { UserPermisions } from "../../../../utils/types/user";
import Navbar from "../../../Navbar/Navbar";
import usePractice from "../../../../hooks/usePractice";

export default function NoteListWrapper() {
    const [totalNotesCount, setTotalNotesCount] = React.useState("");
    const [searchParams, setSearchParams] = useSearchParams();

    const { practice } = usePractice();

    const activeTabInLocalStorage = localStorage.getItem("activeBtn");

    let activeTab;

    const handleChangeTab = (tab: string) => {
        searchParams.set("tab", tab);
        setSearchParams(searchParams);
        localStorage.setItem("notelist_filters", searchParams.toString());
        localStorage.setItem("activeBtn", tab);
    };

    const tabItems = [
        {
            key: "my_notes",
            label: "My notes",
            onClick: () => handleChangeTab("my_notes"),
            children: <NoteList setNotesCount={setTotalNotesCount} />,
            hide: !practice?.provider_id,
        },
        {
            key: "my_supervisees_notes",
            label: "Supervisees' notes",
            onClick: () => handleChangeTab("my_supervisees_notes"),
            hide: !practice?.is_supervisor,
            children: <SuperviseeNoteList setNotesCount={setTotalNotesCount} />,
        },
        {
            key: "all_notes",
            label: "All notes",
            onClick: () => handleChangeTab("all_notes"),
            hide: !practice?.permissions?.includes(
                UserPermisions.CLIENT_INFO_NOTES_FLAG_FOR_AUDIT_WRITE
            ),
            children: <NoteList viewAll setNotesCount={setTotalNotesCount} />,
        },
        {
            key: "flagged_notes",
            label: "Flagged notes",
            onClick: () => handleChangeTab("flagged_notes"),
            children: <NoteList viewAll setNotesCount={setTotalNotesCount} />,
            hide: !practice?.permissions?.includes(
                UserPermisions.CLIENT_INFO_NOTES_FLAG_FOR_AUDIT_WRITE
            ),
        },
    ];

    const tabItemsToNotHide = tabItems.filter((item) => !item.hide);

    if (tabItemsToNotHide.length < 2) {
        activeTab = null;
    } else {
        const tabInLS =
            activeTabInLocalStorage &&
            tabItems.find(
                (tab) => !tab.hide && tab.key === activeTabInLocalStorage
            )?.key;
        activeTab =
            tabInLS ?? (practice?.provider_id ? "my_notes" : "all_notes");
    }

    return (
        <>
            <Navbar
                title={`Notes${totalNotesCount ? `(${totalNotesCount})` : ""}`}
            />
            <div className="page">
                {activeTab ? (
                    <Tabs
                        items={tabItems}
                        size="medium"
                        className="w-full rounded-b-none"
                        defaultActiveKey={activeTab}
                    />
                ) : (
                    <NoteList setNotesCount={setTotalNotesCount} />
                )}
            </div>
        </>
    );
}
