/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import cn from "classnames";
import { ColumnDef } from "@tanstack/react-table";
import { useParams, useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import {
    Button,
    DatePicker,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Pagination,
    Popover,
    PopoverContent,
    PopoverTrigger,
    SearchInput,
    Table,
    Tooltip,
} from "@jhool-io/fe-components";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import styles from "./ClientMemoList.module.scss";
import AddMemo from "./AddMemo/AddMemo";
import {
    formatDate,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../../utils/helpers";
import { useDebounce } from "../../../../hooks/helpers";
import {
    useDoesUserHavePermission,
    useDoesUserHaveRole,
} from "../../../../hooks/permissions";
import { UserPermisions, UserRole } from "../../../../utils/types/user";
import {
    useDeleteMemos,
    useEditMemos,
} from "../../../../hooks/mutations/client";
import useToast from "../../../../hooks/useToast";
import { useFetchUserDetails } from "../../../../hooks/queries/user";
import { momentNotTz } from "../../../../utils/moment";
import { APP_COLORS, LISTS_DEFAULT_LIMIT } from "../../../../utils/constants";
import TableFiltersDisplay from "../../../../shared-ui/TableFiltersDisplay/TableFiltersDisplay";
import { ViewEditorText } from "../../../../components/TextEditor/ViewEditorText/ViewEditorText";
import ChevronDownIcon from "../../../../components/Icons/ChevronDown";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import MobileListItem from "../../../../shared-ui/MobileListItem/MobileListItem";
import {
    ClientMemoCategories,
    ClientMemoStatus,
    IClientMemo,
} from "../../types/clients.types";
import { useFetchClientMemos } from "../../hooks/clients.queries";
import { AddTextEditor } from "../../../../components/TextEditor/AddTextEditor/AddTextEditor";
import InfoBlueIcon from "../../../../components/Icons/InfoBlue";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";
import CheckPrimaryColorIcon from "../../../../components/Icons/CheckPrimaryColor";
import MoreButton from "../../../../shared-ui/Buttons/MoreButton/MoreButton";
import usePractice from "../../../../hooks/usePractice";

const categoriesList: string[] = [
    ClientMemoCategories.CLIENT_SERVICE,
    ClientMemoCategories.BILLING,
    ClientMemoCategories.NOTES,
    ClientMemoCategories.INTAKE,
    ClientMemoCategories.REPORTS,
];

export default function ClientMemoList() {
    // Component local states
    const [memoId, setMemoId] = React.useState<string | null>(null);
    const [memoDescription, setMemoDescription] = React.useState("");
    const [searchParams, setSearchParams] = useSearchParams();

    // Get filters from params
    const pageFilter = Number(searchParams.get("page")) || 1;
    const limitFilter =
        Number(searchParams.get("limit")) || LISTS_DEFAULT_LIMIT;
    const fromDateFilter = searchParams.get("from_date")
        ? momentNotTz(searchParams.get("from_date")).toDate()
        : null;
    const toDateFilter = searchParams.get("to_date")
        ? momentNotTz(searchParams.get("to_date")).toDate()
        : null;
    const authorNameFilter = searchParams.get("author_name");
    const typeFilter = searchParams.get("type");
    const categoryFilter = searchParams.getAll("category[]");
    const memoStatusFilter = searchParams.get("status") || "active";

    // Boolean to hide add memo button in empty list during filtering
    const hideMemoButton = Boolean(
        fromDateFilter ||
            toDateFilter ||
            authorNameFilter ||
            typeFilter ||
            categoryFilter.length > 0
    );

    // check if logged in user can add memo
    const canAddMemo = useDoesUserHavePermission([
        UserPermisions.CLIENT_INFO_ALL_ADMIN,
        UserPermisions.CLIENT_INFO_ALL_WRITE,
        UserPermisions.CLIENT_INFO_MEMO_WRITE,
    ]);

    // Use debounce hook for author's name filter input
    const authorName = useDebounce(authorNameFilter || "", 500);

    // Get id from url params
    const params = useParams();
    const clientId = params.clientId as string;

    const loggedInUser = useFetchUserDetails();

    const { practice } = usePractice();

    const getMemoStatusForApi = () => {
        if (memoStatusFilter === "all") return "";

        return memoStatusFilter;
    };

    // Fetch client memos
    const { data, isLoading, isSuccess, error } = useFetchClientMemos(
        clientId,
        {
            author_name: authorName || "",
            to_date: toDateFilter,
            from_date: fromDateFilter,
            type: "user",
            page: pageFilter,
            limit: limitFilter,
            category: categoryFilter,
            memo_status: getMemoStatusForApi(),
        }
    );

    // To determine if mutation is currently running, returns 0 || 1
    const addMemoIsMutating = useIsMutating(["add-memo", clientId]);
    const editMemoIsMutating = useIsMutating(["edit-memo"]);

    const memoCategories = [
        ClientMemoCategories.BILLING,
        ClientMemoCategories.CLIENT_SERVICE,
        ClientMemoCategories.INTAKE,
        ClientMemoCategories.NOTES,
        ClientMemoCategories.REPORTS,
    ].map((category) => {
        return category;
    });

    const deleteMemo = useDeleteMemos();
    const editMemo = useEditMemos();

    const modalInView = searchParams.get("modal");

    // // Query client
    const queryClient = useQueryClient();

    // // Toast for success and error states
    const { toast } = useToast();

    const canEditMemoStatus = useDoesUserHaveRole([
        UserRole.SUPER_ADMIN,
        UserRole.CODER,
        UserRole.BILLER_ADMIN,
        UserRole.BILLER,
        UserRole.CLIENT_SUPPORT,
    ]);

    /** FILTER INPUTS ONCHANGE FUNCTIONS */
    const handleSearchInputChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (e.target.value === "") searchParams.delete("author_name");
        else searchParams.set("author_name", e.target.value);
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleCategoryChange = (value: string) => {
        if (categoryFilter.includes(value)) {
            searchParams.delete("category[]", value);
        } else searchParams.append("category[]", value);

        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleFromDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("from_date");
        else searchParams.set("from_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleToDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("to_date");
        else searchParams.set("to_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleSetMemoStatusFilter = (status: string) => {
        if (status === "") searchParams.delete("status");
        else searchParams.set("status", status);
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const getStatusFilterTextToDisplay = () => {
        if (memoStatusFilter === "all") return "Status";

        return memoStatusFilter;
    };

    // onChange handler for page
    const handlePageChange = (page: number) => {
        searchParams.set("page", String(page));
        setSearchParams(searchParams);
    };

    const handleLimitChange = (limit: number) => {
        searchParams.set("limit", String(limit));
        setSearchParams(searchParams);
    };

    const toggleModalInView = (modal: string) => {
        if (modal === modalInView) {
            searchParams.delete("modal");
            setSearchParams(searchParams);
        } else {
            searchParams.set("modal", modal);
            setSearchParams(searchParams);
        }
    };

    const handleDeleteClientMemo = () => {
        deleteMemo.mutate([memoId as string], {
            async onSuccess(res) {
                queryClient.invalidateQueries({
                    queryKey: [clientId, "memos"],
                });
                setMemoId(null);
                toast({
                    mode: "success",
                    message: res.message || "Memo created successfully!",
                });
                toggleModalInView("confirmation");
            },
            onError(err) {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Cannot delete memo at this moment, please try again",
                });
            },
        });
    };

    const handleUpdateClientMemoStatus = (
        status: ClientMemoStatus,
        id: string
    ) => {
        editMemo.mutate(
            { memo_status: status, memoId: memoId || id },
            {
                async onSuccess(res) {
                    queryClient.invalidateQueries({
                        queryKey: [clientId, "memos"],
                    });
                    setMemoId(null);
                    toast({
                        mode: "success",
                        message: res.message || "Memo updated successfully!",
                    });
                },
                onError(err) {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Cannot update memo at this moment, please try again",
                    });
                },
            }
        );
    };

    const handleEditClientMemo = () => {
        editMemo.mutate(
            { description: memoDescription, memoId: memoId || "" },
            {
                async onSuccess(res) {
                    queryClient.invalidateQueries({
                        queryKey: [clientId, "memos"],
                    });
                    setMemoId(null);
                    toast({
                        mode: "success",
                        message: res.message || "Memo updated successfully!",
                    });
                    toggleModalInView("edit_memo");
                },
                onError(err) {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Cannot update memo at this moment, please try again",
                    });
                },
            }
        );
    };

    const columns: ColumnDef<IClientMemo>[] = [
        {
            accessorKey: "category",
            header: "",
            cell: ({ row }) => (
                <p
                    className={cn(
                        styles.category,
                        styles[
                            `category_${row.original.category.toLowerCase()}`
                        ],
                        {
                            [styles.category_client_services]:
                                row.original.category.toLowerCase() ===
                                ClientMemoCategories.CLIENT_SERVICE,
                        }
                    )}
                />
            ),
        },
        {
            accessorKey: "date_created",
            header: "Date created",
            cell: ({ row }) => <>{formatDate(row.original.date_created)}</>,
        },
        {
            accessorKey: "type",
            header: "Type",
            cell: ({ row }) => (
                <span className="capitalize">{row.original.type}</span>
            ),
        },
        {
            accessorKey: "memo_status",
            header: "Status",
            cell: ({ row }) => (
                <span className="capitalize">
                    {removeEnumUnderscore(row.original.memo_status)}
                </span>
            ),
        },
        {
            header: "Author",
            cell: ({ row }) => (
                <span className="capitalize">{`${
                    row.original.author
                        ? `${row.original.author.first_name} ${row.original.author.last_name}`
                        : "--"
                }`}</span>
            ),
        },
        {
            accessorKey: "description",
            header: "Description",
            cell: ({ row }) => (
                <span className="items-center flex gap-x-4">
                    <ViewEditorText text={row.original.description} />
                    <Tooltip
                        content={`Updated on: ${formatDate(
                            row.original.date_updated
                        )}`}
                    >
                        <div className="mt-4">
                            <Button size="auto" variant="normal">
                                <InfoBlueIcon />
                            </Button>
                        </div>
                    </Tooltip>
                </span>
            ),
        },
        {
            accessorKey: "memo_id",
            header: "",
            cell: ({ row }) =>
                row.original.type === "user" &&
                (loggedInUser.data?.user_id === row.original.author?.user_id ||
                    practice?.roles?.includes(UserRole.SUPER_ADMIN)) && (
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <MoreButton />
                        </DropdownTrigger>
                        <DropdownContent width="auto" align="end">
                            <DropdownItem
                                onClick={(e) => {
                                    setMemoId(() => row.original.memo_id);
                                    e.stopPropagation();
                                    setMemoDescription(
                                        row.original.description
                                    );
                                    toggleModalInView("edit_memo");
                                }}
                            >
                                Edit
                            </DropdownItem>
                            <DropdownItem
                                onClick={(e) => {
                                    setMemoId(() => row.original.memo_id);
                                    e.stopPropagation();
                                    toggleModalInView("confirmation");
                                }}
                            >
                                Delete
                            </DropdownItem>
                            {canEditMemoStatus && (
                                <DropdownItem
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleUpdateClientMemoStatus(
                                            row.original.memo_status ===
                                                ClientMemoStatus.ACTIVE
                                                ? ClientMemoStatus.PAUSED
                                                : ClientMemoStatus.ACTIVE,
                                            row.original.memo_id
                                        );
                                    }}
                                >
                                    {`Set to ${
                                        row.original.memo_status ===
                                        ClientMemoStatus.ACTIVE
                                            ? ClientMemoStatus.PAUSED
                                            : ClientMemoStatus.ACTIVE
                                    }`}
                                </DropdownItem>
                            )}
                        </DropdownContent>
                    </Dropdown>
                ),
        },
    ];

    return (
        <>
            <Dialog open={modalInView === "add_memo"}>
                <DialogContent
                    title="Add memo"
                    variant="center"
                    handleCloseDialog={() => toggleModalInView("add_memo")}
                    saveText="Add memo"
                    cancelText="Cancel"
                    isSubmitBtnDisabled={addMemoIsMutating > 0}
                    isCancelBtnDisabled={addMemoIsMutating > 0}
                    submitBtnFormValue="add-memo"
                >
                    <AddMemo
                        onFormSubmit={() => toggleModalInView("add_memo")}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={modalInView === "edit_memo"}>
                <DialogContent
                    title="Edit memo"
                    variant="center"
                    handleCloseDialog={() => toggleModalInView("edit_memo")}
                    saveText="Edit memo"
                    cancelText="Cancel"
                    isSubmitBtnDisabled={editMemoIsMutating > 0}
                    isCancelBtnDisabled={editMemoIsMutating > 0}
                    onSaveClick={handleEditClientMemo}
                    onCancelClick={() => toggleModalInView("edit_memo")}
                >
                    <div>
                        <AddTextEditor
                            title="Add description"
                            defaultValue={memoDescription}
                            titleClass={styles.descriptionLabel}
                            onEditorTextChange={setMemoDescription}
                            isRequiredFieldProvided
                            editorClass={styles.descriptionWrapper}
                        />
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={modalInView === "confirmation"}>
                <DialogContent
                    title="Delete memo"
                    isDeleting
                    variant="center"
                    handleCloseDialog={() => toggleModalInView("confirmation")}
                    saveText="Delete memo"
                    cancelText="Cancel"
                    isSubmitBtnDisabled={deleteMemo.isLoading}
                    isCancelBtnDisabled={deleteMemo.isLoading}
                    onSaveClick={handleDeleteClientMemo}
                    showFooter
                >
                    <>
                        <h3 className="font-medium text-xl mb-8">
                            Are you sure you want to delete this memo?
                        </h3>
                        <span className="text-sm">
                            This action cannot be reversed.
                        </span>
                    </>
                </DialogContent>
            </Dialog>

            <div className="rounded-r8 bg-white p-16 mb-32">
                <div className="flex flex-col gap-y-24">
                    <SearchInput
                        containerClass="w-[400px] max-w-full"
                        placeholder="Author"
                        onChange={handleSearchInputChange}
                        defaultValue={authorNameFilter || ""}
                    />
                    <div className="flex items-center justify-between gap-x-12 flex-wrap gap-y-24">
                        <div className="flex items-center flex-wrap gap-12">
                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize"
                                    >
                                        <>
                                            Category
                                            <ChevronDownIcon
                                                stroke={APP_COLORS.COLOR_BLACK}
                                            />
                                        </>
                                    </Button>
                                </DropdownTrigger>
                                <DropdownContent
                                    align="end"
                                    width="auto"
                                    maxHeight={216}
                                    className="overflow-y-auto"
                                >
                                    {memoCategories.map((category) => (
                                        <DropdownItem
                                            key={category}
                                            onClick={() =>
                                                handleCategoryChange(category)
                                            }
                                            isMulti
                                            itemValue={category}
                                            values={categoryFilter}
                                            className=" capitalize"
                                        >
                                            {category}
                                        </DropdownItem>
                                    ))}
                                </DropdownContent>
                            </Dropdown>
                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <FilterButton
                                        text={getStatusFilterTextToDisplay()}
                                    />
                                </DropdownTrigger>
                                <DropdownContent width="auto">
                                    <DropdownItem
                                        className={cn(
                                            "flex gap-x-8 items-center text-xs font-medium",
                                            {
                                                "text-primary":
                                                    memoStatusFilter === "all",
                                            }
                                        )}
                                        onClick={() =>
                                            handleSetMemoStatusFilter("all")
                                        }
                                    >
                                        All Memos
                                        {memoStatusFilter === "all" ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>
                                    {Object.values(ClientMemoStatus).map(
                                        (status) => (
                                            <DropdownItem
                                                key={status}
                                                className={cn(
                                                    "flex gap-x-8 items-center text-xs font-medium capitalize",
                                                    {
                                                        "text-primary":
                                                            memoStatusFilter ===
                                                            status,
                                                    }
                                                )}
                                                onClick={() =>
                                                    handleSetMemoStatusFilter(
                                                        status
                                                    )
                                                }
                                            >
                                                {status}
                                                {memoStatusFilter === status ? (
                                                    <CheckPrimaryColorIcon />
                                                ) : null}
                                            </DropdownItem>
                                        )
                                    )}
                                </DropdownContent>
                            </Dropdown>

                            <Popover>
                                <PopoverTrigger asChild>
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium self-start md:self-center"
                                    >
                                        Date
                                        <ChevronDownIcon
                                            stroke={APP_COLORS.COLOR_BLACK}
                                        />
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent
                                    align="end"
                                    className="h-auto w-96 px-16 pt-6 z-20"
                                >
                                    <DatePicker
                                        label="From"
                                        placeholderText="MM/DD/YYYY"
                                        selected={fromDateFilter}
                                        onChange={handleFromDateFilterChange}
                                        className="hover:border-primary"
                                        maxDate={
                                            toDateFilter || new Date(Date.now())
                                        }
                                        isClearable
                                    />
                                    <div className="mt-24 mb-24 hover:border-primary">
                                        <DatePicker
                                            label="To"
                                            placeholderText="MM/DD/YYYY"
                                            selected={toDateFilter}
                                            onChange={handleToDateFilterChange}
                                            className="hover:border-primary"
                                            minDate={fromDateFilter}
                                            maxDate={new Date(Date.now())}
                                            isClearable
                                        />
                                    </div>
                                </PopoverContent>
                            </Popover>
                        </div>
                        {canAddMemo && (
                            <Button
                                aria-label="AddMemo"
                                onClick={() => toggleModalInView("add_memo")}
                            >
                                Add memo
                            </Button>
                        )}
                    </div>
                </div>
                <TableFiltersDisplay
                    appliedFilters={[
                        {
                            key: "category[]",
                            values: categoryFilter,
                        },
                    ]}
                    wrapperClass="mt-12"
                />
            </div>

            {isLoading && (
                <Skeleton
                    type="table"
                    containerTestId="memos-loader"
                    width="100%"
                />
            )}
            {error && error?.response?.status !== 404 && (
                <ListState
                    isError
                    listHeader=""
                    stateHelperText="Try reloading this page to solve this issue"
                    errorMsg={
                        error.response?.data.message ||
                        `Cannot display client memos at this time please try again later`
                    }
                />
            )}

            <>
                {data && isSuccess && data.data.length === 0 && (
                    <ListState
                        listHeader=""
                        isError={false}
                        stateHelperText="Client memos will appear here when added"
                        emptyMessage="No memo yet"
                        emptyBtnProps={{
                            showButton: !hideMemoButton,
                            buttonText: "Add memo",
                            onButtonClick: () => toggleModalInView("add_memo"),
                        }}
                    />
                )}
                {data && isSuccess && data.data.length > 0 && (
                    <>
                        <div className={styles.categories_wrapper}>
                            <div className={styles.categories}>
                                {practice?.roles?.length === 1 &&
                                practice?.roles?.[0] === UserRole.PROVIDER ? (
                                    <div className={styles.tagandcategory}>
                                        <span
                                            className={cn(
                                                styles.category,
                                                styles.category_notes
                                            )}
                                        />
                                        <p>Notes</p>
                                    </div>
                                ) : (
                                    categoriesList.map((category) => (
                                        <div
                                            key={category}
                                            className={styles.tagandcategory}
                                        >
                                            <span
                                                className={cn(
                                                    styles.category,
                                                    styles[
                                                        `category_${category}`
                                                    ],
                                                    {
                                                        [styles.category_client_services]:
                                                            category ===
                                                            ClientMemoCategories.CLIENT_SERVICE,
                                                    }
                                                )}
                                            />
                                            <p>{category}</p>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                        <div className={styles.list_web}>
                            <Table
                                data={data.data}
                                columns={columns}
                                hasPagination={data.total_count > 20}
                                pagination={
                                    <Pagination
                                        totalCount={data.total_count}
                                        totalPages={data.total_pages}
                                        currentPage={pageFilter}
                                        onPageChange={handlePageChange}
                                        onLimitChange={handleLimitChange}
                                        limit={limitFilter}
                                    />
                                }
                            />
                        </div>

                        <div className="block md:hidden" role="table">
                            <div className="flex flex-col gap-y-12">
                                {data.data.map((memo) => (
                                    <MobileListItem
                                        key={memo.memo_id}
                                        showCheckButton={false}
                                        topChildren={
                                            <div className="flex items-center gap-x-8 text-base font-semibold capitalize">
                                                <p
                                                    className={cn(
                                                        styles.category,
                                                        styles[
                                                            `category_${memo.category.toLowerCase()}`
                                                        ],
                                                        {
                                                            [styles.category_client_services]:
                                                                memo.category.toLowerCase() ===
                                                                ClientMemoCategories.CLIENT_SERVICE,
                                                        }
                                                    )}
                                                />
                                                {formatDate(memo.date_created)}
                                            </div>
                                        }
                                        bottomChildren={
                                            <div className="flex flex-col gap-y-8 w-full items-start">
                                                <div className="flex items-center w-full text-left gap-x-4">
                                                    <div className="basis-1/2">
                                                        <span className="block font-medium text-xss uppercase">
                                                            type
                                                        </span>
                                                        <span className="block text-gray font-semibold text-xs">
                                                            {makeStringFirstLetterCapital(
                                                                memo.type
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className="basis-1/2">
                                                        <span className="block mb-4 font-medium text-xss uppercase">
                                                            Author
                                                        </span>
                                                        <span className="block text-gray font-semibold text-xs">
                                                            {`${memo.author?.first_name} ${memo.author?.last_name}`}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="w-full text-left">
                                                    <div className="basis-1/2">
                                                        <span className="block font-medium text-xss uppercase">
                                                            status
                                                        </span>
                                                        <span className="block text-gray font-semibold text-xs capitalize">
                                                            {removeEnumUnderscore(
                                                                memo.memo_status
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="flex items-center w-full text-left gap-x-4">
                                                    <div className="flex-1">
                                                        <span className="block font-medium text-xss uppercase">
                                                            description
                                                        </span>
                                                        <div className="block *!text-gray *!font-semibold *!text-xs">
                                                            <ViewEditorText
                                                                text={
                                                                    memo.description
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    {memo.type === "user" &&
                                                        (loggedInUser.data
                                                            ?.user_id ===
                                                            memo.author
                                                                ?.user_id ||
                                                            practice?.roles?.includes(
                                                                UserRole.SUPER_ADMIN
                                                            )) && (
                                                            <div className="w-auto shrink-0 ml-12">
                                                                <Dropdown>
                                                                    <DropdownTrigger
                                                                        asChild
                                                                    >
                                                                        <MoreButton />
                                                                    </DropdownTrigger>
                                                                    <DropdownContent
                                                                        width="auto"
                                                                        align="end"
                                                                    >
                                                                        <DropdownItem
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.stopPropagation();
                                                                                setMemoId(
                                                                                    memo.memo_id
                                                                                );
                                                                                setMemoDescription(
                                                                                    memo.description
                                                                                );
                                                                                toggleModalInView(
                                                                                    "edit_memo"
                                                                                );
                                                                            }}
                                                                        >
                                                                            Edit
                                                                        </DropdownItem>
                                                                        <DropdownItem
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.stopPropagation();
                                                                                setMemoId(
                                                                                    memo.memo_id
                                                                                );
                                                                                toggleModalInView(
                                                                                    "confirmation"
                                                                                );
                                                                            }}
                                                                        >
                                                                            Delete
                                                                        </DropdownItem>
                                                                        {canEditMemoStatus && (
                                                                            <DropdownItem
                                                                                onClick={() =>
                                                                                    handleUpdateClientMemoStatus(
                                                                                        memo.memo_status ===
                                                                                            ClientMemoStatus.ACTIVE
                                                                                            ? ClientMemoStatus.PAUSED
                                                                                            : ClientMemoStatus.ACTIVE,
                                                                                        memo.memo_id
                                                                                    )
                                                                                }
                                                                            >
                                                                                {`Set to ${
                                                                                    memo.memo_status ===
                                                                                    ClientMemoStatus.ACTIVE
                                                                                        ? ClientMemoStatus.PAUSED
                                                                                        : ClientMemoStatus.ACTIVE
                                                                                }`}
                                                                            </DropdownItem>
                                                                        )}
                                                                    </DropdownContent>
                                                                </Dropdown>
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                        }
                                    />
                                ))}
                            </div>

                            {data.total_count > 20 && (
                                <div className="pagination_mobile">
                                    <Pagination
                                        totalCount={data.total_count}
                                        totalPages={data.total_pages}
                                        currentPage={pageFilter}
                                        onPageChange={handlePageChange}
                                        limit={limitFilter}
                                        onLimitChange={handleLimitChange}
                                    />
                                </div>
                            )}
                        </div>
                    </>
                )}
            </>
        </>
    );
}
