import { format } from "date-fns";
import axios from "../utils/axios";
import { handleRemoveEmptyParamFromQueryParams } from "../utils/helpers";
import { ISuperviseesListFilters } from "../utils/types/client";
import {
    INewSessionNote,
    ISignNewNotePayload,
    INewAddendum,
    INotesFilterTypes,
    IApproveNotePayload,
    IRequestChangePayload,
    IChangeCode,
    IEditDraftNote,
    IExportNotePayload,
    ClientNoteParams,
    IChangeArchiveStatus,
} from "../utils/types/notes";

// Endpoint to create draft session note for a client
export async function addSessionNote(data: INewSessionNote) {
    const response = await axios.post(`/client/session-note`, data);
    return response.data;
}

// Endpoint to sign a client session note
export async function signSessionNote(payload: {
    noteId: string;
    data: ISignNewNotePayload;
}) {
    const response = await axios.post(
        `/client/session-note/${payload.noteId}/sign`,
        payload.data
    );
    return response.data;
}

// Endpoint to get AI analysis for a note
export async function fetchNoteAiAnalysis(noteId: string) {
    const response = await axios.get(`/ai/note-analysis/${noteId}`);
    return response.data;
}

// Endpoint to edit draft notes
export async function editDraftNote(payload: {
    noteId: string;
    data: IEditDraftNote;
}) {
    const response = await axios.patch(
        `client/session-note/${payload.noteId}`,
        payload.data
    );

    return response.data;
}

export async function fetchNoteActivities(noteId: string) {
    const response = await axios.get(`/client/session-note/${noteId}/activity`);
    return response.data;
}

// Endpoint to fetch clients notes
export async function fetchClientsNotes(
    clientId: string,
    data: ClientNoteParams
) {
    const response = await axios.get(`/client/${clientId}/notes`, {
        params: {
            ...data,
        },
    });
    return response.data;
}
// Endpoint to add note addendum
export async function addNoteAddendum(noteId: string, data: INewAddendum) {
    const response = await axios.post(
        `/client/session-note/${noteId}/addendum`,
        data
    );
    return response.data;
}

// Endpoint for deleting a draft note
export async function deleteDraftNote(noteIds: string[]) {
    const response = await axios.delete(`/client/session-note/`, {
        data: { note_ids: noteIds },
    });
    return response.data;
}

// Endpoint to fetch addendums
export async function fetchAddendums(noteId: string, limit: number) {
    const response = await axios.get(
        `/client/session-note/${noteId}/addendum?limit=${limit}`
    );
    return response.data;
}

// Endpoint to fetch client's session id
export async function fetchSessionNote(clientId: string, noteId: string) {
    const response = await axios.get(
        `/client/${clientId}/session-note/${noteId}`
    );
    return response.data;
}

// Endpoint to fetch notes list
export async function fetchNotesList(
    filters: INotesFilterTypes,
    providerId: string
) {
    const response = await axios.get(`/notes`, {
        params: handleRemoveEmptyParamFromQueryParams({
            provider_id: providerId,
            ...filters,
            from_date: filters.from_date
                ? format(filters.from_date, "yyyy-MM-dd")
                : null,
            to_date: filters.to_date
                ? format(filters.to_date, "yyyy-MM-dd")
                : null,
        }),
    });

    return response.data;
}

// Endpoint to supervisee notes list
export async function fetchSuperviseeNotesList(
    providerId: string,
    filters: ISuperviseesListFilters
) {
    const response = await axios.get(
        `/session-note/${providerId}/supervisees`,
        {
            params: handleRemoveEmptyParamFromQueryParams({
                ...filters,
                from_date: filters.from_date
                    ? format(filters.from_date, "yyyy-MM-dd")
                    : null,
                to_date: filters.to_date
                    ? format(filters.to_date, "yyyy-MM-dd")
                    : null,
            }),
        }
    );
    return response.data;
}

// Endpoint to approve and sign notes for supervisees
export async function approveSuperviseeNote(data: IApproveNotePayload) {
    const response = await axios.post(`/client/session-note/approve`, data);
    return response.data;
}

export async function requestNoteChanges(
    noteId: string,
    data: IRequestChangePayload
) {
    const response = await axios.post(
        `/client/session-note/${noteId}/change-request`,
        data
    );
    return response.data;
}

// Endpoint to change cpt code
export async function changeCptCode(data: IChangeCode) {
    const response = await axios.post(`/client/session-note/cpt-code`, data);
    return response.data;
}

// Endpoint to export notes
export async function exportNotes(data: IExportNotePayload) {
    const response = await axios.post(`/client/session-note/export`, data);
    return response.data;
}

// Endpoint to get exported pdfs from a job
export async function fetchExportedPdfs(exportJobId: string) {
    await axios
        .get(`/client/session-note/export/${exportJobId}`, {
            responseType: "blob",
        })
        .then((res) => {
            const href = window.URL.createObjectURL(res.data);

            const anchorElement = document.createElement("a");

            anchorElement.href = href;
            anchorElement.setAttribute(
                "download",
                `exported_notes_${exportJobId}.zip`
            );
            document.body.appendChild(anchorElement);
            anchorElement.click();

            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(href);
        });
}

// Endpoint to fetch note audit dashboard list
export async function fetchNoteAuditDashboardList(
    custom_date: string,
    sort_attribute?: string,
    sort_order?: string
) {
    const response = await axios.get(`/dashboard/note-audit`, {
        params: handleRemoveEmptyParamFromQueryParams({
            custom_date,
            sort_attribute,
            sort_order,
        }),
    });
    return response.data;
}

// Endpoint to archive note
export async function archiveNote(data: IChangeArchiveStatus) {
    const response = await axios.post(`/client/session-note/archive`, data);
    return response.data;
}

export async function resolveNoteChange(noteId: string) {
    const response = await axios.post(
        `/client/session-note/${noteId}/resolve-change-request`
    );
    return response.data;
}
