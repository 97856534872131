import { format } from "date-fns";
import axios from "../../../utils/axios";
import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";
import {
    IDeleteUnmatchedPaymentPayload,
    IMatchedPaymentListFilterTypes,
    IPaymentToMatchPayload,
    IRefreshPaymentStatusPayload,
    IRematchPayment,
    ISplitPaymentPayload,
    IUnmatchedPaymentFilterTypes,
    IUpdateInvoicePayload,
} from "../types/payments.types";

export async function fetchMatchedPayments(
    filters: IMatchedPaymentListFilterTypes
) {
    const response = await axios.get(`/invoice/matched_payments`, {
        params: handleRemoveEmptyParamFromQueryParams({
            ...filters,
            from_date: filters.from_date
                ? format(filters.from_date, "yyyy-MM-dd")
                : null,
            to_date: filters.to_date
                ? format(filters.to_date, "yyyy-MM-dd")
                : null,
        }),
    });
    return response.data;
}

export async function fetchUnMatchedPayments(
    filters: IUnmatchedPaymentFilterTypes
) {
    const response = await axios.get(`/invoice/unmatched_payments`, {
        params: handleRemoveEmptyParamFromQueryParams({
            ...filters,
            from_date: filters.from_date
                ? format(filters.from_date, "yyyy-MM-dd")
                : null,
            to_date: filters.to_date
                ? format(filters.to_date, "yyyy-MM-dd")
                : null,
        }),
    });
    return response.data;
}

// Endpoint to refresh payment status
export async function refreshPaymentStatus(
    payload: IRefreshPaymentStatusPayload
) {
    const response = await axios.patch(`/invoice/payment_update`, payload);

    return response.data;
}

export async function updateInvoiceStatus(payload: IUpdateInvoicePayload) {
    const response = await axios.patch(`/invoice`, payload);

    return response.data;
}

export async function deleteUnmatchedPayment(
    payload: IDeleteUnmatchedPaymentPayload
) {
    const response = await axios.delete(`/invoice/unmatched_payments`, {
        data: payload,
    });
    return response.data;
}

export async function matchPayment(payload: IPaymentToMatchPayload) {
    const response = await axios.post(`/invoice/match_payments`, payload);

    return response.data;
}

export async function rematchPayment(payload: IRematchPayment) {
    const response = await axios.patch(
        `/invoice/update_matched_payments`,
        payload
    );
    return response.data;
}

export async function splitPayment(payload: ISplitPaymentPayload) {
    const response = await axios.post(`/payment/split`, payload);
    return response.data;
}
