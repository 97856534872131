import * as React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Tabs } from "@jhool-io/fe-components";
import BasicInfo from "../../../../components/Clients/Client/BasicInfo/BasicInfo";
import PersonalInfo from "../../../../components/Clients/Client/PersonalInfo/PersonalInfo";
import Navbar from "../../../../components/Navbar/Navbar";
import Fees from "../../../../components/Clients/Client/Fees/Fees";
import { useFetchUserDetails } from "../../../../hooks/queries/user";
import { UserPermisions, UserRole } from "../../../../utils/types/user";
import { useDoesUserHavePermission } from "../../../../hooks/permissions";
import { useFetchTasks } from "../../../../hooks/queries/tasks";
import { TaskStatus } from "../../../../utils/types/tasks";
import DocumentsList from "../../../../modules/clients/components/DocumentsList/DocumentsList";
import Insurance from "../../../../modules/clients/components/Insurance/Insurance";
import ClientNotesList from "../../../../modules/clients/components/ClientNotesList/ClientNotesList";
import ClientMemoList from "../../../../modules/clients/components/ClientMemoList/ClientMemoList";
import ClientClaimsList from "../../../../modules/clients/components/ClientClaimsList/ClientClaimsList";
import {
    TaskCategory,
    TaskListSortAttribute,
} from "../../../../modules/tasks/types/tasks.types";
import ClientTasksList from "../../../../modules/clients/components/ClientTasksList/ClientTasksList";
import { AppSortOrder } from "../../../../utils/types";
import usePractice from "../../../../hooks/usePractice";

export default function Client() {
    const [searchParams, setSearchParams] = useSearchParams();

    const loggedInUser = useFetchUserDetails();

    const { practice } = usePractice();

    const params = useParams();
    const { clientId } = params;

    const tabInUrl = searchParams.get("tab");

    const taskTabSubTabInUrl = searchParams.get("task_subtab");

    const { data, isLoading, error } = useFetchTasks({
        client_id: clientId || "",
        assignee_user_id: loggedInUser.data?.user_id || "",
        status:
            taskTabSubTabInUrl === "all_tasks"
                ? [
                      TaskStatus.COMPLETED,
                      TaskStatus.IN_PROGRESS,
                      TaskStatus.NOT_STARTED,
                  ]
                : [TaskStatus.IN_PROGRESS, TaskStatus.NOT_STARTED],
        category: [
            TaskCategory.WRITE_SESSION_NOTE,
            TaskCategory.MAKE_REQUESTED_CHANGE,
            TaskCategory.USER_CREATED,
            TaskCategory.WRITE_FOLLOW_UP_SAFETY_NOTE,
        ],
        sort_attribute: TaskListSortAttribute.DUE_DATE,
        sort_order: AppSortOrder.ASC,
    });

    let taskCount;
    if (isLoading) taskCount = 0;
    if (error) taskCount = 0;
    if (data) taskCount = data.total_count;

    const canSeePaymentTab = useDoesUserHavePermission([
        UserPermisions.CLIENT_INFO_ALL_ADMIN,
        UserPermisions.USER_INFO_ALL_ADMIN,
        UserPermisions.CLIENT_INFO_INVOICE_READ,
        UserPermisions.CLIENT_INFO_PAYMENTS_READ,
    ]);

    const userIsProviderOrAuditor =
        practice?.roles?.includes(UserRole.PROVIDER) ||
        practice?.roles?.includes(UserRole.AUDITOR);

    const userCanViewPersonalInfoTab = useDoesUserHavePermission([
        UserPermisions.CLIENT_INFO_ALL_ADMIN,
        UserPermisions.CLIENT_INFO_PROVIDER_READ,
        UserPermisions.CLIENT_INFO_PERSONAL_WRITE,
        UserPermisions.CLIENT_INFO_ALL_WRITE,
        UserPermisions.CLIENT_INFO_PROVIDER_WRITE,
    ]);

    const singleBillViewStatusParams = practice?.roles?.includes(
        UserRole.CLIENT_SUPPORT
    )
        ? "&invoice_status[]=pending_resolution"
        : "";

    const handleTabInView = (tab: string) => {
        if (tab === "claims") {
            return setSearchParams(`tab=${tab}${singleBillViewStatusParams}`);
        }
        return setSearchParams(`tab=${tab}`);
    };

    const tabItems = [
        {
            label: "General Info",
            key: "personal-info",
            children: <PersonalInfo />,
            hide: !userCanViewPersonalInfoTab,
            onClick: () => handleTabInView("personal-info"),
        },
        {
            label: "Insurance",
            key: "insurance",
            children: <Insurance />,
            hide: userIsProviderOrAuditor,
            onClick: () => handleTabInView("insurance"),
        },
        {
            label: "Fees",
            key: "fees",
            children: <Fees />,
            hide: userIsProviderOrAuditor,
            onClick: () => handleTabInView("fees"),
        },
        {
            label: "Claims",
            key: "claims",
            children: <ClientClaimsList />,
            hide:
                !canSeePaymentTab ||
                practice?.roles?.includes(UserRole.AUDITOR),
            onClick: () => handleTabInView("claims"),
        },
        {
            label: "Memo",
            key: "memo",
            children: <ClientMemoList />,
            onClick: () => handleTabInView("memo"),
        },
        {
            label: "Session Notes",
            key: "session_notes",
            children: <ClientNotesList />,
            onClick: () => handleTabInView("session_notes"),
        },
        {
            label: "Documents",
            key: "documents",
            children: <DocumentsList />,
            hide: userIsProviderOrAuditor,
            onClick: () => handleTabInView("documents"),
        },
        {
            label: "Tasks",
            key: "tasks",
            children: <ClientTasksList />,
            count: taskCount,
            onClick: () => handleTabInView("tasks"),
        },
    ];

    return (
        <>
            <Navbar title="Client" />
            <div className="page">
                <BasicInfo />
                <Tabs
                    items={tabItems.filter((item) => !item?.hide)}
                    size="medium"
                    className="w-full rounded-none"
                    defaultActiveKey={
                        tabInUrl ||
                        tabItems.filter((item) => !item?.hide)[0].key
                    }
                />
            </div>
        </>
    );
}
