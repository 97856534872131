import { IAppCustomDates, ISortTable } from "../../../utils/types";

export interface ServicesResponse {
    date_of_service: string;
    place_of_service: string;
    emg: string;
    chp: string;
    modifier: string;
    diagnosis_pointer: string;
    charges: string;
    units: string;
    family_plan: string;
    id_qual: string;
    provider_id: string;
}

export enum ClaimStatus {
    ACKNOWLEDGED = "acknowledged",
    APPEALED = "appealed",
    CLOSED = "closed",
    CODED = "coded",
    DENIED = "denied",
    PAID = "paid",
    PATIENT_PAYMENT_COLLECTION = "patient_payment_collection",
    PENDING = "pending",
    READY_FOR_SUBMISSION = "ready_for_submission",
    RECEIVED = "received",
    REJECTED = "rejected",
    REVERSED = "reversed",
    SUBMITTED = "submitted",
}

export enum ClaimsHasRemit {
    SHOW_CLAIMS_WITH_REMIT = "show_claims_with_remit",
    HIDE_CLAIMS_WITH_REMIT = "show_claims_without_remit",
}

export interface IClaim {
    claim_id: string;
    client_id: string;
    invoice_id: string;
    note_id: string;
    claim_status: ClaimStatus;
    insurance_provider_id: string;
    insurance_name: string;
    status_notes: string;
    status_updated_at: string;
    generated_on: string;
    external_reference_id?: string;
    practice_id: string;
    updated_at: string;
    submitted_on: string;
    submitted_by: string;
    charge_amount: number;
    date_of_service: string;
    client_name: string;
    cpt_code: string;
    has_remits: boolean;
    provider_name: string;
    appointment_type: string;
    claim_content?: {
        patientCity?: string;
        patientFirstName?: string;
        patientLastName?: string;
        patientRelationshipToSubscriber?: string;
        patientState?: string;
        referringProviderNPI?: string;
        subscriberCity?: string;
        subscriberFirstName?: string;
        subscriberLastName?: string;
    };
    accepts_assignment?: boolean;
}

export interface ClaimsServiceDetails extends ISortTable {
    fromDate: string;
    toDate: string;
    placeOfServiceCode: string;
    isEmergency: string;
    procedureCode: string;
    modifiers: string[];
    diagnosisPointers: [string];
    charge: number;
    units: number;
    isEPSDT: string;
    isFamilyPlan: string;
    renderingProviderNumQualifier: string;
    renderingProviderNumber: string;
    renderingProviderNPI: string | null;
    notes: string;
}
export interface SingleClaimResponse {
    message: string;
    data: {
        claim_id: string;
        client_id: string;
        invoice_id: string;
        claim_status: string;
        note_id: string;
        insurance_provider_id: string;
        status_notes: string;
        status_updated_at: string;
        generated_on: string;
        external_reference_id: string;
        practice_id: string;
        updated_at: string;
        submitted_on: string | null;
        submitted_by: string | null;
        charge_amount: string;
        claim_content: {
            insuranceType: string;
            totalCharge: number;
            amountPaid: number;
            subscriberId: string;
            patientLastName: string;
            patientFirstName: string;
            patientMiddleName: string | null;
            patientSuffix: string;
            patientBirthdate: string;
            patientSex: string;
            subscriberLastName: string;
            subscriberFirstName: string;
            subscriberMiddleName: string;
            subscriberSuffix: string;
            patientAddress: string;
            patientCity: string;
            patientState: string;
            patientZip: string;
            patientPhone: string;
            patientRelationshipToSubscriber: ClaimRelationshipToSubscriber;
            subscriberAddress: string;
            subscriberCity: string;
            subscriberState: string;
            subscriberZip: string;
            subscriberPhone: string;
            reservedForNUCCUse: string;
            otherSubscriberLastName: string;
            otherSubscriberFirstName: string;
            otherSubscriberMiddleName: string;
            otherSubscriberSuffix: string;
            otherSubscriberId: string;
            otherSubscriberReserved: string;
            otherSubscriberReserved2: string;
            otherInsurancePlanProgram: string;
            isConditionRelatedToEmployment: string;
            isConditionAutoAccidentRelated: string;
            autoAccidentState: string;
            isConditionOtherAccidentRelated: string;
            claimCodes: string;
            subscriberPolicyGroup: string;
            subscriberBirthdate: string;
            subscriberSex: string;
            otherClaimIdQualifier: string;
            otherClaimId: string;
            insuranceGroupName: string;
            patientSignature: string;
            patientSignatureDate: string;
            subscriberSignature: string;
            illnessDate: string;
            illnessDateQualifier: string;
            otherDate: string;
            otherDateQualifier: string;
            unableToWorkFromDate: string;
            unableToWorkToDate: string;
            referringProviderLastName: string;
            referringProviderFirstName: string | null;
            referringProviderMiddleName: string | null;
            referringProviderSuffix: string;
            referringProviderNumQual: string;
            referringProviderAdditionalId: string;
            referringProviderAdditionalIdQual: string;
            referringProviderNPI: string | null;
            admissionFromDate: string;
            admissionToDate: string;
            additionalClaimInfo: string;
            outsideLabCharge: string;
            diagnosisCode1: string;
            diagnosisCode2: string;
            diagnosisCode3: string;
            diagnosisCode4: string;
            diagnosisCode5: string;
            diagnosisCode6: string;
            diagnosisCode7: string;
            diagnosisCode8: string;
            diagnosisCode9: string;
            diagnosisCode10: string;
            diagnosisCode11: string;
            diagnosisCode12: string;
            icdIndicator: string;
            resubmissionCode: string;
            originalClaimReferenceNumber: string;
            priorAuthorizationNumber: string;
            federalTaxId: string;
            federalTaxIdType: string;
            claimReferenceOrPatientAcctNo: string;
            acceptAssignment: string;
            renderingProviderLastName: string;
            renderingProviderFirstName: string;
            renderingProviderMiddleName: string | null;
            renderingProviderSuffix: string;
            renderingProviderSignatureDate: string;
            facilityName: string;
            facilityNPI: string;
            facilityProviderNumber: string;
            facilityAddress1: string;
            facilityAddress2: string;
            facilityCity: string;
            facilityState: string;
            facilityZip: string;
            billingProviderPhone: string;
            billingProviderName: string;
            billingProviderAddress1: string;
            billingProviderAddress2: string;
            billingProviderCity: string;
            billingProviderState: string;
            billingProviderZipCode: string;
            billingProviderNPI: string;
            billingProviderNumber: string;
            billingProviderIdQualifier: string;
            payerName: string | null;
            payerAddress1: string | null;
            payerAddress2: string | null;
            payerCity: string | null;
            payerState: string | null;
            payerZip: string | null;
            cliaNumber: string;
            serviceDetails: ClaimsServiceDetails[];
        };
    };
}

export interface IUpdateClaimStatus {
    claim_id: string;
    claim_status: ClaimStatus;
}

export interface IBulkUpdateClaims {
    claim_ids: string[];
    claim_status: ClaimStatus;
    status_notes?: string;
}

export interface IUpdateClaimContent {
    claim_id: string;
    claim_content_to_update: IClaimDetailsContent;
}

export interface ICreateDraft {
    note_id: string;
    overwrite_existing?: boolean;
}

export interface IBulkCreateClaim {
    note_ids: string[];
}

export enum ClaimRelationshipToSubscriber {
    Self = "18",
    Spouse = "01",
    Child = "19",
    Other = "G8",
}

export interface IClaimsFilters {
    search_string?: string;
    insurance_provider_id?: string;
    claim_statuses?: string[];
    note_dos_from?: string | null;
    note_dos_to?: string | null;
    page?: number;
    limit?: number;
    sort_attribute?: string;
    sort_order?: string;
    custom_date?: IAppCustomDates;
    has_remits?: string;
    tab_name?: string;
}

export interface ICreateDraftResponse {
    claim_id: string;
    claim: {
        claim_id: string;
        client_id: string;
        invoice_id: string;
        claim_status: string;
        note_id: string;
        insurance_provider_id: string;
        status_notes: string;
        status_updated_at: string;
        generated_on: string;
        external_reference_id: string;
        practice_id: string;
        updated_at: string;
        submitted_on: string | null;
        submitted_by: string | null;
        charge_amount: string;
        claim_content: {
            insuranceType: string;
            totalCharge: number;
            amountPaid: number;
            subscriberId: string;
            patientLastName: string;
            patientFirstName: string;
            patientMiddleName: string | null;
            patientSuffix: string;
            patientBirthdate: string;
            patientSex: string;
            subscriberLastName: string;
            subscriberFirstName: string;
            subscriberMiddleName: string;
            subscriberSuffix: string;
            patientAddress: string;
            patientCity: string;
            patientState: string;
            patientZip: string;
            patientPhone: string;
            patientRelationshipToSubscriber: ClaimRelationshipToSubscriber;
            subscriberAddress: string;
            subscriberCity: string;
            subscriberState: string;
            subscriberZip: string;
            subscriberPhone: string;
            reservedForNUCCUse: string;
            otherSubscriberLastName: string;
            otherSubscriberFirstName: string;
            otherSubscriberMiddleName: string;
            otherSubscriberSuffix: string;
            otherSubscriberId: string;
            otherSubscriberReserved: string;
            otherSubscriberReserved2: string;
            otherInsurancePlanProgram: string;
            isConditionRelatedToEmployment: string;
            isConditionAutoAccidentRelated: string;
            autoAccidentState: string;
            isConditionOtherAccidentRelated: string;
            claimCodes: string;
            subscriberPolicyGroup: string;
            subscriberBirthdate: string;
            subscriberSex: string;
            otherClaimIdQualifier: string;
            otherClaimId: string;
            insuranceGroupName: string;
            patientSignature: string;
            patientSignatureDate: string;
            subscriberSignature: string;
            illnessDate: string;
            illnessDateQualifier: string;
            otherDate: string;
            otherDateQualifier: string;
            unableToWorkFromDate: string;
            unableToWorkToDate: string;
            referringProviderLastName: string;
            referringProviderFirstName: string | null;
            referringProviderMiddleName: string | null;
            referringProviderSuffix: string;
            referringProviderNumQual: string;
            referringProviderAdditionalId: string;
            referringProviderAdditionalIdQual: string;
            referringProviderNPI: string | null;
            admissionFromDate: string;
            admissionToDate: string;
            additionalClaimInfo: string;
            outsideLabCharge: string;
            diagnosisCode1: string;
            diagnosisCode2: string;
            diagnosisCode3: string;
            diagnosisCode4: string;
            diagnosisCode5: string;
            diagnosisCode6: string;
            diagnosisCode7: string;
            diagnosisCode8: string;
            diagnosisCode9: string;
            diagnosisCode10: string;
            diagnosisCode11: string;
            diagnosisCode12: string;
            icdIndicator: string;
            resubmissionCode: string;
            originalClaimReferenceNumber: string;
            priorAuthorizationNumber: string;
            federalTaxId: string;
            federalTaxIdType: string;
            claimReferenceOrPatientAcctNo: string;
            acceptAssignment: string;
            renderingProviderLastName: string;
            renderingProviderFirstName: string;
            renderingProviderMiddleName: string | null;
            renderingProviderSuffix: string;
            renderingProviderSignatureDate: string;
            facilityName: string;
            facilityNPI: string;
            facilityProviderNumber: string;
            facilityAddress1: string;
            facilityAddress2: string;
            facilityCity: string;
            facilityState: string;
            facilityZip: string;
            billingProviderPhone: string;
            billingProviderName: string;
            billingProviderAddress1: string;
            billingProviderAddress2: string;
            billingProviderCity: string;
            billingProviderState: string;
            billingProviderZipCode: string;
            billingProviderNPI: string;
            billingProviderNumber: string;
            billingProviderIdQualifier: string;
            payerName: string | null;
            payerAddress1: string | null;
            payerAddress2: string | null;
            payerCity: string | null;
            payerState: string | null;
            payerZip: string | null;
            cliaNumber: string;
            serviceDetails: ClaimsServiceDetails[];
        };
    };
}

export interface IBulkCreateClaimResponse {
    regenerated_claims: {
        note_id: string;
        claim_id: string;
    }[];
    unregenerated_claims: {
        note_id: string;
        claim_id: string;
        reason: string;
    }[];
}

export interface IGetClaimFilter {
    claim_id?: string;
    note_id?: string;
}

export interface IDeleteDraftClaimsResponse {
    deleted_claim_ids: string[];
    undeleted_claim_ids: string[];
}

export enum IClaimStatus {
    DENIED = "denied",
    PAID = "paid",
    PENDING = "pending",
    RECEIVED = "received",
    PATIENT_PAYMENT_COLLECTION = "patient_payment_collection",
    CLOSED = "closed",
    SUBMITTED = "submitted",
    APPEALED = "appealed",
    CODED = "coded",
    REJECTED = "rejected",
    ACKNOWLEDGED = "acknowledged",
    READY_FOR_SUBMISSION = "ready_for_submission",
    REVERSED = "reversed",
}

export interface IClaimFilterTypes {
    claim_id: string;
    claim_ref_no?: string;
    note_id?: string;
    invoice_id?: string;
}
export interface IClaimDetails {
    claim_id: string;
    client_id: string;
    invoice_id: string;
    claim_status: IClaimStatus;
    insurance_provider_id: string;
    status_notes: string;
    status_updated_at: Date;
    generated_on: Date;
    external_reference_id: string;
    practice_id: string;
    updated_at: string;
    submitted_on: string;
    submitted_by: string;
    charge_amount: string;
    claim_content: IClaimDetailsContent;
}

export enum IClaimInsuranceType {
    MEDICARE = "medicare",
    MEDICAID = "medicaid",
    TRICARE = "tricare",
    CHAMPVA = "champva",
    GROUP_HEALTH_PLAN = "group_health_plan",
    FECA_BLK_LNG = "feca_blk_lung",
    OTHER = "other",
}

export interface IClaimDetailsContent {
    insuranceType: IClaimInsuranceType;
    subscriberId: string;
    patientLastName: string;
    patientFirstName: string;
    patientMiddleName: string;
    patientSuffix: string;
    patientBirthdate: string;
    patientSex: string;
    subscriberLastName: string;
    subscriberFirstName: string;
    subscriberMiddleName: string;
    subscriberSuffix: string;
    patientAddress: string;
    patientCity: string;
    patientState: string;
    patientZip: string;
    patientPhone: string;
    patientRelationshipToSubscriber: "18" | "01" | "19" | "G8";
    subscriberAddress: string;
    subscriberCity: string;
    subscriberState: string;
    subscriberZip: string;
    subscriberPhone: string;
    reservedForNUCCUse: string;
    otherSubscriberLastName: string;
    otherSubscriberFirstName: string;
    otherSubscriberMiddleName: string;
    otherSubscriberSuffix: string;
    otherSubscriberId: string;
    otherSubscriberReserved: string;
    otherSubscriberReserved2: string;
    otherInsurancePlanProgram: string;
    isConditionRelatedToEmployment: string;
    isConditionAutoAccidentRelated: string;
    autoAccidentState: string;
    isConditionOtherAccidentRelated: string;
    claimCodes: string;
    subscriberPolicyGroup: string;
    subscriberBirthdate: string;
    subscriberSex: string;
    otherClaimIdQualifier: string;
    otherClaimId: string;
    insuranceGroupName: string;
    patientSignature: string;
    patientSignatureDate: string;
    subscriberSignature: string;
    illnessDate: string;
    illnessDateQualifier: string;
    otherDate: string;
    otherDateQualifier: string;
    unableToWorkFromDate: string;
    unableToWorkToDate: string;
    referringProviderLastName: string;
    referringProviderFirstName: string;
    referringProviderMiddleName: string;
    referringProviderSuffix: string;
    referringProviderNumQual: string;
    referringProviderAdditionalId: string;
    referringProviderAdditionalIdQual: string;
    referringProviderNPI: string;
    admissionFromDate: string;
    admissionToDate: string;
    additionalClaimInfo: string;
    outsideLabCharge: string;
    diagnosisCode1: string;
    diagnosisCode2?: string;
    diagnosisCode3?: string;
    diagnosisCode4?: string;
    diagnosisCode5?: string;
    diagnosisCode6?: string;
    diagnosisCode7?: string;
    diagnosisCode8?: string;
    diagnosisCode9?: string;
    diagnosisCode10?: string;
    diagnosisCode11?: string;
    diagnosisCode12?: string;
    icdIndicator: string;
    resubmissionCode: string;
    originalClaimReferenceNumber: string;
    priorAuthorizationNumber: string;
    federalTaxId: string;
    federalTaxIdType: string;
    claimReferenceOrPatientAcctNo: string;
    acceptAssignment: string;
    renderingProviderLastName: string;
    renderingProviderFirstName: string;
    renderingProviderMiddleName: string;
    renderingProviderSuffix: string;
    renderingProviderSignatureDate: string;
    facilityName: string;
    facilityNPI: string;
    facilityProviderNumber: string;
    facilityAddress1: string;
    facilityAddress2: string;
    facilityCity: string;
    facilityState: string;
    facilityZip: string;
    billingProviderPhone: string;
    billingProviderName: string;
    billingProviderAddress1: string;
    billingProviderAddress2: string;
    billingProviderCity: string;
    billingProviderState: string;
    billingProviderZipCode: string;
    billingProviderNPI: string;
    billingProviderNumber: string;
    billingProviderIdQualifier: string;
    payerName: string;
    payerAddress1: string;
    payerAddress2: string;
    payerCity: string;
    payerState: string;
    payerZip: string;
    cliaNumber: string;
    amountPaid: number;
    totalCharge: number;
    serviceDetails: IClaimsSingleService[];
}

export interface IClaimsSingleService {
    fromDate?: string;
    toDate?: string;
    placeOfServiceCode?: string;
    isEmergency?: string;
    procedureCode?: string;
    modifiers?: string[];
    diagnosisPointers?: string[];
    charge?: number;
    units?: number;
    isEPSDT?: string;
    isFamilyPlan?: string;
    renderingProviderNumQualifier?: string;
    renderingProviderNumber?: string;
    renderingProviderNPI?: string;
    notes?: string;
}
