import * as React from "react";
import { onValue, query, ref } from "firebase/database";
import { rtdb } from "../../firebase";
import usePractice from "../../hooks/usePractice";
import { AnnouncementContext } from "./AnnouncementContext";
import { IAnnouncement } from "../../modules/announcements/types/announcement";

interface AnnouncementProviderProps {
    children: React.ReactNode;
}

export default function AnnouncementProvider({
    children,
}: AnnouncementProviderProps) {
    const { practice } = usePractice();

    const previousClearedTime = localStorage.getItem(
        `clearedBannerAt_${practice?.practice_id}`
    );

    const [clearedAt, setClearedAt] = React.useState<string | null>(
        () => previousClearedTime
    );
    const [appAnnoucements, setAppAnnouncements] = React.useState<
        IAnnouncement[]
    >([]);

    const memoizedValues = React.useMemo(() => {
        const handleClearedAtValue = () => {
            localStorage.setItem(
                `clearedBannerAt_${practice?.practice_id}`,
                new Date(Date.now()).toISOString()
            );

            setClearedAt(new Date(Date.now()).toISOString());
        };

        return {
            clearedBannerAt: clearedAt,
            announcements: appAnnoucements,
            handleClearedAtValue,
        };
    }, [appAnnoucements, clearedAt, practice?.practice_id]);

    React.useEffect(() => {
        const announcementsRef = query(ref(rtdb, "/announcements"));
        onValue(announcementsRef, (snapShot) => {
            const announcements = snapShot.val();
            if (announcements) {
                const keys = Object.keys(announcements);
                let newAnnouncements: IAnnouncement[] = [];

                keys.forEach((a) => {
                    newAnnouncements = [
                        {
                            ...announcements[a],
                            id: a,
                        },
                        ...newAnnouncements,
                    ];
                });

                setAppAnnouncements(newAnnouncements);
            }
        });
    }, []);

    return (
        <AnnouncementContext.Provider value={memoizedValues}>
            {children}
        </AnnouncementContext.Provider>
    );
}
