/* eslint-disable react/no-unstable-nested-components */
import { Button, Checkbox, SearchInput, Table } from "@jhool-io/fe-components";
import * as React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { ClaimStatus, IClaim } from "../../../claims/types/claims.types";
import {
    formatDate,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    showMoneyInAppFormat,
} from "../../../../utils/helpers";
import { useDebounce } from "../../../../hooks/helpers";
import { useFetchClaims } from "../../../claims/hooks/claims.queries";
import ListState from "../../../../components/ListState/ListState";
import { useAddRemitClaims } from "../../hooks/remits.mutations";
import useToast from "../../../../hooks/useToast";
import { IClaimsReport } from "../../types/remits.types";
import Skeleton from "../../../../components/Skeleton/Skeleton";

interface Props {
    setShowAddRemitModal: (value: boolean) => void;
}

export default function SearchRemitClaims({ setShowAddRemitModal }: Props) {
    const params = useParams();
    const remitId = params.remitId || "";
    const [rowSelection, setRowSelection] = React.useState({});

    const [selectedItems, setSelectedItems] = React.useState([] as IClaim[]);
    const [selectedClaimIds, setSelectedClaimIds] = React.useState<string[]>(
        []
    );
    const [searchString, setSearchString] = React.useState("");

    const queryClient = useQueryClient();

    // Toast for success and error states
    const { toast } = useToast();

    const addRemitClaims = useAddRemitClaims(remitId);

    const searchStringDebouncedValue = useDebounce(searchString, 500);

    const { data, isFetching, isSuccess, error } = useFetchClaims(
        {
            claim_statuses: [
                ClaimStatus.ACKNOWLEDGED,
                ClaimStatus.APPEALED,
                ClaimStatus.CLOSED,
                ClaimStatus.CODED,
                ClaimStatus.DENIED,
                ClaimStatus.PAID,
                ClaimStatus.PATIENT_PAYMENT_COLLECTION,
                ClaimStatus.PENDING,
                ClaimStatus.READY_FOR_SUBMISSION,
                ClaimStatus.RECEIVED,
                ClaimStatus.REJECTED,
                ClaimStatus.REVERSED,
                ClaimStatus.SUBMITTED,
            ],
            search_string: searchStringDebouncedValue,
        },
        Boolean(searchStringDebouncedValue)
    );

    const memoizedTableData = React.useMemo(() => {
        return [
            ...selectedItems,
            ...(data?.data || []).filter(
                (item) => !selectedClaimIds.includes(item.claim_id)
            ),
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.data]);

    const buildSuccessMessage = (
        addedClaims: string[],
        unAddedClaims: IClaimsReport[]
    ) => {
        const addedClaimsMessage =
            addedClaims.length > 0
                ? `${addedClaims.length} Claim(s) added`
                : "";
        const unAddedClaimsMessage =
            unAddedClaims.length > 0
                ? `${unAddedClaims.length} Claims(s) were not added because they have not been submitted`
                : "";
        const message = `Update complete: ${[
            addedClaimsMessage,
            unAddedClaimsMessage,
        ]
            .filter(Boolean)
            .join(", ")}`;
        return message;
    };

    const handleAddRemitClaims = () => {
        const payload = {
            claim_ids: selectedClaimIds || [],
        };
        addRemitClaims.mutate(payload, {
            onSuccess: (res) => {
                queryClient.removeQueries({ queryKey: ["remit-claims"] });
                queryClient.removeQueries({ queryKey: ["batch-remits"] });

                toast({
                    mode: "success",
                    message: `${buildSuccessMessage(
                        res.data.added_claims,
                        res.data.unadded_claims
                    )}`,
                });
                setShowAddRemitModal(false);
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message || "Could not add remit.",
                });
            },
        });
    };

    const columns: ColumnDef<IClaim>[] = [
        {
            id: "select",
            header: ({ table }) => (
                <Checkbox
                    label=""
                    name="row"
                    value="row"
                    isChecked={table.getIsAllRowsSelected()}
                    indeterminate={table.getIsSomeRowsSelected()}
                    handleChange={table.getToggleAllPageRowsSelectedHandler()}
                />
            ),
            cell: ({ row }) => (
                <Checkbox
                    handleChange={() => {
                        row.getToggleSelectedHandler();
                        setSelectedClaimIds((prev) => {
                            return !prev.includes(row.original.claim_id)
                                ? [...prev, row.original.claim_id]
                                : prev.filter(
                                      (val) => val !== row.original.claim_id
                                  );
                        });
                        setSelectedItems((prev) => {
                            return !prev.includes(row.original)
                                ? [...prev, row.original]
                                : prev.filter((val) => val !== row.original);
                        });
                    }}
                    indeterminate={row.getIsSomeSelected()}
                    label=""
                    name="row"
                    value="row"
                    isChecked={selectedClaimIds.includes(row.original.claim_id)}
                />
            ),
        },
        {
            accessorKey: "client_name",
            header: "Client Name",
        },
        {
            accessorKey: "date_of_service",
            header: "Date Of Service",
            cell: ({ row }) => formatDate(row.original.date_of_service),
        },
        {
            accessorKey: "cpt_code",
            header: "CPT code",
        },
        {
            accessorKey: "claim_number",
            header: "Claim ID",
        },
        {
            accessorKey: "charge_amount",
            header: "Amount",
            cell: ({ row }) => showMoneyInAppFormat(row.original.charge_amount),
        },
        {
            accessorKey: "insurance_name",
            header: "Insurance",
            cell: ({ row }) => (
                <span className="uppercase">
                    {makeStringFirstLetterCapital(row.original.insurance_name)}
                </span>
            ),
        },
        {
            accessorKey: "requested_service",
            header: "Service",
            cell: ({ row }) =>
                makeStringFirstLetterCapital(
                    removeEnumUnderscore(row.original.appointment_type)
                ) || "--",
        },
    ];

    return (
        <div className="px-16">
            <SearchInput
                placeholder="Search by client name or claim ID"
                containerClass="h-[32px] mb-12"
                onChange={(e) => setSearchString(e.target.value)}
            />

            {error && error?.response?.status !== 404 && (
                <ListState
                    isError
                    stateHelperText="Try reloading this page to solve this issue"
                    errorMsg="Cannot display claims at this time please try again later"
                />
            )}

            {selectedItems.length === 0 && isFetching && (
                <Skeleton
                    type="table"
                    containerTestId="search-client-list-loader"
                    count={6}
                />
            )}
            {!isFetching && data && isSuccess && data.data.length === 0 && (
                <ListState
                    stateHelperText="Search results will appear here"
                    isError={false}
                    emptyMessage="There are no claims for the current client you are searching for"
                />
            )}
            {selectedItems?.length > 0 ||
            (data && !isFetching && isSuccess && data.data.length > 0) ? (
                <>
                    <Table
                        columns={columns}
                        data={memoizedTableData}
                        setRowSelection={setRowSelection}
                        rowSelection={rowSelection}
                    />

                    <div className="flex items-center justify-end gap-12 mt-16">
                        <Button
                            variant="secondary"
                            onClick={() => setShowAddRemitModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button onClick={handleAddRemitClaims}>
                            Add selected claims
                        </Button>
                    </div>
                </>
            ) : (
                <div>
                    {!searchString && !isFetching && (
                        <div>
                            <div className="flex items-center justify-center flex-col my-[90px] gap-8 max-w-[180px] mx-auto">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="22"
                                    viewBox="0 0 22 22"
                                    fill="none"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M15.0231 16.6187C13.4986 17.8041 11.583 18.5101 9.50251 18.5101C4.53056 18.5101 0.5 14.4784 0.5 9.50503C0.5 4.53169 4.53056 0.5 9.50251 0.5C14.4745 0.5 18.505 4.53169 18.505 9.50503C18.505 11.5859 17.7994 13.5019 16.6145 15.0267L21.1699 19.578C21.6097 20.0173 21.6101 20.73 21.1709 21.1698C20.7317 21.6097 20.0192 21.6101 19.5794 21.1708L15.0231 16.6187ZM16.2544 9.50503C16.2544 13.235 13.2315 16.2588 9.50251 16.2588C5.77355 16.2588 2.75063 13.235 2.75063 9.50503C2.75063 5.77502 5.77355 2.75126 9.50251 2.75126C13.2315 2.75126 16.2544 5.77502 16.2544 9.50503Z"
                                        fill="#BCC2CE"
                                    />
                                </svg>
                                <p className="text-sm font-medium leading-8 text-center text-gray tracking-wide">
                                    Search for a client to add a claim manually
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
