import * as React from "react";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import { useFetchUserDetails } from "../../../../hooks/queries/user";
import { UserRole } from "../../../../utils/types/user";
import BillersDashboard from "../../pages/Billers";
import CliniciansDashboard from "../../pages/Clinicians";
import OtherUsersDashboard from "../../pages/OtherUsers";
import usePractice from "../../../../hooks/usePractice";

export default function DashboardToDisplay() {
    const { isLoading } = useFetchUserDetails();

    const { practice } = usePractice();

    if (isLoading) {
        return (
            <>
                <div className="bg-white py-0 px-12 md:px-32 border-b border-b-strokedark flex justify-end items-center h-[72px] gap-x-16">
                    <Skeleton className="h-[25px] w-[50px]" />
                    <Skeleton className="h-[25px] w-[30px]" />
                    <Skeleton className="h-[50px] w-[50px] rounded-full" />
                    <Skeleton className="h-[32px] w-[60px]" />
                </div>
                <div className="page">
                    <Skeleton
                        width="100%"
                        height="300px"
                        className="mb-32 rounded-r8"
                    />
                    <Skeleton
                        width="100%"
                        height="300px"
                        className="rounded-r8"
                    />
                </div>
            </>
        );
    }

    return (
        <>
            {practice?.roles?.includes(UserRole.PROVIDER) && (
                <CliniciansDashboard />
            )}
            {practice?.roles?.includes(UserRole.BILLER) && <BillersDashboard />}
            {!practice?.roles?.includes(UserRole.PROVIDER) &&
                !practice?.roles?.includes(UserRole.BILLER) && (
                    <OtherUsersDashboard />
                )}
        </>
    );
}
