import { ISortTable } from ".";
import {
    IClientFee,
    IClientMemo,
    IClientNote,
    IClientProviderHistory,
} from "../../modules/clients/types/clients.types";
import { ProviderStatus, SessionServiceType } from "./session";

export interface IClient {
    client_id: string;
    first_name: string;
    last_name: string;
    preferred_name: string;
    pronouns: string;
    age: number;
    contact: string;
    email: string;
    insurance_provider_name: string | null;
    self_pay: boolean | null;
    alt_email_sent: boolean | null;
    financial_assistance?: boolean;
    client_chart_number: string;
    client_status: ClientStatus;
}

export interface ICardHistory {
    trans_id: string;
    invoice_number: string;
    transaction_status: string;
    submit_time_utc: string;
    first_name: string;
    last_name: string;
    settlement_time_utc: string;
    settle_amount: number;
    payment_description: string;
}

// Clients list filter types
export interface IClientFilterTypes {
    page?: number;
    search_string?: string;
    insurance_provider_id?: string;
    provider_id?: string;
    limit?: number;
    sort_attribute?: string;
    sort_order?: string;
    financial_assistance?: string;
}

export interface ICardFilterTypes {
    page?: number;
    limit?: number;
}

export enum Pronouns {
    SHE_HER = "She/Her",
    HE_HIM = "He/Him",
    THEY_THEM = "They/Them",
    SHE_THEY = "She/They",
    THEY_THEM_HIM = "They/Them/Him",
}

export enum ClientCardTypes {
    DISCOVER_FINANCIAL = "Discover",
    AMERICAN_EXPRESS = "AmericanExpress",
    MASTERCARD = "MasterCard",
    CAPITAL_ONE = "Capital One",
    CHASE = "Chase",
    VISA = "Visa",
    DINERSCLUB = "DinersClub",
    JCB = "JCB",
}

export enum UserManagementActionOptions {
    MODIFY_USER_ROLE = "modify_user_role",
    RESET_USER_PASSWORD = "reset_user_password",
    DEACTIVATE_USER = "deactivate_user",
    DELETE_USER = "delete_user",
}

export enum PaymentCardStatus {
    VALIDATED = "validated",
    UNVALIDATED = "unvalidated",
}

export enum ClientInquiryMethod {
    PHONE = "phone",
    EMAIL = "email",
    WEBFORM = "webform",
    INTERNAL = "internal",
    PSYCH_TODAY = "psych_today",
    ZDOC = "zdoc",
    GOOD_THERAPY = "good_therapy",
    INSURANCE_CHECK = "insurance_check",
}

export enum ClientDocumentTypes {
    CONSENT_FORM = "consent form",
    INSURANCE_SCAN = "insurance scan",
    ID_CARD = "id_card",
    APPEAL = "appeal",
    AUTHORIZATION = "authorization",
    CORRESPONDENCE_WITH_PATIENTS = "correspondence_with_patients",
    EXPLANATION_OF_BENEFITS = "explanation_of_benefits",
    OTHER = "other",
}

export enum ClientRelationship {
    SINGLE = "single",
    MARRIED = "married",
    DIVORCED = "divorced",
    SEPARATED = "separated",
    COMMITTED = "committed",
    WIDOW_WIDOWER = "widow_widower",
    OPEN_RELATIONSHIP = "open_relationship",
    ENGAGED = "engaged",
}

export enum ClientGender {
    MALE = "male",
    FEMALE = "female",
    ANDROGYNE = "androgyne",
    BIGENDER = "bigender",
    GENDERQUEER = "genderqueer",
    PANGENDER = "pangender",
}

export enum ClientReligion {
    ISLAM = "islam",
    CHRISTIANITY = "christianity",
    JUDAISM = "judaism",
    BUDDHIDM = "buddhidm",
    HINDUISM = "hinduism",
    TAOISM = "taoism",
    AGNOSTIC = "agnostic",
    ATHEIST = "atheist",
    OTHER = "other",
    NONE = "none",
}

export enum ClientEducation {
    GED = "GED",
    HS_DIPLOMA = "HS Diploma",
    BACHELORS = "bachelors",
    MASTERS = "masters",
    DOCTOR = "doctor",
    OTHER = "other",
}

export enum ClientSexualOrientation {
    HETEROSEXUAL = "heterosexual",
    BISEXUAL = "bisexual",
    QUEER = "queer",
    ASEXUAL = "asexual",
    PANSEXUAL = "pansexual",
    GAY = "gay",
    LESBIAN = "lesbian",
}

export enum ClientRace {
    WHITE = "white",
    BLACK_AFRICAN_AMERICAN = "black_or_african_american",
    ASIAN = "asian",
    LATINO = "latino",
    NATIVE_HAWAIIAN = "native_hawaiian",
    NATIVE_AMERICAN_ALASKA = "native_american_alaska",
}

export enum FamilyMembers {
    ALL = "all",
    FATHER = "father",
    MOTHER = "mother",
    CHILD_CHILDREN = "child_children",
    BROTHER = "brother",
    SISTER = "sister",
    OTHER = "other",
}

export enum InsuranceType {
    MEDICARE = "medicare",
    MEDICAID = "medicaid",
    TRICARE = "tricare",
    CHAMPVA = "champva",
    GROUP_HEALTH_PLAN = "group_health_plan",
    FECA_BLK_LUNG = "feca_blk_lung",
    Other = "other",
}

export enum UserSex {
    MALE = "male",
    FEMALE = "female",
    UNKNOWN = "unknown",
}

export enum RelationshipToPrimaryInsured {
    SELF = "self",
    SPOUSE = "spouse",
    CHILD = "child",
    OTHER = "other",
}

export type DocumentUploadType =
    | "insurance-file"
    | "documents"
    | "insurance-card"
    | "remit-file";

export interface IClientInsuranceAttachement {
    id: string;
    name: string;
    url: string;
}

export interface IClientInsuranceDetails extends ISortTable {
    insurance_id: string;
    name: string;
    is_current: boolean;
    employer_name: string;
    non_client_primary_insured_name: string;
    start_date: string;
    end_date: string;
    insurance_member_id: string;
    is_cal_year: boolean;
    last_verified: string | null;
    non_client_primary_insured_dob: string;
    primary_insured_address: string;
    primary_insured_name: string;
    primary_insured_dob: string;
    financial_assistance_beneficiary: boolean;
    attached_files: IClientInsuranceAttachment[] | null;
    primary_insured_state: string;
    primary_insured_zipcode: string;
    status: ProviderStatus;
    insurance_accepted: boolean;
    relationship_to_primary_insured?: RelationshipToPrimaryInsured;
    primary_insured_city?: string;
    primary_insured_phone_no?: string;
    primary_insured_sex?: UserSex;
    insured_policy_group?: string;
    insurance_type?: InsuranceType;
}

export interface IClientInsuranceAttachment {
    id: string;
    name: string;
    url: string;
}

export interface IClientNewInsuranceDetails {
    author?: string;
    insurance_provider_id: string;
    is_cal_year?: boolean;
    start_date?: string;
    end_date?: string;
    employer_name: string;
    insurance_member_id?: string;
    last_verified?: string;
    non_client_primary_insured_name?: string;
    non_client_primary_insured_dob?: string;
    primary_insured_address?: string;
    primary_insured_state?: string;
    primary_insured_zipcode?: string;
    relationship_to_primary_insured?: RelationshipToPrimaryInsured;
    primary_insured_city?: string;
    primary_insured_phone_no?: string;
    primary_insured_sex?: UserSex;
    primary_insured_dob?: string;
    insured_policy_group?: string;
    insurance_type?: InsuranceType;
}

export interface IClientPaymentDetails {
    payment_id: string;
    date_of_service: string;
    cpt_code: string;
    dsm_code: string;
    insurance_charge: number;
    insurance_collected: number;
    co_insurance_fee: number;
    co_insurance_collected: number;
    balance_insurance: number;
    balance_co_insurance: number;
}

export interface IClientPaymentSummary {
    owed?: {
        insurance: number;
        co_insurance: number;
    };
    collected?: {
        insurance: number;
        co_insurance: number;
    };
}

export interface IClientNewPayment {
    payment_method: string;
    insurance_amount: number;
    insurance_description: string;
    co_insurance_amount: number;
    co_insurance_description: string;
}

export interface IClientDocument extends ISortTable {
    document_id: string;
    title: string;
    type: string;
    url: string;
    uploaded_by: {
        user_id: string;
        first_name: string;
        last_name: string;
    };
    uploaded_date: string;
    metadata: {
        content_type: string;
    } | null;
    third_party_type: "dropbox" | "google_drive" | null;
}

export interface IClientDocumentFilters {
    client_id: string;
    page?: number;
    limit?: number;
    type?: ClientDocumentTypes;
}

export interface IClientRelation {
    first_name: string;
    last_name: string;
    relationship_type: "father" | "mother";
    contact_phone_no: string;
    relation_id: string;
    is_primary_contact: boolean;
}

export interface IClientRelationEdit {
    first_name: string;
    last_name: string;
    relationship_type: "father" | "mother";
    contact_phone_no: string;
    relation_id: string;
    is_primary_contact: boolean;
}

export enum ClientStatus {
    ACTIVE = "active",
    INACTIVE = "inactive",
    COMPLETED = "completed",
}

export interface IClientBasicInfo {
    first_name: string;
    last_name: string;
    city?: string;
    sex?: UserSex;
    preferred_name: string;
    contact: string;
    email: string;
    age: number;
    client_chart_number?: string;
    current_provider: string | null;
    latest_diagnosis: {
        code: string;
        description: string;
    };
    last_session: string | null;
    date_of_birth: string;
    address: string;
    merchant_client_id?: string;
    financial_assistance: boolean | null;
    financial_assistance_end_date: string | null;
    pronouns: string;
    insurance_provider: string | null;
    state: string;
    zip_code: string;
    provider_credential: string;
    client_status: ClientStatus;
}

export interface IClientPersonalInfo {
    relation_info?: IClientRelation[];
    other_info: {
        inquiry_method?: ClientInquiryMethod;
        requested_service?: SessionServiceType;
    };
}

export interface IClientDetails {
    basic_info: IClientBasicInfo;
    personal_info: IClientPersonalInfo;
    providers_history: IClientProviderHistory[];
    insurance_details: IClientInsuranceDetails[];
    fees: IClientFee[];
    payments: IClientPaymentDetails[];
    memo: IClientMemo[];
    notes: IClientNote[];
    documents: IClientDocument[];
}

export interface IClientCreditCardDetails {
    payment_card_id: string;
    merchant_customer_id: string;
    profile_id: string;
    payment_profile_id: string;
    first_name: string;
    last_name: string;
    masked_card_number: string;
    expiration_date: string;
    billing_address: string;
    zip_code: string;
    card_type: ClientCardTypes;
    state: string;
    country: string;
    card_status: PaymentCardStatus;
    created_source: string;
    is_current: boolean;
    card_label: string;
    is_card_expired: boolean;
}

export interface INewCreditCardDetails {
    card_number: string;
    card_cvv: string;
    expiration_month: number;
    expiration_year: number;
    first_name: string;
    last_name: string;
    billing_address: string;
    state: string;
    zip_code: string;
    country: string;
    card_label: string;
}

export interface INewClientDetails {
    first_name: string;
    last_name: string;
    pronouns?: string;
    preferred_name?: string;
    date_of_birth: string;
    practice_id: string;
    contact: string;
    financial_assistance: boolean | null;
    financial_assistance_end_date: string | null;
    email: string;
    address: string;
    state: string;
    zip_code: string;
    sex?: string;
    city?: string;
    primary_contact?: string; // This is here for frontend logic purpose, will be removed before form submission
    inquiry_method?: ClientInquiryMethod;
    requested_service?: SessionServiceType;
    relation_info?: IClientRelation[];
}

export interface IEditMemoResponse {
    message: string;
    data: object;
}

export interface IEditMemo {
    description?: string;
    memo_status?: string;
    memoId: string;
}

export enum InsuranceTabSubModals {
    INFO = "info",
    DOCUMENT = "document",
    EDIT = "edit",
    UPLOAD_DOCUMENT = "upload-document",
}

export interface IClientInsuranceProvider {
    insurance_provider_id: string;
    name: string;
}

export interface INewClientFee {
    author: string;
    cal_year: number;
    start_date: string;
    end_date: string;
    self_pay?: boolean;
    accepts_assignment?: boolean;
    INNDed?: string;
    INNCo?: string;
    INNOOP?: string;
    INNCoins?: string;
    OONDed?: string;
    OONCo?: string;
    OONOOP?: string;
    UCR?: string;
    OONCoins?: string;
    fee_values: {
        type: string;
        value: string;
    }[];
}

export interface EditClientBasicInfo {
    requested_service?: SessionServiceType;
    inquiry_method?: ClientInquiryMethod;
    primary_contact?: string; // This is here for frontend logic purpose, will be removed before form submission
    relation_info?: IClientRelationEdit[];
}

export interface IClientEditPersonalInfo {
    author?: string;
    first_name: string;
    last_name: string;
    pronouns: string | null;
    preferred_name?: string;
    date_of_birth: string;
    user_contact_phone_no: string;
    financial_assistance: boolean;
    financial_assistance_end_date: string | null;
    address: string;
    state: string;
    zipcode: string;
    user_email: string;
    city?: string;
    sex?: UserSex;
    client_status: ClientStatus;
}

export interface IClientEditInsuranceDetails {
    insurance_provider_id: string;
    is_cal_year?: boolean;
    start_date?: string;
    end_date?: string;
    employer_name: string;
    insurance_member_id?: string;
    last_verified?: string | null;
    non_client_primary_insured_name?: string;
    non_client_primary_insured_dob?: string;
    primary_insured_address?: string;
    primary_insured_state?: string;
    primary_insured_zipcode?: string;
    status: ProviderStatus;
    insurance_accepted: boolean;
    relationship_to_primary_insured?: RelationshipToPrimaryInsured;
    primary_insured_city?: string;
    primary_insured_phone_no?: string;
    primary_insured_sex?: UserSex;
    primary_insured_dob?: string;
    insured_policy_group?: string;
    insurance_type?: InsuranceType;
}

export interface IClientNewDocument {
    title: string;
    type: ClientDocumentTypes;
    documents: File[];
}

export interface IClientNewDocumentThirdParty {
    title?: string;
    name?: string;
    type: ClientDocumentTypes;
    documents: string[];
}

export interface IAddRelationInfo {
    client_id: string;
    relation_info: IClientRelation[];
}

export interface ISuperviseesListFilters {
    provider_name: string;
    cpt_code?: string;
    type: string[];
    from_date: null | string;
    to_date: null | string;
    page: number;
    status: string[];
    limit: number;
    show_archived?: string;
    author_id?: string;
}
