/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import {
    Button,
    Checkbox,
    DatePicker,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Popover,
    PopoverContent,
    PopoverTrigger,
    SearchInput,
    Table,
} from "@jhool-io/fe-components";
import useToast from "../../../../hooks/useToast";
import { useDeleteUserRestrictions } from "../../hooks/client-chart-restriction.mutations";
import { useDebounce } from "../../../../hooks/helpers";
import { momentNotTz } from "../../../../utils/moment";
import { useFetchRestrictedUsersList } from "../../hooks/client-chart-restriction.queries";
import { IRestrictedUserSingleClient } from "../../types/client-chart-restriction.types";
import {
    formatDate,
    getTotalAmountOfTableRowsSelected,
    truncateString,
} from "../../../../utils/helpers";
import MoreButton from "../../../../shared-ui/Buttons/MoreButton/MoreButton";
import AddRestrictionForm from "../AddRestrictionForm/AddRestrictionForm";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import { useFetchUsersList } from "../../../user-management/hooks/user-management.queries";
import ListState from "../../../../components/ListState/ListState";
import CheckPrimaryColorIcon from "../../../../components/Icons/CheckPrimaryColor";
import MobileListItem from "../../../../shared-ui/MobileListItem/MobileListItem";

type Dialogs = "add_restriction" | "delete_restriction";

export default function RestrictedUsersList() {
    const [searchParams, setSearchParams] = useSearchParams();

    const [showInternalUsersPopover, setShowInternalUsersPopover] =
        React.useState(false);
    const [internalUsersSearchString, setInternalUsersSearchString] =
        React.useState("");
    const [selectedIds, setSelectedIds] = React.useState<
        { restrictionId: string; clientIds: string[] }[]
    >([]);
    const [dialogInView, setDialogInView] = React.useState<Dialogs | null>(
        null
    );
    const [internalUserRowSelection, setInternalUserRowSelection] =
        React.useState({});
    const [sortConfig, setSortConfig] = React.useState<{
        key: string;
        direction: string;
    }>({
        key: "",
        direction: "",
    });

    const queryClient = useQueryClient();
    const { toast } = useToast();

    const deleteUserRestrictions = useDeleteUserRestrictions();

    // To determine if mutation is currently running, returns 0 || 1
    const restrictAccessIsMutating = useIsMutating(["restrict-access"]);

    // get filters from params
    const clientNameFilter = searchParams.get("client") || "";
    const userNameFilter = searchParams.get("user") || "";
    const fromDateFilter = searchParams.get("from_date")
        ? momentNotTz(searchParams.get("from_date")).toDate()
        : null;
    const toDateFilter = searchParams.get("to_date")
        ? momentNotTz(searchParams.get("to_date")).toDate()
        : null;

    const debouncedClientNameFilter = useDebounce(clientNameFilter);
    const debouncedUserNameFilter = useDebounce(
        internalUsersSearchString || ""
    );

    const { data, isLoading, isSuccess, error } = useFetchRestrictedUsersList({
        user_search_string: debouncedUserNameFilter,
        client_search_string: debouncedClientNameFilter,
        from_date: fromDateFilter ? format(fromDateFilter, "yyyy-MM-dd") : null,
        to_date: toDateFilter ? format(toDateFilter, "yyyy-MM-dd") : null,
    });

    const internalUsersList = useFetchUsersList({
        page: 1,
        limit: 20,
        search_string: debouncedUserNameFilter,
    });

    const handleUserNameFilterChange = (userName: string) => {
        if (!userName) searchParams.delete("user");
        else searchParams.set("user", userName);

        setSearchParams(searchParams);
    };

    const handleClientNameFilterChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (e.target.value === "") searchParams.delete("client");
        else searchParams.set("client", e.target.value);

        setSearchParams(searchParams);
    };

    const handleFromDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("from_date");
        else searchParams.set("from_date", format(date, "yyyy-MM-dd"));

        setSearchParams(searchParams);
    };

    const handleToDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("to_date");
        else searchParams.set("to_date", format(date, "yyyy-MM-dd"));

        setSearchParams(searchParams);
    };

    // The current API response returns the associated clients for a restricted user in an array, but the Design requires that every client - user relationship is a separate row. This means we have to create a new row for each client in a restricted user's client list
    const openRestrictionList: IRestrictedUserSingleClient[] = [];
    data?.data.forEach((item) => {
        item?.clients?.forEach((client) => {
            openRestrictionList.push({ ...item, client });
        });

        // fill up the selectedIds array based on the available restriction ids
        if (
            !selectedIds.find(
                (singleId) => singleId.restrictionId === item.restriction_id
            )
        ) {
            selectedIds.push({
                restrictionId: item.restriction_id,
                clientIds: [],
            });
        }
    });

    const handleDialogInView = (modal: Dialogs | null) => {
        setDialogInView(() => modal);
    };

    // total selected ids
    const totalSelectedIds = selectedIds.reduce(
        (acc, item) => acc + item.clientIds.length,
        0
    );

    // Toggles user selection for mobile
    const toggleItemSelection = (restrictionId: string, clientId: string) => {
        const selectedRestriction = selectedIds.find(
            (singleId) => singleId.restrictionId === restrictionId
        );

        if (selectedRestriction) {
            if (selectedRestriction.clientIds.includes(clientId)) {
                const newList = selectedRestriction?.clientIds.filter(
                    (id) => id !== clientId
                );

                selectedRestriction.clientIds = newList;
            } else {
                selectedRestriction.clientIds = [
                    ...selectedRestriction.clientIds,
                    clientId,
                ];
            }
            setSelectedIds((prev) => [
                ...prev.filter((item) => item.restrictionId !== restrictionId),
                selectedRestriction,
            ]);
        }
    };

    const handleDeleteRestriction = () => {
        const parsedSelectedIds = selectedIds.map((item) => ({
            [item.restrictionId]: [...item.clientIds],
        }));

        deleteUserRestrictions.mutate(parsedSelectedIds, {
            onSuccess: async (res) => {
                queryClient.invalidateQueries(["restricted-users"]);
                toast({
                    mode: "success",
                    message:
                        res.message || "Restriction(s) removed successfully",
                });
                setInternalUserRowSelection({});
                setSelectedIds([]);
                setDialogInView(null);
            },
            onError(err) {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not remove restriction(s), please try again",
                });
            },
        });
    };

    const columns: ColumnDef<IRestrictedUserSingleClient>[] = [
        {
            id: "select",
            header: "",
            cell: ({ row }) => (
                <Checkbox
                    handleChange={() =>
                        toggleItemSelection(
                            row.original.restriction_id,
                            row.original.client.client_id
                        )
                    }
                    indeterminate={row.getIsSomeSelected()}
                    label=""
                    name="row"
                    value="row"
                    isChecked={selectedIds
                        .find(
                            (singleId) =>
                                singleId.restrictionId ===
                                row.original.restriction_id
                        )
                        ?.clientIds.includes(row.original.client.client_id)}
                />
            ),
        },
        {
            accessorKey: "restricted_user",
            header: "RESTRICTED USER",
            cell: ({ row }) =>
                `
                    ${row.original.restricted_user.first_name} 
                    ${row.original.restricted_user.last_name}
                `,
        },
        {
            accessorKey: "client",
            header: "CLIENT NAME",
            cell: ({ row }) =>
                `
                    ${row.original.client.first_name}
                    ${row.original.client.last_name}
                `,
        },
        {
            accessorKey: "date_restricted",
            header: "DATE RESTRICTED",
            cell: ({ row }) => formatDate(row.original.date_restricted),
        },
        {
            accessorKey: "restricted_by",
            header: "RESTRICTED BY",
            cell: ({ row }) =>
                `
                    ${row.original.restricted_by.first_name}
                    ${row.original.restricted_by.last_name}
                `,
        },
        {
            accessorKey: "restriction_id",
            header: "",
            cell: ({ row }) => (
                <Dropdown>
                    <DropdownTrigger asChild>
                        <MoreButton />
                    </DropdownTrigger>
                    <DropdownContent
                        onClick={() => {
                            toggleItemSelection(
                                row.original.restriction_id,
                                row.original.client.client_id
                            );

                            handleDialogInView("delete_restriction");
                        }}
                    >
                        <DropdownItem> Delete restriction</DropdownItem>
                    </DropdownContent>
                </Dropdown>
            ),
        },
    ];

    return (
        <>
            <Dialog open={dialogInView === "delete_restriction"}>
                <DialogContent
                    variant="center"
                    isDeleting
                    handleCloseDialog={() => handleDialogInView(null)}
                    title="Delete restriction(s)"
                    showFooter
                    saveText="Delete"
                    cancelText="Cancel"
                    isCancelBtnDisabled={restrictAccessIsMutating > 0}
                    isSubmitBtnDisabled={restrictAccessIsMutating > 0}
                    onSaveClick={handleDeleteRestriction}
                >
                    <div>
                        <h3 className="font-medium text-xl mb-8">
                            Delete restriction(s)
                        </h3>
                        <p className="text-gray font-normal text-base">
                            Are you sure you want to delete the selected
                            restriction(s)?
                        </p>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog open={dialogInView === "add_restriction"}>
                <DialogContent
                    variant="center"
                    handleCloseDialog={() => handleDialogInView(null)}
                    title="Add restriction"
                    showFooter
                    saveText="Add restriction"
                    cancelText="Cancel"
                    submitBtnFormValue="add-restriction-form"
                    isCancelBtnDisabled={restrictAccessIsMutating > 0}
                    isSubmitBtnDisabled={restrictAccessIsMutating > 0}
                >
                    <AddRestrictionForm
                        onFormSubmit={() => handleDialogInView(null)}
                    />
                </DialogContent>
            </Dialog>

            <div className="rounded-r8 bg-white py-16 px-12 mb-32">
                <div className="flex flex-col gap-y-24">
                    <SearchInput
                        defaultValue={clientNameFilter}
                        onChange={handleClientNameFilterChange}
                        containerClass="w-[40rem]"
                        placeholder="Search by client name"
                    />
                    <div className="flex items-center justify-between gap-x-12 flex-wrap gap-y-24">
                        <div className="flex items-center gap-x-12 flex-wrap gap-y-12">
                            <Popover
                                open={showInternalUsersPopover}
                                onOpenChange={(state) => {
                                    setShowInternalUsersPopover(state);
                                }}
                            >
                                <PopoverTrigger
                                    asChild
                                    onClick={() =>
                                        setShowInternalUsersPopover(true)
                                    }
                                >
                                    <FilterButton
                                        classNames={
                                            userNameFilter
                                                ? "capitalize"
                                                : "normal-case"
                                        }
                                        text={
                                            userNameFilter
                                                ? truncateString(userNameFilter)
                                                : "User"
                                        }
                                    />
                                </PopoverTrigger>
                                <PopoverContent
                                    className="px-4 pt-[3px] py-[3px] w-[316px] rounded-r8 border-none shadow-[0px_0px_0px_1px_#98a1b219,_0px_15px_35px_-5px_#11182626,_0px_5px_15px_0px_#00000014]"
                                    align="start"
                                >
                                    <SearchInput
                                        placeholder="Search by name"
                                        containerClass="h-24"
                                        onChange={(e) =>
                                            setInternalUsersSearchString(
                                                e.target.value
                                            )
                                        }
                                    />

                                    <div className="flex flex-col items-start max-h-[230px] overflow-y-auto">
                                        {internalUsersList.isLoading && (
                                            <div className="flex flex-col gap-y-4 items-stretch w-full">
                                                {[1, 2, 3, 4, 5].map((item) => (
                                                    <Skeleton
                                                        key={item}
                                                        width="100%"
                                                        height="24px"
                                                        containerTestId="internalusers-loader"
                                                    />
                                                ))}
                                            </div>
                                        )}
                                        {internalUsersList.error && (
                                            <ListState
                                                isError
                                                context="general"
                                                stateHelperText="Please try again later"
                                                errorMsg={
                                                    internalUsersList.error
                                                        ?.response?.data
                                                        ?.message ||
                                                    "Error loading internal users"
                                                }
                                            />
                                        )}
                                        {internalUsersList.data?.data &&
                                            internalUsersList.isSuccess && (
                                                <>
                                                    <Button
                                                        size="auto"
                                                        variant="normal"
                                                        className="text-xs px-[6px] py-4 font-medium capitalize w-full justify-start !rounded-none"
                                                        onClick={() => {
                                                            handleUserNameFilterChange(
                                                                ""
                                                            );
                                                            setShowInternalUsersPopover(
                                                                false
                                                            );
                                                            setInternalUsersSearchString(
                                                                ""
                                                            );
                                                        }}
                                                    >
                                                        All
                                                    </Button>
                                                    {internalUsersList?.data?.data.map(
                                                        (user) => (
                                                            <Button
                                                                key={
                                                                    user.user_id
                                                                }
                                                                size="auto"
                                                                variant="normal"
                                                                className="text-xs px-[6px] py-4 font-medium capitalize w-full justify-start gap-x-8 !rounded-none"
                                                                onClick={() => {
                                                                    handleUserNameFilterChange(
                                                                        `${user.first_name} ${user.last_name}`
                                                                    );
                                                                    setShowInternalUsersPopover(
                                                                        false
                                                                    );
                                                                }}
                                                            >
                                                                {`${user.first_name} ${user.last_name}`}
                                                                {userNameFilter.toLocaleLowerCase() ===
                                                                `${user.first_name} ${user.last_name}`.toLowerCase() ? (
                                                                    <CheckPrimaryColorIcon />
                                                                ) : null}
                                                            </Button>
                                                        )
                                                    )}
                                                </>
                                            )}
                                    </div>
                                </PopoverContent>
                            </Popover>
                            <Popover>
                                <PopoverTrigger asChild>
                                    <FilterButton text="Date" />
                                </PopoverTrigger>
                                <PopoverContent
                                    align="end"
                                    className="h-auto w-96 p-16 z-20 gap-y-12"
                                >
                                    <DatePicker
                                        label="From"
                                        placeholderText="MM/DD/YYYY"
                                        selected={fromDateFilter}
                                        onChange={handleFromDateFilterChange}
                                        className="hover:border-primary"
                                        maxDate={
                                            toDateFilter || new Date(Date.now())
                                        }
                                        isClearable
                                    />
                                    <DatePicker
                                        label="To"
                                        placeholderText="MM/DD/YYYY"
                                        selected={toDateFilter}
                                        onChange={handleToDateFilterChange}
                                        className="hover:border-primary"
                                        minDate={fromDateFilter}
                                        maxDate={new Date(Date.now())}
                                        isClearable
                                    />
                                </PopoverContent>
                            </Popover>
                        </div>
                        <div className="w-full min-[500px]:w-auto">
                            <Button
                                onClick={() =>
                                    handleDialogInView("add_restriction")
                                }
                            >
                                Add restriction
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            {isLoading ? (
                <Skeleton
                    type="table"
                    containerTestId="restrictionslist-loader"
                    count={6}
                />
            ) : null}

            {error && error?.response?.status !== 404 && (
                <ListState
                    isError
                    stateHelperText="Try reloading this page to solve this issue"
                    errorMsg={
                        error?.response?.data.message ||
                        `Cannot display restricted users list at this time please try again later`
                    }
                />
            )}

            {openRestrictionList &&
                isSuccess &&
                openRestrictionList.length === 0 && (
                    <ListState
                        stateHelperText="Restricted users list will appear here once they are added"
                        isError={false}
                        emptyMessage="No restricted user added yet"
                    />
                )}

            {isSuccess &&
            openRestrictionList &&
            openRestrictionList.length > 0 ? (
                <div>
                    {totalSelectedIds > 0 ? (
                        <div className="flex items-center mb-16 gap-12 flex-wrap">
                            <div className="font-semibold text-base">
                                {`${totalSelectedIds} selected`}:
                            </div>
                            <Button
                                variant="secondary"
                                aria-label="actions"
                                onClick={() =>
                                    handleDialogInView("delete_restriction")
                                }
                            >
                                {getTotalAmountOfTableRowsSelected(
                                    internalUserRowSelection
                                ).amount > 1
                                    ? "Delete restrictions"
                                    : "Delete restriction"}
                            </Button>
                        </div>
                    ) : null}

                    <div className="hidden md:block">
                        <Table
                            columns={columns}
                            data={openRestrictionList}
                            rowSelection={internalUserRowSelection}
                            setRowSelection={setInternalUserRowSelection}
                            setSortConfig={setSortConfig}
                            sortConfig={sortConfig}
                        />
                    </div>

                    <div className="block md:hidden" role="table">
                        <div className="flex flex-col gap-y-12">
                            {openRestrictionList.map((restriction) => (
                                <MobileListItem
                                    key={restriction.restriction_id}
                                    isSelected={selectedIds
                                        .find(
                                            (singleId) =>
                                                singleId.restrictionId ===
                                                restriction.restriction_id
                                        )
                                        ?.clientIds.includes(
                                            restriction.client.client_id
                                        )}
                                    showCheckButton
                                    onClickCheckButton={() =>
                                        toggleItemSelection(
                                            restriction.restriction_id,
                                            restriction.client.client_id
                                        )
                                    }
                                    topChildren={
                                        <div className="flex items-center gap-x-8 text-base font-semibold capitalize">
                                            {`${restriction.client.first_name} ${restriction.client.last_name}`}
                                        </div>
                                    }
                                    bottomChildren={
                                        <div className="flex flex-col gap-y-8">
                                            <div className="w-full text-left">
                                                <span className="block font-medium text-xss uppercase">
                                                    Restricted user
                                                </span>
                                                <span className="block text-gray font-semibold text-xs capitalize">
                                                    {`${restriction.restricted_user.first_name} ${restriction.restricted_user.last_name}`}
                                                </span>
                                            </div>
                                            <div className="w-full text-left">
                                                <span className="block font-medium text-xss uppercase">
                                                    Date restricted
                                                </span>
                                                <span className="block text-gray font-semibold text-xs">
                                                    {formatDate(
                                                        restriction.date_restricted
                                                    )}
                                                </span>
                                            </div>
                                            <div className="w-full text-left">
                                                <span className="block font-medium text-xss uppercase">
                                                    Restricted by
                                                </span>
                                                <span className="block text-gray font-semibold text-xs capitalize">
                                                    {`${restriction.restricted_by.first_name} ${restriction.restricted_by.last_name}`}
                                                </span>
                                            </div>
                                        </div>
                                    }
                                />
                            ))}
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}
