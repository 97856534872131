import * as React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import cn from "classnames";
import styles from "./NoteContentItem.module.scss";
import { IAddendumDetails, NoteStatus } from "../../../../utils/types/notes";
import {
    formatDate,
    makeStringFirstLetterCapital,
} from "../../../../utils/helpers";
import { ViewEditorText } from "../../../TextEditor/ViewEditorText/ViewEditorText";
import { useFetchSessionNote } from "../../../../hooks/queries/note";
import Button from "../../../Button/Button";
import AddIcon from "../../../Icons/AddIcon";
import Card from "../../../Card/Card";
import usePractice from "../../../../hooks/usePractice";

interface NoteContentAddendumProps {
    /** Addendum header */
    headerText: string;
    /** Array of addendum items to display */
    addendums?: IAddendumDetails[];
    /** Field id of note content item */
    fieldId?: string;
    /** Note content item details */
    content?: React.ReactNode;
    /** has note content to display? */
    hasContent?: boolean;
}

export default function NoteContentItem({
    headerText,
    addendums,
    fieldId,
    content,
    hasContent = true,
}: NoteContentAddendumProps) {
    const [searchParams, setSearchParams] = useSearchParams();

    const params = useParams();
    const noteId = params.noteId as string;
    const clientId = params.clientId as string;

    const { practice } = usePractice();

    const { data } = useFetchSessionNote(
        clientId,
        noteId,
        Boolean(clientId) && Boolean(noteId)
    );

    const handleButtonClick = (id: string) => {
        searchParams.set("field_id", id);
        searchParams.set("modal", "add_addendum");
        setSearchParams(searchParams);
    };

    const allowedNoteStatuses = [
        NoteStatus.PENDING_SUPERVISOR_REVIEW,
        NoteStatus.CHANGES_REQUESTED,
        NoteStatus.PENDING_CODE_REVIEW,
    ];

    const canShowButton =
        (data?.data.provider.provider_id === practice?.provider_id ||
            Boolean(
                practice?.provider_id &&
                    practice?.provider_id ===
                        data?.data?.provider?.supervisor_id
            )) &&
        allowedNoteStatuses.includes(data?.data.note_status as NoteStatus);

    return (
        <Card className={styles.wrapper} type="dashboard">
            <div
                className={cn(styles.header, {
                    [styles.header_canhover]: canShowButton,
                })}
            >
                <h3>{headerText}</h3>
                {canShowButton ? (
                    <Button
                        type="button"
                        mode="transparent"
                        size="auto"
                        onClick={
                            fieldId
                                ? () => handleButtonClick(fieldId)
                                : undefined
                        }
                        className={styles.btn}
                        ariaLabel="add addendum"
                    >
                        <AddIcon />
                    </Button>
                ) : null}
            </div>
            {hasContent ? (
                <div className={styles.content}>{content}</div>
            ) : null}
            {addendums ? (
                <section className={styles.addendums}>
                    {addendums.map((addendum) => (
                        <article
                            key={addendum.note_addendum_id}
                            className={styles.addendum}
                            id={addendum.field_id || undefined}
                            style={{ scrollMarginTop: "6rem" }}
                        >
                            <h4 className={styles.addendum_header}>
                                <span>
                                    {`${makeStringFirstLetterCapital(
                                        addendum.author.first_name
                                    )} ${makeStringFirstLetterCapital(
                                        addendum.author.last_name
                                    )} added addendum`}
                                </span>
                                <span>{`'${addendum.title}'`}</span>
                                <span>
                                    {`- ${formatDate(
                                        addendum.signature_date_time,
                                        false,
                                        "MM/dd/yyyy, h:mmaa"
                                    )}`}
                                </span>
                            </h4>
                            <div className={styles.addendum_content}>
                                <ViewEditorText text={addendum.content} />
                            </div>
                        </article>
                    ))}
                </section>
            ) : null}
        </Card>
    );
}
