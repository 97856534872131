import {
    get,
    limitToLast,
    onChildAdded,
    onChildChanged,
    orderByKey,
    query,
    ref,
} from "firebase/database";
import * as React from "react";
import { rtdb } from "../../firebase";
import { useFetchUserDetails } from "../../hooks/queries/user";
import { AppNotification } from "../../utils/types/notifications";
import { NotificationContext } from "./NotificationContext";
import usePractice from "../../hooks/usePractice";

interface NotificationProviderProps {
    children: React.ReactNode;
}

export default function NotificationProvider({
    children,
}: NotificationProviderProps) {
    const [userNotifications, setUserNotifications] = React.useState<
        AppNotification[]
    >([]);

    const { data: loggedInUserData } = useFetchUserDetails();

    const { practice } = usePractice();

    const processAppNotifications = React.useCallback(
        (newAppNotifications: AppNotification[]) => {
            const currentNotifications = [...userNotifications];
            let notificationListChanged = false;
            newAppNotifications.forEach((newAppNotification) => {
                const existingNotificationIdx = currentNotifications.findIndex(
                    (u) => u.id === newAppNotification.id
                );
                if (
                    existingNotificationIdx > -1 &&
                    currentNotifications[existingNotificationIdx].is_read ===
                        newAppNotification.is_read
                ) {
                    return;
                }

                if (existingNotificationIdx > -1) {
                    currentNotifications.splice(
                        existingNotificationIdx,
                        1,
                        newAppNotification
                    );
                    notificationListChanged = true;
                } else {
                    currentNotifications.push(newAppNotification);
                    notificationListChanged = true;
                }
            });
            if (notificationListChanged) {
                setUserNotifications(currentNotifications);
            }
        },
        [userNotifications]
    );

    React.useEffect(() => {
        if (!loggedInUserData?.user_id) {
            return;
        }

        const notificationPath = `notifications/${
            practice?.provider_id || ""
        }/${loggedInUserData?.user_id}`;
        const userNotificationRef = query(
            ref(rtdb, notificationPath),
            orderByKey(),
            limitToLast(20)
        );
        (async () => {
            const last20UserNotifications = await get(userNotificationRef);
            if (
                last20UserNotifications.exists() &&
                last20UserNotifications.hasChildren()
            ) {
                processAppNotifications(
                    Object.values(last20UserNotifications.val())
                );
            }
        })();

        onChildChanged(userNotificationRef, (snapshot) => {
            if (snapshot.exists()) {
                processAppNotifications([snapshot.val()]);
            }
        });

        onChildAdded(userNotificationRef, (snapshot) => {
            if (snapshot.exists()) {
                processAppNotifications([snapshot.val()]);
            }
        });
    }, [loggedInUserData?.user_id, practice, processAppNotifications]);
    return (
        <NotificationContext.Provider value={userNotifications}>
            {children}
        </NotificationContext.Provider>
    );
}
