import * as React from "react";
import * as yup from "yup";
import {
    Button,
    DatePicker,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    FormCheckbox,
    Input,
    RadioInput,
    TextArea,
    Select,
} from "@jhool-io/fe-components";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { parseISO } from "date-fns";
import {
    FamilyIntakeNotePayload,
    INewNoteAppoinmentDetails,
    emptyFamilyIntakeNote,
    IGetClientNotes,
} from "../../../../utils/types/notes";
import { useFetchDiagnosisCodes } from "../../../../hooks/queries";
import {
    formatDate,
    getTimeDuration,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../../utils/helpers";
import { AppointmentLocation } from "../../../../utils/types/session";
import {
    ClientEducation,
    ClientGender,
    ClientRace,
    ClientRelationship,
    ClientReligion,
    ClientSexualOrientation,
} from "../../../../utils/types/client";
import Accordion from "../../../Accordion/Accordion";
import NoteFormInfoBox from "../../NoteFormInfoBox/NoteFormInfoBox";
import { useFetchClientNotesList } from "../../../../hooks/queries/note";
import LoadPreviousSessionNotificationModal from "../../LoadPreviousSessionNotificatiModal/LoadPreviousSessionNotificationModal";
import { FAMILY_INTAKE_NOTE_DETAILS } from "../../../../utils/constants";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";

export interface ICreateFamilyIntakeNote {
    family: FamilyIntakeNotePayload[];
    signature?: string;
    appointment_location: string;
    diagnosis_codes: string[];
    delivery_option: string;
    provider_location: string;
    other_provider_location?: string;
    other_delivery_option?: string;
    consent_was_received_for_the_teletherapy_session: boolean;
    the_teletherapy_session_was_conducted_via_video: boolean;
    duration: string;
    what_are_the_problem_you_are_seeking_help_for: string;
    people_present_at_session: string[];
    other_person_present_at_session: string;
    type_of_treatment_recommended: string[];
    treatment_modality_intervention: string[];
    other_treatment_modality_intervention: string;
    treatment_goal_one: string;
    treatment_goal_two: string;
    treatment_goal_three: string;
    frequency_of_sessions: string;
    other_frequency_of_sessions: string;
    date_of_next_session: string;
    subjective_report: string;
    summary: string;
    prognosis: string;
    communication_factors_impacting_treatment: string[];
    details_around_communication_factors_impacting_treatment: string;
}

interface FamilyIntakeProps {
    /** Client appointment details  */
    appointmentDetails?: INewNoteAppoinmentDetails | null;
    /** Name of client that own the session this note is linked to */
    clientName?: string;
    /** Note details (used when displaying note content) */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formValues?: any;
    currentAction?: string | null;
    onSubmit?: (data: ICreateFamilyIntakeNote) => void;
}

type Option = {
    label: React.ReactNode;
    value: string;
};

export default function FamilyIntake({
    appointmentDetails,
    formValues,
    clientName,
    currentAction,
    onSubmit,
}: FamilyIntakeProps) {
    const [localDateOfNextSession, setLocalDateOfNextSession] =
        React.useState<Date | null>(
            formValues?.date_of_next_session
                ? parseISO(formValues?.date_of_next_session)
                : null
        );
    const [searchValue, setSearchValue] = React.useState("");

    // Fetch diagonis codes
    const { data, isLoading, error } = useFetchDiagnosisCodes();
    const clientNoteParams = {
        type: appointmentDetails?.note_type as string,
        load_previous_notes: true,
        provider_id: appointmentDetails?.provider_id as string,
    };
    const [showPrompt, setShowPrompt] = React.useState(false);
    const [selectedNote, setSelectedNote] = React.useState(
        {} as IGetClientNotes
    );
    const { data: notes } = useFetchClientNotesList(
        appointmentDetails?.client_id as string,
        clientNoteParams
    );

    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors },
    } = useForm<ICreateFamilyIntakeNote>({
        resolver: yupResolver(
            yup.object({
                family: yup.array().of(
                    yup.object().shape({
                        name: yup.string().when([], {
                            is: () => currentAction === "with_signature",
                            then: yup
                                .string()
                                .required("Client name is required"),
                            otherwise: yup.string(),
                        }),
                        gender: yup.string(),
                        religion: yup.string(),
                        education: yup.string(),
                        race: yup.string(),
                        ethinicity: yup.string(),
                        sexual_orientation: yup.string(),
                        current_employment: yup.string(),
                        current_employment_details: yup.string(),
                        relationship_status: yup.string(),
                        name_of_partner: yup.string(),
                        emergency_contact_information: yup.string(),
                        legal_problems: yup.string(),
                        anxiety_frequency: yup.string(),
                        anxiety_details: yup
                            .string()
                            .when("anxiety", (anxiety: boolean, field) =>
                                anxiety && currentAction === "with_signature"
                                    ? field.required(
                                          "Anxiety details is required"
                                      )
                                    : field
                            ),
                        panic_attacks_freqency: yup.string(),
                        panic_attacks_details: yup
                            .string()
                            .when(
                                "panic_attacks",
                                (panic_attacks: boolean, field) =>
                                    panic_attacks &&
                                    currentAction === "with_signature"
                                        ? field.required(
                                              "Panic attacks details is required"
                                          )
                                        : field
                            ),
                        depression_freqency: yup.string(),
                        depression_details: yup
                            .string()
                            .when("depression", (depression: boolean, field) =>
                                depression && currentAction === "with_signature"
                                    ? field.required(
                                          "Depression details is required"
                                      )
                                    : field
                            ),
                        selected_symptoms: yup.array().nullable(),
                        other_symptoms: yup.string(),
                        physical_symptoms: yup.string(),
                        medication_physical_psychiatric: yup.string(),
                        sleeping_habits_and_concerns: yup.string(),
                        difficulties_with_appetite_or_eating_patterns:
                            yup.string(),
                        parents_stepparents_history: yup.string(),
                        siblings_history: yup.string(),
                        grandparents_history: yup.string(),
                        children_history: yup.string(),
                        other_family_members_history: yup.string(),
                        family_members_alcohol_abuse: yup.array().nullable(),
                        family_members_anxiety: yup.array().nullable(),
                        family_members_bipolar_disorder: yup.array().nullable(),
                        family_members_depression: yup.array().nullable(),
                        family_members_ptsd: yup.array().nullable(),
                        family_members_schizophrenia: yup.array().nullable(),
                        family_members_substance_abuse: yup.array().nullable(),
                        family_members_substance_abuse_substance: yup.string(),
                        trauma_abuse_history: yup.string(),
                        previous_mental_health_treatment_out_patient:
                            yup.string(),
                        impatient_psychiatric_hospitalization: yup.string(),
                        relevant_medical_history: yup.string(),
                        been_so_distressed_you_seriously_wished_to_end_your_life:
                            yup.string().when([], {
                                is: () => currentAction === "with_signature",
                                then: yup
                                    .string()
                                    .required("This field is required"),
                                otherwise: yup.string().nullable(),
                            }),
                        has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live:
                            yup.string().when([], {
                                is: () => currentAction === "with_signature",
                                then: yup
                                    .string()
                                    .required("This field is required"),
                                otherwise: yup.string().nullable(),
                            }),
                        describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live:
                            yup
                                .string()
                                .when(
                                    "has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live",
                                    (
                                        has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live,
                                        field
                                    ) =>
                                        has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live ===
                                            "Yes" &&
                                        currentAction === "with_signature"
                                            ? field.required(
                                                  "Description field is required"
                                              )
                                            : field
                                ),
                        a_specific_plan_of_how_you_would_kill_yourself: yup
                            .string()
                            .when([], {
                                is: () => currentAction === "with_signature",
                                then: yup
                                    .string()
                                    .required("This field is required"),
                                otherwise: yup.string().nullable(),
                            }),
                        access_to_weapons_or_means_of_hurting_self: yup
                            .string()
                            .when([], {
                                is: () => currentAction === "with_signature",
                                then: yup
                                    .string()
                                    .required("This field is required"),
                                otherwise: yup.string().nullable(),
                            }),
                        made_serious_suicide_attempts: yup.string().when([], {
                            is: () => currentAction === "with_signature",
                            then: yup
                                .string()
                                .required("This field is required"),
                            otherwise: yup.string().nullable(),
                        }),
                        purposely_done_something_to_hurt_yourself: yup
                            .string()
                            .when([], {
                                is: () => currentAction === "with_signature",
                                then: yup
                                    .string()
                                    .required("This field is required"),
                                otherwise: yup.string().nullable(),
                            }),
                        describe_done_something_to_hurt_yourself: yup
                            .string()
                            .when(
                                "purposely_done_something_to_hurt_yourself",
                                (
                                    purposely_done_something_to_hurt_yourself,
                                    field
                                ) =>
                                    purposely_done_something_to_hurt_yourself ===
                                        "Yes" &&
                                    currentAction === "with_signature"
                                        ? field.required(
                                              "Description field is required"
                                          )
                                        : field
                            ),
                        heard_voices_telling_you_to_hurt_yourself: yup
                            .string()
                            .when([], {
                                is: () => currentAction === "with_signature",
                                then: yup
                                    .string()
                                    .required("This field is required"),
                                otherwise: yup.string().nullable(),
                            }),
                        had_relatives_who_attempted_or_commited_sucide: yup
                            .string()
                            .when([], {
                                is: () => currentAction === "with_signature",
                                then: yup
                                    .string()
                                    .required("This field is required"),
                                otherwise: yup.string().nullable(),
                            }),
                        had_thoughts_of_killing_or_seriously_hurting_someone:
                            yup.string().when([], {
                                is: () => currentAction === "with_signature",
                                then: yup
                                    .string()
                                    .required("This field is required"),
                                otherwise: yup.string().nullable(),
                            }),
                        heard_voices_telling_you_to_hurt_others: yup
                            .string()
                            .when([], {
                                is: () => currentAction === "with_signature",
                                then: yup
                                    .string()
                                    .required("This field is required"),
                                otherwise: yup.string().nullable(),
                            }),
                        hurt_someone_or_destroyed_property_on_purpose: yup
                            .string()
                            .when([], {
                                is: () => currentAction === "with_signature",
                                then: yup
                                    .string()
                                    .required("This field is required"),
                                otherwise: yup.string().nullable(),
                            }),
                        slapped_kicked_punched_someone_with_intent_to_harm: yup
                            .string()
                            .when([], {
                                is: () => currentAction === "with_signature",
                                then: yup
                                    .string()
                                    .required("This field is required"),
                                otherwise: yup.string().nullable(),
                            }),
                        been_arrested_or_detained_for_violent_behavior: yup
                            .string()
                            .when([], {
                                is: () => currentAction === "with_signature",
                                then: yup
                                    .string()
                                    .required("This field is required"),
                                otherwise: yup.string().nullable(),
                            }),
                        been_to_jail_for_any_reason: yup.string().when([], {
                            is: () => currentAction === "with_signature",
                            then: yup
                                .string()
                                .required("This field is required"),
                            otherwise: yup.string().nullable(),
                        }),
                        been_on_probation_for_any_reason: yup
                            .string()
                            .when([], {
                                is: () => currentAction === "with_signature",
                                then: yup
                                    .string()
                                    .required("This field is required"),
                                otherwise: yup.string().nullable(),
                            }),
                        do_you_have_access_to_guns: yup.string().when([], {
                            is: () => currentAction === "with_signature",
                            then: yup
                                .string()
                                .required("This field is required"),
                            otherwise: yup.string().nullable(),
                        }),
                        mental_health_appearance: yup.array().nullable(),
                        mental_health_movement: yup.array().nullable(),
                        mental_health_speech_behaviour: yup.array().nullable(),
                        mental_health_affect_mood: yup.array().nullable(),
                        mental_health_attention_concentration_effort: yup
                            .array()
                            .nullable(),
                        mental_health_presentation_of_self: yup
                            .array()
                            .nullable(),
                        mental_health_cognition: yup.array().nullable(),
                        patients_ability_and_capability_to_respond_to_treatment:
                            yup.array().nullable(),
                        details_for_diminished_poor_limited_quality_patient_oriented_evidence:
                            yup
                                .string()
                                .when(
                                    "patients_ability_and_capability_to_respond_to_treatment",
                                    (
                                        patients_ability_and_capability_to_respond_to_treatment: string[],
                                        field
                                    ) =>
                                        patients_ability_and_capability_to_respond_to_treatment &&
                                        patients_ability_and_capability_to_respond_to_treatment.includes(
                                            "Diminished/poor or limited-quality patient-oriented evidence"
                                        )
                                            ? field.required(
                                                  "Details is required"
                                              )
                                            : field
                                ),
                        anxiety: yup.bool(),
                        panic_attacks: yup.bool(),
                        depression: yup.bool(),
                    })
                ),
                duration: yup.string(),
                appointment_location: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup.string().required("Session location is required"),
                    otherwise: yup.string(),
                }),
                delivery_option: yup
                    .string()
                    .nullable()
                    .when(
                        "appointment_location",
                        (appointment_location, field) =>
                            appointment_location === "teletherapy" &&
                            currentAction === "with_signature"
                                ? field.required(
                                      "Client teletherapy location is required"
                                  )
                                : field
                    ),
                provider_location: yup
                    .string()
                    .nullable()
                    .when(
                        "appointment_location",
                        (appointment_location, field) =>
                            appointment_location === "teletherapy" &&
                            currentAction === "with_signature"
                                ? field.required(
                                      "Provider teletherapy location is required"
                                  )
                                : field
                    ),
                consent_was_received_for_the_teletherapy_session: yup.bool(),
                the_teletherapy_session_was_conducted_via_video: yup.bool(),
                other_delivery_option: yup
                    .string()
                    .when("delivery_option", (delivery_option, field) =>
                        delivery_option === "Other" &&
                        currentAction === "with_signature"
                            ? field.required(
                                  "Other client teletherapy location is required"
                              )
                            : field
                    ),
                other_provider_location: yup
                    .string()
                    .when("provider_location", (provider_location, field) =>
                        provider_location === "Other" &&
                        currentAction === "with_signature"
                            ? field.required(
                                  "Other provider teletherapy location is required"
                              )
                            : field
                    ),
                diagnosis_codes: yup.array().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup
                        .array()
                        .max(3, "You can only select 3 diagnosis")
                        .min(1, "Diagnosis is required")
                        .required("Diagnosis is required"),
                    otherwise: yup
                        .array()
                        .nullable()
                        .max(3, "You can only select 3 diagnosis"),
                }),
                what_are_the_problem_you_are_seeking_help_for: yup
                    .string()
                    .when([], {
                        is: () => currentAction === "with_signature",
                        then: yup
                            .string()
                            .required(
                                "What are the problem you are seeking help for is required"
                            ),
                        otherwise: yup.string(),
                    }),
                people_present_at_session: yup.array().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup
                        .array()
                        .min(1)
                        .required("People present at session is required"),
                    otherwise: yup.array().nullable(),
                }),
                other_person_present_at_session: yup.string(),
                type_of_treatment_recommended: yup.array().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup
                        .array()
                        .min(1)
                        .required("Type of treatment recommended is required"),
                    otherwise: yup.array().nullable(),
                }),
                treatment_modality_intervention: yup.array().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup
                        .array()
                        .min(1)
                        .required(
                            "Treatment modality intervention is required"
                        ),
                    otherwise: yup.array().nullable(),
                }),
                other_treatment_modality_intervention: yup.string(),
                treatment_goal_one: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup
                        .string()
                        .required("Treatment goal one is required"),
                    otherwise: yup.string(),
                }),
                treatment_goal_two: yup.string(),
                treatment_goal_three: yup.string(),
                subjective_report: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup
                        .string()
                        .required("Subjective report is required"),
                    otherwise: yup.string(),
                }),
                frequency_of_sessions: yup.string().nullable(),
                other_frequency_of_sessions: yup
                    .string()
                    .when(
                        "frequency_of_sessions",
                        (frequency_of_sessions, field) =>
                            frequency_of_sessions === "Other" &&
                            currentAction === "with_signature"
                                ? field.required(
                                      "Other frequency of sessions is required"
                                  )
                                : field
                    ),
                date_of_next_session: yup.date().nullable(),
                summary: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup.string().required("Summary is required"),
                    otherwise: yup.string(),
                }),
                prognosis: yup.string(),
                communication_factors_impacting_treatment: yup
                    .array()
                    .nullable(),
                details_around_communication_factors_impacting_treatment:
                    yup.string(),
                signature: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup.string().required("Signature is required"),
                    otherwise: yup.string(),
                }),
            })
        ),
        mode: "onChange",
        defaultValues: formValues || {
            family: [
                {
                    ...emptyFamilyIntakeNote,
                    name: appointmentDetails?.client_name || clientName,
                },
            ],
        },
    });

    const diagnosisCodes = watch("diagnosis_codes");

    // Function to handle item click and set the selected note content
    const handleLoadPreviousSession = (note: IGetClientNotes) => {
        setValue(
            "appointment_location",
            note?.note_content.appointment_location
        );
        setValue(
            "communication_factors_impacting_treatment",
            note?.note_content.communication_factors_impacting_treatment
        );
        setValue(
            "consent_was_received_for_the_teletherapy_session",
            note?.note_content.consent_was_received_for_the_teletherapy_session
        );
        setValue("delivery_option", note.note_content.delivery_option);
        setValue(
            "details_around_communication_factors_impacting_treatment",
            note.note_content
                .details_around_communication_factors_impacting_treatment
        );
        setValue("diagnosis_codes", note.note_content.diagnosis_codes);
        setValue(
            "duration",
            appointmentDetails
                ? getTimeDuration(
                      formatDate(
                          appointmentDetails?.session_start_time || "",
                          false,
                          "HH:mm"
                      ),
                      formatDate(
                          appointmentDetails?.session_end_time || "",
                          false,
                          "HH:mm"
                      )
                  )
                : ""
        );
        setValue("family", note.note_content.family);
        setValue(
            "other_delivery_option",
            note.note_content.other_delivery_option
        );
        setValue(
            "other_person_present_at_session",
            note.note_content.other_person_present_at_session
        );
        setValue(
            "other_provider_location",
            note.note_content.other_provider_location
        );
        setValue(
            "other_treatment_modality_intervention",
            note.note_content.other_treatment_modality_intervention
        );
        setValue(
            "people_present_at_session",
            note.note_content.people_present_at_session
        );
        setValue("prognosis", note.note_content.prognosis);
        setValue("provider_location", note.note_content.provider_location);
        setValue("subjective_report", note.note_content.subjective_report);

        setValue("summary", note.note_content.summary);
        setValue(
            "the_teletherapy_session_was_conducted_via_video",
            note.note_content.the_teletherapy_session_was_conducted_via_video
        );
        setValue("treatment_goal_one", note.note_content.treatment_goal_one);
        setValue(
            "treatment_goal_three",
            note.note_content.treatment_goal_three
        );
        setValue(
            "frequency_of_sessions",
            note.note_content.frequency_of_sessions
        );
        setValue(
            "other_frequency_of_sessions",
            note.note_content.other_frequency_of_sessions
        );
        setValue(
            "date_of_next_session",
            note.note_content.date_of_next_session
        );
        setValue("treatment_goal_two", note.note_content.treatment_goal_two);
        setValue(
            "treatment_modality_intervention",
            note.note_content.treatment_modality_intervention
        );
        setValue(
            "type_of_treatment_recommended",
            note.note_content.type_of_treatment_recommended
        );
        setValue(
            "what_are_the_problem_you_are_seeking_help_for",
            note.note_content.what_are_the_problem_you_are_seeking_help_for
        );
        setShowPrompt(false);
    };
    const { fields, append, remove } = useFieldArray({
        control,
        name: "family",
    });

    // Get diagnosis codes for select
    const DiagnosisCodesForSelect = data?.data.map((diagnosis) => ({
        label: `${diagnosis.code} ${diagnosis.description}`,
        value: diagnosis.code,
    }));

    // Get frequency for select
    const FrequencyForSelect = ["daily", "weekly", "monthly", "yearly"].map(
        (item) => ({
            label: makeStringFirstLetterCapital(item),
            value: item,
        })
    );

    // Get relationship options for select
    const RelationshipForSelect = Object.values(ClientRelationship).map(
        (relationship) => ({
            label: removeEnumUnderscore(
                makeStringFirstLetterCapital(relationship)
            ),
            value: relationship,
        })
    );

    // Get gender options for select
    const GenderForSelect = Object.values(ClientGender).map((gender) => ({
        label: removeEnumUnderscore(makeStringFirstLetterCapital(gender)),
        value: gender,
    }));

    // Get religion options for select
    const ReligionForSelect = Object.values(ClientReligion).map((religion) => ({
        label: removeEnumUnderscore(makeStringFirstLetterCapital(religion)),
        value: religion,
    }));

    // Get education options for select
    const EducationForSelect = Object.values(ClientEducation).map(
        (education) => ({
            label: removeEnumUnderscore(
                makeStringFirstLetterCapital(education)
            ),
            value: education,
        })
    );

    // Get sexual orientation options for select
    const SexualOrientationForSelect = Object.values(
        ClientSexualOrientation
    ).map((orientation) => ({
        label: removeEnumUnderscore(makeStringFirstLetterCapital(orientation)),
        value: orientation,
    }));

    // Get education options for select
    const RaceForSelect = Object.values(ClientRace).map((race) => ({
        label: removeEnumUnderscore(makeStringFirstLetterCapital(race)),
        value: race,
    }));

    // Get appointment location options
    const AppointmentLocationsForSelect = Object.values(
        AppointmentLocation
    ).map((location) => {
        return {
            label: removeEnumUnderscore(makeStringFirstLetterCapital(location)),
            value: location,
        };
    });

    const isCheckboxChecked = (checkboxValue: string, key: string) => {
        return Boolean(
            formValues?.[key]?.find((value: string) => value === checkboxValue)
        );
    };

    // Get filter options for diagnosis list
    const filterDiagnosisOptions = (phrases: string[]) => {
        const filteredOptions = DiagnosisCodesForSelect?.filter((option) =>
            phrases.every(
                (phrase) =>
                    option &&
                    option.label.toLowerCase().includes(phrase.toLowerCase())
            )
        );
        return filteredOptions;
    };

    return (
        <>
            <LoadPreviousSessionNotificationModal
                showPrompt={showPrompt}
                setShowPrompt={setShowPrompt}
                handleLoadPreviousSession={() =>
                    handleLoadPreviousSession(selectedNote)
                }
            />
            {notes && notes.data.length > 0 && (
                <div className="flex justify-end mb-24">
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <FilterButton text="Load previous session note" />
                        </DropdownTrigger>
                        <DropdownContent width="auto" align="end">
                            {notes &&
                                notes?.data?.map((note) => (
                                    <DropdownItem
                                        key={note.note_id}
                                        onClick={() => {
                                            setShowPrompt(true);
                                            setSelectedNote(note);
                                        }}
                                    >
                                        <div>
                                            {makeStringFirstLetterCapital(
                                                removeEnumUnderscore(
                                                    note.note_type
                                                )
                                            )}{" "}
                                            - Note content Details -{" "}
                                            {formatDate(note.date_of_service)}
                                        </div>
                                    </DropdownItem>
                                ))}
                        </DropdownContent>
                    </Dropdown>
                </div>
            )}
            <div>
                <div className="mb-24 flex items-center">
                    <p className="note-label !mb-0 !text-sm">
                        Family Count -
                        <span>
                            {fields.length || formValues?.family?.length}
                        </span>
                    </p>

                    <div className="ml-16 flex items-center gap-x-12">
                        <Button
                            onClick={() =>
                                append(emptyFamilyIntakeNote, {
                                    shouldFocus: false,
                                })
                            }
                        >
                            Add
                        </Button>
                        <Button
                            onClick={() => remove(fields.length - 1)}
                            disabled={fields.length === 1}
                            variant="secondary"
                        >
                            Remove
                        </Button>
                    </div>
                </div>
            </div>
            <h3 className="text-base font-medium mb-12">Family Intake Form</h3>
            <form
                onSubmit={onSubmit && handleSubmit(onSubmit)}
                className="pb-[60px] md:pb-[150px]"
                aria-label="family intake"
                id="create-note-form"
            >
                <div className="mb-24">
                    <NoteFormInfoBox />
                </div>
                <div className="fg">
                    <Controller
                        name="appointment_location"
                        control={control}
                        render={({ field }) => (
                            <Select
                                isRequired
                                label={
                                    FAMILY_INTAKE_NOTE_DETAILS.appointment_location
                                }
                                placeholder={
                                    FAMILY_INTAKE_NOTE_DETAILS.appointment_location
                                }
                                onChange={(val) =>
                                    field.onChange((val as Option).value)
                                }
                                value={AppointmentLocationsForSelect.find(
                                    (location) => location.value === field.value
                                )}
                                options={AppointmentLocationsForSelect}
                                hasError={!!errors.appointment_location}
                                errorText={errors.appointment_location?.message}
                                onBlur={onSubmit && handleSubmit(onSubmit)}
                            />
                        )}
                    />
                </div>
                {watch("appointment_location") ===
                    AppointmentLocation.TELETHERAPY && (
                    <>
                        <p className="note-label">
                            {FAMILY_INTAKE_NOTE_DETAILS.delivery_option}
                        </p>
                        <div className="fg fg-space-between three flex">
                            <RadioInput
                                {...register("delivery_option")}
                                label="Home"
                                value="Home"
                                defaultChecked={
                                    formValues?.delivery_option === "Home"
                                }
                                onBlur={onSubmit && handleSubmit(onSubmit)}
                            />

                            <RadioInput
                                {...register("delivery_option")}
                                label="Office"
                                value="Office"
                                defaultChecked={
                                    formValues?.delivery_option === "Office"
                                }
                                onBlur={onSubmit && handleSubmit(onSubmit)}
                            />

                            <RadioInput
                                {...register("delivery_option")}
                                label="Other"
                                value="Other"
                                defaultChecked={
                                    formValues?.delivery_option === "Other"
                                }
                                onBlur={onSubmit && handleSubmit(onSubmit)}
                            />
                        </div>
                        {errors.delivery_option ? (
                            <div className="note-error">
                                <p>Client teletherapy location is required</p>
                            </div>
                        ) : null}
                        {watch("delivery_option") === "Other" ||
                        formValues?.delivery_option === "Other" ? (
                            <div className="fg">
                                <Input
                                    {...register("other_delivery_option")}
                                    label={
                                        FAMILY_INTAKE_NOTE_DETAILS.other_delivery_option
                                    }
                                    placeholder={
                                        FAMILY_INTAKE_NOTE_DETAILS.other_delivery_option
                                    }
                                    hasError={!!errors.other_delivery_option}
                                    errorText={
                                        errors.other_delivery_option?.message
                                    }
                                    defaultValue={
                                        formValues?.other_delivery_option
                                    }
                                    onBlur={onSubmit && handleSubmit(onSubmit)}
                                />
                            </div>
                        ) : null}
                    </>
                )}

                {watch("appointment_location") ===
                    AppointmentLocation.TELETHERAPY && (
                    <>
                        <p className="note-label">
                            {FAMILY_INTAKE_NOTE_DETAILS.provider_location}
                        </p>
                        <div className="fg fg-space-between three flex">
                            <RadioInput
                                {...register("provider_location")}
                                label="Home"
                                value="Home"
                                defaultChecked={
                                    formValues?.provider_location === "Home"
                                }
                                onBlur={onSubmit && handleSubmit(onSubmit)}
                            />

                            <RadioInput
                                {...register("provider_location")}
                                label="Office"
                                value="Office"
                                defaultChecked={
                                    formValues?.provider_location === "Office"
                                }
                                onBlur={onSubmit && handleSubmit(onSubmit)}
                            />

                            <RadioInput
                                {...register("provider_location")}
                                label="Other"
                                value="Other"
                                defaultChecked={
                                    formValues?.provider_location === "Other"
                                }
                                onBlur={onSubmit && handleSubmit(onSubmit)}
                            />
                        </div>

                        {errors.provider_location ? (
                            <div className="note-error">
                                <p>Provider teletherapy location is required</p>
                            </div>
                        ) : null}
                        {watch("provider_location") === "Other" ||
                        formValues?.provider_location === "Other" ? (
                            <div className="fg">
                                <Input
                                    {...register("other_provider_location")}
                                    label={
                                        FAMILY_INTAKE_NOTE_DETAILS.other_provider_location
                                    }
                                    placeholder={
                                        FAMILY_INTAKE_NOTE_DETAILS.other_provider_location
                                    }
                                    hasError={!!errors.other_provider_location}
                                    errorText={
                                        errors.other_provider_location?.message
                                    }
                                    defaultValue={
                                        formValues?.other_provider_location
                                    }
                                    onBlur={onSubmit && handleSubmit(onSubmit)}
                                />
                            </div>
                        ) : null}
                    </>
                )}

                {watch("appointment_location") ===
                AppointmentLocation.TELETHERAPY ? (
                    <div className="note-checkboxes">
                        <FormCheckbox
                            {...register(
                                "consent_was_received_for_the_teletherapy_session"
                            )}
                            label={
                                FAMILY_INTAKE_NOTE_DETAILS.consent_was_received_for_the_teletherapy_session
                            }
                            defaultChecked={
                                formValues?.consent_was_received_for_the_teletherapy_session
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(
                                "the_teletherapy_session_was_conducted_via_video"
                            )}
                            label={
                                FAMILY_INTAKE_NOTE_DETAILS.the_teletherapy_session_was_conducted_via_video
                            }
                            defaultChecked={
                                formValues?.the_teletherapy_session_was_conducted_via_video
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                ) : null}
                <div className="fg">
                    <Input
                        {...register("duration")}
                        label={FAMILY_INTAKE_NOTE_DETAILS.duration}
                        placeholder={FAMILY_INTAKE_NOTE_DETAILS.duration}
                        disabled
                        defaultValue={
                            appointmentDetails
                                ? getTimeDuration(
                                      formatDate(
                                          appointmentDetails?.session_start_time ||
                                              "",
                                          false,
                                          "HH:mm"
                                      ),
                                      formatDate(
                                          appointmentDetails?.session_end_time ||
                                              "",
                                          false,
                                          "HH:mm"
                                      )
                                  )
                                : undefined
                        }
                        value={formValues?.duration}
                    />
                </div>
                <div className="fg">
                    <Controller
                        name="diagnosis_codes"
                        control={control}
                        render={({ field }) => (
                            <Select
                                isRequired
                                label={
                                    FAMILY_INTAKE_NOTE_DETAILS.diagnosis_codes
                                }
                                placeholder={
                                    (!data && isLoading && "Loading...") ||
                                    (error &&
                                        !isLoading &&
                                        "Error loading diagnosis") ||
                                    (data &&
                                        !isLoading &&
                                        FAMILY_INTAKE_NOTE_DETAILS.diagnosis_codes)
                                }
                                value={DiagnosisCodesForSelect?.filter(
                                    (diagnosis) =>
                                        field.value?.includes(diagnosis.value)
                                )}
                                isSearchable
                                onInputChange={(value) => setSearchValue(value)}
                                filterOption={() => true} // Disable default filtering
                                onChange={(val) => {
                                    field.onChange(
                                        (val as Option[]).map(
                                            (code) => code.value
                                        )
                                    );
                                }}
                                options={filterDiagnosisOptions(
                                    searchValue.split(" ")
                                )}
                                isDisabled={isLoading || Boolean(error)}
                                hasError={!!errors?.diagnosis_codes}
                                errorText={
                                    errors?.diagnosis_codes?.type ===
                                    "typeError"
                                        ? "Diagnosis codes are required"
                                        : errors?.diagnosis_codes?.message
                                }
                                isMulti
                                multiHasValues={
                                    diagnosisCodes && diagnosisCodes.length > 0
                                }
                                hideSelectedOptions
                                onBlur={onSubmit && handleSubmit(onSubmit)}
                            />
                        )}
                    />
                </div>
                <h3 className="note-header">Identities</h3>
                {Array.from(
                    {
                        length: fields.length || formValues?.family?.length,
                    },
                    (_, i) => (
                        <React.Fragment key={i}>
                            <p className="note-header">
                                Client {i + 1}{" "}
                                {i === 0
                                    ? `- ${
                                          appointmentDetails?.client_name ||
                                          clientName
                                      }`
                                    : ""}
                            </p>
                            <section id="step-one" className="mb-40">
                                {i !== 0 && (
                                    <div className="fg">
                                        <Input
                                            {...register(`family.${i}.name`)}
                                            label="Name"
                                            placeholder="Name"
                                            defaultValue={
                                                watch(`family.${i}.name`) || ""
                                            }
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                    </div>
                                )}
                                <div className="fg">
                                    <Controller
                                        name={`family.${i}.gender`}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                label="Gender"
                                                placeholder="Gender"
                                                value={GenderForSelect.find(
                                                    (gender) =>
                                                        gender.value ===
                                                            watch(
                                                                `family.${i}.gender`
                                                            ) || ""
                                                )}
                                                onChange={(val) =>
                                                    field.onChange(
                                                        (val as Option).value
                                                    )
                                                }
                                                options={GenderForSelect}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                <div className="fg">
                                    <Controller
                                        name={`family.${i}.religion`}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                label="Religion"
                                                placeholder="Religion"
                                                value={ReligionForSelect.find(
                                                    (religion) =>
                                                        religion.value ===
                                                            watch(
                                                                `family.${i}.religion`
                                                            ) || ""
                                                )}
                                                onChange={(val) =>
                                                    field.onChange(
                                                        (val as Option).value
                                                    )
                                                }
                                                options={ReligionForSelect}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                <div className="fg">
                                    <Controller
                                        name={`family.${i}.education`}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                label="Education"
                                                placeholder="Education"
                                                value={EducationForSelect.find(
                                                    (education) =>
                                                        education.value ===
                                                        watch(
                                                            `family.${i}.education` ||
                                                                ""
                                                        )
                                                )}
                                                onChange={(val) =>
                                                    field.onChange(
                                                        (val as Option).value
                                                    )
                                                }
                                                options={EducationForSelect}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                <div className="fg">
                                    <Controller
                                        name={`family.${i}.race`}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                label="Race"
                                                placeholder="Race"
                                                value={RaceForSelect.find(
                                                    (race) =>
                                                        race.value ===
                                                            watch(
                                                                `family.${i}.race`
                                                            ) || ""
                                                )}
                                                onChange={(val) =>
                                                    field.onChange(
                                                        (val as Option).value
                                                    )
                                                }
                                                options={RaceForSelect}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                <div className="fg">
                                    <Input
                                        {...register(`family.${i}.ethinicity`)}
                                        label="Ethnicity"
                                        placeholder="Ethnicity"
                                        defaultValue={
                                            watch(`family.${i}.ethinicity`) ||
                                            ""
                                        }
                                        onBlur={
                                            onSubmit && handleSubmit(onSubmit)
                                        }
                                    />
                                </div>
                                <div className="fg">
                                    <Controller
                                        name={`family.${i}.sexual_orientation`}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                label="Sexual orientation"
                                                placeholder="Sexual orientation"
                                                value={SexualOrientationForSelect.find(
                                                    (orientation) =>
                                                        orientation.value ===
                                                            watch(
                                                                `family.${i}.sexual_orientation`
                                                            ) || ""
                                                )}
                                                onChange={(val) =>
                                                    field.onChange(
                                                        (val as Option).value
                                                    )
                                                }
                                                options={
                                                    SexualOrientationForSelect
                                                }
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                <div className="fg">
                                    <Input
                                        {...register(
                                            `family.${i}.current_employment`
                                        )}
                                        label="Current employment"
                                        placeholder="Current employment"
                                        defaultValue={
                                            watch(
                                                `family.${i}.current_employment`
                                            ) || ""
                                        }
                                        onBlur={
                                            onSubmit && handleSubmit(onSubmit)
                                        }
                                    />
                                </div>
                                <div className="fg">
                                    <TextArea
                                        {...register(
                                            `family.${i}.current_employment_details`
                                        )}
                                        label="Details"
                                        placeholder="Details"
                                        defaultValue={
                                            watch(
                                                `family.${i}.current_employment_details`
                                            ) || ""
                                        }
                                        onBlur={
                                            onSubmit && handleSubmit(onSubmit)
                                        }
                                    />
                                </div>
                                <div className="fg">
                                    <Controller
                                        name={`family.${i}.relationship_status`}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                label="Relationship status"
                                                placeholder="Relationship status"
                                                value={RelationshipForSelect.find(
                                                    (relationship) =>
                                                        relationship.value ===
                                                            watch(
                                                                `family.${i}.relationship_status`
                                                            ) || ""
                                                )}
                                                onChange={(val) =>
                                                    field.onChange(
                                                        (val as Option).value
                                                    )
                                                }
                                                options={RelationshipForSelect}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                <div className="fg">
                                    <Input
                                        {...register(
                                            `family.${i}.name_of_partner`
                                        )}
                                        label="Name of partner"
                                        placeholder="Name of partner"
                                        defaultValue={
                                            watch(
                                                `family.${i}.name_of_partner`
                                            ) || ""
                                        }
                                        onBlur={
                                            onSubmit && handleSubmit(onSubmit)
                                        }
                                    />
                                </div>
                                <div className="fg">
                                    <Input
                                        {...register(
                                            `family.${i}.emergency_contact_information`
                                        )}
                                        label="Emergency contact information"
                                        placeholder="Emergency contact information"
                                        defaultValue={
                                            watch(
                                                `family.${i}.emergency_contact_information`
                                            ) || ""
                                        }
                                        onBlur={
                                            onSubmit && handleSubmit(onSubmit)
                                        }
                                    />
                                </div>
                                <div className="fg">
                                    <Input
                                        {...register(
                                            `family.${i}.legal_problems`
                                        )}
                                        label="Legal problems"
                                        placeholder="Legal problems"
                                        defaultValue={
                                            watch(
                                                `family.${i}.legal_problems`
                                            ) || ""
                                        }
                                        onBlur={
                                            onSubmit && handleSubmit(onSubmit)
                                        }
                                    />
                                </div>
                            </section>
                        </React.Fragment>
                    )
                )}
                <h3 className="note-label-req">Presenting Concerns</h3>
                <p className="note-label">
                    {
                        FAMILY_INTAKE_NOTE_DETAILS.what_are_the_problem_you_are_seeking_help_for
                    }
                </p>
                <div className="fg">
                    <TextArea
                        {...register(
                            `what_are_the_problem_you_are_seeking_help_for`
                        )}
                        defaultValue={
                            formValues?.what_are_the_problem_you_are_seeking_help_for
                        }
                        hasError={
                            !!errors.what_are_the_problem_you_are_seeking_help_for
                        }
                        errorText={
                            errors.what_are_the_problem_you_are_seeking_help_for
                                ?.message
                        }
                        onBlur={onSubmit && handleSubmit(onSubmit)}
                    />
                </div>
                <p className="note-label">Symptoms</p>
                {Array.from(
                    {
                        length: fields.length || formValues.family?.length,
                    },
                    (_, i) => (
                        <React.Fragment key={i}>
                            <p className="note-header">
                                Client {i + 1}{" "}
                                {i === 0
                                    ? `- ${
                                          appointmentDetails?.client_name ||
                                          clientName
                                      }`
                                    : ""}
                                {i !== 0 && watch(`family.${i}.name`)
                                    ? `- ${
                                          watch(`family.${i}.name`) ||
                                          formValues?.[i]?.name
                                      }`
                                    : ""}
                            </p>
                            <section id="step-two" className="mb-40">
                                <div className="mb-20">
                                    <Controller
                                        name={`family.${i}.anxiety`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Anxiety"
                                                name="Anxiety"
                                                checked={watch(
                                                    `family.${i}.anxiety`
                                                )}
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.checked
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                {watch(`family.${i}.anxiety`) ||
                                (formValues?.family &&
                                    formValues?.family[i]?.anxiety) ? (
                                    <div className="fg fg-space-between two flex">
                                        <Controller
                                            name={`family.${i}.anxiety_frequency`}
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    label="Frequency"
                                                    placeholder="Frequency"
                                                    onChange={(val) =>
                                                        field.onChange(
                                                            (val as Option)
                                                                .value
                                                        )
                                                    }
                                                    value={FrequencyForSelect.find(
                                                        (frequency) =>
                                                            frequency.value ===
                                                                watch(
                                                                    `family.${i}.anxiety_frequency`
                                                                ) || ""
                                                    )}
                                                    options={FrequencyForSelect}
                                                    onBlur={
                                                        onSubmit &&
                                                        handleSubmit(onSubmit)
                                                    }
                                                />
                                            )}
                                        />
                                        <TextArea
                                            {...register(
                                                `family.${i}.anxiety_details`
                                            )}
                                            placeholder="Details"
                                            label="Details"
                                            defaultValue={
                                                watch(
                                                    `family.${i}.anxiety_details`
                                                ) || ""
                                            }
                                            hasError={
                                                !!errors?.family?.[i]
                                                    ?.anxiety_details
                                            }
                                            errorText={
                                                errors?.family?.[i]
                                                    ?.anxiety_details?.message
                                            }
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                    </div>
                                ) : null}
                                <div className="mb-20">
                                    <Controller
                                        name={`family.${i}.panic_attacks`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Panic attacks"
                                                checked={watch(
                                                    `family.${i}.panic_attacks`
                                                )}
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.checked
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                {watch(`family.${i}.panic_attacks`) ||
                                (formValues?.family &&
                                    formValues?.family[i]?.panic_attacks) ? (
                                    <div className="fg fg-space-between two flex">
                                        <Controller
                                            name={`family.${i}.panic_attacks_freqency`}
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    label="Frequency"
                                                    placeholder="Frequency"
                                                    onChange={(val) =>
                                                        field.onChange(
                                                            (val as Option)
                                                                .value
                                                        )
                                                    }
                                                    value={FrequencyForSelect.find(
                                                        (frequency) =>
                                                            frequency.value ===
                                                                watch(
                                                                    `family.${i}.panic_attacks_freqency`
                                                                ) || ""
                                                    )}
                                                    options={FrequencyForSelect}
                                                    onBlur={
                                                        onSubmit &&
                                                        handleSubmit(onSubmit)
                                                    }
                                                />
                                            )}
                                        />
                                        <TextArea
                                            {...register(
                                                `family.${i}.panic_attacks_details`
                                            )}
                                            placeholder="Details"
                                            label="Details"
                                            defaultValue={
                                                watch(
                                                    `family.${i}.panic_attacks_details`
                                                ) || ""
                                            }
                                            hasError={
                                                !!errors?.family?.[i]
                                                    ?.panic_attacks_details
                                            }
                                            errorText={
                                                errors?.family?.[i]
                                                    ?.panic_attacks_details
                                                    ?.message
                                            }
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                    </div>
                                ) : null}
                                <div className="mb-20">
                                    <Controller
                                        name={`family.${i}.depression`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Depression"
                                                checked={watch(
                                                    `family.${i}.depression`
                                                )}
                                                onChange={(e) =>
                                                    field.onChange(
                                                        e.target.checked
                                                    )
                                                }
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                {watch(`family.${i}.depression`) ||
                                (formValues?.family &&
                                    formValues?.family[i]?.depression) ? (
                                    <div className="fg fg-space-between two flex">
                                        <Controller
                                            name={`family.${i}.depression_freqency`}
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    label="Frequency"
                                                    placeholder="Frequency"
                                                    onChange={(val) =>
                                                        field.onChange(
                                                            (val as Option)
                                                                .value
                                                        )
                                                    }
                                                    value={FrequencyForSelect.find(
                                                        (frequency) =>
                                                            frequency.value ===
                                                                watch(
                                                                    `family.${i}.depression_freqency`
                                                                ) || ""
                                                    )}
                                                    options={FrequencyForSelect}
                                                    onBlur={
                                                        onSubmit &&
                                                        handleSubmit(onSubmit)
                                                    }
                                                />
                                            )}
                                        />
                                        <TextArea
                                            {...register(
                                                `family.${i}.depression_details`
                                            )}
                                            placeholder="Details"
                                            label="Details"
                                            defaultValue={watch(
                                                `family.${i}.depression_details`
                                            )}
                                            hasError={
                                                !!errors?.family?.[i]
                                                    ?.depression_details
                                            }
                                            errorText={
                                                errors?.family?.[i]
                                                    ?.depression_details
                                                    ?.message
                                            }
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                    </div>
                                ) : null}
                                <div className="note-checkboxes-list">
                                    <Controller
                                        name={`family.${i}.selected_symptoms`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Easily distracted"
                                                value="Easily distracted"
                                                checked={(
                                                    watch(
                                                        `family.${i}.selected_symptoms`
                                                    ) || []
                                                )?.includes(
                                                    "Easily distracted"
                                                )}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.selected_symptoms`
                                                              ) || []),
                                                              "Easily distracted",
                                                          ]
                                                        : watch(
                                                              `family.${i}.selected_symptoms`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Easily distracted"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.selected_symptoms`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Impulsive"
                                                value="Impulsive"
                                                checked={(
                                                    watch(
                                                        `family.${i}.selected_symptoms`
                                                    ) || []
                                                )?.includes("Impulsive")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.selected_symptoms`
                                                              ) || []),
                                                              "Impulsive",
                                                          ]
                                                        : watch(
                                                              `family.${i}.selected_symptoms`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Impulsive"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.selected_symptoms`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Paranoia"
                                                value="Paranoia"
                                                checked={(
                                                    watch(
                                                        `family.${i}.selected_symptoms`
                                                    ) || []
                                                )?.includes("Paranoia")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.selected_symptoms`
                                                              ) || []),
                                                              "Paranoia",
                                                          ]
                                                        : watch(
                                                              `family.${i}.selected_symptoms`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Paranoia"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.selected_symptoms`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Alcohol"
                                                value="Alcohol"
                                                checked={(
                                                    watch(
                                                        `family.${i}.selected_symptoms`
                                                    ) || []
                                                )?.includes("Alcohol")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.selected_symptoms`
                                                              ) || []),
                                                              "Alcohol",
                                                          ]
                                                        : watch(
                                                              `family.${i}.selected_symptoms`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Alcohol"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.selected_symptoms`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Anger outburst"
                                                value="Anger outburst"
                                                checked={(
                                                    watch(
                                                        `family.${i}.selected_symptoms`
                                                    ) || []
                                                )?.includes("Anger outburst")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.selected_symptoms`
                                                              ) || []),
                                                              "Anger outburst",
                                                          ]
                                                        : watch(
                                                              `family.${i}.selected_symptoms`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Anger outburst"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.selected_symptoms`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Unable to feel pleasure"
                                                value="Unable to feel pleasure"
                                                checked={(
                                                    watch(
                                                        `family.${i}.selected_symptoms`
                                                    ) || []
                                                )?.includes(
                                                    "Unable to feel pleasure"
                                                )}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.selected_symptoms`
                                                              ) || []),
                                                              "Unable to feel pleasure",
                                                          ]
                                                        : watch(
                                                              `family.${i}.selected_symptoms`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Unable to feel pleasure"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.selected_symptoms`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Excessive energy"
                                                value="Excessive energy"
                                                checked={(
                                                    watch(
                                                        `family.${i}.selected_symptoms`
                                                    ) || []
                                                )?.includes("Excessive energy")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.selected_symptoms`
                                                              ) || []),
                                                              "Excessive energy",
                                                          ]
                                                        : watch(
                                                              `family.${i}.selected_symptoms`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Excessive energy"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.selected_symptoms`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Recreational drug use"
                                                value="Recreational drug use"
                                                checked={(
                                                    watch(
                                                        `family.${i}.selected_symptoms`
                                                    ) || []
                                                )?.includes(
                                                    "Recreational drug use"
                                                )}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.selected_symptoms`
                                                              ) || []),
                                                              "Recreational drug use",
                                                          ]
                                                        : watch(
                                                              `family.${i}.selected_symptoms`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Recreational drug use"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.selected_symptoms`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Tobacco"
                                                value="Tobacco"
                                                checked={(
                                                    watch(
                                                        `family.${i}.selected_symptoms`
                                                    ) || []
                                                )?.includes("Tobacco")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.selected_symptoms`
                                                              ) || []),
                                                              "Tobacco",
                                                          ]
                                                        : watch(
                                                              `family.${i}.selected_symptoms`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Tobacco"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.selected_symptoms`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Racing thoughts"
                                                value="Racing thoughts"
                                                checked={(
                                                    watch(
                                                        `family.${i}.selected_symptoms`
                                                    ) || []
                                                )?.includes("Racing thoughts")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.selected_symptoms`
                                                              ) || []),
                                                              "Racing thoughts",
                                                          ]
                                                        : watch(
                                                              `family.${i}.selected_symptoms`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Racing thoughts"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                <div className="fg">
                                    <TextArea
                                        {...register(
                                            `family.${i}.other_symptoms`
                                        )}
                                        placeholder="Other symptoms"
                                        label="Other symptoms"
                                        defaultValue={
                                            watch(
                                                `family.${i}.other_symptoms`
                                            ) || ""
                                        }
                                        onBlur={
                                            onSubmit && handleSubmit(onSubmit)
                                        }
                                    />
                                </div>
                                <div className="fg">
                                    <TextArea
                                        {...register(
                                            `family.${i}.physical_symptoms`
                                        )}
                                        placeholder="Physical symptoms"
                                        label="Physical symptoms"
                                        defaultValue={
                                            watch(
                                                `family.${i}.physical_symptoms`
                                            ) || ""
                                        }
                                        onBlur={
                                            onSubmit && handleSubmit(onSubmit)
                                        }
                                    />
                                </div>
                                <div className="fg">
                                    <TextArea
                                        {...register(
                                            `family.${i}.medication_physical_psychiatric`
                                        )}
                                        defaultValue={
                                            watch(
                                                `family.${i}.medication_physical_psychiatric`
                                            ) || ""
                                        }
                                        placeholder="Medication physical/psychiatric"
                                        label="Medication physical/psychiatric"
                                        onBlur={
                                            onSubmit && handleSubmit(onSubmit)
                                        }
                                    />
                                </div>
                                <div className="fg">
                                    <TextArea
                                        {...register(
                                            `family.${i}.sleeping_habits_and_concerns`
                                        )}
                                        placeholder="Sleeping habits and concerns"
                                        label="Sleeping habits and concerns"
                                        defaultValue={
                                            watch(
                                                `family.${i}.sleeping_habits_and_concerns`
                                            ) || ""
                                        }
                                        onBlur={
                                            onSubmit && handleSubmit(onSubmit)
                                        }
                                    />
                                </div>
                                <div className="fg">
                                    <TextArea
                                        {...register(
                                            `family.${i}.difficulties_with_appetite_or_eating_patterns`
                                        )}
                                        placeholder="Difficulties with appetite or eating patterns"
                                        label="Difficulties with appetite or eating patterns"
                                        defaultValue={
                                            watch(
                                                `family.${i}.difficulties_with_appetite_or_eating_patterns`
                                            ) || ""
                                        }
                                        onBlur={
                                            onSubmit && handleSubmit(onSubmit)
                                        }
                                    />
                                </div>
                            </section>
                        </React.Fragment>
                    )
                )}
                {Array.from(
                    {
                        length: fields.length || formValues?.family?.length,
                    },
                    (_, i) => (
                        <React.Fragment key={i}>
                            <section id="step-three" className="mb-40">
                                <h3 className="note-header">Family history</h3>
                                <p className="note-label">
                                    Describe family situation
                                </p>
                                <p className="note-header">
                                    Client {i + 1}{" "}
                                    {i === 0
                                        ? `- ${
                                              appointmentDetails?.client_name ||
                                              clientName
                                          }`
                                        : ""}
                                    {i !== 0 && watch(`family.${i}.name`)
                                        ? `- ${
                                              watch(`family.${i}.name`) ||
                                              formValues?.[i]?.name
                                          }`
                                        : ""}
                                </p>
                                <div className="fg">
                                    <TextArea
                                        {...register(
                                            `family.${i}.parents_stepparents_history`
                                        )}
                                        label="Parents/Stepparents"
                                        placeholder="Parents/Stepparents"
                                        defaultValue={
                                            watch(
                                                `family.${i}.parents_stepparents_history`
                                            ) || ""
                                        }
                                        onBlur={
                                            onSubmit && handleSubmit(onSubmit)
                                        }
                                    />
                                </div>
                                <div className="fg">
                                    <TextArea
                                        {...register(
                                            `family.${i}.siblings_history`
                                        )}
                                        label="Siblings"
                                        placeholder="Siblings"
                                        defaultValue={
                                            watch(
                                                `family.${i}.siblings_history`
                                            ) || ""
                                        }
                                        onBlur={
                                            onSubmit && handleSubmit(onSubmit)
                                        }
                                    />
                                </div>
                                <div className="fg">
                                    <TextArea
                                        {...register(
                                            `family.${i}.grandparents_history`
                                        )}
                                        label="Grandparents"
                                        placeholder="Grandparents"
                                        defaultValue={
                                            watch(
                                                `family.${i}.grandparents_history`
                                            ) || ""
                                        }
                                        onBlur={
                                            onSubmit && handleSubmit(onSubmit)
                                        }
                                    />
                                </div>
                                <div className="fg">
                                    <TextArea
                                        {...register(
                                            `family.${i}.children_history`
                                        )}
                                        label="Children"
                                        placeholder="Children"
                                        defaultValue={
                                            watch(
                                                `family.${i}.children_history`
                                            ) || ""
                                        }
                                        onBlur={
                                            onSubmit && handleSubmit(onSubmit)
                                        }
                                    />
                                </div>
                                <div className="fg">
                                    <TextArea
                                        {...register(
                                            `family.${i}.other_family_members_history`
                                        )}
                                        label="Other family members"
                                        placeholder="Other family members"
                                        defaultValue={
                                            watch(
                                                `family.${i}.other_family_members_history`
                                            ) || ""
                                        }
                                        onBlur={
                                            onSubmit && handleSubmit(onSubmit)
                                        }
                                    />
                                </div>
                                <h3 className="note-header">
                                    Client {i + 1} - Family Psychiatric History
                                </h3>
                                <p className="note-label">
                                    Select which family members the below
                                    situation apply to
                                </p>
                                <h3 className="note-label">Alcohol abuse</h3>
                                <div className="note-checkboxes-list">
                                    <Controller
                                        name={`family.${i}.family_members_alcohol_abuse`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="All"
                                                value="All"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_alcohol_abuse`
                                                    ) || []
                                                )?.includes("All")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_alcohol_abuse`
                                                              ) || []),
                                                              "All",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_alcohol_abuse`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "All"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.family_members_alcohol_abuse`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Father"
                                                value="Father"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_alcohol_abuse`
                                                    ) || []
                                                )?.includes("Father")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_alcohol_abuse`
                                                              ) || []),
                                                              "Father",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_alcohol_abuse`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Father"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.family_members_alcohol_abuse`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Mother"
                                                value="Mother"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_alcohol_abuse`
                                                    ) || []
                                                )?.includes("Mother")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_alcohol_abuse`
                                                              ) || []),
                                                              "Mother",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_alcohol_abuse`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Mother"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.family_members_alcohol_abuse`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Child/Children"
                                                value="Child/Children"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_alcohol_abuse`
                                                    ) || []
                                                )?.includes("Child/Children")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_alcohol_abuse`
                                                              ) || []),
                                                              "Child/Children",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_alcohol_abuse`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Child/Children"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.family_members_alcohol_abuse`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Brother"
                                                value="Brother"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_alcohol_abuse`
                                                    ) || []
                                                )?.includes("Brother")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_alcohol_abuse`
                                                              ) || []),
                                                              "Brother",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_alcohol_abuse`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Brother"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.family_members_alcohol_abuse`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Sister"
                                                value="Sister"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_alcohol_abuse`
                                                    ) || []
                                                )?.includes("Sister")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_alcohol_abuse`
                                                              ) || []),
                                                              "Sister",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_alcohol_abuse`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Sister"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.family_members_alcohol_abuse`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Other"
                                                value="Other"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_alcohol_abuse`
                                                    ) || []
                                                )?.includes("Other")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_alcohol_abuse`
                                                              ) || []),
                                                              "Other",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_alcohol_abuse`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Other"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                <h3 className="note-label">Anxiety</h3>
                                <div className="note-checkboxes-list">
                                    <Controller
                                        name={`family.${i}.family_members_anxiety`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="All"
                                                value="All"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_anxiety`
                                                    ) || []
                                                )?.includes("All")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_anxiety`
                                                              ) || []),
                                                              "All",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_anxiety`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "All"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.family_members_anxiety`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Father"
                                                value="Father"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_anxiety`
                                                    ) || []
                                                )?.includes("Father")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_anxiety`
                                                              ) || []),
                                                              "Father",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_anxiety`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Father"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.family_members_anxiety`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Mother"
                                                value="Mother"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_anxiety`
                                                    ) || []
                                                )?.includes("Mother")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_anxiety`
                                                              ) || []),
                                                              "Mother",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_anxiety`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Mother"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.family_members_anxiety`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Child/Children"
                                                value="Child/Children"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_anxiety`
                                                    ) || []
                                                )?.includes("Child/Children")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_anxiety`
                                                              ) || []),
                                                              "Child/Children",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_anxiety`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Child/Children"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.family_members_anxiety`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Brother"
                                                value="Brother"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_anxiety`
                                                    ) || []
                                                )?.includes("Brother")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_anxiety`
                                                              ) || []),
                                                              "Brother",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_anxiety`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Brother"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.family_members_anxiety`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Sister"
                                                value="Sister"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_anxiety`
                                                    ) || []
                                                )?.includes("Sister")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_anxiety`
                                                              ) || []),
                                                              "Sister",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_anxiety`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Sister"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.family_members_anxiety`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Other"
                                                value="Other"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_anxiety`
                                                    ) || []
                                                )?.includes("Other")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_anxiety`
                                                              ) || []),
                                                              "Other",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_anxiety`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Other"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                <h3 className="note-label">Bipolar Disorder</h3>
                                <div className="note-checkboxes-list">
                                    <Controller
                                        name={`family.${i}.family_members_bipolar_disorder`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="All"
                                                value="All"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_bipolar_disorder`
                                                    ) || []
                                                )?.includes("All")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_bipolar_disorder`
                                                              ) || []),
                                                              "All",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_bipolar_disorder`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "All"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.family_members_bipolar_disorder`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Father"
                                                value="Father"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_bipolar_disorder`
                                                    ) || []
                                                )?.includes("Father")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_bipolar_disorder`
                                                              ) || []),
                                                              "Father",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_bipolar_disorder`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Father"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.family_members_bipolar_disorder`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Mother"
                                                value="Mother"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_bipolar_disorder`
                                                    ) || []
                                                )?.includes("Mother")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_bipolar_disorder`
                                                              ) || []),
                                                              "Mother",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_bipolar_disorder`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Mother"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.family_members_bipolar_disorder`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Child/Children"
                                                value="Child/Children"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_bipolar_disorder`
                                                    ) || []
                                                )?.includes("Child/Children")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_bipolar_disorder`
                                                              ) || []),
                                                              "Child/Children",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_bipolar_disorder`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Child/Children"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.family_members_bipolar_disorder`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Brother"
                                                value="Brother"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_bipolar_disorder`
                                                    ) || []
                                                )?.includes("Brother")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_bipolar_disorder`
                                                              ) || []),
                                                              "Brother",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_bipolar_disorder`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Brother"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.family_members_bipolar_disorder`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Sister"
                                                value="Sister"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_bipolar_disorder`
                                                    ) || []
                                                )?.includes("Sister")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_bipolar_disorder`
                                                              ) || []),
                                                              "Sister",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_bipolar_disorder`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Sister"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.family_members_bipolar_disorder`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Other"
                                                value="Other"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_bipolar_disorder`
                                                    ) || []
                                                )?.includes("Other")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_bipolar_disorder`
                                                              ) || []),
                                                              "Other",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_bipolar_disorder`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Other"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                <h3 className="note-label">Depression</h3>
                                <div className="note-checkboxes-list">
                                    <Controller
                                        name={`family.${i}.family_members_depression`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="All"
                                                value="All"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_depression`
                                                    ) || []
                                                )?.includes("All")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_depression`
                                                              ) || []),
                                                              "All",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_depression`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "All"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.family_members_depression`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Father"
                                                value="Father"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_depression`
                                                    ) || []
                                                )?.includes("Father")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_depression`
                                                              ) || []),
                                                              "Father",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_depression`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Father"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.family_members_depression`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Mother"
                                                value="Mother"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_depression`
                                                    ) || []
                                                )?.includes("Mother")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_depression`
                                                              ) || []),
                                                              "Mother",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_depression`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Mother"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.family_members_depression`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Child/Children"
                                                value="Child/Children"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_depression`
                                                    ) || []
                                                )?.includes("Child/Children")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_depression`
                                                              ) || []),
                                                              "Child/Children",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_depression`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Child/Children"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.family_members_depression`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Brother"
                                                value="Brother"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_depression`
                                                    ) || []
                                                )?.includes("Brother")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_depression`
                                                              ) || []),
                                                              "Brother",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_depression`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Brother"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.family_members_depression`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Sister"
                                                value="Sister"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_depression`
                                                    ) || []
                                                )?.includes("Sister")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_depression`
                                                              ) || []),
                                                              "Sister",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_depression`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Sister"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.family_members_depression`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Other"
                                                value="Other"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_depression`
                                                    ) || []
                                                )?.includes("Other")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_depression`
                                                              ) || []),
                                                              "Other",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_depression`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Other"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                <h3 className="note-label">PTSD</h3>
                                <div className="note-checkboxes-list">
                                    <Controller
                                        name={`family.${i}.family_members_ptsd`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="All"
                                                value="All"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_ptsd`
                                                    ) || []
                                                )?.includes("All")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_ptsd`
                                                              ) || []),
                                                              "All",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_ptsd`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "All"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.family_members_ptsd`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Father"
                                                value="Father"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_ptsd`
                                                    ) || []
                                                )?.includes("Father")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_ptsd`
                                                              ) || []),
                                                              "Father",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_ptsd`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Father"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.family_members_ptsd`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Mother"
                                                value="Mother"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_ptsd`
                                                    ) || []
                                                )?.includes("Mother")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_ptsd`
                                                              ) || []),
                                                              "Mother",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_ptsd`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Mother"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.family_members_ptsd`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Child/Children"
                                                value="Child/Children"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_ptsd`
                                                    ) || []
                                                )?.includes("Child/Children")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_ptsd`
                                                              ) || []),
                                                              "Child/Children",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_ptsd`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Child/Children"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.family_members_ptsd`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Brother"
                                                value="Brother"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_ptsd`
                                                    ) || []
                                                )?.includes("Brother")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_ptsd`
                                                              ) || []),
                                                              "Brother",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_ptsd`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Brother"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.family_members_ptsd`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Sister"
                                                value="Sister"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_ptsd`
                                                    ) || []
                                                )?.includes("Sister")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_ptsd`
                                                              ) || []),
                                                              "Sister",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_ptsd`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Sister"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.family_members_ptsd`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Other"
                                                value="Other"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_ptsd`
                                                    ) || []
                                                )?.includes("Other")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_ptsd`
                                                              ) || []),
                                                              "Other",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_ptsd`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Other"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                <h3 className="note-label">Schizophrenia</h3>
                                <div className="note-checkboxes-list">
                                    <Controller
                                        name={`family.${i}.family_members_schizophrenia`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="All"
                                                value="All"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_schizophrenia`
                                                    ) || []
                                                )?.includes("All")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_schizophrenia`
                                                              ) || []),
                                                              "All",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_schizophrenia`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "All"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.family_members_schizophrenia`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Father"
                                                value="Father"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_schizophrenia`
                                                    ) || []
                                                )?.includes("Father")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_schizophrenia`
                                                              ) || []),
                                                              "Father",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_schizophrenia`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Father"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.family_members_schizophrenia`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Mother"
                                                value="Mother"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_schizophrenia`
                                                    ) || []
                                                )?.includes("Mother")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_schizophrenia`
                                                              ) || []),
                                                              "Mother",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_schizophrenia`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Mother"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.family_members_schizophrenia`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Child/Children"
                                                value="Child/Children"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_schizophrenia`
                                                    ) || []
                                                )?.includes("Child/Children")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_schizophrenia`
                                                              ) || []),
                                                              "Child/Children",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_schizophrenia`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Child/Children"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.family_members_schizophrenia`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Brother"
                                                value="Brother"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_schizophrenia`
                                                    ) || []
                                                )?.includes("Brother")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_schizophrenia`
                                                              ) || []),
                                                              "Brother",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_schizophrenia`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Brother"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.family_members_schizophrenia`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Sister"
                                                value="Sister"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_schizophrenia`
                                                    ) || []
                                                )?.includes("Sister")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_schizophrenia`
                                                              ) || []),
                                                              "Sister",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_schizophrenia`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Sister"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.family_members_schizophrenia`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Other"
                                                value="Other"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_schizophrenia`
                                                    ) || []
                                                )?.includes("Other")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_schizophrenia`
                                                              ) || []),
                                                              "Other",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_schizophrenia`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Other"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                <h3 className="note-label">Substance use</h3>
                                <div className="note-checkboxes-list">
                                    <Controller
                                        name={`family.${i}.family_members_substance_abuse`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="All"
                                                value="All"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_substance_abuse`
                                                    ) || []
                                                )?.includes("All")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_substance_abuse`
                                                              ) || []),
                                                              "All",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_substance_abuse`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "All"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.family_members_substance_abuse`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Father"
                                                value="Father"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_substance_abuse`
                                                    ) || []
                                                )?.includes("Father")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_substance_abuse`
                                                              ) || []),
                                                              "Father",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_substance_abuse`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Father"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.family_members_substance_abuse`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Mother"
                                                value="Mother"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_substance_abuse`
                                                    ) || []
                                                )?.includes("Mother")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_substance_abuse`
                                                              ) || []),
                                                              "Mother",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_substance_abuse`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Mother"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.family_members_substance_abuse`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Child/Children"
                                                value="Child/Children"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_substance_abuse`
                                                    ) || []
                                                )?.includes("Child/Children")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_substance_abuse`
                                                              ) || []),
                                                              "Child/Children",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_substance_abuse`
                                                          ).filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Child/Children"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.family_members_substance_abuse`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Brother"
                                                value="Brother"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_substance_abuse`
                                                    ) || []
                                                )?.includes("Brother")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_substance_abuse`
                                                              ) || []),
                                                              "Brother",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_substance_abuse`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Brother"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.family_members_substance_abuse`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Sister"
                                                value="Sister"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_substance_abuse`
                                                    ) || []
                                                )?.includes("Sister")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_substance_abuse`
                                                              ) || []),
                                                              "Sister",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_substance_abuse`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Sister"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.family_members_substance_abuse`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormCheckbox
                                                label="Other"
                                                value="Other"
                                                checked={(
                                                    watch(
                                                        `family.${i}.family_members_substance_abuse`
                                                    ) || []
                                                )?.includes("Other")}
                                                onChange={(e) => {
                                                    const updatedValue = e
                                                        .target.checked
                                                        ? [
                                                              ...(watch(
                                                                  `family.${i}.family_members_substance_abuse`
                                                              ) || []),
                                                              "Other",
                                                          ]
                                                        : watch(
                                                              `family.${i}.family_members_substance_abuse`
                                                          )?.filter(
                                                              (symptom) =>
                                                                  symptom !==
                                                                  "Other"
                                                          );
                                                    field.onChange(
                                                        updatedValue
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                <div className="fg">
                                    <Input
                                        {...register(
                                            `family.${i}.family_members_substance_abuse_substance`
                                        )}
                                        label="Specify substance"
                                        placeholder="Specify substance"
                                        defaultValue={
                                            watch(
                                                `family.${i}.family_members_substance_abuse_substance`
                                            ) || ""
                                        }
                                        onBlur={
                                            onSubmit && handleSubmit(onSubmit)
                                        }
                                    />
                                </div>
                            </section>
                        </React.Fragment>
                    )
                )}
                <h3 className="note-header">Mental health history</h3>
                {Array.from(
                    {
                        length: fields.length || formValues?.family?.length,
                    },
                    (_, i) => (
                        <React.Fragment key={i}>
                            <section id="step-four" className="mb-40">
                                <p className="note-header">
                                    Client {i + 1}{" "}
                                    {i === 0
                                        ? `- ${
                                              appointmentDetails?.client_name ||
                                              clientName
                                          }`
                                        : ""}
                                    {i !== 0 && watch(`family.${i}.name`)
                                        ? `- ${
                                              watch(`family.${i}.name`) ||
                                              formValues?.[i]?.name
                                          }`
                                        : ""}
                                </p>
                                <div className="fg">
                                    <TextArea
                                        {...register(
                                            `family.${i}.trauma_abuse_history`
                                        )}
                                        label="Trauma/abuse history"
                                        placeholder="Trauma/abuse history"
                                        defaultValue={
                                            watch(
                                                `family.${i}.trauma_abuse_history`
                                            ) || ""
                                        }
                                        onBlur={
                                            onSubmit && handleSubmit(onSubmit)
                                        }
                                    />
                                </div>
                                <div className="fg">
                                    <TextArea
                                        {...register(
                                            `family.${i}.previous_mental_health_treatment_oupatient`
                                        )}
                                        label="Previous Mental Health Treatment oupatient"
                                        placeholder="Previous Mental Health Treatment oupatient"
                                        defaultValue={
                                            watch(
                                                `family.${i}.previous_mental_health_treatment_oupatient`
                                            ) || ""
                                        }
                                        onBlur={
                                            onSubmit && handleSubmit(onSubmit)
                                        }
                                    />
                                </div>
                                <div className="fg">
                                    <TextArea
                                        {...register(
                                            `family.${i}.impatient_psychiatric_hospitalization`
                                        )}
                                        label="Impatient/Psychiatric Hospitalization"
                                        placeholder="Impatient/Psychiatric Hospitalization"
                                        defaultValue={
                                            watch(
                                                `family.${i}.impatient_psychiatric_hospitalization`
                                            ) || ""
                                        }
                                        onBlur={
                                            onSubmit && handleSubmit(onSubmit)
                                        }
                                    />
                                </div>
                                <div className="fg">
                                    <TextArea
                                        {...register(
                                            `family.${i}.relevant_medical_history`
                                        )}
                                        label="Relevant Medical History"
                                        placeholder="Relevant Medical History"
                                        defaultValue={
                                            watch(
                                                `family.${i}.relevant_medical_history`
                                            ) || ""
                                        }
                                        onBlur={
                                            onSubmit && handleSubmit(onSubmit)
                                        }
                                    />
                                </div>
                            </section>
                        </React.Fragment>
                    )
                )}
                {Array.from(
                    {
                        length: fields.length || formValues?.family?.length,
                    },
                    (_, i) => (
                        <React.Fragment key={i}>
                            <section id="step-five" className="mb-40">
                                <h3 className="note-header">
                                    Suicide risk assessment
                                </h3>
                                <p className="note-header">
                                    Client {i + 1}{" "}
                                    {i === 0
                                        ? `- ${
                                              appointmentDetails?.client_name ||
                                              clientName
                                          }`
                                        : ""}
                                    {i !== 0 && watch(`family.${i}.name`)
                                        ? `- ${
                                              watch(`family.${i}.name`) ||
                                              formValues?.[i]?.name
                                          }`
                                        : ""}
                                </p>
                                <p className="note-label-req">
                                    1. Been so distressed you seriously wished
                                    to end your life?
                                </p>
                                <div className="note-radios">
                                    <Controller
                                        name={`family.${i}.been_so_distressed_you_seriously_wished_to_end_your_life`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="No"
                                                value="No"
                                                name={`family.${i}.been_so_distressed_you_seriously_wished_to_end_your_life`}
                                                checked={
                                                    watch(
                                                        `family.${i}.been_so_distressed_you_seriously_wished_to_end_your_life`
                                                    ) === "No"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.been_so_distressed_you_seriously_wished_to_end_your_life`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="Today"
                                                value="Today"
                                                name={`family.${i}.been_so_distressed_you_seriously_wished_to_end_your_life`}
                                                checked={
                                                    watch(
                                                        `family.${i}.been_so_distressed_you_seriously_wished_to_end_your_life`
                                                    ) === "Today"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.been_so_distressed_you_seriously_wished_to_end_your_life`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="Recently"
                                                value="Recently"
                                                name={`family.${i}.been_so_distressed_you_seriously_wished_to_end_your_life`}
                                                checked={
                                                    watch(
                                                        `family.${i}.been_so_distressed_you_seriously_wished_to_end_your_life`
                                                    ) === "Recently"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                {errors.family?.[i]
                                    ?.been_so_distressed_you_seriously_wished_to_end_your_life ? (
                                    <div className="note-error">
                                        <p>This field is required</p>
                                    </div>
                                ) : null}
                                <p className="note-label-req">
                                    2. Has anything happened recently to make
                                    you feel you don’t want to live?
                                </p>
                                <div className="note-radios">
                                    <Controller
                                        name={`family.${i}.has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="No"
                                                value="No"
                                                name={`family.${i}.has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live`}
                                                checked={
                                                    watch(
                                                        `family.${i}.has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live`
                                                    ) === "No"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="Yes"
                                                value="Yes"
                                                name={`family.${i}.has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live`}
                                                checked={
                                                    watch(
                                                        `family.${i}.has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live`
                                                    ) === "Yes"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                {errors.family?.[i]
                                    ?.has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live ? (
                                    <div className="note-error">
                                        <p>This field is required</p>
                                    </div>
                                ) : null}
                                <div className="fg">
                                    <TextArea
                                        {...register(
                                            `family.${i}.describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live`
                                        )}
                                        placeholder="Describe the situation"
                                        label="Describe the situation"
                                        defaultValue={
                                            watch(
                                                `family.${i}.describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live`
                                            ) || ""
                                        }
                                        errorText={
                                            errors.family?.[i]
                                                ?.describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live
                                                ?.message
                                        }
                                        hasError={
                                            !!errors.family?.[i]
                                                ?.describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live
                                        }
                                        onBlur={
                                            onSubmit && handleSubmit(onSubmit)
                                        }
                                    />
                                </div>
                                <p className="note-header">
                                    Have you had/Do you have -
                                </p>
                                <p className="note-label-req">
                                    3. A specific plan of how you would kill
                                    yourself?
                                </p>
                                <div className="note-radios">
                                    <Controller
                                        name={`family.${i}.a_specific_plan_of_how_you_would_kill_yourself`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="No"
                                                value="No"
                                                name={`family.${i}.a_specific_plan_of_how_you_would_kill_yourself`}
                                                checked={
                                                    watch(
                                                        `family.${i}.a_specific_plan_of_how_you_would_kill_yourself`
                                                    ) === "No"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.a_specific_plan_of_how_you_would_kill_yourself`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="Yes"
                                                value="Yes"
                                                name={`family.${i}.a_specific_plan_of_how_you_would_kill_yourself`}
                                                checked={
                                                    watch(
                                                        `family.${i}.a_specific_plan_of_how_you_would_kill_yourself`
                                                    ) === "Yes"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                {errors.family?.[i]
                                    ?.a_specific_plan_of_how_you_would_kill_yourself ? (
                                    <div className="note-error">
                                        <p>This field is required</p>
                                    </div>
                                ) : null}
                                <p className="note-label-req">
                                    4. Access to weapons or means of hurting
                                    self?
                                </p>
                                <div className="note-radios">
                                    <Controller
                                        name={`family.${i}.access_to_weapons_or_means_of_hurting_self`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="No"
                                                value="No"
                                                name={`family.${i}.access_to_weapons_or_means_of_hurting_self`}
                                                checked={
                                                    watch(
                                                        `family.${i}.access_to_weapons_or_means_of_hurting_self`
                                                    ) === "No"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.access_to_weapons_or_means_of_hurting_self`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="Yes"
                                                value="Yes"
                                                name={`family.${i}.access_to_weapons_or_means_of_hurting_self`}
                                                checked={
                                                    watch(
                                                        `family.${i}.access_to_weapons_or_means_of_hurting_self`
                                                    ) === "Yes"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                {errors.family?.[i]
                                    ?.access_to_weapons_or_means_of_hurting_self ? (
                                    <div className="note-error">
                                        <p>This field is required</p>
                                    </div>
                                ) : null}
                                <p className="note-label-req">
                                    5. Made serious suicide attempts
                                </p>
                                <div className="note-radios">
                                    <Controller
                                        name={`family.${i}.made_serious_suicide_attempts`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="No"
                                                value="No"
                                                name={`family.${i}.made_serious_suicide_attempts`}
                                                checked={
                                                    watch(
                                                        `family.${i}.made_serious_suicide_attempts`
                                                    ) === "No"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.made_serious_suicide_attempts`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="Yes"
                                                value="Yes"
                                                name={`family.${i}.made_serious_suicide_attempts`}
                                                checked={
                                                    watch(
                                                        `family.${i}.made_serious_suicide_attempts`
                                                    ) === "Yes"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                {errors.family?.[i]
                                    ?.made_serious_suicide_attempts ? (
                                    <div className="note-error">
                                        <p>This field is required</p>
                                    </div>
                                ) : null}
                                <p className="note-label-req">
                                    6. Purposely done something to hurt
                                    yourself?
                                </p>
                                <div className="note-radios">
                                    <Controller
                                        name={`family.${i}.purposely_done_something_to_hurt_yourself`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="No"
                                                value="No"
                                                name={`family.${i}.purposely_done_something_to_hurt_yourself`}
                                                checked={
                                                    watch(
                                                        `family.${i}.purposely_done_something_to_hurt_yourself`
                                                    ) === "No"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.purposely_done_something_to_hurt_yourself`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="Yes"
                                                value="Yes"
                                                name={`family.${i}.purposely_done_something_to_hurt_yourself`}
                                                checked={
                                                    watch(
                                                        `family.${i}.purposely_done_something_to_hurt_yourself`
                                                    ) === "Yes"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                {errors.family?.[i]
                                    ?.purposely_done_something_to_hurt_yourself ? (
                                    <div className="note-error">
                                        <p>This field is required</p>
                                    </div>
                                ) : null}
                                <div className="fg">
                                    <TextArea
                                        {...register(
                                            `family.${i}.describe_done_something_to_hurt_yourself`
                                        )}
                                        placeholder="Describe the situation"
                                        label="Describe the situation"
                                        defaultValue={
                                            watch(
                                                `family.${i}.describe_done_something_to_hurt_yourself`
                                            ) || ""
                                        }
                                        hasError={
                                            !!errors.family?.[i]
                                                ?.describe_done_something_to_hurt_yourself
                                        }
                                        errorText={
                                            errors.family?.[i]
                                                ?.describe_done_something_to_hurt_yourself
                                                ?.message
                                        }
                                        onBlur={
                                            onSubmit && handleSubmit(onSubmit)
                                        }
                                    />
                                </div>
                                <p className="note-label-req">
                                    7. Heard voices telling you to hurt
                                    yourself?
                                </p>
                                <div className="note-radios">
                                    <Controller
                                        name={`family.${i}.heard_voices_telling_you_to_hurt_yourself`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="No"
                                                value="No"
                                                name={`family.${i}.heard_voices_telling_you_to_hurt_yourself`}
                                                checked={
                                                    watch(
                                                        `family.${i}.heard_voices_telling_you_to_hurt_yourself`
                                                    ) === "No"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.heard_voices_telling_you_to_hurt_yourself`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="Yes"
                                                value="Yes"
                                                name={`family.${i}.heard_voices_telling_you_to_hurt_yourself`}
                                                checked={
                                                    watch(
                                                        `family.${i}.heard_voices_telling_you_to_hurt_yourself`
                                                    ) === "Yes"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                {errors.family?.[i]
                                    ?.heard_voices_telling_you_to_hurt_yourself ? (
                                    <div className="note-error">
                                        <p>This field is required</p>
                                    </div>
                                ) : null}
                                <p className="note-label-req">
                                    8. Had relatives who attempted or commited
                                    sucide?
                                </p>
                                <div className="note-radios">
                                    <Controller
                                        name={`family.${i}.had_relatives_who_attempted_or_commited_sucide`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="No"
                                                value="No"
                                                name={`family.${i}.had_relatives_who_attempted_or_commited_sucide`}
                                                checked={
                                                    watch(
                                                        `family.${i}.had_relatives_who_attempted_or_commited_sucide`
                                                    ) === "No"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.had_relatives_who_attempted_or_commited_sucide`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="Yes"
                                                value="Yes"
                                                name={`family.${i}.had_relatives_who_attempted_or_commited_sucide`}
                                                checked={
                                                    watch(
                                                        `family.${i}.had_relatives_who_attempted_or_commited_sucide`
                                                    ) === "Yes"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                {errors.family?.[i]
                                    ?.had_relatives_who_attempted_or_commited_sucide ? (
                                    <div className="note-error">
                                        <p>This field is required</p>
                                    </div>
                                ) : null}
                                <p className="note-label-req">
                                    9. Had thoughts of killing or seriously
                                    hurting someone?
                                </p>
                                <div className="note-radios">
                                    <Controller
                                        name={`family.${i}.had_thoughts_of_killing_or_seriously_hurting_someone`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="No"
                                                value="No"
                                                name={`family.${i}.had_thoughts_of_killing_or_seriously_hurting_someone`}
                                                checked={
                                                    watch(
                                                        `family.${i}.had_thoughts_of_killing_or_seriously_hurting_someone`
                                                    ) === "No"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.had_thoughts_of_killing_or_seriously_hurting_someone`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="Yes"
                                                value="Yes"
                                                name={`family.${i}.had_thoughts_of_killing_or_seriously_hurting_someone`}
                                                checked={
                                                    watch(
                                                        `family.${i}.had_thoughts_of_killing_or_seriously_hurting_someone`
                                                    ) === "Yes"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                {errors.family?.[i]
                                    ?.had_thoughts_of_killing_or_seriously_hurting_someone ? (
                                    <div className="note-error">
                                        <p>This field is required</p>
                                    </div>
                                ) : null}
                                <p className="note-label-req">
                                    10. Heard voices telling you to hurt others?
                                </p>
                                <div className="note-radios">
                                    <Controller
                                        name={`family.${i}.heard_voices_telling_you_to_hurt_others`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="No"
                                                value="No"
                                                name={`family.${i}.heard_voices_telling_you_to_hurt_others`}
                                                checked={
                                                    watch(
                                                        `family.${i}.heard_voices_telling_you_to_hurt_others`
                                                    ) === "No"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`family.${i}.heard_voices_telling_you_to_hurt_others`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="Yes"
                                                value="Yes"
                                                name={`family.${i}.heard_voices_telling_you_to_hurt_others`}
                                                checked={
                                                    watch(
                                                        `family.${i}.heard_voices_telling_you_to_hurt_others`
                                                    ) === "Yes"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                {errors.family?.[i]
                                    ?.heard_voices_telling_you_to_hurt_others ? (
                                    <div className="note-error">
                                        <p>This field is required</p>
                                    </div>
                                ) : null}
                                <p className="note-label-req">
                                    11. Hurt someone or destroyed property on
                                    purpose?
                                </p>
                                <div className="note-radios">
                                    <Controller
                                        name={`family.${i}.hurt_someone_or_destroyed_property_on_purpose`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="No"
                                                value="No"
                                                name={`family.${i}.hurt_someone_or_destroyed_property_on_purpose`}
                                                checked={
                                                    watch(
                                                        `family.${i}.hurt_someone_or_destroyed_property_on_purpose`
                                                    ) === "No"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.hurt_someone_or_destroyed_property_on_purpose`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="Yes"
                                                value="Yes"
                                                name={`family.${i}.hurt_someone_or_destroyed_property_on_purpose`}
                                                checked={
                                                    watch(
                                                        `family.${i}.hurt_someone_or_destroyed_property_on_purpose`
                                                    ) === "Yes"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                {errors.family?.[i]
                                    ?.hurt_someone_or_destroyed_property_on_purpose ? (
                                    <div className="note-error">
                                        <p>This field is required</p>
                                    </div>
                                ) : null}
                                <p className="note-label-req">
                                    12. Slapped, kicked, punched someone with
                                    intent to harm?
                                </p>
                                <div className="note-radios">
                                    <Controller
                                        name={`family.${i}.slapped_kicked_punched_someone_with_intent_to_harm`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="No"
                                                value="No"
                                                name={`family.${i}.slapped_kicked_punched_someone_with_intent_to_harm`}
                                                checked={
                                                    watch(
                                                        `family.${i}.slapped_kicked_punched_someone_with_intent_to_harm`
                                                    ) === "No"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.slapped_kicked_punched_someone_with_intent_to_harm`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="Yes"
                                                value="Yes"
                                                name={`family.${i}.slapped_kicked_punched_someone_with_intent_to_harm`}
                                                checked={
                                                    watch(
                                                        `family.${i}.slapped_kicked_punched_someone_with_intent_to_harm`
                                                    ) === "Yes"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                {errors.family?.[i]
                                    ?.slapped_kicked_punched_someone_with_intent_to_harm ? (
                                    <div className="note-error">
                                        <p>This field is required</p>
                                    </div>
                                ) : null}
                                <p className="note-label-req">
                                    13. Been arrested or detained for violent
                                    behavior?
                                </p>
                                <div className="note-radios">
                                    <Controller
                                        name={`family.${i}.been_arrested_or_detained_for_violent_behavior`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="No"
                                                value="No"
                                                name={`family.${i}.been_arrested_or_detained_for_violent_behavior`}
                                                checked={
                                                    watch(
                                                        `family.${i}.been_arrested_or_detained_for_violent_behavior`
                                                    ) === "No"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.been_arrested_or_detained_for_violent_behavior`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="Yes"
                                                value="Yes"
                                                name={`family.${i}.been_arrested_or_detained_for_violent_behavior`}
                                                checked={
                                                    watch(
                                                        `family.${i}.been_arrested_or_detained_for_violent_behavior`
                                                    ) === "Yes"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                {errors.family?.[i]
                                    ?.been_arrested_or_detained_for_violent_behavior ? (
                                    <div className="note-error">
                                        <p>This field is required</p>
                                    </div>
                                ) : null}
                                <p className="note-label-req">
                                    14. Been to jail for any reason?
                                </p>
                                <div className="note-radios">
                                    <Controller
                                        name={`family.${i}.been_to_jail_for_any_reason`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="No"
                                                value="No"
                                                name={`family.${i}.been_to_jail_for_any_reason`}
                                                checked={
                                                    watch(
                                                        `family.${i}.been_to_jail_for_any_reason`
                                                    ) === "No"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.been_to_jail_for_any_reason`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="Yes"
                                                value="Yes"
                                                name={`family.${i}.been_to_jail_for_any_reason`}
                                                checked={
                                                    watch(
                                                        `family.${i}.been_to_jail_for_any_reason`
                                                    ) === "Yes"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                {errors.family?.[i]
                                    ?.been_to_jail_for_any_reason ? (
                                    <div className="note-error">
                                        <p>This field is required</p>
                                    </div>
                                ) : null}
                                <p className="note-label-req">
                                    15. Been on probation for any reason?
                                </p>
                                <div className="note-radios">
                                    <Controller
                                        name={`family.${i}.been_on_probation_for_any_reason`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="No"
                                                value="No"
                                                name={`family.${i}.been_on_probation_for_any_reason`}
                                                checked={
                                                    watch(
                                                        `family.${i}.been_on_probation_for_any_reason`
                                                    ) === "No"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.been_on_probation_for_any_reason`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="Yes"
                                                value="Yes"
                                                name={`family.${i}.been_on_probation_for_any_reason`}
                                                checked={
                                                    watch(
                                                        `family.${i}.been_on_probation_for_any_reason`
                                                    ) === "Yes"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                {errors.family?.[i]
                                    ?.been_on_probation_for_any_reason ? (
                                    <div className="note-error">
                                        <p>This field is required</p>
                                    </div>
                                ) : null}
                                <p className="note-label-req">
                                    16. Do you have access to guns?
                                </p>
                                <div className="note-radios">
                                    <Controller
                                        name={`family.${i}.do_you_have_access_to_guns`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="No"
                                                value="No"
                                                name={`family.${i}.do_you_have_access_to_guns`}
                                                checked={
                                                    watch(
                                                        `family.${i}.do_you_have_access_to_guns`
                                                    ) === "No"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`family.${i}.do_you_have_access_to_guns`}
                                        control={control}
                                        render={({ field }) => (
                                            <RadioInput
                                                label="Yes"
                                                value="Yes"
                                                name={`family.${i}.do_you_have_access_to_guns`}
                                                checked={
                                                    watch(
                                                        `family.${i}.do_you_have_access_to_guns`
                                                    ) === "Yes"
                                                }
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={
                                                    onSubmit &&
                                                    handleSubmit(onSubmit)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                {errors.family?.[i]
                                    ?.do_you_have_access_to_guns ? (
                                    <div className="note-error">
                                        <p>This field is required</p>
                                    </div>
                                ) : null}
                            </section>
                        </React.Fragment>
                    )
                )}
                {Array.from(
                    {
                        length: fields.length || formValues?.family?.length,
                    },
                    (_, i) => (
                        <React.Fragment key={i}>
                            <section id="step-six" className="mb-40">
                                <h3 className="note-header">
                                    Mental status exam
                                </h3>
                                <p className="note-header">
                                    Client {i + 1}{" "}
                                    {i === 0
                                        ? `- ${
                                              appointmentDetails?.client_name ||
                                              clientName
                                          }`
                                        : ""}
                                    {i !== 0 && watch(`family.${i}.name`)
                                        ? `- ${
                                              watch(`family.${i}.name`) ||
                                              formValues?.[i]?.name
                                          }`
                                        : ""}
                                </p>
                                <div className="mb-12">
                                    <Accordion
                                        buttonPosition="left"
                                        header={
                                            <span className="note-label !mb-0">
                                                Appearance
                                            </span>
                                        }
                                        content={
                                            <div className="mt-20">
                                                <div className="note-checkboxes-list !mb-0">
                                                    <Controller
                                                        name={`family.${i}.mental_health_appearance`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Neat"
                                                                value="Neat"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_appearance`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Neat"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_appearance`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Neat",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_appearance`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Neat"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_appearance`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Unkempt"
                                                                value="Unkempt"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_appearance`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Unkempt"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_appearance`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Unkempt",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_appearance`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Unkempt"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_appearance`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Thin"
                                                                value="Thin"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_appearance`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Thin"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_appearance`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Thin",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_appearance`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Thin"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_appearance`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Average"
                                                                value="Average"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_appearance`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Average"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_appearance`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Average",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_appearance`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Average"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_appearance`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Overweight"
                                                                value="Overweight"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_appearance`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Overweight"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_appearance`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Overweight",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_appearance`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Overweight"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_appearance`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Pale"
                                                                value="Pale"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_appearance`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Pale"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_appearance`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Pale",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_appearance`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Pale"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_appearance`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Tanned"
                                                                value="Tanned"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_appearance`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Tanned"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_appearance`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Tanned",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_appearance`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Tanned"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    />
                                </div>
                                <div className="mb-12">
                                    <Accordion
                                        buttonPosition="left"
                                        header={
                                            <span className="note-label !mb-0">
                                                Movement
                                            </span>
                                        }
                                        content={
                                            <div className="mt-20">
                                                <div className="note-checkboxes-list !mb-0">
                                                    <Controller
                                                        name={`family.${i}.mental_health_movement`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Coordinated"
                                                                value="Coordinated"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_movement`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Coordinated"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_movement`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Coordinated",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_movement`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Coordinated"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_movement`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Uncoordinated"
                                                                value="Uncoordinated"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_movement`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Uncoordinated"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_movement`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Uncoordinated",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_movement`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Uncoordinated"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_movement`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Mannerisms/oddities"
                                                                value="Mannerisms/oddities"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_movement`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Mannerisms/oddities"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_movement`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Mannerisms/oddities",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_movement`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Mannerisims/oddities"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_movement`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Good eye contact"
                                                                value="Good eye contact"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_movement`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Good eye contact"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_movement`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Good eye contact",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_movement`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Good eye contact"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_movement`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Kept eyes downcast"
                                                                value="Kept eyes downcast"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_movement`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Kept eyes downcast"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_movement`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Kept eyes downcast",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_movement`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Kept eyes downcast"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_movement`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Stared into space"
                                                                value="Stared into space"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_movement`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Stared into space"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_movement`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Stared into space",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_movement`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Stared into space"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    />
                                </div>
                                <div className="mb-12">
                                    <Accordion
                                        buttonPosition="left"
                                        header={
                                            <span className="note-label !mb-0">
                                                Speech Behavior
                                            </span>
                                        }
                                        content={
                                            <div className="mt-20">
                                                <div className="note-checkboxes-list !mb-0">
                                                    <Controller
                                                        name={`family.${i}.mental_health_speech_behaviour`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Unintelligible"
                                                                value="Unintelligible"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_speech_behaviour`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Unintelligible"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_speech_behaviour`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Unintelligible",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_speech_behaviour`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Unintelligible"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_speech_behaviour`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Stammer/stutter"
                                                                value="Stammer/stutter"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_speech_behaviour`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Stammer/stutter"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_speech_behaviour`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Stammer/stutter",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_speech_behaviour`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Stammer/stutter"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_speech_behaviour`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Mumbles"
                                                                value="Mumbles"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_speech_behaviour`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Mumbles"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_speech_behaviour`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Mumbles",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_speech_behaviour`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Mumbles"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_speech_behaviour`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Clear"
                                                                value="Clear"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_speech_behaviour`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Clear"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_speech_behaviour`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Clear",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_speech_behaviour`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Clear"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_speech_behaviour`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Precise"
                                                                value="Precise"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_speech_behaviour`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Precise"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_speech_behaviour`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Precise",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_speech_behaviour`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Precise"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_speech_behaviour`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Strident"
                                                                value="Strident"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_speech_behaviour`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Strident"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_speech_behaviour`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Strident",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_speech_behaviour`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Strident"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_speech_behaviour`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Distant"
                                                                value="Distant"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_speech_behaviour`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Distant"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_speech_behaviour`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Distant",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_speech_behaviour`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Distant"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_speech_behaviour`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Squeaky"
                                                                value="Squeaky"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_speech_behaviour`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Squeaky"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_speech_behaviour`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Squeaky",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_speech_behaviour`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Squeaky"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_speech_behaviour`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Soft"
                                                                value="Soft"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_speech_behaviour`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Soft"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_speech_behaviour`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Soft",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_speech_behaviour`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Soft"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_speech_behaviour`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Weak"
                                                                value="Weak"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_speech_behaviour`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Weak"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_speech_behaviour`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Weak",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_speech_behaviour`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Weak"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_speech_behaviour`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Normal"
                                                                value="Normal"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_speech_behaviour`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Normal"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_speech_behaviour`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Normal",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_speech_behaviour`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Normal"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_speech_behaviour`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Hurried"
                                                                value="Hurried"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_speech_behaviour`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Hurried"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_speech_behaviour`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Hurried",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_speech_behaviour`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Hurried"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />
                                                    <Controller
                                                        name={`family.${i}.mental_health_speech_behaviour`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Loud"
                                                                value="Loud"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_speech_behaviour`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Loud"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_speech_behaviour`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Loud",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_speech_behaviour`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Loud"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    />
                                </div>
                                <div className="mb-12">
                                    <Accordion
                                        buttonPosition="left"
                                        header={
                                            <span className="note-label !mb-0">
                                                Affect/Mood
                                            </span>
                                        }
                                        content={
                                            <div className="mt-20">
                                                <div className="note-checkboxes-list !mb-0">
                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Blunted"
                                                                value="Blunted"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Blunted"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Blunted",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Blunted"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Constricted"
                                                                value="Constricted"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Constricted"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Constricted",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Constricted"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Normal"
                                                                value="Normal"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Normal"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Normal",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Normal"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Broad"
                                                                value="Broad"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Broad"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Broad",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Broad"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Inappropriate"
                                                                value="Inappropriate"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Inappropriate"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Inappropriate",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Inappropriate"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Indifferent to problems"
                                                                value="Indifferent to problems"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Indifferent to problems"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Indifferent to problems",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Indifferent to problems"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Congruent"
                                                                value="Congruent"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Congruent"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Congruent",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Congruent"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Irritated"
                                                                value="Irritated"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Irritated"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Irritated",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Irritated"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Hostile"
                                                                value="Hostile"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Hostile"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Hostile",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Hostile"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Flushing"
                                                                value="flushing"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Flushing"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Flushing",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Flushing"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Dizziness"
                                                                value="Dizziness"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Dizziness"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Dizziness",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Dizziness"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Can't sit still"
                                                                value="Can't sit still"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Can't sit still"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Can't sit still",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Can't sit still"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Panicked"
                                                                value="Panicked"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Panicked"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Panicked",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Panicked"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Fearful"
                                                                value="Fearful"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Fearful"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Fearful",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Fearful"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Wretched"
                                                                value="Wretched"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Wretched"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Wretched",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Wretched"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Melancholy"
                                                                value="Melancholy"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Melancholy"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Melancholy",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Melancholy"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Sad"
                                                                value="Sad"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Sad"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Sad",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Sad"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Hopeless"
                                                                value="Hopeless"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Hopeless"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Hopeless",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Hopeless"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Bored"
                                                                value="Bored"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Bored"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Bored",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Bored"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Sorrow"
                                                                value="Sorrow"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Sorrow"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Sorrow",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              ).filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Sorrow"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Grief"
                                                                value="Grief"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Grief"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Grief",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Grief"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Flight of ideas"
                                                                value="Flight of ideas"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Flight of ideas"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Flight of ideas",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Flight of ideas"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Manic"
                                                                value="Manic"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Manic"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Manic",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Manic"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Hypomanic"
                                                                value="Hypomanic"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Hypomanic"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Hypomanic",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Hypomanic"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Ashamed"
                                                                value="Ashamed"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Ashamed"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Ashamed",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Ashamed"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Embarrassed"
                                                                value="Embarrassed"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Embarrassed"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Embarrassed",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Embarrassed"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Apologetic"
                                                                value="Apologetic"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Apologetic"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Apologetic",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Apologetic"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Calm"
                                                                value="Calm"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Calm"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Calm",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Calm"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Guilty"
                                                                value="Guilty"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Guilty"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Guilty",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Guilty"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />
                                                    <Controller
                                                        name={`family.${i}.mental_health_affect_mood`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Flat"
                                                                value="Flat"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_affect_mood`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Flat"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_affect_mood`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Flat",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_affect_mood`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Flat"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    />
                                </div>
                                <div className="mb-12">
                                    <Accordion
                                        buttonPosition="left"
                                        header={
                                            <span className="note-label !mb-0">
                                                Attention/Concentration/effort
                                            </span>
                                        }
                                        content={
                                            <div className="mt-20">
                                                <div className="note-checkboxes-list !mb-0">
                                                    <Controller
                                                        name={`family.${i}.mental_health_attention_concentration_effort`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Sluggish"
                                                                value="Sluggish"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_attention_concentration_effort`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Sluggish"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_attention_concentration_effort`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Sluggish",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_attention_concentration_effort`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Sluggish"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_attention_concentration_effort`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Flat"
                                                                value="Flat"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_attention_concentration_effort`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Flat"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_attention_concentration_effort`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Flat",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_attention_concentration_effort`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Flat"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_attention_concentration_effort`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Distractible"
                                                                value="Distractible"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_attention_concentration_effort`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Distractible"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_attention_concentration_effort`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Distractible",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_attention_concentration_effort`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Distractible"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_attention_concentration_effort`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Normal energy"
                                                                value="Normal energy"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_attention_concentration_effort`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Normal energy"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_attention_concentration_effort`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Normal energy",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_attention_concentration_effort`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Normal energy"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_attention_concentration_effort`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Eager"
                                                                value="Eager"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_attention_concentration_effort`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Eager"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_attention_concentration_effort`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Eager",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_attention_concentration_effort`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Eager"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_attention_concentration_effort`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Indifferent"
                                                                value="Indifferent"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_attention_concentration_effort`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Indifferent"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_attention_concentration_effort`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Indifferent",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_attention_concentration_effort`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Indifferent"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_attention_concentration_effort`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Scattered"
                                                                value="Scattered"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_attention_concentration_effort`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Scattered"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_attention_concentration_effort`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Scattered",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_attention_concentration_effort`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Scattered"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_attention_concentration_effort`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Baffled"
                                                                value="Baffled"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_attention_concentration_effort`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Baffled"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_attention_concentration_effort`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Baffled",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_attention_concentration_effort`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Baffled"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_attention_concentration_effort`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Perplexed"
                                                                value="Perplexed"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_attention_concentration_effort`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Perplexed"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_attention_concentration_effort`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Perplexed",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_attention_concentration_effort`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Perplexed"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_attention_concentration_effort`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Hurried"
                                                                value="Hurried"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_attention_concentration_effort`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Hurried"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_attention_concentration_effort`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Hurried",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_attention_concentration_effort`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Hurried"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_attention_concentration_effort`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Organized"
                                                                value="Organized"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_attention_concentration_effort`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Organized"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_attention_concentration_effort`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Organized",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_attention_concentration_effort`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Organized"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_attention_concentration_effort`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Rigid"
                                                                value="Rigid"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_attention_concentration_effort`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Rigid"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_attention_concentration_effort`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Rigid",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_attention_concentration_effort`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Rigid"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_attention_concentration_effort`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Pleasant"
                                                                value="Pleasant"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_attention_concentration_effort`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Pleasant"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_attention_concentration_effort`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Pleasant",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_attention_concentration_effort`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Pleasant"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_attention_concentration_effort`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Cooperative"
                                                                value="Cooperative"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_attention_concentration_effort`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Cooperative"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_attention_concentration_effort`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Cooperative",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_attention_concentration_effort`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Cooperative"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_attention_concentration_effort`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Dependent"
                                                                value="Dependent"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_attention_concentration_effort`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Dependent"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_attention_concentration_effort`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Dependent",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_attention_concentration_effort`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Dependent"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_attention_concentration_effort`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Abusive"
                                                                value="Abusive"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_attention_concentration_effort`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Abusive"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_attention_concentration_effort`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Abusive",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_attention_concentration_effort`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Abusive"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_attention_concentration_effort`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Superior"
                                                                value="Superior"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_attention_concentration_effort`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Superior"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_attention_concentration_effort`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Superior",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_attention_concentration_effort`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Superior"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_attention_concentration_effort`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Stubborn"
                                                                value="Stubborn"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_attention_concentration_effort`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Stubborn"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_attention_concentration_effort`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Stubborn",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_attention_concentration_effort`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Stubborn"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_attention_concentration_effort`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Belligerent"
                                                                value="Belligerent"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_attention_concentration_effort`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Belligerent"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_attention_concentration_effort`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Belligerent",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_attention_concentration_effort`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Belligerent"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_attention_concentration_effort`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Argumentative"
                                                                value="Argumentative"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_attention_concentration_effort`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Argumentative"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_attention_concentration_effort`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Argumentative",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_attention_concentration_effort`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Argumentative"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_attention_concentration_effort`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Hostile"
                                                                value="Hostile"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_attention_concentration_effort`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Hostile"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_attention_concentration_effort`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Hostile",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_attention_concentration_effort`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Hostile"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_attention_concentration_effort`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Demanding"
                                                                value="Demanding"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_attention_concentration_effort`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Demanding"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_attention_concentration_effort`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Demanding",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_attention_concentration_effort`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Demanding"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_attention_concentration_effort`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Resentful"
                                                                value="Resentful"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_attention_concentration_effort`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Resentful"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_attention_concentration_effort`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Resentful",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_attention_concentration_effort`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Resentful"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_attention_concentration_effort`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Surly"
                                                                value="Surly"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_attention_concentration_effort`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Surly"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_attention_concentration_effort`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Surly",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_attention_concentration_effort`
                                                                              ).filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Surly"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_attention_concentration_effort`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Guarded"
                                                                value="Guarded"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_attention_concentration_effort`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Guarded"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_attention_concentration_effort`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Guarded",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_attention_concentration_effort`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Guarded"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    />
                                </div>
                                <div className="mb-12">
                                    <Accordion
                                        buttonPosition="left"
                                        header={
                                            <span className="note-label !mb-0">
                                                Presentation of self
                                            </span>
                                        }
                                        content={
                                            <div className="mt-20">
                                                <div className="note-checkboxes-list !mb-0">
                                                    <Controller
                                                        name={`family.${i}.mental_health_presentation_of_self`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Withdrawn"
                                                                value="Withdrawn"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_presentation_of_self`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Withdrawn"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_presentation_of_self`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Withdrawn",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_presentation_of_self`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Withdrawn"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_presentation_of_self`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Threatened"
                                                                value="Threatened"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_presentation_of_self`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Threatened"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_presentation_of_self`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Threatened",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_presentation_of_self`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Threatened"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_presentation_of_self`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Vulnerable"
                                                                value="Vulnerable"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_presentation_of_self`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Vulnerable"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_presentation_of_self`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Vulnerable",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_presentation_of_self`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Vunlnerable"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_presentation_of_self`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Shy"
                                                                value="Shy"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_presentation_of_self`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Shy"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_presentation_of_self`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Shy",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_presentation_of_self`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Shy"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_presentation_of_self`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Friendly"
                                                                value="Friendly"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_presentation_of_self`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Friendly"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_presentation_of_self`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Friendly",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_presentation_of_self`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Friendly"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_presentation_of_self`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Confident"
                                                                value="Confident"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_presentation_of_self`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Confident"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_presentation_of_self`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Confident",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_presentation_of_self`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Confident"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_presentation_of_self`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Grandiose"
                                                                value="Grandiose"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_presentation_of_self`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Grandiose"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_presentation_of_self`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Grandiose",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_presentation_of_self`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Grandiose"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_presentation_of_self`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Humble"
                                                                value="Humble"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_presentation_of_self`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Humble"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_presentation_of_self`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Humble",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_presentation_of_self`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Humble"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_presentation_of_self`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Self-doubting"
                                                                value="Self-doubting"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_presentation_of_self`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Self-doubting"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_presentation_of_self`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Self-doubting",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_presentation_of_self`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Self-doubting"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_presentation_of_self`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Hopeful"
                                                                value="Hopeful"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_presentation_of_self`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Hopeful"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_presentation_of_self`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Hopeful",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_presentation_of_self`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Hopeful"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_presentation_of_self`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Pessimistic"
                                                                value="Pessimistic"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_presentation_of_self`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Pessimistic"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_presentation_of_self`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Pessimistic",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_presentation_of_self`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Pessimistic"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_presentation_of_self`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Overindulgent"
                                                                value="Overindulgent"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_presentation_of_self`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Overindulgent"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_presentation_of_self`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Overindulgent",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_presentation_of_self`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Overindulgent"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_presentation_of_self`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Warm hearted"
                                                                value="Warm hearted"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_presentation_of_self`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Warm hearted"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_presentation_of_self`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Warm hearted",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_presentation_of_self`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Warm hearted"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_presentation_of_self`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Distant"
                                                                value="Distant"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_presentation_of_self`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Distant"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_presentation_of_self`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Distant",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_presentation_of_self`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Distant"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_presentation_of_self`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Harsh"
                                                                value="Harsh"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_presentation_of_self`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Harsh"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_presentation_of_self`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Harsh",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_presentation_of_self`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Harsh"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    />
                                </div>
                                <div className="mb-32">
                                    <Accordion
                                        buttonPosition="left"
                                        header={
                                            <span className="note-label !mb-0">
                                                Cognition (decision making,
                                                insight, memory, etc)
                                            </span>
                                        }
                                        content={
                                            <div className="mt-20">
                                                <div className="note-checkboxes-list !mb-0">
                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Indecisive"
                                                                value="Indecisive"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Indecisive"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Indecisive",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Indecisive"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Decisive"
                                                                value="Decisive"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Decisive"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Decisive",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Decisive"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Some insight"
                                                                value="Some insight"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Some insight"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Some insight",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Some insight"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Sluggish recall"
                                                                value="Sluggish recall"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Sluggish recall"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Sluggish recall",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Sluggish recall"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Normal memory"
                                                                value="Normal memory"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Normal memory"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Normal memory",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Normal memory"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Low intelligent"
                                                                value="Low intelligent"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Low intelligent"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Low intelligent",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Low intelligient"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Good orientation"
                                                                value="Good orientation"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Good orientation"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Good orientation",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Good orientation"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Defective reality"
                                                                value="Defective reality"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Defective reality"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Defective reality",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Defective reality"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Flight of ideas"
                                                                value="Flight of ideas"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Flight of ideas"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Flight of ideas",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Flight of ideas"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Incoherent thought process"
                                                                value="Incoherent thought process"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Incoherent thought process"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Incoherent thought process",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Incoherent thought process"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Procrastinates"
                                                                value="Procrastinates"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Procrastinates"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Procrastinates",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Procrastinates"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Follows through"
                                                                value="Follows through"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Follows through"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Follows through",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Follows through"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Full insight"
                                                                value="Full insight"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Full insight"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Full insight",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Full insight"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Amnesia"
                                                                value="Amnesia"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Amnesia"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Amnesia",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Amnesia"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Intelligent"
                                                                value="Intelligent"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Intelligent"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Intelligent",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Intelligent"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Normal judgment"
                                                                value="Normal judgment"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Normal judgment"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Normal judgment",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Normal judgment"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Intact reality"
                                                                value="Intact reality"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Intact reality"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Intact reality",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Intact reality"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Normal abstraction"
                                                                value="Normal abstraction"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Normal abstraction"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Normal abstraction",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              ).filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Normal abstraction"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Loose thought process"
                                                                value="Loose thought process"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Loose thought process"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Loose thought process",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Loose thought process"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Clear thought process"
                                                                value="Clear thought process"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Clear thought process"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Clear thought process",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Clear thought process"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Easily confused"
                                                                value="Easily confused"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Easily confused"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Easily confused",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Easily confused"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="No insight"
                                                                value="No insight"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "No insight"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "No insight",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "No insight"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Forgetful"
                                                                value="Forgetful"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Forgetful"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Forgetful",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Forgetful"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Confuses time frames/sequence"
                                                                value="Confuses time frames/sequence"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Confuses time frames/sequence"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Confuses time frames/sequence",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Confuses time frames/sequence"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Lack of common sense"
                                                                value="Lack of common sense"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Lack of common sense"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Lack of common sense",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Lack of common sense"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Victimization"
                                                                value="Victimization"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Victimization"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Victimization",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Victimization"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Confused present location/date/persons, places"
                                                                value="Confused present location/date/persons, places"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Confused present location/date/persons, places"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Confused present location/date/persons, places",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Confused present location/date/persons, places"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Faulty reasoning"
                                                                value="Faulty reasoning"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Faulty reasoning"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Faulty reasoning",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Faulty reasoning"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Impoverished stream of thoughts"
                                                                value="Impoverished stream of thoughts"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Impoverished stream of thoughts"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Impoverished stream of thoughts",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Impoverished stream of thoughts"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name={`family.${i}.mental_health_cognition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <FormCheckbox
                                                                label="Normal stream of thoughts"
                                                                value="Normal stream of thoughts"
                                                                checked={(
                                                                    watch(
                                                                        `family.${i}.mental_health_cognition`
                                                                    ) || []
                                                                )?.includes(
                                                                    "Normal stream of thoughts"
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedValue =
                                                                        e.target
                                                                            .checked
                                                                            ? [
                                                                                  ...(watch(
                                                                                      `family.${i}.mental_health_cognition`
                                                                                  ) ||
                                                                                      []),
                                                                                  "Normal stream of thoughts",
                                                                              ]
                                                                            : watch(
                                                                                  `family.${i}.mental_health_cognition`
                                                                              )?.filter(
                                                                                  (
                                                                                      symptom
                                                                                  ) =>
                                                                                      symptom !==
                                                                                      "Normal stream of thoughts"
                                                                              );
                                                                    field.onChange(
                                                                        updatedValue
                                                                    );
                                                                }}
                                                                onBlur={
                                                                    onSubmit &&
                                                                    handleSubmit(
                                                                        onSubmit
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    />
                                </div>
                                <div>
                                    <h3 className="note-label">
                                        Patient&apos;s ability and capability to
                                        respond to treatment
                                    </h3>
                                    <div className="note-checkboxes">
                                        <Controller
                                            name={`family.${i}.patients_ability_and_capability_to_respond_to_treatment`}
                                            control={control}
                                            render={({ field }) => (
                                                <FormCheckbox
                                                    label="Consistent, good-quality patient-oriented evidence"
                                                    value="Consistent, good-quality patient-oriented evidence"
                                                    checked={(
                                                        watch(
                                                            `family.${i}.patients_ability_and_capability_to_respond_to_treatment`
                                                        ) || []
                                                    )?.includes(
                                                        "Consistent, good-quality patient-oriented evidence"
                                                    )}
                                                    onChange={(e) => {
                                                        const updatedValue = e
                                                            .target.checked
                                                            ? [
                                                                  ...(watch(
                                                                      `family.${i}.patients_ability_and_capability_to_respond_to_treatment`
                                                                  ) || []),
                                                                  "Consistent, good-quality patient-oriented evidence",
                                                              ]
                                                            : watch(
                                                                  `family.${i}.patients_ability_and_capability_to_respond_to_treatment`
                                                              )?.filter(
                                                                  (symptom) =>
                                                                      symptom !==
                                                                      "Consistent, good-quality patient-oriented evidence"
                                                              );
                                                        field.onChange(
                                                            updatedValue
                                                        );
                                                    }}
                                                    onBlur={
                                                        onSubmit &&
                                                        handleSubmit(onSubmit)
                                                    }
                                                />
                                            )}
                                        />
                                        <Controller
                                            name={`family.${i}.patients_ability_and_capability_to_respond_to_treatment`}
                                            control={control}
                                            render={({ field }) => (
                                                <FormCheckbox
                                                    label="Diminished/poor or limited-quality patient-oriented evidence"
                                                    value="Diminished/poor or limited-quality patient-oriented evidence"
                                                    checked={(
                                                        watch(
                                                            `family.${i}.patients_ability_and_capability_to_respond_to_treatment`
                                                        ) || []
                                                    )?.includes(
                                                        "Diminished/poor or limited-quality patient-oriented evidence"
                                                    )}
                                                    onChange={(e) => {
                                                        const updatedValue = e
                                                            .target.checked
                                                            ? [
                                                                  ...(watch(
                                                                      `family.${i}.patients_ability_and_capability_to_respond_to_treatment`
                                                                  ) || []),
                                                                  "Diminished/poor or limited-quality patient-oriented evidence",
                                                              ]
                                                            : watch(
                                                                  `family.${i}.patients_ability_and_capability_to_respond_to_treatment`
                                                              )?.filter(
                                                                  (symptom) =>
                                                                      symptom !==
                                                                      "Diminished/poor or limited-quality patient-oriented evidence"
                                                              );
                                                        field.onChange(
                                                            updatedValue
                                                        );
                                                    }}
                                                    onBlur={
                                                        onSubmit &&
                                                        handleSubmit(onSubmit)
                                                    }
                                                />
                                            )}
                                        />

                                        {(
                                            watch(
                                                `family.${i}.patients_ability_and_capability_to_respond_to_treatment`
                                            ) || []
                                        )?.includes(
                                            "Diminished/poor or limited-quality patient-oriented evidence"
                                        ) && (
                                            <>
                                                <span className="note-label !mb-0">
                                                    Please provide details for
                                                    diminished/poor quality
                                                    patient-orientation
                                                </span>
                                                <Controller
                                                    name={`family.${i}.details_for_diminished_poor_limited_quality_patient_oriented_evidence`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextArea
                                                            defaultValue={
                                                                watch(
                                                                    `family.${i}.details_for_diminished_poor_limited_quality_patient_oriented_evidence`
                                                                ) || ""
                                                            }
                                                            hasError={
                                                                !!errors
                                                                    .family?.[i]
                                                                    ?.details_for_diminished_poor_limited_quality_patient_oriented_evidence
                                                            }
                                                            errorText={
                                                                errors.family?.[
                                                                    i
                                                                ]
                                                                    ?.details_for_diminished_poor_limited_quality_patient_oriented_evidence
                                                                    ?.message
                                                            }
                                                            onChange={
                                                                field.onChange
                                                            }
                                                            onBlur={
                                                                onSubmit &&
                                                                handleSubmit(
                                                                    onSubmit
                                                                )
                                                            }
                                                        />
                                                    )}
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </section>
                        </React.Fragment>
                    )
                )}
                <section id="step-seven" className="mb-40">
                    <h3 className="note-header">Summary</h3>
                    <p className="note-label-req">
                        {FAMILY_INTAKE_NOTE_DETAILS.people_present_at_session}
                    </p>
                    <div className="note-checkboxes-list">
                        <FormCheckbox
                            {...register(`people_present_at_session`)}
                            label="Patient"
                            value="Patient"
                            defaultChecked={isCheckboxChecked(
                                "Patient",
                                formValues?.people_present_at_session
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(`people_present_at_session`)}
                            label="Mother"
                            value="Mother"
                            defaultChecked={isCheckboxChecked(
                                "Mother",
                                formValues?.people_present_at_session
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(`people_present_at_session`)}
                            label="Stepfather"
                            value="Stepfather"
                            defaultChecked={isCheckboxChecked(
                                "Stepfather",
                                formValues?.people_present_at_session
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(`people_present_at_session`)}
                            label="Spouse"
                            value="Spouse"
                            defaultChecked={isCheckboxChecked(
                                "Spouse",
                                formValues?.people_present_at_session
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(`people_present_at_session`)}
                            label="Father"
                            value="Father"
                            defaultChecked={isCheckboxChecked(
                                "Father",
                                formValues?.people_present_at_session
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(`people_present_at_session`)}
                            label="Stepmother"
                            value="Stepmother"
                            defaultChecked={isCheckboxChecked(
                                "Stepmother",
                                formValues?.people_present_at_session
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(`people_present_at_session`)}
                            label="Partner"
                            value="Partner"
                            defaultChecked={isCheckboxChecked(
                                "Partner",
                                formValues?.people_present_at_session
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(`people_present_at_session`)}
                            label="Guardian"
                            value="Guardian"
                            defaultChecked={isCheckboxChecked(
                                "Guardian",
                                formValues?.people_present_at_session
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(`people_present_at_session`)}
                            label="Other"
                            value="Other"
                            defaultChecked={isCheckboxChecked(
                                "Other",
                                formValues?.people_present_at_session
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    {errors.people_present_at_session ? (
                        <div className="note-error">
                            <p>People present at session is required</p>
                        </div>
                    ) : null}
                    <div className="fg">
                        <Input
                            {...register(`other_person_present_at_session`)}
                            label={
                                FAMILY_INTAKE_NOTE_DETAILS.other_person_present_at_session
                            }
                            placeholder={
                                FAMILY_INTAKE_NOTE_DETAILS.other_person_present_at_session
                            }
                            defaultValue={
                                formValues?.other_person_present_at_session
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <p className="note-label-req">
                        {
                            FAMILY_INTAKE_NOTE_DETAILS.type_of_treatment_recommended
                        }
                    </p>
                    <div className="note-checkboxes-list">
                        <FormCheckbox
                            {...register(`type_of_treatment_recommended`)}
                            label="Individual"
                            value="Individual"
                            defaultChecked={isCheckboxChecked(
                                "Individual",
                                formValues?.type_of_treatment_recommended
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(`type_of_treatment_recommended`)}
                            label="Family"
                            value="Family"
                            defaultChecked={isCheckboxChecked(
                                "Family",
                                formValues?.type_of_treatment_recommended
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(`type_of_treatment_recommended`)}
                            label="Group"
                            value="Group"
                            defaultChecked={isCheckboxChecked(
                                "Group",
                                formValues?.type_of_treatment_recommended
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(`type_of_treatment_recommended`)}
                            label="Collateral/Parenting"
                            value="Collateral/Parenting"
                            defaultChecked={isCheckboxChecked(
                                "Collateral/Parenting",
                                formValues?.type_of_treatment_recommended
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    {errors.type_of_treatment_recommended ? (
                        <div className="note-error">
                            <p>Type of treatment recommended is required</p>
                        </div>
                    ) : null}
                    <p className="note-label-req">
                        {
                            FAMILY_INTAKE_NOTE_DETAILS.treatment_modality_intervention
                        }
                    </p>
                    <div className="note-checkboxes-list">
                        <FormCheckbox
                            {...register(`treatment_modality_intervention`)}
                            label="Cognitive/Behavioral"
                            value="Cognitive/Behavioral"
                            defaultChecked={isCheckboxChecked(
                                "Cognitive/Behavioral",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(`treatment_modality_intervention`)}
                            label="Behavioral Modification"
                            value="Behavioral Modification"
                            defaultChecked={isCheckboxChecked(
                                "Behavioral Modification",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(`treatment_modality_intervention`)}
                            label="Supportive"
                            value="Supportive"
                            defaultChecked={isCheckboxChecked(
                                "Supportive",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(`treatment_modality_intervention`)}
                            label="Marital/Couples Therapy "
                            value="Marital/Couples Therapy "
                            defaultChecked={isCheckboxChecked(
                                "Marital/Couples Therapy ",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(`treatment_modality_intervention`)}
                            label="Family Therapy"
                            value="Family Therapy"
                            defaultChecked={isCheckboxChecked(
                                "Family Therapy",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(`treatment_modality_intervention`)}
                            label="Stress Management"
                            value="Stress Management"
                            defaultChecked={isCheckboxChecked(
                                "Stress Management",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(`treatment_modality_intervention`)}
                            label="Psychodynamic"
                            value="Psychodynamic"
                            defaultChecked={isCheckboxChecked(
                                "Psychodynamic",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(`treatment_modality_intervention`)}
                            label="Parent Training"
                            value="Parent Training"
                            defaultChecked={isCheckboxChecked(
                                "Parent Training",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(`treatment_modality_intervention`)}
                            label="Crisis Intervention"
                            value="Crisis Intervention"
                            defaultChecked={isCheckboxChecked(
                                "Crisis Intervention",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(`treatment_modality_intervention`)}
                            label="Other"
                            value="Other"
                            defaultChecked={isCheckboxChecked(
                                "Other",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    {errors.treatment_modality_intervention ? (
                        <div className="note-error">
                            <p>Treatment modality/intervention is required</p>
                        </div>
                    ) : null}
                    <div className="fg">
                        <Input
                            {...register(
                                `other_treatment_modality_intervention`
                            )}
                            label={
                                FAMILY_INTAKE_NOTE_DETAILS.other_treatment_modality_intervention
                            }
                            placeholder={
                                FAMILY_INTAKE_NOTE_DETAILS.other_treatment_modality_intervention
                            }
                            defaultValue={
                                formValues?.other_treatment_modality_intervention
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <p className="note-label">Treatment goals</p>
                    <div className="flex flex-col gap-y-20 mb-32">
                        <Input
                            {...register(`treatment_goal_one`)}
                            label={
                                FAMILY_INTAKE_NOTE_DETAILS.treatment_goal_one
                            }
                            placeholder={
                                FAMILY_INTAKE_NOTE_DETAILS.treatment_goal_one
                            }
                            defaultValue={formValues?.treatment_goal_one}
                            isRequired
                            hasError={!!errors.treatment_goal_one}
                            errorText={errors.treatment_goal_one?.message}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <Input
                            {...register(`treatment_goal_two`)}
                            label={
                                FAMILY_INTAKE_NOTE_DETAILS.treatment_goal_two
                            }
                            placeholder={
                                FAMILY_INTAKE_NOTE_DETAILS.treatment_goal_two
                            }
                            defaultValue={formValues?.treatment_goal_two}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <Input
                            {...register(`treatment_goal_three`)}
                            label={
                                FAMILY_INTAKE_NOTE_DETAILS.treatment_goal_three
                            }
                            placeholder={
                                FAMILY_INTAKE_NOTE_DETAILS.treatment_goal_three
                            }
                            defaultValue={formValues?.treatment_goal_three}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <h3 className="note-label">
                        {FAMILY_INTAKE_NOTE_DETAILS.frequency_of_sessions}
                    </h3>
                    <div className="note-radios">
                        <RadioInput
                            {...register("frequency_of_sessions")}
                            label="Weekly"
                            value="Weekly"
                            defaultChecked={
                                formValues?.frequency_of_sessions === "Weekly"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <RadioInput
                            {...register("frequency_of_sessions")}
                            label="Bi-Weekly"
                            value="Bi-Weekly"
                            defaultChecked={
                                formValues?.frequency_of_sessions ===
                                "Bi-Weekly"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <RadioInput
                            {...register("frequency_of_sessions")}
                            label="Monthly"
                            value="Monthly"
                            defaultChecked={
                                formValues?.frequency_of_sessions === "Monthly"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <RadioInput
                            {...register("frequency_of_sessions")}
                            label="Other"
                            value="Other"
                            defaultChecked={
                                formValues?.frequency_of_sessions === "Other"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    {watch("frequency_of_sessions") === "Other" && (
                        <div className="fg">
                            <Input
                                {...register("other_frequency_of_sessions")}
                                label={
                                    FAMILY_INTAKE_NOTE_DETAILS.other_frequency_of_sessions
                                }
                                placeholder={
                                    FAMILY_INTAKE_NOTE_DETAILS.other_frequency_of_sessions
                                }
                                hasError={!!errors.other_frequency_of_sessions}
                                errorText={
                                    errors.other_frequency_of_sessions?.message
                                }
                                defaultValue={
                                    formValues?.other_frequency_of_sessions
                                }
                                onBlur={onSubmit && handleSubmit(onSubmit)}
                            />
                        </div>
                    )}
                    <div className="fg">
                        <Controller
                            name="date_of_next_session"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    label={
                                        FAMILY_INTAKE_NOTE_DETAILS.date_of_next_session
                                    }
                                    onChange={(date) => {
                                        field.onChange(date);
                                        setLocalDateOfNextSession(date);
                                    }}
                                    selected={localDateOfNextSession}
                                    value={
                                        field.value
                                            ? formatDate(
                                                  field.value,
                                                  true,
                                                  "MM/dd/yyyy"
                                              )
                                            : undefined
                                    }
                                    hasError={!!errors.date_of_next_session}
                                    errorText={
                                        errors.date_of_next_session?.type ===
                                        "typeError"
                                            ? "Invalid date value"
                                            : errors.date_of_next_session
                                                  ?.message
                                    }
                                    onBlur={onSubmit && handleSubmit(onSubmit)}
                                    shouldCloseOnSelect
                                    onCalendarClose={
                                        onSubmit && handleSubmit(onSubmit)
                                    }
                                />
                            )}
                        />
                    </div>
                    <h3 className="note-label-req">
                        {FAMILY_INTAKE_NOTE_DETAILS.subjective_report}
                    </h3>
                    <p className="note-label">
                        Please make sure to only include what the client has
                        reported. This should only tie back to what the client
                        reported in the session, re; symptoms, mood/affect,
                        ideations, interpersonal conflicts, etc. Do not include
                        your assessment or interpretation of the clients
                        symptoms here.
                    </p>
                    <div className="fg">
                        <TextArea
                            {...register(`subjective_report`)}
                            defaultValue={formValues?.subjective_report}
                            hasError={!!errors.subjective_report}
                            errorText={errors.subjective_report?.message}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <h3 className="note-label-req">
                        {FAMILY_INTAKE_NOTE_DETAILS.summary}
                    </h3>
                    <div className="fg">
                        <TextArea
                            {...register(`summary`)}
                            defaultValue={formValues?.subjective_report}
                            hasError={!!errors.summary}
                            errorText={errors.summary?.message}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <h3 className="note-label">
                        {FAMILY_INTAKE_NOTE_DETAILS.prognosis}
                    </h3>
                    <p className="note-label">
                        Please write at least 1-2 sentences about the likely
                        outcome/development of the symptoms/challenges
                    </p>
                    <div className="fg">
                        <TextArea
                            {...register("prognosis")}
                            defaultValue={formValues?.prognosis}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <h3 className="note-label">
                        {
                            FAMILY_INTAKE_NOTE_DETAILS.communication_factors_impacting_treatment
                        }
                    </h3>
                    <div className="note-checkboxes">
                        <FormCheckbox
                            {...register(
                                `communication_factors_impacting_treatment`
                            )}
                            label="Need to manage maladaptive communication (e.g., related to high anxiety, high reactivity repeated questions, or disagreement) among participants"
                            value="Need to manage maladaptive communication (e.g., related to high anxiety, high reactivity repeated questions, or disagreement) among participants"
                            defaultChecked={isCheckboxChecked(
                                "Need to manage maladaptive communication (e.g., related to high anxiety, high reactivity repeated questions, or disagreement) among participants",
                                formValues?.communication_factors_impacting_treatment
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(
                                `communication_factors_impacting_treatment`
                            )}
                            label="Caregiver emotions or behaviors interfered with the caregiver's understanding and ability"
                            value="Caregiver emotions or behaviors interfered with the caregiver's understanding and ability"
                            defaultChecked={isCheckboxChecked(
                                "Caregiver emotions or behaviors interfered with the caregiver's understanding and ability",
                                formValues?.communication_factors_impacting_treatment
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(
                                `communication_factors_impacting_treatment`
                            )}
                            label="Evidence or disclosure of a sentinel event and mandated reporting to a third party"
                            value="Evidence or disclosure of a sentinel event and mandated reporting to a third party"
                            defaultChecked={isCheckboxChecked(
                                "Evidence or disclosure of a sentinel event and mandated reporting to a third party",
                                formValues?.communication_factors_impacting_treatment
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(
                                `communication_factors_impacting_treatment`
                            )}
                            label="Use of play equipment or other physical devices to communicate with the client to overcome barriers to therapeutic or diagnostic interaction"
                            value="Use of play equipment or other physical devices to communicate with the client to overcome barriers to therapeutic or diagnostic interaction"
                            defaultChecked={isCheckboxChecked(
                                "Use of play equipment or other physical devices to communicate with the client to overcome barriers to therapeutic or diagnostic interaction",
                                formValues?.communication_factors_impacting_treatment
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(
                                `communication_factors_impacting_treatment`
                            )}
                            label="Client has not developed, or lost, either the expressive language communication skills to explain their symptoms and respond to treatment"
                            value="Client has not developed, or lost, either the expressive language communication skills to explain their symptoms and respond to treatment"
                            defaultChecked={isCheckboxChecked(
                                "Client has not developed, or lost, either the expressive language communication skills to explain their symptoms and respond to treatment",
                                formValues?.communication_factors_impacting_treatment
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(
                                `communication_factors_impacting_treatment`
                            )}
                            label="Client lacks the receptive communication skills to understand the therapist when using typical language for communication"
                            value="Client lacks the receptive communication skills to understand the therapist when using typical language for communication"
                            defaultChecked={isCheckboxChecked(
                                "Client lacks the receptive communication skills to understand the therapist when using typical language for communication",
                                formValues?.communication_factors_impacting_treatment
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <h3 className="note-label">
                        {
                            FAMILY_INTAKE_NOTE_DETAILS.details_around_communication_factors_impacting_treatment
                        }
                    </h3>
                    <TextArea
                        {...register(
                            `details_around_communication_factors_impacting_treatment`
                        )}
                        defaultValue={
                            formValues?.details_around_communication_factors_impacting_treatment
                        }
                        onBlur={onSubmit && handleSubmit(onSubmit)}
                    />
                </section>

                <div className="fg-info fg-line">
                    <p>Sign note here</p>
                    <div className="fg">
                        <Input
                            {...register("signature")}
                            hasError={!!errors.signature}
                            errorText={errors.signature?.message}
                            label="Provider's Initials"
                            placeholder="Provider's Initials"
                            autoComplete="off"
                            isRequired
                        />
                    </div>
                </div>
            </form>
        </>
    );
}
