import * as React from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { Button } from "@jhool-io/fe-components";
import { NoteTypes } from "../../types/notes.types";
import { useFetchSessionNote } from "../../../../hooks/queries/note";
import {
    INTAKE_SECTIONS,
    SOAP_NOTE_SECTIONS,
    TREATMENT_REVIEW_NOTE_SECTIONS,
} from "../../../../utils/constants";
import { cn, makeStringFirstLetterCapital } from "../../../../utils/helpers";
import ChevronLeftIcon from "../../../../components/Icons/ChevronLeft";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import NoteContentItem from "../../../../components/Notes/NoteContent/NoteContentItem/NoteContentItem";
import ChevronRightIcon from "../../../../components/Icons/ChevronRight";
import {
    IAddendumDetails,
    IIndividualSoapNoteAnalysisResponse,
} from "../../../../utils/types/notes";
import ViewSupervisionNote from "../../../../components/Notes/NoteContent/ViewSupervisionNote/ViewSupervisionNote";
import ViewCancellationNote from "../../../../components/Notes/NoteContent/ViewCancellationNote/ViewCancellationNote";
import ViewRecordOfDisclosureNote from "../../../../components/Notes/NoteContent/ViewRecordOfDisclosureNote/ViewRecordOfDisclosureNote";
import ViewSoapNote from "../../../../components/Notes/NoteContent/ViewSoapNote/ViewSoapNote";
import ViewTerminationNote from "../../../../components/Notes/NoteContent/ViewTerminationNote/ViewTerminationNote";
import ViewSafetyPlanNote from "../../../../components/Notes/NoteContent/ViewSafetyPlanNote/ViewSafetyPlanNote";
import ViewTreatmentReviewNote from "../../../../components/Notes/NoteContent/ViewTreatmentReviewNote/ViewTreatementReviewNote";
import ViewIndividualIntakeNote from "../../../../components/Notes/NoteContent/ViewIndividualIntakeNote/ViewIndividualIntakeNote";
import ViewGeneralNote from "../../../../components/Notes/NoteContent/ViewGeneralNote/ViewGeneralNote";
import ViewFamilyIntakeNote from "../../../../components/Notes/NoteContent/ViewFamilyIntakeNote/ViewFamilyIntakeNote";
import ViewSupervisorCheckInNote from "../../../../components/Notes/NoteContent/ViewSupervisorCheckInNote/ViewSupervisorCheckInNote";

interface NoteContentProps {
    showSideNavigation?: boolean;
    noteAnalysisResponse?: Partial<IIndividualSoapNoteAnalysisResponse>;
}

// Function to handle note contents to show
export const handleNoteContentToDisplay = (
    type: NoteTypes,
    noteContent: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
    },
    fieldAddendums?: {
        [key: string]: IAddendumDetails[];
    } | null,
    noteAnalysisResponse?: Partial<IIndividualSoapNoteAnalysisResponse>
) => {
    switch (type) {
        case NoteTypes.SUPERVISION_NOTE:
            return (
                <ViewSupervisionNote
                    noteContent={noteContent}
                    fieldAddendums={fieldAddendums || null}
                />
            );
        case NoteTypes.CANCELLATION_NOTE:
            return (
                <ViewCancellationNote
                    noteContent={noteContent}
                    fieldAddendums={fieldAddendums || null}
                />
            );
        case NoteTypes.RECORD_OF_DISCLOSURE:
            return (
                <ViewRecordOfDisclosureNote
                    noteContent={noteContent}
                    fieldAddendums={fieldAddendums || null}
                />
            );
        case NoteTypes.FAMILY_SOAP_NOTE:
            return (
                <ViewSoapNote
                    noteContent={noteContent}
                    isFamilySoap
                    fieldAddendums={fieldAddendums || null}
                />
            );
        case NoteTypes.INDIVIDUAL_SOAP_NOTE:
            return (
                <ViewSoapNote
                    noteContent={noteContent}
                    isFamilySoap={false}
                    fieldAddendums={fieldAddendums || null}
                    noteAnalysis={noteAnalysisResponse}
                />
            );
        case NoteTypes.TERMINATION_NOTE:
            return (
                <ViewTerminationNote
                    noteContent={noteContent}
                    fieldAddendums={fieldAddendums || null}
                />
            );
        case NoteTypes.SAFETY_PLAN:
            return (
                <ViewSafetyPlanNote
                    noteContent={noteContent}
                    fieldAddendums={fieldAddendums || null}
                />
            );
        case NoteTypes.INDIVIDUAL_TREATMENT_REVIEW:
            return (
                <ViewTreatmentReviewNote
                    noteContent={noteContent}
                    fieldAddendums={fieldAddendums || null}
                    isFamilyTreatmentReview={false}
                />
            );
        case NoteTypes.FAMILY_TREATMENT_REVIEW:
            return (
                <ViewTreatmentReviewNote
                    noteContent={noteContent}
                    fieldAddendums={fieldAddendums || null}
                    isFamilyTreatmentReview
                />
            );
        case NoteTypes.INDIVIDUAL_INTAKE_NOTE:
            return (
                <ViewIndividualIntakeNote
                    fieldAddendums={fieldAddendums || null}
                    noteContent={noteContent}
                    isMinor={false}
                />
            );
        case NoteTypes.MINOR_INTAKE_NOTE:
            return (
                <ViewIndividualIntakeNote
                    fieldAddendums={fieldAddendums || null}
                    noteContent={noteContent}
                    isMinor
                />
            );
        case NoteTypes.SUPERVISOR_CHECK_IN:
            return (
                <ViewSupervisorCheckInNote
                    fieldAddendums={fieldAddendums || null}
                    noteContent={noteContent}
                />
            );
        case NoteTypes.FAMILY_INTAKE_NOTE:
            return (
                <ViewFamilyIntakeNote
                    fieldAddendums={fieldAddendums || null}
                    noteContent={noteContent}
                />
            );
        case NoteTypes.GENERAL_UPDATE_NOTE:
            return (
                <ViewGeneralNote
                    fieldAddendums={fieldAddendums || null}
                    noteContent={noteContent}
                />
            );
        default:
            return null;
    }
};

export default function NoteContent({
    showSideNavigation = true,
    noteAnalysisResponse,
}: NoteContentProps) {
    const [searchParams, setSearchParams] = useSearchParams();
    const params = useParams();
    const location = useLocation();

    const noteId = params.noteId as string;
    const clientId = params.clientId as string;

    const sectionsNav = searchParams.get("sections");

    const { data, error, isLoading, isSuccess } = useFetchSessionNote(
        clientId,
        noteId,
        Boolean(clientId) && Boolean(noteId)
    );

    // Toggle note sections sidenav for long notes
    const handleToggleSectionsNav = () => {
        if (sectionsNav) {
            searchParams.delete("sections");
            setSearchParams(searchParams);
        } else {
            searchParams.set("sections", "open");
            setSearchParams(searchParams);
        }
    };

    const getSectionsNavLinks = (sectionItems: { [key: string]: string }) => {
        return Object.keys(sectionItems).map((item) => {
            return {
                title: item,
                url: `#${sectionItems[item as keyof typeof sectionItems]}`,
            };
        });
    };

    const getSectionNavLinksToDisplay = (noteType: NoteTypes) => {
        switch (noteType) {
            case NoteTypes.INDIVIDUAL_INTAKE_NOTE:
            case NoteTypes.FAMILY_INTAKE_NOTE:
            case NoteTypes.MINOR_INTAKE_NOTE:
                return getSectionsNavLinks(INTAKE_SECTIONS);

            case NoteTypes.INDIVIDUAL_TREATMENT_REVIEW:
            case NoteTypes.FAMILY_TREATMENT_REVIEW:
                return getSectionsNavLinks(TREATMENT_REVIEW_NOTE_SECTIONS);

            case NoteTypes.FAMILY_SOAP_NOTE:
            case NoteTypes.INDIVIDUAL_SOAP_NOTE:
                return getSectionsNavLinks(SOAP_NOTE_SECTIONS);

            default:
                return [];
        }
    };

    const showSectionsNavigation =
        [
            NoteTypes.FAMILY_INTAKE_NOTE,
            NoteTypes.INDIVIDUAL_INTAKE_NOTE,
            NoteTypes.MINOR_INTAKE_NOTE,
            NoteTypes.INDIVIDUAL_TREATMENT_REVIEW,
            NoteTypes.FAMILY_TREATMENT_REVIEW,
            NoteTypes.FAMILY_SOAP_NOTE,
            NoteTypes.INDIVIDUAL_SOAP_NOTE,
        ].includes(data?.data?.type as NoteTypes) && showSideNavigation;

    React.useEffect(() => {
        const addendumSection = location.hash.split("?")[0];

        if (
            addendumSection &&
            addendumSection.includes("#") &&
            document.querySelector(`article${addendumSection}`)
        ) {
            const scrollToId = document.querySelector(
                `article${addendumSection}`
            ) as HTMLElement;

            scrollToId.scrollIntoView({ behavior: "instant" });
        }
    }, [location.hash]);

    return (
        <div>
            {isLoading && (
                <div className="flex flex-col gap-y-24 mt-24 bg-white p-16 rounded-r8">
                    <Skeleton
                        className="h-[2rem] w-[10rem]"
                        containerTestId="notecontents-loader"
                    />
                    <Skeleton
                        className="h-[2.4rem] w-[15rem]"
                        containerTestId="notecontents-loader"
                    />
                    <div className="flex flex-col gap-y-8">
                        {[1, 2, 3, 4, 5].map((item) => (
                            <Skeleton
                                key={item}
                                className="h-[8rem] w-full rounded-r8"
                                containerTestId="notecontents-loader"
                            />
                        ))}
                    </div>
                </div>
            )}
            {error && (
                <div className="mt-24">
                    <ListState
                        isError
                        errorMsg={
                            error.response?.data.message ||
                            "Cannot display note content at this time"
                        }
                        stateHelperText="Try reloading this page to solve this issue"
                    />
                </div>
            )}
            {data?.data && isSuccess && (
                <div className="flex flex-col gap-y-20 sm:flex-row sm:items-start mt-24 relative">
                    {showSectionsNavigation ? (
                        <div
                            className={cn(
                                "w-full sm:w-auto bg-white sm:bg-transparent pb-20 sm:pb-0 sm:mr-32 border-r-0 sm:!border-r-[1px] !border-r-components-divider hidden sticky will-change-transform top-[20px] pr-0 sm:pr-20",
                                {
                                    block: sectionsNav === "open",
                                }
                            )}
                        >
                            <div className="flex items-center gap-x-8 mb-16">
                                <Button
                                    type="button"
                                    variant="normal"
                                    size="auto"
                                    className="shrink-0 capitalize bg-[#d6d5c97f] shadow-light_shadow flex items-center justify-center !rounded-[3rem] p-0"
                                    onClick={handleToggleSectionsNav}
                                    aria-label="Close note sections navigation"
                                >
                                    <ChevronLeftIcon className="size-[1rem]" />
                                </Button>
                                <span className="font-medium text-sm">
                                    Sections
                                </span>
                            </div>
                            <ul className="flex flex-wrap gap-16 sm:flex-col">
                                {getSectionNavLinksToDisplay(
                                    data.data.type
                                ).map((link) => (
                                    <li
                                        key={link.title}
                                        className="text-gray text-xs font-medium pr-4 pl-20"
                                    >
                                        <a
                                            href={link.url}
                                            className={cn(
                                                "border-b-2 border-b-transparent",
                                                {
                                                    "text-black border-b-primary":
                                                        location.hash.includes(
                                                            link.url
                                                        ),
                                                }
                                            )}
                                        >
                                            {makeStringFirstLetterCapital(
                                                link.title
                                            )}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : null}
                    <div className="flex-1 bg-white p-16 rounded-r8">
                        {showSectionsNavigation ? (
                            <div
                                className={cn("mb-16", {
                                    hidden: sectionsNav === "open",
                                })}
                            >
                                <Button
                                    variant="normal"
                                    size="auto"
                                    onClick={handleToggleSectionsNav}
                                    aria-label="Open note sections navigation"
                                    className="text-primary text-sm font-medium underline gap-x-[0.8rem]"
                                >
                                    Sections
                                    <span
                                        aria-hidden="true"
                                        className="flex items-center justify-center size-[2.4rem] rounded-[3rem] bg-[#d6d5c980] capitalize shadow-light_shadow"
                                    >
                                        <ChevronRightIcon className="size-[1rem]" />
                                    </span>
                                </Button>
                            </div>
                        ) : null}
                        <h2 className="text-black text-base font-bold">
                            NOTE CONTENT
                        </h2>
                        <div className="mt-[2.6rem] flex flex-col gap-y-[0.8rem]">
                            {handleNoteContentToDisplay(
                                data.data.type,
                                data.data.note_content,
                                data.data.note_addendums.field_addendums,
                                noteAnalysisResponse
                            )}
                        </div>
                        {data.data.note_addendums?.general_addendums &&
                        data.data.note_addendums?.general_addendums.length >
                            0 ? (
                            <div style={{ marginTop: "8px" }}>
                                <NoteContentItem
                                    headerText="NOTES ADDENDUM"
                                    addendums={
                                        data.data.note_addendums
                                            .general_addendums
                                    }
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
            )}
        </div>
    );
}
