import BackLink from "../../../../../../components/BackLink/BackLink";
import DocumentViewer from "../../../../../../components/DocumentViewer/DocumentViewer";
import {
    IClientInsuranceAttachment,
    InsuranceTabSubModals,
} from "../../../../types/clients.types";

interface InsuranceDocumentProps {
    setInsuranceModalInView?: (modalType: InsuranceTabSubModals) => void;
    attachmentInView: IClientInsuranceAttachment;
    attachmentUrl: string;
}

export default function InsuranceDocument({
    setInsuranceModalInView,
    attachmentInView,
    attachmentUrl,
}: InsuranceDocumentProps) {
    return (
        <div>
            {setInsuranceModalInView ? (
                <div>
                    <BackLink
                        onClick={() =>
                            setInsuranceModalInView(InsuranceTabSubModals.INFO)
                        }
                    />
                </div>
            ) : null}

            <DocumentViewer
                file={attachmentUrl}
                attachment={attachmentInView}
            />
        </div>
    );
}
