import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import cn from "classnames";
import * as yup from "yup";
import { parseISO } from "date-fns";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { DatePicker, Input, Select } from "@jhool-io/fe-components";
import {
    ClientStatus,
    IClientBasicInfo,
    IClientEditPersonalInfo,
    Pronouns,
    UserSex,
} from "../../../../../utils/types/client";
import {
    APP_COLORS,
    UNIQUE_CLIENT_EMAIL_REGEX,
    UNIQUE_CLIENT_EMAIL_SEPARATOR,
} from "../../../../../utils/constants";
import { STATES } from "../../../../../utils/helpers/us-states/us-states";
import {
    PHONE_REGEX,
    normalizePhoneNumber,
} from "../../../../../utils/helpers/phonenumber/phonenumber";
import {
    handleDisplayClientEmailCorrectly,
    handleFormatDatePickerValue,
    makeStringFirstLetterCapital,
    NUMBER_REGEX,
} from "../../../../../utils/helpers";
import useToast from "../../../../../hooks/useToast";
import { useEditClientPersonalInfo } from "../../../../../hooks/mutations/client";
import { IApiResponse } from "../../../../../utils/types/api-response";

interface EditBasicInfoProps {
    // Client information being edited
    clientBasicInfo: IClientBasicInfo;
    // Function to call when form submit button is clicked
    onFormSubmit(): void;
}

type Option = {
    label: React.ReactNode;
    value: boolean;
};

// Options for financial assistances select input
const options: Option[] = [
    {
        value: true,
        label: <span style={{ color: APP_COLORS.COLOR_PRIMARY }}>Yes</span>,
    },
    {
        value: false,
        label: <span style={{ color: APP_COLORS.COLOR_DANGER }}>No</span>,
    },
];

// Options for financial assistances select input
const clientStatusOptions: { label: React.ReactNode; value: ClientStatus }[] =
    Object.values(ClientStatus).map((status) => ({
        value: status,
        label: <span className="capitalize">{status}</span>,
    }));

// Schema for form validation
const schema = yup.object({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    preferred_name: yup.string(),
    pronouns: yup.string().nullable(),
    user_contact_phone_no: yup
        .string()
        .required("Contact is required")
        .matches(PHONE_REGEX, "Field should only contain numbers"),
    date_of_birth: yup.date().required("Date of birth is required"),
    user_email: yup
        .string()
        .email("Invalid email")
        .required("Email is required"),
    client_status: yup.string().required("Client status is required"),
    address: yup.string().required("Address is required"),
    zipcode: yup
        .string()
        .required("Zip code is required")
        .matches(NUMBER_REGEX, "Field should only contain numbers"),
    state: yup.string(),
    city: yup.string(),
    sex: yup.string().nullable(),
    financial_assistance: yup
        .boolean()
        .required("Financial assistance is required"),
    financial_assistance_end_date: yup.date(),
});

export default function EditBasicInfo({
    clientBasicInfo,
    onFormSubmit,
}: EditBasicInfoProps) {
    // Local component states
    const [localDateOfBirth, setLocalDateOfBirth] = React.useState<Date | null>(
        parseISO(clientBasicInfo.date_of_birth)
    );
    const [localFinancialAssistanceEndDate, setLocalFinancialAssitanceEndDate] =
        React.useState<Date | null>(
            clientBasicInfo.financial_assistance_end_date
                ? parseISO(clientBasicInfo.financial_assistance_end_date)
                : null
        );

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        watch,
        setValue,
    } = useForm<IClientEditPersonalInfo>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    // Query client
    const queryClient = useQueryClient();

    // To initialize clientId constant
    const params = useParams();
    const clientId = params.clientId as string;

    // Get the toast hook
    const { toast } = useToast();

    // Get the edit client basic info hook
    const { mutate, error } = useEditClientPersonalInfo(clientId);

    // Function to handle submit actions
    const onSubmit = (data: IClientEditPersonalInfo) => {
        const dataToSend = {
            ...data,
            date_of_birth: handleFormatDatePickerValue(data.date_of_birth),
            pronouns: data.pronouns || null,
        };

        if (dataToSend.user_email !== clientBasicInfo.email) {
            if (UNIQUE_CLIENT_EMAIL_REGEX.test(clientBasicInfo.email)) {
                dataToSend.user_email = `${
                    clientBasicInfo.email.split(
                        UNIQUE_CLIENT_EMAIL_SEPARATOR
                    )[0]
                }${UNIQUE_CLIENT_EMAIL_SEPARATOR}${dataToSend.user_email}`;
            }
        }

        if (dataToSend.financial_assistance_end_date)
            dataToSend.financial_assistance_end_date =
                handleFormatDatePickerValue(
                    dataToSend.financial_assistance_end_date
                );

        if (!dataToSend.financial_assistance)
            dataToSend.financial_assistance_end_date = null;

        if (!dataToSend.sex) dataToSend.sex = undefined;

        mutate(dataToSend, {
            onSuccess: (result) => {
                queryClient.setQueryData<IApiResponse<IClientEditPersonalInfo>>(
                    [clientId, "basic-personal-info"],

                    (prev) => {
                        const prevRequired =
                            prev as IApiResponse<IClientEditPersonalInfo>;
                        return {
                            message: prevRequired.message,
                            data: {
                                ...prevRequired.data,
                                ...result.data,
                                contact: data.user_contact_phone_no,
                            },
                        };
                    }
                );
                queryClient.invalidateQueries({
                    queryKey: [clientId, "basic-personal-info"],
                });
                toast({
                    mode: "success",
                    message: result.message || "Changes saved!",
                });
                onFormSubmit();
            },
            onError: () => {
                toast({
                    mode: "error",
                    message:
                        error?.response?.data.message ||
                        "Couldn't save changes!",
                });
            },
        });
    };

    // Get the states options for State select input
    const getClientStateFieldSelectOptions = () => {
        return STATES.map((state) => {
            return {
                label: state.name,
                value: state.abbrv,
            };
        });
    };

    /** Watch financial_assistance, to determine whether 
        to disable financial_assistance_end_date input or not
     */
    const financialAssistance = watch(
        "financial_assistance",
        clientBasicInfo.financial_assistance || false
    ) as boolean;

    // Get client contact info from the form
    const contact = watch("user_contact_phone_no");

    // Effect that changes the contact to phone number format
    React.useEffect(() => {
        if (contact) {
            setValue("user_contact_phone_no", normalizePhoneNumber(contact));
        }
    }, [contact, setValue]);

    // Get pronouns for select
    const getPronounsForSelect = Object.values(Pronouns).map((pronoun) => ({
        label: pronoun,
        value: pronoun,
    }));

    // Get sex for select
    const getSexForSelect = Object.values(UserSex).map((sex) => ({
        label: makeStringFirstLetterCapital(sex),
        value: sex,
    }));
    return (
        <form id="edit-basic-info" onSubmit={handleSubmit(onSubmit)}>
            <div className="fg fg-space-between two flex">
                <Input
                    {...register("first_name")}
                    label="First name"
                    defaultValue={clientBasicInfo.first_name}
                    placeholder="First name"
                    hasError={!!errors.first_name}
                    errorText={errors.first_name?.message}
                />
                <Input
                    {...register("last_name")}
                    label="Last name"
                    defaultValue={clientBasicInfo.last_name}
                    placeholder="Last name"
                    hasError={!!errors.last_name}
                    errorText={errors.last_name?.message}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Controller
                    name="pronouns"
                    control={control}
                    defaultValue={
                        clientBasicInfo.pronouns &&
                        getPronounsForSelect.find(
                            (option) =>
                                option.value.toLowerCase() ===
                                clientBasicInfo.pronouns.toLowerCase()
                        )?.value
                    }
                    render={({ field }) => (
                        <Select
                            label="Pronouns"
                            placeholder="Pronouns"
                            options={getPronounsForSelect}
                            onChange={(val) =>
                                field.onChange((val as Option).value)
                            }
                            defaultValue={
                                clientBasicInfo.pronouns &&
                                getPronounsForSelect.find(
                                    (option) =>
                                        option.value.toLowerCase() ===
                                        clientBasicInfo.pronouns.toLowerCase()
                                )
                            }
                            hasError={!!errors.pronouns}
                            errorText={errors.pronouns?.message}
                        />
                    )}
                />
                <Controller
                    name="sex"
                    control={control}
                    defaultValue={
                        clientBasicInfo.sex &&
                        getSexForSelect.find(
                            (option) =>
                                option.value.toLowerCase() ===
                                clientBasicInfo?.sex?.toLowerCase()
                        )?.value
                    }
                    render={({ field }) => (
                        <Select
                            label="Sex"
                            placeholder="Sex"
                            options={getSexForSelect}
                            onChange={(val) =>
                                field.onChange((val as Option).value)
                            }
                            defaultValue={
                                clientBasicInfo?.sex &&
                                getSexForSelect.find(
                                    (option) =>
                                        option.value.toLowerCase() ===
                                        clientBasicInfo?.sex?.toLowerCase()
                                )
                            }
                            hasError={!!errors.sex}
                            errorText={errors.sex?.message}
                        />
                    )}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Controller
                    name="date_of_birth"
                    control={control}
                    defaultValue={clientBasicInfo.date_of_birth}
                    render={({ field }) => (
                        <DatePicker
                            label="Date of Birth"
                            onChange={(date) => {
                                field.onChange(date);
                                setLocalDateOfBirth(date);
                            }}
                            maxDate={new Date(Date.now())}
                            selected={localDateOfBirth}
                            hasError={!!errors.date_of_birth}
                            errorText={
                                errors.date_of_birth?.type === "typeError"
                                    ? "invalid date value"
                                    : errors.date_of_birth?.message
                            }
                        />
                    )}
                />
                <Input
                    {...register("user_contact_phone_no")}
                    defaultValue={clientBasicInfo.contact}
                    label="Contact"
                    placeholder="Contact"
                    hasError={!!errors.user_contact_phone_no}
                    errorText={errors.user_contact_phone_no?.message}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Input
                    {...register("preferred_name")}
                    label="Preferred name"
                    defaultValue={clientBasicInfo.preferred_name}
                    placeholder="Preferred name"
                    hasError={!!errors.preferred_name}
                    errorText={errors.preferred_name?.message}
                />

                <Input
                    defaultValue={clientBasicInfo.current_provider || "--"}
                    disabled
                    label="Current provider"
                    placeholder="Current provider"
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Input
                    {...register("user_email")}
                    defaultValue={
                        clientBasicInfo.email
                            ? handleDisplayClientEmailCorrectly(
                                  clientBasicInfo.email
                              )
                            : ""
                    }
                    label="Email"
                    placeholder="Email"
                    hasError={!!errors.user_email}
                    errorText={errors.user_email?.message}
                />
                <Controller
                    name="client_status"
                    control={control}
                    defaultValue={
                        clientStatusOptions.find(
                            (option) =>
                                option.value === clientBasicInfo.client_status
                        )?.value
                    }
                    render={({ field }) => (
                        <Select
                            defaultValue={clientStatusOptions.find(
                                (option) =>
                                    option.value ===
                                    clientBasicInfo.client_status
                            )}
                            label="Client status"
                            options={clientStatusOptions}
                            onChange={(val) =>
                                field.onChange((val as Option).value)
                            }
                            hasError={!!errors.client_status}
                            errorText={errors.client_status?.message}
                        />
                    )}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Input
                    defaultValue={clientBasicInfo.latest_diagnosis.description}
                    label="Last diagnosis"
                    placeholder="Last diagnosis"
                    disabled
                />
                <DatePicker
                    disabled
                    label="Last session"
                    selected={
                        clientBasicInfo.last_session
                            ? parseISO(clientBasicInfo.last_session)
                            : null
                    }
                    onChange={(date) => date}
                />
            </div>
            <div
                className={cn("fg", {
                    "fg-space-between two flex": financialAssistance,
                })}
            >
                <Controller
                    name="financial_assistance"
                    control={control}
                    defaultValue={
                        options.find(
                            (option) =>
                                option.value ===
                                Boolean(clientBasicInfo.financial_assistance)
                        )?.value
                    }
                    render={({ field }) => (
                        <Select
                            defaultValue={options.find(
                                (option) =>
                                    option.value ===
                                    Boolean(
                                        clientBasicInfo.financial_assistance
                                    )
                            )}
                            label="Financial assistance"
                            options={options}
                            onChange={(val) =>
                                field.onChange((val as Option).value)
                            }
                            hasError={!!errors.financial_assistance}
                            errorText={errors.financial_assistance?.message}
                        />
                    )}
                />
                {financialAssistance && (
                    <Controller
                        name="financial_assistance_end_date"
                        control={control}
                        defaultValue={
                            clientBasicInfo.financial_assistance_end_date as string
                        }
                        render={({ field }) => (
                            <DatePicker
                                label="Financial assistance ends on"
                                onChange={(date) => {
                                    field.onChange(date);
                                    setLocalFinancialAssitanceEndDate(date);
                                }}
                                selected={localFinancialAssistanceEndDate}
                                hasError={
                                    !!errors.financial_assistance_end_date
                                }
                                errorText={
                                    errors.financial_assistance_end_date
                                        ?.type === "typeError"
                                        ? "invalid date value"
                                        : errors.financial_assistance_end_date
                                              ?.message
                                }
                            />
                        )}
                    />
                )}
            </div>
            <div className="fg">
                <Input
                    {...register("address")}
                    defaultValue={clientBasicInfo.address}
                    label="Address"
                    placeholder="Address"
                    hasError={!!errors.address}
                    errorText={errors.address?.message}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Controller
                    name="state"
                    control={control}
                    defaultValue={
                        getClientStateFieldSelectOptions().find(
                            (option) =>
                                option.value.toLowerCase() ===
                                    clientBasicInfo.state.toLowerCase() ||
                                option.label.toLowerCase() ===
                                    clientBasicInfo.state.toLowerCase()
                        )?.value
                    }
                    render={({ field }) => (
                        <Select
                            defaultValue={getClientStateFieldSelectOptions().find(
                                (option) =>
                                    option.value.toLowerCase() ===
                                        clientBasicInfo.state.toLowerCase() ||
                                    option.label.toLowerCase() ===
                                        clientBasicInfo.state.toLowerCase()
                            )}
                            label="State"
                            isSearchable
                            options={getClientStateFieldSelectOptions()}
                            onChange={(val) =>
                                field.onChange((val as Option).value)
                            }
                            hasError={!!errors.state}
                            errorText={errors.state?.message}
                        />
                    )}
                />
                <Input
                    {...register("city")}
                    defaultValue={clientBasicInfo.city}
                    label="City"
                    placeholder="City"
                    hasError={!!errors.city}
                    errorText={errors.city?.message}
                />
            </div>
            <Input
                {...register("zipcode")}
                defaultValue={clientBasicInfo.zip_code}
                label="Zipcode"
                placeholder="Zipcode"
                hasError={!!errors.zipcode}
                errorText={errors.zipcode?.message}
            />
        </form>
    );
}
