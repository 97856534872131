import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
    IApiResponse,
    IPaginatedApiResponse,
} from "../../../utils/types/api-response";
import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";
import { getSingleClaim, fetchClaims } from "../services/claims.services";
import {
    SingleClaimResponse,
    IClaim,
    IClaimsFilters,
    IGetClaimFilter,
} from "../types/claims.types";

export function useGetClaim(filters: IGetClaimFilter, isEnabled: boolean) {
    return useQuery<SingleClaimResponse, AxiosError<SingleClaimResponse>>({
        queryKey: ["get-claim", handleRemoveEmptyParamFromQueryParams(filters)],
        queryFn: () => getSingleClaim(filters || {}),
        enabled: isEnabled,
    });
}
export function useFetchClaims(filters: IClaimsFilters, enabled?: boolean) {
    return useQuery<
        IPaginatedApiResponse<IClaim[]>,
        AxiosError<IApiResponse<IClaim[]>>
    >({
        queryKey: [
            "claims",
            filters.search_string,
            handleRemoveEmptyParamFromQueryParams(filters),
        ],
        queryFn: () => fetchClaims(filters),
        enabled,
    });
}
