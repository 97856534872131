import { Switch, Tabs } from "@jhool-io/fe-components";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { cn } from "../../../../utils/helpers";
import InvoiceNotes from "../../../../components/Billing/InvoiceNotes/InvoiceNotes";
import EditClaims from "../EditClaims/EditClaims";
import BackLink from "../../../../components/BackLink/BackLink";
import BillsClientInfo from "../../../../components/BillsClientInfo/BillsClientInfo";
import { useFetchSessionNote } from "../../../../hooks/queries/note";
import SingleClaim from "../Claim/SingleClaim";
import PaymentTabWrapper from "../../../billing/components/PaymentTabWrapper/PaymentTabWrapper";
import { useFetchRemitClaims } from "../../../remits/hooks/remits.queries";
import { getClaimsListNavigateUrl } from "../../helpers/claims.helper";
import RemitsWrapper from "../../../remits/components/RemitsWrapper/RemitsWrapper";
import NoteItemsWrapper from "../../../notes/components/NoteItemsWrapper/NoteItemsWrapper";
import usePractice from "../../../../hooks/usePractice";
import { UserRole } from "../../../../utils/types/user";
import ClaimSubmissionHistory from "../ClaimSubmissionHistory/ClaimSubmissionHistory";

export default function ViewClaim() {
    const [searchParams, setSearchParams] = useSearchParams();
    const params = useParams();
    const { clientId, noteId } = params;
    const defaultActiveKey = searchParams.get("key");
    const claimsView = searchParams.get("claims_view") || "view";
    // const status = searchParams.get("status") as ClaimStatus;
    const navigate = useNavigate();

    const claimsListUrl = getClaimsListNavigateUrl();

    // const claimStatusWithoutRemit = [
    //     ClaimStatus.SUBMITTED,
    //     ClaimStatus.RECEIVED,
    //     ClaimStatus.ACKNOWLEDGED,
    //     ClaimStatus.PENDING,
    //     ClaimStatus.PAID,
    //     ClaimStatus.DENIED,
    //     ClaimStatus.REJECTED,
    //     ClaimStatus.REVERSED,
    //     ClaimStatus.APPEALED,
    //     ClaimStatus.PATIENT_PAYMENT_COLLECTION,
    //     ClaimStatus.CLOSED,
    // ];

    const { data } = useFetchSessionNote(
        clientId as string,
        noteId as string,
        Boolean(clientId) && Boolean(noteId)
    );

    // fetch single remit data
    const remitsData = useFetchRemitClaims({
        note_id: noteId,
    });
    const { practice } = usePractice();

    const handleSetClaimTabInUrl = (claimTab: string) => {
        searchParams.set("claim_tab", claimTab);
        setSearchParams(searchParams);
    };

    const switchOptions = [
        {
            title: "Content",
            key: "content",
            onClick: () => handleSetClaimTabInUrl("content"),
        },
        {
            title: "Claims Activity",
            key: "claims_activity",
            onClick: () => handleSetClaimTabInUrl("claims_activity"),
        },
    ];

    const handleSetTabInUrl = (claimsSubTab: string) => {
        searchParams.set("claims_sub_tab", claimsSubTab);
        if (
            claimsSubTab === "payments" &&
            practice?.roles?.includes(UserRole.CLIENT_SUPPORT)
        ) {
            searchParams.set("invoice_status[]", "pending_resolution");
        } else {
            searchParams.delete("invoice_status[]");
        }
        setSearchParams(searchParams);
    };

    const claimTabInUrl = searchParams.get("claim_tab") || "content";

    const tabItems = [
        {
            key: "notes",
            label: "Session Note",
            children: (
                <div className="mt-24">
                    <NoteItemsWrapper />
                </div>
            ),
            onClick: () => handleSetTabInUrl("note"),
        },
        {
            key: "claims",
            label: "Claims",
            children: (
                <div className="mt-24">
                    <Switch
                        switchOptions={switchOptions}
                        activeSwitch={claimTabInUrl}
                    />
                    <div className="mt-24">
                        {claimTabInUrl === "content" && (
                            <>
                                {claimsView === "edit" && <EditClaims />}
                                {claimsView === "view" && <SingleClaim />}
                            </>
                        )}
                        {claimTabInUrl === "claims_activity" && (
                            <ClaimSubmissionHistory />
                        )}
                    </div>
                </div>
            ),
            onClick: () => handleSetTabInUrl("claims"),
        },
        {
            key: "payments",
            label: "Payments History",
            children: (
                <div className="mt-24">
                    <PaymentTabWrapper />
                </div>
            ),
            onClick: () => handleSetTabInUrl("payments"),
        },
        {
            key: "remit",
            label: "Remits",
            children: (
                <div className="mt-24">
                    <RemitsWrapper />
                </div>
            ),
            onClick: () => handleSetTabInUrl("remit"),
            disabled: remitsData.data?.data.length === 0,
        },
    ];

    return (
        <div>
            <div className="mb-32">
                <BackLink onClick={() => navigate(claimsListUrl)} />
            </div>
            <div className="flex items-stretch max-[1100px]:flex-col gap-x-8">
                <div className="w-[70%] max-[1100px]:w-full max-[1100px]:mb-10">
                    <BillsClientInfo />
                </div>
                <div className=" w-[30%] max-[1100px]:w-full max-[1100px]:ml-0 border border-strokelight rounded-r8">
                    <InvoiceNotes invoiceId={data?.data.invoice_id as string} />
                </div>
            </div>

            <div className="pt-24">
                <Tabs
                    items={tabItems}
                    className={cn("!w-[100%] !mb-0 !items-end")}
                    defaultActiveKey={defaultActiveKey || "claims"}
                    size="small"
                />
            </div>
        </div>
    );
}
