import * as React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Input } from "@jhool-io/fe-components";
import { IChangeArchiveStatus } from "../../../../utils/types/notes";
import { useChangeNoteArchiveStatus } from "../../../../hooks/mutations/note";
import useToast from "../../../../hooks/useToast";
import { getNoteListNavigateUrl } from "../../../../modules/notes/helpers/notes.helpers";
import usePractice from "../../../../hooks/usePractice";

interface ArchiveNoteProps {
    // Function to call when form submit button is clicked
    onFormSubmit(): void;
    /** Id of single note */
    noteId?: string;
    /** Ids of selected notes */
    selectedIds?: string[];
}

// For form validation purposes
const schema = yup.object({
    reason: yup.string().required("Reason is required"),
});

export default function ArchiveNote({
    onFormSubmit,
    noteId,
    selectedIds,
}: ArchiveNoteProps) {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IChangeArchiveStatus>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    const params = useParams();
    const clientId = params.clientId as string;

    const { practice } = usePractice();

    const navigate = useNavigate();
    const location = useLocation();

    const archiveNote = useChangeNoteArchiveStatus();

    const { toast } = useToast();

    // Query client
    const queryClient = useQueryClient();

    const isSingleNoteView =
        location.pathname.includes("/notes") && clientId && noteId;

    const noteListNavigateUrl = getNoteListNavigateUrl(practice?.roles || []);

    const onSubmit = (data: IChangeArchiveStatus) => {
        const payload = {
            ...data,
            note_ids_to_archive:
                selectedIds && selectedIds.length > 0
                    ? selectedIds
                    : [noteId as string],
            archive_notes: data.reason,
            archive_status: true,
        };

        archiveNote.mutate(payload, {
            onSuccess: async (res) => {
                await queryClient.invalidateQueries({
                    queryKey: [`notes`],
                });

                await queryClient.invalidateQueries({
                    queryKey: ["supervisee"],
                });
                if (isSingleNoteView) {
                    await queryClient.invalidateQueries({
                        queryKey: [clientId, `session-note`, noteId],
                    });
                }

                toast({
                    mode: "success",
                    message: res.message || "Note archived successfully",
                });
                // Redirect back to notes list if current view is note content
                if (isSingleNoteView) {
                    navigate(noteListNavigateUrl);
                }

                onFormSubmit();
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not archive note at this time",
                });
            },
        });
    };

    return (
        <form id="archive-note" onSubmit={handleSubmit(onSubmit)}>
            <div className="fg">
                <Input
                    {...register("reason")}
                    label="Reason for archive"
                    placeholder="Reason for archive"
                    hasError={!!errors.reason}
                    errorText={errors.reason?.message}
                />
            </div>
        </form>
    );
}
