import * as React from "react";
import Navbar from "../../../../components/Navbar/Navbar";
import CreateNoteFormWrapper from "../../../../components/Notes/CreateNote/CreateNoteFormsWrapper/CreateNoteFormsWrapper";

export default function AddNote() {
    const { matches } = window.matchMedia("(max-width: 768px)");

    return (
        <>
            <Navbar showBackIconInNav={matches} title="Create Note" />
            <div className="page">
                <CreateNoteFormWrapper />
            </div>
        </>
    );
}
