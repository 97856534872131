/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// There is a weird behavoir when trying to edit a task, the Link will navigate when you
// try to type, temporary solution is to wrap the text editor in  div.
import * as React from "react";
import {
    Button,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Tag,
} from "@jhool-io/fe-components";
import {
    Link,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import MoreIcon from "../../../../components/Icons/More";
import { useFetchTasks } from "../../../../hooks/queries/tasks";
import {
    TaskCategory,
    TaskListSortAttribute,
    TaskStatus,
} from "../../../../utils/types/tasks";
import { AppSortOrder } from "../../../../utils/types";
import { useFetchUserDetails } from "../../../../hooks/queries/user";
import { momentNotTz } from "../../../../utils/moment";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import { cn, formatDate } from "../../../../utils/helpers";
import { ViewEditorText } from "../../../../components/TextEditor/ViewEditorText/ViewEditorText";
import {
    useCancelSession,
    useDeleteTasks,
    useUpdateTask,
} from "../../../../hooks/mutations/tasks";
import useToast from "../../../../hooks/useToast";
import { AddTextEditor } from "../../../../components/TextEditor/AddTextEditor/AddTextEditor";
import ReassignTask from "../../../tasks/components/ReassignTask/ReassignTask";
import usePractice from "../../../../hooks/usePractice";

type Dialogs =
    | "mark-task-complete"
    | "delete-task"
    | "cancel-session"
    | "forward-task"
    | "reassign-task";

interface PendingTaskProps {
    /** Client ID for filtering */
    clientId?: string;
    /** Context in which component is used */
    context?:
        | "dashboard_my_tasks"
        | "dashboard_supervisee_tasks"
        | "client_chart_pending_tasks"
        | "client_chart_all_tasks";
}

export default function PendingTasks({
    clientId,
    context = "dashboard_my_tasks",
}: PendingTaskProps) {
    const [modalInView, setModalInView] = React.useState<Dialogs | null>(null);
    const [taskInModalId, setTaskInModalId] = React.useState<string | null>(
        null
    );
    const [isEditingId, setIsEditingId] = React.useState<string | null>(null);
    const [taskDescription, setTaskDescription] = React.useState("");

    const loggedInUser = useFetchUserDetails();

    const { practice } = usePractice();

    const [searchParams] = useSearchParams();
    const params = useParams();

    // Query client
    const queryClient = useQueryClient();

    const { toast } = useToast();

    const clientName = searchParams.get("client_name") || "";
    const fromDateFilter = searchParams.get("from_date")
        ? momentNotTz(searchParams.get("from_date")).toDate()
        : null;
    const toDateFilter = searchParams.get("to_date")
        ? momentNotTz(searchParams.get("to_date")).toDate()
        : null;

    const getDefaultStatusForApi = () => {
        if (context === "client_chart_all_tasks") {
            return [
                TaskStatus.COMPLETED,
                TaskStatus.IN_PROGRESS,
                TaskStatus.NOT_STARTED,
            ];
        }

        return [TaskStatus.IN_PROGRESS, TaskStatus.NOT_STARTED];
    };

    const getDefaultCategoryForApi = () => {
        if (context === "dashboard_supervisee_tasks") {
            return [
                TaskCategory.REVIEW_SUPERVISEE_ADDENDUM,
                TaskCategory.REVIEW_SUPERVISEE_NOTE,
            ];
        }

        return [
            TaskCategory.WRITE_SESSION_NOTE,
            TaskCategory.MAKE_REQUESTED_CHANGE,
            TaskCategory.USER_CREATED,
            TaskCategory.WRITE_FOLLOW_UP_SAFETY_NOTE,
        ];
    };

    const { isLoading, error, data, isSuccess } = useFetchTasks({
        status: getDefaultStatusForApi(),
        assignee_user_id: loggedInUser.data?.user_id || "",
        client_name: clientName,
        from_date: fromDateFilter || undefined,
        to_date: toDateFilter || undefined,
        sort_attribute: TaskListSortAttribute.DUE_DATE,
        sort_order: AppSortOrder.ASC,
        client_id: clientId || "",
        category: getDefaultCategoryForApi(),
    });

    const updateTask = useUpdateTask();
    const deleteTask = useDeleteTasks();
    const cancelSession = useCancelSession();

    const navigate = useNavigate();

    const isViewingPendingTasks =
        context.includes("dashboard") || context.includes("pending");

    const handleModalInView = (modal: Dialogs | null, taskId?: string) => {
        setModalInView(modal);
        if (taskId && !modal) setTaskInModalId(null);
        else if (taskId && modal) setTaskInModalId(taskId);
    };

    const getTaskLinkRoute = (
        category: TaskCategory,
        noteId?: string,
        idOfClient?: string,
        status?: TaskStatus,
        session_history_id?: string
    ) => {
        if (
            status === TaskStatus.IN_PROGRESS &&
            category === TaskCategory.WRITE_SESSION_NOTE
        ) {
            return `/notes?status[]=draft`;
        }

        if (category === TaskCategory.WRITE_SESSION_NOTE) {
            return `/add-note?session_history_id=${session_history_id}`;
        }

        if (category === TaskCategory.MAKE_REQUESTED_CHANGE) {
            return `/notes/${idOfClient}/${noteId}?tab=changes-requested`;
        }

        if (category === TaskCategory.REVIEW_SUPERVISEE_ADDENDUM) {
            return `/notes/${idOfClient}/${noteId}`;
        }

        if (category === TaskCategory.REVIEW_SUPERVISEE_NOTE) {
            return `/notes/${idOfClient}/${noteId}`;
        }

        if (
            category === TaskCategory.USER_CREATED &&
            idOfClient &&
            !params.clientId
        ) {
            return `/clients/${idOfClient}`;
        }

        return "";
    };

    const handleUpdateTask = (payload: {
        todoId: string;
        status?: TaskStatus;
        description?: string;
    }) => {
        const dataToSend = {
            todo_id: payload.todoId,
            status: payload.status || undefined,
            description: payload.description || undefined,
        };

        if (isEditingId && !taskDescription) {
            toast({
                mode: "error",
                message: "Task description field cannot be empty",
            });
            return;
        }

        updateTask.mutate(
            { todos_to_update: [dataToSend] },
            {
                onSuccess: () => {
                    toast({
                        mode: "success",
                        message: "Task updated successfully",
                    });
                    setModalInView(null);
                    setTaskInModalId(null);
                    queryClient.invalidateQueries({
                        queryKey: ["todos"],
                    });
                    if (isEditingId) {
                        setIsEditingId(null);
                        setTaskDescription("");
                    }
                },
                onError: (err) => {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Could not update task at this time",
                    });
                },
            }
        );
    };

    const handleDeleteTask = () => {
        if (taskInModalId) {
            deleteTask.mutate([taskInModalId], {
                onSuccess: () => {
                    toast({
                        mode: "success",
                        message: "Task deleted successfully",
                    });
                    setModalInView(null);
                    setTaskInModalId(null);
                    queryClient.invalidateQueries({
                        queryKey: ["todos"],
                    });
                },
                onError: (err) => {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Could not delete task at this time",
                    });
                },
            });
        }
    };

    const handleCancelSession = (shouldNavigate: boolean) => {
        const todoDetails = data?.data.find((todo) => {
            return todo.todo_id === taskInModalId;
        });

        if (!todoDetails || !todoDetails?.metadata?.session_history_id) {
            toast({
                mode: "error",
                message: "Could not cancel session at this time",
            });
        } else {
            const payload = {
                session_history_ids: [
                    todoDetails?.metadata?.session_history_id as string,
                ],
            };

            cancelSession.mutate(payload, {
                onSuccess: () => {
                    queryClient.invalidateQueries({ queryKey: [`todos`] });
                    toast({
                        mode: "success",
                        message: "Session cancelled successfully!",
                    });
                    if (shouldNavigate) {
                        navigate(`/add-note?note_type=cancellation_note`);
                    }
                    handleModalInView(null);
                },
                onError: () => {
                    toast({
                        mode: "error",
                        message: "Could not cancel session",
                    });
                },
            });
        }
    };

    return (
        <>
            <Dialog
                open={
                    modalInView === "mark-task-complete" &&
                    Boolean(taskInModalId)
                }
            >
                <DialogContent
                    title="Mark task as complete"
                    variant="center"
                    onSaveClick={() =>
                        handleUpdateTask({
                            todoId: taskInModalId as string,
                            status: TaskStatus.COMPLETED,
                        })
                    }
                    handleCloseDialog={() => handleModalInView(null)}
                    isCancelBtnDisabled={updateTask.isLoading}
                    isSubmitBtnDisabled={updateTask.isLoading}
                    classNames="pt-24 pb-48"
                >
                    <div>
                        <h3 className="font-medium text-xl mb-8">
                            Are you sure you want to mark task as complete
                        </h3>
                        <p className="text-gray font-normal text-base">
                            By completing this task, you won’t be able to
                            recover task as you have marked and completed the
                            task.
                        </p>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog
                open={modalInView === "delete-task" && Boolean(taskInModalId)}
            >
                <DialogContent
                    title="Delete task"
                    variant="center"
                    handleCloseDialog={() => handleModalInView(null)}
                    isSubmitBtnDisabled={deleteTask.isLoading}
                    onSaveClick={handleDeleteTask}
                    isDeleting
                >
                    <div>
                        <h3 className="font-medium text-xl mb-8">
                            Are you sure you want to delete this task
                        </h3>
                        <p className="text-gray font-normal text-base">
                            By deleting this task, you won’t be able to recover
                            task and this action can’t be undone
                        </p>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog
                open={
                    (modalInView === "forward-task" ||
                        modalInView === "reassign-task") &&
                    Boolean(taskInModalId)
                }
            >
                <DialogContent
                    title={
                        modalInView === "forward-task"
                            ? "Forward task"
                            : "Re-assign task"
                    }
                    variant="center"
                    handleCloseDialog={() => handleModalInView(null)}
                    submitBtnFormValue="reassign-task"
                    isCancelBtnDisabled={updateTask.isLoading}
                    isSubmitBtnDisabled={updateTask.isLoading}
                >
                    <ReassignTask
                        onFormSubmit={() => handleModalInView(null)}
                        action={
                            modalInView === "forward-task"
                                ? "forward"
                                : "reassign"
                        }
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                open={
                    modalInView === "cancel-session" && Boolean(taskInModalId)
                }
            >
                <DialogContent
                    title="Cancel session"
                    variant="center"
                    onSaveClick={() => handleCancelSession(true)}
                    handleCloseDialog={() => handleModalInView(null)}
                    isCancelBtnDisabled={cancelSession.isLoading}
                    isSubmitBtnDisabled={cancelSession.isLoading}
                    classNames="pt-24 pb-48"
                    onCancelClick={() => handleCancelSession(false)}
                    isDeleting
                    cancelText="Yes,cancel"
                    saveText="No,create No show note"
                >
                    <div>
                        <h3 className="font-medium text-xl mb-8">
                            Report session as Cancelled
                        </h3>
                        <p className="text-gray font-normal text-base">
                            Did the client cancel the session at least 24 hours
                            before the start time
                        </p>
                    </div>
                </DialogContent>
            </Dialog>
            <div className="h-full">
                {isLoading &&
                    [1, 2, 3, 4].map((item) => (
                        <div
                            key={item}
                            className="flex flex-col gap-y-12 w-4/5 md:w-3/4 pb-24 last:pb-0"
                        >
                            <Skeleton
                                containerTestId="skeleton-loader"
                                width="100%"
                                height={20}
                            />
                            <Skeleton
                                containerTestId="skeleton-loader"
                                width="100%"
                                height={20}
                            />
                            <Skeleton
                                containerTestId="skeleton-loader"
                                width="35%"
                                height={30}
                            />
                        </div>
                    ))}
                {error && error.response?.status !== 404 && (
                    <ListState
                        context="general"
                        isError
                        errorMsg={
                            error?.response?.data?.message ||
                            `${
                                isViewingPendingTasks
                                    ? "Could not load pending task as this time"
                                    : "Could not load tasks as this time"
                            } `
                        }
                        stateHelperText="Try reloading this page to solve this issue"
                    />
                )}
                {data && isSuccess && data.data.length === 0 && (
                    <ListState
                        stateHelperText="Tasks will be added when you have one or more tasks"
                        isError={false}
                        emptyMessage={
                            isViewingPendingTasks
                                ? "No pending task found"
                                : "No task found"
                        }
                        context="general"
                    />
                )}
                {data && isSuccess && data.data.length > 0 && (
                    <div>
                        {data.data.map((task) => (
                            <Link
                                to={getTaskLinkRoute(
                                    task.category,
                                    task.metadata?.note_id,
                                    task.metadata?.client_id,
                                    task.status,
                                    task.metadata?.session_history_id
                                )}
                                key={task.todo_id}
                                className="flex items-start justify-between pb-24 gap-x-12"
                            >
                                <div className="flex flex-col w-4/5 relative items-start before:content-[''] before:size-8 before:rounded-full before:bg-gray after:content-[''] before:absolute before:top-[5px] before:left-0 after:bg-strokelight after:w-px after:h-full after:absolute after:left-[3.5px] after:top-[20px] pl-24 ">
                                    <div className="text-sm font-medium mb-12 *:!m-0">
                                        {task.title ? (
                                            <ViewEditorText text={task.title} />
                                        ) : (
                                            "--"
                                        )}
                                    </div>
                                    <div className="mb-12 text-gray font-normal text-xs w-full">
                                        {isEditingId === task.todo_id ? (
                                            <div id="edit">
                                                <div
                                                    onClick={(e) =>
                                                        e.preventDefault()
                                                    }
                                                >
                                                    <AddTextEditor
                                                        defaultValue={
                                                            taskDescription ||
                                                            ""
                                                        }
                                                        onEditorTextChange={
                                                            setTaskDescription
                                                        }
                                                        isRequiredFieldProvided={Boolean(
                                                            taskDescription
                                                        )}
                                                        reduceToolbaroptions
                                                    />
                                                </div>
                                                <div className="flex justify-end gap-8 mt-12 mb-24">
                                                    <Button
                                                        mode="filled"
                                                        variant="secondary"
                                                        aria-label="Cancel"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setIsEditingId(
                                                                null
                                                            );
                                                        }}
                                                        disabled={
                                                            updateTask.isLoading
                                                        }
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        type="submit"
                                                        mode="filled"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            handleUpdateTask({
                                                                todoId: task.todo_id,
                                                                description:
                                                                    taskDescription,
                                                            });
                                                        }}
                                                        disabled={
                                                            updateTask.isLoading
                                                        }
                                                    >
                                                        Save changes
                                                    </Button>
                                                </div>
                                            </div>
                                        ) : (
                                            (task?.description && (
                                                <ViewEditorText
                                                    text={task.description}
                                                />
                                            )) ||
                                            "--"
                                        )}
                                    </div>
                                    <Tag
                                        title={`Due date: ${formatDate(
                                            task.due_date
                                        )}`}
                                        bgColor="#FFDAD9"
                                        textColor="#981F41"
                                        className="py-4 px-8 text-xs"
                                    />
                                </div>
                                <Dropdown>
                                    <DropdownTrigger asChild>
                                        <Button
                                            variant="normal"
                                            size="auto"
                                            className="w-16 h-16 rounded-[16px] shadow-morebtn relative z-[1] data-[state=open]:border-primary-800"
                                        >
                                            <MoreIcon />
                                        </Button>
                                    </DropdownTrigger>
                                    <DropdownContent
                                        width="auto"
                                        align="end"
                                        className={cn(
                                            "-mt-[7px] z-[10] relative",
                                            {
                                                "z-[50]": clientId,
                                            }
                                        )}
                                    >
                                        <DropdownItem
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleModalInView(
                                                    "mark-task-complete",
                                                    task.todo_id
                                                );
                                            }}
                                        >
                                            Mark as complete
                                        </DropdownItem>
                                        {loggedInUser.data?.user_id ===
                                            task.creator.user_id && (
                                            <DropdownItem
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleModalInView(
                                                        "delete-task",
                                                        task.todo_id
                                                    );
                                                }}
                                            >
                                                Delete Task
                                            </DropdownItem>
                                        )}
                                        <DropdownItem
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setIsEditingId(task.todo_id);
                                                setTaskDescription(
                                                    task.description || ""
                                                );
                                            }}
                                        >
                                            Edit
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleModalInView(
                                                    "forward-task",
                                                    task.todo_id
                                                );
                                            }}
                                        >
                                            Forward task
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleModalInView(
                                                    "reassign-task",
                                                    task.todo_id
                                                );
                                            }}
                                        >
                                            Reassign task
                                        </DropdownItem>
                                        {practice?.provider_id &&
                                            task?.metadata &&
                                            task.metadata
                                                .session_history_id && (
                                                <DropdownItem
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleModalInView(
                                                            "cancel-session",
                                                            task.todo_id
                                                        );
                                                    }}
                                                >
                                                    Report session as cancelled
                                                </DropdownItem>
                                            )}
                                    </DropdownContent>
                                </Dropdown>
                            </Link>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
}
