import { SvgProps } from "../../utils/types";

export default function TrashIcon({ stroke }: SvgProps) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.44434 3.77783H13.5554"
                stroke={stroke || "#858CA0"}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6 3.77789V2.44455C6 1.95389 6.39822 1.55566 6.88889 1.55566H9.11111C9.60178 1.55566 10 1.95389 10 2.44455V3.77789"
                stroke={stroke || "#858CA0"}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.0001 6L11.6445 12.76C11.5948 13.704 10.8143 14.4444 9.86942 14.4444H6.13164C5.18587 14.4444 4.40631 13.704 4.35653 12.76L4.00098 6"
                stroke={stroke || "#858CA0"}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
