import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";
import {
    IClaimsFilters,
    ICreateDraft,
    IGetClaimFilter,
    IUpdateClaimStatus,
    IBulkUpdateClaims,
    IUpdateClaimContent,
    IBulkCreateClaim,
    IClaimSubmissionHistoryFilters,
} from "../types/claims.types";
import axios from "../../../utils/axios";

// Endpoint to get a single claim
export async function getSingleClaim(filters: IGetClaimFilter) {
    const response = await axios.get(`/claims`, {
        params: handleRemoveEmptyParamFromQueryParams(filters),
    });

    return response.data;
}

export async function updateClaimStatus(payload: IUpdateClaimStatus) {
    const response = await axios.patch(`/claims`, {
        ...payload,
    });
    return response.data;
}

export async function createDraftClaim(payload: ICreateDraft) {
    const response = await axios.post(`/claims`, {
        ...payload,
    });
    return response.data;
}

export async function bulkCreateClaims(payload: IBulkCreateClaim) {
    const response = await axios.post(`/claims/bulk-regenerate`, payload);
    return response.data;
}

export async function fetchClaims(filters: IClaimsFilters) {
    const response = await axios.get(`/claims/search`, {
        params: handleRemoveEmptyParamFromQueryParams(filters),
    });
    return response.data;
}

export async function bulkUpdateClaims(payload: IBulkUpdateClaims) {
    const response = await axios.post(`/claims/status`, {
        ...payload,
    });
    return response.data;
}

export async function deleteClaims(claimIds: string[]) {
    const response = await axios.delete(`/claims`, {
        data: { claim_ids: claimIds },
    });
    return response.data;
}

export async function checkClaimStatus(claimIds: string[]) {
    const response = await axios.post(`/claims/status-checks`, {
        claim_ids: claimIds,
    });
    return response.data;
}

export async function updateClaimContent(payload: IUpdateClaimContent) {
    const response = await axios.post(`/claims/content`, payload);

    return response.data;
}

export async function fetchClaimSubmissionHistory(
    claimId: string,
    filters: IClaimSubmissionHistoryFilters
) {
    const response = await axios.get(`/claims/${claimId}/submission-history`, {
        params: handleRemoveEmptyParamFromQueryParams(filters),
    });

    return response.data;
}
