import * as React from "react";
import { Switch } from "@jhool-io/fe-components";
import { useSearchParams } from "react-router-dom";
import PendingTasks from "../PendingTasks/PendingTasks";
import usePractice from "../../../../hooks/usePractice";

export default function PendingTasksWrapper() {
    const [searchParams, setSearchParams] = useSearchParams();

    const tabInUrl = searchParams.get("task_tab") || "my_tasks";

    const { practice } = usePractice();

    const handleTabSwitch = (tab: string) => {
        searchParams.delete("client_name");
        searchParams.delete("from_date");
        searchParams.delete("to_date");

        searchParams.set("task_tab", tab);

        setSearchParams(searchParams);
    };

    const switchOptions = [
        {
            key: "my_tasks",
            title: "My Tasks",
            onClick: () => handleTabSwitch("my_tasks"),
        },
        {
            key: "supervisee_tasks",
            title: "Supervisees' Tasks",
            onClick: () => handleTabSwitch("supervisee_tasks"),
        },
    ];
    return (
        <div className="h-full">
            {practice?.is_supervisor ? (
                <>
                    <Switch
                        switchOptions={switchOptions}
                        activeSwitch={tabInUrl}
                    />
                    <div className="mt-24">
                        {tabInUrl === "my_tasks" && <PendingTasks />}
                        {tabInUrl === "supervisee_tasks" && (
                            <PendingTasks context="dashboard_supervisee_tasks" />
                        )}
                    </div>
                </>
            ) : (
                <PendingTasks />
            )}
        </div>
    );
}
