import { ISortTable } from "../../../utils/types";
import {
    ProviderStatus,
    SessionRecurrenceRule,
    SessionServiceType,
} from "../../../utils/types/session";
import { NoteStatus, NoteTypes } from "../../notes/types/notes.types";

export enum ClientCardTypes {
    DISCOVER_FINANCIAL = "Discover",
    AMERICAN_EXPRESS = "AmericanExpress",
    MASTERCARD = "MasterCard",
    CAPITAL_ONE = "Capital One",
    CHASE = "Chase",
    VISA = "Visa",
    DINERSCLUB = "DinersClub",
    JCB = "JCB",
}

export enum ProviderCredential {
    PHD = "PhD",
    PSYD = "PsyD",
    LMSW = "LMSW",
    LMHC = "LMHC",
    LCSW = "LCSW",
    MHCLP = "MHC-LP",
    INTERN = "Intern",
    MD = "MD",
    LMSWA = "LMSW-A",
    LMHCA = "LMHC-A",
    LMHCB = "LMHC-B",
    LMHCC = "LMHC-C",
    PSYDA = "PsyD-A",
    PSYDB = "PsyD-B",
    PSYDC = "PsyD-C",
    PHDA = "PhD-A",
    PHDB = "PhD-B",
    PHDC = "PhD-C",
    LCSWA = "LCSW-A",
    LCSWB = "LCSW-B",
    LCSWC = "LCSW-C",
    EXTERN = "Extern",
}

export enum PaymentCardStatus {
    VALIDATED = "validated",
    UNVALIDATED = "unvalidated",
}

export enum RelationshipToPrimaryInsured {
    SELF = "self",
    SPOUSE = "spouse",
    CHILD = "child",
    OTHER = "other",
}

export enum UserSex {
    MALE = "male",
    FEMALE = "female",
    UNKNOWN = "unknown",
}

export enum InsuranceType {
    MEDICARE = "medicare",
    MEDICAID = "medicaid",
    TRICARE = "tricare",
    CHAMPVA = "champva",
    GROUP_HEALTH_PLAN = "group_health_plan",
    FECA_BLK_LUNG = "feca_blk_lung",
    Other = "other",
}

export enum ClientDocumentTypes {
    CONSENT_FORM = "consent form",
    INSURANCE_SCAN = "insurance scan",
    ID_CARD = "id_card",
    APPEAL = "appeal",
    AUTHORIZATION = "authorization",
    CORRESPONDENCE_WITH_PATIENTS = "correspondence_with_patients",
    EXPLANATION_OF_BENEFITS = "explanation_of_benefits",
    OTHER = "other",
}

export enum InsuranceTabSubModals {
    INFO = "info",
    DOCUMENT = "document",
    EDIT = "edit",
    UPLOAD_DOCUMENT = "upload-document",
}

export interface IClientCreditCardDetails {
    payment_card_id: string;
    merchant_customer_id: string;
    profile_id: string;
    payment_profile_id: string;
    first_name: string;
    last_name: string;
    masked_card_number: string;
    expiration_date: string;
    billing_address: string;
    zip_code: string;
    card_type: ClientCardTypes;
    state: string;
    country: string;
    card_status: PaymentCardStatus;
    created_source: string;
    is_current: boolean;
    card_label: string;
}

export interface INewCreditCardDetails {
    card_number: string;
    card_cvv: string;
    expiration_month: number;
    expiration_year: number;
    first_name: string;
    last_name: string;
    billing_address: string;
    state: string;
    zip_code: string;
    country: string;
    card_label: string;
}

export interface ICardHistory {
    trans_id: string;
    invoice_number: string;
    transaction_status: string;
    submit_time_utc: string;
    first_name: string;
    last_name: string;
    settlement_time_utc: string;
    settle_amount: number;
    payment_description: string;
}

export interface IClientInsuranceProvider {
    insurance_provider_id: string;
    name: string;
}

export interface ICardFilterTypes {
    page?: number;
    limit?: number;
}

export interface IClientInsuranceAttachment {
    id: string;
    name: string;
    url: string;
    metadata: {
        content_type: string;
    } | null;
    third_party_type: "dropbox" | "google_drive" | null;
}

export interface IClientInsuranceDetails {
    insurance_id: string;
    name: string;
    is_current: boolean;
    employer_name: string;
    non_client_primary_insured_name: string;
    start_date: string;
    end_date: string;
    insurance_member_id: string;
    is_cal_year: boolean;
    last_verified: string | null;
    non_client_primary_insured_dob: string;
    primary_insured_address: string;
    primary_insured_name: string;
    primary_insured_dob: string;
    financial_assistance_beneficiary: boolean;
    attached_files: IClientInsuranceAttachment[] | null;
    primary_insured_state: string;
    primary_insured_zipcode: string;
    status: ProviderStatus;
    insurance_accepted: boolean;
    relationship_to_primary_insured?: RelationshipToPrimaryInsured;
    primary_insured_city?: string;
    primary_insured_phone_no?: string;
    primary_insured_sex?: UserSex;
    insured_policy_group?: string;
    insurance_type?: InsuranceType;
}

export interface IClientNewInsuranceDetails {
    author?: string;
    insurance_provider_id: string;
    is_cal_year?: boolean;
    start_date?: string;
    end_date?: string;
    employer_name: string;
    insurance_member_id?: string;
    last_verified?: string;
    non_client_primary_insured_name?: string;
    non_client_primary_insured_dob?: string;
    primary_insured_address?: string;
    primary_insured_state?: string;
    primary_insured_zipcode?: string;
    relationship_to_primary_insured?: RelationshipToPrimaryInsured;
    primary_insured_city?: string;
    primary_insured_phone_no?: string;
    primary_insured_sex?: UserSex;
    primary_insured_dob?: string;
    insured_policy_group?: string;
    insurance_type?: InsuranceType;
}

export interface IClientEditInsuranceDetails {
    insurance_provider_id: string;
    is_cal_year?: boolean;
    start_date?: string;
    end_date?: string;
    employer_name: string;
    insurance_member_id?: string;
    last_verified?: string | null;
    non_client_primary_insured_name?: string;
    non_client_primary_insured_dob?: string;
    primary_insured_address?: string;
    primary_insured_state?: string;
    primary_insured_zipcode?: string;
    status: ProviderStatus;
    insurance_accepted: boolean;
    relationship_to_primary_insured?: RelationshipToPrimaryInsured;
    primary_insured_city?: string;
    primary_insured_phone_no?: string;
    primary_insured_sex?: UserSex;
    primary_insured_dob?: string;
    insured_policy_group?: string;
    insurance_type?: InsuranceType;
}
export interface IClientDocumentFilters {
    client_id: string;
    page?: number;
    limit?: number;
    type?: ClientDocumentTypes;
}

export interface IClientDocument {
    document_id: string;
    title: string;
    type: string;
    url: string;
    uploaded_by: {
        user_id: string;
        first_name: string;
        last_name: string;
    };
    uploaded_date: string;
    metadata: {
        content_type: string;
    } | null;
    third_party_type: "dropbox" | "google_drive" | null;
}

export enum ClientDocumentType {
    CONSENT_FORM = "consent form",
    INSURANCE_SCAN = "insurance scan",
    ID_CARD = "id_card",
    APPEAL = "appeal",
    AUTHORIZATION = "authorization",
    CORRESPONDENCE_WITH_PATIENTS = "correspondence_with_patients",
    EXPLANATION_OF_BENEFITS = "explanation_of_benefits",
    OTHER = "other",
}
export interface IClientDocumentsListFilters {
    client_id: string;
    page?: number;
    limit?: number;
    type?: ClientDocumentType;
}

export interface IClientProviderHistory {
    provider_id: string;
    provider_name: string;
    is_current: boolean;
    credential: ProviderCredential;
    service: SessionServiceType;
    no_of_sessions: number;
    first_session_date: string;
    last_seen: string;
    status: ProviderStatus;
    session_id: string;
    appointment: {
        session_day: string;
        session_start_hr: number;
        session_start_min: number;
        session_end_hr: number;
        session_end_min: number;
        recurrence_rule: SessionRecurrenceRule;
    };
}

export interface IClientProviderHistoryListFilters {
    limit: number;
    page: number;
}

export enum ClientMemoCategories {
    INTAKE = "intake",
    BILLING = "billing",
    CLIENT_SERVICE = "client services",
    REPORTS = "reports",
    NOTES = "notes",
}

export enum ClientMemoStatus {
    ACTIVE = "active",
    PAUSED = "paused",
}

export interface IClientMemo extends ISortTable {
    memo_id: string;
    category: ClientMemoCategories;
    date_created: string;
    type: "All" | "system" | "user";
    author: {
        first_name: string;
        last_name: string;
        user_id: string;
    } | null;
    description: string;
    date_updated: string;
    updated_by: string;
    memo_status: ClientMemoStatus;
}

export interface INewMemo {
    author?: string;
    category: ClientMemoCategories;
    description: string;
}

export interface IClientMemoListFilterTypes {
    author_name?: string;
    type?: string;
    category?: string[];
    from_date?: null | Date;
    to_date?: null | Date;
    page?: number;
    limit?: number;
    memo_status?: string;
}

export interface IClientNotesListFilterTypes {
    provider_name: string;
    status?: string[];
    type: string[];
    from_date: null | Date;
    to_date: null | Date;
    page: number;
    limit: number;
    show_archived?: string;
}

export interface IClientNote {
    note_id: string;
    date_of_service: string;
    note_type: NoteTypes;
    provider: {
        user_id: string;
        last_name: string;
        first_name: string;
    };
    note_url: string;
    status: NoteStatus;
    created_at: string;
}
