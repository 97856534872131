import * as React from "react";
import cn from "classnames";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import { Button } from "@jhool-io/fe-components";
import { useFetchSessionNote } from "../../../hooks/queries/note";
import {
    formatDate,
    formatZonedTimeToUtc,
    getTimeDuration,
    handleFormatDatePickerValue,
    showUserLocalTime,
} from "../../../utils/helpers";
import {
    EditNoteSteps,
    FamilyIntakeNotePayload,
    INewNoteAppoinmentDetails,
    NoteTypes,
} from "../../../utils/types/notes";
import ListState from "../../ListState/ListState";
import Skeleton from "../../Skeleton/Skeleton";
import AppointmentDetails from "../AppointmentDetails/AppointmentDetails";
import EditCancellation, {
    IEditCancellationNotePayload,
} from "./EditCancellation/EditCancellation";
import styles from "./EditNote.module.scss";
import EditRecordOfDisclosure, {
    IEditRecordOfDisclosurePayload,
} from "./EditRecordOfDisclosure/EditRecordOfDisclosure";
import EditSoapNote, { IEditSoapNote } from "./EditSoapNote/EditSoapNote";
import EditSupervision, {
    IEditSupervisionNotePayload,
} from "./EditSupervision/EditSupervision";
import EditTermination, {
    IEditTerminationNotePayload,
} from "./EditTermination/EditTermination";
import EditSafetyPlan, {
    IEditSafetyPlanPayload,
} from "./EditSafetyPlan/EditSafetyPlan";
import EditTreatmentReview, {
    IEditTreatmentReviewNotePayload,
} from "./EditTreatmentReview/EditTreatmentReview";
import EditIndividualIntake, {
    IEditIndividualIntakeNote,
} from "./EditIndividualIntake/EditIndividualIntake";
import EditFamilyIntake, {
    IEditFamilyIntakeNote,
} from "./EditFamilyIntake/EditFamilyIntake";
import {
    useEditDraftNote,
    useSignSessionNote,
} from "../../../hooks/mutations/note";
import useToast from "../../../hooks/useToast";
import InfoIcon from "../../Icons/InfoIcon";
import {
    AppointmentLocation,
    AppointmentLocationTeletherapy,
    SessionPlaceOfServiceCode,
} from "../../../utils/types/session";
import AddNoteMetadata from "../CreateNote/AddNoteMetadata/AddNoteMetadata";
import EditGeneralNote, {
    IEditGeneralNotesPayload,
} from "./EditGeneralNote/EditGeneralNote";
import EditSupervisorCheckInNote, {
    IEditSupervisorCheckInNotePayload,
} from "./EditSupervisorCheckInNote/EditSupervisorCheckInNote";
import { getNoteListNavigateUrl } from "../../../modules/notes/helpers/notes.helpers";
import usePractice from "../../../hooks/usePractice";
import { useAppSelector } from "../../../hooks/useRedux";

type Buttons = "edit" | "with-signature";

interface EditNoteFormWrapperProps {
    currentEditNoteStepInView?: EditNoteSteps;
    currentNoteAppointmentDetails?: INewNoteAppoinmentDetails;
}

export default function EditNote({
    currentEditNoteStepInView,
    currentNoteAppointmentDetails,
}: EditNoteFormWrapperProps) {
    const [submitBtnClicked, setSubmitBtnClicked] =
        React.useState<Buttons | null>(null);
    const [timeLastSaved, setTimeLastSaved] = React.useState<string | null>(
        null
    );
    const [noteAppointmentDetails, setNoteAppointmentDetails] =
        React.useState<INewNoteAppoinmentDetails | null>(
            currentNoteAppointmentDetails || null
        );
    const [editNoteStepInView, setEditNoteStepInView] =
        React.useState<EditNoteSteps>(currentEditNoteStepInView || "edit-note");

    const [searchParams] = useSearchParams();

    // Get id from url params
    const params = useParams();
    const noteId = params.noteId as string;
    const clientId = params.clientId as string;

    const { isOpen } = useAppSelector((state) => state.nav);

    // Get currentAction search param from url
    // helps us to determine what type of action is being carried
    // out on the note
    const currentAction = searchParams.get("action");

    // React router navigation hook
    const navigate = useNavigate();

    const { toast } = useToast();

    // Query client
    const queryClient = useQueryClient();

    // hooks for editing and signing note
    const editDraftNote = useEditDraftNote();

    // To determine if mutation is currently running, returns 0 || 1
    const isCurrentlyEditingNote = useIsMutating(["edit-note"]);

    const signSessionNote = useSignSessionNote();

    // Hook for fetching note
    const { data, error, isLoading, isSuccess } = useFetchSessionNote(
        clientId,
        noteId,
        Boolean(clientId) && Boolean(noteId)
    );

    const { practice } = usePractice();

    // Function to call when any of the submit button is clicked
    const handleSetButtonClicked = (button: Buttons) => {
        if (button === "edit") {
            navigate(`/notes/${noteId}/${clientId}/edit?action=edit`);
        } else {
            navigate(`/notes/${noteId}/${clientId}/edit?action=with_signature`);
        }
        setSubmitBtnClicked(() => button);
    };

    // Function for setting createNoteInView state
    const handleEditNoteStepInView = (step: EditNoteSteps) => {
        setEditNoteStepInView(() => step);
        window.scrollTo(0, 0);
    };

    // Function for setting noteAppointmentDetails state
    const handleSetAppointmentDetailsInView = (
        appointmentDetails: INewNoteAppoinmentDetails | null
    ) => {
        setNoteAppointmentDetails(() => appointmentDetails);
    };

    // function to disable button when dos is in the future
    const isTheDateOfServiceInTheFuture = () => {
        if (data?.data?.date_of_service) {
            const formattedDateOfService = handleFormatDatePickerValue(
                data?.data?.date_of_service as string
            );
            const today = new Date();
            const formattedCurrentDate = today.toISOString().split("T")[0];
            if (formattedDateOfService > formattedCurrentDate) {
                return true;
            }
        }
        return false;
    };

    // Handle edit form submit
    const onSubmit = (
        values:
            | IEditCancellationNotePayload
            | IEditSupervisionNotePayload
            | IEditRecordOfDisclosurePayload
            | IEditSafetyPlanPayload
            | IEditTerminationNotePayload
            | IEditTreatmentReviewNotePayload
            | IEditSoapNote
            | IEditIndividualIntakeNote
            | IEditFamilyIntakeNote
            | IEditGeneralNotesPayload
            | IEditSupervisorCheckInNotePayload
    ) => {
        let diagnosisCodes: string[] | null = null;
        // we have this field in safety plan note
        let whenShouldFollowUpBeDoneForThisClient: string | null = null;
        // we have these fields in termination note
        let lastSessionDate: string | null = null;
        let firstSessionDate: string | null = null;
        let dateOfNextSession: string | null = null;
        let dateOfLastExam: string | null = null;
        let expectedTerminationDate: string | null = null;
        let detailsForDiminishedPoorLimitedQualityPatientOrientedEvidence = "";
        let arrayOfdetailsForDiminishedPoorLimitedQualityPatientOrientedEvidences: string[] =
            [];
        let family: FamilyIntakeNotePayload[] = [];
        let deliveryOption: string | undefined;
        let otherDeliveryOption: string | undefined;
        let otherFrequencyOfSession: string | undefined;
        let providerLocation: string | undefined;
        let otherProviderLocation: string | undefined;
        let consentWasReceivedForTheTeletherapySession: boolean | undefined;
        let theTeletherapySessionWasConductedViaVideo: boolean | undefined;
        let posCode: string | undefined;
        let sessionStartTime: string | undefined;
        let sessionEndTime: string | undefined;

        if ("diagnosis_codes" in values) {
            /* this is coming in as an array of string(codes) */
            diagnosisCodes = values.diagnosis_codes;
        }

        if ("appointment_location" in values) {
            if (values.appointment_location === AppointmentLocation.IN_PERSON) {
                deliveryOption = undefined;
                posCode = SessionPlaceOfServiceCode.OFFICE;
                otherDeliveryOption = undefined;
                consentWasReceivedForTheTeletherapySession = undefined;
                theTeletherapySessionWasConductedViaVideo = undefined;
                providerLocation = undefined;
                otherProviderLocation = undefined;
            } else if (
                values.appointment_location === AppointmentLocation.TELETHERAPY
            ) {
                deliveryOption = values.delivery_option;
                otherDeliveryOption = values.other_delivery_option;
                consentWasReceivedForTheTeletherapySession =
                    values.consent_was_received_for_the_teletherapy_session;
                theTeletherapySessionWasConductedViaVideo =
                    values.the_teletherapy_session_was_conducted_via_video;
                providerLocation = values.provider_location;
                otherProviderLocation = values.other_provider_location;

                if (
                    values.delivery_option ===
                    AppointmentLocationTeletherapy.HOME
                ) {
                    posCode =
                        SessionPlaceOfServiceCode.TELEHEALTH_PROVIDED_IN_PATIENTS_HOME;
                } else if (
                    values.delivery_option ===
                        AppointmentLocationTeletherapy.OFFICE ||
                    values.delivery_option ===
                        AppointmentLocationTeletherapy.OTHER
                ) {
                    posCode =
                        SessionPlaceOfServiceCode.TELEHEALTH_PROVIDED_OTHER_THAN_IN_PATIENTS_HOME;
                }

                if (
                    values.delivery_option !==
                    AppointmentLocationTeletherapy.OTHER
                ) {
                    otherDeliveryOption = undefined;
                }

                if (
                    values.provider_location !==
                    AppointmentLocationTeletherapy.OTHER
                ) {
                    otherProviderLocation = undefined;
                }
            }
        }

        if ("frequency_of_sessions" in values) {
            if (values.frequency_of_sessions !== "Other") {
                otherFrequencyOfSession = undefined;
            } else {
                otherFrequencyOfSession = values.other_frequency_of_sessions;
            }
        }

        if ("when_should_follow_up_be_done_for_this_client" in values) {
            whenShouldFollowUpBeDoneForThisClient =
                values.when_should_follow_up_be_done_for_this_client
                    ? `${handleFormatDatePickerValue(
                          values.when_should_follow_up_be_done_for_this_client
                      )}T00:00:00.000`
                    : null;
        }

        if ("last_session_date" in values) {
            lastSessionDate = values.last_session_date
                ? formatZonedTimeToUtc(values.last_session_date)
                : null;
        }

        if ("first_session_date" in values) {
            firstSessionDate = values?.first_session_date
                ? formatZonedTimeToUtc(values.first_session_date)
                : null;
        }

        if ("date_of_next_session" in values) {
            dateOfNextSession = values?.date_of_next_session
                ? formatZonedTimeToUtc(values?.date_of_next_session)
                : null;
        }

        if ("date_of_last_exam" in values) {
            dateOfLastExam = values?.date_of_last_exam
                ? formatZonedTimeToUtc(values?.date_of_last_exam)
                : null;
        }

        if ("expected_termination_date" in values) {
            expectedTerminationDate = values?.expected_termination_date
                ? formatZonedTimeToUtc(values?.expected_termination_date)
                : null;
        }

        if (
            "patients_ability_and_capability_to_respond_to_treatment" in values
        ) {
            const shouldHaveDetails =
                values.patients_ability_and_capability_to_respond_to_treatment
                    ? values.patients_ability_and_capability_to_respond_to_treatment.includes(
                          "Diminished/poor or limited-quality patient-oriented evidence"
                      )
                    : false;

            detailsForDiminishedPoorLimitedQualityPatientOrientedEvidence =
                shouldHaveDetails
                    ? values.details_for_diminished_poor_limited_quality_patient_oriented_evidence
                    : "";
        }

        if ("family" in values) {
            const arrayOfShouldHaveDetails = values.family.map((fam) => {
                return (
                    fam.patients_ability_and_capability_to_respond_to_treatment?.includes(
                        "Diminished/poor or limited-quality patient-oriented evidence"
                    ) || false
                );
            });

            arrayOfdetailsForDiminishedPoorLimitedQualityPatientOrientedEvidences =
                values.family.map((fam, i) =>
                    arrayOfShouldHaveDetails[i]
                        ? fam.details_for_diminished_poor_limited_quality_patient_oriented_evidence
                        : ""
                );

            family = values.family.map(
                (fam: FamilyIntakeNotePayload, i: number) => ({
                    ...fam,
                    details_for_diminished_poor_limited_quality_patient_oriented_evidence:
                        arrayOfdetailsForDiminishedPoorLimitedQualityPatientOrientedEvidences[
                            i
                        ],
                    family_id: fam?.family_id || uuidv4(),
                })
            );
        }

        if (noteId && isCurrentlyEditingNote === 0) {
            editDraftNote.mutate(
                {
                    noteId,
                    data: {
                        pos_code: posCode,
                        note_content: {
                            ...values,
                            diagnosis_codes: diagnosisCodes || undefined,
                            delivery_option: deliveryOption,
                            other_delivery_option: otherDeliveryOption,
                            provider_location: providerLocation,
                            other_provider_location: otherProviderLocation,
                            consent_was_received_for_the_teletherapy_session:
                                consentWasReceivedForTheTeletherapySession,
                            the_teletherapy_session_was_conducted_via_video:
                                theTeletherapySessionWasConductedViaVideo,
                            when_should_follow_up_be_done_for_this_client:
                                whenShouldFollowUpBeDoneForThisClient,
                            first_session_date: firstSessionDate,
                            last_session_date: lastSessionDate,
                            date_of_next_session: dateOfNextSession,
                            expected_termination_date: expectedTerminationDate,
                            date_of_last_exam: dateOfLastExam,
                            other_frequency_of_sessions:
                                otherFrequencyOfSession,
                            details_for_diminished_poor_limited_quality_patient_oriented_evidence:
                                detailsForDiminishedPoorLimitedQualityPatientOrientedEvidence,
                            family,
                        },
                        date_of_service: data?.data.date_of_service as string,
                        session_start_time: sessionStartTime
                            ? formatZonedTimeToUtc(sessionStartTime)
                            : (data?.data.session_start_time as string),
                        session_end_time: sessionEndTime
                            ? formatZonedTimeToUtc(sessionEndTime)
                            : (data?.data.session_end_time as string),
                        reason_for_short_duration: data?.data
                            .reason_for_short_duration
                            ? data?.data.reason_for_short_duration
                            : undefined,
                    },
                },
                {
                    onSuccess: async (res) => {
                        await queryClient.invalidateQueries({
                            queryKey: [clientId, `session-note`, noteId],
                        });
                        setTimeLastSaved(showUserLocalTime());

                        if (submitBtnClicked === "edit") {
                            toast({
                                mode: "success",
                                message:
                                    res.message ||
                                    "Draft note updated successfully",
                            });

                            navigate(`/notes/${clientId}/${noteId}`, {
                                replace: true,
                            });
                        }

                        if (submitBtnClicked === "with-signature") {
                            signSessionNote.mutate(
                                {
                                    noteId,
                                    data: {
                                        signature: values.signature as string,
                                        signature_date_time: new Date(
                                            Date.now()
                                        ).toISOString(),
                                    },
                                },
                                {
                                    onSuccess: async (response) => {
                                        toast({
                                            mode: "success",
                                            message:
                                                response.message ||
                                                "Note signed successfully",
                                        });

                                        await queryClient.invalidateQueries({
                                            queryKey: [
                                                clientId,
                                                `session-note`,
                                                noteId,
                                            ],
                                        });

                                        await queryClient.invalidateQueries({
                                            queryKey: [
                                                practice?.is_supervisor
                                                    ? `notes`
                                                    : clientId,
                                                `session-note`,
                                                noteId,
                                            ],
                                        });

                                        navigate(
                                            practice?.is_supervisor
                                                ? getNoteListNavigateUrl(
                                                      practice?.roles || []
                                                  )
                                                : `/notes/${clientId}/${noteId}`,
                                            { replace: true }
                                        );
                                    },
                                    onError: (err) => {
                                        // Do not show autosave error
                                        if (submitBtnClicked) {
                                            toast({
                                                mode: "error",
                                                message:
                                                    err.response?.data
                                                        .message ||
                                                    "Could not sign draft note at this time",
                                            });
                                        }
                                    },
                                }
                            );
                        }
                    },
                    onError: (err) => {
                        // Do not show autosave error
                        if (submitBtnClicked) {
                            toast({
                                mode: "error",
                                message:
                                    err.response?.data.message ||
                                    "Could not edit draft note at this time",
                            });
                        }
                    },
                }
            );
        }
    };

    // Determines the edit form to display
    const handleEditNoteFormToShow = () => {
        switch (data?.data.type) {
            case NoteTypes.CANCELLATION_NOTE:
                return (
                    <EditCancellation
                        currentAction={currentAction}
                        noteDetails={data.data}
                        onSubmit={onSubmit}
                    />
                );

            case NoteTypes.SUPERVISION_NOTE:
                return (
                    <EditSupervision
                        currentAction={currentAction}
                        noteDetails={data.data}
                        onSubmit={onSubmit}
                    />
                );

            case NoteTypes.RECORD_OF_DISCLOSURE:
                return (
                    <EditRecordOfDisclosure
                        currentAction={currentAction}
                        noteDetails={data.data}
                        onSubmit={onSubmit}
                    />
                );

            case NoteTypes.FAMILY_SOAP_NOTE:
                return (
                    <EditSoapNote
                        currentAction={currentAction}
                        noteDetails={data.data}
                        onSubmit={onSubmit}
                    />
                );

            case NoteTypes.INDIVIDUAL_SOAP_NOTE:
                return (
                    <EditSoapNote
                        currentAction={currentAction}
                        noteDetails={data.data}
                        onSubmit={onSubmit}
                    />
                );

            case NoteTypes.TERMINATION_NOTE:
                return (
                    <EditTermination
                        currentAction={currentAction}
                        noteDetails={data.data}
                        onSubmit={onSubmit}
                    />
                );

            case NoteTypes.SAFETY_PLAN:
                return (
                    <EditSafetyPlan
                        currentAction={currentAction}
                        noteDetails={data.data}
                        onSubmit={onSubmit}
                    />
                );

            case NoteTypes.INDIVIDUAL_TREATMENT_REVIEW:
                return (
                    <EditTreatmentReview
                        currentAction={currentAction}
                        noteDetails={data.data}
                        onSubmit={onSubmit}
                    />
                );

            case NoteTypes.FAMILY_TREATMENT_REVIEW:
                return (
                    <EditTreatmentReview
                        currentAction={currentAction}
                        noteDetails={data.data}
                        onSubmit={onSubmit}
                    />
                );

            case NoteTypes.INDIVIDUAL_INTAKE_NOTE:
                return (
                    <EditIndividualIntake
                        currentAction={currentAction}
                        noteDetails={data.data}
                        onSubmit={onSubmit}
                        isMinor={false}
                    />
                );

            case NoteTypes.MINOR_INTAKE_NOTE:
                return (
                    <EditIndividualIntake
                        currentAction={currentAction}
                        noteDetails={data.data}
                        onSubmit={onSubmit}
                        isMinor
                    />
                );

            case NoteTypes.FAMILY_INTAKE_NOTE:
                return (
                    <EditFamilyIntake
                        currentAction={currentAction}
                        noteDetails={data.data}
                        onSubmit={onSubmit}
                    />
                );

            case NoteTypes.GENERAL_UPDATE_NOTE:
                return (
                    <EditGeneralNote
                        noteDetails={data.data}
                        currentAction={currentAction}
                        onSubmit={onSubmit}
                    />
                );
            case NoteTypes.SUPERVISOR_CHECK_IN:
                return (
                    <EditSupervisorCheckInNote
                        noteDetails={data.data}
                        currentAction={currentAction}
                        onSubmit={onSubmit}
                    />
                );
            default:
                return null;
        }
    };

    const getProviderWithRole = (roleType: string) => {
        const roleAvailable = data?.data.note_signatures?.find(
            (item) => item.role === roleType
        );

        return roleAvailable;
    };

    React.useEffect(() => {
        if (data?.data) {
            setNoteAppointmentDetails({
                note_type: data?.data.type as NoteTypes,
                client_name: `${data?.data.client.first_name} ${data?.data.client.last_name}`,
                appointment_type: data?.data.appointment_type,
                session_history_id: data?.data.session_history_id || null,
                date_of_service: data.data.date_of_service
                    ? formatDate(
                          data?.data.session_start_time,
                          false,
                          "yyyy-MM-dd'T'HH:mm:ss'Z'"
                      )
                    : "--",
                session_start_time: formatDate(
                    data?.data.session_start_time,
                    false,
                    "yyyy-MM-dd'T'HH:mm:ss'Z'"
                ),
                session_end_time: formatDate(
                    data?.data.session_end_time,
                    false,
                    "yyyy-MM-dd'T'HH:mm:ss'Z'"
                ),
                reason_for_short_duration: data?.data.reason_for_short_duration,
                provider_name: `${data.data.provider.first_name} ${data.data.provider.last_name}`,
                session_id: data?.data.session_id as string,
                client_id: data?.data.client.client_id as string,
                duration: getTimeDuration(
                    formatDate(
                        data?.data?.session_start_time || "",
                        false,
                        "HH:mm"
                    ),
                    formatDate(
                        data?.data?.session_end_time || "",
                        false,
                        "HH:mm"
                    )
                ),
                provider_signature: getProviderWithRole("author")?.signature,
                provider_signature_datetime:
                    getProviderWithRole("author")?.signature_date_time,
                supervisor_name: getProviderWithRole("supervisor")
                    ? `${getProviderWithRole("supervisor")?.first_name} ${
                          getProviderWithRole("supervisor")?.last_name
                      }`
                    : "--",
                supervisor_signature:
                    getProviderWithRole("supervisor")?.signature,
                supervisor_signature_datetime:
                    getProviderWithRole("supervisor")?.signature_date_time,
                provider_id: data?.data.provider.provider_id,
                first_session_date: null,
                no_of_sessions: null,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.data]);

    return (
        <>
            {isLoading && (
                <div className={styles.editskeleton}>
                    <Skeleton
                        containerTestId="edit-loader"
                        width="150px"
                        height={20}
                    />
                    <Skeleton
                        count={5}
                        height={60}
                        width="100%"
                        containerTestId="edit-loader"
                    />
                </div>
            )}
            {error && error?.response?.status !== 404 && (
                <ListState
                    isError
                    stateHelperText="Try reloading this page to solve this issue"
                    errorMsg={
                        error?.response?.data.message ||
                        `Cannot display edit forms at this time, please try again later`
                    }
                />
            )}
            {isSuccess && data && (
                <>
                    {editNoteStepInView === "appointment-details" && (
                        <AddNoteMetadata
                            noteAppointmentDetails={noteAppointmentDetails}
                            handleCreateNoteStepInView={
                                handleEditNoteStepInView
                            }
                            handleSetAppointmentDetailsInView={
                                handleSetAppointmentDetailsInView
                            }
                        />
                    )}
                    {editNoteStepInView === "edit-note" &&
                        noteAppointmentDetails && (
                            <div className={styles.wrapper}>
                                <div className={styles.wrapform}>
                                    {editDraftNote.isLoading ||
                                    timeLastSaved ? (
                                        <div
                                            className={cn(styles.autosave, {
                                                [styles.autosave_full]: !isOpen,
                                            })}
                                        >
                                            {(editDraftNote.isLoading && (
                                                <span className={styles.saving}>
                                                    Autosaving...
                                                </span>
                                            )) || (
                                                <span className={styles.saved}>
                                                    <InfoIcon /> Autosaved at{" "}
                                                    {timeLastSaved}
                                                </span>
                                            )}
                                        </div>
                                    ) : null}
                                    <div className={styles.appointment}>
                                        {noteAppointmentDetails && (
                                            <AppointmentDetails
                                                details={noteAppointmentDetails}
                                                noteType={
                                                    noteAppointmentDetails.note_type
                                                }
                                                showEditButton
                                                editButtonClicked={() =>
                                                    handleEditNoteStepInView(
                                                        "appointment-details"
                                                    )
                                                }
                                            />
                                        )}
                                    </div>

                                    {handleEditNoteFormToShow()}
                                    <div
                                        className={cn(
                                            styles.footer,
                                            styles.footer_two,
                                            {
                                                [styles.footer_full]: !isOpen,
                                            }
                                        )}
                                    >
                                        <Button
                                            className={styles.cancelbtn}
                                            type="submit"
                                            form="edit-note-form"
                                            variant="secondary"
                                            onClick={() =>
                                                handleSetButtonClicked("edit")
                                            }
                                            disabled={
                                                editDraftNote.isLoading ||
                                                signSessionNote.isLoading
                                            }
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            disabled={
                                                editDraftNote.isLoading ||
                                                signSessionNote.isLoading ||
                                                isTheDateOfServiceInTheFuture()
                                            }
                                            form="edit-note-form"
                                            variant="primary"
                                            type="submit"
                                            onClick={() =>
                                                handleSetButtonClicked(
                                                    "with-signature"
                                                )
                                            }
                                        >
                                            Submit for review
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                </>
            )}
        </>
    );
}
