import { Input } from "@jhool-io/fe-components";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useQueryClient } from "@tanstack/react-query";
import { useFetchSessionNote } from "../../../../hooks/queries/note";
import { useEditDraftNote } from "../../../../hooks/mutations/note";
import { IEditDraftNote } from "../../../../utils/types/notes";
import {
    formatDate,
    formatZonedTimeToUtc,
    getDurationInMinutes,
    handleFormatDatePickerValue,
} from "../../../../utils/helpers";
import useToast from "../../../../hooks/useToast";

interface EditNoteSessionTimeFormProps {
    onFormSubmit(): void;
}

export default function EditNoteSessionTimeForm({
    onFormSubmit,
}: EditNoteSessionTimeFormProps) {
    const params = useParams();
    const noteId = params.noteId as string;
    const clientId = params.clientId as string;

    const { data } = useFetchSessionNote(
        clientId,
        noteId,
        Boolean(clientId) && Boolean(noteId)
    );

    const editNote = useEditDraftNote();

    const { toast } = useToast();

    const queryClient = useQueryClient();

    const {
        handleSubmit,
        register,
        watch,
        formState: { errors },
    } = useForm<IEditDraftNote>({
        resolver: yupResolver(
            yup.object({
                session_start_time: yup
                    .string()
                    .required("Session start time is required"),
                session_end_time: yup
                    .string()
                    .required("Session end time is required")
                    .test({
                        name: "is-greater",
                        message:
                            "Session end time should be later than the start time",
                        test: (
                            value: string | undefined,
                            context: {
                                parent: {
                                    session_start_time: string;
                                };
                            }
                        ) => {
                            const { session_start_time: sessionStartTime } =
                                context.parent;
                            if (!sessionStartTime || !value) {
                                return true;
                            }
                            const start = new Date(
                                `1970-01-01T${sessionStartTime}:00`
                            );
                            const end = new Date(`1970-01-01T${value}:00`);
                            return end > start;
                        },
                    }),
            })
        ),
        mode: "onChange",
        defaultValues:
            data?.data?.session_start_time && data?.data?.session_end_time
                ? {
                      session_start_time: formatDate(
                          data?.data.session_start_time,
                          false,
                          "HH:mm"
                      ),
                      session_end_time: formatDate(
                          data?.data.session_end_time,
                          false,
                          "HH:mm"
                      ),
                      reason_for_short_duration:
                          data.data.reason_for_short_duration,
                  }
                : undefined,
    });

    const startTime = watch("session_start_time");
    const endTime = watch("session_end_time");

    const isSessionShort = () => {
        if (startTime && endTime) {
            const durationInMinutes = getDurationInMinutes(startTime, endTime);

            return durationInMinutes < 55;
        }
        return false;
    };

    const onSubmit = (payload: IEditDraftNote) => {
        const dataToSend = {
            noteId: data?.data.note_id || "",
            data: {
                session_start_time: formatZonedTimeToUtc(
                    `${handleFormatDatePickerValue(
                        data?.data.session_start_time as string
                    )}T${payload.session_start_time?.split(":")[0]}:${
                        payload.session_start_time?.split(":")[1]
                    }:00.000Z`
                ),
                session_end_time: formatZonedTimeToUtc(
                    `${handleFormatDatePickerValue(
                        data?.data.session_start_time as string
                    )}T${payload.session_end_time?.split(":")[0]}:${
                        payload.session_end_time?.split(":")[1]
                    }:00.000Z`
                ),
                reason_for_short_duration:
                    payload.reason_for_short_duration || undefined,
            },
        };

        editNote.mutate(dataToSend, {
            onSuccess: async (res) => {
                await queryClient.invalidateQueries({
                    queryKey: [clientId, `session-note`, noteId],
                });

                toast({
                    mode: "success",
                    message:
                        res.message || "Note session time edited successfully",
                });

                onFormSubmit();
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not edit draft note at this time",
                });
            },
        });
    };

    return (
        <form id="edit-session-time" onSubmit={handleSubmit(onSubmit)}>
            <div className="fg">
                <Input
                    {...register("session_start_time")}
                    label="Start time"
                    placeholder="Start time"
                    hasError={!!errors.session_start_time}
                    errorText={errors.session_start_time?.message}
                    type="time"
                />
            </div>
            <div className="fg">
                <Input
                    {...register("session_end_time")}
                    label="End time"
                    placeholder="End time"
                    hasError={!!errors.session_end_time}
                    errorText={errors.session_end_time?.message}
                    type="time"
                />
            </div>
            {isSessionShort() && (
                <div className="fg">
                    <Input
                        {...register("reason_for_short_duration")}
                        label="Reason for short note duration"
                        placeholder="Reason for short note duration"
                        hasError={!!errors.reason_for_short_duration}
                        errorText={errors.reason_for_short_duration?.message}
                    />
                </div>
            )}
        </form>
    );
}
