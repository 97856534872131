import * as React from "react";
import cn from "classnames";
import { Link, useLocation } from "react-router-dom";
import styles from "./AppointmentDetails.module.scss";
import {
    INewNoteAppoinmentDetails,
    ModifierTypes,
    NoteLabels,
    NoteTypes,
} from "../../../utils/types/notes";
import Button from "../../Button/Button";
import EditIcon from "../../Icons/Edit";
import {
    removeEnumUnderscore,
    hideNoteField,
    getTimeDuration,
    formatDate,
} from "../../../utils/helpers";
import Accordion from "../../Accordion/Accordion";
import usePractice from "../../../hooks/usePractice";

/** Appointment details component is also used in other places, so we will be picking more types based on that  */
type AppointmentDetailsToBeShown = Partial<INewNoteAppoinmentDetails>;

interface AppointmentDetailsProps {
    /** An array of the detail title and its corresponding value */
    details: AppointmentDetailsToBeShown;
    /** Optional classname for extra styling */
    className?: string;
    /** Note to be created or viewed type */
    noteType: NoteTypes;
    /** Determine if edit button is displayed */
    showEditButton: boolean;
    /** Function to call if edit button is clicked */
    editButtonClicked?(): void;
    /** Determine if note type should show */
    showNoteType?: boolean;
    /** Optional classname for extra styling */
    wrapperClass?: string;
}

export default function AppointmentDetails({
    details,
    className,
    noteType,
    showEditButton,
    editButtonClicked,
    showNoteType = true,
    wrapperClass,
}: AppointmentDetailsProps) {
    const { practice } = usePractice();

    const modifiers = Object.values(ModifierTypes);
    const matchingModifiers = modifiers.filter((value) =>
        details.modifier_codes?.includes(value.split(" ")[0])
    );

    const location = useLocation();

    /* Determines the value of the content parameter of the
       hideField() function */
    const getHideFieldContext = location.pathname.includes("notes")
        ? "view-note"
        : "create-note";

    return (
        <>
            <div className={styles.web}>
                <h2 className={cn(styles.title, wrapperClass)}>
                    Appointment Details
                    {showEditButton && (
                        <Button
                            onClick={editButtonClicked}
                            type="button"
                            mode="transparent"
                            size="auto"
                            ariaLabel="edit-btn"
                        >
                            <EditIcon />
                        </Button>
                    )}
                </h2>
                <div
                    className={cn(styles.details, className, {
                        [styles.details_cancellation]:
                            noteType === NoteTypes.CANCELLATION_NOTE,
                        [styles.details_supervision]:
                            noteType === NoteTypes.SUPERVISION_NOTE,
                    })}
                >
                    <div
                        className={styles.detail}
                        hidden={hideNoteField(
                            noteType,
                            "client_name",
                            getHideFieldContext
                        )}
                    >
                        <span className="section-info fs-exclude">
                            {practice?.is_supervisor &&
                            noteType === NoteTypes.SUPERVISION_NOTE
                                ? "Supervisee’s client"
                                : "Client name"}
                        </span>
                        <Link
                            to={`/clients/${details.client_id}`}
                            className={cn("section-value", styles.clientname)}
                        >
                            {details.client_name}
                        </Link>
                    </div>
                    <div
                        className={styles.detail}
                        hidden={hideNoteField(
                            noteType,
                            "date_of_service",
                            getHideFieldContext
                        )}
                    >
                        <span className="section-info fs-exclude">
                            Date of service
                        </span>
                        <span className="section-value">
                            {formatDate(details.date_of_service as string)}
                        </span>
                    </div>
                    <div
                        className={styles.detail}
                        hidden={hideNoteField(
                            noteType,
                            "time",
                            getHideFieldContext
                        )}
                    >
                        <span className="section-info fs-exclude">Time</span>
                        <span className="section-value">
                            {`${formatDate(
                                details.session_start_time as string,
                                true,
                                "h:mmaaa"
                            )} - ${formatDate(
                                details.session_end_time as string,
                                true,
                                "h:mmaaa"
                            )}`}
                        </span>
                    </div>
                    <div
                        className={styles.detail}
                        hidden={hideNoteField(
                            noteType,
                            "duration",
                            getHideFieldContext
                        )}
                    >
                        <span className="section-info fs-exclude">
                            Duration
                        </span>
                        <span className="section-value">
                            {getTimeDuration(
                                formatDate(
                                    details.session_start_time || "",
                                    false,
                                    "HH:mm"
                                ),
                                formatDate(
                                    details.session_end_time || "",
                                    false,
                                    "HH:mm"
                                )
                            )}
                        </span>
                    </div>
                    <div
                        className={styles.detail}
                        hidden={hideNoteField(
                            noteType,
                            "appointment_type",
                            getHideFieldContext
                        )}
                    >
                        <span className="section-info fs-exclude">
                            Appointment type
                        </span>
                        <span className="section-value">
                            {removeEnumUnderscore(
                                details.appointment_type || ""
                            )}
                        </span>
                    </div>
                    <div
                        className={styles.detail}
                        hidden={hideNoteField(
                            noteType,
                            "provider_name",
                            getHideFieldContext
                        )}
                    >
                        <span className="section-info fs-exclude">
                            Provider name
                        </span>
                        <span className="section-value">
                            {details.provider_name}
                        </span>
                    </div>
                    {details.provider_signature ? (
                        <div
                            className={styles.detail}
                            hidden={hideNoteField(
                                noteType,
                                "provider_signature",
                                getHideFieldContext
                            )}
                        >
                            <span className="section-info fs-exclude">
                                Provider signature
                            </span>
                            <span className="section-value">
                                <span
                                    style={{
                                        display: "inline-block",
                                        marginRight: "3px",
                                    }}
                                >
                                    {details.provider_signature}
                                </span>
                                (
                                {formatDate(
                                    details.provider_signature_datetime as string
                                )}
                                ,
                                <span
                                    className={styles.time}
                                    style={{
                                        display: "inline-block",
                                        marginLeft: "3px",
                                    }}
                                >
                                    {formatDate(
                                        details.provider_signature_datetime as string,
                                        false,
                                        "h:mm a"
                                    )}
                                </span>
                                )
                            </span>
                        </div>
                    ) : null}
                    {details.supervisor_name !== "--" && (
                        <div
                            className={styles.detail}
                            hidden={hideNoteField(
                                noteType,
                                "supervisor_name",
                                getHideFieldContext
                            )}
                        >
                            <span className="section-info fs-exclude">
                                Supervisor name
                            </span>
                            <span className="section-value">
                                {details.supervisor_name || "--"}
                            </span>
                        </div>
                    )}

                    {details.supervisor_signature && (
                        <div
                            className={styles.detail}
                            hidden={hideNoteField(
                                noteType,
                                "supervisor_signature",
                                getHideFieldContext
                            )}
                        >
                            <span className="section-info fs-exclude">
                                Supervisor signature
                            </span>
                            <span className="section-value">
                                <span
                                    style={{
                                        display: "inline-block",
                                        marginRight: "3px",
                                    }}
                                >
                                    {details.supervisor_signature}
                                </span>
                                (
                                {formatDate(
                                    details.supervisor_signature_datetime as string
                                )}
                                ,
                                <span
                                    className={styles.time}
                                    style={{
                                        display: "inline-block",
                                        marginLeft: "3px",
                                    }}
                                >
                                    {formatDate(
                                        details.supervisor_signature_datetime as string,
                                        false,
                                        "h:mm a"
                                    )}
                                </span>
                                )
                            </span>
                        </div>
                    )}
                    {details.modifier_codes?.length !== 0 &&
                        matchingModifiers.map((code, i) => (
                            <div className={styles.detail} key={code}>
                                <span className="section-info fs-exclude">
                                    {`Modifier ${i + 1}`}
                                </span>
                                <span className="section-value">{code}</span>
                            </div>
                        ))}
                    {showNoteType && (
                        <div className={styles.detail}>
                            <span className="section-info fs-exclude">
                                Note type
                            </span>
                            <span className="section-value">
                                {NoteLabels[details.note_type as string] || ""}
                            </span>
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.mobile}>
                <Accordion
                    cardClass={styles.cardclass}
                    header={
                        <>
                            <h2 className={cn(styles.title, wrapperClass)}>
                                Appointment Details
                                {showEditButton && (
                                    <Button
                                        onClick={editButtonClicked}
                                        type="button"
                                        mode="transparent"
                                        size="auto"
                                        ariaLabel="edit-btn"
                                    >
                                        <EditIcon />
                                    </Button>
                                )}
                            </h2>
                            <div className={styles.details}>
                                <div
                                    className={styles.detail}
                                    hidden={hideNoteField(
                                        noteType,
                                        "client_name",
                                        getHideFieldContext
                                    )}
                                >
                                    <span className="section-info fs-exclude">
                                        {practice?.is_supervisor &&
                                        noteType === NoteTypes.SUPERVISION_NOTE
                                            ? "Supervisee’s client"
                                            : "Client name"}
                                    </span>
                                    <Link
                                        to={`/clients/${details.client_id}`}
                                        className={cn(
                                            "section-value",
                                            styles.clientname
                                        )}
                                    >
                                        {details.client_name}
                                    </Link>
                                </div>
                                <div
                                    className={styles.detail}
                                    hidden={hideNoteField(
                                        noteType,
                                        "date_of_service",
                                        getHideFieldContext
                                    )}
                                >
                                    <span className="section-info fs-exclude">
                                        Date of service
                                    </span>
                                    <span className="section-value">
                                        {formatDate(
                                            details.date_of_service as string
                                        )}
                                    </span>
                                </div>
                            </div>
                        </>
                    }
                    content={
                        <div className={styles.details}>
                            <div
                                className={styles.detail}
                                hidden={hideNoteField(
                                    noteType,
                                    "time",
                                    getHideFieldContext
                                )}
                            >
                                <span className="section-info fs-exclude">
                                    Time
                                </span>
                                <span className="section-value">
                                    {`${formatDate(
                                        details.session_start_time as string,
                                        false,
                                        "h:mmaaa"
                                    )} - ${formatDate(
                                        details.session_end_time as string,
                                        false,
                                        "h:mmaaa"
                                    )}`}
                                </span>
                            </div>
                            <div
                                className={styles.detail}
                                hidden={hideNoteField(
                                    noteType,
                                    "duration",
                                    getHideFieldContext
                                )}
                            >
                                <span className="section-info fs-exclude">
                                    Duration
                                </span>
                                <span className="section-value">
                                    {getTimeDuration(
                                        formatDate(
                                            details.session_start_time || "",
                                            false,
                                            "HH:mm"
                                        ),
                                        formatDate(
                                            details.session_end_time || "",
                                            false,
                                            "HH:mm"
                                        )
                                    )}
                                </span>
                            </div>
                            <div
                                className={styles.detail}
                                hidden={hideNoteField(
                                    noteType,
                                    "appointment_type",
                                    getHideFieldContext
                                )}
                            >
                                <span className="section-info fs-exclude">
                                    Appointment type
                                </span>
                                <span className="section-value">
                                    {removeEnumUnderscore(
                                        details.appointment_type || ""
                                    )}
                                </span>
                            </div>
                            <div
                                className={styles.detail}
                                hidden={hideNoteField(
                                    noteType,
                                    "provider_name",
                                    getHideFieldContext
                                )}
                            >
                                <span className="section-info fs-exclude">
                                    Provider name
                                </span>
                                <span className="section-value">
                                    {details.provider_name}
                                </span>
                            </div>
                            {details.provider_signature ? (
                                <div
                                    className={styles.detail}
                                    hidden={hideNoteField(
                                        noteType,
                                        "provider_signature",
                                        getHideFieldContext
                                    )}
                                >
                                    <span className="section-info fs-exclude">
                                        Provider signature
                                    </span>
                                    <span className="section-value">
                                        <span
                                            style={{
                                                display: "inline-block",
                                                marginRight: "3px",
                                            }}
                                        >
                                            {details.provider_signature}
                                        </span>
                                        (
                                        {formatDate(
                                            details.provider_signature_datetime as string
                                        )}
                                        ,
                                        <span
                                            className={styles.time}
                                            style={{
                                                display: "inline-block",
                                                marginLeft: "3px",
                                            }}
                                        >
                                            {formatDate(
                                                details.provider_signature_datetime as string,
                                                false,
                                                "h:mm a"
                                            )}
                                        </span>
                                        )
                                    </span>
                                </div>
                            ) : null}
                            {details.supervisor_name !== "--" && (
                                <div
                                    className={styles.detail}
                                    hidden={hideNoteField(
                                        noteType,
                                        "supervisor_name",
                                        getHideFieldContext
                                    )}
                                >
                                    <span className="section-info fs-exclude">
                                        Supervisor name
                                    </span>
                                    <span className="section-value">
                                        {details.supervisor_name || "--"}
                                    </span>
                                </div>
                            )}

                            {details.supervisor_signature && (
                                <div
                                    className={styles.detail}
                                    hidden={hideNoteField(
                                        noteType,
                                        "supervisor_signature",
                                        getHideFieldContext
                                    )}
                                >
                                    <span className="section-info fs-exclude">
                                        Supervisor signature
                                    </span>
                                    <span className="section-value">
                                        <span
                                            style={{
                                                display: "inline-block",
                                                marginRight: "3px",
                                            }}
                                        >
                                            {details.supervisor_signature}
                                        </span>
                                        (
                                        {formatDate(
                                            details.supervisor_signature_datetime as string
                                        )}
                                        ,
                                        <span
                                            className={styles.time}
                                            style={{
                                                display: "inline-block",
                                                marginLeft: "3px",
                                            }}
                                        >
                                            {formatDate(
                                                details.supervisor_signature_datetime as string,
                                                false,
                                                "h:mm a"
                                            )}
                                        </span>
                                        )
                                    </span>
                                </div>
                            )}
                            {details.modifier_codes?.length !== 0 &&
                                matchingModifiers.map((code, i) => (
                                    <div className={styles.detail} key={code}>
                                        <span className="section-info fs-exclude">
                                            {`Modifier ${i + 1}`}
                                        </span>
                                        <span className="section-value">
                                            {code}
                                        </span>
                                    </div>
                                ))}
                            {showNoteType && (
                                <div className={styles.detail}>
                                    <span className="section-info fs-exclude">
                                        Note type
                                    </span>
                                    <span className="section-value">
                                        {NoteLabels[
                                            details.note_type as string
                                        ] || "--"}
                                    </span>
                                </div>
                            )}
                        </div>
                    }
                />
            </div>
        </>
    );
}
