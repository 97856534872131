import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import * as React from "react";
import { useSearchParams } from "react-router-dom";
import axios from "../../utils/axios";
import {
    IApiResponse,
    IPaginatedApiResponse,
} from "../../utils/types/api-response";
import {
    ClientDocumentTypes,
    IClientDocument,
    IClientInsuranceAttachement,
    IClientBasicInfo,
    IClientPersonalInfo,
    IClientInsuranceDetails,
    DocumentUploadType,
} from "../../utils/types/client";
import { IThirdPartyDocument } from "../../utils/types/filepicker";
import { useFetchUserDetails } from "../queries/user";
import useToast from "../useToast";
import { IRemitDocument } from "../../modules/remits/types/remits.types";

interface FileUploadProps {
    uploadType: DocumentUploadType;
    files: File[];
    client?: IClientBasicInfo & IClientPersonalInfo;
    clientId: string;
    type?: ClientDocumentTypes;
    insuranceId: string;
    successCallback: () => void;
    documents?: IThirdPartyDocument[];
    remitId?: string;
    remitName?: string;
}

interface IApiResponseOthers {
    message: string;
    documents: IClientDocument[];
}

export default function useFileUpload({
    uploadType,
    files,
    client,
    clientId,
    type,
    insuranceId,
    successCallback,
    documents,
    remitId,
    remitName,
}: FileUploadProps) {
    const [uploadProgress, setUploadProgress] = React.useState(0);

    const [searchParams] = useSearchParams();

    const pageFilter = Number(searchParams.get("page")) || 1;
    const limitFilter = Number(searchParams.get("limit")) || 20;

    const { toast } = useToast();

    const formData = new FormData();

    // Query client
    const queryClient = useQueryClient();

    // Get logged in user
    const loggedInUser = useFetchUserDetails();

    // Payload items
    let name: string;
    let title: string;
    let documentType: ClientDocumentTypes;

    if (files && files.length > 0) {
        for (let i = 0; i < [...files].length; i += 1) {
            formData.append("documents", [...files][i]);
        }
    }

    if (uploadType === "insurance-file") {
        name = `${client?.first_name} ${client?.last_name} - insurance-document`;
        formData.append("name", name);
    } else if (uploadType === "remit-file") {
        name = `${remitName} - remit document`;
        formData.append("name", name);
    } else {
        title = `${client?.first_name} ${client?.last_name} - ${type}`;
        formData.append("title", title);
    }

    if (
        (uploadType === "documents" || uploadType === "insurance-card") &&
        type
    ) {
        documentType = type;
        formData.append("type", documentType);
    }

    const requestUrl =
        uploadType === "documents" || uploadType === "insurance-card"
            ? `/client/${clientId}/documents`
            : `/client/${clientId}/insurance/${insuranceId}/attachment`;

    const thirdParyRequestUrl =
        uploadType === "documents" || uploadType === "insurance-card"
            ? `/client/${clientId}/document_links`
            : `/client/${clientId}/insurance/${insuranceId}/attachment_links`;

    const remitRequestUrl = `/remit/eob/${remitId}`;

    // Updater function for both normal uploads and third party upload
    const updateCache = (
        res: {
            data: IApiResponse<IClientInsuranceAttachement[]>;
        } & {
            data: IApiResponseOthers;
        }
    ) => {
        if (uploadType === "documents" || uploadType === "insurance-card") {
            queryClient.setQueryData<IPaginatedApiResponse<IClientDocument[]>>(
                [
                    clientId,
                    `documents`,
                    {
                        client_id: clientId,
                        page: pageFilter,
                        limit: limitFilter,
                    },
                ],
                (prev) => {
                    const addedDocuments = res.data.documents.map((doc) => ({
                        ...doc,
                        uploaded_date: new Date(Date.now()).toISOString(),
                        uploaded_by: {
                            first_name: loggedInUser.data?.first_name as string,
                            last_name: loggedInUser.data?.last_name as string,
                            user_id: loggedInUser.data?.user_id as string,
                        },
                    }));
                    if (!prev) {
                        return {
                            count: 1,
                            total_count: 1,
                            current_page: 1,
                            total_pages: 1,
                            message: "",
                            data: [...addedDocuments],
                        };
                    }
                    return {
                        ...prev,
                        count: prev.count + 1,
                        total_count: prev.total_count + 1,
                        message: "",
                        data: [...addedDocuments, ...prev.data],
                    };
                }
            );
            queryClient.invalidateQueries({
                queryKey: [clientId, `documents`],
            });
        } else {
            const newInsuranceId = localStorage.getItem(
                "newInsuranceId"
            ) as string;
            queryClient.setQueryData<
                IPaginatedApiResponse<IClientInsuranceDetails[]>
            >([clientId, `insurance`, 1], (prev) => {
                const prevNotUndefined = prev as IPaginatedApiResponse<
                    IClientInsuranceDetails[]
                >;
                return {
                    ...prevNotUndefined,
                    data: prevNotUndefined?.data.map((insurance) => {
                        if (insurance.insurance_id === newInsuranceId) {
                            return {
                                ...insurance,
                                attached_files: [...res.data.data],
                            };
                        }
                        return insurance;
                    }),
                };
            });

            queryClient.invalidateQueries({
                queryKey: [clientId, `insurance`, 1],
            });
            localStorage.removeItem("newInsuranceId");
        }
    };

    const mutation = useMutation<
        {
            data: IApiResponse<IClientInsuranceAttachement[]>;
        } & { data: IApiResponseOthers },
        AxiosError<
            IApiResponse<IClientInsuranceAttachement[]> & IApiResponseOthers
        >
    >({
        mutationKey: ["add-file", clientId],
        mutationFn: () =>
            axios.post(requestUrl, formData, {
                onUploadProgress: (progressEvent) => {
                    if (progressEvent.total) {
                        setUploadProgress(
                            Math.round(
                                (progressEvent.loaded * 100) /
                                    progressEvent.total
                            )
                        );
                    }
                },
            }),
        onSuccess: (res) => {
            toast({
                mode: "success",
                message: res.data.message || "Document uploaded successfully",
            });
            setUploadProgress(0);
            updateCache(res);
            successCallback();
        },
        onError: (err) => {
            toast({
                mode: "error",
                message:
                    err.response?.data.message ||
                    "Document could not be uploaded at this time",
            });
            setUploadProgress(0);
        },
    });

    const remitFileMutation = useMutation<
        {
            data: IApiResponse<IRemitDocument[]>;
        },
        AxiosError<IApiResponse<IRemitDocument[]>>
    >({
        mutationKey: ["add-remit-file", remitId],
        mutationFn: () =>
            axios.post(remitRequestUrl, formData, {
                onUploadProgress: (progressEvent) => {
                    if (progressEvent.total) {
                        setUploadProgress(
                            Math.round(
                                (progressEvent.loaded * 100) /
                                    progressEvent.total
                            )
                        );
                    }
                },
            }),
        onSuccess: (res) => {
            toast({
                mode: "success",
                message: res.data.message || "Remit file uploaded successfully",
            });
            setUploadProgress(0);
            queryClient.invalidateQueries({
                queryKey: ["remit-docs", remitId],
            });
            successCallback();
        },
        onError: (err) => {
            toast({
                mode: "error",
                message:
                    err.response?.data.message ||
                    "Remit file could not be uploaded at this time",
            });
            setUploadProgress(0);
        },
    });

    const thirdPartyMutation = useMutation<
        {
            data: IApiResponse<IClientInsuranceAttachement[]>;
        } & { data: IApiResponseOthers },
        AxiosError<
            IApiResponse<IClientInsuranceAttachement[]> & IApiResponseOthers,
            null
        >
    >({
        mutationKey: ["add-file-thirdparty", clientId],
        mutationFn: () =>
            axios.post(
                thirdParyRequestUrl,
                {
                    title,
                    type,
                    documents,
                },
                {
                    onUploadProgress: (progressEvent) => {
                        if (progressEvent.total) {
                            setUploadProgress(
                                Math.round(
                                    (progressEvent.loaded * 100) /
                                        progressEvent.total
                                )
                            );
                        }
                    },
                }
            ),
        onSuccess: (res) => {
            toast({
                mode: "success",
                message: res.data.message || "Document uploaded successfully",
            });
            setUploadProgress(0);
            updateCache(res);
            successCallback();
        },
        onError: (err) => {
            toast({
                mode: "error",
                message:
                    err.response?.data.message ||
                    "Document could not be uploaded at this time",
            });
            setUploadProgress(0);
        },
    });

    return { mutation, thirdPartyMutation, remitFileMutation, uploadProgress };
}
