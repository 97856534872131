import * as React from "react";
import { UserPractice } from "../../modules/user-management/types/user-management.types";
import { UserPracticeContext } from "./UserPracticeContext";
import axiosInstance from "../../utils/axios";
import { areArraysEqual } from "../../utils/helpers";

interface UserPracticeProviderProps {
    children: React.ReactNode;
}

export default function UserPracticeProvider({
    children,
}: UserPracticeProviderProps) {
    const currentPractice: UserPractice | null = localStorage.getItem(
        "practice"
    )
        ? JSON.parse(localStorage.getItem("practice") || "{}")
        : null;

    const [userCurrentPractice, setUserCurrentPractice] =
        React.useState<UserPractice | null>(() => currentPractice);

    const memoizedValues = React.useMemo(() => {
        const handleChangeUserPractice = async (
            practice: UserPractice | null,
            updateLocation = true
        ) => {
            if (practice) {
                if (
                    userCurrentPractice?.practice_id === practice.practice_id &&
                    areArraysEqual(userCurrentPractice?.roles, practice.roles)
                )
                    return;

                setUserCurrentPractice(() => practice);

                localStorage.setItem("practice", JSON.stringify(practice));

                axiosInstance.defaults.headers.common.practice_id =
                    practice.practice_id;
            } else {
                setUserCurrentPractice(() => practice);
            }

            if (updateLocation) {
                window.location.href = "/";
            }
        };

        return {
            userCurrentPractice,
            handleChangeUserPractice,
        };
    }, [userCurrentPractice]);

    return (
        <UserPracticeContext.Provider value={memoizedValues}>
            {children}
        </UserPracticeContext.Provider>
    );
}
