/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import {
    Button,
    Checkbox,
    DatePicker,
    Dropdown,
    DropdownContent,
    DropdownTrigger,
    Pagination,
    Popover,
    PopoverContent,
    PopoverTrigger,
    SearchInput,
    DropdownItem,
    Table,
    Dialog,
    DialogContent,
    Tag,
} from "@jhool-io/fe-components";
import { format } from "date-fns";
import { ColumnDef, Row } from "@tanstack/react-table";
import { useQueryClient } from "@tanstack/react-query";

import { useFetchTasks } from "../../hooks/tasks.queries";
import {
    ITask,
    TaskCategory,
    TaskListSortAttribute,
    TaskStatus,
} from "../../types/tasks.types";
import { momentNotTz } from "../../../../utils/moment";
import {
    cn,
    formatDate,
    getTotalAmountOfTableRowsSelected,
    removeEnumUnderscore,
    truncateString,
} from "../../../../utils/helpers";
import ChevronDownIcon from "../../../../components/Icons/ChevronDown";
import { APP_COLORS, LISTS_DEFAULT_LIMIT } from "../../../../utils/constants";
import { useFetchUserDetails } from "../../../../hooks/queries/user";
import { useDebounce } from "../../../../hooks/helpers";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import { UserPermisions } from "../../../../utils/types/user";

import MoreIcon from "../../../../components/Icons/More";
import { getStatusTag } from "../../helpers/tasks.helper";
import { useDeleteTasks, useUpdateTask } from "../../hooks/tasks.mutations";
import useToast from "../../../../hooks/useToast";
import TableFiltersDisplay from "../../../../shared-ui/TableFiltersDisplay/TableFiltersDisplay";
import { useFetchUsersList } from "../../../../hooks/queries/user-management";
import usePractice from "../../../../hooks/usePractice";

type ComboOption = {
    value: string;
    label: string;
};

type ModalContent = "add-task" | "reassign-task";

interface MyTasksListProps {
    setModalInView: (modal: ModalContent | null) => void;
}

export default function MyTasksList({ setModalInView }: MyTasksListProps) {
    const navigate = useNavigate();
    const location = useLocation();

    const [searchParams, setSearchParams] = useSearchParams();

    const [currentLimit, setCurrentLimit] = React.useState(LISTS_DEFAULT_LIMIT);
    const [selectedInternalUser, setSelectedInternalUser] =
        React.useState<ComboOption | null>(null);
    const [sortConfig, setSortConfig] = React.useState<{
        key: string;
        direction: string;
    }>({
        key: "",
        direction: "",
    });
    const [showCreatorListPopover, setShowCreatorListPopover] =
        React.useState(false);
    const [internalUserSearchString, setInternalUserSearchString] =
        React.useState("");
    const [taskRowSelection, setTaskRowSelection] = React.useState({});
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [selectedTaskId, setSelectedTaskId] = React.useState<string | null>();
    const [getSelectedData, setGetSelectedData] =
        React.useState<Row<ITask>[]>();

    // params
    const fromDateFilter = searchParams.get("from_date")
        ? momentNotTz(searchParams.get("from_date")).toDate()
        : null;
    const toDateFilter = searchParams.get("to_date")
        ? momentNotTz(searchParams.get("to_date")).toDate()
        : null;
    const pageFilter = Number(searchParams.get("page") || 1);
    const clientFilter = searchParams.get("search_string") || "";
    const taskStatusFilter = searchParams.getAll("status[]");
    const taskCategoryFilter = searchParams.getAll("category[]");

    const sortableColumns = [
        "due_date",
        "client_name",
        "created_by",
        "task_subject",
    ];

    const handleConfigAttribute = () => {
        if (sortableColumns.includes(sortConfig.key)) {
            return sortConfig.key;
        }

        return undefined;
    };

    const handleTaskStatusFilter = (status: string) => {
        if (taskStatusFilter.includes(status)) {
            searchParams.delete("status[]", status);
        } else {
            searchParams.append("status[]", status);
        }
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleTaskCategoryFilter = (category: string) => {
        if (taskCategoryFilter.includes(category)) {
            searchParams.delete("category[]", category);
        } else {
            searchParams.append("category[]", category);
        }
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const loggedInUser = useFetchUserDetails();

    const { practice } = usePractice();

    const update = useUpdateTask();
    const queryClient = useQueryClient();
    const deleteTasks = useDeleteTasks();
    const { toast } = useToast();

    const debouncedClientFilter = useDebounce(clientFilter);

    const { data, isLoading, isSuccess, error } = useFetchTasks({
        page: pageFilter,
        limit: currentLimit,
        status: taskStatusFilter,
        creator_user_id: selectedInternalUser?.value,
        assignee_user_id: loggedInUser.data?.user_id,
        category: taskCategoryFilter,
        from_date: fromDateFilter || undefined,
        to_date: toDateFilter || undefined,
        search_string: debouncedClientFilter,
        sort_attribute:
            (searchParams.get("sort_attr") as TaskListSortAttribute) ||
            handleConfigAttribute(),
        sort_order: searchParams.get("sort_order") || sortConfig.direction,
    });

    const debouncedInternalUserSearchValue = useDebounce(
        internalUserSearchString,
        500
    );

    const internalUsersList = useFetchUsersList({
        page: 1,
        limit: 20,
        search_string: debouncedInternalUserSearchValue,
    });

    const handleFromDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("from_date");
        else searchParams.set("from_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleToDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("to_date");
        else searchParams.set("to_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    // onChange handler for search input
    const handleSetClientSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") searchParams.delete("search_string");
        else searchParams.set("search_string", e.target.value);

        setSearchParams(searchParams);
    };

    const hasTimePassed = (targetDateTimeString: string) => {
        const targetDateTime = new Date(targetDateTimeString);
        const currentDateTime = new Date();

        return currentDateTime >= targetDateTime;
    };

    const canDeleteTask = (id: string) => {
        if (practice?.permissions?.includes(UserPermisions.TODO_ALL_DELETE)) {
            return true;
        }
        if (loggedInUser.data?.user_id === id) {
            return true;
        }

        return false;
    };

    const completeTask = (id: string) => {
        const selectedTaskDetails = data?.data.find((task) => {
            return task.todo_id === id;
        });

        const payload = {
            todos_to_update: [
                {
                    todo_id: selectedTaskDetails?.todo_id as string,
                    status: TaskStatus.COMPLETED,
                    title: selectedTaskDetails?.title as string,
                    description: selectedTaskDetails?.description as string,
                },
            ],
        };

        update.mutate(payload, {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: ["todos"],
                });
                toast({
                    mode: "success",
                    message: "Task completed successfully!",
                });
                setModalInView(null);
            },

            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not mark task as complete",
                });
            },
        });
    };

    const handlePageChange = (page: number) => {
        searchParams.set("page", String(page));
        setSearchParams(searchParams);
    };

    const selectedTasks = getSelectedData?.map(
        (item: Row<ITask>) => item.original.todo_id
    );

    const tasksDetails =
        data?.data &&
        selectedTasks &&
        (selectedTasks.map((taskId: string) =>
            data.data.find((task) => task.todo_id === taskId)
        ) as ITask[]);

    const handleDeleteTasks = () => {
        const getPayload = () => {
            if (tasksDetails && selectedTasks && selectedTasks?.length > 0) {
                return tasksDetails.map((task: ITask) => task.todo_id);
            }

            return [selectedTaskId as string];
        };

        deleteTasks.mutate(
            getPayload(),

            {
                onSuccess() {
                    queryClient.invalidateQueries({
                        queryKey: [`todos`],
                    });
                    toast({
                        mode: "success",
                        message: "Tasks deleted successfully",
                    });
                    navigate(
                        searchParams.get("tab")
                            ? `${location.pathname}?tab=${searchParams.get(
                                  "tab"
                              )}`
                            : location.pathname
                    );
                    setTaskRowSelection({});
                    setSelectedTaskId(null);
                    setShowDeleteModal(false);
                },
                onError(err) {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Could not delete tasks, please try again",
                    });
                },
            }
        );
    };

    const columns: ColumnDef<ITask>[] = [
        {
            id: "select",
            header: ({ table }) => (
                <Checkbox
                    handleChange={table.getToggleAllRowsSelectedHandler()}
                    indeterminate={table.getIsSomeRowsSelected()}
                    label=""
                    name="row"
                    value="row"
                    isChecked={table.getIsAllRowsSelected()}
                    disabled={data?.data.some(
                        (task) => task.status === TaskStatus.COMPLETED
                    )}
                />
            ),
            cell: ({ row }) => {
                return (
                    row.original.status !== TaskStatus.COMPLETED && (
                        <Checkbox
                            handleChange={row.getToggleSelectedHandler()}
                            indeterminate={row.getIsSomeSelected()}
                            label=""
                            name="row"
                            value="row"
                            isChecked={row.getIsSelected()}
                        />
                    )
                );
            },
        },
        {
            accessorKey: "due_date",
            header: "DUE DATE",
            cell: ({ row }) => (
                <span className="flex gap-8 items-center">
                    {formatDate(row.original.due_date, true)}

                    {hasTimePassed(row.original.due_date) &&
                        row.original.status !== TaskStatus.COMPLETED && (
                            <span>
                                <Tag
                                    title="Overdue"
                                    textColor="#981F41"
                                    bgColor="rgba(251, 199, 198, 0.50)"
                                    className="font-normal px-8 py-4 h-24 rounded-r4"
                                />
                            </span>
                        )}
                </span>
            ),
        },
        {
            accessorKey: "client_name",
            header: "CLIENT NAME",
            cell: ({ row }) =>
                row.original.client?.client_id
                    ? `${row.original.client?.first_name} ${row.original.client?.last_name}`
                    : "--",
        },
        {
            accessorKey: "created_by",
            header: "CREATED BY",
            cell: ({ row }) =>
                row.original.creator.user_id
                    ? `${row.original.creator.first_name} ${row.original.creator.last_name}`
                    : "Mantle",
        },
        {
            accessorKey: "task_subject",
            header: "TASK SUBJECT",
            cell: ({ row }) => (
                <span className="capitalize">{row.original.title}</span>
            ),
        },
        {
            accessorKey: "status",
            header: "STATUS",
            cell: ({ row }) => getStatusTag(row.original.status),
        },
        {
            accessorKey: "task_id",
            header: "",
            cell: ({ row }) => {
                return (
                    row.original.status !== TaskStatus.COMPLETED && (
                        <Dropdown>
                            <DropdownTrigger asChild>
                                <Button
                                    size="auto"
                                    variant="normal"
                                    className="w-16 h-16 rounded-[16px] shadow-morebtn relative z-[1] data-[state=open]:border-primary-800 mt-1"
                                >
                                    <MoreIcon />
                                </Button>
                            </DropdownTrigger>

                            <DropdownContent
                                width={240}
                                className="px-8 py-16 text-gray"
                                align="end"
                            >
                                <DropdownItem
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        completeTask(row.original.todo_id);
                                    }}
                                >
                                    Mark as Completed
                                </DropdownItem>

                                <DropdownItem
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        searchParams.set(
                                            "task_id",
                                            row.original.todo_id
                                        );
                                        setModalInView("reassign-task");
                                        setSearchParams(searchParams);
                                    }}
                                >
                                    Re-Assign
                                </DropdownItem>

                                {canDeleteTask(
                                    row.original.creator.user_id
                                ) && (
                                    <DropdownItem
                                        className="text-danger"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShowDeleteModal(true);
                                            setSelectedTaskId(
                                                row.original.todo_id
                                            );
                                        }}
                                    >
                                        Delete Task
                                    </DropdownItem>
                                )}
                            </DropdownContent>
                        </Dropdown>
                    )
                );
            },
        },
    ];

    return (
        <>
            <Dialog open={showDeleteModal}>
                <DialogContent
                    title="Delete task"
                    variant="center"
                    handleCloseDialog={() => setShowDeleteModal(false)}
                    onCancelClick={() => setShowDeleteModal(false)}
                    saveText="Yes, delete task"
                    cancelText="Cancel"
                    isSubmitBtnDisabled={deleteTasks.isLoading}
                    isDeleting
                    onSaveClick={() => handleDeleteTasks()}
                >
                    <div className="px-8">
                        <h3 className="text-xl font-medium mb-[8px]">
                            Are you sure you want to delete this task?
                        </h3>
                        <p className="text-base">
                            Are you sure you want to delete this task? Please
                            note that this action cannot be undone.
                        </p>
                    </div>
                </DialogContent>
            </Dialog>

            <div className="rounded-r8 bg-white py-16 px-12 mb-32">
                <div className="flex flex-col gap-y-24">
                    <SearchInput
                        placeholder="Search by client name or chart number"
                        containerClass="w-[400px] max-w-full"
                        onChange={handleSetClientSearch}
                        defaultValue={clientFilter}
                    />
                    <div className="flex items-center justify-between gap-x-12 flex-wrap gap-y-24">
                        <div className="flex items-center flex-wrap gap-12">
                            <Popover
                                open={showCreatorListPopover}
                                onOpenChange={setShowCreatorListPopover}
                            >
                                <PopoverTrigger
                                    asChild
                                    onClick={() =>
                                        setShowCreatorListPopover(true)
                                    }
                                >
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        className={cn(
                                            "px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium",
                                            {
                                                capitalize:
                                                    selectedInternalUser,
                                            }
                                        )}
                                    >
                                        {selectedInternalUser
                                            ? truncateString(
                                                  selectedInternalUser.label
                                              )
                                            : "Task creators"}
                                        <ChevronDownIcon
                                            stroke={APP_COLORS.COLOR_BLACK}
                                        />
                                    </Button>
                                </PopoverTrigger>

                                <PopoverContent
                                    className="px-4 pt-[3px] py-[3px] w-[316px] rounded-r8 border-none shadow-[0px_0px_0px_1px_#98a1b219,_0px_15px_35px_-5px_#11182626,_0px_5px_15px_0px_#00000014]"
                                    align="start"
                                >
                                    <SearchInput
                                        placeholder="Search by creator name"
                                        containerClass="h-24"
                                        onChange={(e) =>
                                            setInternalUserSearchString(
                                                e.target.value
                                            )
                                        }
                                    />

                                    <div className="flex flex-col items-start max-h-[230px] overflow-y-auto">
                                        {internalUsersList.isLoading && (
                                            <div className="flex flex-col gap-y-4 items-stretch w-full">
                                                {[1, 2, 3, 4, 5].map((item) => (
                                                    <Skeleton
                                                        key={item}
                                                        width="100%"
                                                        height="24px"
                                                        containerTestId="creator-loader"
                                                    />
                                                ))}
                                            </div>
                                        )}

                                        {internalUsersList.error && (
                                            <ListState
                                                isError
                                                context="general"
                                                stateHelperText="Please try again later"
                                                errorMsg={
                                                    internalUsersList.error
                                                        ?.response?.data
                                                        ?.message ||
                                                    "Error loading creators"
                                                }
                                            />
                                        )}

                                        {internalUsersList.data?.data &&
                                            internalUsersList.isSuccess && (
                                                <>
                                                    <Button
                                                        size="auto"
                                                        variant="normal"
                                                        className="text-xs px-[6px] py-4 font-medium capitalize w-full justify-start !rounded-none"
                                                        onClick={() => {
                                                            setSelectedInternalUser(
                                                                null
                                                            );
                                                            setInternalUserSearchString(
                                                                ""
                                                            );
                                                            setShowCreatorListPopover(
                                                                false
                                                            );
                                                        }}
                                                    >
                                                        All
                                                    </Button>
                                                    {internalUsersList?.data?.data.map(
                                                        (creator) => (
                                                            <Button
                                                                key={
                                                                    creator.user_id
                                                                }
                                                                size="auto"
                                                                variant="normal"
                                                                className="text-xs px-[6px] py-4 font-medium capitalize w-full justify-start !rounded-none"
                                                                onClick={() => {
                                                                    setSelectedInternalUser(
                                                                        {
                                                                            label: removeEnumUnderscore(
                                                                                `${creator.first_name} ${creator.last_name}`
                                                                            ),
                                                                            value: creator.user_id,
                                                                        }
                                                                    );

                                                                    setShowCreatorListPopover(
                                                                        false
                                                                    );
                                                                }}
                                                            >
                                                                {`${creator.first_name} ${creator.last_name}`}
                                                            </Button>
                                                        )
                                                    )}
                                                </>
                                            )}
                                    </div>
                                </PopoverContent>
                            </Popover>

                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize"
                                    >
                                        Status
                                        <ChevronDownIcon
                                            stroke={APP_COLORS.COLOR_BLACK}
                                        />
                                    </Button>
                                </DropdownTrigger>
                                <DropdownContent width="auto">
                                    {Object.values(TaskStatus).map((status) => (
                                        <DropdownItem
                                            className="flex justify-between items-center capitalize text-xs font-medium"
                                            key={status}
                                            onClick={() =>
                                                handleTaskStatusFilter(status)
                                            }
                                            isMulti
                                            itemValue={status}
                                            values={taskStatusFilter}
                                        >
                                            {removeEnumUnderscore(status)}
                                        </DropdownItem>
                                    ))}
                                </DropdownContent>
                            </Dropdown>

                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize"
                                    >
                                        Task category
                                        <ChevronDownIcon
                                            stroke={APP_COLORS.COLOR_BLACK}
                                        />
                                    </Button>
                                </DropdownTrigger>
                                <DropdownContent width="auto">
                                    {Object.values(TaskCategory).map(
                                        (category) => (
                                            <DropdownItem
                                                className="flex justify-between items-center capitalize text-xs font-medium"
                                                key={category}
                                                onClick={() =>
                                                    handleTaskCategoryFilter(
                                                        category
                                                    )
                                                }
                                                isMulti
                                                itemValue={category}
                                                values={taskCategoryFilter}
                                            >
                                                {removeEnumUnderscore(category)}
                                            </DropdownItem>
                                        )
                                    )}
                                </DropdownContent>
                            </Dropdown>

                            <Popover>
                                <PopoverTrigger asChild>
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium self-start md:self-center"
                                    >
                                        Date
                                        <ChevronDownIcon
                                            stroke={APP_COLORS.COLOR_BLACK}
                                        />
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent
                                    align="end"
                                    className="h-auto w-96 px-16 pt-6 z-20"
                                >
                                    <DatePicker
                                        label="From"
                                        placeholderText="MM/DD/YYYY"
                                        selected={fromDateFilter}
                                        onChange={handleFromDateFilterChange}
                                        className="hover:border-primary"
                                        maxDate={
                                            toDateFilter || new Date(Date.now())
                                        }
                                        isClearable
                                    />
                                    <div className="mt-24 mb-24 hover:border-primary">
                                        <DatePicker
                                            label="To"
                                            placeholderText="MM/DD/YYYY"
                                            selected={toDateFilter}
                                            onChange={handleToDateFilterChange}
                                            className="hover:border-primary"
                                            minDate={fromDateFilter}
                                            maxDate={new Date(Date.now())}
                                            isClearable
                                        />
                                    </div>
                                </PopoverContent>
                            </Popover>
                        </div>

                        {practice?.permissions?.includes(
                            UserPermisions.TODO_ALL_READ
                        ) && (
                            <Button onClick={() => setModalInView("add-task")}>
                                Create new task
                            </Button>
                        )}
                    </div>
                </div>

                <TableFiltersDisplay
                    appliedFilters={[
                        { key: "category[]", values: taskCategoryFilter },
                        { key: "status[]", values: taskStatusFilter },
                    ]}
                    wrapperClass="mt-12"
                />
            </div>

            {isLoading && (
                <Skeleton type="table" containerTestId="my-tasks-loader" />
            )}

            {error && error?.response?.status !== 404 && (
                <ListState
                    isError
                    stateHelperText="Try reloading this page to solve this issue"
                    errorMsg={
                        error?.response?.data.message ||
                        `Cannot display tasks at this time please try again later`
                    }
                />
            )}

            {data && isSuccess && (
                <>
                    {data.data.length === 0 && (
                        <ListState
                            stateHelperText="Tasks will appear here once they are added"
                            emptyMessage="No tasks added yet"
                            isError={false}
                            emptyBtnProps={{
                                showButton: true,
                                onButtonClick: () => setModalInView("add-task"),
                                buttonText: "Create New Task",
                            }}
                        />
                    )}

                    {data.data.length > 0 && (
                        <>
                            {getTotalAmountOfTableRowsSelected(taskRowSelection)
                                .amount > 0 && (
                                <div className="flex mb-2 gap-2">
                                    <span className="text-sm pt-[0.7rem] pr-[0.4rem] font-semibold">
                                        {
                                            getTotalAmountOfTableRowsSelected(
                                                taskRowSelection
                                            ).amountString
                                        }
                                    </span>
                                    <Button
                                        className="bg-danger hover:bg-danger"
                                        aria-label="delete tasks"
                                        type="button"
                                        onClick={() => setShowDeleteModal(true)}
                                    >
                                        Delete tasks
                                    </Button>
                                </div>
                            )}

                            <Table
                                columns={columns}
                                data={data?.data}
                                handleRowClick={(row) => {
                                    searchParams.set(
                                        "task_id",
                                        row.original.todo_id
                                    );
                                    setSearchParams(searchParams);
                                }}
                                rowSelection={taskRowSelection}
                                setRowSelection={setTaskRowSelection}
                                setGetSelectedData={setGetSelectedData}
                                setSortConfig={setSortConfig}
                                sortConfig={sortConfig}
                                sortableColumns={sortableColumns}
                                hasPagination={data.total_count > 20}
                                pagination={
                                    <Pagination
                                        totalCount={data.total_count}
                                        totalPages={data.total_pages}
                                        onPageChange={handlePageChange}
                                        limit={currentLimit}
                                        onLimitChange={(l) =>
                                            setCurrentLimit(l)
                                        }
                                        currentPage={pageFilter}
                                    />
                                }
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
}
