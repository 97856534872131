import { ref, update } from "firebase/database";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import flowerVase from "../../assets/images/flowervase.svg";
import { rtdb } from "../../firebase";
import { useFetchUserDetails } from "../../hooks/queries/user";
import { formatDate } from "../../utils/helpers";
import {
    AppNotification,
    AppNotificationType,
} from "../../utils/types/notifications";
import Notification from "./Notification/Notification";
import styles from "./NotificationBox.module.scss";
import usePractice from "../../hooks/usePractice";

interface NotificationBoxProps {
    /** Notifications loading prop */
    loading: boolean;
    /** Error when fetching notifications */
    isError: boolean;
    /** Notifications data */
    data: AppNotification[];
    /** Type of notification */
    type: "not_reminder" | "reminder";
}

function getTimeLapse(isoDateTime: string): string {
    return formatDate(isoDateTime, true, "MM/dd/yyyy, h:mm a");
}

function sortNotificationBySentDate(
    notifications: AppNotification[]
): AppNotification[] {
    return notifications.sort((a, b) =>
        new Date(a.sent_at).getTime() > new Date(b.sent_at).getTime() ? -1 : 1
    );
}

export default function NotificationBox({
    loading,
    isError,
    data: notifications,
    type,
}: NotificationBoxProps) {
    const loggedInUser = useFetchUserDetails();
    const [isExecutingMarkAsRead, setIsExecutingMarkAsRead] =
        React.useState(false);
    const navigate = useNavigate();

    const { practice } = usePractice();

    const markNotificationAsRead = async (
        item: AppNotification,
        isRunning: boolean
    ) => {
        if (item.is_read || isRunning) {
            return;
        }

        setIsExecutingMarkAsRead(true);
        const notificationDocPath = `notifications/${
            practice?.practice_id || ""
        }/${loggedInUser.data?.user_id}/${item.id}`;
        const updatedNotificationItem: AppNotification = {
            ...item,
            is_read: true,
        };
        try {
            await update(
                ref(rtdb, notificationDocPath),
                updatedNotificationItem
            );
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log("Error making notification as read", e);
        } finally {
            setIsExecutingMarkAsRead(false);
        }
    };

    const unreadNotificationCount = notifications.filter(
        (n) => !n.is_read
    ).length;

    return (
        <div className={styles.notifications}>
            <div className={styles.inner}>
                <div className={styles.header}>
                    <div className={styles.header_left}>
                        <h3 className={styles.header_text}>Notifications</h3>
                        {unreadNotificationCount > 0 && (
                            <span className={styles.amount}>
                                {unreadNotificationCount}
                            </span>
                        )}
                    </div>
                    {/* <button className={styles.mark_all}>
                        Mark all as Read
                    </button> */}
                </div>
                {loading && <div>Loading</div>}
                {isError && <div>Error</div>}
                {notifications.length === 0 && (
                    <div className={styles.empty}>
                        <img
                            src={flowerVase}
                            alt="illustration for empty state"
                        />
                        <div className={styles.empty_text}>
                            Looks like you haven&apos;t received any
                            notification yet.
                        </div>
                    </div>
                )}
                {notifications.length > 0 && (
                    <ul className={styles.list}>
                        {sortNotificationBySentDate(notifications).map(
                            (item) => {
                                const onNotificationClick = async () => {
                                    await markNotificationAsRead(
                                        item,
                                        isExecutingMarkAsRead
                                    );
                                    switch (item.type) {
                                        case AppNotificationType.ASSIGNED_TODO: {
                                            if (item.metadata?.todo_id) {
                                                navigate(
                                                    `/tasks?&task_id=${item.metadata.todo_id}`
                                                );
                                            }
                                            break;
                                        }
                                        case AppNotificationType.NEW_NOTE_MESSAGE: {
                                            if (
                                                !item.metadata?.client_id ||
                                                !item.metadata?.note_id
                                            ) {
                                                break;
                                            }
                                            navigate(
                                                `/notes/${item.metadata.client_id}/${item.metadata.note_id}?tab=messages`
                                            );
                                            break;
                                        }
                                        default:
                                            break;
                                    }
                                };
                                // const onNotificationHover = async () => {
                                //     await markNotificationAsRead(
                                //         item,
                                //         isExecutingMarkAsRead
                                //     );
                                // };
                                return (
                                    <li key={item.id}>
                                        <Notification
                                            title={item.subject}
                                            details={item.description}
                                            timeLaspe={getTimeLapse(
                                                item.sent_at
                                            )}
                                            isUnread={!item.is_read}
                                            type={type}
                                            onClick={onNotificationClick}
                                            onHover={() => {}}
                                        />
                                    </li>
                                );
                            }
                        )}
                    </ul>
                )}
            </div>
        </div>
    );
}
