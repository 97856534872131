import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useQueryClient } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router-dom";
import { DatePicker, Input, Select } from "@jhool-io/fe-components";
import { STATES } from "../../../../../utils/helpers/us-states/us-states";
import useToast from "../../../../../hooks/useToast";
import { IPaginatedApiResponse } from "../../../../../utils/types/api-response";
import {
    NUMBER_REGEX,
    handleFormatDatePickerValue,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../../../utils/helpers";
import { APP_COLORS } from "../../../../../utils/constants";
import { normalizePhoneNumber } from "../../../../../utils/helpers/phonenumber/phonenumber";
import {
    IClientInsuranceDetails,
    IClientNewInsuranceDetails,
    InsuranceType,
    RelationshipToPrimaryInsured,
    UserSex,
} from "../../../types/clients.types";
import { useAddClientInsurance } from "../../../hooks/clients.mutations";
import { useFetchInsuranceProviders } from "../../../hooks/clients.queries";

interface AddInsuranceProps {
    // Function to call when form submit button is clicked
    onFormSubmit(): void;
    // client id when component is used in create client flow
    newClientId?: string;
}

type SelectOptionInsuranceProvider = {
    label: React.ReactNode;
    value: string;
};

type Option = {
    label: React.ReactNode;
    value: boolean;
};

const options: Option[] = [
    {
        value: true,
        label: <span style={{ color: APP_COLORS.COLOR_PRIMARY }}>Yes</span>,
    },
    {
        value: false,
        label: <span style={{ color: APP_COLORS.COLOR_DANGER }}>No</span>,
    },
];

const schema = yup.object({
    insurance_provider_id: yup.string().required("Provider's name is required"),
    is_cal_year: yup.boolean(),
    start_date: yup
        .date()
        .nullable()
        .when(["is_cal_year"], {
            is: (is_cal_year: boolean) =>
                is_cal_year === false && typeof is_cal_year !== undefined,
            then: yup.date().required("Start date is required"),
        }),
    end_date: yup
        .date()
        .nullable()
        .when(["is_cal_year"], {
            is: (is_cal_year: boolean) =>
                is_cal_year === false && typeof is_cal_year !== undefined,
            then: yup
                .date()
                .required("End date is required")
                .min(
                    yup.ref("start_date"),
                    "The end date cannot be earlier than the start date"
                ),
        }),
    employer_name: yup.string().required("Employer name is required"),
    insurance_member_id: yup
        .string()
        .required("Insurance member id is required"),
    primary_insured_address: yup.string(),
    primary_insured_state: yup.string(),
    primary_insured_zipcode: yup.string().test({
        test: (val) => (val ? NUMBER_REGEX.test(val) : true),
        message: "Field should only contain numbers",
    }),
    last_verified: yup.date().nullable(),
    non_client_primary_insured_name: yup.string(),
    non_client_primary_insured_dob: yup
        .date()
        .nullable()
        .typeError("Invalid Date"),
    relationship_to_primary_insured: yup.string(),
    primary_insured_city: yup.string(),
    primary_insured_phone_no: yup.string(),
    primary_insured_sex: yup.string(),
    primary_insured_dob: yup.date().nullable().typeError("Invalid Date"),
    insured_policy_group: yup.string(),
    insurance_type: yup.string(),
});

const getClientRelationshipToPrimaryInsuredOptions = [
    {
        label: "Relationship to primary insured",
        value: "",
    },
    ...Object.values(RelationshipToPrimaryInsured).map((item) => ({
        label: makeStringFirstLetterCapital(item),
        value: item,
    })),
];
const getClientPrimaryInsuredSexOptions = [
    {
        label: "Primary insured sex",
        value: "",
    },
    ...Object.values(UserSex).map((item) => ({
        label: makeStringFirstLetterCapital(item),
        value: item,
    })),
];
const getClientInsuranceTypeOptions = [
    {
        label: "Insurance type",
        value: "",
    },

    ...Object.values(InsuranceType).map((item) => ({
        label: makeStringFirstLetterCapital(removeEnumUnderscore(item)),
        value: item,
    })),
];

const getClientStateFieldSelectOptions = () => {
    return STATES.map((state) => {
        return {
            label: state.name,
            value: state.abbrv,
        };
    });
};

export default function AddInsurance({
    onFormSubmit,
    newClientId,
}: AddInsuranceProps) {
    // Local component states
    const [nonClientPrimaryInsuredDOB, setNonClientPrimaryInsuredDOB] =
        React.useState<Date | null>(null);
    const [lastVerified, setLastVerified] = React.useState<Date | null>(null);
    const [startDate, setStartDate] = React.useState<Date | null>(null);
    const [endDate, setEndDate] = React.useState<Date | null>(null);
    const [localDateOfBirth, setLocalDateOfBirth] = React.useState<Date | null>(
        null
    );

    const {
        register,
        handleSubmit,
        control,
        setValue,
        formState: { errors },
        watch,
    } = useForm<IClientNewInsuranceDetails>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    // To initialize clientId constant
    const params = useParams();
    const clientId = newClientId || (params.clientId as string);

    // Get the current location object
    const location = useLocation();

    // Hook for adding client fee
    const { mutate } = useAddClientInsurance(clientId);

    // Query client
    const queryClient = useQueryClient();

    // Hook for fetching insurance providers
    const insuranceProviders = useFetchInsuranceProviders();

    // Toast for success and error states
    const { toast } = useToast();

    // Select options for insurance providers
    const insuranceProvidersSelectOptions = insuranceProviders.data?.data
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((provider) => ({
            label: removeEnumUnderscore(provider.name).toUpperCase(),
            value: provider.insurance_provider_id,
        }));

    // Watch value of is_cal_year input field
    const isCalYear = watch("is_cal_year", true);

    // Watch value of end_date

    const onSubmit = (payload: IClientNewInsuranceDetails) => {
        const dataToSend = {
            ...payload,
        };

        if (payload.last_verified) {
            dataToSend.last_verified = handleFormatDatePickerValue(
                payload.last_verified
            );
        }

        if (payload.end_date)
            dataToSend.end_date = handleFormatDatePickerValue(payload.end_date);

        if (payload.start_date)
            dataToSend.start_date = handleFormatDatePickerValue(
                payload.start_date
            );

        if (payload.non_client_primary_insured_dob) {
            dataToSend.non_client_primary_insured_dob =
                handleFormatDatePickerValue(
                    payload.non_client_primary_insured_dob
                );
        }

        if (payload.primary_insured_dob) {
            dataToSend.primary_insured_dob = handleFormatDatePickerValue(
                payload.primary_insured_dob
            );
        }

        if (!dataToSend.insurance_member_id)
            dataToSend.insurance_member_id = undefined;
        if (!dataToSend.last_verified) dataToSend.last_verified = undefined;
        if (!dataToSend.non_client_primary_insured_name)
            dataToSend.non_client_primary_insured_name = undefined;
        if (!dataToSend.non_client_primary_insured_dob)
            dataToSend.non_client_primary_insured_dob = undefined;
        if (!dataToSend.relationship_to_primary_insured)
            dataToSend.relationship_to_primary_insured = undefined;
        if (!dataToSend.primary_insured_city)
            dataToSend.primary_insured_city = undefined;
        if (!dataToSend.primary_insured_phone_no)
            dataToSend.primary_insured_phone_no = undefined;
        if (!dataToSend.primary_insured_sex)
            dataToSend.primary_insured_sex = undefined;
        if (!dataToSend.primary_insured_dob)
            dataToSend.primary_insured_dob = undefined;
        if (!dataToSend.insured_policy_group)
            dataToSend.insured_policy_group = undefined;
        if (!dataToSend.insurance_type) dataToSend.insurance_type = undefined;
        if (!dataToSend.primary_insured_address)
            dataToSend.primary_insured_address = undefined;
        if (!dataToSend.primary_insured_state)
            dataToSend.primary_insured_state = undefined;
        if (!dataToSend.primary_insured_zipcode)
            dataToSend.primary_insured_zipcode = undefined;

        mutate(dataToSend, {
            onSuccess: (res) => {
                if (location.search === "?tab=insurance")
                    localStorage.setItem(
                        "newInsuranceId",
                        res.data.insurance_id
                    );

                queryClient.setQueryData<
                    IPaginatedApiResponse<IClientInsuranceDetails[]>
                >([clientId, "insurance", 1], (prev) => {
                    if (!prev) {
                        return {
                            count: 1,
                            total_count: 0,
                            current_page: 0,
                            total_pages: 0,
                            message: "Insurance history fetched successfully!",
                            data: [res.data],
                        };
                    }
                    return {
                        ...prev,
                        count: prev.count + 1,
                        total_count: prev.total_count + 1,
                        message: "Insurance history fetched successfully!",
                        data: [res.data, ...prev.data],
                    };
                });
                queryClient.invalidateQueries({
                    queryKey: [clientId, "insurance", 1],
                });
                toast({
                    mode: "success",
                    message: res.message || "Insurance details added!",
                });
                onFormSubmit();
            },
            onError: (error) => {
                toast({
                    mode: "error",
                    message:
                        error.response?.data.message ||
                        "Could not add client insurance details at this time",
                });
            },
        });
    };

    // Watch for when contact values in our form change
    const phoneNo = watch("primary_insured_phone_no");

    // Run helper to format contact values when it change
    React.useEffect(() => {
        if (phoneNo) {
            setValue("primary_insured_phone_no", normalizePhoneNumber(phoneNo));
        }
    }, [phoneNo, setValue]);

    return (
        <form id="add-insurance" onSubmit={handleSubmit(onSubmit)}>
            <div className="fg">
                <Controller
                    name="insurance_provider_id"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Insurance Provider"
                            options={insuranceProvidersSelectOptions || []}
                            onChange={(val) =>
                                field.onChange(
                                    (val as SelectOptionInsuranceProvider).value
                                )
                            }
                            hasError={!!errors.insurance_provider_id}
                            errorText={errors.insurance_provider_id?.message}
                        />
                    )}
                />
            </div>
            <div className="fg">
                <Controller
                    name="is_cal_year"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Calendar Year"
                            placeholder="Calendar Year"
                            options={options}
                            onChange={(val) =>
                                field.onChange((val as Option).value)
                            }
                            hasError={!!errors.is_cal_year}
                            errorText={errors.is_cal_year?.message}
                        />
                    )}
                />
            </div>
            {isCalYear === false && typeof isCalYear !== undefined && (
                <div className="fg">
                    <div className="fg fg-space-between two flex">
                        <Controller
                            name="start_date"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    label="Start date"
                                    onChange={(date) => {
                                        field.onChange(date);
                                        setStartDate(date);
                                    }}
                                    selected={startDate}
                                    hasError={!!errors.start_date}
                                    errorText={
                                        errors.start_date?.type === "typeError"
                                            ? "invalid date value"
                                            : errors.start_date?.message
                                    }
                                />
                            )}
                        />
                        <Controller
                            name="end_date"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    label="End date"
                                    onChange={(date) => {
                                        field.onChange(date);
                                        setEndDate(date);
                                    }}
                                    selected={endDate}
                                    hasError={!!errors.end_date}
                                    errorText={
                                        errors.end_date?.type === "typeError"
                                            ? "invalid date value"
                                            : errors.end_date?.message
                                    }
                                />
                            )}
                        />
                    </div>
                </div>
            )}
            <div className="fg">
                <Controller
                    name="insurance_type"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Insurance type"
                            placeholder="Insurance type"
                            options={getClientInsuranceTypeOptions}
                            onChange={(val) =>
                                field.onChange((val as Option).value)
                            }
                        />
                    )}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Input
                    {...register("employer_name")}
                    label="Employer name"
                    placeholder="Employer name"
                    hasError={!!errors.employer_name}
                    errorText={errors.employer_name?.message}
                />
                <Input
                    {...register("insurance_member_id")}
                    label="Insurance member ID"
                    placeholder="Insurance member ID"
                    hasError={!!errors.insurance_member_id}
                    errorText={errors.insurance_member_id?.message}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Controller
                    name="last_verified"
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            label="Last Verified"
                            onChange={(date) => {
                                field.onChange(date);
                                setLastVerified(date);
                            }}
                            selected={lastVerified}
                            maxDate={new Date(Date.now())}
                            hasError={!!errors.last_verified}
                            errorText={
                                errors.last_verified?.type === "typeError"
                                    ? "invalid date value"
                                    : errors.last_verified?.message
                            }
                        />
                    )}
                />
                <Input
                    {...register("non_client_primary_insured_name")}
                    label="Non-Client Primary Insured name"
                    placeholder="Non-Client Primary Insured name"
                    hasError={!!errors.non_client_primary_insured_name}
                    errorText={errors.non_client_primary_insured_name?.message}
                />
            </div>
            <div className="fg">
                <Controller
                    name="non_client_primary_insured_dob"
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            label="Non-Client Primary Insured DOB"
                            onChange={(date) => {
                                field.onChange(date);
                                setNonClientPrimaryInsuredDOB(date);
                            }}
                            maxDate={new Date(Date.now())}
                            selected={nonClientPrimaryInsuredDOB}
                            hasError={!!errors.non_client_primary_insured_dob}
                            errorText={
                                errors.non_client_primary_insured_dob?.type ===
                                "typeError"
                                    ? "invalid date value"
                                    : errors.non_client_primary_insured_dob
                                          ?.message
                            }
                        />
                    )}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Input
                    {...register("primary_insured_address")}
                    label="Primary Insured address"
                    placeholder="Primary Insured address"
                    hasError={!!errors.primary_insured_address}
                    errorText={errors.primary_insured_address?.message}
                />
                <Controller
                    name="primary_insured_sex"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Primary insured sex"
                            placeholder="Primary insured sex"
                            options={getClientPrimaryInsuredSexOptions}
                            onChange={(val) =>
                                field.onChange((val as Option).value)
                            }
                        />
                    )}
                />
            </div>
            <div className="fg">
                <Controller
                    name="relationship_to_primary_insured"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Relationship to primary insured"
                            placeholder="Relationship to primary insured"
                            options={
                                getClientRelationshipToPrimaryInsuredOptions
                            }
                            onChange={(val) =>
                                field.onChange((val as Option).value)
                            }
                        />
                    )}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Controller
                    name="primary_insured_state"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="State"
                            options={getClientStateFieldSelectOptions()}
                            onChange={(val) =>
                                field.onChange((val as Option).value)
                            }
                        />
                    )}
                />
                <Input
                    {...register("primary_insured_zipcode")}
                    label="Zipcode"
                    placeholder="Zipcode"
                    hasError={!!errors.primary_insured_zipcode}
                    errorText={errors.primary_insured_zipcode?.message}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Input
                    {...register("primary_insured_city")}
                    label="Primary insured city"
                    placeholder="Primary insured city"
                />
                <Input
                    {...register("primary_insured_phone_no")}
                    label="Primary insured phone no"
                    placeholder="Primary insured phone no"
                />
            </div>
            <div className="fg">
                <Controller
                    name="primary_insured_dob"
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            label="Primary insured dob"
                            onChange={(date) => {
                                field.onChange(date);
                                setLocalDateOfBirth(date);
                            }}
                            selected={localDateOfBirth}
                            maxDate={new Date(Date.now())}
                        />
                    )}
                />
            </div>
            <div className="fg">
                <Input
                    {...register("insured_policy_group")}
                    label="Insured policy group"
                    placeholder="Insured policy group"
                />
            </div>
        </form>
    );
}
