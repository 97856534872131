/* eslint-disable react/no-unstable-nested-components */
import { Tabs, Dialog, DialogContent } from "@jhool-io/fe-components";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import React from "react";
import { useIsMutating } from "@tanstack/react-query";

import AddTask from "./AddTask/AddTask";
import TaskDetails from "./TaskDetails/TaskDetails";
import ReassignTask from "./ReassignTask/ReassignTask";
import MyTasksList from "./MyTasksList/MyTasksList";
import AllTasksList from "./AllTasksList/AllTasksList";

export type ModalContent = "add-task" | "reassign-task";

export default function TasksListsWrapper() {
    const location = useLocation();
    const navigate = useNavigate();

    // Component states
    const [searchParams, setSearchParams] = useSearchParams();
    const [modalInView, setModalInView] = React.useState<ModalContent | null>(
        null
    );
    const [showEditor, setShowEditor] = React.useState(false);

    const taskIdInUrl = searchParams.get("task_id");

    const handleSetTabInUrl = (tab: string) => {
        searchParams.set("tab", tab);
        setSearchParams(searchParams);

        // reset local filters before switching
        navigate(
            searchParams.get("tab")
                ? `${location.pathname}?tab=${searchParams.get("tab")}`
                : location.pathname
        );
    };

    const isAddTaskMutating = useIsMutating(["add-todo"]);
    const isUpdateTaskMutating = useIsMutating(["update-todo"]);

    const handleSetModalInView = (modal: ModalContent | null) => {
        setModalInView(() => modal);
    };

    const handleCloseDetailsDialog = () => {
        searchParams.delete("task_id");
        setSearchParams(searchParams, { replace: true });
    };

    const handleCloseTaskMutationDialog = () => {
        setModalInView(() => null);
        searchParams.delete("task_id");
        setSearchParams(searchParams);
    };

    const getOkText = (action: ModalContent) => {
        let confirmText: string;
        switch (action) {
            case "add-task":
                confirmText = "Add task";
                break;
            case "reassign-task":
                confirmText = "Re-assign task";
                break;
            default:
                confirmText = "Delete task";
        }
        return confirmText;
    };

    const getActionToShow = (actionModalInView: ModalContent) => {
        let modalHeader: string;
        switch (actionModalInView) {
            case "add-task":
                modalHeader = "Add task";
                break;
            case "reassign-task":
                modalHeader = "Re-assign task";
                break;
            default:
                modalHeader = "Delete task";
        }
        return modalHeader;
    };

    const handleSubmitValue = () => {
        if (modalInView === "add-task") {
            return "add-todo";
        }

        if (modalInView === "reassign-task") {
            return "reassign-task";
        }

        return "edit-task";
    };

    const tabItems = [
        {
            key: "my_tasks",
            label: "My tasks",
            children: <MyTasksList setModalInView={handleSetModalInView} />,
            onClick: () =>
                handleSetTabInUrl(
                    "my_tasks&status[]=not_started&status[]=in_progress&category[]=user_created_todo"
                ),
        },
        {
            key: "all_tasks",
            label: "All tasks",
            children: <AllTasksList setModalInView={handleSetModalInView} />,
            onClick: () =>
                handleSetTabInUrl(
                    "all_tasks&status[]=not_started&status[]=in_progress&category[]=user_created_todo"
                ),
        },
    ];

    return (
        <>
            <Dialog open={Boolean(taskIdInUrl) && !modalInView}>
                <DialogContent
                    title="Task Details"
                    handleCloseDialog={handleCloseDetailsDialog}
                    showFooter={showEditor}
                    submitBtnFormValue="edit-task"
                    isSubmitBtnDisabled={isUpdateTaskMutating > 0}
                    isCancelBtnDisabled={isUpdateTaskMutating > 0}
                >
                    <TaskDetails
                        showEditor={showEditor}
                        setShowEditor={setShowEditor}
                        setHideModal={() => handleSetModalInView(null)}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={Boolean(modalInView)}>
                <DialogContent
                    title={getActionToShow(modalInView as ModalContent)}
                    variant="center"
                    handleCloseDialog={handleCloseTaskMutationDialog}
                    showFooter
                    saveText={getOkText(modalInView as ModalContent)}
                    cancelText="Cancel"
                    submitBtnFormValue={handleSubmitValue()}
                    isSubmitBtnDisabled={
                        isAddTaskMutating > 0 || isUpdateTaskMutating > 0
                    }
                    isCancelBtnDisabled={
                        isAddTaskMutating > 0 || isUpdateTaskMutating > 0
                    }
                >
                    <>
                        {modalInView === "add-task" && (
                            <AddTask
                                onFormSubmit={handleCloseTaskMutationDialog}
                            />
                        )}

                        {modalInView === "reassign-task" && (
                            <ReassignTask
                                onFormSubmit={handleCloseTaskMutationDialog}
                            />
                        )}
                    </>
                </DialogContent>
            </Dialog>

            <Tabs
                defaultActiveKey="my_tasks"
                size="medium"
                className="w-full rounded-b-none"
                items={tabItems}
            />
        </>
    );
}
