import { format } from "date-fns";
import axios from "../../../utils/axios";
import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";
import {
    INewTask,
    ITaskListFilters,
    IUpdateTaskPayload,
} from "../types/tasks.types";

// Endpoint to fetch provider's tasks
export async function fetchTasks(filters: ITaskListFilters) {
    const response = await axios.get(`/todos`, {
        params: handleRemoveEmptyParamFromQueryParams({
            ...filters,
            from_date: filters.from_date
                ? format(filters.from_date, "yyyy-MM-dd")
                : null,
            to_date: filters.to_date
                ? format(filters.to_date, "yyyy-MM-dd")
                : null,
        }),
    });

    return response.data;
}

// Endpoint to update task
export async function updateTask(payload: IUpdateTaskPayload) {
    const response = await axios.patch(`/todos`, payload);
    return response.data;
}

// Endpoint to add task
export async function addTask(data: INewTask) {
    const response = await axios.post(`/todos`, data);
    return response.data;
}

// Endpoint to cancel session
export async function cancelSession(payload: {
    session_history_ids: string[];
}) {
    const response = await axios.post(
        `/clients/session_history/cancel`,
        payload
    );
    return response.data;
}

// Endpoint to delete tasks
export async function deleteTasks(todoIds: string[]) {
    const response = await axios.delete(`/todos`, {
        data: { todo_ids_to_delete: todoIds },
    });
    return response.data;
}
