import * as React from "react";
import * as yup from "yup";
import cn from "classnames";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { DatePicker, Input, RadioInput, Select } from "@jhool-io/fe-components";
import {
    ClientInquiryMethod,
    IClientRelation,
    INewClientDetails,
    Pronouns,
    UserSex,
} from "../../../utils/types/client";
import styles from "./AddPersonalInfo.module.scss";
import { STATES } from "../../../utils/helpers/us-states/us-states";
import {
    PHONE_REGEX,
    normalizePhoneNumber,
} from "../../../utils/helpers/phonenumber/phonenumber";
import {
    handleFormatDatePickerValue,
    removeEnumUnderscore,
    NUMBER_REGEX,
    makeStringFirstLetterCapital,
} from "../../../utils/helpers";
import { SessionServiceType } from "../../../utils/types/session";
import { useFetchUserDetails } from "../../../hooks/queries/user";

type SubmitTypes = "save" | "save_continue";

interface AddPersonalInfoProps {
    onFormSubmit: (payload: INewClientDetails) => void;
    submitType: SubmitTypes | null;
}

const schema = yup.object().shape({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    pronouns: yup.string(),
    email: yup
        .string()
        .email("Please enter a valid email")
        .required("Email is required"),
    preferred_name: yup.string(),
    date_of_birth: yup.date().required("Date of birth is required"),
    contact: yup
        .string()
        .required("Contact is required")
        .matches(PHONE_REGEX, "Field should only contain numbers"),
    financial_assistance: yup
        .boolean()
        .required("Financial assistance is required"),
    financial_assistance_end_date: yup.date(),
    address: yup.string().required("Address is required"),
    state: yup.string().required("State is required"),
    zip_code: yup
        .string()
        .required("Zip Code is required")
        .matches(NUMBER_REGEX, "Field should only contain numbers"),
    referred_by: yup.string(),
    inquiry_method: yup.string(),
    requested_service: yup.string(),
    sex: yup.string().nullable(),
    city: yup.string(),
    primary_contact: yup
        .string()
        .nullable()
        .when("relation_info", (relation_info: IClientRelation[], field) => {
            const filteredRelationInfo = relation_info.filter(
                (item) => item.first_name !== ""
            );
            if (filteredRelationInfo.length > 0) return field.required();
            return field;
        }),
    relation_info: yup.array().of(
        yup.object().shape(
            {
                relationship_type: yup
                    .string()
                    .when(["first_name", "last_name", "contact_phone_no"], {
                        is: (
                            first_name: string,
                            last_name: string,
                            contact_phone_no: string
                        ) =>
                            Boolean(first_name) ||
                            Boolean(last_name) ||
                            Boolean(contact_phone_no),
                        then: yup
                            .string()
                            .required("Relationship type is required"),
                    }),
                first_name: yup
                    .string()
                    .when(
                        ["relationship_type", "last_name", "contact_phone_no"],
                        {
                            is: (
                                relationship_type: string,
                                last_name: string,
                                contact_phone_no: string
                            ) =>
                                Boolean(relationship_type) ||
                                Boolean(last_name) ||
                                Boolean(contact_phone_no),
                            then: yup
                                .string()
                                .required("Relation's first name is required"),
                        }
                    ),
                last_name: yup
                    .string()
                    .when(
                        ["relationship_type", "first_name", "contact_phone_no"],
                        {
                            is: (
                                relationship_type: string,
                                first_name: string,
                                contact_phone_no: string
                            ) =>
                                Boolean(relationship_type) ||
                                Boolean(first_name) ||
                                Boolean(contact_phone_no),
                            then: yup
                                .string()
                                .required("Relation's last name is required"),
                        }
                    ),
                contact_phone_no: yup
                    .string()
                    .when(["relationship_type", "first_name", "last_name"], {
                        is: (
                            relationship_type: string,
                            first_name: string,
                            last_name: string
                        ) =>
                            Boolean(relationship_type) ||
                            Boolean(first_name) ||
                            Boolean(last_name),
                        then: yup
                            .string()
                            .required("Relation's contact is required")
                            .matches(
                                PHONE_REGEX,
                                "Field should only contain numbers"
                            ),
                    }),
            },
            [
                ["relationship_type", "first_name"],
                ["relationship_type", "last_name"],
                ["relationship_type", "contact_phone_no"],
                ["first_name", "last_name"],
                ["first_name", "contact_phone_no"],
                ["last_name", "contact_phone_no"],
            ]
        )
    ),
});

type Option = {
    label: React.ReactNode;
    value: boolean;
};

type FinancialAssistanceOption = {
    label: string;
    value: boolean;
};

const options: FinancialAssistanceOption[] = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
];

const getSexForSelect = Object.values(UserSex).map((sex) => ({
    label: makeStringFirstLetterCapital(sex),
    value: sex,
}));

export default function AddPersonalInfo({
    onFormSubmit,
    submitType,
}: AddPersonalInfoProps) {
    // Local component states
    const [localDateOfBirth, setLocalDateOfBirth] = React.useState<Date | null>(
        null
    );
    const [localFinancialAssistanceEndDate, setLocalFinancialAssitanceEndDate] =
        React.useState<Date | null>(null);

    // Get looged in user data
    const loggedInUser = useFetchUserDetails();

    // React hook form values
    const {
        control,
        handleSubmit,
        register,
        formState: { errors },
        watch,
        setValue,
    } = useForm<INewClientDetails>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    // Function that handles mutation of data and form submit action in general
    const onSubmit = (data: INewClientDetails) => {
        let relationInfo: IClientRelation[] | undefined;

        if (!data.relation_info) {
            relationInfo = [];
        } else if (data.relation_info) {
            relationInfo = data.relation_info.filter(
                (item) => item.first_name !== ""
            );
        }

        if (relationInfo && relationInfo.length !== 0) {
            if (relationInfo.length === 1) {
                relationInfo = relationInfo.map((item) => ({
                    ...item,
                    is_primary_contact: true,
                }));
            } else if (relationInfo.length === 2) {
                relationInfo = relationInfo.map((item, i) => {
                    if (Number(data.primary_contact) === i) {
                        return { ...item, is_primary_contact: true };
                    }
                    return { ...item, is_primary_contact: false };
                });
            }
        }

        const dataToSend = {
            ...data,
            practice_id: loggedInUser.data?.practices[0]?.practice_id || "",
            date_of_birth: handleFormatDatePickerValue(data.date_of_birth),
            relation_info: relationInfo,
            primary_contact: undefined,
        };

        if (dataToSend.financial_assistance_end_date)
            dataToSend.financial_assistance_end_date =
                handleFormatDatePickerValue(
                    dataToSend.financial_assistance_end_date
                );

        if (!dataToSend.financial_assistance)
            dataToSend.financial_assistance_end_date = null;

        if (!dataToSend.preferred_name) dataToSend.preferred_name = undefined;

        if (!dataToSend.sex) dataToSend.sex = undefined;

        onFormSubmit(dataToSend);
    };

    // Get states as select options
    const getClientStateFieldSelectOptions = STATES.map((state) => ({
        label: state.name,
        value: state.abbrv,
    }));

    // Get inquiry methods as select options
    const getInquiryMethodSelectOptions = Object.values(
        ClientInquiryMethod
    ).map((method) => ({
        label: removeEnumUnderscore(method),
        value: method,
    }));

    // Get pronouns for select
    const getPronounsForSelect = Object.values(Pronouns).map((pronoun) => ({
        label: pronoun,
        value: pronoun,
    }));

    // Get requested services as select options
    const getRequestedServiceSelectOptions = Object.values(
        SessionServiceType
    ).map((service) => ({
        label: removeEnumUnderscore(service),
        value: service,
    }));

    /** Watch financial_assistance, to determine whether 
        to disable financial_assistance_end_date input or not
     */
    const financialAssitance = watch("financial_assistance", false) as boolean;

    // Watch for when contact values in our form change
    const contact = watch("contact");
    const parentOneContact = watch("relation_info.0.contact_phone_no");
    const parentTwoContact = watch("relation_info.1.contact_phone_no");

    // Run helper to format contact values when it change
    React.useEffect(() => {
        if (contact) {
            setValue("contact", normalizePhoneNumber(contact));
        }
    }, [contact, setValue]);

    React.useEffect(() => {
        if (parentOneContact) {
            setValue(
                "relation_info.0.contact_phone_no",
                normalizePhoneNumber(parentOneContact)
            );
        }
    }, [parentOneContact, setValue]);

    React.useEffect(() => {
        if (parentTwoContact) {
            setValue(
                "relation_info.1.contact_phone_no",
                normalizePhoneNumber(parentTwoContact)
            );
        }
    }, [parentTwoContact, setValue]);

    return (
        <form
            id={
                submitType === "save"
                    ? "add-personal-info-save"
                    : "add-personal-info-continue"
            }
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="fg fg-space-between two flex">
                <Input
                    {...register("first_name")}
                    label="First name"
                    placeholder="First name"
                    hasError={!!errors.first_name}
                    errorText={errors.first_name?.message}
                />
                <Input
                    {...register("last_name")}
                    label="Last name"
                    placeholder="Last name"
                    hasError={!!errors.last_name}
                    errorText={errors.last_name?.message}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Controller
                    name="pronouns"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Pronouns"
                            placeholder="Pronouns"
                            options={getPronounsForSelect}
                            onChange={(val) =>
                                field.onChange((val as Option).value)
                            }
                            hasError={!!errors.pronouns}
                            errorText={errors.pronouns?.message}
                        />
                    )}
                />
                <Input
                    {...register("preferred_name")}
                    label="Preferred name"
                    placeholder="Preferred name"
                    hasError={!!errors.preferred_name}
                    errorText={errors.preferred_name?.message}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Controller
                    name="date_of_birth"
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            label="Date of Birth"
                            onChange={(date) => {
                                field.onChange(date);
                                setLocalDateOfBirth(date);
                            }}
                            selected={localDateOfBirth}
                            maxDate={new Date(Date.now())}
                            hasError={!!errors.date_of_birth}
                            errorText={
                                errors.date_of_birth?.type === "typeError"
                                    ? "invalid date value"
                                    : errors.date_of_birth?.message
                            }
                        />
                    )}
                />
                <Input
                    {...register("contact")}
                    label="Contact"
                    placeholder="Contact"
                    hasError={!!errors.contact}
                    errorText={errors.contact?.message}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Controller
                    name="sex"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Sex"
                            placeholder="Sex"
                            options={getSexForSelect}
                            onChange={(val) =>
                                field.onChange((val as Option).value)
                            }
                            hasError={!!errors.sex}
                            errorText={errors.sex?.message}
                        />
                    )}
                />
                <Input
                    {...register("email")}
                    label="Email"
                    placeholder="Email"
                    hasError={!!errors.email}
                    errorText={errors.email?.message}
                />
            </div>
            <div
                className={cn("fg", {
                    "fg-space-between two flex": financialAssitance,
                })}
            >
                <Controller
                    name="financial_assistance"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Financial assistance"
                            options={options}
                            onChange={(val) =>
                                field.onChange(
                                    (val as FinancialAssistanceOption).value
                                )
                            }
                            hasError={!!errors.financial_assistance}
                            errorText={errors.financial_assistance?.message}
                        />
                    )}
                />
                {financialAssitance && (
                    <Controller
                        name="financial_assistance_end_date"
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                disabled={!financialAssitance}
                                label="Financial assistance ends on"
                                minDate={new Date(Date.now())}
                                onChange={(date) => {
                                    field.onChange(date);
                                    setLocalFinancialAssitanceEndDate(date);
                                }}
                                selected={localFinancialAssistanceEndDate}
                                hasError={
                                    !!errors.financial_assistance_end_date
                                }
                                errorText={
                                    errors.financial_assistance_end_date
                                        ?.type === "typeError"
                                        ? "invalid date value"
                                        : errors.financial_assistance_end_date
                                              ?.message
                                }
                            />
                        )}
                    />
                )}
            </div>
            <div className="fg">
                <Input
                    {...register("address")}
                    label="Address"
                    placeholder="Address"
                    hasError={!!errors.address}
                    errorText={errors.address?.message}
                />
            </div>

            <div className="fg fg-space-between two flex">
                <Controller
                    name="state"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="State"
                            options={getClientStateFieldSelectOptions}
                            isSearchable
                            onChange={(val) =>
                                field.onChange((val as Option).value)
                            }
                            hasError={!!errors.state}
                            errorText={errors.state?.message}
                        />
                    )}
                />
                <Input
                    {...register("city")}
                    label="City"
                    placeholder="City"
                    hasError={!!errors.city}
                    errorText={errors.city?.message}
                />
            </div>
            <div className="fg">
                <Input
                    {...register("zip_code")}
                    label="Zipcode"
                    placeholder="Zipcode"
                    hasError={!!errors.zip_code}
                    errorText={errors.zip_code?.message}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Controller
                    name="inquiry_method"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Inquiry Method"
                            placeholder="Inquiry Method"
                            isSearchable
                            options={getInquiryMethodSelectOptions}
                            onChange={(val) =>
                                field.onChange((val as Option).value)
                            }
                            hasError={!!errors.inquiry_method}
                            errorText={errors.inquiry_method?.message}
                        />
                    )}
                />
                <Controller
                    name="requested_service"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Service Requested"
                            placeholder="Service Requested"
                            options={getRequestedServiceSelectOptions}
                            isSearchable
                            onChange={(val) =>
                                field.onChange((val as Option).value)
                            }
                            hasError={!!errors.requested_service}
                            errorText={errors.requested_service?.message}
                        />
                    )}
                />
            </div>
            <div className={styles.parent_section}>
                <div className={styles.form_header}>Relation info</div>
                {errors.primary_contact ? (
                    <div className={styles.error}>
                        <p>Please select a primary contact</p>
                    </div>
                ) : null}
                <div className={styles.mb}>
                    <RadioInput
                        {...register("primary_contact")}
                        label="Primary contact"
                        value="0"
                    />
                </div>
                <div className="fg">
                    <Input
                        {...register(`relation_info.0.relationship_type`)}
                        label="Relationship"
                        placeholder="Relationship"
                        hasError={
                            !!errors?.relation_info?.[0]?.relationship_type
                        }
                        errorText={
                            errors?.relation_info?.[0]?.relationship_type
                                ?.message
                        }
                    />
                </div>
                <div className="fg fg-space-between two flex">
                    <Input
                        {...register(`relation_info.0.first_name`)}
                        label="Relation's first name"
                        placeholder="Relation's first name"
                        hasError={!!errors?.relation_info?.[0]?.first_name}
                        errorText={
                            errors?.relation_info?.[0]?.first_name?.message
                        }
                    />
                    <Input
                        {...register(`relation_info.0.last_name`)}
                        label="Relation's last name"
                        placeholder="Relation's last name"
                        hasError={!!errors?.relation_info?.[0]?.last_name}
                        errorText={
                            errors?.relation_info?.[0]?.last_name?.message
                        }
                    />
                </div>
                <div className="fg">
                    <Input
                        {...register(`relation_info.0.contact_phone_no`)}
                        label="Contact"
                        placeholder="Contact"
                        hasError={
                            !!errors?.relation_info?.[0]?.contact_phone_no
                        }
                        errorText={
                            errors?.relation_info?.[0]?.contact_phone_no
                                ?.message
                        }
                    />
                </div>
                <div className={styles.mb}>
                    <RadioInput
                        {...register("primary_contact")}
                        label="Primary contact"
                        value="1"
                    />
                </div>
                <div className="fg">
                    <Input
                        {...register(`relation_info.1.relationship_type`)}
                        label="Relationship"
                        placeholder="Relationship"
                        hasError={
                            !!errors?.relation_info?.[1]?.relationship_type
                        }
                        errorText={
                            errors?.relation_info?.[1]?.relationship_type
                                ?.message
                        }
                    />
                </div>
                <div className="fg fg-space-between two flex">
                    <Input
                        {...register(`relation_info.1.first_name`)}
                        label="Relation's first name"
                        placeholder="Relation's first name"
                        hasError={!!errors?.relation_info?.[1]?.first_name}
                        errorText={
                            errors?.relation_info?.[1]?.first_name?.message
                        }
                    />
                    <Input
                        {...register(`relation_info.1.last_name`)}
                        label="Relation's last name"
                        placeholder="Relation's last name"
                        hasError={!!errors?.relation_info?.[1]?.last_name}
                        errorText={
                            errors?.relation_info?.[1]?.last_name?.message
                        }
                    />
                </div>
                <div className="fg">
                    <Input
                        {...register(`relation_info.1.contact_phone_no`)}
                        label="Contact"
                        placeholder="Contact"
                        hasError={
                            !!errors?.relation_info?.[1]?.contact_phone_no
                        }
                        errorText={
                            errors?.relation_info?.[1]?.contact_phone_no
                                ?.message
                        }
                    />
                </div>
            </div>
        </form>
    );
}
