import { useParams } from "react-router-dom";
import { DatePicker, Input, Select, TextArea } from "@jhool-io/fe-components";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useFetchInvoices } from "../../../hooks/queries/billing.queries";
import {
    cn,
    formatDate,
    handleFormatDatePickerValue,
    showMoneyInAppFormat,
    truncateString,
} from "../../../../../utils/helpers";
import {
    BillPaymentType,
    IAddBulkInvoicePayment,
    IAddBulkInvoicePaymentResponse,
    RemarkCodeDescription,
    RemarkCodes,
    WriteOffCodes,
    WriteOffCodesDecription,
} from "../../../types/billing.types";
import Skeleton from "../../../../../components/Skeleton/Skeleton";
import ListState from "../../../../../components/ListState/ListState";
import { useAddBulkInvoicePayment } from "../../../hooks/billing.mutations";
import useToast from "../../../../../hooks/useToast";
import { LISTS_DEFAULT_LIMIT } from "../../../../../utils/constants";

interface AddBulkPaymentFormProps {
    // what type of bulk payment are we making, we should be able
    // to extend this for other bulk payment type
    bulk_type?: "writeoff";
    // function to call when the form has been submitted.
    onFormSubmit(): void;
}

type Options = {
    label: string;
    value: string;
};

const schema = yup.object({
    invoices: yup.array().of(
        yup.object().shape({
            amount: yup
                .string()
                .matches(
                    /^(?!^\.)(\d+(\.\d+)?|\.\d+)$/,
                    "Please enter a valid amount"
                ),
            invoice_id: yup.string().required("Invoice ID is required"),
        })
    ),
    payment_type: yup.string().required("Payment type is required"),
    payment_date: yup.date().required("Payment date is required"),
    payment_notes: yup.string(),
    writeoff_codes: yup.string(),
    remark_code: yup.string(),
    writeoff_comments: yup.string(),
    auth_code: yup.string(),
    payment_description: yup.string().nullable(),
    cheque_number: yup.string(),
});

export default function AddBulkPaymentForm({
    onFormSubmit,
}: AddBulkPaymentFormProps) {
    const [paymentSource, setPaymentSource] = useState("insurance");
    const [paymentDate, setPaymentDate] = useState<Date | null>(new Date());

    const { clientId } = useParams();

    const { data, isLoading, error } = useFetchInvoices({
        client_id: clientId,
        payment_view: true,
        has_remits: paymentSource === "insurance",
        limit: LISTS_DEFAULT_LIMIT,
    });

    const addBulkInvoicePayment = useAddBulkInvoicePayment();

    const { toast } = useToast();

    const queryClient = useQueryClient();

    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<IAddBulkInvoicePayment>({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: {
            payment_type:
                paymentSource === "insurance"
                    ? BillPaymentType.WBI
                    : BillPaymentType.WBCI,
        },
    });

    const paymentSourceSelectOptions: Options[] = [
        {
            label: "Insurance",
            value: "insurance",
        },
        {
            label: "Co insurance",
            value: "co_insurance",
        },
    ];

    const writeOffCodeSelectOptions: Options[] = [
        ...Object.values(WriteOffCodes).map((item) => ({
            value: item,
            label: WriteOffCodesDecription[item],
        })),
    ];

    const remarkCodeForSelectOptions: Options[] = Object.values(RemarkCodes)
        .map((item) => ({
            value: item,
            label: truncateString(
                RemarkCodeDescription[
                    item as keyof typeof RemarkCodeDescription
                ],
                60
            ),
        }))
        .sort((a, b) => a.value.localeCompare(b.value));

    const getInvoiceDetails = (invoiceId: string) => {
        const invoiceDetails = data?.data?.find(
            (invoice) => invoice.invoice_id === invoiceId
        );

        if (invoiceDetails) {
            return `${formatDate(invoiceDetails.date_of_service)} CPT: ${
                invoiceDetails.cpt_code
            }, ${
                paymentSource === "insurance"
                    ? `INS DUE:
        ${showMoneyInAppFormat(invoiceDetails.coinsurance_amount)}`
                    : `CINS DUE:
        ${showMoneyInAppFormat(invoiceDetails.coinsurance_amount)}`
            }`;
        }
        return "";
    };

    const getToastMessageToDisplay = (res: IAddBulkInvoicePaymentResponse) => {
        return (
            <div className="flex flex-col gap-y-8 leading-none !text-xss">
                {res.added_payments.length > 0 && (
                    <div className="flex flex-col gap-y-[6px]">
                        <span className="leading-none font-semibold !text-xs">
                            Added Payments
                        </span>

                        {res.added_payments.map((payment) => (
                            <div key={payment.invoice_id}>
                                {getInvoiceDetails(payment.invoice_id)}
                            </div>
                        ))}
                    </div>
                )}
                {res.unadded_payments.length > 0 && (
                    <div className="flex flex-col gap-y-[6px]">
                        <span className="leading-none font-semibold !text-xs">
                            Unadded Payments
                        </span>
                        {res.unadded_payments.map((payment) => (
                            <div
                                key={payment.invoice_id}
                                className="leading-tight"
                            >
                                {`${getInvoiceDetails(payment.invoice_id)} - ${
                                    payment.reason
                                }`}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    };

    const onSubmit = (payload: IAddBulkInvoicePayment) => {
        const invoicesWithPayment = payload.invoices.filter(
            (invoice) => invoice.payment_amount
        );

        if (invoicesWithPayment.length === 0) {
            toast({
                mode: "error",
                message: "Please enter at least one payment to writeoff",
            });
            return;
        }

        const dataToSend = {
            ...payload,
            invoices: payload.cheque_number
                ? invoicesWithPayment.map((invoice) => ({
                      invoice_id: invoice.invoice_id,
                      payment_amount: Number(invoice.payment_amount),
                      payment_info: {
                          cheque_number: payload.cheque_number || "",
                      },
                  }))
                : invoicesWithPayment.map((invoice) => ({
                      invoice_id: invoice.invoice_id,
                      payment_amount: Number(invoice.payment_amount),
                  })),
            payment_date: payload.payment_date
                ? handleFormatDatePickerValue(payload.payment_date)
                : handleFormatDatePickerValue(Date()),
            cheque_number: undefined,
            payment_type:
                paymentSource === "insurance"
                    ? BillPaymentType.WBI
                    : BillPaymentType.WBCI,
        };

        addBulkInvoicePayment.mutate(dataToSend, {
            onSuccess: async (res) => {
                await queryClient.invalidateQueries({
                    queryKey: ["invoices", { client_id: clientId }],
                });

                await queryClient.invalidateQueries({
                    queryKey: ["get-payment"],
                });
                toast({
                    mode: "success",
                    message: getToastMessageToDisplay(res.data),
                    duration: 12000,
                });

                onFormSubmit();
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not perform action at this time, please try again later",
                });
            },
        });
    };

    return (
        <div>
            <form
                id="bulk-writeoff-form"
                name="bulk-writeoff-form"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="fg">
                    <div className="p-12 rounded-r8  border-[0.2px] border-stroke max-h-[250px] overflow-y-auto">
                        {isLoading && (
                            <div className="flex flex-col gap-y-12">
                                {[1, 2, 3, 4, 5].map((item) => (
                                    <div
                                        key={item}
                                        className="flex items-center justify-between gap-x-8"
                                    >
                                        <Skeleton height={40} width="30%" />
                                        <Skeleton height={40} width="30%" />
                                    </div>
                                ))}
                            </div>
                        )}
                        {error && (
                            <ListState
                                isError
                                errorMsg="Could not load invoices at this time"
                                stateHelperText="We could not load invoices for bulk writeoff please try again later"
                            />
                        )}
                        {data?.data?.map((invoice, i) => (
                            <div
                                key={invoice.invoice_id}
                                className="flex justify-between items-center pb-12"
                            >
                                <div className="flex gap-x-8 items-center text-xs">
                                    <span>
                                        {formatDate(invoice.date_of_service)},
                                    </span>
                                    <span>
                                        CPT: {invoice.cpt_code},{" "}
                                        <span
                                            className={cn({
                                                "text-danger":
                                                    (paymentSource ===
                                                        "insurance" &&
                                                        invoice.accounting_insurance >
                                                            0) ||
                                                    (paymentSource ===
                                                        "co_insurance" &&
                                                        invoice.accounting_coinsurance >
                                                            0),
                                            })}
                                        >
                                            {paymentSource === "insurance"
                                                ? `INS DUE:
                                ${
                                    invoice.accounting_insurance > 0
                                        ? `(${showMoneyInAppFormat(
                                              invoice.accounting_insurance
                                          )})`
                                        : showMoneyInAppFormat(
                                              Math.abs(
                                                  invoice.accounting_insurance
                                              )
                                          )
                                }`
                                                : `CINS DUE:
                                ${
                                    invoice.accounting_coinsurance > 0
                                        ? `(${showMoneyInAppFormat(
                                              invoice.accounting_coinsurance
                                          )})`
                                        : showMoneyInAppFormat(
                                              Math.abs(
                                                  invoice.accounting_coinsurance
                                              )
                                          )
                                }`}
                                        </span>
                                    </span>
                                </div>

                                <div>
                                    <Input
                                        {...register(
                                            `invoices.${i}.payment_amount`
                                        )}
                                        showCurrency
                                        placeholder="Enter Amount"
                                        errorText={
                                            errors?.invoices?.[i]
                                                ?.payment_amount?.message
                                        }
                                        hasError={
                                            !!errors.invoices?.[i]
                                                ?.payment_amount
                                        }
                                    />
                                    <input
                                        type="hidden"
                                        value={invoice.invoice_id}
                                        {...register(
                                            `invoices.${i}.invoice_id`
                                        )}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="fg">
                    <Select
                        label="Source"
                        placeholder="Source"
                        defaultValue={paymentSourceSelectOptions.find(
                            (source) => source.value === paymentSource
                        )}
                        onChange={(val) =>
                            setPaymentSource((val as Options).value)
                        }
                        options={paymentSourceSelectOptions}
                    />
                </div>
                <div className="fg">
                    <Controller
                        name="payment_date"
                        control={control}
                        defaultValue={handleFormatDatePickerValue(Date())}
                        render={({ field }) => (
                            <DatePicker
                                label="Writeoff Date"
                                onChange={(date) => {
                                    field.onChange(date);
                                    setPaymentDate(date);
                                }}
                                selected={paymentDate}
                                hasError={!!errors.payment_date}
                                errorText={
                                    errors.payment_date?.type === "typeError"
                                        ? "invalid date value"
                                        : errors.payment_date?.message
                                }
                            />
                        )}
                    />
                </div>
                <div className="fg">
                    <Controller
                        name="writeoff_code"
                        control={control}
                        render={({ field }) => (
                            <Select
                                label="Write-Off Code"
                                placeholder="Write-Off Code"
                                options={writeOffCodeSelectOptions}
                                onChange={(val) =>
                                    field.onChange((val as Options).value)
                                }
                            />
                        )}
                    />
                </div>
                {paymentSource === "insurance" && (
                    <div className="fg">
                        <Controller
                            name="remark_code"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    label="Remark Code"
                                    placeholder="Remark Code"
                                    options={remarkCodeForSelectOptions}
                                    onChange={(val) =>
                                        field.onChange((val as Options).value)
                                    }
                                />
                            )}
                        />
                    </div>
                )}
                <div className="fg">
                    <TextArea
                        {...register("payment_notes")}
                        label="Notes"
                        placeholder="Notes"
                    />
                </div>
            </form>
        </div>
    );
}
