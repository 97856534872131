import cn from "classnames";
import * as React from "react";

import * as RadixDropdownMenu from "@radix-ui/react-dropdown-menu";

import styles from "./DropdownMenu.module.scss";

interface DropdownContentProps
    extends RadixDropdownMenu.DropdownMenuContentProps {
    contentClass?: string;
}

export const DropdownMenuContent = React.forwardRef<
    HTMLInputElement,
    DropdownContentProps
>(({ contentClass, children, ...props }, forwardedRef) => {
    return (
        <RadixDropdownMenu.Portal>
            <RadixDropdownMenu.Content
                {...props}
                className={cn(styles.content, contentClass)}
                ref={forwardedRef}
                align={props.align || "end"}
            >
                {children}
            </RadixDropdownMenu.Content>
        </RadixDropdownMenu.Portal>
    );
});

DropdownMenuContent.displayName = "DropdownMenuContent";

export const DropdownMenuItem = RadixDropdownMenu.Item;
const DropdownMenu = RadixDropdownMenu.Root;
export default DropdownMenu;
export const DropdownMenuTrigger = RadixDropdownMenu.Trigger;
