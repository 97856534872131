import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import React from "react";
import {
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Input,
    TextArea,
} from "@jhool-io/fe-components";
import { IGetClientNotes, ISessionNote } from "../../../../utils/types/notes";
import { useFetchClientNotesList } from "../../../../hooks/queries/note";
import {
    formatDate,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../../utils/helpers";
import LoadPreviousSessionNotificationModal from "../../LoadPreviousSessionNotificatiModal/LoadPreviousSessionNotificationModal";
import { SUPERVISION_NOTE_LABELS } from "../../../../utils/constants";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";
import NoteFormInfoBox from "../../NoteFormInfoBox/NoteFormInfoBox";

export interface IEditSupervisionNotePayload {
    supervision_note: string;
    client_progress: string;
    signature?: string;
}

interface SupervisionProps {
    noteDetails: ISessionNote;
    currentAction: string | null;
    onSubmit: (data: IEditSupervisionNotePayload) => void;
    lastSaved?: string;
}

export default function EditSupervision({
    noteDetails,
    currentAction,
    onSubmit,
}: SupervisionProps) {
    const [showPrompt, setShowPrompt] = React.useState(false);
    const [selectedNote, setSelectedNote] = React.useState(
        {} as IGetClientNotes
    );
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<IEditSupervisionNotePayload>({
        resolver: yupResolver(
            yup.object({
                supervision_note: yup.string(),
                client_progress: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup.string().required("Client progress is required"),
                    otherwise: yup.string(),
                }),
                signature: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup.string().required("Signature is required"),
                    otherwise: yup.string(),
                }),
            })
        ),
        mode: "onChange",
        defaultValues: noteDetails.note_content || undefined,
    });

    const clientNoteParams = {
        type: noteDetails?.type as string,
        load_previous_notes: true,
        provider_id: noteDetails?.provider?.provider_id as string,
    };
    const { data: notes } = useFetchClientNotesList(
        noteDetails?.client?.client_id as string,
        clientNoteParams
    );

    // Function to handle item click and set the selected note content
    const handleLoadPreviousSession = (note: IGetClientNotes) => {
        setValue("client_progress", note.note_content.client_progress);
        setValue("supervision_note", note.note_content.supervision_note);
        setShowPrompt(false);
    };

    return (
        <div>
            <LoadPreviousSessionNotificationModal
                showPrompt={showPrompt}
                setShowPrompt={setShowPrompt}
                handleLoadPreviousSession={() =>
                    handleLoadPreviousSession(selectedNote)
                }
            />

            {notes && notes.data.length > 0 && (
                <div className="flex justify-end mb-24">
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <FilterButton text="Load previous session note" />
                        </DropdownTrigger>
                        <DropdownContent width="auto" align="end">
                            {notes &&
                                notes?.data?.map((note) => (
                                    <DropdownItem
                                        key={note.note_id}
                                        onClick={() => {
                                            setShowPrompt(true);
                                            setSelectedNote(note);
                                        }}
                                    >
                                        <div>
                                            {makeStringFirstLetterCapital(
                                                removeEnumUnderscore(
                                                    note.note_type
                                                )
                                            )}{" "}
                                            - Note content Details -{" "}
                                            {formatDate(note.date_of_service)}
                                        </div>
                                    </DropdownItem>
                                ))}
                        </DropdownContent>
                    </Dropdown>
                </div>
            )}

            <form
                aria-label="edit-form"
                className="pb-[60px] md:pb-[150px]"
                onSubmit={handleSubmit(onSubmit)}
                id="edit-note-form"
            >
                <div className="mb-24">
                    <NoteFormInfoBox />
                </div>
                <div className="fg">
                    <TextArea
                        {...register("supervision_note")}
                        label={SUPERVISION_NOTE_LABELS.supervision_note}
                        placeholder={SUPERVISION_NOTE_LABELS.supervision_note}
                        defaultValue={
                            noteDetails.note_content?.supervision_note
                        }
                        onBlur={handleSubmit(onSubmit)}
                    />
                </div>
                <div className="fg">
                    <TextArea
                        {...register("client_progress")}
                        label={SUPERVISION_NOTE_LABELS.client_progress}
                        placeholder={SUPERVISION_NOTE_LABELS.client_progress}
                        hasError={!!errors.client_progress}
                        errorText={errors.client_progress?.message}
                        defaultValue={noteDetails.note_content?.client_progress}
                        isRequired
                        onBlur={handleSubmit(onSubmit)}
                    />
                </div>
                <div className="fg-info fg-line">
                    <p>Sign note here</p>
                    <div className="fg">
                        <Input
                            {...register("signature")}
                            hasError={!!errors.signature}
                            errorText={errors.signature?.message}
                            label="Provider's Initials"
                            placeholder="Provider's Initials"
                            autoComplete="off"
                            isRequired
                        />
                    </div>
                </div>
            </form>
        </div>
    );
}
