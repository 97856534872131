import * as React from "react";
import {
    IAppointmentChecks,
    defaultClinician,
} from "../../../utils/types/availiability";
import DashboardCalendar from "../../../components/DashboardCalender/DashboardCalender";
import Navbar from "../../../components/Navbar/Navbar";

export default function Schedule() {
    return (
        <>
            <Navbar title="Schedule" />
            <div className="page">
                <div className="flex flex-col gap-y-12">
                    <h3 className=" text-xxl font-extrabold  ">
                        Manage all your activities
                    </h3>
                    <p className="text-sm font-normal text-[rgba(11,_19,_43,_0.64)] mb-32">
                        You can manage your availability, reserve time and
                        time-off here on mantle
                    </p>
                </div>
                <div className="border border-[#E7E7E7] rounded-r8 h-fit bg-white">
                    <DashboardCalendar
                        schedules={defaultClinician.confirmed.flatMap(
                            (confirmedAppointment) => {
                                const appointmentId = confirmedAppointment.id;
                                const appointmentCheck = (
                                    defaultClinician.appointment_checks as IAppointmentChecks
                                )[appointmentId];
                                return {
                                    ...confirmedAppointment,
                                    appointment_check: appointmentCheck || null,
                                };
                            }
                        )}
                    />
                </div>
            </div>
        </>
    );
}
