import Client from "../pages/AppContainer/Clients/Client/Client";
import Clients from "../pages/AppContainer/Clients/Clients";
import AddNote from "../pages/AppContainer/Notes/AddNote/AddNote";
import ViewSpecificBilling from "../pages/AppContainer/BillingQueue/ViewBilling/ViewBilling";
import EditNote from "../pages/AppContainer/Notes/EditNote/EditNote";
import TasksView from "../modules/tasks/pages/Tasks";
import Schedule from "../modules/schedule/pages/Schedule";
import AuditLogView from "../modules/audit-log/pages/AuditLog";
import PracticeSettingsView from "../modules/practice-settings/pages/PracticeSettings";
import Claims from "../modules/claims/pages/Claims";
import ViewSingleClaim from "../modules/claims/pages/ViewClaims";
import BillingSummary from "../modules/billing/pages/BillingSummary";
import PrintClaim from "../modules/claims/pages/PrintClaim";
import Payers from "../modules/payers/pages/Payers";
import BatchRemits from "../modules/remits/pages/RemitsList";
import Remits from "../modules/remits/pages/RemitClaims";
import ViewSingleRemit from "../modules/remits/pages/ViewSingleRemit";
import PracticesPage from "../modules/practices/pages/Practices";
import BatchEOB from "../modules/remits/components/RemitClaims/BatchEOB/BatchEOB";
import PaymentsPage from "../modules/payments/pages/Payments";
import Bills from "../modules/billing/pages/Bills";
import UsersManagementPage from "../modules/user-management/pages/UsersManagement";
import Notes from "../pages/AppContainer/Notes/Notes";
import ProvidersList from "../modules/providers/components/ProvidersList/ProvidersList";
import ClientStatementOfAccountPage from "../modules/reports/pages/ClientStatementOfAccount";
import Provider from "../pages/Provider/Provider";
import ProviderSessionActivityPage from "../modules/reports/pages/ProviderSessionActivity";
import NoteAuditDashboardPage from "../modules/notes/pages/NoteAuditDashboardPage";
import BillingReports from "../modules/reports/pages/BillingReports";
import NotePage from "../modules/notes/pages/Note";
import ClientChartRestrictionPage from "../modules/client-chart-restriction/pages/ClientChartRestriction";
import Announcements from "../modules/announcements/pages/Announcements";
import SingleRemitEOB from "../modules/remits/components/SingleRemitEOB/SingleRemitEOB";
import FeesCalculatorView from "../modules/tools/pages/FeesCalculator";
import ThirtydaysProviders from "../modules/thirtydays-providers/pages/ThirtydaysProviders";

interface Route {
    path: string;
    component: () => React.ReactElement;
}

const routes: Route[] = [
    {
        path: "clients",
        component: Clients,
    },
    {
        path: "clients/:clientId",
        component: Client,
    },
    {
        path: "notes",
        component: Notes,
    },
    {
        path: "notes/:clientId/:noteId",
        component: NotePage,
    },
    {
        path: "notes/:noteId/:clientId/edit",
        component: EditNote,
    },
    {
        path: "notes/note-audit-dashboard",
        component: NoteAuditDashboardPage,
    },
    {
        path: "add-note",
        component: AddNote,
    },
    {
        path: "billing-summary",
        component: BillingSummary,
    },
    {
        path: "/reports/billing-reports/:clientId",
        component: ClientStatementOfAccountPage,
    },
    {
        path: "billing-and-claims",
        component: Bills,
    },
    {
        path: "billing-and-claims/:clientId/:noteId",
        component: ViewSpecificBilling,
    },
    {
        path: "billing-payments",
        component: PaymentsPage,
    },
    { path: "providers", component: ProvidersList },
    {
        path: "providers/:providerId",
        component: Provider,
    },
    {
        path: "user-management",
        component: UsersManagementPage,
    },
    {
        path: "/reports/provider-session-activity",
        component: ProviderSessionActivityPage,
    },
    {
        path: "/reports/billing-reports",
        component: BillingReports,
    },
    {
        path: "/tasks",
        component: TasksView,
    },
    {
        path: "/client-chart-restrictions",
        component: ClientChartRestrictionPage,
    },
    {
        path: "/my-schedule",
        component: Schedule,
    },
    {
        path: "/audit-log",
        component: AuditLogView,
    },
    {
        path: "/practice-settings",
        component: PracticeSettingsView,
    },
    {
        path: "/claims",
        component: Claims,
    },
    {
        path: "/claims/:claimId/:clientId/:noteId",
        component: ViewSingleClaim,
    },
    {
        path: "/remits/:remitId/:claimId/:clientId/:noteId",
        component: ViewSingleRemit,
    },
    {
        path: "/claim/:claimId/print",
        component: PrintClaim,
    },
    {
        path: "/remits",
        component: BatchRemits,
    },
    {
        path: "/remits/:remitId/claims",
        component: Remits,
    },
    {
        path: "/payers",
        component: Payers,
    },
    {
        path: "/practices",
        component: PracticesPage,
    },
    {
        path: "/announcements",
        component: Announcements,
    },
    {
        path: "/thirty-days-providers",
        component: ThirtydaysProviders,
    },
    {
        path: "/remits/:remitId/claims/EOB",
        component: BatchEOB,
    },
    {
        path: "/remit/eob/:remitId",
        component: SingleRemitEOB,
    },
    {
        path: "/tools/fees-calculator",
        component: FeesCalculatorView,
    },
];

export default routes;
