import * as React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Input, Select, Tooltip } from "@jhool-io/fe-components";
import { useParams, useSearchParams } from "react-router-dom";
import useToast from "../../../../../hooks/useToast";
import usePractice from "../../../../../hooks/usePractice";
import CopyIcon from "../../../../Icons/Copy";
import { convertArrayToString } from "../../../../../utils/helpers";
import { copyToClipboard } from "../../../../../utils/helpers/copy";
import {
    useFetchClientFees,
    useFetchClientInsuranceHistory,
} from "../../../../../hooks/queries/client";
import {
    CalculateFeesInsuranceProviders,
    ICalculateClientFee,
    ICalculateFeeResponse,
    ISubmitCalculateClientFee,
} from "../../../../../modules/tools/types/tools.types";
import { useCalculateClientFees } from "../../../../../modules/tools/hooks/mutations/tools.mutations";

type SelectOptionInsuranceProvider = {
    label: string;
    value: string;
};

// Select options for insurance providers
const insuranceProvidersSelectOptions = Object.values(
    CalculateFeesInsuranceProviders
)
    .sort((a, b) => a.localeCompare(b))
    .map((provider) => ({
        label: provider,
        value: provider,
    }));

// finding insurance providers that match
const findMatchingOption = (
    selectedProvider: string,
    options: SelectOptionInsuranceProvider[]
): SelectOptionInsuranceProvider | undefined => {
    const lowerCasedSelectedProvider = selectedProvider.toLowerCase();

    return options.find((option) =>
        lowerCasedSelectedProvider
            .split(" ")
            .some((word) => option.label.toLowerCase().includes(word))
    );
};

const schema = yup.object({
    customer_status: yup.string(),
    insurance: yup.string(),
    deductible: yup.string(),
    deductible_met: yup.string(),
    inn_coinsurance: yup.string(),
    inn_copay: yup.string(),
    oon_coinsurance: yup.string(),
    oon_copay: yup.string(),
    ucr: yup.string(),
});

export default function CalculateFees() {
    const [feeResponse, setFeeResponse] = React.useState<
        ICalculateFeeResponse[]
    >([]);
    const [searchParams] = useSearchParams();
    // Get id from url params
    const params = useParams();
    const clientId = params.clientId as string;

    // Fetch client insurance history
    const { data: insuranceProviders } = useFetchClientInsuranceHistory(
        clientId,
        1,
        50
    );
    const currentLimit = Number(searchParams.get("limit")) || 3;
    const { data } = useFetchClientFees(clientId, currentLimit);
    const userCurrentProvider = insuranceProviders?.data.filter(
        (item) => item.status === "active"
    );
    const [isFeeCopied, setIsFeeCopied] = React.useState(false);
    const [selectedInsuranceProvider, setSelectedInsuranceProvider] =
        React.useState(userCurrentProvider?.[0]?.name?.toUpperCase());

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ICalculateClientFee>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    // Hook for adding client fee
    const { mutate } = useCalculateClientFees();

    const { practice } = usePractice();
    const currentPractice = practice?.practice_name as string;

    const currentFee = data?.data?.[0];
    const deductibleToUse =
        currentPractice === "Park-Hill-Staging" ||
        currentPractice === "Park Hill"
            ? currentFee?.innded
            : currentFee?.oonded || "";

    // Toast for success and error states
    const { toast } = useToast();

    // Function for copying FEES
    const handleFeeCopied = () => {
        const copiedText = convertArrayToString([
            `Masters Fee: ${feeResponse?.[0].masters}` || "",
            `Doctoral Fee: ${feeResponse?.[0].doctoral}` || "",
        ]);
        copyToClipboard(copiedText);
        setIsFeeCopied(true);

        setTimeout(() => {
            setIsFeeCopied(false);
        }, 2000);
    };

    const onSubmit = (payload: ICalculateClientFee) => {
        const currentYear = new Date().getFullYear().toString();
        const defaultProvider =
            userCurrentProvider?.[0]?.name?.toUpperCase() || "";

        // making sure the currect matched insurance provider is sent to the backend
        const matchedOption = defaultProvider
            ? findMatchingOption(
                  defaultProvider,
                  insuranceProvidersSelectOptions
              )
            : null;

        const dataToSend: ISubmitCalculateClientFee = {
            year: currentYear,
            fee_params: [
                {
                    ...payload,
                    customer_status: "existing",
                    insurance: matchedOption?.label || "",
                },
            ],
        };

        mutate(dataToSend, {
            onSuccess: (res) => {
                toast({
                    mode: "success",
                    message: res.message || "Fees calculated successfully",
                });
                setFeeResponse(res.data);
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not calculate fees at this time",
                });
            },
        });
    };

    return (
        <div>
            <form id="calculate-fees" onSubmit={handleSubmit(onSubmit)}>
                <div className="fg fg-space-between two flex">
                    <Select
                        label="Insurance"
                        placeholder="Insurance"
                        options={insuranceProvidersSelectOptions}
                        onChange={(val) =>
                            setSelectedInsuranceProvider(
                                (val as SelectOptionInsuranceProvider)
                                    .label as string
                            )
                        }
                        value={
                            selectedInsuranceProvider
                                ? findMatchingOption(
                                      selectedInsuranceProvider,
                                      insuranceProvidersSelectOptions
                                  ) || null
                                : null
                        }
                    />
                </div>
                <div className="fg fg-space-between two flex">
                    <Input
                        {...register("deductible")}
                        label="Deductible"
                        placeholder="Deductible"
                        defaultValue={deductibleToUse as string}
                        hasError={!!errors.deductible}
                        errorText={errors.deductible?.message}
                    />
                    <Input
                        {...register("deductible_met")}
                        label="Deductible Met"
                        placeholder="Deductible Met"
                        hasError={!!errors.deductible_met}
                        errorText={errors.deductible_met?.message}
                    />
                </div>
                <div className="fg fg-space-between two flex">
                    <Input
                        {...register("inn_coinsurance")}
                        label="INNCOINS"
                        placeholder="INNCOINS"
                        defaultValue={currentFee?.inncoins || ""}
                        hasError={!!errors.inn_coinsurance}
                        errorText={errors.inn_coinsurance?.message}
                    />
                    <Input
                        {...register("inn_copay")}
                        label="INNCOPAY"
                        placeholder="INNCOPAY"
                        hasError={!!errors.inn_copay}
                        defaultValue={currentFee?.innco || ""}
                        errorText={errors.inn_copay?.message}
                    />
                </div>
                <div className="fg fg-space-between two flex">
                    <Input
                        {...register("oon_coinsurance")}
                        label="OONCOINS"
                        placeholder="OONCOINS"
                        hasError={!!errors.oon_coinsurance}
                        defaultValue={currentFee?.ooncoins || ""}
                        errorText={errors.oon_coinsurance?.message}
                    />
                    <Input
                        {...register("oon_copay")}
                        label="ONNCOPAY"
                        placeholder="ONNCOPAY"
                        hasError={!!errors.oon_copay}
                        defaultValue={currentFee?.oonco || ""}
                        errorText={errors.oon_copay?.message}
                    />
                </div>
                <div className="fg fg-space-between two flex">
                    <Input
                        {...register("ucr")}
                        label="UCR"
                        placeholder="UCR"
                        hasError={!!errors.ucr}
                        defaultValue={currentFee?.ucr || ""}
                        errorText={errors.ucr?.message}
                    />
                </div>
            </form>
            {feeResponse?.length ? (
                <div className="bg-card-bg rounded-r8 p-16 mt-24">
                    <div className="flex items-center">
                        <div className="flex flex-col gap-y-12">
                            <span className="text-sm text-gray  font-medium text-left">
                                Masters Fees:{" "}
                                <span className="text-sm text-black">
                                    {feeResponse?.[0]?.masters}
                                </span>
                            </span>
                            <span className="text-sm text-gray font-medium text-left">
                                Doctoral Fees:{" "}
                                <span className="text-sm  text-black">
                                    {feeResponse?.[0]?.doctoral}
                                </span>
                            </span>
                        </div>
                        <div className="ml-12">
                            <Tooltip
                                content={
                                    isFeeCopied ? "copied!" : "Copy result"
                                }
                            >
                                <Button
                                    onClick={handleFeeCopied}
                                    aria-label="copyInnBtn"
                                    variant="normal"
                                    size="auto"
                                    className="self-center"
                                >
                                    <CopyIcon />
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
}
