import * as React from "react";
import { UserPractice } from "../../modules/user-management/types/user-management.types";

interface UserPracticeContextProps {
    userCurrentPractice: UserPractice | null;
    handleChangeUserPractice: (
        userPractice: UserPractice | null,
        updateLocation: boolean
    ) => Promise<void>;
}

export const UserPracticeContext =
    React.createContext<UserPracticeContextProps | null>(null);
