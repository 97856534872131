import * as React from "react";
import { Button, Switch } from "@jhool-io/fe-components";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import NoteContent from "../NoteContent/NoteContent";
import Messages from "../Messages/Messages";
import SingleNoteActions from "../SingleNoteActions/SingleNoteActions";
import { UserPermisions } from "../../../../utils/types/user";
import { useFetchUserDetails } from "../../../../hooks/queries/user";
import { useFetchSessionNote } from "../../../../hooks/queries/note";
import ChangesRequested from "../ChangesRequested/ChangesRequested";
import NoteActivityList from "../NoteActivityList/NoteActivityList";
import usePractice from "../../../../hooks/usePractice";

export default function NoteItemsWrapper() {
    const [searchParams, setSearchParams] = useSearchParams();

    const location = useLocation();
    const params = useParams();

    const noteId = params.noteId as string;
    const clientId = params.clientId as string;

    const noteTabInUrl = searchParams.get("note_tab") || "content";

    const isNoteView = location.pathname.includes("/notes");

    const loggedInUser = useFetchUserDetails();

    const { practice } = usePractice();

    const handleSetTabInUrl = (noteTab: string) => {
        searchParams.set("note_tab", noteTab);
        setSearchParams(searchParams);
    };

    const { data } = useFetchSessionNote(
        clientId,
        noteId,
        Boolean(clientId) && Boolean(noteId)
    );

    const switchOptions = [
        {
            title: "Content",
            key: "content",
            onClick: () => handleSetTabInUrl("content"),
        },
        {
            title: "Messages",
            key: "messages",
            onClick: () => handleSetTabInUrl("messages"),
        },
        {
            title: "Change requested",
            key: "change_requested",
            onClick: () => handleSetTabInUrl("change_requested"),
        },
        {
            title: "Activity",
            key: "activity",
            onClick: () => handleSetTabInUrl("activity"),
        },
    ];

    const canRequestChanges =
        loggedInUser &&
        practice?.is_supervisor &&
        practice?.permissions?.includes(
            UserPermisions.CLIENT_INFO_NOTES_CHANGE_REQUEST_WRITE
        ) &&
        !data?.data.is_archived;

    return (
        <>
            <div className="flex items-center justify-between flex-wrap gap-x-8 gap-y-24">
                <div className="max-w-full overflow-x-auto">
                    <Switch
                        switchOptions={switchOptions}
                        activeSwitch={noteTabInUrl}
                    />
                </div>
                {!isNoteView ? (
                    <div className="flex items-center gap-x-16">
                        {canRequestChanges && (
                            <Button variant="secondary" mode="outline">
                                Request for changes
                            </Button>
                        )}
                        <SingleNoteActions />
                    </div>
                ) : null}
            </div>
            <div className="mt-32">
                {noteTabInUrl === "content" && <NoteContent />}
                {noteTabInUrl === "messages" && <Messages />}
                {noteTabInUrl === "change_requested" && <ChangesRequested />}
                {noteTabInUrl === "activity" && <NoteActivityList />}
            </div>
        </>
    );
}
