import axios from "../../../utils/axios";
import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";
import {
    IAddRemitClaims,
    IArchiveRemitPayload,
    IBatchRemitFilters,
    IDeleteManualBatchRemit,
    IRemitsFilters,
    IUpdateRemitStatus,
} from "../types/remits.types";

export async function fetchBatchRemits(filters: IBatchRemitFilters) {
    const response = await axios.get(`/batch-remits`, {
        params: handleRemoveEmptyParamFromQueryParams({
            ...filters,
            from_date: filters?.from_date ? filters?.from_date : null,
            to_date: filters?.to_date ? filters?.to_date : null,
        }),
    });
    return response.data;
}

export async function fetchRemitClaims(filters: IRemitsFilters) {
    const response = await axios.get(`/remits`, {
        params: handleRemoveEmptyParamFromQueryParams(filters),
    });
    return response.data;
}

export async function postPayment(paymentId: string) {
    const response = await axios.patch(`/post-payment/${paymentId}`);
    return response.data;
}

export async function updateRemitStatus(payload: IUpdateRemitStatus) {
    const response = await axios.patch(`/batch-remits`, payload);
    return response.data;
}

export async function deleteManualBatchRemit(payload: IDeleteManualBatchRemit) {
    const response = await axios.delete(`/batch-remits`, { data: payload });
    return response.data;
}

export async function fetchSingleRemitDocuments(
    remitId: string,
    sourceIsMantle?: boolean
) {
    const response = await axios.get(
        sourceIsMantle
            ? `/remit/eob/${remitId}?file_source=mantle`
            : `/remit/eob/${remitId}`
    );
    return response.data;
}

export async function addRemit(payload: FormData) {
    const response = await axios.post(`/batch-remits`, payload);
    return response.data;
}

export async function addRemitClaims(
    batchRemitId: string,
    payload: IAddRemitClaims
) {
    const response = await axios.post(`/batch-remits/${batchRemitId}`, {
        ...payload,
    });
    return response.data;
}

/** Endpoint to delet remit documents */
export async function deleteRemitDocument(
    remitId: string,
    remittanceAttachmentId: string
) {
    const response = await axios.delete(
        `/remit/eob/${remitId}/attachments/${remittanceAttachmentId}`
    );

    return response.data;
}

export async function archiveRemit(payload: IArchiveRemitPayload) {
    const response = await axios.patch(`/remit/archive`, payload);

    return response.data;
}
