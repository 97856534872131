import * as React from "react";
import cn from "classnames";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import {
    Button,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
} from "@jhool-io/fe-components";
import {
    calculateFees,
    getMessageForFees,
} from "../../utils/helpers/fees/fees";
import { ClientFeeValues, IClientFee } from "../../utils/types/client";
import Card from "../Card/Card";
import CopyIcon from "../Icons/Copy";
import InfoIcon from "../Icons/InfoIcon";
import Tooltip from "../Tooltip/Tooltip";
import styles from "./FeeStructure.module.scss";
import NavArrowUpIcon from "../Icons/NavArrowUp";
import NavArrowDownIcon from "../Icons/NavArrowDown";
import {
    convertArrayToString,
    formatDate,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../utils/helpers";
import { copyToClipboard } from "../../utils/helpers/copy";
import EditFees from "../Clients/Client/Fees/EditFees/EditFees";
import EditIcon from "../Icons/Edit";
import { SessionServiceType } from "../../utils/types/session";
import { ProviderCredential } from "../../modules/clients/types/clients.types";
import { useFetchUserDetails } from "../../hooks/queries/user";
import { useEditClientCurrentFee } from "../../hooks/mutations/client";
import useToast from "../../hooks/useToast";
import FilterButton from "../../shared-ui/Buttons/FilterButton/FilterButton";
import { useFetchPracticeSettings } from "../../modules/practice-settings/hooks/queries/practice-settings";
import useShowInsuranceOverSelfPay from "../../modules/practice-settings/hooks/useShowInsuranceOverSelfPay";

interface FeeStructureProps {
    /** Fee values */
    fee: IClientFee;
    /** Fee title */
    titleText: string;
    /** Fee title year */
    titleDate: string;
    /** Check if fee is displaying current fee */
    isCurrentFee?: boolean;
    /** Default isMaximized state for fee */
    defaultIsMaximizedValue: boolean;
    /** Show maximize button */
    showMaximizeButton: boolean;
    /** Show edit button */
    showEditButton: boolean;
}

export default function FeeStructure({
    fee,
    titleText,
    titleDate,
    isCurrentFee = false,
    defaultIsMaximizedValue = false,
    showMaximizeButton,
    showEditButton = false,
}: FeeStructureProps) {
    // Local component states
    const [isMaximised, setIsMaximised] = React.useState(
        defaultIsMaximizedValue
    );
    const [isONNCopied, setIsONNCopied] = React.useState(false);
    const [isINNCopied, setIsINNCopied] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);

    // Get id from url params
    const params = useParams();
    const clientId = params.clientId as string;

    // Get logged user details
    const loggedInUser = useFetchUserDetails();

    const showInsOverSelfPay = useShowInsuranceOverSelfPay();

    // Hook for editing current fee
    const { mutate, isLoading } = useEditClientCurrentFee(clientId, fee.fee_id);

    // Hook for fetching the fee applicable by practice
    const { data: practiceFee } = useFetchPracticeSettings({
        setting_name: "fee_type",
        category: "fees",
    });

    // Query client
    const queryClient = useQueryClient();

    // Hook for toast
    const { toast } = useToast();

    // To determine if mutation is currently running, returns 0 || 1
    const isMutatingCurrentFee = useIsMutating({
        mutationKey: ["edit-fee", clientId],
    });

    // Function for toggling display of fees breakdown
    const handleToggleBasicInfoMaximise = () => {
        setIsMaximised((state) => !state);
    };

    // Function for toggling modal display
    const handleToggleShowModal = () => {
        setShowModal((state) => !state);
    };

    // Function for copying INN FEES
    const handleINNCopied = () => {
        const copiedText = convertArrayToString([
            fee.innded || "",
            fee.innco || "",
            fee.innoop || "",
            fee.inncoins || "",
        ]);
        copyToClipboard(copiedText);
        setIsINNCopied(true);

        setTimeout(() => {
            setIsINNCopied(false);
        }, 2000);
    };

    // Function for copying 0NN FEES
    const handleONNCopied = () => {
        const copiedText = convertArrayToString([
            fee.oonded || "",
            fee.oonco || "",
            fee.oonoop || "",
            fee.ooncoins || "",
        ]);
        copyToClipboard(copiedText);
        setIsONNCopied(true);

        setTimeout(() => {
            setIsONNCopied(false);
        }, 2000);
    };

    const isMobileScreen = window.matchMedia("(max-width: 1000px)").matches;

    const hasApplicableFee = (
        credentials: ProviderCredential[],
        requestedService?: SessionServiceType[]
    ) => {
        const fees: ClientFeeValues[] = [];

        const isPsychFee =
            requestedService?.includes(
                SessionServiceType.PSYCHIATRIC_COUNSELING
            ) ||
            requestedService?.includes(
                SessionServiceType.MEDICATION_MANAGEMENT
            );

        const isTestingFee = requestedService?.includes(
            SessionServiceType.MENTAL_HEALTH_ASSESSMENT
        );

        const practiceHasINNFee =
            practiceFee &&
            practiceFee?.data.settings.length > 0 &&
            practiceFee?.data.settings[0].current_value === "INN";
        if (practiceHasINNFee) {
            fees.push(ClientFeeValues.INN_MASTERS_FEE);
        } else if (isPsychFee) {
            fees.push(ClientFeeValues.PSYCH_FEE);
        } else if (isTestingFee) {
            fees.push(ClientFeeValues.TESTING_FEE);
        } else {
            credentials.forEach((credential) => {
                switch (credential) {
                    case ProviderCredential.PSYD:
                    case ProviderCredential.PHD:
                    case ProviderCredential.MD:
                        fees.push(ClientFeeValues.DR_FEE);
                        break;
                    case ProviderCredential.PSYDA:
                    case ProviderCredential.PHDA:
                        if (ClientFeeValues.DR_FEE_A) {
                            fees.push(ClientFeeValues.DR_FEE_A);
                        } else {
                            fees.push(ClientFeeValues.DR_FEE);
                        }
                        break;
                    case ProviderCredential.PSYDB:
                    case ProviderCredential.PHDB:
                        if (ClientFeeValues.DR_FEE_B) {
                            fees.push(ClientFeeValues.DR_FEE_B);
                        } else {
                            fees.push(ClientFeeValues.DR_FEE);
                        }
                        break;
                    case ProviderCredential.PSYDC:
                    case ProviderCredential.PHDC:
                        if (ClientFeeValues.DR_FEE_C) {
                            fees.push(ClientFeeValues.DR_FEE_C);
                        } else {
                            fees.push(ClientFeeValues.DR_FEE);
                        }
                        break;
                    case ProviderCredential.INTERN:
                        fees.push(ClientFeeValues.INTERN_FEE);
                        break;
                    case ProviderCredential.LMHC:
                    case ProviderCredential.LCSW:
                        if (ClientFeeValues.LIC_FEE) {
                            fees.push(ClientFeeValues.LIC_FEE);
                        } else {
                            fees.push(ClientFeeValues.MASTERS_FEE);
                        }
                        break;
                    case ProviderCredential.LMHCA:
                    case ProviderCredential.LCSWA:
                        if (ClientFeeValues.LIC_A_FEE) {
                            fees.push(ClientFeeValues.LIC_A_FEE);
                        } else {
                            fees.push(ClientFeeValues.MASTERS_FEE);
                        }
                        break;
                    case ProviderCredential.LMHCB:
                    case ProviderCredential.LCSWB:
                        if (ClientFeeValues.LIC_B_FEE) {
                            fees.push(ClientFeeValues.LIC_B_FEE);
                        } else {
                            fees.push(ClientFeeValues.MASTERS_FEE);
                        }
                        break;
                    case ProviderCredential.LMHCC:
                    case ProviderCredential.LCSWC:
                        if (ClientFeeValues.LIC_C_FEE) {
                            fees.push(ClientFeeValues.LIC_C_FEE);
                        } else {
                            fees.push(ClientFeeValues.MASTERS_FEE);
                        }
                        break;
                    default:
                        return fees.push(ClientFeeValues.MASTERS_FEE);
                }
                return fees;
            });
        }
        return fees;
    };

    const desiredOrder = [
        "masters fee",
        "lic_fee",
        "lic_a_fee",
        "lic_b_fee",
        "lic_c_fee",
        "intern fee",
        "drs fee",
        "dr_fee_a",
        "dr_fee_b",
        "dr_fee_c",
        "psych fee",
        "testing fee",
    ];

    const rearrangeFees = (
        fees: {
            type: ClientFeeValues;
            value: string;
            fee_value_id: string;
        }[]
    ) => {
        const orderMap: { [key: string]: number } = {};
        desiredOrder.forEach((type, index) => {
            orderMap[type] = index;
        });

        return fees.sort((a, b) => {
            return orderMap[a.type] - orderMap[b.type];
        });
    };

    const handleUpdateFee = (obj: {
        selfPay?: boolean;
        acceptsAssignment?: boolean;
        altEmailSent?: boolean;
    }) => {
        const dataToSend = {
            ...fee,
            author: loggedInUser.data?.user_id,
            self_pay:
                obj.selfPay !== undefined
                    ? obj.selfPay
                    : fee.self_pay ?? undefined,
            accepts_assignment:
                obj.acceptsAssignment !== undefined
                    ? obj.acceptsAssignment
                    : fee.accepts_assignment ?? undefined,
            alt_email_sent:
                obj.altEmailSent !== undefined
                    ? obj.altEmailSent
                    : fee.alt_email_sent ?? undefined,
        };

        mutate(dataToSend, {
            onSuccess: (res) => {
                queryClient.invalidateQueries({
                    queryKey: [clientId, "fees"],
                });
                toast({
                    mode: "success",
                    message: res.message || "Current fee edited successfully",
                });
            },
            onError: (error) => {
                toast({
                    mode: "error",
                    message:
                        error.response?.data.message ||
                        "Could not edit current fee at this time",
                });
            },
        });
    };

    return (
        <>
            <Dialog open={showModal}>
                <DialogContent
                    title="Edit Fees"
                    handleCloseDialog={handleToggleShowModal}
                    showFooter
                    saveText="Save Changes"
                    submitBtnFormValue="edit-fees"
                    isSubmitBtnDisabled={isMutatingCurrentFee > 0}
                    isCancelBtnDisabled={isMutatingCurrentFee > 0}
                    variant="center"
                >
                    <EditFees
                        onFormSubmit={handleToggleShowModal}
                        clientFee={fee}
                    />
                </DialogContent>
            </Dialog>

            <Card type="dashboard" className={styles.fee_card}>
                <div className={styles.feenav}>
                    <h3
                        className={cn(styles.feeheader, {
                            [styles.feeheader_current]: isCurrentFee,
                        })}
                    >
                        <span>{titleText}</span> - <span>{titleDate}</span>
                    </h3>
                    {isMaximised && showEditButton && (
                        <div>
                            <Button
                                ariaL-label="edit button"
                                onClick={handleToggleShowModal}
                                className="md:flex hidden"
                            >
                                Edit
                            </Button>
                            <Button
                                aria-label="edit button"
                                variant="normal"
                                size="auto"
                                className="size-[32px] flex items-center justify-center rounded-full md:hidden"
                                onClick={handleToggleShowModal}
                            >
                                <EditIcon />
                            </Button>
                        </div>
                    )}
                    {showMaximizeButton && (
                        <Button
                            aria-label="maximize button"
                            type="button"
                            size="auto"
                            onClick={handleToggleBasicInfoMaximise}
                            variant="normal"
                            className="absolute top-0 right-0 size-24 rounded-r6 border border-[#b8bec5] bg-[#b9baa33f]"
                        >
                            {isMaximised ? (
                                <NavArrowUpIcon />
                            ) : (
                                <NavArrowDownIcon />
                            )}
                        </Button>
                    )}
                </div>
                {isMaximised && (
                    <>
                        <div className={styles.feesgrid}>
                            {rearrangeFees(fee.fee_values).map((item) => {
                                const feesCalculated = calculateFees(
                                    item.value
                                );

                                const hideToolTip = Boolean(
                                    feesCalculated &&
                                        feesCalculated.singleFee &&
                                        !feesCalculated.tbdFee &&
                                        !feesCalculated.tempFee &&
                                        !feesCalculated.tempNoOfSessions
                                );

                                return hideToolTip ? (
                                    <div
                                        className={cn(styles.feecode, {
                                            [styles.feecode_applicable]:
                                                isCurrentFee &&
                                                hasApplicableFee(
                                                    fee.providers.map(
                                                        (provider) =>
                                                            provider.credential
                                                    ),
                                                    fee.providers.map(
                                                        (provider) =>
                                                            provider.service
                                                    )
                                                ).includes(item.type),
                                        })}
                                        key={item.type}
                                    >
                                        <span
                                            className={cn(
                                                styles.feetop,
                                                {
                                                    [styles.feetop_applicable]:
                                                        isCurrentFee &&
                                                        hasApplicableFee(
                                                            fee.providers.map(
                                                                (provider) =>
                                                                    provider.credential
                                                            ),
                                                            fee.providers.map(
                                                                (provider) =>
                                                                    provider.service
                                                            )
                                                        ).includes(item.type),
                                                },
                                                "fs-exclude"
                                            )}
                                        >
                                            {makeStringFirstLetterCapital(
                                                removeEnumUnderscore(item.type)
                                            )}
                                        </span>
                                        <span
                                            className={cn(styles.feebottom, {
                                                [styles.feebottom_applicable]:
                                                    isCurrentFee &&
                                                    hasApplicableFee(
                                                        fee.providers.map(
                                                            (provider) =>
                                                                provider.credential
                                                        ),
                                                        fee.providers.map(
                                                            (provider) =>
                                                                provider.service
                                                        )
                                                    ).includes(item.type),
                                            })}
                                        >
                                            {item.value || "--"}
                                        </span>
                                    </div>
                                ) : (
                                    <div
                                        className={cn(styles.feecode, {
                                            [styles.feecode_applicable]:
                                                isCurrentFee &&
                                                hasApplicableFee(
                                                    fee.providers.map(
                                                        (provider) =>
                                                            provider.credential
                                                    ),
                                                    fee.providers.map(
                                                        (provider) =>
                                                            provider.service
                                                    )
                                                ).includes(item.type),
                                        })}
                                        key={item.type}
                                    >
                                        <span
                                            className={cn(
                                                styles.feetop,
                                                {
                                                    [styles.feetop_applicable]:
                                                        isCurrentFee &&
                                                        hasApplicableFee(
                                                            fee.providers.map(
                                                                (provider) =>
                                                                    provider.credential
                                                            ),
                                                            fee.providers.map(
                                                                (provider) =>
                                                                    provider.service
                                                            )
                                                        ).includes(item.type),
                                                },
                                                "fs-exclude"
                                            )}
                                        >
                                            {makeStringFirstLetterCapital(
                                                removeEnumUnderscore(item.type)
                                            )}
                                        </span>
                                        <span
                                            className={cn(styles.feebottom, {
                                                [styles.feebottom_applicable]:
                                                    isCurrentFee &&
                                                    hasApplicableFee(
                                                        fee.providers.map(
                                                            (provider) =>
                                                                provider.credential
                                                        ),
                                                        fee.providers.map(
                                                            (provider) =>
                                                                provider.service
                                                        )
                                                    ).includes(item.type),
                                            })}
                                        >
                                            {item.value || "--"}
                                            {item.value && (
                                                <Tooltip
                                                    trigger={
                                                        isMobileScreen
                                                            ? "click"
                                                            : "hover"
                                                    }
                                                    content={getMessageForFees(
                                                        item.value
                                                    )}
                                                    showArrow
                                                    key={item.type}
                                                >
                                                    <Button
                                                        size="auto"
                                                        variant="normal"
                                                        aria-label="info icon"
                                                    >
                                                        <InfoIcon />
                                                    </Button>
                                                </Tooltip>
                                            )}
                                        </span>
                                    </div>
                                );
                            })}

                            <div className={styles.feecode}>
                                <span
                                    className={cn(styles.feetop, "fs-exclude")}
                                >
                                    UCR
                                </span>
                                <span className={styles.feebottom}>
                                    {fee.ucr || "--"}
                                </span>
                            </div>
                        </div>
                        <div
                            className={`${styles.feesinfo} ${styles.feesinfo_bottom}`}
                        >
                            <div className={styles.feecode}>
                                <span
                                    className={cn(styles.feetop, "fs-exclude")}
                                >
                                    DATE CALCULATED
                                </span>
                                <span className={styles.feebottom}>
                                    {formatDate(fee.created_at) || "--"}
                                </span>
                            </div>

                            {!showInsOverSelfPay && (
                                <div className={styles.feecode}>
                                    <span
                                        className={cn(
                                            styles.feetop,
                                            "fs-exclude"
                                        )}
                                    >
                                        SELF PAY
                                    </span>
                                    {!isCurrentFee ? (
                                        <span
                                            className={cn(styles.feebottom, {
                                                [styles.feebottom_pri]:
                                                    fee.self_pay,
                                                [styles.feebottom_red]:
                                                    !fee.self_pay,
                                            })}
                                        >
                                            {fee.self_pay ? "Yes" : "No"}
                                        </span>
                                    ) : (
                                        <Dropdown>
                                            <DropdownTrigger asChild>
                                                <FilterButton
                                                    text={
                                                        fee.self_pay
                                                            ? "Yes"
                                                            : "No"
                                                    }
                                                    className={cn(
                                                        "justify-start gap-x-4",
                                                        {
                                                            " text-primary":
                                                                fee.self_pay,
                                                            " text-danger":
                                                                !fee.self_pay,
                                                        }
                                                    )}
                                                />
                                            </DropdownTrigger>
                                            <DropdownContent
                                                width="auto"
                                                align="end"
                                            >
                                                <DropdownItem
                                                    onClick={() =>
                                                        handleUpdateFee({
                                                            selfPay: true,
                                                        })
                                                    }
                                                    disabled={isLoading}
                                                >
                                                    Yes
                                                </DropdownItem>
                                                <DropdownItem
                                                    onClick={() =>
                                                        handleUpdateFee({
                                                            selfPay: false,
                                                        })
                                                    }
                                                    disabled={isLoading}
                                                >
                                                    No
                                                </DropdownItem>
                                            </DropdownContent>
                                        </Dropdown>
                                    )}
                                </div>
                            )}
                            <div className={styles.feecode}>
                                <span
                                    className={cn(styles.feetop, "fs-exclude")}
                                >
                                    ACCEPTS ASSIGNMENT
                                </span>
                                {!isCurrentFee ? (
                                    <span
                                        className={cn(styles.feebottom, {
                                            [styles.feebottom_pri]:
                                                fee.accepts_assignment,
                                            [styles.feebottom_red]:
                                                !fee.accepts_assignment,
                                        })}
                                    >
                                        {fee.accepts_assignment ? "Yes" : "No"}
                                    </span>
                                ) : (
                                    <Dropdown>
                                        <DropdownTrigger asChild>
                                            <FilterButton
                                                text={
                                                    fee.accepts_assignment
                                                        ? "Yes"
                                                        : "No"
                                                }
                                                className={cn(
                                                    "justify-start gap-x-4",
                                                    {
                                                        " text-primary":
                                                            fee.accepts_assignment,
                                                        " text-danger":
                                                            !fee.accepts_assignment,
                                                    }
                                                )}
                                            />
                                        </DropdownTrigger>
                                        <DropdownContent width="auto">
                                            <DropdownItem
                                                onClick={() =>
                                                    handleUpdateFee({
                                                        acceptsAssignment: true,
                                                    })
                                                }
                                                disabled={isLoading}
                                            >
                                                Yes
                                            </DropdownItem>
                                            <DropdownItem
                                                onClick={() =>
                                                    handleUpdateFee({
                                                        acceptsAssignment:
                                                            false,
                                                    })
                                                }
                                                disabled={isLoading}
                                            >
                                                No
                                            </DropdownItem>
                                        </DropdownContent>
                                    </Dropdown>
                                )}
                            </div>
                            {!showInsOverSelfPay && (
                                <div className={styles.feecode}>
                                    <span
                                        className={cn(
                                            styles.feetop,
                                            "fs-exclude"
                                        )}
                                    >
                                        ALT EMAIL SENT
                                    </span>
                                    {!isCurrentFee ? (
                                        <span
                                            className={cn(styles.feebottom, {
                                                [styles.feebottom_pri]:
                                                    fee.alt_email_sent,
                                                [styles.feebottom_red]:
                                                    !fee.alt_email_sent,
                                            })}
                                        >
                                            {fee.alt_email_sent ? "Yes" : "No"}
                                        </span>
                                    ) : (
                                        <Dropdown>
                                            <DropdownTrigger asChild>
                                                <FilterButton
                                                    text={
                                                        fee.alt_email_sent
                                                            ? "Yes"
                                                            : "No"
                                                    }
                                                    className={cn(
                                                        "justify-start gap-x-4",
                                                        {
                                                            " text-primary":
                                                                fee.alt_email_sent,
                                                            " text-danger":
                                                                !fee.alt_email_sent,
                                                        }
                                                    )}
                                                />
                                            </DropdownTrigger>
                                            <DropdownContent width="auto">
                                                <DropdownItem
                                                    onClick={() =>
                                                        handleUpdateFee({
                                                            altEmailSent: true,
                                                        })
                                                    }
                                                    disabled={isLoading}
                                                >
                                                    Yes
                                                </DropdownItem>
                                                <DropdownItem
                                                    onClick={() =>
                                                        handleUpdateFee({
                                                            altEmailSent: false,
                                                        })
                                                    }
                                                    disabled={isLoading}
                                                >
                                                    No
                                                </DropdownItem>
                                            </DropdownContent>
                                        </Dropdown>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className={styles.mainfeescode}>
                            <div className={styles.middle}>
                                <div className={styles.feecode}>
                                    <span
                                        className={cn(
                                            styles.feetop,
                                            "fs-exclude"
                                        )}
                                    >
                                        INNDED
                                    </span>
                                    <span className={styles.feebottom}>
                                        {fee.innded || "--"}
                                    </span>
                                </div>

                                <div className={styles.feecode}>
                                    <span
                                        className={cn(
                                            styles.feetop,
                                            "fs-exclude"
                                        )}
                                    >
                                        INNCO
                                    </span>
                                    <span className={styles.feebottom}>
                                        {fee.innco || "--"}
                                    </span>
                                </div>
                                <div className={styles.feecode}>
                                    <span
                                        className={cn(
                                            styles.feetop,
                                            "fs-exclude"
                                        )}
                                    >
                                        INNOOP
                                    </span>
                                    <span className={styles.feebottom}>
                                        {fee.innoop || "--"}
                                    </span>
                                </div>
                                <div className={styles.feecode}>
                                    <span
                                        className={cn(
                                            styles.feetop,
                                            "fs-exclude"
                                        )}
                                    >
                                        INNCOINS
                                    </span>
                                    <span className={styles.feebottom}>
                                        {fee.inncoins || "--"}
                                    </span>
                                </div>

                                <Tooltip
                                    trigger="hover"
                                    content={
                                        <span
                                            className={cn(styles.copytext, {
                                                [styles.copytext_active]:
                                                    isINNCopied,
                                            })}
                                        >
                                            {isINNCopied ? "copied!" : "copy"}
                                        </span>
                                    }
                                    showArrow
                                >
                                    <Button
                                        onClick={handleINNCopied}
                                        aria-label="copyInnBtn"
                                        variant="normal"
                                        size="auto"
                                        className="self-center"
                                    >
                                        <CopyIcon />
                                    </Button>
                                </Tooltip>
                            </div>

                            <div className={styles.middle}>
                                <div className={styles.feecode}>
                                    <span
                                        className={cn(
                                            styles.feetop,
                                            "fs-exclude"
                                        )}
                                    >
                                        ONNDED
                                    </span>
                                    <span className={styles.feebottom}>
                                        {fee.oonded || "--"}
                                    </span>
                                </div>
                                <div className={styles.feecode}>
                                    <span
                                        className={cn(
                                            styles.feetop,
                                            "fs-exclude"
                                        )}
                                    >
                                        ONNCO
                                    </span>
                                    <span className={styles.feebottom}>
                                        {fee.oonco || "--"}
                                    </span>
                                </div>
                                <div className={styles.feecode}>
                                    <span
                                        className={cn(
                                            styles.feetop,
                                            "fs-exclude"
                                        )}
                                    >
                                        OONOOP
                                    </span>
                                    <span className={styles.feebottom}>
                                        {fee.oonoop || "--"}
                                    </span>
                                </div>
                                <div className={styles.feecode}>
                                    <span
                                        className={cn(
                                            styles.feetop,
                                            "fs-exclude"
                                        )}
                                    >
                                        ONNCOINS
                                    </span>
                                    <span className={styles.feebottom}>
                                        {fee.ooncoins || "--"}
                                    </span>
                                </div>
                                <Tooltip
                                    trigger="hover"
                                    content={
                                        <span
                                            className={cn(styles.copytext, {
                                                [styles.copytext_active]:
                                                    isONNCopied,
                                            })}
                                        >
                                            {isONNCopied ? "copied!" : "copy"}
                                        </span>
                                    }
                                    showArrow
                                >
                                    <Button
                                        onClick={handleONNCopied}
                                        aria-label="copyOnnBtn"
                                        variant="normal"
                                        size="auto"
                                        className="self-center"
                                    >
                                        <CopyIcon />
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                    </>
                )}
            </Card>
        </>
    );
}
