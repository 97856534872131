import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { IApiResponse } from "../../../utils/types/api-response";
import {
    IBulkCreateClaim,
    IBulkCreateClaimResponse,
    IBulkUpdateClaims,
    ICreateDraft,
    ICreateDraftResponse,
    IUpdateClaimContent,
    IUpdateClaimStatus,
} from "../types/claims.types";
import {
    bulkCreateClaims,
    bulkUpdateClaims,
    checkClaimStatus,
    createDraftClaim,
    deleteClaims,
    updateClaimContent,
    updateClaimStatus,
} from "../services/claims.services";

export function useUpdateClaimStatus() {
    return useMutation<
        IApiResponse<IUpdateClaimStatus>,
        AxiosError<IApiResponse<IUpdateClaimStatus>>,
        IUpdateClaimStatus
    >({
        mutationKey: ["update_claim_status"],
        mutationFn: (payload: IUpdateClaimStatus) => updateClaimStatus(payload),
    });
}

export function useCreateDraftClaim() {
    return useMutation<
        IApiResponse<ICreateDraftResponse>,
        AxiosError<IApiResponse<ICreateDraftResponse>>,
        ICreateDraft
    >({
        mutationKey: ["create_draft_claim"],
        mutationFn: (payload: ICreateDraft) => createDraftClaim(payload),
    });
}

export function useBulkCreateClaim() {
    return useMutation<
        IApiResponse<IBulkCreateClaimResponse>,
        AxiosError<IApiResponse<IBulkCreateClaimResponse>>,
        IBulkCreateClaim
    >({
        mutationKey: ["bulk_create_claim"],
        mutationFn: (payload: IBulkCreateClaim) => bulkCreateClaims(payload),
    });
}

export function useBulkUpdateClaims() {
    return useMutation<
        IApiResponse<IBulkUpdateClaims>,
        AxiosError<IApiResponse<IBulkUpdateClaims>>,
        IBulkUpdateClaims
    >({
        mutationKey: ["bulk-update-claims"],
        mutationFn: (payload: IBulkUpdateClaims) => bulkUpdateClaims(payload),
    });
}

export function useDeleteClaims() {
    interface IDeleteClaimsResponse {
        deleted_claim_ids: string[];
        undeleted_claim_ids: string[];
    }

    return useMutation<
        IApiResponse<IDeleteClaimsResponse>,
        AxiosError<IApiResponse<IDeleteClaimsResponse>>,
        string[]
    >({
        mutationKey: ["delete-claims"],
        mutationFn: (claimIds: string[]) => deleteClaims(claimIds),
    });
}

export function useCheckClaimStatus() {
    return useMutation<
        IApiResponse<{ message: string }>,
        AxiosError<IApiResponse<{ message: string }>>,
        string[]
    >({
        mutationKey: ["check-claim-status"],
        mutationFn: (claimIds: string[]) => checkClaimStatus(claimIds),
    });
}

export function useUpdateClaimContent() {
    return useMutation<
        IApiResponse<{ message: string }>,
        AxiosError<IApiResponse<{ message: string }>>,
        IUpdateClaimContent
    >({
        mutationKey: ["update-claim-content"],
        mutationFn: (payload) => updateClaimContent(payload),
    });
}
