import * as React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { DatePicker, Input, Select, TextArea } from "@jhool-io/fe-components";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { FormatOptionLabelMeta } from "react-select";
import useToast from "../../../../hooks/useToast";
import {
    IUpdatePaymentWriteoff,
    WriteOffCodes,
    WriteOffCodesDecription,
} from "../../../../utils/types/billing";
import { useUpdatePaymentWriteoff } from "../../../../hooks/mutations/billing";
import {
    formatDate,
    handleFormatDatePickerValue,
    truncateString,
} from "../../../../utils/helpers";
import {
    RemarkCodeDescription,
    RemarkCodes,
} from "../../../billing/types/billing.types";
import { usePostPayment } from "../../hooks/remits.mutations";

type Options = {
    label: React.ReactNode;
    value: string | undefined;
};

export interface ISelectedWriteoff {
    payment_id: string;
    date_posted: string;
    payment_date: string;
    amount: number;
    payment_notes: string;
    writeoff_code: WriteOffCodes;
    payment_type: string;
    remark_code: string;
    payment_status: string;
}

interface UpdateWriteoffProps {
    /** function to call when the form's submit button is clicked */
    onFormSubmit(): void;
    /** the selected payment */
    selectedWriteOff: ISelectedWriteoff;
}

const writeOffCodesForSelect: Options[] = [
    ...Object.values(WriteOffCodes).map((item) => ({
        value: item,
        label: WriteOffCodesDecription[item],
    })),
];

const remarkCodeForSelect: Options[] = Object.values(RemarkCodes)
    .map((item) => ({
        value: item, // Use the enum value as the option value
        label: RemarkCodeDescription[
            item as keyof typeof RemarkCodeDescription
        ],
        // Use this for the description
    }))
    .sort((a, b) => a.value.localeCompare(b.value));

export default function UpdateWriteoff({
    onFormSubmit,
    selectedWriteOff,
}: UpdateWriteoffProps) {
    const { toast } = useToast();
    const { noteId } = useParams();
    const queryClient = useQueryClient();

    const {
        register,
        control,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<IUpdatePaymentWriteoff>({
        resolver: yupResolver(
            yup.object().shape({
                amount: yup.number().min(1),
                payment_date: yup.string(),
                writeoff_code: yup.string(),
                payment_notes: yup.string(),
            })
        ),
        mode: "onChange",
        defaultValues: {
            amount: selectedWriteOff.amount,
            payment_date: formatDate(selectedWriteOff.payment_date, true),
            writeoff_code: writeOffCodesForSelect.find(
                (code) => code.value === selectedWriteOff.writeoff_code
            )?.value,
            payment_notes: selectedWriteOff.payment_notes || "",
            remark_code: remarkCodeForSelect.find(
                (code) => code.value === selectedWriteOff.remark_code
            )?.value,
        },
    });

    const selectedDate = watch("payment_date");
    const selectedWriteoffCode = watch("writeoff_code");

    const updateWriteoff = useUpdatePaymentWriteoff();

    const postPayment = usePostPayment(selectedWriteOff.payment_id);

    const onSubmit = (data: IUpdatePaymentWriteoff) => {
        const payload = {
            ...data,
            payment_id: selectedWriteOff.payment_id,
            payment_date: handleFormatDatePickerValue(selectedDate),
            payment_notes: data.payment_notes || undefined,
            remark_code: data.remark_code,
        };

        updateWriteoff.mutate(payload, {
            onSuccess: () => {
                if (selectedWriteOff.payment_status === "pending") {
                    postPayment.mutate(payload.payment_id, {
                        onError() {
                            toast({
                                mode: "success",
                                message:
                                    "Payment updated successfully.Could not confirm payment.",
                            });
                        },
                    });
                } else {
                    toast({
                        mode: "success",
                        message: "Payment updated successfully.",
                    });
                }

                queryClient.invalidateQueries({
                    queryKey: ["get-payment"],
                });

                queryClient.invalidateQueries({
                    queryKey: [
                        "remit-claims",
                        {
                            note_id: noteId,
                        },
                    ],
                });

                onFormSubmit();
            },

            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Error updating writeoff please try again later",
                });
            },
        });
    };

    return (
        <form
            id="update-writeoff"
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-32"
        >
            <Input
                disabled
                label="Date of Service"
                value={
                    selectedWriteOff.date_posted
                        ? formatDate(selectedWriteOff.date_posted)
                        : ""
                }
            />

            <Input label="Payment Type" disabled value="Insurance" />

            <Input
                {...register("amount")}
                label="Writeoff amount($)"
                placeholder="Confirm writeoff amount($)"
                hasError={!!errors.amount}
                errorText={
                    errors.amount?.type === "typeError"
                        ? "This field must be a number"
                        : "Amount must not be less than 1"
                }
                showCurrency
            />

            <Controller
                name="payment_date"
                control={control}
                render={({ field }) => (
                    <DatePicker
                        label="Writeoff Date"
                        onChange={(date) => field.onChange(date)}
                        selected={
                            selectedDate ? new Date(selectedDate) : new Date()
                        }
                        hasError={!!errors.payment_date}
                        errorText={
                            errors.payment_date?.type === "typeError"
                                ? "Invalid date value"
                                : errors.payment_date?.message
                        }
                    />
                )}
            />

            <Controller
                name="writeoff_code"
                control={control}
                render={({ field }) => (
                    <Select
                        label="Writeoff Code"
                        placeholder="Write-Off Code"
                        wrapperClass="select"
                        options={writeOffCodesForSelect}
                        onChange={(val) =>
                            field.onChange((val as Options).value)
                        }
                        value={{
                            label: WriteOffCodesDecription[
                                selectedWriteoffCode as WriteOffCodes
                            ],
                            value: selectedWriteoffCode,
                        }}
                        hasError={!!errors.writeoff_code}
                        errorText={errors.writeoff_code?.message}
                    />
                )}
            />

            {selectedWriteOff.payment_type === "wbi" && (
                <Controller
                    name="remark_code"
                    control={control}
                    defaultValue={
                        remarkCodeForSelect.find(
                            (option) =>
                                option.value === selectedWriteOff.remark_code
                        )?.value
                    }
                    render={({ field }) => (
                        <Select
                            label="Remark Code"
                            placeholder="Remark Code"
                            options={remarkCodeForSelect}
                            onChange={(val) => {
                                field.onChange((val as Options).value);
                            }}
                            formatOptionLabel={(
                                data: unknown,
                                formatOptionLabelMeta: FormatOptionLabelMeta<unknown>
                            ) => {
                                return formatOptionLabelMeta.context === "menu"
                                    ? (data as Options).label
                                    : truncateString(
                                          (data as Options).label as string,
                                          60
                                      );
                            }}
                            defaultValue={remarkCodeForSelect.find(
                                (option) =>
                                    option.value ===
                                    selectedWriteOff.remark_code
                            )}
                        />
                    )}
                />
            )}

            <div className="fg">
                <TextArea
                    {...register("payment_notes")}
                    label="Notes"
                    placeholder="Notes"
                    hasError={!!errors.payment_notes}
                    errorText={errors.payment_notes?.message}
                />
            </div>
        </form>
    );
}
