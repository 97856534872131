import axios from "../utils/axios";
import { handleRemoveEmptyParamFromQueryParams } from "../utils/helpers";
import { IExportPdf } from "../utils/types";

// Fetch all diagnosis codes
export async function fetchDiagnosisCodes() {
    const response = await axios.get(`/diagnosis/diagnosis_codes`);
    return response.data;
}

// Fetch all provider's sessions
export async function fetchProviderSessions() {
    const response = await axios.get(`/clients/sessions`);
    return response.data;
}

// Fetch single session details
export async function fetchSingleSessionDetails(sessionHistoryId: string) {
    const response = await axios.get(
        `/session_history?session_history_id=${sessionHistoryId}`
    );
    return response.data;
}

// Get uploaded document buffer
export async function fetchDocument(fileUrl: string) {
    const response = await axios.get(`/file?file_url=${fileUrl}`);
    return response.data;
}

// Export/download zipped file
export async function exportPdfs(params: IExportPdf) {
    const response = await axios.get("/pdf/export", {
        params: handleRemoveEmptyParamFromQueryParams(params),
        responseType: "blob",
    });
    return response.data;
}
