import {
    Button,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    SearchInput,
    Switch,
} from "@jhool-io/fe-components";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { APP_COLORS } from "../../utils/constants";
import ChevronDownIcon from "../Icons/ChevronDown";
import DashboardDayView from "./DayView/DayView";
import DashboardCaseloadView from "./CaseloadView/CaseloadView";
import DashboardWeekView from "./WeekView/WeekView";
import { CalenderEvents } from "../../utils/types/availiability";
import NavigateLeft from "../Icons/NavigateL";
import NavigateRight from "../Icons/NavigateR";
import moment from "../../utils/moment";
import {
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    truncateString,
} from "../../utils/helpers";
import CheckPrimaryColorIcon from "../Icons/CheckPrimaryColor";

export default function DashboardCalendar({
    schedules,
}: {
    schedules: CalenderEvents[];
}) {
    const [searchParams, setSearchParams] = useSearchParams();

    const clientFilter = searchParams.get("search_string") || "";

    const filter = searchParams.get("filter");

    const currentView = searchParams.get("view") || "caseload";

    const todayDate = moment().format("Do, MMM");

    const handleCurrentView = (view: string) => {
        searchParams.set("view", view);
        setSearchParams(searchParams);
    };

    const handleSetSearchString = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") searchParams.delete("search_string");
        else searchParams.set("search_string", e.target.value);

        setSearchParams(searchParams);
    };

    const handleSetFilter = (filterType: string) => {
        if (filterType === "") searchParams.delete("filter");
        else searchParams.set("filter", filterType);

        setSearchParams(searchParams);
    };

    return (
        <div className="rounded-r8 bg-white">
            <div className="py-12 px-32 border-b border-b-strokelight flex items-center gap-x-16 flex-wrap justify-between gap-y-16 overflow-x-auto">
                <SearchInput
                    placeholder="Search by client name or chart number"
                    containerClass="rounded-r8 h-[32px] !w-[350px] max-w-[350px]"
                    defaultValue={clientFilter}
                    onChange={handleSetSearchString}
                />
                <div className="flex gap-x-16 items-center flex-wrap gap-y-16">
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <Button
                                variant="normal"
                                size="auto"
                                iconRight={
                                    <ChevronDownIcon
                                        stroke={APP_COLORS.COLOR_GRAY}
                                    />
                                }
                                className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium"
                            >
                                {filter
                                    ? makeStringFirstLetterCapital(
                                          truncateString(
                                              removeEnumUnderscore(filter)
                                          )
                                      )
                                    : "Show All Clients"}
                            </Button>
                        </DropdownTrigger>
                        <DropdownContent width="auto" align="end">
                            <DropdownItem
                                className="capitalize flex gap-x-8 items-center text-xs font-medium"
                                onClick={() => handleSetFilter("")}
                            >
                                All clients
                                {filter === "" ? (
                                    <CheckPrimaryColorIcon />
                                ) : null}
                            </DropdownItem>
                            <DropdownItem
                                className="capitalize flex gap-x-8 items-center text-xs font-medium"
                                onClick={() =>
                                    handleSetFilter("clients_with_pending_task")
                                }
                            >
                                Clients with pending tasks
                                {filter === "clients_with_pending_task" ? (
                                    <CheckPrimaryColorIcon />
                                ) : null}
                            </DropdownItem>
                        </DropdownContent>
                    </Dropdown>

                    {currentView !== "caseload" && (
                        <div className="flex items-center gap-x-16 text-sm font-medium">
                            <Button variant="normal" size="auto">
                                <NavigateLeft />
                            </Button>

                            {`Today ${todayDate}`}
                            <Button variant="normal" size="auto">
                                <NavigateRight />
                            </Button>
                        </div>
                    )}

                    <Switch
                        activeSwitch={currentView}
                        switchOptions={[
                            {
                                title: "My Day",
                                key: "my_day",
                                onClick: () => handleCurrentView("my_day"),
                                disabled: true,
                            },
                            {
                                title: "My Week",
                                key: "my_week",
                                onClick: () => handleCurrentView("my_week"),
                                disabled: true,
                            },
                            {
                                title: "Caseload",
                                key: "caseload",
                                onClick: () => handleCurrentView("caseload"),
                            },
                        ]}
                    />
                </div>
            </div>
            <div className="overflow-x-auto">
                <div className="px-32">
                    {currentView === "my_day" && (
                        <DashboardDayView schedules={schedules} />
                    )}
                </div>
                {currentView === "my_week" && (
                    <div className="pl-[29px] pr-[34px]">
                        <DashboardWeekView schedules={schedules} />
                    </div>
                )}
                {currentView === "caseload" && (
                    <div className=" pl-[64px] pr-[34px] min-w-[1000px] max-w-full">
                        <DashboardCaseloadView />
                    </div>
                )}
            </div>
        </div>
    );
}
