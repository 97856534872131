import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { DatePicker, Input, Select } from "@jhool-io/fe-components";
import { APP_COLORS } from "../../../../../utils/constants";
import {
    ClientFeeValues,
    INewClientFee,
} from "../../../../../utils/types/client";
import { useFetchUserDetails } from "../../../../../hooks/queries/user";
import { useAddClientFees } from "../../../../../hooks/mutations/client";
import useToast from "../../../../../hooks/useToast";
import {
    cn,
    handleFormatDatePickerValue,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../../../utils/helpers";
import { calculateFees } from "../../../../../utils/helpers/fees/fees";
import useShowInsuranceOverSelfPay from "../../../../../modules/practice-settings/hooks/useShowInsuranceOverSelfPay";

interface AddFeesProps {
    // Function to call when form submit button is clicked
    onFormSubmit(): void;
}

type Option = {
    label: React.ReactNode;
    value: boolean;
};

const options: Option[] = [
    {
        value: true,
        label: <span style={{ color: APP_COLORS.COLOR_PRIMARY }}>Yes</span>,
    },
    {
        value: false,
        label: <span style={{ color: APP_COLORS.COLOR_DANGER }}>No</span>,
    },
];

const schema = yup.object({
    cal_year: yup.date().required("Calender Year is required"),
    start_date: yup.date().required("Start Date is required"),
    end_date: yup
        .date()
        .required("End Date is required")
        .min(
            yup.ref("start_date"),
            "The end date cannot be earlier than the start date"
        ),
    self_pay: yup.boolean(),
    accepts_assignment: yup.boolean(),
    INNDed: yup.string(),
    INNOOP: yup.string(),
    INNCo: yup.string(),
    INNCoins: yup.string(),
    OONDed: yup.string(),
    OONOOP: yup.string(),
    OONCo: yup.string(),
    OONCoins: yup.string(),
    dr_fee_a: yup.string(),
    dr_fee_b: yup.string(),
    dr_fee_c: yup.string(),
    lic_fee: yup.string(),
    lic_a_fee: yup.string(),
    lic_b_fee: yup.string(),
    lic_c_fee: yup.string(),
    fee_values: yup.array().of(
        yup.object().shape({
            value: yup.string().test({
                test: (val) =>
                    val ? Boolean(calculateFees(val as string)) : true,
                message: "Invalid fee format",
            }),
        })
    ),
});

export default function AddFees({ onFormSubmit }: AddFeesProps) {
    const [startDate, setStartDate] = React.useState<Date | null>(null);
    const [endDate, setEndDate] = React.useState<Date | null>(null);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<INewClientFee>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    // To initialize clientId constant
    const params = useParams();
    const clientId = params.clientId as string;

    const showInsOverSelfPay = useShowInsuranceOverSelfPay();

    // Hook for adding client fee
    const { mutate } = useAddClientFees(clientId);

    // Query client
    const queryClient = useQueryClient();

    // Get logged user details
    const { data } = useFetchUserDetails();

    // Toast for success and error states
    const { toast } = useToast();

    const onSubmit = (payload: INewClientFee) => {
        const dataToSend: INewClientFee = {
            ...payload,
            author: data?.user_id as string,
            start_date: handleFormatDatePickerValue(payload.start_date),
            cal_year: new Date(payload.cal_year).getFullYear(),
            end_date: handleFormatDatePickerValue(payload.end_date),
            fee_values: Object.values(ClientFeeValues).map((fee, i) => ({
                type: fee,
                value: payload.fee_values[i].value,
            })),
        };

        mutate(dataToSend, {
            onSuccess: (res) => {
                queryClient.invalidateQueries({
                    queryKey: [clientId, "fees"],
                });
                toast({
                    mode: "success",
                    message: res.message || "Fees added successfully",
                });
                onFormSubmit();
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not add fees at this time",
                });
            },
        });
    };

    const getClientFeeValues = Object.values(ClientFeeValues).map((fee) => ({
        type: fee,
        value: "",
    }));

    return (
        <form id="add-fees" onSubmit={handleSubmit(onSubmit)}>
            <div className="fg">
                <Input
                    {...register("cal_year")}
                    label="Calendar Year"
                    placeholder="Calendar Year"
                    hasError={!!errors.cal_year}
                    errorText={
                        errors.cal_year?.message?.includes(
                            "cal_year must be a `date` type, but the final value was"
                        )
                            ? "Invalid date"
                            : errors.cal_year?.message
                    }
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Controller
                    name="start_date"
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            label="Start Date"
                            onChange={(date) => {
                                field.onChange(date);
                                setStartDate(date);
                            }}
                            selected={startDate}
                            hasError={!!errors.start_date}
                            errorText={
                                errors.start_date?.type === "typeError"
                                    ? "Invalid date value"
                                    : errors.start_date?.message
                            }
                        />
                    )}
                />
                <Controller
                    name="end_date"
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            label="End Date"
                            onChange={(date) => {
                                field.onChange(date);
                                setEndDate(date);
                            }}
                            selected={endDate}
                            hasError={!!errors.end_date}
                            errorText={
                                errors.end_date?.type === "typeError"
                                    ? "Invalid date value"
                                    : errors.end_date?.message
                            }
                        />
                    )}
                />
            </div>
            <div
                className={cn("fg", {
                    "fg-space-between two flex": !showInsOverSelfPay,
                })}
            >
                {!showInsOverSelfPay && (
                    <Controller
                        name="self_pay"
                        control={control}
                        render={({ field }) => (
                            <Select
                                label="Self Pay"
                                options={options}
                                onChange={(val) =>
                                    field.onChange((val as Option).value)
                                }
                                hasError={!!errors.self_pay}
                                errorText={errors.self_pay?.message}
                            />
                        )}
                    />
                )}
                <Controller
                    name="accepts_assignment"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Accepts Assignment"
                            options={options}
                            onChange={(val) =>
                                field.onChange((val as Option).value)
                            }
                            hasError={!!errors.accepts_assignment}
                            errorText={errors.accepts_assignment?.message}
                        />
                    )}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Input
                    {...register("INNDed")}
                    label="INNDed"
                    placeholder="INNDed"
                    hasError={!!errors.INNDed}
                    errorText={errors.INNDed?.message}
                />
                <Input
                    {...register("INNCo")}
                    label="INNCo"
                    placeholder="INNCo"
                    hasError={!!errors.INNCo}
                    errorText={errors.INNCo?.message}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Input
                    {...register("INNOOP")}
                    label="INNOOP"
                    placeholder="INNOOP"
                    hasError={!!errors.INNOOP}
                    errorText={errors.INNOOP?.message}
                />

                <Input
                    {...register("INNCoins")}
                    label="INNCoins"
                    placeholder="INNCoins"
                    hasError={!!errors.INNCoins}
                    errorText={errors.INNCoins?.message}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Input
                    {...register("OONDed")}
                    label="OONDed"
                    placeholder="OONDed"
                    hasError={!!errors.OONDed}
                    errorText={errors.OONDed?.message}
                />
                <Input
                    {...register("OONCo")}
                    label="OONCo"
                    placeholder="OONCo"
                    hasError={!!errors.OONCo}
                    errorText={errors.OONCo?.message}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Input
                    {...register("OONOOP")}
                    label="OONOOP"
                    placeholder="OONOOP"
                    hasError={!!errors.OONOOP}
                    errorText={errors.OONOOP?.message}
                />

                <Input
                    {...register("OONCoins")}
                    label="OONCoins"
                    placeholder="OONCoins"
                    hasError={!!errors.OONCoins}
                    errorText={errors.OONCoins?.message}
                />
            </div>
            <div className="fg-wrap">
                <Input
                    {...register("UCR")}
                    label="UCR"
                    placeholder="UCR"
                    hasError={!!errors.UCR}
                    errorText={errors.UCR?.message}
                />
                {getClientFeeValues.map((fee_key, i) => {
                    return (
                        <div
                            key={fee_key.type}
                            className="fg-justify-content-smaller"
                        >
                            <Input
                                {...register(`fee_values.${i}.value`)}
                                label={makeStringFirstLetterCapital(
                                    removeEnumUnderscore(fee_key.type)
                                )}
                                placeholder={makeStringFirstLetterCapital(
                                    removeEnumUnderscore(fee_key.type)
                                )}
                                hasError={!!errors?.fee_values?.[i]?.value}
                                errorText={
                                    errors?.fee_values?.[i]?.value?.message
                                }
                            />
                        </div>
                    );
                })}
            </div>
        </form>
    );
}
