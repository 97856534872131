import * as React from "react";
import { useIsMutating } from "@tanstack/react-query";
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import { Dialog, DialogContent, Tabs } from "@jhool-io/fe-components";
import { useFetchSessionNote } from "../../../../hooks/queries/note";
import {
    checkIfRichTextHasTextContent,
    cn,
    convertUrlSearchParamsToObject,
    removeEnumUnderscore,
} from "../../../../utils/helpers";
import Button from "../../../../components/Button/Button";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import styles from "./SingleBillViewWrapper.module.scss";
import Card from "../../../../components/Card/Card";
import { ViewEditorText } from "../../../../components/TextEditor/ViewEditorText/ViewEditorText";
import EditIcon from "../../../../components/Icons/Edit";
import ChevronLeftIcon from "../../../../components/Icons/ChevronLeft";
import ChevronRightIcon from "../../../../components/Icons/ChevronRight";
import InvoiceNotes from "../../../../components/Billing/InvoiceNotes/InvoiceNotes";
import ClaimsWrapper from "../../../claims/components/ClaimsWrapper/ClaimsWrapper";
import BillsClientInfo from "../../../../components/BillsClientInfo/BillsClientInfo";
import { useGetClaim } from "../../../claims/hooks/claims.queries";
import { useFetchClientFees } from "../../../../hooks/queries/client";
import { NoteStatus, NoteTypes } from "../../../../utils/types/notes";
import PaymentTabWrapper from "../PaymentTabWrapper/PaymentTabWrapper";
import { useFetchRemitClaims } from "../../../remits/hooks/remits.queries";
import RemitsWrapper from "../../../remits/components/RemitsWrapper/RemitsWrapper";
import NoteItemsWrapper from "../../../notes/components/NoteItemsWrapper/NoteItemsWrapper";
import PendingResolutionForm from "../PendingResolutionForm/PendingResolutionForm";
import { useFetchInvoices } from "../../hooks/queries/billing.queries";
import usePractice from "../../../../hooks/usePractice";
import { UserRole } from "../../../../utils/types/user";

type Modals = "change-cpt" | "pending-resolution";

export default function SingleBillViewWrapper() {
    const [modalInView, setModalInView] = React.useState<Modals | null>(null);
    // Get id from url params
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const clientId = params.clientId as string;

    const noteId = params.noteId as string;
    const claimIdFromSearchParams = searchParams.get("claim_id");
    const { data, isLoading, isSuccess } = useFetchSessionNote(
        clientId,
        noteId,
        Boolean(clientId) && Boolean(noteId)
    );

    const tabInUrl =
        searchParams.get("tab") ||
        (data?.data.is_archived && "note") ||
        "payments";

    const viewFrom = searchParams.get("viewFrom");

    /* Get an object of the searchparams from the invoices list
     we get this from location.state which was set on the handleRowClick function
     of each row on the invoices list */
    const billsPageParamsObj = location.state
        ? convertUrlSearchParamsToObject(location.state)
        : null;

    const getInvoiceStatusQueryString = () => {
        if (billsPageParamsObj && billsPageParamsObj?.invoice_status) {
            if (billsPageParamsObj?.invoice_status === "all") return [];
            return [billsPageParamsObj?.invoice_status];
        }
        return ["open"];
    };

    // Hook for fetching all invoices
    const invoicesList = useFetchInvoices({
        search_string: billsPageParamsObj?.client_name || "",
        provider_name: billsPageParamsObj?.provider_name || "",
        insurance_provider: billsPageParamsObj?.insurance_provider || "",
        invoice_status: getInvoiceStatusQueryString(),
        cpt_code: billsPageParamsObj?.cpt_code || "",
        from_date: billsPageParamsObj?.from_date
            ? new Date(billsPageParamsObj?.from_date as string)
            : null,
        to_date: billsPageParamsObj?.to_date
            ? new Date(billsPageParamsObj?.to_date as string)
            : null,
        page: Number(billsPageParamsObj?.page) || 1,
        limit: 300,
        sort_attribute: billsPageParamsObj?.sort_attr || undefined,
        sort_order: billsPageParamsObj?.sort_order || undefined,
    });

    // Get an array of object of {client_name, note_id, invoice_id} from the invoice list
    const arrayOfInvoicesToNavigateWith =
        invoicesList?.data?.data.map((invoice) => ({
            client_id: invoice.client.client_id || "",
            note_id: invoice.note_id,
            invoice_id: invoice.invoice_id,
        })) || [];

    /**
     * Handle onclick of navigation buttons
     */
    const getCurrentInvoiceIndex = () => {
        const getCurrent = arrayOfInvoicesToNavigateWith.findIndex(
            (invoice) => invoice.note_id === noteId
        );

        return getCurrent;
    };

    const handlePrevButtonClick = () => {
        const currentIndex = getCurrentInvoiceIndex();
        if (currentIndex === 0) return;
        navigate(
            `/billing-and-claims/${
                arrayOfInvoicesToNavigateWith[currentIndex - 1]?.client_id || ""
            }/${arrayOfInvoicesToNavigateWith[currentIndex - 1]?.note_id}`,
            {
                state: location.state || null,
            }
        );
    };

    const handleNextButtonClick = () => {
        const currentIndex = getCurrentInvoiceIndex();
        if (currentIndex === arrayOfInvoicesToNavigateWith.length - 1) return;
        navigate(
            `/billing-and-claims/${
                arrayOfInvoicesToNavigateWith[currentIndex + 1].client_id || ""
            }/${arrayOfInvoicesToNavigateWith[currentIndex + 1].note_id}`,
            {
                state: location.state || null,
            }
        );
    };

    //   fetch claims
    const { data: claimData } = useGetClaim(
        {
            note_id: noteId as string,
        },
        Boolean(noteId)
    );
    // Fetch client fees
    const { data: clientFees } = useFetchClientFees(clientId, 3);

    // fetch single remit data
    const remitsData = useFetchRemitClaims({
        note_id: noteId,
    });

    const { practice } = usePractice();

    const handleSetTabInUrl = (tab: string) => {
        searchParams.set("tab", tab);
        if (
            tab === "payments" &&
            practice?.roles?.includes(UserRole.CLIENT_SUPPORT)
        ) {
            searchParams.set("invoice_status[]", "pending_resolution");
        } else {
            searchParams.delete("invoice_status[]");
        }
        setSearchParams(searchParams);
    };

    const tabItems = [
        {
            label: "Session Note",
            key: "note",
            children: (
                <div className="mt-24">
                    <NoteItemsWrapper />
                </div>
            ),
            onClick: () => handleSetTabInUrl("note"),
        },
        {
            label: "Claims",
            key: "claims",
            children: (
                <div className="mt-24">
                    <ClaimsWrapper />
                </div>
            ),
            onClick: () => handleSetTabInUrl("claims"),
            disabled:
                Boolean(clientFees?.data[0]?.self_pay) ||
                data?.data.note_status !== NoteStatus.CODED ||
                !data?.data?.cpt_code ||
                !data.data.invoice_id,
        },
        {
            label: "Payments History",
            key: "payments",
            children: data?.data.is_archived ? null : (
                <div className="mt-24">
                    <PaymentTabWrapper />
                </div>
            ),
            onClick: () => handleSetTabInUrl("payments"),
            disabled: data?.data.is_archived,
        },
        {
            key: "remit",
            label: "Remits",
            children: (
                <div className="mt-24">
                    <RemitsWrapper />
                </div>
            ),
            onClick: () => handleSetTabInUrl("remit"),
            disabled: remitsData.data?.data.length === 0,
        },
    ];

    const updateInVoiceIsMutating = useIsMutating(["update-invoice"]);

    return (
        <div>
            <Dialog open={modalInView === "pending-resolution"}>
                <DialogContent
                    title="Pending session fee"
                    handleCloseDialog={() => setModalInView(null)}
                    variant="center"
                    showFooter
                    cancelText="Cancel"
                    saveText="Mark as Pending session fee"
                    submitBtnFormValue="pending-resolution"
                    isSubmitBtnDisabled={updateInVoiceIsMutating > 0}
                    isCancelBtnDisabled={updateInVoiceIsMutating > 0}
                >
                    <PendingResolutionForm
                        onFormSubmit={() => setModalInView(null)}
                    />
                </DialogContent>
            </Dialog>
            {isLoading ? (
                <div className={styles.skeleton}>
                    <div className={styles.top}>
                        <Skeleton
                            containerTestId="viewbilling-loader"
                            width="65%"
                        />
                        <Skeleton
                            containerTestId="viewbilling-loader"
                            width="33%"
                        />
                    </div>
                    <div className={styles.middle}>
                        <Skeleton containerTestId="viewbilling-loader" />
                        <Skeleton containerTestId="viewbilling-loader" />
                        <Skeleton containerTestId="viewbilling-loader" />
                    </div>
                    <div className={styles.bottom}>
                        <Skeleton containerTestId="viewbilling-loader" />
                    </div>
                </div>
            ) : null}

            {isSuccess && data && (
                <div className={styles.header}>
                    <div className={styles.navigation}>
                        {invoicesList.isLoading && (
                            <div className={styles.navigation_loading}>
                                <Skeleton height="20px" width="50px" />
                                <Skeleton height="20px" width="50px" />
                            </div>
                        )}
                        {invoicesList.error && (
                            <div className={styles.navigation_error}>
                                Cannot display navigation buttons at this time,
                                try reloading the page
                            </div>
                        )}
                        {invoicesList.data && viewFrom !== "session_note" && (
                            <div className={styles.navigation_btns}>
                                <Button
                                    mode="transparent"
                                    size="auto"
                                    type="button"
                                    disabled={
                                        !getCurrentInvoiceIndex() ||
                                        getCurrentInvoiceIndex() <= 0
                                    }
                                    onClick={handlePrevButtonClick}
                                >
                                    <ChevronLeftIcon /> Prev
                                </Button>
                                <Button
                                    mode="transparent"
                                    size="auto"
                                    type="button"
                                    disabled={
                                        getCurrentInvoiceIndex() >=
                                        arrayOfInvoicesToNavigateWith.length - 1
                                    }
                                    onClick={handleNextButtonClick}
                                >
                                    Next
                                    <ChevronRightIcon />
                                </Button>
                            </div>
                        )}
                    </div>

                    <div className={styles.infonoteswrapper}>
                        <div className={styles.infowrapper}>
                            <div>
                                <BillsClientInfo
                                    claimID={
                                        (claimData?.data?.claim_id as string) ||
                                        (claimIdFromSearchParams as string)
                                    }
                                />
                            </div>
                            {data.data.invoice_status ===
                            "pending_resolution" ? (
                                <Card className={styles.prcard}>
                                    <div className={styles.prcard_wrapper}>
                                        <div
                                            className={
                                                styles.prcard_contentwrapper
                                            }
                                        >
                                            <span
                                                className={styles.prcard_status}
                                            >
                                                {removeEnumUnderscore(
                                                    data.data.issue_category ||
                                                        ""
                                                )}
                                                <Button
                                                    type="button"
                                                    size="auto"
                                                    mode="transparent"
                                                    onClick={() =>
                                                        setModalInView(
                                                            "pending-resolution"
                                                        )
                                                    }
                                                >
                                                    <EditIcon />
                                                </Button>
                                            </span>

                                            <div
                                                className={
                                                    styles.prcard_details
                                                }
                                            >
                                                {data.data.issue_notes &&
                                                checkIfRichTextHasTextContent(
                                                    data.data.issue_notes
                                                ) ? (
                                                    <ViewEditorText
                                                        text={
                                                            data.data
                                                                .issue_notes
                                                        }
                                                    />
                                                ) : (
                                                    <span
                                                        className={styles.empty}
                                                    >
                                                        No details provided
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            ) : null}
                        </div>

                        {data.data.invoice_id ? (
                            <div className={styles.noteswrapper}>
                                <InvoiceNotes
                                    invoiceId={data.data.invoice_id}
                                    isNoShowNote={
                                        data.data.type ===
                                        NoteTypes.CANCELLATION_NOTE
                                    }
                                />
                            </div>
                        ) : null}
                    </div>

                    <div className={styles.flex_tab_div}>
                        <Tabs
                            items={tabItems}
                            className={cn("!w-[100%] !mb-0 !items-end")}
                            defaultActiveKey={tabInUrl}
                            size="small"
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
