import * as React from "react";
import cnm from "classnames";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast as reactHotToast } from "react-hot-toast";
import * as yup from "yup";
import { parseISO } from "date-fns";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import {
    Alert,
    Button,
    DatePicker,
    Input,
    Select,
} from "@jhool-io/fe-components";
import {
    useFetchProviderSessions,
    useFetchSingleProviderSession,
} from "../../../../hooks/queries";
import {
    showUserLocalTime,
    formatDate,
    formatZonedTimeToUtc,
    getCorrectTimeValueFromIsoString,
    handleFormatDatePickerValue,
    hideNoteField,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    cn,
    getDurationInMinutes,
} from "../../../../utils/helpers";
import {
    CreateNoteSteps,
    EditNoteSteps,
    INewNoteAppoinmentDetails,
    NoteLabels,
    NoteTypes,
} from "../../../../utils/types/notes";
import {
    IProviderSession,
    ProviderStatus,
    SessionServiceType,
} from "../../../../utils/types/session";
import styles from "./AddNoteMetadata.module.scss";
import generalStyles from "../CreateNote.module.scss";
import { useFetchUserDetails } from "../../../../hooks/queries/user";
import DotIcon from "../../../Icons/DotIcon";
import useToast from "../../../../hooks/useToast";
import Skeleton from "../../../Skeleton/Skeleton";
import ListState from "../../../ListState/ListState";
import {
    useAddSessionNote,
    useEditDraftNote,
} from "../../../../hooks/mutations/note";
import usePractice from "../../../../hooks/usePractice";
import { useAppSelector } from "../../../../hooks/useRedux";
import useThirtydaysProviders from "../../../../hooks/useThirtydaysProviders";

interface AddNoteMetadataProps {
    noteAppointmentDetails: INewNoteAppoinmentDetails | null;
    handleCreateNoteStepInView(step: CreateNoteSteps | EditNoteSteps): void;
    handleSetAppointmentDetailsInView(
        appointmentDetails: INewNoteAppoinmentDetails
    ): void;
}

type Option = {
    label: React.ReactNode;
    value: string;
};

type AllowedNoteTypes = Exclude<NoteTypes, NoteTypes.SUPERVISION_NOTE>;

const notesWithOptionalDOS = [
    NoteTypes.TERMINATION_NOTE,
    NoteTypes.RECORD_OF_DISCLOSURE,
    NoteTypes.SAFETY_PLAN,
    NoteTypes.INDIVIDUAL_TREATMENT_REVIEW,
    NoteTypes.FAMILY_TREATMENT_REVIEW,
    NoteTypes.SUPERVISION_NOTE,
    NoteTypes.GENERAL_UPDATE_NOTE,
    NoteTypes.SUPERVISOR_CHECK_IN,
];

export default function AddNoteMetadata({
    handleSetAppointmentDetailsInView,
    noteAppointmentDetails,
    handleCreateNoteStepInView,
}: AddNoteMetadataProps) {
    // Local component state
    const [selectedSessionId, setSelectedSessionId] = React.useState<
        string | null
    >(() =>
        noteAppointmentDetails ? noteAppointmentDetails.session_id : null
    );
    const [searchValue, setSearchValue] = React.useState("");

    const params = useParams();

    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);

    const { practice } = usePractice();

    const { isOpen } = useAppSelector((state) => state.nav);

    const { providers } = useThirtydaysProviders();

    const providersThatCanWriteNoteAfterThirtyDays = providers.map(
        (item) => item.provider_id
    );

    const appointmentDateOfService = noteAppointmentDetails
        ? noteAppointmentDetails.date_of_service
        : "";

    const [localDateOfService, setLocalDateOfService] =
        React.useState<Date | null>(
            appointmentDateOfService && appointmentDateOfService !== "--"
                ? parseISO(
                      new Date(
                          noteAppointmentDetails?.date_of_service as string
                      )?.toISOString()
                  )
                : null
        );

    // Fetch provider's sessions list
    const { data, isLoading, error } = useFetchProviderSessions();

    const { toast } = useToast();

    const navigate = useNavigate();

    const sessionHistoryId = searchParams.get("session_history_id");
    const appointmentTypeFromUrl = searchParams.get("appt_type");

    const noteId = (searchParams.get("note_id") as string) || params.noteId;

    const noteTypeInParam = searchParams.get("note_type") as NoteTypes;

    const sessionHistoryDetails = useFetchSingleProviderSession(
        sessionHistoryId || ""
    );

    const {
        handleSubmit,
        control,
        watch,
        register,
        setValue,
        formState: { errors },
    } = useForm<INewNoteAppoinmentDetails>({
        resolver: yupResolver(
            yup.object({
                note_type: yup.string().required("Note type is required"),
                client_name: yup.string().required("Client name is required"),
                provider_name: yup
                    .string()
                    .when("note_type", (note_type, field) =>
                        note_type === NoteTypes.SUPERVISION_NOTE
                            ? field.required("Provider name is required")
                            : field
                    ),
                appointment_type: yup
                    .string()
                    .when("note_type", (note_type, field) =>
                        note_type !== NoteTypes.SUPERVISION_NOTE
                            ? field.required("Appointment type is required")
                            : field
                    )
                    .notOneOf(
                        ["Individual", "individual"],
                        "Invalid or empty appointment type"
                    ),
                date_of_service: yup.date().when("note_type", {
                    is: (note_type: NoteTypes) =>
                        !notesWithOptionalDOS.includes(note_type),
                    then: yup
                        .date()
                        .required("Date of service is required")
                        .test(
                            "date-range",
                            "You can only write a note for this session within 30 days of its occurrence",
                            (dateOfService) => {
                                const todaysDate = new Date(Date.now());

                                // Allow providers with ids in the provider providersThatCanWriteNoteAfterThirtyDays to
                                // pass this check
                                if (
                                    providersThatCanWriteNoteAfterThirtyDays.includes(
                                        practice?.provider_id || ""
                                    )
                                )
                                    return true;

                                if (dateOfService) {
                                    const diff = Math.abs(
                                        dateOfService.getTime() -
                                            todaysDate.getTime()
                                    );
                                    const diffDays = Math.ceil(
                                        diff / (1000 * 60 * 60 * 24)
                                    );

                                    return diffDays <= 30;
                                }
                                return true;
                            }
                        ),
                    otherwise: yup.date().nullable(),
                }),
                session_start_time: yup
                    .string()
                    .when("note_type", (note_type, field) =>
                        note_type !== NoteTypes.SUPERVISION_NOTE
                            ? field.required("Session start time is required")
                            : field
                    ),
                session_end_time: yup
                    .string()
                    .when("note_type", (note_type, field) =>
                        note_type !== NoteTypes.SUPERVISION_NOTE
                            ? field
                                  .required("Session end time is required")
                                  .test({
                                      test: (
                                          value: string,
                                          context: {
                                              parent: {
                                                  session_start_time: string;
                                              };
                                          }
                                      ) => {
                                          const {
                                              session_start_time:
                                                  sessionStartTime,
                                          } = context.parent;
                                          if (!sessionStartTime || !value) {
                                              return true;
                                          }
                                          const start = new Date(
                                              `1970-01-01T${sessionStartTime}:00`
                                          );
                                          const end = new Date(
                                              `1970-01-01T${value}:00`
                                          );
                                          return end > start;
                                      },
                                      message:
                                          "Session end time should be later than the start time",
                                  })
                            : field
                    ),
            })
        ),
        mode: "onChange",
        defaultValues: noteAppointmentDetails
            ? {
                  ...noteAppointmentDetails,
                  session_start_time: getCorrectTimeValueFromIsoString(
                      noteAppointmentDetails?.session_start_time || ""
                  ),
                  session_end_time: getCorrectTimeValueFromIsoString(
                      noteAppointmentDetails?.session_end_time || ""
                  ),
                  date_of_service:
                      noteAppointmentDetails.date_of_service !== "--"
                          ? noteAppointmentDetails.date_of_service
                          : null,
                  note_type:
                      noteTypeInParam ||
                      noteAppointmentDetails?.note_type ||
                      "",
              }
            : {
                  note_type: noteTypeInParam || "",
              },
    });

    const loggedInUser = useFetchUserDetails();

    const shouldShowDOSAlert = notesWithOptionalDOS
        .filter((type) => type !== NoteTypes.SUPERVISION_NOTE)
        .includes(noteTypeInParam as AllowedNoteTypes);

    // Get options for session type select input
    const getSessionTypeSelectOptions = Object.values(SessionServiceType).map(
        (sessionType) => ({
            label: (
                <span style={{ textTransform: "capitalize" }}>
                    {removeEnumUnderscore(sessionType)}
                </span>
            ),
            value: sessionType,
        })
    );

    const dateOfServiceFromForm = watch("date_of_service");
    const sessionEndTimeFromForm = watch("session_end_time");

    const shouldShowLateNoteAlert = () => {
        const currentDateTime = new Date();

        if (dateOfServiceFromForm) {
            const formattedDateOfService = `${handleFormatDatePickerValue(
                dateOfServiceFromForm as string
            )}T${sessionEndTimeFromForm}:00.000`;

            if (new Date(formattedDateOfService) > currentDateTime) {
                return false;
            }

            const differenceInMs = Math.abs(
                parseISO(formattedDateOfService).getTime() -
                    new Date(Date.now()).getTime()
            );

            const hoursDifference = differenceInMs / (1000 * 60 * 60);

            return hoursDifference > 24;
        }
        return false;
    };

    const shouldShowFutureNoteAlert = () => {
        if (dateOfServiceFromForm) {
            const formattedDateOfService = handleFormatDatePickerValue(
                dateOfServiceFromForm as string
            );
            const today = new Date();
            const formattedCurrentDate = today.toISOString().split("T")[0];
            if (formattedDateOfService > formattedCurrentDate) {
                return true;
            }
        }
        return false;
    };

    // Get options for note type select input
    const getNoteTypeSelectOptions = [
        NoteTypes.CANCELLATION_NOTE,
        NoteTypes.SUPERVISION_NOTE,
        NoteTypes.FAMILY_SOAP_NOTE,
        NoteTypes.INDIVIDUAL_SOAP_NOTE,
        NoteTypes.RECORD_OF_DISCLOSURE,
        NoteTypes.TERMINATION_NOTE,
        NoteTypes.SAFETY_PLAN,
        NoteTypes.INDIVIDUAL_TREATMENT_REVIEW,
        NoteTypes.FAMILY_TREATMENT_REVIEW,
        NoteTypes.INDIVIDUAL_INTAKE_NOTE,
        NoteTypes.FAMILY_INTAKE_NOTE,
        NoteTypes.GENERAL_UPDATE_NOTE,
        NoteTypes.SUPERVISOR_CHECK_IN,
        NoteTypes.MINOR_INTAKE_NOTE,
    ]
        .sort((a, b) => a.localeCompare(b))
        .map((noteType) => ({
            label: NoteLabels[noteType],
            value: noteType,
        }));

    const noteType = watch("note_type");
    const appointmentType = watch("appointment_type");

    const startTime = watch("session_start_time");
    const endTime = watch("session_end_time");

    const isSessionShort = () => {
        if (startTime && endTime) {
            const durationInMinutes = getDurationInMinutes(startTime, endTime);

            return durationInMinutes < 55;
        }
        return false;
    };

    const setValuesFromSelectedSession = (sessionId: string) => {
        const selectedSession = data?.data.find(
            (session) => session.session_id === sessionId
        );

        if (selectedSession?.status === ProviderStatus.INACTIVE) {
            setValue("note_type", NoteTypes.TERMINATION_NOTE);
            searchParams.set("note_type", NoteTypes.TERMINATION_NOTE);
            navigate(
                params.noteId
                    ? `/notes/${params.noteId}/${
                          params.clientId
                      }/edit?${searchParams.toString()}`
                    : `/add-note?${searchParams.toString()}`
            );
        }

        if (selectedSession?.first_session_date) {
            setValue(
                "first_session_date",
                formatDate(
                    selectedSession?.first_session_date as string,
                    false,
                    "yyyy-MM-dd'T'HH:mm:ss'Z'"
                )
            );
        } else {
            setValue("first_session_date", null);
        }
        setValue(
            "appointment_type",
            selectedSession?.service.toLowerCase() ===
                "Individual".toLowerCase()
                ? SessionServiceType.INDIVIDUAL_THERAPY
                : selectedSession?.service
        );
        setValue(
            "session_history_id",
            selectedSession?.session_history_id as string
        );
        setValue(
            "date_of_service",
            !notesWithOptionalDOS.includes(
                searchParams.get("note_type") as NoteTypes
            )
                ? formatDate(selectedSession?.start_date_time as string)
                : null
        );
        setValue("no_of_sessions", selectedSession?.no_of_sessions);
        setLocalDateOfService(
            !notesWithOptionalDOS.includes(
                searchParams.get("note_type") as NoteTypes
            )
                ? new Date(
                      formatDate(selectedSession?.start_date_time as string)
                  )
                : null
        );
        setValue(
            "session_start_time",
            formatDate(
                selectedSession?.start_date_time as string,
                false,
                "HH:mm"
            )
        );
        setValue(
            "session_end_time",
            formatDate(selectedSession?.end_date_time as string, false, "HH:mm")
        );
        setValue(
            "provider_id",
            selectedSession?.provider.provider_id as string
        );
        setValue(
            "client_name",
            `${selectedSession?.client.first_name} ${selectedSession?.client.last_name}`
        );
        setValue("client_id", selectedSession?.client.client_id as string);
        setValue(
            "provider_name",
            `${selectedSession?.provider.first_name} ${selectedSession?.provider.last_name}`
        );
        setValue("session_id", selectedSession?.session_id as string);
    };

    // Custom comparison function
    const compareItems = (a: IProviderSession, b: IProviderSession) => {
        if (
            !a.provider.provider_id &&
            practice?.provider_id &&
            b.provider.provider_id &&
            practice.provider_id
        ) {
            return -1;
        }
        if (
            a.provider.provider_id &&
            practice?.provider_id &&
            !b.provider.provider_id &&
            practice?.provider_id
        ) {
            return 1;
        }
        return 0;
    };

    // Function to check if supervisor's supervisees have active sessions
    const superviseeHaveActiveSessions = () => {
        const arrayOfSuperviseesSessions = data?.data.filter(
            (item) => item.provider.provider_id !== practice?.provider_id
        );
        return arrayOfSuperviseesSessions?.length === 0;
    };

    const makeLabelsAndValuesProviderSessionSelect = (
        session: IProviderSession
    ) => {
        return {
            label: (
                <div className={styles.label}>
                    <div className={styles.label_top}>
                        <span
                            className={styles.label_top_l}
                        >{`${makeStringFirstLetterCapital(
                            session.client.first_name
                        )} ${makeStringFirstLetterCapital(
                            session.client.last_name
                        )} - ${makeStringFirstLetterCapital(
                            removeEnumUnderscore(session.service)
                        )}`}</span>
                        {loggedInUser &&
                            practice?.is_supervisor &&
                            !superviseeHaveActiveSessions() && (
                                <span className={styles.label_top_r}>
                                    <DotIcon />
                                    {`${
                                        session.provider.provider_id ===
                                        practice?.provider_id
                                            ? "Self"
                                            : `Supervisee (${makeStringFirstLetterCapital(
                                                  session.provider.first_name
                                              )} ${session.provider.last_name.charAt(
                                                  0
                                              )}.)`
                                    }
                    `}
                                </span>
                            )}
                    </div>
                    <div className="text-gray text-xs">
                        {`${makeStringFirstLetterCapital(
                            session.session_day
                        )}, ${formatDate(
                            session.start_date_time,
                            false,
                            "h:mmaaa"
                        )} - ${formatDate(
                            session.end_date_time,
                            false,
                            "h:mmaaa"
                        )}`}
                        {" - "}
                        <span
                            className={cn(
                                "text-xs inline-block font-medium capitalize",
                                {
                                    "text-primary":
                                        session.status ===
                                        ProviderStatus.INACTIVE,
                                    "text-danger":
                                        session.status ===
                                        ProviderStatus.INACTIVE,
                                }
                            )}
                        >
                            {session.status === ProviderStatus.INACTIVE
                                ? "Terminated"
                                : session.status}
                        </span>
                    </div>
                </div>
            ),
            value: session.session_id,
        };
    };

    // Temporary hack to prevent showing the same session/client/provider with terminated and active session
    const filterSessionsToCorrectShape = (sessions: IProviderSession[]) => {
        // Step 1: Add a key to each session
        const sessionsWithKey = sessions.map((session) => ({
            ...session,
            key: `${session.client.first_name} ${session.client.last_name} - ${session.provider.first_name} ${session.provider.last_name} - ${session.service}`,
        }));

        // Step 2: Group sessions by key
        const groupedSessions = sessionsWithKey.reduce((acc, session) => {
            if (!acc[session.key]) {
                // eslint-disable-next-line no-param-reassign
                acc[session.key] = [];
            }
            acc[session.key].push(session);
            return acc;
        }, {} as Record<string, typeof sessionsWithKey>);

        // Step 3: Filter groups to keep only non-cancelled sessions
        const filteredSessions = Object.values(groupedSessions).map((group) => {
            const nonCancelledSessions = group.filter(
                (session) => session.status === ProviderStatus.ACTIVE
            );
            return nonCancelledSessions.length > 0
                ? nonCancelledSessions[0]
                : group[0];
        });

        // Step 4: Remove the added 'key' property
        return filteredSessions.map(({ key, ...session }) => session);
    };

    // Get options for client name select input
    const getProviderSessionSelectOptions = filterSessionsToCorrectShape(
        data?.data || []
    )
        .sort((a, b) => a.client.first_name.localeCompare(b.client.first_name))
        .sort(compareItems)
        .filter((session) =>
            practice?.is_supervisor && noteType === NoteTypes.SUPERVISION_NOTE
                ? session.provider.provider_id !== practice?.provider_id
                : session
        )
        .map((session) => makeLabelsAndValuesProviderSessionSelect(session));

    const createSessionNote = useAddSessionNote();

    // Hook for editing note
    const editDraftNote = useEditDraftNote();

    // Query client
    const queryClient = useQueryClient();

    // Handle form submit
    const onSubmit = (appoinmentDetails: INewNoteAppoinmentDetails) => {
        const currentSession = data?.data.find(
            (session) => session.session_id === selectedSessionId
        );

        const sessionStartTime = appoinmentDetails.date_of_service
            ? (appoinmentDetails.date_of_service as string)
            : (currentSession?.start_date_time as string);

        const sessionEndTime = appoinmentDetails.date_of_service
            ? (appoinmentDetails.date_of_service as string)
            : (currentSession?.end_date_time as string);

        const dateOfService = appoinmentDetails.date_of_service
            ? (appoinmentDetails.date_of_service as string)
            : undefined;

        const modifiedAppointmentDetails = {
            note_type:
                appoinmentDetails.note_type ||
                noteAppointmentDetails?.note_type,
            client_name: `${currentSession?.client.first_name} ${currentSession?.client.last_name}`,
            appointment_type:
                appoinmentDetails.appointment_type ||
                noteAppointmentDetails?.appointment_type,
            no_of_sessions:
                appoinmentDetails.no_of_sessions ||
                noteAppointmentDetails?.no_of_sessions,
            first_session_date:
                (appoinmentDetails.first_session_date as string | null) ||
                (noteAppointmentDetails?.first_session_date as string | null),
            date_of_service: dateOfService
                ? `${handleFormatDatePickerValue(dateOfService as string)}T${
                      appoinmentDetails?.session_start_time?.split(":")[0]
                  }:${
                      appoinmentDetails?.session_start_time?.split(":")[1]
                  }:00.000Z`
                : noteAppointmentDetails?.session_start_time,
            session_start_time: appoinmentDetails.session_start_time
                ? `${handleFormatDatePickerValue(sessionStartTime)}T${
                      appoinmentDetails?.session_start_time.split(":")[0]
                  }:${
                      appoinmentDetails?.session_start_time.split(":")[1]
                  }:00.000Z`
                : noteAppointmentDetails?.session_start_time,
            session_end_time: appoinmentDetails.session_end_time
                ? `${handleFormatDatePickerValue(sessionEndTime)}T${
                      appoinmentDetails?.session_end_time.split(":")[0]
                  }:${
                      appoinmentDetails?.session_end_time.split(":")[1]
                  }:00.000Z`
                : noteAppointmentDetails?.session_start_time,
            session_id:
                appoinmentDetails?.session_id ||
                (noteAppointmentDetails?.session_id as string),
            session_history_id:
                appoinmentDetails?.session_history_id ||
                (noteAppointmentDetails?.session_history_id as string),
            client_id:
                appoinmentDetails.client_id ||
                (noteAppointmentDetails?.client_id as string),
            provider_id:
                appoinmentDetails.provider_id ||
                (noteAppointmentDetails?.provider_id as string),
            provider_name: `${currentSession?.provider.first_name} ${currentSession?.provider.last_name}`,
            note_content: {},
            reason_for_short_duration:
                appoinmentDetails.reason_for_short_duration || undefined,
        };

        const createPayload = {
            client_id: modifiedAppointmentDetails.client_id,
            session_id: modifiedAppointmentDetails.session_id,
            provider_id: modifiedAppointmentDetails.provider_id,
            session_history_id: modifiedAppointmentDetails.session_history_id,
            date_of_service: dateOfService
                ? formatZonedTimeToUtc(
                      modifiedAppointmentDetails.date_of_service as string
                  )
                : null,
            session_start_time: formatZonedTimeToUtc(
                modifiedAppointmentDetails.session_start_time as string
            ),
            session_end_time: formatZonedTimeToUtc(
                modifiedAppointmentDetails.session_end_time as string
            ),
            note_content: modifiedAppointmentDetails.note_content,
            note_type: modifiedAppointmentDetails.note_type,
            appointment_type:
                modifiedAppointmentDetails.appointment_type as SessionServiceType,
            cancelled_by:
                modifiedAppointmentDetails.note_type ===
                NoteTypes.CANCELLATION_NOTE
                    ? loggedInUser.data?.user_id
                    : undefined,
            reason_for_short_duration:
                modifiedAppointmentDetails.reason_for_short_duration,
        };

        const detailsToDisplay = {
            ...modifiedAppointmentDetails,
            date_of_service: dateOfService
                ? formatZonedTimeToUtc(
                      modifiedAppointmentDetails.date_of_service as string
                  )
                : undefined,
        };

        if (
            currentSession?.status === ProviderStatus.INACTIVE &&
            modifiedAppointmentDetails.note_type !== NoteTypes.TERMINATION_NOTE
        ) {
            toast({
                mode: "warning",
                message:
                    "You can only create Termination notes for inactive sessions",
            });
            return;
        }

        if (
            (createPayload.note_type.toLowerCase().includes("individual") &&
                createPayload.appointment_type
                    .toLowerCase()
                    .includes("family")) ||
            (createPayload.note_type.toLowerCase().includes("family") &&
                createPayload.appointment_type
                    .toLowerCase()
                    .includes("individual"))
        ) {
            toast({
                mode: "error",
                title: "Note type and appointment type mismatch",
                message: `You cannot create ${removeEnumUnderscore(
                    createPayload.note_type.replace("note", "")
                )} notes for ${removeEnumUnderscore(
                    createPayload.appointment_type
                )} session`,
            });
            return;
        }

        if (!noteId) {
            createSessionNote.mutate(createPayload, {
                onSuccess: (res) => {
                    toast({
                        mode: "success",
                        message: `Draft note autosaved at ${showUserLocalTime()} `,
                    });
                    searchParams.set("note_id", res.data.note_id);
                    handleCreateNoteStepInView("add-note");
                    handleSetAppointmentDetailsInView(detailsToDisplay);
                    navigate(
                        `/add-note?note_id=${res.data.note_id}&note_type=${noteTypeInParam}&action=draft`
                    );
                },
                onError: (err) => {
                    const clientId = err.response?.data?.data?.client_id;
                    const noteID = err.response?.data?.data?.note_id;

                    if (clientId && noteID) {
                        toast({
                            mode: "error",
                            message: (
                                <>
                                    Duplicate draft note found. Please view
                                    existing draft note{" "}
                                    <Link
                                        to={`/notes/${clientId}/${noteID}`}
                                        className="text-primary underline"
                                        onClick={() => reactHotToast.remove()}
                                    >
                                        here
                                    </Link>
                                </>
                            ),
                            duration: 10000,
                        });
                        return;
                    }
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Could not create note at this time",
                    });
                },
            });
        }

        if (noteId) {
            const payload = {
                noteId: noteId as string,
                data: {
                    date_of_service: dateOfService
                        ? (modifiedAppointmentDetails.date_of_service as string)
                        : null,
                    session_start_time: formatZonedTimeToUtc(
                        modifiedAppointmentDetails.session_start_time as string
                    ),
                    session_end_time: formatZonedTimeToUtc(
                        modifiedAppointmentDetails.session_end_time as string
                    ),
                    note_type: modifiedAppointmentDetails.note_type,
                    appointment_type:
                        modifiedAppointmentDetails.appointment_type,
                    reason_for_short_duration:
                        modifiedAppointmentDetails.reason_for_short_duration ||
                        undefined,
                },
            };

            editDraftNote.mutate(payload, {
                onSuccess: async (res) => {
                    if (params.noteId) {
                        handleCreateNoteStepInView("edit-note");
                        navigate(
                            `/notes/${res.data.note_id}/${res.data.client.client_id}/edit?note_type=${res.data.type}&action=draft`
                        );
                    } else {
                        handleCreateNoteStepInView("add-note");
                        handleSetAppointmentDetailsInView(detailsToDisplay);
                        navigate(
                            `/add-note?note_id=${res.data.note_id}&note_type=${noteTypeInParam}&action=draft`
                        );
                    }

                    await queryClient.invalidateQueries({
                        queryKey: [
                            appoinmentDetails.client_id,
                            `session-note`,
                            noteId,
                        ],
                    });
                },
                onError: async (err) => {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Could not create note at this time",
                    });
                },
            });
        }
    };

    // Get filter options for sessions list
    const filteredOptions = getProviderSessionSelectOptions?.filter(
        (option) => {
            return option.label.props.children[0].props.children[0].props.children
                .split(" ")
                .filter(Boolean)
                .join(" ")
                .toLowerCase()
                .includes(searchValue.toLowerCase());
        }
    );

    React.useEffect(() => {
        if (sessionHistoryId && !noteId) {
            if (sessionHistoryDetails?.data?.data) {
                const selectedSession = sessionHistoryDetails?.data?.data;

                if (selectedSession?.first_session_date) {
                    setValue(
                        "first_session_date",
                        formatDate(
                            selectedSession?.first_session_date as string,
                            false,
                            "yyyy-MM-dd'T'HH:mm:ss'Z'"
                        )
                    );
                } else {
                    setValue("first_session_date", null);
                }
                if (appointmentTypeFromUrl) {
                    setValue(
                        "appointment_type",
                        appointmentTypeFromUrl as SessionServiceType
                    );
                } else {
                    setValue(
                        "appointment_type",
                        selectedSession?.service.toLowerCase() ===
                            "Individual".toLowerCase()
                            ? SessionServiceType.INDIVIDUAL_THERAPY
                            : selectedSession?.service
                    );
                }
                setValue(
                    "session_history_id",
                    selectedSession?.session_history_id as string
                );
                setValue(
                    "date_of_service",
                    formatDate(selectedSession?.start_date_time as string)
                );

                setValue("no_of_sessions", selectedSession?.no_of_sessions);
                setLocalDateOfService(
                    new Date(
                        formatDate(selectedSession?.start_date_time as string)
                    )
                );
                setValue(
                    "session_start_time",
                    formatDate(
                        selectedSession?.start_date_time as string,
                        false,
                        "HH:mm"
                    )
                );
                setValue(
                    "session_end_time",
                    formatDate(
                        selectedSession?.end_date_time as string,
                        false,
                        "HH:mm"
                    )
                );
                setValue(
                    "provider_id",
                    selectedSession?.provider.provider_id as string
                );
                setValue(
                    "client_name",
                    `${selectedSession?.client.first_name} ${selectedSession?.client.last_name}`
                );
                setValue(
                    "client_id",
                    selectedSession?.client.client_id as string
                );
                setValue(
                    "provider_name",
                    `${selectedSession?.provider.first_name} ${selectedSession?.provider.last_name}`
                );
                setValue("session_id", selectedSession?.session_id as string);
            }
        }
    }, [
        sessionHistoryDetails?.data?.data,
        sessionHistoryId,
        setValue,
        appointmentTypeFromUrl,
        noteId,
    ]);

    // This should run when we have session_history_id in the search params
    const getDefaultClientNameValue = () => {
        if (sessionHistoryId) {
            if (sessionHistoryDetails?.data?.data) {
                return makeLabelsAndValuesProviderSessionSelect(
                    sessionHistoryDetails?.data?.data
                );
            }
        }
        return undefined;
    };

    if (sessionHistoryId) {
        return (
            <section className="px-[1px]">
                {sessionHistoryDetails.isLoading && (
                    <div className={styles.loader}>
                        <div className={styles.loader_top}>
                            <Skeleton height="20px" width="200px" />
                        </div>
                        <div className={styles.loader_list}>
                            <Skeleton count={6} height="50px" />
                        </div>
                    </div>
                )}
                {sessionHistoryDetails.isError && (
                    <ListState
                        isError
                        errorMsg="Could not load provider sessions"
                        stateHelperText="Please reload page or use the feedback button to report this bug"
                    />
                )}
                {sessionHistoryDetails?.data?.data === null ? (
                    <ListState
                        isError={false}
                        emptyMessage="Could not fetch session details"
                        stateHelperText="Please reload page or use the feedback button to report this bug"
                    />
                ) : null}
                {sessionHistoryDetails?.data?.data && (
                    <div className={styles.form}>
                        <h2 className={cnm(styles.form_header, "fs-exclude")}>
                            Please select note type
                        </h2>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="fg">
                                <Controller
                                    name="note_type"
                                    control={control}
                                    defaultValue={
                                        getNoteTypeSelectOptions.find(
                                            (type) =>
                                                type.value ===
                                                    noteAppointmentDetails?.note_type ||
                                                type.value === noteTypeInParam
                                        )?.value
                                    }
                                    render={({ field }) => (
                                        <Select
                                            label="Note type"
                                            value={getNoteTypeSelectOptions.find(
                                                (type) =>
                                                    type.value ===
                                                        noteAppointmentDetails?.note_type ||
                                                    type.value ===
                                                        searchParams.get(
                                                            "note_type"
                                                        )
                                            )}
                                            isSearchable
                                            isDisabled={Boolean(params.noteId)}
                                            options={getNoteTypeSelectOptions}
                                            onChange={(val) => {
                                                field.onChange(
                                                    (val as Option).value
                                                );
                                                searchParams.set(
                                                    "note_type",
                                                    (val as Option).value
                                                );
                                                navigate(
                                                    params.noteId
                                                        ? `/notes/${
                                                              params.noteId
                                                          }/${
                                                              params.clientId
                                                          }/edit?${searchParams.toString()}`
                                                        : `/add-note?${searchParams.toString()}`
                                                );
                                            }}
                                            hasError={!!errors.note_type}
                                            errorText={
                                                errors.note_type?.message
                                            }
                                        />
                                    )}
                                />
                            </div>
                            <div className="fg">
                                <Controller
                                    name="client_name"
                                    control={control}
                                    defaultValue={
                                        sessionHistoryId
                                            ? getDefaultClientNameValue()?.value
                                            : getProviderSessionSelectOptions?.find(
                                                  (session) =>
                                                      session.value ===
                                                      noteAppointmentDetails?.session_id
                                              )?.value
                                    }
                                    render={({ field }) => (
                                        <Select
                                            label={
                                                practice?.is_supervisor &&
                                                noteType ===
                                                    NoteTypes.SUPERVISION_NOTE
                                                    ? "Supervisee’s client"
                                                    : "Client name"
                                            }
                                            options={
                                                data || !error || !isLoading
                                                    ? filteredOptions
                                                    : []
                                            }
                                            defaultValue={
                                                sessionHistoryId
                                                    ? getDefaultClientNameValue()
                                                    : getProviderSessionSelectOptions?.find(
                                                          (session) =>
                                                              session.value ===
                                                              noteAppointmentDetails?.session_id
                                                      )
                                            }
                                            isDisabled={
                                                Boolean(params.noteId) ||
                                                !data ||
                                                Boolean(error) ||
                                                isLoading
                                            }
                                            onInputChange={(value) =>
                                                setSearchValue(value)
                                            }
                                            filterOption={() => true} // Disable default filtering
                                            isSearchable
                                            placeholder={
                                                (!data &&
                                                    isLoading &&
                                                    "Loading...") ||
                                                (error &&
                                                    !isLoading &&
                                                    "Error loading clients") ||
                                                (data &&
                                                !isLoading &&
                                                practice?.is_supervisor &&
                                                noteType ===
                                                    NoteTypes.SUPERVISION_NOTE
                                                    ? "Supervisee’s client"
                                                    : "Client name")
                                            }
                                            onChange={(val) => {
                                                field.onChange(
                                                    (val as Option).value
                                                );
                                                setSelectedSessionId(
                                                    () => (val as Option).value
                                                );
                                                setValuesFromSelectedSession(
                                                    (val as Option).value
                                                );
                                            }}
                                            hasError={!!errors.client_name}
                                            errorText={
                                                errors.client_name?.message
                                            }
                                            wrapperClass={cnm({
                                                [styles.selectwrapper]:
                                                    selectedSessionId ||
                                                    sessionHistoryId,
                                            })}
                                            multiHasValues={Boolean(
                                                watch("client_name")
                                            )}
                                        />
                                    )}
                                />
                            </div>
                            {noteTypeInParam ? (
                                <div
                                    className="fg"
                                    hidden={hideNoteField(
                                        noteType ||
                                            noteAppointmentDetails?.note_type,
                                        "provider_name"
                                    )}
                                >
                                    <Input
                                        {...register("provider_name")}
                                        label="Supervisee name"
                                        placeholder="Supervisee name"
                                        hasError={!!errors.provider_name}
                                        errorText={
                                            errors.provider_name?.message
                                        }
                                        disabled
                                    />
                                </div>
                            ) : null}

                            <div
                                className="fg"
                                hidden={hideNoteField(
                                    noteType ||
                                        noteAppointmentDetails?.note_type,
                                    "appointment_type"
                                )}
                            >
                                <Controller
                                    name="appointment_type"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            label="Appointment type"
                                            value={getSessionTypeSelectOptions.find(
                                                (option) =>
                                                    option.value ===
                                                    appointmentType
                                            )}
                                            isSearchable
                                            options={
                                                getSessionTypeSelectOptions
                                            }
                                            onChange={(val) => {
                                                field.onChange(
                                                    (val as Option).value
                                                );
                                            }}
                                            hasError={!!errors.appointment_type}
                                            errorText={
                                                errors.appointment_type?.message
                                            }
                                        />
                                    )}
                                />
                            </div>
                            {shouldShowDOSAlert &&
                                noteType &&
                                noteType !== NoteTypes.GENERAL_UPDATE_NOTE && (
                                    <Alert
                                        title="Notes with Date of service"
                                        description="Adding date of service to this note means the client will be charged"
                                        type="info"
                                        classNames="mb-32"
                                    />
                                )}
                            {shouldShowLateNoteAlert() && (
                                <Alert
                                    title="Warning: Late Note"
                                    description="The date of service you entered is more than 24 hours ago. Notes created after this timeframe will be flagged as late for the Audit team"
                                    type="warning"
                                    classNames="mb-32"
                                />
                            )}
                            <div
                                className="fg"
                                hidden={hideNoteField(
                                    noteType ||
                                        noteAppointmentDetails?.note_type,
                                    "date_of_service"
                                )}
                            >
                                <Controller
                                    name="date_of_service"
                                    control={control}
                                    render={({ field }) => (
                                        <DatePicker
                                            label="Date of service"
                                            onChange={(date) => {
                                                field.onChange(date);
                                                setLocalDateOfService(date);
                                            }}
                                            maxDate={new Date(Date.now())}
                                            selected={localDateOfService}
                                            hasError={
                                                !notesWithOptionalDOS.includes(
                                                    searchParams.get(
                                                        "note_type"
                                                    ) as NoteTypes
                                                ) && !!errors.date_of_service
                                            }
                                            errorText={
                                                errors.date_of_service?.type ===
                                                "typeError"
                                                    ? "invalid date value"
                                                    : errors.date_of_service
                                                          ?.message
                                            }
                                            dateFormat="MMM d, yyyy"
                                        />
                                    )}
                                />
                            </div>
                            {shouldShowFutureNoteAlert() && (
                                <Alert
                                    title="Warning: Future Note"
                                    description="This note can only be saved as draft because the DOS is still in the future"
                                    type="warning"
                                    classNames="mb-32"
                                />
                            )}
                            <div className="fg fg-space-between two flex">
                                <Input
                                    hidden={hideNoteField(
                                        noteType ||
                                            noteAppointmentDetails?.note_type,
                                        "start_time"
                                    )}
                                    {...register("session_start_time")}
                                    label="Start time"
                                    placeholder="Start time"
                                    hasError={!!errors.session_start_time}
                                    errorText={
                                        errors.session_start_time?.message
                                    }
                                    type="time"
                                />

                                <Input
                                    hidden={hideNoteField(
                                        noteType ||
                                            noteAppointmentDetails?.note_type,
                                        "end_time"
                                    )}
                                    {...register("session_end_time")}
                                    label="End time"
                                    placeholder="End time"
                                    hasError={!!errors.session_end_time}
                                    errorText={errors.session_end_time?.message}
                                    type="time"
                                />
                            </div>
                            {isSessionShort() && (
                                <Input
                                    {...register("reason_for_short_duration")}
                                    label="Reason for short note duration"
                                    placeholder="Reason for short note duration"
                                    hasError={
                                        !!errors.reason_for_short_duration
                                    }
                                    errorText={
                                        errors.reason_for_short_duration
                                            ?.message
                                    }
                                />
                            )}
                            <div
                                className={cnm(generalStyles.footer, {
                                    [generalStyles.footer_full]: !isOpen,
                                })}
                            >
                                <Button
                                    className="!min-h-32"
                                    type="submit"
                                    disabled={
                                        createSessionNote.isLoading ||
                                        editDraftNote.isLoading
                                    }
                                >
                                    Continue
                                </Button>
                            </div>
                        </form>
                    </div>
                )}
            </section>
        );
    }

    return (
        <section className="px-[1px]">
            <div className={styles.form}>
                <h2 className={cnm(styles.form_header, "fs-exclude")}>
                    Please select note type
                </h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="fg">
                        <Controller
                            name="note_type"
                            control={control}
                            defaultValue={
                                getNoteTypeSelectOptions.find(
                                    (type) =>
                                        type.value ===
                                            noteAppointmentDetails?.note_type ||
                                        type.value === noteTypeInParam
                                )?.value
                            }
                            render={({ field }) => (
                                <Select
                                    label="Note type"
                                    value={getNoteTypeSelectOptions.find(
                                        (type) =>
                                            type.value ===
                                                noteAppointmentDetails?.note_type ||
                                            type.value ===
                                                searchParams.get("note_type")
                                    )}
                                    isSearchable
                                    isDisabled={Boolean(params.noteId)}
                                    options={getNoteTypeSelectOptions}
                                    onChange={(val) => {
                                        field.onChange((val as Option).value);
                                        searchParams.set(
                                            "note_type",
                                            (val as Option).value
                                        );
                                        navigate(
                                            `/add-note?${searchParams.toString()}`
                                        );
                                    }}
                                    hasError={!!errors.note_type}
                                    errorText={errors.note_type?.message}
                                />
                            )}
                        />
                    </div>
                    <div className="fg">
                        <Controller
                            name="client_name"
                            control={control}
                            defaultValue={
                                sessionHistoryId
                                    ? getDefaultClientNameValue()?.value
                                    : getProviderSessionSelectOptions?.find(
                                          (session) =>
                                              session.value ===
                                              noteAppointmentDetails?.session_id
                                      )?.value
                            }
                            render={({ field }) => (
                                <Select
                                    label={
                                        practice?.is_supervisor &&
                                        noteType === NoteTypes.SUPERVISION_NOTE
                                            ? "Supervisee’s client"
                                            : "Client name"
                                    }
                                    options={
                                        data || !error || !isLoading
                                            ? filteredOptions
                                            : []
                                    }
                                    defaultValue={
                                        sessionHistoryId
                                            ? getDefaultClientNameValue()
                                            : getProviderSessionSelectOptions?.find(
                                                  (session) =>
                                                      session.value ===
                                                      noteAppointmentDetails?.session_id
                                              )
                                    }
                                    isDisabled={
                                        Boolean(params.noteId) ||
                                        !data ||
                                        Boolean(error) ||
                                        isLoading
                                    }
                                    onInputChange={(value) =>
                                        setSearchValue(value)
                                    }
                                    filterOption={() => true} // Disable default filtering
                                    isSearchable
                                    placeholder={
                                        (!data && isLoading && "Loading...") ||
                                        (error &&
                                            !isLoading &&
                                            "Error loading clients") ||
                                        (data &&
                                        !isLoading &&
                                        practice?.is_supervisor &&
                                        noteType === NoteTypes.SUPERVISION_NOTE
                                            ? "Supervisee’s client"
                                            : "Client name")
                                    }
                                    onChange={(val) => {
                                        field.onChange((val as Option).value);
                                        setSelectedSessionId(
                                            () => (val as Option).value
                                        );
                                        setValuesFromSelectedSession(
                                            (val as Option).value
                                        );
                                    }}
                                    hasError={!!errors.client_name}
                                    errorText={errors.client_name?.message}
                                    wrapperClass={cnm({
                                        [styles.selectwrapper]:
                                            selectedSessionId,
                                    })}
                                    multiHasValues={Boolean(
                                        watch("client_name")
                                    )}
                                />
                            )}
                        />
                    </div>
                    {noteTypeInParam ? (
                        <div
                            className="fg"
                            hidden={hideNoteField(
                                noteType || noteAppointmentDetails?.note_type,
                                "provider_name"
                            )}
                        >
                            <Input
                                {...register("provider_name")}
                                label="Supervisee name"
                                placeholder="Supervisee name"
                                hasError={!!errors.provider_name}
                                errorText={errors.provider_name?.message}
                                disabled
                            />
                        </div>
                    ) : null}

                    <div
                        className="fg"
                        hidden={hideNoteField(
                            noteType || noteAppointmentDetails?.note_type,
                            "appointment_type"
                        )}
                    >
                        <Controller
                            name="appointment_type"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    label="Appointment type"
                                    value={getSessionTypeSelectOptions.find(
                                        (option) =>
                                            option.value === appointmentType
                                    )}
                                    options={getSessionTypeSelectOptions}
                                    onChange={(val) => {
                                        field.onChange((val as Option).value);
                                    }}
                                    isSearchable
                                    hasError={!!errors.appointment_type}
                                    errorText={errors.appointment_type?.message}
                                />
                            )}
                        />
                    </div>
                    {shouldShowDOSAlert &&
                        noteType &&
                        noteType !== NoteTypes.GENERAL_UPDATE_NOTE && (
                            <Alert
                                title="Notes with Date of service"
                                description="Adding date of service to this note means the client will be charged"
                                type="info"
                                classNames="mb-32"
                            />
                        )}
                    {shouldShowLateNoteAlert() && (
                        <Alert
                            title="Warning: Late Note"
                            description="The date of service you entered is more than 24 hours ago. Notes created after this timeframe will be flagged as late for the Audit team"
                            type="warning"
                            classNames="mb-32"
                        />
                    )}
                    <div
                        className="fg"
                        hidden={hideNoteField(
                            noteType || noteAppointmentDetails?.note_type,
                            "date_of_service"
                        )}
                    >
                        <Controller
                            name="date_of_service"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    label="Date of service"
                                    onChange={(date) => {
                                        field.onChange(date);
                                        setLocalDateOfService(date);
                                    }}
                                    hasError={
                                        !notesWithOptionalDOS.includes(
                                            searchParams.get(
                                                "note_type"
                                            ) as NoteTypes
                                        ) && !!errors.date_of_service
                                    }
                                    errorText={
                                        errors.date_of_service?.type ===
                                        "typeError"
                                            ? "invalid date value"
                                            : errors.date_of_service?.message
                                    }
                                    selected={localDateOfService}
                                    dateFormat="MMM d, yyyy"
                                />
                            )}
                        />
                    </div>
                    {shouldShowFutureNoteAlert() && (
                        <Alert
                            title="Warning: Future Note"
                            description="This note can only be saved as draft because the DOS is still in the future"
                            type="warning"
                            classNames="mb-32"
                        />
                    )}

                    <div className={cnm(styles.two, "fg")}>
                        <Input
                            hidden={hideNoteField(
                                noteType || noteAppointmentDetails?.note_type,
                                "start_time"
                            )}
                            {...register("session_start_time")}
                            label="Start time"
                            placeholder="Start time"
                            hasError={!!errors.session_start_time}
                            errorText={errors.session_start_time?.message}
                            type="time"
                        />

                        <Input
                            hidden={hideNoteField(
                                noteType || noteAppointmentDetails?.note_type,
                                "end_time"
                            )}
                            {...register("session_end_time")}
                            label="End time"
                            placeholder="End time"
                            hasError={!!errors.session_end_time}
                            errorText={errors.session_end_time?.message}
                            type="time"
                        />
                    </div>

                    {isSessionShort() && (
                        <Input
                            {...register("reason_for_short_duration")}
                            label="Reason for short note duration"
                            placeholder="Reason for short note duration"
                            hasError={!!errors.reason_for_short_duration}
                            errorText={
                                errors.reason_for_short_duration?.message
                            }
                        />
                    )}

                    <div
                        className={cnm(generalStyles.footer, {
                            [generalStyles.footer_full]: !isOpen,
                        })}
                    >
                        <Button
                            type="submit"
                            disabled={
                                createSessionNote.isLoading ||
                                editDraftNote.isLoading
                            }
                            className="!min-h-32"
                        >
                            Continue
                        </Button>
                    </div>
                </form>
            </div>
        </section>
    );
}
