import * as React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/auth/AuthContext";
import { auth } from "../firebase";
import usePractice from "./usePractice";
import axiosInstance from "../utils/axios";

export default function useAuthContext() {
    const user = React.useContext(AuthContext);

    const { handleChangeUserPractice } = usePractice();

    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const logOutUser = async () => {
        await auth.signOut();
        queryClient.clear();
        localStorage.removeItem("practice");
        localStorage.removeItem("from");
        handleChangeUserPractice?.(null, false);
        axiosInstance.defaults.headers.common.practice_id = undefined;
        navigate("/login", { replace: true });
    };

    return { user, logOutUser };
}
