import * as React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { parseISO } from "date-fns";
import {
    DatePicker,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    FormCheckbox,
    Input,
    RadioInput,
    Select,
    TextArea,
} from "@jhool-io/fe-components";
import { IGetClientNotes, ISessionNote } from "../../../../utils/types/notes";
import { useFetchDiagnosisCodes } from "../../../../hooks/queries";
import {
    formatDate,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../../utils/helpers";
import { AppointmentLocation } from "../../../../utils/types/session";
import { useFetchClientNotesList } from "../../../../hooks/queries/note";
import { formatDateString } from "../../CreateNote/SafetyPlan/SafetyPlan";
import LoadPreviousSessionNotificationModal from "../../LoadPreviousSessionNotificatiModal/LoadPreviousSessionNotificationModal";
import { TERMINATION_NOTE_LABELS } from "../../../../utils/constants";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";

export interface IEditTerminationNotePayload {
    duration: string;
    appointment_location: string;
    delivery_option: string;
    provider_location: string;
    other_provider_location?: string;
    consent_was_received_for_the_teletherapy_session: boolean;
    the_teletherapy_session_was_conducted_via_video: boolean;
    total_number_of_sessions: string;
    other_delivery_option?: string;
    diagnosis_codes: string[];
    first_session_date: string;
    last_session_date: string;
    presenting_problems: string;
    planned_treatment_and_goals: string;
    course_of_treatment: string;
    patient_final_condition: string;
    prognosis: string;
    reason_for_termination: string;
    discharge_plan_and_follow_up: string;
    signature: string;
}

interface EditTerminationProps {
    noteDetails: ISessionNote;
    currentAction: string | null;
    onSubmit: (data: IEditTerminationNotePayload) => void;
}

type Option = {
    label: React.ReactNode;
    value: string;
};

export default function EditTermination({
    noteDetails,
    currentAction,
    onSubmit,
}: EditTerminationProps) {
    // Local component state
    const [searchValue, setSearchValue] = React.useState("");
    const [localFirstSessionDate, setLocalFirstSessionDate] =
        React.useState<Date | null>(
            noteDetails.note_content?.first_session_date
                ? parseISO(noteDetails.note_content.first_session_date)
                : null
        );
    const [localLastSessionDate, setLocalLastSessionDate] =
        React.useState<Date | null>(
            noteDetails.note_content?.last_session_date
                ? parseISO(noteDetails.note_content.last_session_date)
                : null
        );
    const [showPrompt, setShowPrompt] = React.useState(false);
    const [selectedNote, setSelectedNote] = React.useState(
        {} as IGetClientNotes
    );
    const clientNoteParams = {
        type: noteDetails?.type as string,
        load_previous_notes: true,
        provider_id: noteDetails?.provider?.provider_id as string,
    };
    const { data: notes } = useFetchClientNotesList(
        noteDetails?.client?.client_id as string,
        clientNoteParams
    );

    // Fetch diagonis codes
    const { data, isLoading, error } = useFetchDiagnosisCodes();

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        formState: { errors },
    } = useForm<IEditTerminationNotePayload>({
        resolver: yupResolver(
            yup.object({
                duration: yup.string(),
                appointment_location: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup.string().required("Session location is required"),
                    otherwise: yup.string(),
                }),
                total_number_of_sessions: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup
                        .string()
                        .required("Total number of sessions is required")
                        .test(
                            "is-number",
                            "Field should only contain numbers",
                            (value) => {
                                if (!value || value.trim() === "") {
                                    return true;
                                }

                                const regex = /^\d+$/;
                                return regex.test(value);
                            }
                        ),
                    otherwise: yup
                        .string()
                        .test(
                            "is-number",
                            "Field should only contain numbers",
                            (value) => {
                                if (!value || value.trim() === "") {
                                    return true;
                                }

                                const regex = /^\d+$/;
                                return regex.test(value);
                            }
                        ),
                }),
                first_session_date: yup.date().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup.date().required("First session date is required"),
                    otherwise: yup.date().nullable(),
                }),
                last_session_date: yup.date().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup
                        .date()
                        .required("Last session date is required")
                        .test(
                            "is-after-or-equal",
                            `Last session date cannot be earlier than first session date`,
                            (
                                value,
                                context: {
                                    parent: {
                                        first_session_date: Date;
                                    };
                                }
                            ) => {
                                const { first_session_date: firstSessionDate } =
                                    context.parent;

                                if (!firstSessionDate || !value) {
                                    // If either date is not provided, don't perform the validation
                                    return true;
                                }

                                return value >= firstSessionDate;
                            }
                        ),
                    otherwise: yup
                        .date()
                        .test(
                            "is-after-or-equal",
                            `Last session date cannot be earlier than first session date`,
                            (
                                value,
                                context: {
                                    parent: {
                                        first_session_date: Date;
                                    };
                                }
                            ) => {
                                const { first_session_date: firstSessionDate } =
                                    context.parent;

                                if (!firstSessionDate || !value) {
                                    // If either date is not provided, don't perform the validation
                                    return true;
                                }

                                return value >= firstSessionDate;
                            }
                        )
                        .nullable(),
                }),
                delivery_option: yup
                    .string()
                    .nullable()
                    .when(
                        "appointment_location",
                        (appointment_location, field) =>
                            appointment_location === "teletherapy" &&
                            currentAction === "with_signature"
                                ? field.required(
                                      "Client teletherapy location is required"
                                  )
                                : field
                    ),
                provider_location: yup
                    .string()
                    .nullable()
                    .when(
                        "appointment_location",
                        (appointment_location, field) =>
                            appointment_location === "teletherapy" &&
                            currentAction === "with_signature"
                                ? field.required(
                                      "Provider teletherapy location is required"
                                  )
                                : field
                    ),
                consent_was_received_for_the_teletherapy_session: yup.bool(),
                the_teletherapy_session_was_conducted_via_video: yup.bool(),
                other_delivery_option: yup
                    .string()
                    .when("delivery_option", (delivery_option, field) =>
                        delivery_option === "Other" &&
                        currentAction === "with_signature"
                            ? field.required(
                                  "Other client teletherapy location is required"
                              )
                            : field
                    ),
                other_provider_location: yup
                    .string()
                    .when("provider_location", (provider_location, field) =>
                        provider_location === "Other" &&
                        currentAction === "with_signature"
                            ? field.required(
                                  "Other provider teletherapy location is required"
                              )
                            : field
                    ),
                diagnosis_codes: yup.array().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup
                        .array()
                        .max(3, "You can only select 3 diagnosis")
                        .min(1, "Diagnosis is required")
                        .required("Diagnosis is required"),
                    otherwise: yup
                        .array()
                        .nullable()
                        .max(3, "You can only select 3 diagnosis"),
                }),
                presenting_problems: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup
                        .string()
                        .required("Presenting problems is required"),
                    otherwise: yup.string(),
                }),
                planned_treatment_and_goals: yup.string(),
                course_of_treatment: yup.string(),
                patient_final_condition: yup.string(),
                prognosis: yup.string(),
                reason_for_termination: yup.string(),
                discharge_plan_and_follow_up: yup.string(),
                signature: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup.string().required("Signature is required"),
                    otherwise: yup.string(),
                }),
            })
        ),
        mode: "onChange",
        defaultValues: noteDetails.note_content || undefined,
    });

    const diagnosisCodes = watch("diagnosis_codes");

    // Get diagnosis codes for select
    const DiagnosisCodesForSelect = data?.data.map((diagnosis) => ({
        label: `${diagnosis.code} ${diagnosis.description}`,
        value: diagnosis.code,
    }));

    // Get filter options for diagnosis list
    const filterDiagnosisOptions = (phrases: string[]) => {
        const filteredOptions = DiagnosisCodesForSelect?.filter((option) =>
            phrases.every(
                (phrase) =>
                    option &&
                    option.label.toLowerCase().includes(phrase.toLowerCase())
            )
        );
        return filteredOptions;
    };
    // Get appointment location options
    const AppointmentLocationsForSelect = Object.values(
        AppointmentLocation
    ).map((location) => {
        return {
            label: removeEnumUnderscore(makeStringFirstLetterCapital(location)),
            value: location,
        };
    });

    // Function to handle item click and set the selected note content
    const handleLoadPreviousSession = (note: IGetClientNotes) => {
        setValue(
            "appointment_location",
            note.note_content.appointment_location
        );
        setValue(
            "consent_was_received_for_the_teletherapy_session",
            note.note_content.consent_was_received_for_the_teletherapy_session
        );
        setValue("course_of_treatment", note.note_content.course_of_treatment);
        setValue("delivery_option", note.note_content.delivery_option);
        setValue("diagnosis_codes", note.note_content.diagnosis_codes);
        setValue(
            "discharge_plan_and_follow_up",
            note.note_content.discharge_plan_and_follow_up
        );
        setValue("duration", note.note_content.duration);
        setValue("first_session_date", note.note_content.first_session_date);
        setValue("last_session_date", note.note_content.last_session_date);
        setValue(
            "other_delivery_option",
            note.note_content.other_delivery_option
        );
        setValue(
            "other_provider_location",
            note.note_content.other_provider_location
        );
        setValue(
            "patient_final_condition",
            note.note_content.patient_final_condition
        );
        setValue(
            "planned_treatment_and_goals",
            note.note_content.planned_treatment_and_goals
        );
        setValue("presenting_problems", note.note_content.presenting_problems);
        setValue("prognosis", note.note_content.prognosis);
        setValue("provider_location", note.note_content.provider_location);
        setValue(
            "reason_for_termination",
            note.note_content.reason_for_termination
        );
        setValue(
            "the_teletherapy_session_was_conducted_via_video",
            note.note_content.the_teletherapy_session_was_conducted_via_video
        );
        setValue(
            "total_number_of_sessions",
            note.note_content.total_number_of_sessions
        );
        setShowPrompt(false);
    };

    return (
        <div>
            <LoadPreviousSessionNotificationModal
                showPrompt={showPrompt}
                setShowPrompt={setShowPrompt}
                handleLoadPreviousSession={() =>
                    handleLoadPreviousSession(selectedNote)
                }
            />
            {notes && notes.data.length > 0 && (
                <div className="flex justify-end mb-24">
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <FilterButton text="Load previous session note" />
                        </DropdownTrigger>
                        <DropdownContent width="auto" align="end">
                            {notes &&
                                notes?.data?.map((note) => (
                                    <DropdownItem
                                        key={note.note_id}
                                        onClick={() => {
                                            setShowPrompt(true);
                                            setSelectedNote(note);
                                        }}
                                    >
                                        <div>
                                            {makeStringFirstLetterCapital(
                                                removeEnumUnderscore(
                                                    note.note_type
                                                )
                                            )}{" "}
                                            - Note content Details -{" "}
                                            {formatDate(note.date_of_service)}
                                        </div>
                                    </DropdownItem>
                                ))}
                        </DropdownContent>
                    </Dropdown>
                </div>
            )}
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="pb-[60px] md:pb-[150px]"
                aria-label="termination form"
                id="edit-note-form"
            >
                <div className="fg">
                    <Controller
                        name="appointment_location"
                        control={control}
                        render={({ field }) => (
                            <Select
                                isRequired
                                label={
                                    TERMINATION_NOTE_LABELS.appointment_location
                                }
                                placeholder={
                                    TERMINATION_NOTE_LABELS.appointment_location
                                }
                                onChange={(val) =>
                                    field.onChange((val as Option).value)
                                }
                                value={
                                    AppointmentLocationsForSelect.find(
                                        (location) =>
                                            location.value === field.value
                                    ) || null
                                }
                                options={AppointmentLocationsForSelect}
                                hasError={!!errors.appointment_location}
                                errorText={errors.appointment_location?.message}
                                onBlur={handleSubmit(onSubmit)}
                            />
                        )}
                    />
                </div>
                {watch(`appointment_location`) ===
                    AppointmentLocation.TELETHERAPY && (
                    <>
                        <p className="note-label">
                            {TERMINATION_NOTE_LABELS.delivery_option}
                        </p>
                        <div className="fg fg-space-between three flex">
                            <RadioInput
                                {...register(`delivery_option`)}
                                label="Home"
                                value="Home"
                                defaultChecked={
                                    noteDetails.note_content
                                        ?.delivery_option === "Home"
                                }
                                onBlur={handleSubmit(onSubmit)}
                            />
                            <RadioInput
                                {...register(`delivery_option`)}
                                label="Office"
                                value="Office"
                                defaultChecked={
                                    noteDetails.note_content
                                        ?.delivery_option === "Office"
                                }
                                onBlur={handleSubmit(onSubmit)}
                            />
                            <RadioInput
                                {...register(`delivery_option`)}
                                label="Other"
                                value="Other"
                                defaultChecked={
                                    noteDetails.note_content
                                        ?.delivery_option === "Other"
                                }
                                onBlur={handleSubmit(onSubmit)}
                            />
                        </div>
                        {errors.delivery_option ? (
                            <div className="note-error">
                                <p>Delivery option is required</p>
                            </div>
                        ) : null}
                        {watch(`delivery_option`) === "Other" ||
                        noteDetails.note_content?.delivery_option ===
                            "Other" ? (
                            <div className="fg">
                                <Input
                                    {...register(`other_delivery_option`)}
                                    label={
                                        TERMINATION_NOTE_LABELS.other_delivery_option
                                    }
                                    placeholder={
                                        TERMINATION_NOTE_LABELS.other_delivery_option
                                    }
                                    hasError={!!errors?.other_delivery_option}
                                    errorText={
                                        errors?.other_delivery_option?.message
                                    }
                                    defaultValue={
                                        noteDetails.note_content
                                            ?.other_delivery_option
                                    }
                                    onBlur={handleSubmit(onSubmit)}
                                />
                            </div>
                        ) : null}
                    </>
                )}
                {watch(`appointment_location`) ===
                    AppointmentLocation.TELETHERAPY && (
                    <>
                        <p className="note-label">
                            {TERMINATION_NOTE_LABELS.provider_location}
                        </p>
                        <div className="fg fg-space-between three flex">
                            <RadioInput
                                {...register(`provider_location`)}
                                label="Home"
                                value="Home"
                                defaultChecked={
                                    noteDetails.note_content
                                        ?.provider_location === "Home"
                                }
                                onBlur={handleSubmit(onSubmit)}
                            />
                            <RadioInput
                                {...register(`provider_location`)}
                                label="Office"
                                value="Office"
                                defaultChecked={
                                    noteDetails.note_content
                                        ?.provider_location === "Office"
                                }
                                onBlur={handleSubmit(onSubmit)}
                            />
                            <RadioInput
                                {...register(`provider_location`)}
                                label="Other"
                                value="Other"
                                defaultChecked={
                                    noteDetails.note_content
                                        ?.provider_location === "Other"
                                }
                                onBlur={handleSubmit(onSubmit)}
                            />
                        </div>
                        {errors.provider_location ? (
                            <div className="note-error">
                                <p>Delivery option is required</p>
                            </div>
                        ) : null}
                        {watch(`provider_location`) === "Other" ||
                        noteDetails.note_content?.provider_location ===
                            "Other" ? (
                            <div className="fg">
                                <Input
                                    {...register(`other_provider_location`)}
                                    label={
                                        TERMINATION_NOTE_LABELS.other_provider_location
                                    }
                                    placeholder={
                                        TERMINATION_NOTE_LABELS.other_provider_location
                                    }
                                    hasError={!!errors?.other_provider_location}
                                    errorText={
                                        errors?.other_provider_location?.message
                                    }
                                    defaultValue={
                                        noteDetails.note_content
                                            ?.other_provider_location
                                    }
                                    onBlur={handleSubmit(onSubmit)}
                                />
                            </div>
                        ) : null}
                    </>
                )}
                {watch("appointment_location") ===
                AppointmentLocation.TELETHERAPY ? (
                    <div className="note-checkboxes">
                        <FormCheckbox
                            {...register(
                                "consent_was_received_for_the_teletherapy_session"
                            )}
                            label={
                                TERMINATION_NOTE_LABELS.consent_was_received_for_the_teletherapy_session
                            }
                            defaultChecked={
                                noteDetails.note_content
                                    ?.consent_was_received_for_the_teletherapy_session
                            }
                            onBlur={handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(
                                "the_teletherapy_session_was_conducted_via_video"
                            )}
                            label={
                                TERMINATION_NOTE_LABELS.the_teletherapy_session_was_conducted_via_video
                            }
                            defaultChecked={
                                noteDetails.note_content
                                    ?.the_teletherapy_session_was_conducted_via_video
                            }
                            onBlur={handleSubmit(onSubmit)}
                        />
                    </div>
                ) : null}
                <div className="fg fg-space-between two flex">
                    <Controller
                        name="first_session_date"
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                isRequired
                                label={
                                    TERMINATION_NOTE_LABELS.first_session_date
                                }
                                onChange={(date) => {
                                    field.onChange(date);
                                    setLocalFirstSessionDate(date);
                                }}
                                value={formatDateString(field.value)}
                                maxDate={new Date(Date.now())}
                                selected={localFirstSessionDate}
                                hasError={!!errors.first_session_date}
                                errorText={
                                    errors.first_session_date?.type ===
                                    "typeError"
                                        ? "Invalid date value"
                                        : errors.first_session_date?.message
                                }
                                onBlur={handleSubmit(onSubmit)}
                            />
                        )}
                    />
                    <Controller
                        name="last_session_date"
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                isRequired
                                label={
                                    TERMINATION_NOTE_LABELS.last_session_date
                                }
                                onChange={(date) => {
                                    field.onChange(date);
                                    setLocalLastSessionDate(date);
                                }}
                                value={formatDateString(field.value)}
                                maxDate={new Date(Date.now())}
                                selected={localLastSessionDate}
                                hasError={!!errors.last_session_date}
                                errorText={
                                    errors.last_session_date?.type ===
                                    "typeError"
                                        ? "Invalid date value"
                                        : errors.last_session_date?.message
                                }
                                onBlur={handleSubmit(onSubmit)}
                            />
                        )}
                    />
                </div>
                <div className="fg">
                    <Input
                        {...register("total_number_of_sessions")}
                        label={TERMINATION_NOTE_LABELS.total_number_of_sessions}
                        placeholder={
                            TERMINATION_NOTE_LABELS.total_number_of_sessions
                        }
                        isRequired
                        hasError={!!errors.total_number_of_sessions}
                        errorText={errors.total_number_of_sessions?.message}
                        defaultValue={noteDetails.note_content.no_of_sessions}
                        onBlur={handleSubmit(onSubmit)}
                    />
                </div>
                <div className="fg">
                    <Controller
                        name="diagnosis_codes"
                        control={control}
                        render={({ field }) => (
                            <Select
                                label={TERMINATION_NOTE_LABELS.diagnosis_codes}
                                isRequired
                                placeholder={
                                    (!data && isLoading && "Loading...") ||
                                    (error &&
                                        !isLoading &&
                                        "Error loading diagnosis") ||
                                    (data &&
                                        !isLoading &&
                                        TERMINATION_NOTE_LABELS.diagnosis_codes)
                                }
                                isSearchable
                                onInputChange={(value) => setSearchValue(value)}
                                filterOption={() => true} // Disable default filtering
                                options={filterDiagnosisOptions(
                                    searchValue.split(" ")
                                )}
                                onChange={(val) => {
                                    field.onChange(
                                        (val as Option[]).map(
                                            (code) => code.value
                                        )
                                    );
                                }}
                                isDisabled={isLoading || Boolean(error)}
                                hasError={!!errors.diagnosis_codes}
                                value={
                                    DiagnosisCodesForSelect?.filter(
                                        (diagnosis) =>
                                            field.value?.includes(
                                                diagnosis.value
                                            )
                                    ) || null
                                }
                                hideSelectedOptions
                                errorText={
                                    errors.diagnosis_codes?.type === "typeError"
                                        ? "Diagnosis codes are required"
                                        : errors.diagnosis_codes?.message
                                }
                                isMulti
                                multiHasValues={
                                    diagnosisCodes && diagnosisCodes.length > 0
                                }
                                onBlur={handleSubmit(onSubmit)}
                            />
                        )}
                    />
                </div>
                <div className="fg">
                    <TextArea
                        {...register("presenting_problems")}
                        isRequired
                        label={TERMINATION_NOTE_LABELS.presenting_problems}
                        placeholder={
                            TERMINATION_NOTE_LABELS.presenting_problems
                        }
                        defaultValue={
                            noteDetails.note_content?.presenting_problems
                        }
                        hasError={!!errors.presenting_problems}
                        errorText={errors.presenting_problems?.message}
                        onBlur={handleSubmit(onSubmit)}
                    />
                </div>
                <div className="fg">
                    <TextArea
                        {...register("planned_treatment_and_goals")}
                        label={
                            TERMINATION_NOTE_LABELS.planned_treatment_and_goals
                        }
                        placeholder={
                            TERMINATION_NOTE_LABELS.planned_treatment_and_goals
                        }
                        defaultValue={
                            noteDetails.note_content
                                ?.planned_treatment_and_goals
                        }
                        onBlur={handleSubmit(onSubmit)}
                    />
                </div>
                <div className="fg">
                    <TextArea
                        {...register("course_of_treatment")}
                        label={TERMINATION_NOTE_LABELS.course_of_treatment}
                        placeholder={
                            TERMINATION_NOTE_LABELS.course_of_treatment
                        }
                        defaultValue={
                            noteDetails.note_content?.course_of_treatment
                        }
                        onBlur={handleSubmit(onSubmit)}
                    />
                </div>
                <div className="fg">
                    <Input
                        {...register("patient_final_condition")}
                        label={TERMINATION_NOTE_LABELS.patient_final_condition}
                        placeholder={
                            TERMINATION_NOTE_LABELS.patient_final_condition
                        }
                        defaultValue={
                            noteDetails.note_content?.patient_final_condition
                        }
                        onBlur={handleSubmit(onSubmit)}
                    />
                </div>
                <div className="fg">
                    <Input
                        {...register("prognosis")}
                        label={TERMINATION_NOTE_LABELS.prognosis}
                        placeholder={TERMINATION_NOTE_LABELS.prognosis}
                        defaultValue={noteDetails.note_content?.prognosis}
                        onBlur={handleSubmit(onSubmit)}
                    />
                </div>
                <div className="fg">
                    <TextArea
                        {...register("reason_for_termination")}
                        label={TERMINATION_NOTE_LABELS.reason_for_termination}
                        placeholder={
                            TERMINATION_NOTE_LABELS.reason_for_termination
                        }
                        defaultValue={
                            noteDetails.note_content?.reason_for_termination
                        }
                        onBlur={handleSubmit(onSubmit)}
                    />
                </div>
                <div className="fg">
                    <TextArea
                        {...register("discharge_plan_and_follow_up")}
                        label={
                            TERMINATION_NOTE_LABELS.discharge_plan_and_follow_up
                        }
                        placeholder={
                            TERMINATION_NOTE_LABELS.discharge_plan_and_follow_up
                        }
                        defaultValue={
                            noteDetails.note_content
                                ?.discharge_plan_and_follow_up
                        }
                        onBlur={handleSubmit(onSubmit)}
                    />
                </div>
                <div className="fg-info fg-line">
                    <p>Sign note here</p>
                    <div className="fg">
                        <Input
                            {...register("signature")}
                            hasError={!!errors.signature}
                            errorText={errors.signature?.message}
                            label="Provider's Initials"
                            placeholder="Provider's Initials"
                            autoComplete="off"
                            isRequired
                        />
                    </div>
                </div>
            </form>
        </div>
    );
}
