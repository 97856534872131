import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
    exportPdfs,
    fetchDiagnosisCodes,
    fetchDocument,
    fetchProviderSessions,
    fetchSingleSessionDetails,
} from "../../services";
import { IDiagnosisCode, IExportPdf } from "../../utils/types";
import { IApiResponse } from "../../utils/types/api-response";
import { IProviderSession } from "../../utils/types/session";
import useToast from "../useToast";
import { handleRemoveEmptyParamFromQueryParams } from "../../utils/helpers";

// Fetch diagnosis codes hook
export function useFetchDiagnosisCodes() {
    return useQuery<
        IApiResponse<IDiagnosisCode[]>,
        AxiosError<IApiResponse<IDiagnosisCode[]>>
    >({
        queryKey: ["diagnosis-codes"],
        queryFn: () => fetchDiagnosisCodes(),
        staleTime: Infinity,
    });
}

// Fetch provider's sessions hook
export function useFetchProviderSessions() {
    return useQuery<
        IApiResponse<IProviderSession[]>,
        AxiosError<IApiResponse<IProviderSession[]>>
    >({
        queryKey: ["provider-sessions"],
        queryFn: () => fetchProviderSessions(),
    });
}

// Fetch single provider session hook
export function useFetchSingleProviderSession(sessionHistoryId: string) {
    return useQuery<
        IApiResponse<IProviderSession>,
        AxiosError<IApiResponse<IProviderSession>>
    >({
        queryKey: [sessionHistoryId, "provider-session"],
        queryFn: () => fetchSingleSessionDetails(sessionHistoryId),
        enabled: Boolean(sessionHistoryId),
    });
}

// Get uploaded document buffer hook
export function useFetchDocument(fileUrl: string, enabled: boolean) {
    return useQuery<{ data: string }, AxiosError<{ data: string }>>({
        queryKey: ["document-buffer", fileUrl],
        queryFn: () => fetchDocument(fileUrl),
        enabled,
    });
}

export function useExportPdfs(params: IExportPdf, isEnabled: boolean) {
    const toast = useToast();

    return useQuery<Blob, AxiosError<string>>({
        queryKey: [
            "export-pdfs",
            handleRemoveEmptyParamFromQueryParams(params),
        ],
        queryFn: () => exportPdfs(params),
        enabled: isEnabled,
        onSuccess: (data) => {
            const href = window.URL.createObjectURL(data);

            const anchorElement = document.createElement("a");
            anchorElement.href = href;

            document.body.appendChild(anchorElement);
            anchorElement.click();

            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(href);
        },
        onError: () => {
            if (params.pdf_type === "statement_of_account") {
                toast.toast({
                    mode: "error",
                    message: "Could not download statement of account.",
                });
            } else {
                toast.toast({
                    mode: "error",
                    message: "Could not export notes at this time",
                });
            }
        },
        retry: 2,
        retryDelay: 1000,
    });
}
