import * as React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { parseISO } from "date-fns";
import {
    DatePicker,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    FormCheckbox,
    Input,
    RadioInput,
    Select,
    TextArea,
} from "@jhool-io/fe-components";
import {
    IGetClientNotes,
    INewNoteAppoinmentDetails,
} from "../../../../utils/types/notes";
import { useFetchDiagnosisCodes } from "../../../../hooks/queries";
import {
    cn,
    formatDate,
    getTimeDuration,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../../utils/helpers";
import { AppointmentLocation } from "../../../../utils/types/session";
import {
    ClientEducation,
    ClientGender,
    ClientRace,
    ClientRelationship,
    ClientReligion,
    ClientSexualOrientation,
} from "../../../../utils/types/client";
import Accordion from "../../../Accordion/Accordion";
import NoteFormInfoBox from "../../NoteFormInfoBox/NoteFormInfoBox";
import { useFetchClientNotesList } from "../../../../hooks/queries/note";
import LoadPreviousSessionNotificationModal from "../../LoadPreviousSessionNotificatiModal/LoadPreviousSessionNotificationModal";
import { INDIVIDUAL_INTAKE_NOTE_DETAILS } from "../../../../utils/constants";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";

export interface ICreateIndividualIntakeNote {
    appointment_location: string;
    diagnosis_codes: string[];
    delivery_option: string;
    provider_location: string;
    other_provider_location?: string;
    other_delivery_option?: string;
    consent_was_received_for_the_teletherapy_session: boolean;
    the_teletherapy_session_was_conducted_via_video: boolean;
    duration: string;
    gender: string;
    religion: string;
    education: string;
    race: string;
    ethinicity: string;
    sexual_orientation: string;
    current_employment: string;
    current_employment_details: string;
    relationship_status: string;
    name_of_partner: string;
    emergency_contact_information: string;
    legal_problems: string;
    what_are_the_problem_you_are_seeking_help_for: string;
    anxiety_frequency: string;
    anxiety_details: string;
    panic_attacks_freqency: string;
    panic_attacks_details: string;
    depression_freqency: string;
    depression_details: string;
    selected_symptoms: string;
    other_symptoms: string[];
    physical_symptoms: string;
    medication_physical_psychiatric: string;
    sleeping_habits_and_concerns: string;
    difficulties_with_appetite_or_eating_patterns: string;
    parents_stepparents_history: string;
    siblings_history: string;
    grandparents_history: string;
    children_history: string;
    other_family_members_history: string;
    family_members_alcohol_abuse: string[];
    family_members_anxiety: string[];
    family_members_bipolar_disorder: string[];
    family_members_depression: string[];
    family_members_ptsd: string[];
    family_members_schizophrenia: string[];
    family_members_substance_abuse: string[];
    family_members_substance_abuse_substance: string;
    trauma_abuse_history: string;
    previous_mental_health_treatment_oupatient: string;
    impatient_psychiatric_hospitalization: string;
    relevant_medical_history: string;
    been_so_distressed_you_seriously_wished_to_end_your_life: string;
    has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live: string;
    describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live: string;
    a_specific_plan_of_how_you_would_kill_yourself: string;
    access_to_weapons_or_means_of_hurting_self: string;
    made_serious_suicide_attempts: string;
    purposely_done_something_to_hurt_yourself: string;
    describe_done_something_to_hurt_yourself: string;
    heard_voices_telling_you_to_hurt_yourself: string;
    had_relatives_who_attempted_or_commited_sucide: string;
    had_thoughts_of_killing_or_seriously_hurting_someone: string;
    heard_voices_telling_you_to_hurt_others: string;
    hurt_someone_or_destroyed_property_on_purpose: string;
    slapped_kicked_punched_someone_with_intent_to_harm: string;
    been_arrested_or_detained_for_violent_behavior: string;
    been_to_jail_for_any_reason: string;
    contact_information_for_both_parents: string;
    name_address_of_personal_physician: string;
    date_of_last_exam: string;
    been_on_probation_for_any_reason: string;
    do_you_have_access_to_guns: string;
    mental_health_appearance: string[];
    mental_health_movement: string[];
    mental_health_speech_behaviour: string[];
    mental_health_affect_mood: string[];
    mental_health_attention_concentration_effort: string[];
    mental_health_presentation_of_self: string[];
    mental_health_cognition: string[];
    patients_ability_and_capability_to_respond_to_treatment: string[];
    details_for_diminished_poor_limited_quality_patient_oriented_evidence: string;
    people_present_at_session: string[];
    other_person_present_at_session: string;
    type_of_treatment_recommended: string[];
    treatment_modality_intervention: string[];
    other_treatment_modality_intervention: string;
    treatment_goal_one: string;
    treatment_goal_two: string;
    treatment_goal_three: string;
    subjective_report: string;
    frequency_of_sessions: string;
    other_frequency_of_sessions: string;
    date_of_next_session: string;
    summary: string;
    prognosis: string;
    communication_factors_impacting_treatment: string[];
    details_around_communication_factors_impacting_treatment: string;
    anxiety?: boolean;
    panic_attacks?: boolean;
    depression?: boolean;
    signature?: string;
}

interface IndividualIntakeProps {
    appointmentDetails?: INewNoteAppoinmentDetails | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formValues?: { [key: string]: any };
    currentAction?: string | null;
    onSubmit?: (data: ICreateIndividualIntakeNote) => void;
    isMinor: boolean;
}

type Option = {
    label: React.ReactNode;
    value: string;
};

export default function IndividualIntake({
    appointmentDetails,
    formValues,
    currentAction,
    onSubmit,
    isMinor,
}: IndividualIntakeProps) {
    // Local component state
    const [localDateOfNextSession, setLocalDateOfNextSession] =
        React.useState<Date | null>(
            formValues?.date_of_next_session
                ? parseISO(formValues?.date_of_next_session)
                : null
        );
    const [localDateOfLastExam, setLocalDateOfLastExam] =
        React.useState<Date | null>(
            formValues?.date_of_last_exam
                ? parseISO(formValues?.date_of_last_exam)
                : null
        );
    const [searchValue, setSearchValue] = React.useState("");

    // Fetch diagonis codes
    const { data, isLoading, error } = useFetchDiagnosisCodes();

    const clientNoteParams = {
        type: appointmentDetails?.note_type as string,
        load_previous_notes: true,
        provider_id: appointmentDetails?.provider_id as string,
    };
    const [showPrompt, setShowPrompt] = React.useState(false);
    const [selectedNote, setSelectedNote] = React.useState(
        {} as IGetClientNotes
    );
    const { data: notes } = useFetchClientNotesList(
        appointmentDetails?.client_id as string,
        clientNoteParams
    );

    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors },
    } = useForm<ICreateIndividualIntakeNote>({
        resolver: yupResolver(
            yup.object({
                duration: yup.string(),
                appointment_location: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup.string().required("Session location is required"),
                    otherwise: yup.string(),
                }),
                delivery_option: yup
                    .string()
                    .nullable()
                    .when(
                        "appointment_location",
                        (appointment_location, field) =>
                            appointment_location === "teletherapy" &&
                            currentAction === "with_signature"
                                ? field.required(
                                      "Client teletherapy location is required"
                                  )
                                : field
                    ),
                provider_location: yup
                    .string()
                    .nullable()
                    .when(
                        "appointment_location",
                        (appointment_location, field) =>
                            appointment_location === "teletherapy" &&
                            currentAction === "with_signature"
                                ? field.required(
                                      "Provider teletherapy location is required"
                                  )
                                : field
                    ),
                consent_was_received_for_the_teletherapy_session: yup.bool(),
                the_teletherapy_session_was_conducted_via_video: yup.bool(),
                other_delivery_option: yup
                    .string()
                    .when("delivery_option", (delivery_option, field) =>
                        delivery_option === "Other" &&
                        currentAction === "with_signature"
                            ? field.required(
                                  "Other provider teletherapy location is required"
                              )
                            : field
                    ),
                other_provider_location: yup
                    .string()
                    .when("provider_location", (provider_location, field) =>
                        provider_location === "Other" &&
                        currentAction === "with_signature"
                            ? field.required(
                                  "Other provider teletherapy location is required"
                              )
                            : field
                    ),
                diagnosis_codes: yup.array().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup
                        .array()
                        .max(3, "You can only select 3 diagnosis")
                        .min(1, "Diagnosis is required")
                        .required("Diagnosis is required"),
                    otherwise: yup
                        .array()
                        .nullable()
                        .max(3, "You can only select 3 diagnosis"),
                }),
                gender: yup.string(),
                religion: yup.string(),
                education: yup.string(),
                race: yup.string(),
                ethinicity: yup.string(),
                sexual_orientation: yup.string(),
                current_employment: yup.string(),
                current_employment_details: yup.string(),
                relationship_status: yup.string(),
                name_of_partner: yup.string(),
                emergency_contact_information: yup.string(),
                contact_information_for_both_parents: yup.string(),
                name_address_of_personal_physician: yup.string(),
                date_of_last_exam: yup.date().nullable(),
                legal_problems: yup.string(),
                what_are_the_problem_you_are_seeking_help_for: yup
                    .string()
                    .when([], {
                        is: () => currentAction === "with_signature",
                        then: yup
                            .string()
                            .required(
                                "What are the problem you are seeking help for is required"
                            ),
                        otherwise: yup.string(),
                    }),
                anxiety_frequency: yup.string(),
                anxiety_details: yup
                    .string()
                    .when("anxiety", (anxiety: boolean, field) =>
                        anxiety && currentAction === "with_signature"
                            ? field.required("Anxiety details is required")
                            : field
                    ),
                panic_attacks_freqency: yup.string(),
                panic_attacks_details: yup
                    .string()
                    .when("panic_attacks", (panic_attacks: boolean, field) =>
                        panic_attacks && currentAction === "with_signature"
                            ? field.required(
                                  "Panic attacks details is required"
                              )
                            : field
                    ),
                depression_freqency: yup.string(),
                depression_details: yup
                    .string()
                    .when("depression", (depression: boolean, field) =>
                        depression && currentAction === "with_signature"
                            ? field.required("Depression details is required")
                            : field
                    ),
                selected_symptoms: yup.array().nullable(),
                other_symptoms: yup.string(),
                physical_symptoms: yup.string(),
                medication_physical_psychiatric: yup.string(),
                sleeping_habits_and_concerns: yup.string(),
                difficulties_with_appetite_or_eating_patterns: yup.string(),
                parents_stepparents_history: yup.string(),
                siblings_history: yup.string(),
                grandparents_history: yup.string(),
                children_history: yup.string(),
                other_family_members_history: yup.string(),
                family_members_alcohol_abuse: yup.array().nullable(),
                family_members_anxiety: yup.array().nullable(),
                family_members_bipolar_disorder: yup.array().nullable(),
                family_members_depression: yup.array().nullable(),
                family_members_ptsd: yup.array().nullable(),
                family_members_schizophrenia: yup.array().nullable(),
                family_members_substance_abuse: yup.array().nullable(),
                family_members_substance_abuse_substance: yup.string(),
                trauma_abuse_history: yup.string(),
                previous_mental_health_treatment_out_patient: yup.string(),
                impatient_psychiatric_hospitalization: yup.string(),
                relevant_medical_history: yup.string(),
                been_so_distressed_you_seriously_wished_to_end_your_life: yup
                    .string()
                    .when([], {
                        is: () => currentAction === "with_signature",
                        then: yup.string().required("This field is required"),
                        otherwise: yup.string().nullable(),
                    }),
                has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live:
                    yup.string().when([], {
                        is: () => currentAction === "with_signature",
                        then: yup.string().required("This field is required"),
                        otherwise: yup.string().nullable(),
                    }),
                describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live:
                    yup
                        .string()
                        .when(
                            "has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live",
                            (
                                has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live,
                                field
                            ) =>
                                has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live ===
                                    "Yes" && currentAction === "with_signature"
                                    ? field.required(
                                          "Description field is required"
                                      )
                                    : field
                        ),
                a_specific_plan_of_how_you_would_kill_yourself: yup
                    .string()
                    .when([], {
                        is: () => currentAction === "with_signature",
                        then: yup.string().required("This field is required"),
                        otherwise: yup.string().nullable(),
                    }),
                access_to_weapons_or_means_of_hurting_self: yup
                    .string()
                    .when([], {
                        is: () => currentAction === "with_signature",
                        then: yup.string().required("This field is required"),
                        otherwise: yup.string().nullable(),
                    }),
                made_serious_suicide_attempts: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup.string().required("This field is required"),
                    otherwise: yup.string().nullable(),
                }),
                purposely_done_something_to_hurt_yourself: yup
                    .string()
                    .when([], {
                        is: () => currentAction === "with_signature",
                        then: yup.string().required("This field is required"),
                        otherwise: yup.string().nullable(),
                    }),
                describe_done_something_to_hurt_yourself: yup
                    .string()
                    .when(
                        "purposely_done_something_to_hurt_yourself",
                        (purposely_done_something_to_hurt_yourself, field) =>
                            purposely_done_something_to_hurt_yourself ===
                                "Yes" && currentAction === "with_signature"
                                ? field.required(
                                      "Description field field is required"
                                  )
                                : field
                    ),
                heard_voices_telling_you_to_hurt_yourself: yup
                    .string()
                    .when([], {
                        is: () => currentAction === "with_signature",
                        then: yup.string().required("This field is required"),
                        otherwise: yup.string().nullable(),
                    }),
                had_relatives_who_attempted_or_commited_sucide: yup
                    .string()
                    .when([], {
                        is: () => currentAction === "with_signature",
                        then: yup.string().required("This field is required"),
                        otherwise: yup.string().nullable(),
                    }),
                had_thoughts_of_killing_or_seriously_hurting_someone: yup
                    .string()
                    .when([], {
                        is: () => currentAction === "with_signature",
                        then: yup.string().required("This field is required"),
                        otherwise: yup.string().nullable(),
                    }),
                heard_voices_telling_you_to_hurt_others: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup.string().required("This field is required"),
                    otherwise: yup.string().nullable(),
                }),
                hurt_someone_or_destroyed_property_on_purpose: yup
                    .string()
                    .when([], {
                        is: () => currentAction === "with_signature",
                        then: yup.string().required("This field is required"),
                        otherwise: yup.string().nullable(),
                    }),
                slapped_kicked_punched_someone_with_intent_to_harm: yup
                    .string()
                    .when([], {
                        is: () => currentAction === "with_signature",
                        then: yup.string().required("This field is required"),
                        otherwise: yup.string().nullable(),
                    }),
                been_arrested_or_detained_for_violent_behavior: yup
                    .string()
                    .when([], {
                        is: () => currentAction === "with_signature",
                        then: yup.string().required("This field is required"),
                        otherwise: yup.string().nullable(),
                    }),
                been_to_jail_for_any_reason: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup.string().required("This field is required"),
                    otherwise: yup.string().nullable(),
                }),
                been_on_probation_for_any_reason: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup.string().required("This field is required"),
                    otherwise: yup.string().nullable(),
                }),
                do_you_have_access_to_guns: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup.string().required("This field is required"),
                    otherwise: yup.string().nullable(),
                }),
                mental_health_appearance: yup.array().nullable(),
                mental_health_movement: yup.array().nullable(),
                mental_health_speech_behaviour: yup.array().nullable(),
                mental_health_affect_mood: yup.array().nullable(),
                mental_health_attention_concentration_effort: yup
                    .array()
                    .nullable(),
                mental_health_presentation_of_self: yup.array().nullable(),
                mental_health_cognition: yup.array().nullable(),
                patients_ability_and_capability_to_respond_to_treatment: yup
                    .array()
                    .nullable(),
                details_for_diminished_poor_limited_quality_patient_oriented_evidence:
                    yup
                        .string()
                        .when(
                            "patients_ability_and_capability_to_respond_to_treatment",
                            (
                                patients_ability_and_capability_to_respond_to_treatment: string[],
                                field
                            ) =>
                                patients_ability_and_capability_to_respond_to_treatment &&
                                patients_ability_and_capability_to_respond_to_treatment.includes(
                                    "Diminished/poor or limited-quality patient-oriented evidence"
                                )
                                    ? field.required("Details is required")
                                    : field
                        ),
                people_present_at_session: yup.array().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup
                        .array()
                        .min(1)
                        .required("People present at session is required"),
                    otherwise: yup.array().nullable(),
                }),
                other_person_present_at_session: yup.string(),
                type_of_treatment_recommended: yup.array().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup
                        .array()
                        .min(1)
                        .required("Type of treatment recommended is required"),
                    otherwise: yup.array().nullable(),
                }),
                treatment_modality_intervention: yup.array().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup
                        .array()
                        .min(1)
                        .required(
                            "Treatment modality intervention is required"
                        ),
                    otherwise: yup.array().nullable(),
                }),
                other_treatment_modality_intervention: yup.string(),
                treatment_goal_one: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup
                        .string()
                        .required("Treatment goal one is required"),
                    otherwise: yup.string(),
                }),
                treatment_goal_two: yup.string(),
                treatment_goal_three: yup.string(),
                frequency_of_sessions: yup.string().nullable(),
                other_frequency_of_sessions: yup
                    .string()
                    .when(
                        "frequency_of_sessions",
                        (frequency_of_sessions, field) =>
                            frequency_of_sessions === "Other" &&
                            currentAction === "with_signature"
                                ? field.required(
                                      "Other frequency of sessions is required"
                                  )
                                : field
                    ),
                date_of_next_session: yup.date().nullable(),
                subjective_report: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup
                        .string()
                        .required("Subjective report is required"),
                    otherwise: yup.string(),
                }),
                summary: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup.string().required("Summary is required"),
                    otherwise: yup.string(),
                }),
                prognosis: yup.string(),
                communication_factors_impacting_treatment: yup
                    .array()
                    .nullable(),
                details_around_communication_factors_impacting_treatment:
                    yup.string(),
                anxiety: yup.bool(),
                panic_attacks: yup.bool(),
                depression: yup.bool(),
                signature: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup.string().required("Signature is required"),
                    otherwise: yup.string(),
                }),
            })
        ),
        mode: "onChange",
        defaultValues: formValues || undefined,
    });

    const diagnosisCodes = watch("diagnosis_codes");

    // Function to handle item click and set the selected note content
    const handleLoadPreviousSession = (note: IGetClientNotes) => {
        setValue(
            "a_specific_plan_of_how_you_would_kill_yourself",
            note.note_content.a_specific_plan_of_how_you_would_kill_yourself
        );
        setValue(
            "access_to_weapons_or_means_of_hurting_self",
            note.note_content.access_to_weapons_or_means_of_hurting_self
        );
        setValue("anxiety", note.note_content.anxiety);
        setValue("anxiety_details", note.note_content.anxiety_details);
        setValue("anxiety_frequency", note.note_content.anxiety_frequency);
        setValue(
            "appointment_location",
            note.note_content.appointment_location
        );
        setValue(
            "been_arrested_or_detained_for_violent_behavior",
            note.note_content.been_arrested_or_detained_for_violent_behavior
        );
        setValue(
            "been_on_probation_for_any_reason",
            note.note_content.been_on_probation_for_any_reason
        );
        setValue(
            "been_so_distressed_you_seriously_wished_to_end_your_life",
            note.note_content
                .been_so_distressed_you_seriously_wished_to_end_your_life
        );
        setValue(
            "been_to_jail_for_any_reason",
            note.note_content.been_to_jail_for_any_reason
        );
        setValue("children_history", note.note_content.children_history);
        setValue(
            "communication_factors_impacting_treatment",
            note.note_content.communication_factors_impacting_treatment
        );
        setValue(
            "consent_was_received_for_the_teletherapy_session",
            note.note_content.consent_was_received_for_the_teletherapy_session
        );
        setValue("current_employment", note.note_content.current_employment);
        setValue(
            "current_employment_details",
            note.note_content.current_employment_details
        );
        setValue("delivery_option", note.note_content.delivery_option);
        setValue("depression", note.note_content.depression);
        setValue("depression_details", note.note_content.depression_details);
        setValue("depression_freqency", note.note_content.depression_freqency);
        setValue(
            "describe_done_something_to_hurt_yourself",
            note.note_content.describe_done_something_to_hurt_yourself
        );
        setValue(
            "describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live",
            note.note_content
                .describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live
        );
        setValue(
            "details_around_communication_factors_impacting_treatment",
            note.note_content
                .details_around_communication_factors_impacting_treatment
        );
        setValue(
            "details_for_diminished_poor_limited_quality_patient_oriented_evidence",
            note.note_content
                .details_for_diminished_poor_limited_quality_patient_oriented_evidence
        );
        setValue("diagnosis_codes", note.note_content.diagnosis_codes);
        setValue(
            "difficulties_with_appetite_or_eating_patterns",
            note.note_content.difficulties_with_appetite_or_eating_patterns
        );
        setValue(
            "do_you_have_access_to_guns",
            note.note_content.do_you_have_access_to_guns
        );
        setValue(
            "duration",
            appointmentDetails
                ? getTimeDuration(
                      formatDate(
                          appointmentDetails?.session_start_time || "",
                          false,
                          "HH:mm"
                      ),
                      formatDate(
                          appointmentDetails?.session_end_time || "",
                          false,
                          "HH:mm"
                      )
                  )
                : ""
        );
        setValue("education", note.note_content.education);
        setValue("gender", note.note_content.gender);
        setValue(
            "grandparents_history",
            note.note_content.grandparents_history
        );
        setValue(
            "had_relatives_who_attempted_or_commited_sucide",
            note.note_content.had_relatives_who_attempted_or_commited_sucide
        );
        setValue(
            "had_thoughts_of_killing_or_seriously_hurting_someone",
            note.note_content
                .had_thoughts_of_killing_or_seriously_hurting_someone
        );
        setValue(
            "has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live",
            note.note_content
                .has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live
        );
        setValue(
            "heard_voices_telling_you_to_hurt_others",
            note.note_content.heard_voices_telling_you_to_hurt_others
        );
        setValue(
            "heard_voices_telling_you_to_hurt_yourself",
            note.note_content.heard_voices_telling_you_to_hurt_yourself
        );
        setValue(
            "hurt_someone_or_destroyed_property_on_purpose",
            note.note_content.hurt_someone_or_destroyed_property_on_purpose
        );
        setValue(
            "impatient_psychiatric_hospitalization",
            note.note_content.impatient_psychiatric_hospitalization
        );
        setValue("legal_problems", note.note_content.legal_problems);
        setValue(
            "made_serious_suicide_attempts",
            note.note_content.made_serious_suicide_attempts
        );
        setValue(
            "medication_physical_psychiatric",
            note.note_content.medication_physical_psychiatric
        );
        setValue(
            "mental_health_affect_mood",
            note.note_content.mental_health_affect_mood
        );
        setValue(
            "mental_health_appearance",
            note.note_content.mental_health_appearance
        );
        setValue(
            "mental_health_attention_concentration_effort",
            note.note_content.mental_health_attention_concentration_effort
        );
        setValue(
            "mental_health_cognition",
            note.note_content.mental_health_cognition
        );
        setValue(
            "mental_health_movement",
            note.note_content.mental_health_movement
        );
        setValue(
            "mental_health_presentation_of_self",
            note.note_content.mental_health_presentation_of_self
        );
        setValue(
            "mental_health_speech_behaviour",
            note.note_content.mental_health_speech_behaviour
        );
        setValue("name_of_partner", note.note_content.name_of_partner);
        setValue(
            "other_delivery_option",
            note.note_content.other_delivery_option
        );
        setValue(
            "other_family_members_history",
            note.note_content.other_family_members_history
        );
        setValue(
            "other_person_present_at_session",
            note.note_content.other_person_present_at_session
        );
        setValue(
            "other_provider_location",
            note.note_content.other_provider_location
        );
        setValue("other_symptoms", note.note_content.other_symptoms);
        setValue(
            "other_treatment_modality_intervention",
            note.note_content.other_treatment_modality_intervention
        );
        setValue("panic_attacks", note.note_content.panic_attacks);
        setValue(
            "panic_attacks_details",
            note.note_content.panic_attacks_details
        );
        setValue(
            "panic_attacks_freqency",
            note.note_content.panic_attacks_freqency
        );
        setValue(
            "parents_stepparents_history",
            note.note_content.parents_stepparents_history
        );
        setValue(
            "patients_ability_and_capability_to_respond_to_treatment",
            note.note_content
                .patients_ability_and_capability_to_respond_to_treatment
        );
        setValue(
            "people_present_at_session",
            note.note_content.people_present_at_session
        );
        setValue("physical_symptoms", note.note_content.physical_symptoms);
        setValue(
            "previous_mental_health_treatment_oupatient",
            note.note_content.previous_mental_health_treatment_oupatient
        );
        setValue("prognosis", note.note_content.prognosis);
        setValue("provider_location", note.note_content.provider_location);
        setValue(
            "purposely_done_something_to_hurt_yourself",
            note.note_content.purposely_done_something_to_hurt_yourself
        );
        setValue("race", note.note_content.race);
        setValue("relationship_status", note.note_content.relationship_status);
        setValue(
            "relevant_medical_history",
            note.note_content.relevant_medical_history
        );
        setValue("religion", note.note_content.religion);
        setValue("selected_symptoms", note.note_content.selected_symptoms);
        setValue("sexual_orientation", note.note_content.sexual_orientation);
        setValue("siblings_history", note.note_content.siblings_history);
        setValue(
            "slapped_kicked_punched_someone_with_intent_to_harm",
            note.note_content.slapped_kicked_punched_someone_with_intent_to_harm
        );
        setValue(
            "sleeping_habits_and_concerns",
            note.note_content.sleeping_habits_and_concerns
        );
        setValue("subjective_report", note.note_content.subjective_report);
        setValue("summary", note.note_content.summary);
        setValue(
            "the_teletherapy_session_was_conducted_via_video",
            note.note_content.the_teletherapy_session_was_conducted_via_video
        );
        setValue(
            "trauma_abuse_history",
            note.note_content.trauma_abuse_history
        );
        setValue("treatment_goal_one", note.note_content.treatment_goal_one);
        setValue(
            "treatment_goal_three",
            note.note_content.treatment_goal_three
        );
        setValue("treatment_goal_two", note.note_content.treatment_goal_two);
        setValue(
            "treatment_modality_intervention",
            note.note_content.treatment_modality_intervention
        );
        setValue(
            "type_of_treatment_recommended",
            note.note_content.type_of_treatment_recommended
        );
        setValue(
            "what_are_the_problem_you_are_seeking_help_for",
            note.note_content.what_are_the_problem_you_are_seeking_help_for
        );
        setValue(
            "frequency_of_sessions",
            note.note_content.frequency_of_sessions
        );
        setValue(
            "other_frequency_of_sessions",
            note.note_content.other_frequency_of_sessions
        );
        setValue(
            "date_of_next_session",
            note.note_content.date_of_next_session
        );
        setValue(
            "contact_information_for_both_parents",
            note.note_content.contact_information_for_both_parents
        );
        setValue(
            "name_address_of_personal_physician",
            note.note_content.name_address_of_personal_physician
        );
        setValue("date_of_last_exam", note.note_content.date_of_last_exam);
        setShowPrompt(false);
    };

    // Get diagnosis codes for select
    const DiagnosisCodesForSelect = data?.data.map((diagnosis) => ({
        label: `${diagnosis.code} ${diagnosis.description}`,
        value: diagnosis.code,
    }));

    // Get frequency for select
    const FrequencyForSelect = ["daily", "weekly", "monthly", "yearly"].map(
        (item) => ({
            label: makeStringFirstLetterCapital(item),
            value: item,
        })
    );

    // Get relationship options for select
    const RelationshipForSelect = Object.values(ClientRelationship).map(
        (relationship) => ({
            label: removeEnumUnderscore(
                makeStringFirstLetterCapital(relationship)
            ),
            value: relationship,
        })
    );

    // Get gender options for select
    const GenderForSelect = Object.values(ClientGender).map((gender) => ({
        label: removeEnumUnderscore(makeStringFirstLetterCapital(gender)),
        value: gender,
    }));

    // Get religion options for select
    const ReligionForSelect = Object.values(ClientReligion).map((religion) => ({
        label: removeEnumUnderscore(makeStringFirstLetterCapital(religion)),
        value: religion,
    }));

    // Get education options for select
    const EducationForSelect = Object.values(ClientEducation).map(
        (education) => ({
            label: removeEnumUnderscore(
                makeStringFirstLetterCapital(education)
            ),
            value: education,
        })
    );

    // Get sexual orientation options for select
    const SexualOrientationForSelect = Object.values(
        ClientSexualOrientation
    ).map((orientation) => ({
        label: removeEnumUnderscore(makeStringFirstLetterCapital(orientation)),
        value: orientation,
    }));

    // Get education options for select
    const RaceForSelect = Object.values(ClientRace).map((race) => ({
        label: removeEnumUnderscore(makeStringFirstLetterCapital(race)),
        value: race,
    }));

    const anxiety = watch("anxiety", false);
    const panicAttacks = watch("panic_attacks", false);
    const depression = watch("depression", false);

    // Get appointment location options
    const AppointmentLocationsForSelect = Object.values(
        AppointmentLocation
    ).map((location) => {
        return {
            label: removeEnumUnderscore(makeStringFirstLetterCapital(location)),
            value: location,
        };
    });

    const isCheckboxChecked = (checkboxValue: string, key: string) => {
        return Boolean(
            formValues?.[key]?.find((value: string) => value === checkboxValue)
        );
    };

    // Get filter options for diagnosis list
    const filterDiagnosisOptions = (phrases: string[]) => {
        const filteredOptions = DiagnosisCodesForSelect?.filter((option) =>
            phrases.every(
                (phrase) =>
                    option &&
                    option.label.toLowerCase().includes(phrase.toLowerCase())
            )
        );
        return filteredOptions;
    };

    return (
        <>
            <LoadPreviousSessionNotificationModal
                showPrompt={showPrompt}
                setShowPrompt={setShowPrompt}
                handleLoadPreviousSession={() =>
                    handleLoadPreviousSession(selectedNote)
                }
            />
            {notes && notes.data.length > 0 && (
                <div className="flex justify-end mb-24">
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <FilterButton text="Load previous session note" />
                        </DropdownTrigger>
                        <DropdownContent width="auto" align="end">
                            {notes &&
                                notes?.data?.map((note) => (
                                    <DropdownItem
                                        key={note.note_id}
                                        onClick={() => {
                                            setShowPrompt(true);
                                            setSelectedNote(note);
                                        }}
                                    >
                                        <div>
                                            {makeStringFirstLetterCapital(
                                                removeEnumUnderscore(
                                                    note.note_type
                                                )
                                            )}{" "}
                                            - Note content Details -{" "}
                                            {formatDate(note.date_of_service)}
                                        </div>
                                    </DropdownItem>
                                ))}
                        </DropdownContent>
                    </Dropdown>
                </div>
            )}
            <form
                onSubmit={onSubmit && handleSubmit(onSubmit)}
                className="pb-[60px] md:pb-[150px]"
                aria-label="individual intake"
                id="create-note-form"
            >
                <div className="mb-24">
                    <NoteFormInfoBox />
                </div>
                <section id="step-one" className="mb-40">
                    <div className="fg">
                        <Controller
                            name="appointment_location"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    isRequired
                                    label={
                                        INDIVIDUAL_INTAKE_NOTE_DETAILS.appointment_location
                                    }
                                    placeholder={
                                        INDIVIDUAL_INTAKE_NOTE_DETAILS.appointment_location
                                    }
                                    onChange={(val) =>
                                        field.onChange((val as Option).value)
                                    }
                                    value={AppointmentLocationsForSelect.find(
                                        (location) =>
                                            location.value === field.value
                                    )}
                                    options={AppointmentLocationsForSelect}
                                    hasError={!!errors.appointment_location}
                                    errorText={
                                        errors.appointment_location?.message
                                    }
                                    onBlur={onSubmit && handleSubmit(onSubmit)}
                                />
                            )}
                        />
                    </div>
                    {watch("appointment_location") ===
                        AppointmentLocation.TELETHERAPY && (
                        <>
                            <p className="note-label">
                                {INDIVIDUAL_INTAKE_NOTE_DETAILS.delivery_option}
                            </p>
                            <div className="fg fg-space-between three flex">
                                <RadioInput
                                    {...register("delivery_option")}
                                    label="Home"
                                    value="Home"
                                    defaultChecked={
                                        formValues?.delivery_option === "Home"
                                    }
                                    onBlur={onSubmit && handleSubmit(onSubmit)}
                                />

                                <RadioInput
                                    {...register("delivery_option")}
                                    label="Office"
                                    value="Office"
                                    defaultChecked={
                                        formValues?.delivery_option === "Office"
                                    }
                                    onBlur={onSubmit && handleSubmit(onSubmit)}
                                />

                                <RadioInput
                                    {...register("delivery_option")}
                                    label="Other"
                                    value="Other"
                                    defaultChecked={
                                        formValues?.delivery_option === "Other"
                                    }
                                    onBlur={onSubmit && handleSubmit(onSubmit)}
                                />
                            </div>
                            {errors.delivery_option ? (
                                <div className="note-error">
                                    <p>
                                        Client teletherapy location is required
                                    </p>
                                </div>
                            ) : null}
                            {watch("delivery_option") === "Other" ||
                            formValues?.delivery_option === "Other" ? (
                                <div className="fg">
                                    <Input
                                        {...register("other_delivery_option")}
                                        label={
                                            INDIVIDUAL_INTAKE_NOTE_DETAILS.other_delivery_option
                                        }
                                        placeholder={
                                            INDIVIDUAL_INTAKE_NOTE_DETAILS.other_delivery_option
                                        }
                                        hasError={
                                            !!errors.other_delivery_option
                                        }
                                        errorText={
                                            errors.other_delivery_option
                                                ?.message
                                        }
                                        defaultValue={
                                            formValues?.other_delivery_option
                                        }
                                        onBlur={
                                            onSubmit && handleSubmit(onSubmit)
                                        }
                                    />
                                </div>
                            ) : null}
                        </>
                    )}

                    {watch("appointment_location") ===
                        AppointmentLocation.TELETHERAPY && (
                        <>
                            <p className="note-label">
                                {
                                    INDIVIDUAL_INTAKE_NOTE_DETAILS.provider_location
                                }
                            </p>
                            <div className="fg fg-space-between three flex">
                                <RadioInput
                                    {...register("provider_location")}
                                    label="Home"
                                    value="Home"
                                    defaultChecked={
                                        formValues?.provider_location === "Home"
                                    }
                                    onBlur={onSubmit && handleSubmit(onSubmit)}
                                />

                                <RadioInput
                                    {...register("provider_location")}
                                    label="Office"
                                    value="Office"
                                    defaultChecked={
                                        formValues?.provider_location ===
                                        "Office"
                                    }
                                    onBlur={onSubmit && handleSubmit(onSubmit)}
                                />

                                <RadioInput
                                    {...register("provider_location")}
                                    label="Other"
                                    value="Other"
                                    defaultChecked={
                                        formValues?.provider_location ===
                                        "Other"
                                    }
                                    onBlur={onSubmit && handleSubmit(onSubmit)}
                                />
                            </div>
                            {errors.provider_location ? (
                                <div className="note-error">
                                    <p>
                                        Provider teletherapy location is
                                        required
                                    </p>
                                </div>
                            ) : null}
                            {watch("provider_location") === "Other" ||
                            formValues?.provider_location === "Other" ? (
                                <div className="fg">
                                    <Input
                                        {...register("other_provider_location")}
                                        label={
                                            INDIVIDUAL_INTAKE_NOTE_DETAILS.other_provider_location
                                        }
                                        placeholder={
                                            INDIVIDUAL_INTAKE_NOTE_DETAILS.other_provider_location
                                        }
                                        hasError={
                                            !!errors.other_provider_location
                                        }
                                        errorText={
                                            errors.other_provider_location
                                                ?.message
                                        }
                                        defaultValue={
                                            formValues?.other_provider_location
                                        }
                                        onBlur={
                                            onSubmit && handleSubmit(onSubmit)
                                        }
                                    />
                                </div>
                            ) : null}
                        </>
                    )}

                    {watch("appointment_location") ===
                    AppointmentLocation.TELETHERAPY ? (
                        <div className="note-checkboxes">
                            <FormCheckbox
                                {...register(
                                    "consent_was_received_for_the_teletherapy_session"
                                )}
                                label={
                                    INDIVIDUAL_INTAKE_NOTE_DETAILS.consent_was_received_for_the_teletherapy_session
                                }
                                defaultChecked={
                                    formValues?.consent_was_received_for_the_teletherapy_session
                                }
                                onBlur={onSubmit && handleSubmit(onSubmit)}
                            />
                            <FormCheckbox
                                {...register(
                                    "the_teletherapy_session_was_conducted_via_video"
                                )}
                                label={
                                    INDIVIDUAL_INTAKE_NOTE_DETAILS.the_teletherapy_session_was_conducted_via_video
                                }
                                defaultChecked={
                                    formValues?.the_teletherapy_session_was_conducted_via_video
                                }
                                onBlur={onSubmit && handleSubmit(onSubmit)}
                            />
                        </div>
                    ) : null}
                    <div className="fg">
                        <Input
                            {...register("duration")}
                            label={INDIVIDUAL_INTAKE_NOTE_DETAILS.duration}
                            placeholder={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.duration
                            }
                            disabled
                            defaultValue={
                                appointmentDetails
                                    ? getTimeDuration(
                                          formatDate(
                                              appointmentDetails?.session_start_time ||
                                                  "",
                                              false,
                                              "HH:mm"
                                          ),
                                          formatDate(
                                              appointmentDetails?.session_end_time ||
                                                  "",
                                              false,
                                              "HH:mm"
                                          )
                                      )
                                    : undefined
                            }
                            value={formValues?.duration}
                        />
                    </div>

                    <div className="fg">
                        <Controller
                            name="diagnosis_codes"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    isRequired
                                    label={
                                        INDIVIDUAL_INTAKE_NOTE_DETAILS.diagnosis_codes
                                    }
                                    placeholder={
                                        (!data && isLoading && "Loading...") ||
                                        (error &&
                                            !isLoading &&
                                            "Error loading diagnosis") ||
                                        (data &&
                                            !isLoading &&
                                            INDIVIDUAL_INTAKE_NOTE_DETAILS.diagnosis_codes)
                                    }
                                    value={DiagnosisCodesForSelect?.filter(
                                        (diagnosis) =>
                                            field.value?.includes(
                                                diagnosis.value
                                            )
                                    )}
                                    isSearchable
                                    onInputChange={(value) =>
                                        setSearchValue(value)
                                    }
                                    filterOption={() => true} // Disable default filtering
                                    onChange={(val) => {
                                        field.onChange(
                                            (val as Option[]).map(
                                                (code) => code.value
                                            )
                                        );
                                    }}
                                    options={filterDiagnosisOptions(
                                        searchValue.split(" ")
                                    )}
                                    isDisabled={isLoading || Boolean(error)}
                                    hasError={!!errors.diagnosis_codes}
                                    errorText={
                                        errors.diagnosis_codes?.type ===
                                        "typeError"
                                            ? "Diagnosis codes are required"
                                            : errors.diagnosis_codes?.message
                                    }
                                    isMulti
                                    multiHasValues={
                                        diagnosisCodes &&
                                        diagnosisCodes.length > 0
                                    }
                                    hideSelectedOptions
                                    onBlur={onSubmit && handleSubmit(onSubmit)}
                                />
                            )}
                        />
                    </div>
                    <h3 className="note-header">Identity</h3>
                    <div className="fg">
                        <Controller
                            name="gender"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    label={
                                        INDIVIDUAL_INTAKE_NOTE_DETAILS.gender
                                    }
                                    placeholder={
                                        INDIVIDUAL_INTAKE_NOTE_DETAILS.gender
                                    }
                                    value={GenderForSelect.find(
                                        (gender) => gender.value === field.value
                                    )}
                                    onChange={(val) =>
                                        field.onChange((val as Option).value)
                                    }
                                    options={GenderForSelect}
                                    onBlur={onSubmit && handleSubmit(onSubmit)}
                                />
                            )}
                        />
                    </div>
                    <div className="fg">
                        <Controller
                            name="religion"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    label={
                                        INDIVIDUAL_INTAKE_NOTE_DETAILS.religion
                                    }
                                    placeholder={
                                        INDIVIDUAL_INTAKE_NOTE_DETAILS.religion
                                    }
                                    value={ReligionForSelect.find(
                                        (religion) =>
                                            religion.value === field.value
                                    )}
                                    onChange={(val) =>
                                        field.onChange((val as Option).value)
                                    }
                                    options={ReligionForSelect}
                                    onBlur={onSubmit && handleSubmit(onSubmit)}
                                />
                            )}
                        />
                    </div>
                    <div className="fg">
                        <Controller
                            name="education"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    label={
                                        INDIVIDUAL_INTAKE_NOTE_DETAILS.education
                                    }
                                    placeholder={
                                        INDIVIDUAL_INTAKE_NOTE_DETAILS.education
                                    }
                                    value={EducationForSelect.find(
                                        (education) =>
                                            education.value === field.value
                                    )}
                                    onChange={(val) =>
                                        field.onChange((val as Option).value)
                                    }
                                    options={EducationForSelect}
                                    onBlur={onSubmit && handleSubmit(onSubmit)}
                                />
                            )}
                        />
                    </div>
                    <div className="fg">
                        <Controller
                            name="race"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    label={INDIVIDUAL_INTAKE_NOTE_DETAILS.race}
                                    placeholder={
                                        INDIVIDUAL_INTAKE_NOTE_DETAILS.race
                                    }
                                    value={RaceForSelect.find(
                                        (race) => race.value === field.value
                                    )}
                                    onChange={(val) =>
                                        field.onChange((val as Option).value)
                                    }
                                    options={RaceForSelect}
                                    onBlur={onSubmit && handleSubmit(onSubmit)}
                                />
                            )}
                        />
                    </div>
                    <div className="fg">
                        <Input
                            {...register("ethinicity")}
                            label={INDIVIDUAL_INTAKE_NOTE_DETAILS.ethinicity}
                            placeholder={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.ethinicity
                            }
                            defaultValue={formValues?.ethinicity}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <div className="fg">
                        <Controller
                            name="sexual_orientation"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    label={
                                        INDIVIDUAL_INTAKE_NOTE_DETAILS.sexual_orientation
                                    }
                                    placeholder={
                                        INDIVIDUAL_INTAKE_NOTE_DETAILS.sexual_orientation
                                    }
                                    value={SexualOrientationForSelect.find(
                                        (orientation) =>
                                            orientation.value === field.value
                                    )}
                                    onChange={(val) =>
                                        field.onChange((val as Option).value)
                                    }
                                    options={SexualOrientationForSelect}
                                    onBlur={onSubmit && handleSubmit(onSubmit)}
                                />
                            )}
                        />
                    </div>
                    <div className="fg">
                        <Input
                            {...register("current_employment")}
                            label={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.current_employment
                            }
                            placeholder={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.current_employment
                            }
                            defaultValue={formValues?.current_employment}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <div className="fg">
                        <TextArea
                            label={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.current_employment_details
                            }
                            placeholder={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.current_employment_details
                            }
                            {...register("current_employment_details")}
                            defaultValue={
                                formValues?.current_employment_details
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <div className="fg">
                        <Controller
                            name="relationship_status"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    label={
                                        INDIVIDUAL_INTAKE_NOTE_DETAILS.relationship_status
                                    }
                                    placeholder={
                                        INDIVIDUAL_INTAKE_NOTE_DETAILS.relationship_status
                                    }
                                    value={RelationshipForSelect.find(
                                        (relationship) =>
                                            relationship.value === field.value
                                    )}
                                    onChange={(val) =>
                                        field.onChange((val as Option).value)
                                    }
                                    options={RelationshipForSelect}
                                    onBlur={onSubmit && handleSubmit(onSubmit)}
                                />
                            )}
                        />
                    </div>
                    <div className="fg">
                        <Input
                            {...register("name_of_partner")}
                            label={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.name_of_partner
                            }
                            placeholder={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.name_of_partner
                            }
                            defaultValue={formValues?.name_of_partner}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    {isMinor && (
                        <div className="fg">
                            <Input
                                {...register(
                                    "contact_information_for_both_parents"
                                )}
                                label={
                                    INDIVIDUAL_INTAKE_NOTE_DETAILS.emergency_contact_information
                                }
                                placeholder={
                                    INDIVIDUAL_INTAKE_NOTE_DETAILS.emergency_contact_information
                                }
                                defaultValue={
                                    formValues?.emergency_contact_information
                                }
                                onBlur={onSubmit && handleSubmit(onSubmit)}
                            />
                        </div>
                    )}
                    <div className="fg">
                        <Input
                            {...register("emergency_contact_information")}
                            label={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.emergency_contact_information
                            }
                            placeholder={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.emergency_contact_information
                            }
                            defaultValue={
                                formValues?.emergency_contact_information
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <div className="fg">
                        <Input
                            {...register("legal_problems")}
                            label={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.legal_problems
                            }
                            placeholder={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.legal_problems
                            }
                            defaultValue={formValues?.legal_problems}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    {isMinor && (
                        <>
                            <div className="fg">
                                <Input
                                    {...register(
                                        "name_address_of_personal_physician"
                                    )}
                                    label={
                                        INDIVIDUAL_INTAKE_NOTE_DETAILS.name_address_of_personal_physician
                                    }
                                    placeholder={
                                        INDIVIDUAL_INTAKE_NOTE_DETAILS.name_address_of_personal_physician
                                    }
                                    defaultValue={
                                        formValues?.name_address_of_personal_physician
                                    }
                                    onBlur={onSubmit && handleSubmit(onSubmit)}
                                />
                            </div>
                            <div className="fg">
                                <Controller
                                    name="date_of_last_exam"
                                    control={control}
                                    render={({ field }) => (
                                        <DatePicker
                                            label={
                                                INDIVIDUAL_INTAKE_NOTE_DETAILS.date_of_last_exam
                                            }
                                            onChange={(date) => {
                                                field.onChange(date);
                                                setLocalDateOfLastExam(date);
                                            }}
                                            selected={localDateOfLastExam}
                                            value={
                                                field.value
                                                    ? formatDate(
                                                          field.value,
                                                          true
                                                      )
                                                    : undefined
                                            }
                                            maxDate={new Date(Date.now())}
                                            hasError={
                                                !!errors.date_of_last_exam
                                            }
                                            errorText={
                                                errors.date_of_last_exam
                                                    ?.type === "typeError"
                                                    ? "Invalid date value"
                                                    : errors.date_of_last_exam
                                                          ?.message
                                            }
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                            shouldCloseOnSelect
                                            onCalendarClose={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                    )}
                                />
                            </div>
                        </>
                    )}
                </section>
                <section id="step-two" className="mb-40">
                    <h3 className="note-label-req">Presenting Concerns</h3>
                    <p className="note-label">
                        {
                            INDIVIDUAL_INTAKE_NOTE_DETAILS.what_are_the_problem_you_are_seeking_help_for
                        }
                    </p>
                    <div className="fg">
                        <TextArea
                            {...register(
                                "what_are_the_problem_you_are_seeking_help_for"
                            )}
                            defaultValue={
                                formValues?.what_are_the_problem_you_are_seeking_help_for
                            }
                            hasError={
                                !!errors.what_are_the_problem_you_are_seeking_help_for
                            }
                            errorText={
                                errors
                                    .what_are_the_problem_you_are_seeking_help_for
                                    ?.message
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <p className="note-label">Symptoms</p>
                    <div className="mb-20">
                        <FormCheckbox
                            {...register("anxiety")}
                            label={INDIVIDUAL_INTAKE_NOTE_DETAILS.anxiety}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                            defaultChecked={formValues?.anxiety}
                        />
                    </div>
                    {anxiety || formValues?.anxiety ? (
                        <div className="fg fg-space-between two flex">
                            <Controller
                                name="anxiety_frequency"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        label={
                                            INDIVIDUAL_INTAKE_NOTE_DETAILS.anxiety_frequency
                                        }
                                        placeholder={
                                            INDIVIDUAL_INTAKE_NOTE_DETAILS.anxiety_frequency
                                        }
                                        onChange={(val) =>
                                            field.onChange(
                                                (val as Option).value
                                            )
                                        }
                                        value={FrequencyForSelect.find(
                                            (frequency) =>
                                                frequency.value === field.value
                                        )}
                                        options={FrequencyForSelect}
                                        onBlur={
                                            onSubmit && handleSubmit(onSubmit)
                                        }
                                    />
                                )}
                            />
                            <TextArea
                                {...register("anxiety_details")}
                                placeholder={
                                    INDIVIDUAL_INTAKE_NOTE_DETAILS.anxiety_details
                                }
                                label={
                                    INDIVIDUAL_INTAKE_NOTE_DETAILS.anxiety_details
                                }
                                defaultValue={formValues?.anxiety_details}
                                hasError={!!errors.anxiety_details}
                                errorText={errors.anxiety_details?.message}
                                onBlur={onSubmit && handleSubmit(onSubmit)}
                            />
                        </div>
                    ) : null}
                    <div className="mb-20">
                        <FormCheckbox
                            {...register("panic_attacks")}
                            label={INDIVIDUAL_INTAKE_NOTE_DETAILS.panic_attacks}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                            defaultChecked={formValues?.panic_attacks}
                        />
                    </div>
                    {panicAttacks || formValues?.panic_attacks ? (
                        <div className="fg fg-space-between two flex">
                            <Controller
                                name="panic_attacks_freqency"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        label={
                                            INDIVIDUAL_INTAKE_NOTE_DETAILS.panic_attacks_freqency
                                        }
                                        placeholder={
                                            INDIVIDUAL_INTAKE_NOTE_DETAILS.panic_attacks_freqency
                                        }
                                        onChange={(val) =>
                                            field.onChange(
                                                (val as Option).value
                                            )
                                        }
                                        value={FrequencyForSelect.find(
                                            (frequency) =>
                                                frequency.value === field.value
                                        )}
                                        options={FrequencyForSelect}
                                        onBlur={
                                            onSubmit && handleSubmit(onSubmit)
                                        }
                                    />
                                )}
                            />
                            <TextArea
                                {...register("panic_attacks_details")}
                                placeholder={
                                    INDIVIDUAL_INTAKE_NOTE_DETAILS.panic_attacks_details
                                }
                                label={
                                    INDIVIDUAL_INTAKE_NOTE_DETAILS.panic_attacks_details
                                }
                                defaultValue={formValues?.panic_attacks_details}
                                hasError={!!errors.panic_attacks_details}
                                errorText={
                                    errors.panic_attacks_details?.message
                                }
                                onBlur={onSubmit && handleSubmit(onSubmit)}
                            />
                        </div>
                    ) : null}
                    <div className="mb-20">
                        <FormCheckbox
                            {...register("depression")}
                            label={INDIVIDUAL_INTAKE_NOTE_DETAILS.depression}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                            defaultChecked={formValues?.depression}
                        />
                    </div>
                    {depression || formValues?.depression ? (
                        <div className="fg fg-space-between two flex">
                            <Controller
                                name="depression_freqency"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        label={
                                            INDIVIDUAL_INTAKE_NOTE_DETAILS.depression_freqency
                                        }
                                        placeholder={
                                            INDIVIDUAL_INTAKE_NOTE_DETAILS.depression_freqency
                                        }
                                        onChange={(val) =>
                                            field.onChange(
                                                (val as Option).value
                                            )
                                        }
                                        value={FrequencyForSelect.find(
                                            (frequency) =>
                                                frequency.value === field.value
                                        )}
                                        options={FrequencyForSelect}
                                        onBlur={
                                            onSubmit && handleSubmit(onSubmit)
                                        }
                                    />
                                )}
                            />
                            <TextArea
                                {...register("depression_details")}
                                placeholder={
                                    INDIVIDUAL_INTAKE_NOTE_DETAILS.depression_details
                                }
                                label={
                                    INDIVIDUAL_INTAKE_NOTE_DETAILS.depression_details
                                }
                                defaultValue={formValues?.depression_details}
                                hasError={!!errors.depression_details}
                                errorText={errors.depression_details?.message}
                                onBlur={onSubmit && handleSubmit(onSubmit)}
                            />
                        </div>
                    ) : null}
                    <div className="note-checkboxes-list">
                        <FormCheckbox
                            {...register("selected_symptoms")}
                            label="Easily distracted"
                            value="Easily distracted"
                            defaultChecked={isCheckboxChecked(
                                "Easily distracted",
                                formValues?.selected_symptoms
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("selected_symptoms")}
                            label="Impulsive"
                            value="Impulsive"
                            defaultChecked={isCheckboxChecked(
                                "Impulsive",
                                formValues?.selected_symptoms
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("selected_symptoms")}
                            label="Paranoia"
                            value="Paranoia"
                            defaultChecked={isCheckboxChecked(
                                "Paranoia",
                                formValues?.selected_symptoms
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("selected_symptoms")}
                            label="Alcohol"
                            value="Alcohol"
                            defaultChecked={isCheckboxChecked(
                                "Alcohol",
                                formValues?.selected_symptoms
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("selected_symptoms")}
                            label="Anger outburst"
                            value="Anger outburst"
                            defaultChecked={isCheckboxChecked(
                                "Anger outburst",
                                formValues?.selected_symptoms
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("selected_symptoms")}
                            label="Unable to feel pleasure"
                            value="Unable to feel pleasure"
                            defaultChecked={isCheckboxChecked(
                                "Unable to feel pleasure",
                                formValues?.selected_symptoms
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("selected_symptoms")}
                            label="Excessive energy"
                            value="Excessive energy"
                            defaultChecked={isCheckboxChecked(
                                "Excessive energy",
                                formValues?.selected_symptoms
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("selected_symptoms")}
                            label="Recreational drug use"
                            value="Recreational drug use"
                            defaultChecked={isCheckboxChecked(
                                "Recreational drug use",
                                formValues?.selected_symptoms
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("selected_symptoms")}
                            label="Tobacco"
                            value="Tobacco"
                            defaultChecked={isCheckboxChecked(
                                "Tobacco",
                                formValues?.selected_symptoms
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("selected_symptoms")}
                            label="Racing thoughts"
                            value="Racing thoughts"
                            defaultChecked={isCheckboxChecked(
                                "Racing thoughts",
                                formValues?.selected_symptoms
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <div className="fg">
                        <TextArea
                            {...register("other_symptoms")}
                            placeholder={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.other_symptoms
                            }
                            label={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.other_symptoms
                            }
                            defaultValue={formValues?.other_symptoms}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <div className="fg">
                        <TextArea
                            {...register("physical_symptoms")}
                            defaultValue={formValues?.physical_symptoms}
                            placeholder={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.physical_symptoms
                            }
                            label={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.physical_symptoms
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <div className="fg">
                        <TextArea
                            {...register("medication_physical_psychiatric")}
                            defaultValue={
                                formValues?.medication_physical_psychiatric
                            }
                            placeholder={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.medication_physical_psychiatric
                            }
                            label={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.medication_physical_psychiatric
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <div className="fg">
                        <TextArea
                            {...register("sleeping_habits_and_concerns")}
                            placeholder={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.sleeping_habits_and_concerns
                            }
                            label={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.sleeping_habits_and_concerns
                            }
                            defaultValue={
                                formValues?.sleeping_habits_and_concerns
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <div className="fg">
                        <TextArea
                            {...register(
                                "difficulties_with_appetite_or_eating_patterns"
                            )}
                            placeholder={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.difficulties_with_appetite_or_eating_patterns
                            }
                            label={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.difficulties_with_appetite_or_eating_patterns
                            }
                            defaultValue={
                                formValues?.difficulties_with_appetite_or_eating_patterns
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                </section>
                <section id="step-three" className="mb-40">
                    <h3 className="note-header">Family history</h3>
                    <p className="note-label">Describe family situation</p>
                    <div className="fg">
                        <TextArea
                            {...register("parents_stepparents_history")}
                            label={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.parents_stepparents_history
                            }
                            placeholder={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.parents_stepparents_history
                            }
                            defaultValue={
                                formValues?.parents_stepparents_history
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <div className="fg">
                        <TextArea
                            {...register("siblings_history")}
                            label={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.siblings_history
                            }
                            placeholder={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.siblings_history
                            }
                            defaultValue={formValues?.siblings_history}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <div className="fg">
                        <TextArea
                            {...register("grandparents_history")}
                            label={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.grandparents_history
                            }
                            placeholder={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.grandparents_history
                            }
                            defaultValue={formValues?.grandparents_history}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <div className="fg">
                        <TextArea
                            {...register("children_history")}
                            label={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.children_history
                            }
                            placeholder={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.children_history
                            }
                            defaultValue={formValues?.children_history}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <div className="fg">
                        <TextArea
                            {...register("other_family_members_history")}
                            label={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.other_family_members_history
                            }
                            placeholder={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.other_family_members_history
                            }
                            defaultValue={
                                formValues?.other_family_members_history
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <h3 className="note-header">Family Psychiatric History</h3>
                    <p className="note-label">
                        Select which family members the below situation apply to
                    </p>
                    <h3 className="note-label">
                        {
                            INDIVIDUAL_INTAKE_NOTE_DETAILS.family_members_alcohol_abuse
                        }
                    </h3>
                    <div className="note-checkboxes-list">
                        <FormCheckbox
                            {...register("family_members_alcohol_abuse")}
                            label="All"
                            value="All"
                            defaultChecked={isCheckboxChecked(
                                "All",
                                formValues?.family_members_alcohol_abuse
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_alcohol_abuse")}
                            label="Father"
                            value="Father"
                            defaultChecked={isCheckboxChecked(
                                "Father",
                                formValues?.family_members_alcohol_abuse
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_alcohol_abuse")}
                            label="Mother"
                            value="Mother"
                            defaultChecked={isCheckboxChecked(
                                "Mother",
                                formValues?.family_members_alcohol_abuse
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_alcohol_abuse")}
                            label="Child/Children"
                            value="Child/Children"
                            defaultChecked={isCheckboxChecked(
                                "Child/Children",
                                formValues?.family_members_alcohol_abuse
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_alcohol_abuse")}
                            label="Brother"
                            value="Brother"
                            defaultChecked={isCheckboxChecked(
                                "Brother",
                                formValues?.family_members_alcohol_abuse
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_alcohol_abuse")}
                            label="Sister"
                            value="Sister"
                            defaultChecked={isCheckboxChecked(
                                "Sister",
                                formValues?.family_members_alcohol_abuse
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_alcohol_abuse")}
                            label="Other"
                            value="Other"
                            defaultChecked={isCheckboxChecked(
                                "Other",
                                formValues?.family_members_alcohol_abuse
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <h3 className="note-label">
                        {INDIVIDUAL_INTAKE_NOTE_DETAILS.family_members_anxiety}
                    </h3>
                    <div className="note-checkboxes-list">
                        <FormCheckbox
                            {...register("family_members_anxiety")}
                            label="All"
                            value="All"
                            defaultChecked={isCheckboxChecked(
                                "All",
                                formValues?.family_members_anxiety
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_anxiety")}
                            label="Father"
                            value="Father"
                            defaultChecked={isCheckboxChecked(
                                "Father",
                                formValues?.family_members_anxiety
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_anxiety")}
                            label="Mother"
                            value="Mother"
                            defaultChecked={isCheckboxChecked(
                                "Mother",
                                formValues?.family_members_anxiety
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_anxiety")}
                            label="Child/Children"
                            value="Child/Children"
                            defaultChecked={isCheckboxChecked(
                                "Child/Children",
                                formValues?.family_members_anxiety
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_anxiety")}
                            label="Brother"
                            value="Brother"
                            defaultChecked={isCheckboxChecked(
                                "Brother",
                                formValues?.family_members_anxiety
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_anxiety")}
                            label="Sister"
                            value="Sister"
                            defaultChecked={isCheckboxChecked(
                                "Sister",
                                formValues?.family_members_anxiety
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_anxiety")}
                            label="Other"
                            value="Other"
                            defaultChecked={isCheckboxChecked(
                                "Other",
                                formValues?.family_members_anxiety
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <h3 className="note-label">
                        {
                            INDIVIDUAL_INTAKE_NOTE_DETAILS.family_members_bipolar_disorder
                        }
                    </h3>
                    <div className="note-checkboxes-list">
                        <FormCheckbox
                            {...register("family_members_bipolar_disorder")}
                            label="All"
                            value="All"
                            defaultChecked={isCheckboxChecked(
                                "All",
                                formValues?.family_members_bipolar_disorder
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_bipolar_disorder")}
                            label="Father"
                            value="Father"
                            defaultChecked={isCheckboxChecked(
                                "Father",
                                formValues?.family_members_bipolar_disorder
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_bipolar_disorder")}
                            label="Mother"
                            value="Mother"
                            defaultChecked={isCheckboxChecked(
                                "Mother",
                                formValues?.family_members_bipolar_disorder
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_bipolar_disorder")}
                            label="Child/Children"
                            value="Child/Children"
                            defaultChecked={isCheckboxChecked(
                                "Child/Children",
                                formValues?.family_members_bipolar_disorder
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_bipolar_disorder")}
                            label="Brother"
                            value="Brother"
                            defaultChecked={isCheckboxChecked(
                                "Brother",
                                formValues?.family_members_bipolar_disorder
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_bipolar_disorder")}
                            label="Sister"
                            value="Sister"
                            defaultChecked={isCheckboxChecked(
                                "Sister",
                                formValues?.family_members_bipolar_disorder
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_bipolar_disorder")}
                            label="Other"
                            value="Other"
                            defaultChecked={isCheckboxChecked(
                                "Other",
                                formValues?.family_members_bipolar_disorder
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <h3 className="note-label">
                        {
                            INDIVIDUAL_INTAKE_NOTE_DETAILS.family_members_depression
                        }
                    </h3>
                    <div className="note-checkboxes-list">
                        <FormCheckbox
                            {...register("family_members_depression")}
                            label="All"
                            value="All"
                            defaultChecked={isCheckboxChecked(
                                "All",
                                formValues?.family_members_depression
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_depression")}
                            label="Father"
                            value="Father"
                            defaultChecked={isCheckboxChecked(
                                "Father",
                                formValues?.family_members_depression
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_depression")}
                            label="Mother"
                            value="Mother"
                            defaultChecked={isCheckboxChecked(
                                "Mother",
                                formValues?.family_members_depression
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_depression")}
                            label="Child/Children"
                            value="Child/Children"
                            defaultChecked={isCheckboxChecked(
                                "Child/Children",
                                formValues?.family_members_depression
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_depression")}
                            label="Brother"
                            value="Brother"
                            defaultChecked={isCheckboxChecked(
                                "Brother",
                                formValues?.family_members_depression
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_depression")}
                            label="Sister"
                            value="Sister"
                            defaultChecked={isCheckboxChecked(
                                "Sister",
                                formValues?.family_members_depression
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_depression")}
                            label="Other"
                            value="Other"
                            defaultChecked={isCheckboxChecked(
                                "Other",
                                formValues?.family_members_depression
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <h3 className="note-label">
                        {INDIVIDUAL_INTAKE_NOTE_DETAILS.family_members_ptsd}
                    </h3>
                    <div className="note-checkboxes-list">
                        <FormCheckbox
                            {...register("family_members_ptsd")}
                            label="All"
                            value="All"
                            defaultChecked={isCheckboxChecked(
                                "All",
                                formValues?.family_members_ptsd
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_ptsd")}
                            label="Father"
                            value="Father"
                            defaultChecked={isCheckboxChecked(
                                "Father",
                                formValues?.family_members_ptsd
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_ptsd")}
                            label="Mother"
                            value="Mother"
                            defaultChecked={isCheckboxChecked(
                                "Mother",
                                formValues?.family_members_ptsd
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_ptsd")}
                            label="Child/Children"
                            value="Child/Children"
                            defaultChecked={isCheckboxChecked(
                                "Child/Children",
                                formValues?.family_members_ptsd
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_ptsd")}
                            label="Brother"
                            value="Brother"
                            defaultChecked={isCheckboxChecked(
                                "Brother",
                                formValues?.family_members_ptsd
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_ptsd")}
                            label="Sister"
                            value="Sister"
                            defaultChecked={isCheckboxChecked(
                                "Sister",
                                formValues?.family_members_ptsd
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_ptsd")}
                            label="Other"
                            value="Other"
                            defaultChecked={isCheckboxChecked(
                                "Other",
                                formValues?.family_members_ptsd
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <h3 className="note-label">
                        {
                            INDIVIDUAL_INTAKE_NOTE_DETAILS.family_members_schizophrenia
                        }
                    </h3>
                    <div className="note-checkboxes-list">
                        <FormCheckbox
                            {...register("family_members_schizophrenia")}
                            label="All"
                            value="All"
                            defaultChecked={isCheckboxChecked(
                                "All",
                                formValues?.family_members_schizophrenia
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_schizophrenia")}
                            label="Father"
                            value="Father"
                            defaultChecked={isCheckboxChecked(
                                "Father",
                                formValues?.family_members_schizophrenia
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_schizophrenia")}
                            label="Mother"
                            value="Mother"
                            defaultChecked={isCheckboxChecked(
                                "Mother",
                                formValues?.family_members_schizophrenia
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_schizophrenia")}
                            label="Child/Children"
                            value="Child/Children"
                            defaultChecked={isCheckboxChecked(
                                "Child/Children",
                                formValues?.family_members_schizophrenia
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_schizophrenia")}
                            label="Brother"
                            value="Brother"
                            defaultChecked={isCheckboxChecked(
                                "Brother",
                                formValues?.family_members_schizophrenia
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_schizophrenia")}
                            label="Sister"
                            value="Sister"
                            defaultChecked={isCheckboxChecked(
                                "Sister",
                                formValues?.family_members_schizophrenia
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_schizophrenia")}
                            label="Other"
                            value="Other"
                            defaultChecked={isCheckboxChecked(
                                "Other",
                                formValues?.family_members_schizophrenia
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <h3 className="note-label">
                        {
                            INDIVIDUAL_INTAKE_NOTE_DETAILS.family_members_substance_abuse
                        }
                    </h3>
                    <div className="note-checkboxes-list">
                        <FormCheckbox
                            {...register("family_members_substance_abuse")}
                            label="All"
                            value="All"
                            defaultChecked={isCheckboxChecked(
                                "All",
                                formValues?.family_members_substance_abuse
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_substance_abuse")}
                            label="Father"
                            value="Father"
                            defaultChecked={isCheckboxChecked(
                                "Father",
                                formValues?.family_members_substance_abuse
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_substance_abuse")}
                            label="Mother"
                            value="Mother"
                            defaultChecked={isCheckboxChecked(
                                "Mother",
                                formValues?.family_members_substance_abuse
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_substance_abuse")}
                            label="Child/Children"
                            value="Child/Children"
                            defaultChecked={isCheckboxChecked(
                                "Child/Children",
                                formValues?.family_members_substance_abuse
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_substance_abuse")}
                            label="Brother"
                            value="Brother"
                            defaultChecked={isCheckboxChecked(
                                "Brother",
                                formValues?.family_members_substance_abuse
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_substance_abuse")}
                            label="Sister"
                            value="Sister"
                            defaultChecked={isCheckboxChecked(
                                "Sister",
                                formValues?.family_members_substance_abuse
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("family_members_substance_abuse")}
                            label="Other"
                            value="Other"
                            defaultChecked={isCheckboxChecked(
                                "Other",
                                formValues?.family_members_substance_abuse
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <div className="fg">
                        <Input
                            {...register(
                                "family_members_substance_abuse_substance"
                            )}
                            label={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.family_members_substance_abuse_substance
                            }
                            placeholder={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.family_members_substance_abuse_substance
                            }
                            defaultValue={
                                formValues?.family_members_substance_abuse_substance
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                </section>
                <section id="step-four" className="mb-40">
                    <h3 className="note-header">Mental health history</h3>
                    <div className="fg">
                        <TextArea
                            {...register("trauma_abuse_history")}
                            label={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.trauma_abuse_history
                            }
                            placeholder={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.trauma_abuse_history
                            }
                            defaultValue={formValues?.trauma_abuse_history}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <div className="fg">
                        <TextArea
                            {...register(
                                "previous_mental_health_treatment_oupatient"
                            )}
                            label={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.previous_mental_health_treatment_oupatient
                            }
                            placeholder={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.previous_mental_health_treatment_oupatient
                            }
                            defaultValue={
                                formValues?.previous_mental_health_treatment_oupatient
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <div className="fg">
                        <TextArea
                            {...register(
                                "impatient_psychiatric_hospitalization"
                            )}
                            label={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.impatient_psychiatric_hospitalization
                            }
                            placeholder={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.impatient_psychiatric_hospitalization
                            }
                            defaultValue={
                                formValues?.impatient_psychiatric_hospitalization
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <div className="fg">
                        <TextArea
                            {...register("relevant_medical_history")}
                            label={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.relevant_medical_history
                            }
                            placeholder={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.relevant_medical_history
                            }
                            defaultValue={formValues?.relevant_medical_history}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                </section>
                <section id="step-five" className="mb-40">
                    <p className="note-header">Suicide risk assessment</p>
                    <p className="note-label-req">
                        {`1. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.been_so_distressed_you_seriously_wished_to_end_your_life}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register(
                                "been_so_distressed_you_seriously_wished_to_end_your_life"
                            )}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.been_so_distressed_you_seriously_wished_to_end_your_life ===
                                "No"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <RadioInput
                            {...register(
                                "been_so_distressed_you_seriously_wished_to_end_your_life"
                            )}
                            label="Today"
                            value="Today"
                            defaultChecked={
                                formValues?.been_so_distressed_you_seriously_wished_to_end_your_life ===
                                "Today"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <RadioInput
                            {...register(
                                "been_so_distressed_you_seriously_wished_to_end_your_life"
                            )}
                            label="Recently"
                            value="Recently"
                            defaultChecked={
                                formValues?.been_so_distressed_you_seriously_wished_to_end_your_life ===
                                "Recently"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    {errors.been_so_distressed_you_seriously_wished_to_end_your_life ? (
                        <div className="note-error">
                            <p>This field is required</p>
                        </div>
                    ) : null}
                    <p className="note-label-req">
                        {`2. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register(
                                "has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live"
                            )}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live ===
                                "No"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <RadioInput
                            {...register(
                                "has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live"
                            )}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live ===
                                "Yes"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    {errors.has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live ? (
                        <div className="note-error">
                            <p>This field is required</p>
                        </div>
                    ) : null}
                    <div className="fg">
                        <TextArea
                            {...register(
                                "describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live"
                            )}
                            placeholder={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live
                            }
                            label={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live
                            }
                            defaultValue={
                                formValues?.describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live
                            }
                            errorText={
                                errors
                                    .describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live
                                    ?.message
                            }
                            hasError={
                                !!errors.describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <p className="note-header">Have you had/Do you have -</p>
                    <p className="note-label-req">
                        {`3. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.a_specific_plan_of_how_you_would_kill_yourself}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register(
                                "a_specific_plan_of_how_you_would_kill_yourself"
                            )}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.a_specific_plan_of_how_you_would_kill_yourself ===
                                "No"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <RadioInput
                            {...register(
                                "a_specific_plan_of_how_you_would_kill_yourself"
                            )}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.a_specific_plan_of_how_you_would_kill_yourself ===
                                "Yes"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    {errors.a_specific_plan_of_how_you_would_kill_yourself ? (
                        <div className="note-error">
                            <p>This field is required</p>
                        </div>
                    ) : null}
                    <p className="note-label-req">
                        {`4. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.access_to_weapons_or_means_of_hurting_self}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register(
                                "access_to_weapons_or_means_of_hurting_self"
                            )}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.access_to_weapons_or_means_of_hurting_self ===
                                "No"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <RadioInput
                            {...register(
                                "access_to_weapons_or_means_of_hurting_self"
                            )}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.access_to_weapons_or_means_of_hurting_self ===
                                "Yes"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    {errors.access_to_weapons_or_means_of_hurting_self ? (
                        <div className="note-error">
                            <p>This field is required</p>
                        </div>
                    ) : null}
                    <p className="note-label-req">
                        {`5. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.made_serious_suicide_attempts}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register("made_serious_suicide_attempts")}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.made_serious_suicide_attempts ===
                                "No"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <RadioInput
                            {...register("made_serious_suicide_attempts")}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.made_serious_suicide_attempts ===
                                "Yes"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    {errors.made_serious_suicide_attempts ? (
                        <div className="note-error">
                            <p>This field is required</p>
                        </div>
                    ) : null}
                    <p className="note-label-req">
                        {`6. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.purposely_done_something_to_hurt_yourself}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register(
                                "purposely_done_something_to_hurt_yourself"
                            )}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.purposely_done_something_to_hurt_yourself ===
                                "No"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <RadioInput
                            {...register(
                                "purposely_done_something_to_hurt_yourself"
                            )}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.purposely_done_something_to_hurt_yourself ===
                                "Yes"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    {errors.purposely_done_something_to_hurt_yourself ? (
                        <div className="note-error">
                            <p>This field is required</p>
                        </div>
                    ) : null}
                    <div className="fg">
                        <TextArea
                            {...register(
                                "describe_done_something_to_hurt_yourself"
                            )}
                            placeholder={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.describe_done_something_to_hurt_yourself
                            }
                            label={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.describe_done_something_to_hurt_yourself
                            }
                            defaultValue={
                                formValues?.describe_done_something_to_hurt_yourself
                            }
                            hasError={
                                !!errors.describe_done_something_to_hurt_yourself
                            }
                            errorText={
                                errors.describe_done_something_to_hurt_yourself
                                    ?.message
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <p className="note-label-req">
                        {`7. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.heard_voices_telling_you_to_hurt_yourself}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register(
                                "heard_voices_telling_you_to_hurt_yourself"
                            )}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.heard_voices_telling_you_to_hurt_yourself ===
                                "No"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <RadioInput
                            {...register(
                                "heard_voices_telling_you_to_hurt_yourself"
                            )}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.heard_voices_telling_you_to_hurt_yourself ===
                                "Yes"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    {errors.heard_voices_telling_you_to_hurt_yourself ? (
                        <div className="note-error">
                            <p>This field is required</p>
                        </div>
                    ) : null}
                    <p className="note-label-req">
                        {`8. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.had_relatives_who_attempted_or_commited_sucide}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register(
                                "had_relatives_who_attempted_or_commited_sucide"
                            )}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.had_relatives_who_attempted_or_commited_sucide ===
                                "No"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <RadioInput
                            {...register(
                                "had_relatives_who_attempted_or_commited_sucide"
                            )}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.had_relatives_who_attempted_or_commited_sucide ===
                                "Yes"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    {errors.had_relatives_who_attempted_or_commited_sucide ? (
                        <div className="note-error">
                            <p>This field is required</p>
                        </div>
                    ) : null}
                    <p className="note-label-req">
                        {`9. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.had_thoughts_of_killing_or_seriously_hurting_someone}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register(
                                "had_thoughts_of_killing_or_seriously_hurting_someone"
                            )}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.had_thoughts_of_killing_or_seriously_hurting_someone ===
                                "No"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <RadioInput
                            {...register(
                                "had_thoughts_of_killing_or_seriously_hurting_someone"
                            )}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.had_thoughts_of_killing_or_seriously_hurting_someone ===
                                "Yes"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    {errors.had_thoughts_of_killing_or_seriously_hurting_someone ? (
                        <div className="note-error">
                            <p>This field is required</p>
                        </div>
                    ) : null}
                    <p className="note-label-req">
                        {`10. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.heard_voices_telling_you_to_hurt_others}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register(
                                "heard_voices_telling_you_to_hurt_others"
                            )}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.heard_voices_telling_you_to_hurt_others ===
                                "No"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <RadioInput
                            {...register(
                                "heard_voices_telling_you_to_hurt_others"
                            )}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.heard_voices_telling_you_to_hurt_others ===
                                "Yes"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    {errors.heard_voices_telling_you_to_hurt_others ? (
                        <div className="note-error">
                            <p>This field is required</p>
                        </div>
                    ) : null}
                    <p className="note-label-req">
                        {`11. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.hurt_someone_or_destroyed_property_on_purpose}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register(
                                "hurt_someone_or_destroyed_property_on_purpose"
                            )}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.hurt_someone_or_destroyed_property_on_purpose ===
                                "No"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <RadioInput
                            {...register(
                                "hurt_someone_or_destroyed_property_on_purpose"
                            )}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.hurt_someone_or_destroyed_property_on_purpose ===
                                "Yes"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    {errors.hurt_someone_or_destroyed_property_on_purpose ? (
                        <div className="note-error">
                            <p>This field is required</p>
                        </div>
                    ) : null}
                    <p className="note-label-req">
                        {`12. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.slapped_kicked_punched_someone_with_intent_to_harm}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register(
                                "slapped_kicked_punched_someone_with_intent_to_harm"
                            )}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.slapped_kicked_punched_someone_with_intent_to_harm ===
                                "No"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <RadioInput
                            {...register(
                                "slapped_kicked_punched_someone_with_intent_to_harm"
                            )}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.slapped_kicked_punched_someone_with_intent_to_harm ===
                                "Yes"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    {errors.slapped_kicked_punched_someone_with_intent_to_harm ? (
                        <div className="note-error">
                            <p>This field is required</p>
                        </div>
                    ) : null}
                    <p className="note-label-req">
                        {`13. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.been_arrested_or_detained_for_violent_behavior}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register(
                                "been_arrested_or_detained_for_violent_behavior"
                            )}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.been_arrested_or_detained_for_violent_behavior ===
                                "No"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <RadioInput
                            {...register(
                                "been_arrested_or_detained_for_violent_behavior"
                            )}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.been_arrested_or_detained_for_violent_behavior ===
                                "Yes"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    {errors.been_arrested_or_detained_for_violent_behavior ? (
                        <div className="note-error">
                            <p>This field is required</p>
                        </div>
                    ) : null}
                    <p className="note-label-req">
                        {`14. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.been_to_jail_for_any_reason}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register("been_to_jail_for_any_reason")}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.been_to_jail_for_any_reason === "No"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <RadioInput
                            {...register("been_to_jail_for_any_reason")}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.been_to_jail_for_any_reason ===
                                "Yes"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    {errors.been_to_jail_for_any_reason ? (
                        <div className="note-error">
                            <p>This field is required</p>
                        </div>
                    ) : null}
                    <p className="note-label-req">
                        {`15. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.been_on_probation_for_any_reason}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register("been_on_probation_for_any_reason")}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.been_on_probation_for_any_reason ===
                                "No"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <RadioInput
                            {...register("been_on_probation_for_any_reason")}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.been_on_probation_for_any_reason ===
                                "Yes"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    {errors.been_on_probation_for_any_reason ? (
                        <div className="note-error">
                            <p>This field is required</p>
                        </div>
                    ) : null}
                    <p className="note-label-req">
                        {`16. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.do_you_have_access_to_guns}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register("do_you_have_access_to_guns")}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.do_you_have_access_to_guns === "No"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <RadioInput
                            {...register("do_you_have_access_to_guns")}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.do_you_have_access_to_guns === "Yes"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    {errors.do_you_have_access_to_guns ? (
                        <div className="note-error">
                            <p>This field is required</p>
                        </div>
                    ) : null}
                </section>
                <section id="step-six" className="mb-40">
                    <h3 className="note-header">Mental status exam</h3>
                    <div className="mb-12">
                        <Accordion
                            buttonPosition="left"
                            header={
                                <p className="note-label !mb-0">
                                    {
                                        INDIVIDUAL_INTAKE_NOTE_DETAILS.mental_health_appearance
                                    }
                                </p>
                            }
                            content={
                                <div className="mt-20">
                                    <div
                                        className={cn(
                                            "note-checkboxes-list !mb-0"
                                        )}
                                    >
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_appearance"
                                            )}
                                            label="Neat"
                                            value="Neat"
                                            defaultChecked={isCheckboxChecked(
                                                "Neat",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_appearance"
                                            )}
                                            label="Unkempt"
                                            value="Unkempt"
                                            defaultChecked={isCheckboxChecked(
                                                "Unkempt",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_appearance"
                                            )}
                                            label="Thin"
                                            value="Thin"
                                            defaultChecked={isCheckboxChecked(
                                                "Thin",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_appearance"
                                            )}
                                            label="Average"
                                            value="Average"
                                            defaultChecked={isCheckboxChecked(
                                                "Average",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_appearance"
                                            )}
                                            label="Overweight"
                                            value="Overweight"
                                            defaultChecked={isCheckboxChecked(
                                                "Overweight",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_appearance"
                                            )}
                                            label="Pale"
                                            value="Pale"
                                            defaultChecked={isCheckboxChecked(
                                                "Pale",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_appearance"
                                            )}
                                            label="Tanned"
                                            value="Tanned"
                                            defaultChecked={isCheckboxChecked(
                                                "Tanned",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>
                    <div className="mb-12">
                        <Accordion
                            buttonPosition="left"
                            header={
                                <span className="note-label !mb-0">
                                    {
                                        INDIVIDUAL_INTAKE_NOTE_DETAILS.mental_health_movement
                                    }
                                </span>
                            }
                            content={
                                <div className="mt-20">
                                    <div
                                        className={cn(
                                            "note-checkboxes-list !mb-0"
                                        )}
                                    >
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_movement"
                                            )}
                                            label="Coordinated"
                                            value="Coordinated"
                                            defaultChecked={isCheckboxChecked(
                                                "Coordinated",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_movement"
                                            )}
                                            label="Uncoordinated"
                                            value="Uncoordinated"
                                            defaultChecked={isCheckboxChecked(
                                                "Uncoordinated",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_movement"
                                            )}
                                            label="Mannerisms/oddities"
                                            value="Mannerisms/oddities"
                                            defaultChecked={isCheckboxChecked(
                                                "Mannerisms/oddities",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_movement"
                                            )}
                                            label="Good eye contact"
                                            value="Good eye contact"
                                            defaultChecked={isCheckboxChecked(
                                                "Good eye contact",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_movement"
                                            )}
                                            label="Kept eyes downcast"
                                            value="Kept eyes downcast"
                                            defaultChecked={isCheckboxChecked(
                                                "Kept eyes downcast",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_movement"
                                            )}
                                            label="Stared into space"
                                            value="Stared into space"
                                            defaultChecked={isCheckboxChecked(
                                                "Stared into space",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>
                    <div className="mb-12">
                        <Accordion
                            buttonPosition="left"
                            header={
                                <span className="note-label !mb-0">
                                    {
                                        INDIVIDUAL_INTAKE_NOTE_DETAILS.mental_health_speech_behaviour
                                    }
                                </span>
                            }
                            content={
                                <div className="mt-20">
                                    <div
                                        className={cn(
                                            "note-checkboxes-list !mb-0"
                                        )}
                                    >
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_speech_behaviour"
                                            )}
                                            label="Unintelligible"
                                            value="Unintelligible"
                                            defaultChecked={isCheckboxChecked(
                                                "Unintelligible",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_speech_behaviour"
                                            )}
                                            label="Stammer/stutter"
                                            value="Stammer/stutter"
                                            defaultChecked={isCheckboxChecked(
                                                "Stammer/stutter",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_speech_behaviour"
                                            )}
                                            label="Mumbles"
                                            value="Mumbles"
                                            defaultChecked={isCheckboxChecked(
                                                "Mumbles",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_speech_behaviour"
                                            )}
                                            label="Clear"
                                            value="Clear"
                                            defaultChecked={isCheckboxChecked(
                                                "Clear",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_speech_behaviour"
                                            )}
                                            label="Precise"
                                            value="Precise"
                                            defaultChecked={isCheckboxChecked(
                                                "Precise",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_speech_behaviour"
                                            )}
                                            label="Strident"
                                            value="Strident"
                                            defaultChecked={isCheckboxChecked(
                                                "Strident",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_speech_behaviour"
                                            )}
                                            label="Distant"
                                            value="Distant"
                                            defaultChecked={isCheckboxChecked(
                                                "Distant",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_speech_behaviour"
                                            )}
                                            label="Squeaky"
                                            value="Squeaky"
                                            defaultChecked={isCheckboxChecked(
                                                "Squeaky",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_speech_behaviour"
                                            )}
                                            label="Soft"
                                            value="Soft"
                                            defaultChecked={isCheckboxChecked(
                                                "Soft",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_speech_behaviour"
                                            )}
                                            label="Weak"
                                            value="Weak"
                                            defaultChecked={isCheckboxChecked(
                                                "Weak",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_speech_behaviour"
                                            )}
                                            label="Normal"
                                            value="Normal"
                                            defaultChecked={isCheckboxChecked(
                                                "Normal",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_speech_behaviour"
                                            )}
                                            label="Hurried"
                                            value="Hurried"
                                            defaultChecked={isCheckboxChecked(
                                                "Hurried",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_speech_behaviour"
                                            )}
                                            label="Loud"
                                            value="Loud"
                                            defaultChecked={isCheckboxChecked(
                                                "Loud",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>
                    <div className="mb-12">
                        <Accordion
                            buttonPosition="left"
                            header={
                                <span className="note-label !mb-0">
                                    {
                                        INDIVIDUAL_INTAKE_NOTE_DETAILS.mental_health_affect_mood
                                    }
                                </span>
                            }
                            content={
                                <div className="mt-20">
                                    <div
                                        className={cn(
                                            "note-checkboxes-list !mb-0"
                                        )}
                                    >
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Blunted"
                                            value="Blunted"
                                            defaultChecked={isCheckboxChecked(
                                                "Blunted",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Constricted"
                                            value="Constricted"
                                            defaultChecked={isCheckboxChecked(
                                                "Constricted",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Normal"
                                            value="Normal"
                                            defaultChecked={isCheckboxChecked(
                                                "Normal",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Broad"
                                            value="Broad"
                                            defaultChecked={isCheckboxChecked(
                                                "Broad",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Inappropriate"
                                            value="Inappropriate"
                                            defaultChecked={isCheckboxChecked(
                                                "Inappropriate",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Indifferent to problems"
                                            value="Indifferent to problems"
                                            defaultChecked={isCheckboxChecked(
                                                "Indifferent to problems",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Congruent"
                                            value="Congruent"
                                            defaultChecked={isCheckboxChecked(
                                                "Congruent",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Irritated"
                                            value="Irritated"
                                            defaultChecked={isCheckboxChecked(
                                                "Irritated",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Hostile"
                                            value="Hostile"
                                            defaultChecked={isCheckboxChecked(
                                                "Hostile",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Flushing"
                                            value="flushing"
                                            defaultChecked={isCheckboxChecked(
                                                "flushing",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Dizziness"
                                            value="Dizziness"
                                            defaultChecked={isCheckboxChecked(
                                                "Dizziness",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Can't sit still"
                                            value="Can't sit still"
                                            defaultChecked={isCheckboxChecked(
                                                "Can't sit still",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Panicked"
                                            value="Panicked"
                                            defaultChecked={isCheckboxChecked(
                                                "Panicked",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Fearful"
                                            value="Fearful"
                                            defaultChecked={isCheckboxChecked(
                                                "Fearful",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Wretched"
                                            value="Wretched"
                                            defaultChecked={isCheckboxChecked(
                                                "Wretched",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Melancholy"
                                            value="Melancholy"
                                            defaultChecked={isCheckboxChecked(
                                                "Melancholy",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Sad"
                                            value="Sad"
                                            defaultChecked={isCheckboxChecked(
                                                "Sad",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Hopeless"
                                            value="Hopeless"
                                            defaultChecked={isCheckboxChecked(
                                                "Hopeless",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Bored"
                                            value="Bored"
                                            defaultChecked={isCheckboxChecked(
                                                "Bored",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Sorrow"
                                            value="Sorrow"
                                            defaultChecked={isCheckboxChecked(
                                                "Sorrow",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Grief"
                                            value="Grief"
                                            defaultChecked={isCheckboxChecked(
                                                "Grief",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Flight of ideas"
                                            value="Flight of ideas"
                                            defaultChecked={isCheckboxChecked(
                                                "Flight of ideas",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Manic"
                                            value="Manic"
                                            defaultChecked={isCheckboxChecked(
                                                "Manic",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Hypomanic"
                                            value="Hypomanic"
                                            defaultChecked={isCheckboxChecked(
                                                "Hypomanic",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Ashamed"
                                            value="Ashamed"
                                            defaultChecked={isCheckboxChecked(
                                                "Ashamed",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Embarrassed"
                                            value="Embarrassed"
                                            defaultChecked={isCheckboxChecked(
                                                "Embarrassed",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Apologetic"
                                            value="Apologetic"
                                            defaultChecked={isCheckboxChecked(
                                                "Apologetic",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Calm"
                                            value="Calm"
                                            defaultChecked={isCheckboxChecked(
                                                "Calm",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Guilty"
                                            value="Guilty"
                                            defaultChecked={isCheckboxChecked(
                                                "Guilty",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_affect_mood"
                                            )}
                                            label="Flat"
                                            value="Flat"
                                            defaultChecked={isCheckboxChecked(
                                                "Flat",
                                                formValues?.mental_health_affect_mood
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>
                    <div className="mb-12">
                        <Accordion
                            buttonPosition="left"
                            header={
                                <span className="note-label !mb-0">
                                    {
                                        INDIVIDUAL_INTAKE_NOTE_DETAILS.mental_health_attention_concentration_effort
                                    }
                                </span>
                            }
                            content={
                                <div className="mt-20">
                                    <div
                                        className={cn(
                                            "note-checkboxes-list !mb-0"
                                        )}
                                    >
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_attention_concentration_effort"
                                            )}
                                            label="Sluggish"
                                            value="Sluggish"
                                            defaultChecked={isCheckboxChecked(
                                                "Sluggish",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_attention_concentration_effort"
                                            )}
                                            label="Flat"
                                            value="Flat"
                                            defaultChecked={isCheckboxChecked(
                                                "Flat",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_attention_concentration_effort"
                                            )}
                                            label="Distractible"
                                            value="Distractible"
                                            defaultChecked={isCheckboxChecked(
                                                "Distractible",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_attention_concentration_effort"
                                            )}
                                            label="Normal energy"
                                            value="Normal energy"
                                            defaultChecked={isCheckboxChecked(
                                                "Normal energy",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_attention_concentration_effort"
                                            )}
                                            label="Eager"
                                            value="Eager"
                                            defaultChecked={isCheckboxChecked(
                                                "Eager",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_attention_concentration_effort"
                                            )}
                                            label="Indifferent"
                                            value="Indifferent"
                                            defaultChecked={isCheckboxChecked(
                                                "Indifferent",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_attention_concentration_effort"
                                            )}
                                            label="Scattered"
                                            value="Scattered"
                                            defaultChecked={isCheckboxChecked(
                                                "Scattered",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_attention_concentration_effort"
                                            )}
                                            label="Baffled"
                                            value="Baffled"
                                            defaultChecked={isCheckboxChecked(
                                                "Baffled",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_attention_concentration_effort"
                                            )}
                                            label="Perplexed"
                                            value="Perplexed"
                                            defaultChecked={isCheckboxChecked(
                                                "Perplexed",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_attention_concentration_effort"
                                            )}
                                            label="Hurried"
                                            value="Hurried"
                                            defaultChecked={isCheckboxChecked(
                                                "Hurried",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_attention_concentration_effort"
                                            )}
                                            label="Organized"
                                            value="Organized"
                                            defaultChecked={isCheckboxChecked(
                                                "Organized",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_attention_concentration_effort"
                                            )}
                                            label="Rigid"
                                            value="Rigid"
                                            defaultChecked={isCheckboxChecked(
                                                "Rigid",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_attention_concentration_effort"
                                            )}
                                            label="Pleasant"
                                            value="Pleasant"
                                            defaultChecked={isCheckboxChecked(
                                                "Pleasant",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_attention_concentration_effort"
                                            )}
                                            label="Cooperative"
                                            value="Cooperative"
                                            defaultChecked={isCheckboxChecked(
                                                "Cooperative",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_attention_concentration_effort"
                                            )}
                                            label="Dependent"
                                            value="Dependent"
                                            defaultChecked={isCheckboxChecked(
                                                "Dependent",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_attention_concentration_effort"
                                            )}
                                            label="Abusive"
                                            value="Abusive"
                                            defaultChecked={isCheckboxChecked(
                                                "Abusive",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_attention_concentration_effort"
                                            )}
                                            label="Superior"
                                            value="Superior"
                                            defaultChecked={isCheckboxChecked(
                                                "Superior",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_attention_concentration_effort"
                                            )}
                                            label="Stubborn"
                                            value="Stubborn"
                                            defaultChecked={isCheckboxChecked(
                                                "Stubborn",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_attention_concentration_effort"
                                            )}
                                            label="Belligerent"
                                            value="Belligerent"
                                            defaultChecked={isCheckboxChecked(
                                                "Belligerent",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_attention_concentration_effort"
                                            )}
                                            label="Argumentative"
                                            value="Argumentative"
                                            defaultChecked={isCheckboxChecked(
                                                "Argumentative",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_attention_concentration_effort"
                                            )}
                                            label="Hostile"
                                            value="Hostile"
                                            defaultChecked={isCheckboxChecked(
                                                "Hostile",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_attention_concentration_effort"
                                            )}
                                            label="Demanding"
                                            value="Demanding"
                                            defaultChecked={isCheckboxChecked(
                                                "Demanding",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_attention_concentration_effort"
                                            )}
                                            label="Resentful"
                                            value="Resentful"
                                            defaultChecked={isCheckboxChecked(
                                                "Resentful",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_attention_concentration_effort"
                                            )}
                                            label="Surly"
                                            value="Surly"
                                            defaultChecked={isCheckboxChecked(
                                                "Surly",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_attention_concentration_effort"
                                            )}
                                            label="Guarded"
                                            value="Guarded"
                                            defaultChecked={isCheckboxChecked(
                                                "Guarded",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_attention_concentration_effort"
                                            )}
                                            label="Indifferent"
                                            value="Indifferent"
                                            defaultChecked={isCheckboxChecked(
                                                "Indifferent",
                                                formValues?.mental_health_appearance
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>
                    <div className="mb-12">
                        <Accordion
                            buttonPosition="left"
                            header={
                                <span className="note-label !mb-0">
                                    {
                                        INDIVIDUAL_INTAKE_NOTE_DETAILS.mental_health_presentation_of_self
                                    }
                                </span>
                            }
                            content={
                                <div className="mt-20">
                                    <div
                                        className={cn(
                                            "note-checkboxes-list !mb-0"
                                        )}
                                    >
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_presentation_of_self"
                                            )}
                                            label="Withdrawn"
                                            value="Withdrawn"
                                            defaultChecked={isCheckboxChecked(
                                                "Withdrawn",
                                                formValues?.mental_health_presentation_of_self
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_presentation_of_self"
                                            )}
                                            label="Threatened"
                                            value="Threatened"
                                            defaultChecked={isCheckboxChecked(
                                                "Threatened",
                                                formValues?.mental_health_presentation_of_self
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_presentation_of_self"
                                            )}
                                            label="Vulnerable"
                                            value="Vulnerable"
                                            defaultChecked={isCheckboxChecked(
                                                "Vulnerable",
                                                formValues?.mental_health_presentation_of_self
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_presentation_of_self"
                                            )}
                                            label="Shy"
                                            value="Shy"
                                            defaultChecked={isCheckboxChecked(
                                                "Shy",
                                                formValues?.mental_health_presentation_of_self
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_presentation_of_self"
                                            )}
                                            label="Friendly"
                                            value="Friendly"
                                            defaultChecked={isCheckboxChecked(
                                                "Friendly",
                                                formValues?.mental_health_presentation_of_self
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_presentation_of_self"
                                            )}
                                            label="Confident"
                                            value="Confident"
                                            defaultChecked={isCheckboxChecked(
                                                "Confident",
                                                formValues?.mental_health_presentation_of_self
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_presentation_of_self"
                                            )}
                                            label="Grandiose"
                                            value="Grandiose"
                                            defaultChecked={isCheckboxChecked(
                                                "Grandiose",
                                                formValues?.mental_health_presentation_of_self
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_presentation_of_self"
                                            )}
                                            label="Humble"
                                            value="Humble"
                                            defaultChecked={isCheckboxChecked(
                                                "Humble",
                                                formValues?.mental_health_presentation_of_self
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_presentation_of_self"
                                            )}
                                            label="Self-doubting"
                                            value="Self-doubting"
                                            defaultChecked={isCheckboxChecked(
                                                "Self-doubting",
                                                formValues?.mental_health_presentation_of_self
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_presentation_of_self"
                                            )}
                                            label="Hopeful"
                                            value="Hopeful"
                                            defaultChecked={isCheckboxChecked(
                                                "Hopeful",
                                                formValues?.mental_health_presentation_of_self
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_presentation_of_self"
                                            )}
                                            label="Pessimistic"
                                            value="Pessimistic"
                                            defaultChecked={isCheckboxChecked(
                                                "Pessimistic",
                                                formValues?.mental_health_presentation_of_self
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_presentation_of_self"
                                            )}
                                            label="Overindulgent"
                                            value="Overindulgent"
                                            defaultChecked={isCheckboxChecked(
                                                "Overindulgent",
                                                formValues?.mental_health_presentation_of_self
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_presentation_of_self"
                                            )}
                                            label="Warm hearted"
                                            value="Warm hearted"
                                            defaultChecked={isCheckboxChecked(
                                                "Warm hearted",
                                                formValues?.mental_health_presentation_of_self
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_presentation_of_self"
                                            )}
                                            label="Distant"
                                            value="Distant"
                                            defaultChecked={isCheckboxChecked(
                                                "Distant",
                                                formValues?.mental_health_presentation_of_self
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_presentation_of_self"
                                            )}
                                            label="Harsh"
                                            value="Harsh"
                                            defaultChecked={isCheckboxChecked(
                                                "Harsh",
                                                formValues?.mental_health_presentation_of_self
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>
                    <div className="mb-32">
                        <Accordion
                            buttonPosition="left"
                            header={
                                <span className="note-label !mb-0">
                                    {
                                        INDIVIDUAL_INTAKE_NOTE_DETAILS.mental_health_cognition
                                    }
                                </span>
                            }
                            content={
                                <div className="mt-20">
                                    <div
                                        className={cn(
                                            "note-checkboxes-list !mb-0"
                                        )}
                                    >
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="Indecisive"
                                            value="Indecisive"
                                            defaultChecked={isCheckboxChecked(
                                                "Indecisive",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="Decisive"
                                            value="Decisive"
                                            defaultChecked={isCheckboxChecked(
                                                "Decisive",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="Some insight"
                                            value="Some insight"
                                            defaultChecked={isCheckboxChecked(
                                                "Some insight",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="Sluggish recall"
                                            value="Sluggish recall"
                                            defaultChecked={isCheckboxChecked(
                                                "Sluggish recall",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="Normal memory"
                                            value="Normal memory"
                                            defaultChecked={isCheckboxChecked(
                                                "Normal memory",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="Low intelligent"
                                            value="Low intelligent"
                                            defaultChecked={isCheckboxChecked(
                                                "Low intelligent",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="Good orientation"
                                            value="Good orientation"
                                            defaultChecked={isCheckboxChecked(
                                                "Good orientation",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="Defective reality"
                                            value="Defective reality"
                                            defaultChecked={isCheckboxChecked(
                                                "Defective reality",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="Flight of ideas"
                                            value="Flight of ideas"
                                            defaultChecked={isCheckboxChecked(
                                                "Flight of ideas",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="Incoherent thought process"
                                            value="Incoherent thought process"
                                            defaultChecked={isCheckboxChecked(
                                                "Incoherent thought process",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="Procrastinates"
                                            value="Procrastinates"
                                            defaultChecked={isCheckboxChecked(
                                                "Procrastinates",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="Follows through"
                                            value="Follows through"
                                            defaultChecked={isCheckboxChecked(
                                                "Follows through",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="Full insight"
                                            value="Full insight"
                                            defaultChecked={isCheckboxChecked(
                                                "Full insight",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="Amnesia"
                                            value="Amnesia"
                                            defaultChecked={isCheckboxChecked(
                                                "Amnesia",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="Intelligent"
                                            value="Intelligent"
                                            defaultChecked={isCheckboxChecked(
                                                "Intelligent",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="Normal judgment"
                                            value="Normal judgment"
                                            defaultChecked={isCheckboxChecked(
                                                "Normal judgment",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="Intact reality"
                                            value="Intact reality"
                                            defaultChecked={isCheckboxChecked(
                                                "Intact reality",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="Normal abstraction"
                                            value="Normal abstraction"
                                            defaultChecked={isCheckboxChecked(
                                                "Normal abstraction",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="Loose thought process"
                                            value="Loose thought process"
                                            defaultChecked={isCheckboxChecked(
                                                "Loose thought process",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="Clear thought process"
                                            value="Clear thought process"
                                            defaultChecked={isCheckboxChecked(
                                                "Clear thought process",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="Easily confused"
                                            value="Easily confused"
                                            defaultChecked={isCheckboxChecked(
                                                "Easily confused",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="No insight"
                                            value="No insight"
                                            defaultChecked={isCheckboxChecked(
                                                "No insight",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="Forgetful"
                                            value="Forgetful"
                                            defaultChecked={isCheckboxChecked(
                                                "Forgetful",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="Confuses time frames/sequence"
                                            value="Confuses time frames/sequence"
                                            defaultChecked={isCheckboxChecked(
                                                "Confuses time frames/sequence",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="Lack of common sense"
                                            value="Lack of common sense"
                                            defaultChecked={isCheckboxChecked(
                                                "Lack of common sense",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="Victimization"
                                            value="Victimization"
                                            defaultChecked={isCheckboxChecked(
                                                "Victimization",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="Confused present location/date/persons, places"
                                            value="Confused present location/date/persons, places"
                                            defaultChecked={isCheckboxChecked(
                                                "Confused present location/date/persons, places",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="Faulty reasoning"
                                            value="Faulty reasoning"
                                            defaultChecked={isCheckboxChecked(
                                                "Faulty reasoning",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="Impoverished stream of thoughts"
                                            value="Impoverished stream of thoughts"
                                            defaultChecked={isCheckboxChecked(
                                                "Impoverished stream of thoughts",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                        <FormCheckbox
                                            {...register(
                                                "mental_health_cognition"
                                            )}
                                            label="Normal stream of thoughts"
                                            value="Normal stream of thoughts"
                                            defaultChecked={isCheckboxChecked(
                                                "Normal stream of thoughts",
                                                formValues?.mental_health_cognition
                                            )}
                                            onBlur={
                                                onSubmit &&
                                                handleSubmit(onSubmit)
                                            }
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>
                    <div>
                        <h3 className="note-label">
                            {
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.patients_ability_and_capability_to_respond_to_treatment
                            }
                        </h3>
                        <div className="note-checkboxes">
                            <FormCheckbox
                                {...register(
                                    `patients_ability_and_capability_to_respond_to_treatment`
                                )}
                                label="Consistent, good-quality patient-oriented evidence"
                                value="Consistent, good-quality patient-oriented evidence"
                                defaultChecked={isCheckboxChecked(
                                    "Consistent, good-quality patient-oriented evidence",
                                    formValues?.patients_ability_and_capability_to_respond_to_treatment
                                )}
                                onBlur={onSubmit && handleSubmit(onSubmit)}
                            />
                            <FormCheckbox
                                {...register(
                                    `patients_ability_and_capability_to_respond_to_treatment`
                                )}
                                label="Diminished/poor or limited-quality patient-oriented evidence"
                                value="Diminished/poor or limited-quality patient-oriented evidence"
                                defaultChecked={isCheckboxChecked(
                                    "Diminished/poor or limited-quality patient-oriented evidence",
                                    formValues?.patients_ability_and_capability_to_respond_to_treatment
                                )}
                                onBlur={onSubmit && handleSubmit(onSubmit)}
                            />
                            {(
                                watch(
                                    `patients_ability_and_capability_to_respond_to_treatment`
                                ) || []
                            )?.includes(
                                "Diminished/poor or limited-quality patient-oriented evidence"
                            ) && (
                                <>
                                    <span className="note-label">
                                        {
                                            INDIVIDUAL_INTAKE_NOTE_DETAILS.details_for_diminished_poor_limited_quality_patient_oriented_evidence
                                        }
                                    </span>
                                    <TextArea
                                        {...register(
                                            `details_for_diminished_poor_limited_quality_patient_oriented_evidence`
                                        )}
                                        defaultValue={
                                            formValues?.details_for_diminished_poor_limited_quality_patient_oriented_evidence
                                        }
                                        hasError={
                                            !!errors.details_for_diminished_poor_limited_quality_patient_oriented_evidence
                                        }
                                        errorText={
                                            errors
                                                .details_for_diminished_poor_limited_quality_patient_oriented_evidence
                                                ?.message
                                        }
                                        onBlur={
                                            onSubmit && handleSubmit(onSubmit)
                                        }
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </section>
                <section id="step-seven" className="mb-40">
                    <h3 className="note-header">Summary</h3>
                    <p className="note-label-req">
                        {
                            INDIVIDUAL_INTAKE_NOTE_DETAILS.people_present_at_session
                        }
                    </p>
                    <div className="note-checkboxes-list">
                        <FormCheckbox
                            {...register("people_present_at_session")}
                            label="Patient"
                            value="Patient"
                            defaultChecked={isCheckboxChecked(
                                "Patient",
                                formValues?.people_present_at_session
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("people_present_at_session")}
                            label="Mother"
                            value="Mother"
                            defaultChecked={isCheckboxChecked(
                                "Mother",
                                formValues?.people_present_at_session
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("people_present_at_session")}
                            label="Stepfather"
                            value="Stepfather"
                            defaultChecked={isCheckboxChecked(
                                "Stepfather",
                                formValues?.people_present_at_session
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("people_present_at_session")}
                            label="Spouse"
                            value="Spouse"
                            defaultChecked={isCheckboxChecked(
                                "Spouse",
                                formValues?.people_present_at_session
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("people_present_at_session")}
                            label="Father"
                            value="Father"
                            defaultChecked={isCheckboxChecked(
                                "Father",
                                formValues?.people_present_at_session
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("people_present_at_session")}
                            label="Stepmother"
                            value="Stepmother"
                            defaultChecked={isCheckboxChecked(
                                "Stepmother",
                                formValues?.people_present_at_session
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("people_present_at_session")}
                            label="Partner"
                            value="Partner"
                            defaultChecked={isCheckboxChecked(
                                "Partner",
                                formValues?.people_present_at_session
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("people_present_at_session")}
                            label="Guardian"
                            value="Guardian"
                            defaultChecked={isCheckboxChecked(
                                "Guardian",
                                formValues?.people_present_at_session
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("people_present_at_session")}
                            label="Other"
                            value="Other"
                            defaultChecked={isCheckboxChecked(
                                "Other",
                                formValues?.people_present_at_session
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    {errors.people_present_at_session ? (
                        <div className="note-error">
                            <p>People present at session is required</p>
                        </div>
                    ) : null}
                    <div className="fg">
                        <Input
                            {...register("other_person_present_at_session")}
                            label={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.other_person_present_at_session
                            }
                            placeholder={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.other_person_present_at_session
                            }
                            defaultValue={
                                formValues?.other_person_present_at_session
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <p className="note-label-req">
                        {
                            INDIVIDUAL_INTAKE_NOTE_DETAILS.type_of_treatment_recommended
                        }
                    </p>
                    <div className="note-checkboxes-list">
                        <FormCheckbox
                            {...register("type_of_treatment_recommended")}
                            label="Individual"
                            value="Individual"
                            defaultChecked={isCheckboxChecked(
                                "Individual",
                                formValues?.type_of_treatment_recommended
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("type_of_treatment_recommended")}
                            label="Family"
                            value="Family"
                            defaultChecked={isCheckboxChecked(
                                "Family",
                                formValues?.type_of_treatment_recommended
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("type_of_treatment_recommended")}
                            label="Group"
                            value="Group"
                            defaultChecked={isCheckboxChecked(
                                "Group",
                                formValues?.type_of_treatment_recommended
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("type_of_treatment_recommended")}
                            label="Collateral/Parenting"
                            value="Collateral/Parenting"
                            defaultChecked={isCheckboxChecked(
                                "Collateral/Parenting",
                                formValues?.type_of_treatment_recommended
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    {errors.type_of_treatment_recommended ? (
                        <div className="note-error">
                            <p>Type of treatment recommended is required</p>
                        </div>
                    ) : null}
                    <p className="note-label-req">
                        {
                            INDIVIDUAL_INTAKE_NOTE_DETAILS.treatment_modality_intervention
                        }
                    </p>
                    <div className="note-checkboxes-list">
                        <FormCheckbox
                            {...register("treatment_modality_intervention")}
                            label="Cognitive/Behavioral"
                            value="Cognitive/Behavioral"
                            defaultChecked={isCheckboxChecked(
                                "Cognitive/Behavioral",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("treatment_modality_intervention")}
                            label="Behavioral Modification"
                            value="Behavioral Modification"
                            defaultChecked={isCheckboxChecked(
                                "Behavioral Modification",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("treatment_modality_intervention")}
                            label="Supportive"
                            value="Supportive"
                            defaultChecked={isCheckboxChecked(
                                "Supportive",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("treatment_modality_intervention")}
                            label="Marital/Couples Therapy "
                            value="Marital/Couples Therapy "
                            defaultChecked={isCheckboxChecked(
                                "Marital/Couples Therapy ",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("treatment_modality_intervention")}
                            label="Family Therapy"
                            value="Family Therapy"
                            defaultChecked={isCheckboxChecked(
                                "Family Therapy",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("treatment_modality_intervention")}
                            label="Stress Management"
                            value="Stress Management"
                            defaultChecked={isCheckboxChecked(
                                "Stress Management",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("treatment_modality_intervention")}
                            label="Psychodynamic"
                            value="Psychodynamic"
                            defaultChecked={isCheckboxChecked(
                                "Psychodynamic",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("treatment_modality_intervention")}
                            label="Parent Training"
                            value="Parent Training"
                            defaultChecked={isCheckboxChecked(
                                "Parent Training",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("treatment_modality_intervention")}
                            label="Crisis Intervention"
                            value="Crisis Intervention"
                            defaultChecked={isCheckboxChecked(
                                "Crisis Intervention",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register("treatment_modality_intervention")}
                            label="Other"
                            value="Other"
                            defaultChecked={isCheckboxChecked(
                                "Other",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    {errors.treatment_modality_intervention ? (
                        <div className="note-error">
                            <p>Treatment modality/intervention is required</p>
                        </div>
                    ) : null}
                    <div className="fg">
                        <Input
                            {...register(
                                "other_treatment_modality_intervention"
                            )}
                            label={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.other_treatment_modality_intervention
                            }
                            placeholder={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.other_treatment_modality_intervention
                            }
                            defaultValue={
                                formValues?.other_treatment_modality_intervention
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <p className="note-label">Treatment goals</p>
                    <div className="flex flex-col gap-y-20 mb-32">
                        <Input
                            {...register("treatment_goal_one")}
                            label={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.treatment_goal_one
                            }
                            placeholder={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.treatment_goal_one
                            }
                            defaultValue={formValues?.treatment_goal_one}
                            isRequired
                            hasError={!!errors.treatment_goal_one}
                            errorText={errors.treatment_goal_one?.message}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <Input
                            {...register("treatment_goal_two")}
                            label={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.treatment_goal_two
                            }
                            placeholder={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.treatment_goal_two
                            }
                            defaultValue={formValues?.treatment_goal_two}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <Input
                            {...register("treatment_goal_three")}
                            label={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.treatment_goal_three
                            }
                            placeholder={
                                INDIVIDUAL_INTAKE_NOTE_DETAILS.treatment_goal_three
                            }
                            defaultValue={formValues?.treatment_goal_three}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <h3 className="note-label">
                        {INDIVIDUAL_INTAKE_NOTE_DETAILS.frequency_of_sessions}
                    </h3>
                    <div className="note-radios">
                        <RadioInput
                            {...register("frequency_of_sessions")}
                            label="Weekly"
                            value="Weekly"
                            defaultChecked={
                                formValues?.frequency_of_sessions === "Weekly"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <RadioInput
                            {...register("frequency_of_sessions")}
                            label="Bi-Weekly"
                            value="Bi-Weekly"
                            defaultChecked={
                                formValues?.frequency_of_sessions ===
                                "Bi-Weekly"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <RadioInput
                            {...register("frequency_of_sessions")}
                            label="Monthly"
                            value="Monthly"
                            defaultChecked={
                                formValues?.frequency_of_sessions === "Monthly"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <RadioInput
                            {...register("frequency_of_sessions")}
                            label="Other"
                            value="Other"
                            defaultChecked={
                                formValues?.frequency_of_sessions === "Other"
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    {watch("frequency_of_sessions") === "Other" && (
                        <div className="fg">
                            <Input
                                {...register("other_frequency_of_sessions")}
                                label={
                                    INDIVIDUAL_INTAKE_NOTE_DETAILS.other_frequency_of_sessions
                                }
                                placeholder={
                                    INDIVIDUAL_INTAKE_NOTE_DETAILS.other_frequency_of_sessions
                                }
                                hasError={!!errors.other_frequency_of_sessions}
                                errorText={
                                    errors.other_frequency_of_sessions?.message
                                }
                                defaultValue={
                                    formValues?.other_frequency_of_sessions
                                }
                                onBlur={onSubmit && handleSubmit(onSubmit)}
                            />
                        </div>
                    )}
                    <div className="fg">
                        <Controller
                            name="date_of_next_session"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    label={
                                        INDIVIDUAL_INTAKE_NOTE_DETAILS.date_of_next_session
                                    }
                                    onChange={(date) => {
                                        field.onChange(date);
                                        setLocalDateOfNextSession(date);
                                    }}
                                    selected={localDateOfNextSession}
                                    value={
                                        field.value
                                            ? formatDate(field.value, true)
                                            : undefined
                                    }
                                    minDate={new Date(Date.now())}
                                    hasError={!!errors.date_of_next_session}
                                    errorText={
                                        errors.date_of_next_session?.type ===
                                        "typeError"
                                            ? "Invalid date value"
                                            : errors.date_of_next_session
                                                  ?.message
                                    }
                                    onBlur={onSubmit && handleSubmit(onSubmit)}
                                    shouldCloseOnSelect
                                    onCalendarClose={
                                        onSubmit && handleSubmit(onSubmit)
                                    }
                                />
                            )}
                        />
                    </div>
                    <h3 className="note-label-req">
                        {INDIVIDUAL_INTAKE_NOTE_DETAILS.subjective_report}
                    </h3>
                    <p className="note-label">
                        Please make sure to only include what the client has
                        reported. This should only tie back to what the client
                        reported in the session, re; symptoms, mood/affect,
                        ideations, interpersonal conflicts, etc. Do not include
                        your assessment or interpretation of the clients
                        symptoms here.
                    </p>
                    <div className="fg">
                        <TextArea
                            {...register("subjective_report")}
                            defaultValue={formValues?.subjective_report}
                            hasError={!!errors.subjective_report}
                            errorText={errors.subjective_report?.message}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <h3 className="note-label-req">
                        {INDIVIDUAL_INTAKE_NOTE_DETAILS.summary}
                    </h3>
                    <div className="fg">
                        <TextArea
                            {...register("summary")}
                            defaultValue={formValues?.subjective_report}
                            hasError={!!errors.summary}
                            errorText={errors.summary?.message}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <h3 className="note-label">
                        {INDIVIDUAL_INTAKE_NOTE_DETAILS.prognosis}
                    </h3>
                    <p className="note-label">
                        Please write at least 1-2 sentences about the likely
                        outcome/development of the symptoms/challenges
                    </p>
                    <div className="fg">
                        <TextArea
                            {...register("prognosis")}
                            defaultValue={formValues?.prognosis}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <h3 className="note-label">
                        {
                            INDIVIDUAL_INTAKE_NOTE_DETAILS.communication_factors_impacting_treatment
                        }
                    </h3>
                    <div className="note-checkboxes">
                        <FormCheckbox
                            {...register(
                                "communication_factors_impacting_treatment"
                            )}
                            label="Need to manage maladaptive communication (e.g., related to high anxiety, high reactivity repeated questions, or disagreement) among participants"
                            value="Need to manage maladaptive communication (e.g., related to high anxiety, high reactivity repeated questions, or disagreement) among participants"
                            defaultChecked={isCheckboxChecked(
                                "Need to manage maladaptive communication (e.g., related to high anxiety, high reactivity repeated questions, or disagreement) among participants",
                                formValues?.communication_factors_impacting_treatment
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(
                                "communication_factors_impacting_treatment"
                            )}
                            label="Caregiver emotions or behaviors interfered with the caregiver's understanding and ability"
                            value="Caregiver emotions or behaviors interfered with the caregiver's understanding and ability"
                            defaultChecked={isCheckboxChecked(
                                "Caregiver emotions or behaviors interfered with the caregiver's understanding and ability",
                                formValues?.communication_factors_impacting_treatment
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(
                                "communication_factors_impacting_treatment"
                            )}
                            label="Evidence or disclosure of a sentinel event and mandated reporting to a third party"
                            value="Evidence or disclosure of a sentinel event and mandated reporting to a third party"
                            defaultChecked={isCheckboxChecked(
                                "Evidence or disclosure of a sentinel event and mandated reporting to a third party",
                                formValues?.communication_factors_impacting_treatment
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(
                                "communication_factors_impacting_treatment"
                            )}
                            label="Use of play equipment or other physical devices to communicate with the client to overcome barriers to therapeutic or diagnostic interaction"
                            value="Use of play equipment or other physical devices to communicate with the client to overcome barriers to therapeutic or diagnostic interaction"
                            defaultChecked={isCheckboxChecked(
                                "Use of play equipment or other physical devices to communicate with the client to overcome barriers to therapeutic or diagnostic interaction",
                                formValues?.communication_factors_impacting_treatment
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(
                                "communication_factors_impacting_treatment"
                            )}
                            label="Client has not developed, or lost, either the expressive language communication skills to explain their symptoms and respond to treatment"
                            value="Client has not developed, or lost, either the expressive language communication skills to explain their symptoms and respond to treatment"
                            defaultChecked={isCheckboxChecked(
                                "Client has not developed, or lost, either the expressive language communication skills to explain their symptoms and respond to treatment",
                                formValues?.communication_factors_impacting_treatment
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                        <FormCheckbox
                            {...register(
                                "communication_factors_impacting_treatment"
                            )}
                            label="Client lacks the receptive communication skills to understand the therapist when using typical language for communication"
                            value="Client lacks the receptive communication skills to understand the therapist when using typical language for communication"
                            defaultChecked={isCheckboxChecked(
                                "Client lacks the receptive communication skills to understand the therapist when using typical language for communication",
                                formValues?.communication_factors_impacting_treatment
                            )}
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                    <h3 className="note-label">
                        {
                            INDIVIDUAL_INTAKE_NOTE_DETAILS.details_around_communication_factors_impacting_treatment
                        }
                    </h3>
                    <TextArea
                        {...register(
                            "details_around_communication_factors_impacting_treatment"
                        )}
                        defaultValue={
                            formValues?.details_around_communication_factors_impacting_treatment
                        }
                        onBlur={onSubmit && handleSubmit(onSubmit)}
                    />
                </section>

                <div className="fg-info fg-line">
                    <p>Sign note here</p>
                    <div className="fg">
                        <Input
                            {...register("signature")}
                            hasError={!!errors.signature}
                            errorText={errors.signature?.message}
                            label="Provider's Initials"
                            placeholder="Provider's Initials"
                            autoComplete="off"
                            isRequired
                        />
                    </div>
                </div>
            </form>
        </>
    );
}
