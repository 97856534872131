/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import {
    Button,
    Card,
    DatePicker,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Popover,
    PopoverContent,
    PopoverTrigger,
    SearchInput,
    Table,
    Tag,
} from "@jhool-io/fe-components";
import { ColumnDef, ExpandedState, Row } from "@tanstack/react-table";
import { IAppCustomDates } from "../../../../utils/types";
import { NoteLabels, NoteStatus } from "../../../notes/types/notes.types";
import { useDebounce } from "../../../../hooks/helpers";
import {
    useExportProviderSessionActivityReport,
    useFetchProviderSessionActivityReport,
} from "../../hooks/queries/reports.queries";
import { ProviderCredential } from "../../../clients/types/clients.types";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";
import {
    cn,
    convertDateFilterStringToDate,
    convertTimeInSecondsToOtherTimeUnits,
    formatDate,
    getCorrectTimeValueFromIsoStringInNewYorkTimeZone,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    truncateString,
} from "../../../../utils/helpers";
import CheckPrimaryColorIcon from "../../../../components/Icons/CheckPrimaryColor";
import {
    IProviderSessionActivityItem,
    IProviderSessionStatsResponse,
    ISessionActivity,
} from "../../types/reports.types";
import { IApiResponse } from "../../../../utils/types/api-response";
import { fetchProviderSessionStats } from "../../services/reports.services";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import { SessionLabels } from "../../../../utils/types/session";
import { getNoteStatusTag } from "../../../notes/helpers/notes.helpers";
import UnionUpIcon from "../../../../components/Icons/UnionUp";
import UnionDownIcon from "../../../../components/Icons/UnionDown";
import TableFiltersDisplay from "../../../../shared-ui/TableFiltersDisplay/TableFiltersDisplay";
import useToast from "../../../../hooks/useToast";

interface IProviderRowBreakdown {
    [providerId: string]: {
        expanded: boolean;
        response: IApiResponse<IProviderSessionStatsResponse> | null;
        isLoading: boolean;
    };
}

export default function ProviderSessionActivity() {
    const [expandedRows, setExpandedRows] = React.useState<ExpandedState>({});
    const [providerRowBreakdown, setProviderRowBreakdown] =
        React.useState<IProviderRowBreakdown>({});
    const [isExporting, setIsExporting] = React.useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    const toast = useToast();

    const providerNameFilter = searchParams.get("search") || "";
    const providerCredentialFilter = searchParams.get("credential") || "";
    const customDateFilter = searchParams.get("date_range") || "this_month";
    const noteStatusFilter = searchParams.getAll("note_status[]");
    const fromDateFilter = searchParams.get("from_date");
    const toDateFilter = searchParams.get("to_date");
    const fromDateCreatedFilter = searchParams.get("from_date_created");
    const toDateCreatedFilter = searchParams.get("to_date_created");

    const fromDateFilterToDate = convertDateFilterStringToDate(fromDateFilter);
    const toDateFilterToDate = convertDateFilterStringToDate(toDateFilter);
    const fromDateCreatedFilterToDate = convertDateFilterStringToDate(
        fromDateCreatedFilter
    );
    const toDateCreatedFilterToDate =
        convertDateFilterStringToDate(toDateCreatedFilter);

    const name = useDebounce(providerNameFilter, 500);

    const { data, isLoading, error, isSuccess, isFetching } =
        useFetchProviderSessionActivityReport({
            provider_name: name,
            custom_date: customDateFilter,
            provider_credential: providerCredentialFilter,
            from_date: fromDateFilter,
            to_date: toDateFilter,
            note_status: noteStatusFilter,
            from_created_at_date: fromDateCreatedFilter,
            to_created_at_date: toDateCreatedFilter,
        });

    const exportQuery = useExportProviderSessionActivityReport(
        {
            custom_date: customDateFilter,
            provider_name: providerNameFilter,
            provider_credential: providerCredentialFilter,
            from_date: fromDateFilter,
            to_date: toDateFilter,
            note_status: noteStatusFilter,
            from_created_at_date: fromDateCreatedFilter,
            to_created_at_date: toDateCreatedFilter,
        },
        isExporting
    );

    const credentialOptionsForSelect = [
        ...Object.values(ProviderCredential).map((credential) => {
            return credential;
        }),
    ];

    const customDateForSelect = [
        ...Object.values(IAppCustomDates).map((item) => {
            return item;
        }),
    ];

    const noteStatusForSelect = [
        ...Object.values(NoteStatus)
            .filter((status) => status !== NoteStatus.DRAFT)
            .map((status) => {
                return status;
            }),
    ];

    // onChange handler for search input
    const handleSetSearchString = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") searchParams.delete("search");
        else searchParams.set("search", e.target.value);
        localStorage.setItem(
            "providersactivity_filters",
            searchParams.toString()
        );
        setSearchParams(searchParams);
    };

    const handleCredentialChange = (value: string) => {
        if (value === "") searchParams.delete("credential");
        else searchParams.set("credential", value);
        searchParams.set("page", "1");
        localStorage.setItem(
            "providersactivity_filters",
            searchParams.toString()
        );
        setSearchParams(searchParams);
    };

    const handleCustomDateChange = (value: IAppCustomDates) => {
        searchParams.set("date_range", value);
        searchParams.set("page", "1");
        localStorage.setItem(
            "providersactivity_filters",
            searchParams.toString()
        );
        setSearchParams(searchParams);
    };

    const handleNoteStatusChange = (status: string) => {
        if (noteStatusFilter.includes(status)) {
            searchParams.delete("note_status[]", status);
        } else {
            searchParams.append("note_status[]", status);
        }
        searchParams.set("page", "1");
        localStorage.setItem(
            "providersactivity_filters",
            searchParams.toString()
        );
        setSearchParams(searchParams);
    };

    const handleFromDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("from_date");
        else searchParams.set("from_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        localStorage.setItem(
            "providersactivity_filters",
            searchParams.toString()
        );
        setSearchParams(searchParams);
    };

    const handleToDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("to_date");
        else searchParams.set("to_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        localStorage.setItem(
            "providersactivity_filters",
            searchParams.toString()
        );
        setSearchParams(searchParams);
    };

    const handleFromDateCreatedFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("from_date_created");
        else searchParams.set("from_date_created", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        localStorage.setItem(
            "providersactivity_filters",
            searchParams.toString()
        );
        setSearchParams(searchParams);
    };

    const handleToDateCreatedFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("to_date_created");
        else searchParams.set("to_date_created", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        localStorage.setItem(
            "providersactivity_filters",
            searchParams.toString()
        );
        setSearchParams(searchParams);
    };

    const handleExportClick = async () => {
        setIsExporting(true);
        await exportQuery.refetch();
        setIsExporting(false);
        if (exportQuery.error) {
            toast.toast({
                mode: "error",
                message: "Could not export report.",
            });
        }
    };

    const handleViewSessionNote = (clientId: string, noteId: string) => {
        navigate(`/notes/${clientId}/${noteId}`);
    };

    // eslint-disable-next-line consistent-return
    const getStatusTag = (item: IProviderSessionActivityItem) => {
        if (item.note_status) {
            return getNoteStatusTag(item.note_status);
        }

        if (item.is_cancelled && !item.note_status) {
            return (
                <Tag
                    title="Cancelled, No Note"
                    textColor="#BC2C7C"
                    bgColor="rgba(255, 246, 251, 1)"
                    className="font-normal px-8 py-4 h-24 rounded-r4"
                />
            );
        }

        if (!item.is_cancelled && !item.note_status) {
            return (
                <Tag
                    title="Missing Note"
                    textColor="#981F41"
                    bgColor="rgba(251, 199, 198, 0.5)"
                    className="font-normal px-8 py-4 h-24 rounded-r4"
                />
            );
        }
    };

    // This function is used to update the state that stores the expanded state,
    //  response, and loading status for each provider's row breakdown.
    const setExpandedProvider = (providerId: string, isExpanded: boolean) => {
        // Use the state updater function with the current state to avoid modifying it directly.
        setProviderRowBreakdown((currentBreakdown) => {
            // Create a clone of the current breakdown state.
            const clonedBreakdown = { ...currentBreakdown };

            // Get the existing breakdown record for a particular providerId, or create one.
            const providerBreakdownRecord = clonedBreakdown[providerId] || {
                expanded: false,
                response: null,
                isLoading: false,
            };

            // Update the 'expanded' property of the breakdown with the new value.
            providerBreakdownRecord.expanded = isExpanded;

            // If the row is being expanded, update the 'isLoading' and 'response' properties.
            if (isExpanded) {
                providerBreakdownRecord.isLoading = true;
                providerBreakdownRecord.response = null;
            }

            // Update the cloned breakdown object with the modified provider breakdown values.
            clonedBreakdown[providerId] = providerBreakdownRecord;

            // Return the updated cloned breakdown object as the new state.
            return clonedBreakdown;
        });
    };

    // This function is used to refresh provider breakdown data based on specific conditions.
    const refreshProviderBreakdownData = async ({
        refreshAllOpened,
        customDate_ = IAppCustomDates.THIS_MONTH,
        providerRowBreakdown_,
        fromDate,
        toDate,
        noteStatus,
        fromDateCreated,
        toDateCreated,
    }: {
        refreshAllOpened: boolean;
        customDate_?: IAppCustomDates;
        providerRowBreakdown_: IProviderRowBreakdown;
        fromDate?: string | null;
        toDate?: string | null;
        noteStatus?: string[];
        fromDateCreated?: string | null;
        toDateCreated?: string | null;
    }) => {
        // Create a clone of the original providerRowBreakdown_.
        const clonedProviderBreakdown = { ...providerRowBreakdown_ };

        // Determine which providerIds should be refreshed.
        const providerIdsToRefresh = Object.keys(
            clonedProviderBreakdown
        ).filter((providerId) =>
            refreshAllOpened
                ? clonedProviderBreakdown[providerId].expanded
                : clonedProviderBreakdown[providerId].expanded &&
                  clonedProviderBreakdown[providerId].response === null
        );

        // Check if there are providerIds to refresh.
        if (providerIdsToRefresh.length > 0) {
            // Use Promise.all to make API requests for each providerId.
            await Promise.all(
                providerIdsToRefresh.map(async (providerId) => {
                    // Fetch provider session stats using the provided function.
                    const response = await fetchProviderSessionStats(
                        providerId,
                        {
                            custom_date: customDate_,
                            from_date: fromDate,
                            to_date: toDate,
                            note_status: noteStatus,
                            from_created_at_date: fromDateCreated,
                            to_created_at_date: toDateCreated,
                        }
                    );

                    // Update the clonedProviderBreakdown with the new response and reset loading status.
                    clonedProviderBreakdown[providerId].response = response;
                    clonedProviderBreakdown[providerId].isLoading = false;
                })
            );

            // Update the state with the modified providerRowBreakdown.
            setProviderRowBreakdown(clonedProviderBreakdown);
        }
    };

    // these are the column items that show when a row is expanded
    const renderRowSubComponent =
        // eslint-disable-next-line react/no-unused-prop-types
        ({ row }: { row: Row<ISessionActivity> }) => {
            const providerIdToRender = row.original.provider_id;

            const matchingResponse:
                | {
                      response: IApiResponse<IProviderSessionStatsResponse>;
                  }
                | undefined =
                providerIdToRender in providerRowBreakdown
                    ? {
                          response: providerRowBreakdown[providerIdToRender]
                              .response as IApiResponse<IProviderSessionStatsResponse>,
                      }
                    : undefined;

            return (
                <div>
                    {providerRowBreakdown[providerIdToRender].isLoading && (
                        <Skeleton
                            type="table"
                            containerTestId="provideractivity-loader"
                            count={6}
                        />
                    )}
                    {matchingResponse &&
                        matchingResponse.response?.data?.activity_list?.length <
                            1 && (
                            <ListState
                                stateHelperText="Provider session activity breakdown for this provider will appear here when available"
                                isError={false}
                                emptyMessage="No breakdown is available for this provider"
                            />
                        )}
                    {matchingResponse &&
                        matchingResponse?.response?.data?.activity_list
                            ?.length > 0 && (
                            <div className="overflow-x-auto max-w-full">
                                <div className="-ml-10 grid grid-cols-10 gap-x-[1rem] gap-y-[1.4rem] text-xs pl-36 py-10 text-gray border-b border-secondary">
                                    <div>CLIENT</div>
                                    <div>DATE OF SERVICE</div>
                                    <div>SERVICE TYPE</div>
                                    <div>SESSION TIME</div>
                                    <div>DURATION</div>
                                    <div>NOTE TYPE</div>
                                    <div>DATE CREATED</div>
                                    <div>LATE NOTE</div>
                                    <div className="col-[9_/_span_10]">
                                        STATUS
                                    </div>
                                </div>
                                {matchingResponse.response?.data?.activity_list?.map(
                                    (item) => (
                                        <div
                                            className="-ml-10 grid grid-cols-10 gap-x-[1rem] gap-y-[1.4rem] pl-36 border-b border-secondary text-sm py-10 hover:bg-card-bg hover:cursor-pointer"
                                            key={item.note_id}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (item.note_id) {
                                                    handleViewSessionNote(
                                                        item.client.client_id,
                                                        item.note_id
                                                    );
                                                }
                                            }}
                                            role="button"
                                            tabIndex={0}
                                            onKeyDown={(e) => {
                                                if (
                                                    e.key === "Enter" ||
                                                    e.key === " "
                                                ) {
                                                    e.preventDefault();
                                                    if (item.note_id) {
                                                        handleViewSessionNote(
                                                            item.client
                                                                .client_id,
                                                            item.note_id
                                                        );
                                                    }
                                                }
                                            }}
                                        >
                                            <div>
                                                {`${item.client.first_name} ${item.client.last_name}`}
                                            </div>
                                            <div>
                                                {formatDate(
                                                    item.date_of_service
                                                ) || "--"}
                                            </div>
                                            <div>
                                                {SessionLabels[item.service] ||
                                                    "--"}
                                            </div>
                                            <div>
                                                {getCorrectTimeValueFromIsoStringInNewYorkTimeZone(
                                                    item.start_date_time
                                                )}
                                            </div>
                                            <div>
                                                {convertTimeInSecondsToOtherTimeUnits(
                                                    item.duration_secs
                                                )}
                                            </div>
                                            <div>
                                                {NoteLabels[item.note_type] ||
                                                    "--"}
                                            </div>
                                            <div>
                                                {formatDate(item.created_at)}
                                            </div>
                                            <div
                                                className={
                                                    item.is_note_late
                                                        ? "text-primary"
                                                        : "text-danger"
                                                }
                                            >
                                                {item.is_note_late
                                                    ? "Yes"
                                                    : "No"}
                                            </div>
                                            <div className="col-[9_/_span_10]">
                                                {getStatusTag(item)}
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        )}
                </div>
            );
        };

    const getErrorAndLoadingStates = () => {
        if (error) {
            return "0";
        }

        if (isLoading) {
            return (
                <Skeleton
                    containerTestId="activity-summary-loader"
                    height={20}
                    width={20}
                />
            );
        }

        return "";
    };

    const columns: ColumnDef<ISessionActivity>[] = [
        {
            accessorKey: "first_name",
            header: "Provider name",
            cell: ({ row }) => (
                <div className="flex gap-12">
                    <Button
                        variant="normal"
                        size="auto"
                        onClick={() => {
                            row.toggleExpanded();
                            setExpandedProvider(
                                row.original.provider_id,
                                !row.getIsExpanded()
                            );
                        }}
                    >
                        {row.getIsExpanded() ? (
                            <UnionUpIcon />
                        ) : (
                            <UnionDownIcon />
                        )}
                    </Button>

                    <Link
                        to={`/providers/${row.original.provider_id}?tab=provider-caseload`}
                    >
                        {row.original.first_name} {row.original.last_name}
                    </Link>
                </div>
            ),
        },
        {
            accessorKey: "supervisor_first_name",
            header: "SUPERVISORS NAME",
            cell: ({ row }) =>
                row.original.supervisor_first_name ? (
                    <Link
                        to={`/providers/${row.original.supervisor_provider_id}?tab=Supervisees`}
                    >
                        {row.original.supervisor_first_name}{" "}
                        {row.original.supervisor_last_name}
                    </Link>
                ) : (
                    "--"
                ),
        },
        {
            accessorKey: "no_of_clients",
            header: "NO OF CLIENTS",
        },
        // {
        //     accessorKey: "sessions_completed",
        //     header: "SESSIONS COMPLETED",
        // },
        // {
        //     accessorKey: "provider_id",
        //     header: "NOTES DUE",
        //     cell: ({ row }) =>
        //         Math.max(
        //             row.original.sessions_completed -
        //                 row.original.notes_completed,
        //             0
        //         ),
        // },
        {
            accessorKey: "notes_completed",
            header: "NOTES COMPLETED",
            cell: ({ row }) => {
                const providerName = `${row.original.first_name} ${row.original.last_name}`;
                return (
                    <Link
                        to={`/notes?provider_name=${providerName}&status%5B%5D=${NoteStatus.CODED}&status%5B%5D=${NoteStatus.PENDING_CODE_REVIEW}`}
                    >
                        {row.original.notes_completed}
                    </Link>
                );
            },
        },
        {
            accessorKey: "notes_in_draft",
            header: "NOTES IN DRAFTS",
            cell: ({ row }) => row.original.notes_in_draft,
        },
        {
            accessorKey: "notes_in_draft",
            header: "NOTES PENDING CHANGES",
            cell: ({ row }) => row.original.notes_pending_changes,
        },
        {
            accessorKey: "notes_pending_review",
            header: "NOTES PENDING REVIEW",
            cell: ({ row }) => {
                const providerName = `${row.original.first_name} ${row.original.last_name}`;
                return (
                    <Link
                        to={`/notes?provider_name=${providerName}&status%5B%5D=${NoteStatus.PENDING_SUPERVISOR_REVIEW}`}
                    >
                        {row.original.notes_pending_review}
                    </Link>
                );
            },
        },
    ];

    // determine whether to refresh only the current expandedRow
    React.useEffect(() => {
        refreshProviderBreakdownData({
            refreshAllOpened: false,
            customDate_:
                (customDateFilter as IAppCustomDates) ||
                IAppCustomDates.THIS_MONTH,
            providerRowBreakdown_: providerRowBreakdown,
            fromDate: fromDateFilter,
            toDate: toDateFilter,
            noteStatus: noteStatusFilter,
            fromDateCreated: fromDateCreatedFilter,
            toDateCreated: toDateCreatedFilter,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providerRowBreakdown]);

    //  determine whether to refresh all expandedRows when date changes
    React.useEffect(() => {
        refreshProviderBreakdownData({
            refreshAllOpened: isFetching,
            customDate_:
                (customDateFilter as IAppCustomDates) ||
                IAppCustomDates.THIS_MONTH,
            providerRowBreakdown_: providerRowBreakdown,
            fromDate: fromDateFilter,
            toDate: toDateFilter,
            noteStatus: noteStatusFilter,
            fromDateCreated: fromDateCreatedFilter,
            toDateCreated: toDateCreatedFilter,
        });
    }, [
        customDateFilter,
        fromDateFilter,
        isFetching,
        noteStatusFilter,
        providerRowBreakdown,
        toDateFilter,
        fromDateCreatedFilter,
        toDateCreatedFilter,
    ]);

    return (
        <div>
            <div className=" bg-white rounded-r8 px-12 py-16 gap-y-24 mb-24">
                <div className="flex flex-col gap-y-24">
                    <SearchInput
                        placeholder="Search by provider"
                        containerClass="w-[400px]"
                        onChange={handleSetSearchString}
                        defaultValue={providerNameFilter}
                    />
                    <div className="flex items-center gap-12 justify-between flex-wrap">
                        <div className="flex gap-x-12 gap-y-24 flex-wrap">
                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <FilterButton
                                        text={
                                            providerCredentialFilter
                                                ? truncateString(
                                                      removeEnumUnderscore(
                                                          providerCredentialFilter
                                                      )
                                                  )
                                                : "Credential"
                                        }
                                    />
                                </DropdownTrigger>
                                <DropdownContent className="overflow-y-auto">
                                    <DropdownItem
                                        className={cn(
                                            "flex gap-x-8 items-center text-xs font-medium",
                                            {
                                                "text-primary":
                                                    !providerCredentialFilter,
                                            }
                                        )}
                                        onClick={() =>
                                            handleCredentialChange("")
                                        }
                                    >
                                        All
                                        {!providerCredentialFilter ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>
                                    {credentialOptionsForSelect.map(
                                        (credential) => (
                                            <DropdownItem
                                                className={cn(
                                                    "flex gap-x-8 items-center capitalize text-xs font-medium",
                                                    {
                                                        "text-primary":
                                                            providerCredentialFilter ===
                                                            credential,
                                                    }
                                                )}
                                                key={credential}
                                                onClick={() =>
                                                    handleCredentialChange(
                                                        credential
                                                    )
                                                }
                                            >
                                                {makeStringFirstLetterCapital(
                                                    credential
                                                )}
                                                {providerCredentialFilter ===
                                                credential ? (
                                                    <CheckPrimaryColorIcon />
                                                ) : null}
                                            </DropdownItem>
                                        )
                                    )}
                                </DropdownContent>
                            </Dropdown>
                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <FilterButton
                                        text={
                                            customDateFilter
                                                ? truncateString(
                                                      removeEnumUnderscore(
                                                          removeEnumUnderscore(
                                                              customDateFilter
                                                          )
                                                      )
                                                  )
                                                : "Date range"
                                        }
                                    />
                                </DropdownTrigger>
                                <DropdownContent
                                    align="start"
                                    width={200}
                                    maxHeight={216}
                                    className="overflow-y-auto"
                                >
                                    {customDateForSelect.map((range) => (
                                        <DropdownItem
                                            className={cn(
                                                "flex gap-x-8 items-center capitalize text-xs font-medium",
                                                {
                                                    "text-primary":
                                                        customDateFilter ===
                                                        range,
                                                }
                                            )}
                                            key={range}
                                            onClick={() =>
                                                handleCustomDateChange(range)
                                            }
                                        >
                                            {removeEnumUnderscore(range)}{" "}
                                            {customDateFilter === range ? (
                                                <CheckPrimaryColorIcon />
                                            ) : null}
                                        </DropdownItem>
                                    ))}
                                </DropdownContent>
                            </Dropdown>
                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <FilterButton text="Note status" />
                                </DropdownTrigger>
                                <DropdownContent
                                    width="auto"
                                    className="overflow-y-auto"
                                >
                                    {noteStatusForSelect.map((status) => (
                                        <DropdownItem
                                            className={cn(
                                                "flex gap-x-8 items-center capitalize text-xs font-medium"
                                            )}
                                            key={status}
                                            onClick={() =>
                                                handleNoteStatusChange(status)
                                            }
                                            isMulti
                                            itemValue={status}
                                            values={noteStatusFilter}
                                        >
                                            {makeStringFirstLetterCapital(
                                                removeEnumUnderscore(status)
                                            )}
                                        </DropdownItem>
                                    ))}
                                </DropdownContent>
                            </Dropdown>
                            <Popover>
                                <PopoverTrigger asChild>
                                    <FilterButton text="Date Of Service" />
                                </PopoverTrigger>
                                <PopoverContent
                                    align="end"
                                    className="p-16 gap-y-12"
                                >
                                    <DatePicker
                                        label="From"
                                        placeholderText="MM/DD/YYYY"
                                        selected={fromDateFilterToDate}
                                        onChange={handleFromDateFilterChange}
                                        className="hover:border-primary"
                                        maxDate={
                                            toDateFilterToDate ||
                                            new Date(Date.now())
                                        }
                                        isClearable
                                    />
                                    <DatePicker
                                        label="To"
                                        placeholderText="MM/DD/YYYY"
                                        selected={toDateFilterToDate}
                                        onChange={handleToDateFilterChange}
                                        className="hover:border-primary"
                                        minDate={
                                            fromDateFilterToDate || undefined
                                        }
                                        maxDate={new Date(Date.now())}
                                        isClearable
                                    />
                                </PopoverContent>
                            </Popover>
                            <Popover>
                                <PopoverTrigger asChild>
                                    <FilterButton text="Date Created" />
                                </PopoverTrigger>
                                <PopoverContent
                                    align="end"
                                    className="p-16 gap-y-12"
                                >
                                    <DatePicker
                                        label="From"
                                        placeholderText="MM/DD/YYYY"
                                        selected={fromDateCreatedFilterToDate}
                                        onChange={
                                            handleFromDateCreatedFilterChange
                                        }
                                        maxDate={
                                            toDateCreatedFilterToDate ||
                                            new Date(Date.now())
                                        }
                                        isClearable
                                    />
                                    <DatePicker
                                        label="To"
                                        placeholderText="MM/DD/YYYY"
                                        selected={toDateCreatedFilterToDate}
                                        onChange={
                                            handleToDateCreatedFilterChange
                                        }
                                        minDate={
                                            fromDateFilterToDate || undefined
                                        }
                                        maxDate={new Date(Date.now())}
                                        isClearable
                                    />
                                </PopoverContent>
                            </Popover>
                        </div>
                        <Button
                            onClick={handleExportClick}
                            disabled={
                                data?.data.rows.length === 0 ||
                                exportQuery.isFetching
                            }
                        >
                            Export
                        </Button>
                    </div>
                </div>
                <TableFiltersDisplay
                    appliedFilters={[
                        {
                            key: "note_status[]",
                            values: noteStatusFilter,
                        },
                    ]}
                    wrapperClass="mt-12"
                />
            </div>
            <div className="grid grid-cols-[repeat(4,minmax(209px,1fr))] overflow-x-auto overflow-y-hidden gap-x-12 mb-24 bg-white rounded-r8 px-12 py-16">
                <Card classNames="bg-card-bg flex flex-col gap-y-8 px-16 py-12 border border-[#0974461e]">
                    <div className="font-medium uppercase text-xs">
                        Notes Completed
                    </div>
                    <div className="font-bold text-xxl">
                        {getErrorAndLoadingStates() ||
                            data?.data.summary.total_notes_completed}
                    </div>
                </Card>
                <Card classNames="bg-card-bg flex flex-col gap-y-8 px-16 py-12 border border-[#0974461e]">
                    <div className="font-medium uppercase text-xs">
                        Notes pending review
                    </div>
                    <div className="font-bold text-xxl">
                        {getErrorAndLoadingStates() ||
                            data?.data.summary.total_notes_pending_review}
                    </div>
                </Card>
                <Card classNames="bg-card-bg flex flex-col gap-y-8 px-16 py-12 border border-[#0974461e]">
                    <div className="font-medium uppercase text-xs">
                        Notes in draft
                    </div>
                    <div className="font-bold text-xxl">
                        {getErrorAndLoadingStates() ||
                            data?.data.summary.total_notes_in_draft}
                    </div>
                </Card>
                <Card classNames="bg-card-bg flex flex-col gap-y-8 px-16 py-12 border border-[#0974461e]">
                    <div className="font-medium uppercase text-xs">
                        Notes pending changes
                    </div>
                    <div className="font-bold text-xxl">
                        {getErrorAndLoadingStates() ||
                            data?.data.summary.total_notes_pending_changes}
                    </div>
                </Card>
            </div>
            {isLoading && (
                <Skeleton
                    type="table"
                    containerTestId="provideractivity-loader"
                    count={6}
                />
            )}
            {error && (
                <ListState
                    isError
                    stateHelperText="Try reloading this page to solve this issue"
                    errorMsg={
                        error?.response?.data.message ||
                        `Cannot display provider session activity list at this time please try again later`
                    }
                />
            )}
            {data && isSuccess && data.data && data.data.rows.length === 0 && (
                <ListState
                    stateHelperText="Provider session activity list will appear here once providers are added"
                    isError={false}
                    emptyMessage="No providers session activity list added yet"
                />
            )}
            {data && isSuccess && data.data && data.data.rows.length > 0 && (
                <Table
                    columns={columns}
                    data={data.data.rows}
                    isHeaderFixed
                    expandedRows={expandedRows}
                    setExpandedRows={setExpandedRows}
                    renderRowSubComponent={renderRowSubComponent}
                />
            )}
        </div>
    );
}
