export type PaymentModals =
    | "unmatch_payment"
    | "update_charge"
    | "update_writeoff"
    | "charge_client"
    | "insurance_payment"
    | "coinsurance_payment"
    | "writeoff"
    | "refund"
    | "unmatched_payments"
    | "payment"
    | "move_payment"
    | "confirm_payment"
    | "refund-authorize"
    | "bulk_writeoff";

export enum BillStatus {
    OPEN = "open",
    CLOSED = "closed",
    PENDING_RESOLUTION = "pending_resolution",
    PENDING_PATIENT_RESOLUTION = "pending_patient_resolution",
    PENDING_INSURANCE_RESOLUTION = "pending_insurance_resolution",
}

export enum BillPaymentStatus {
    COMPLETED = "completed",
    FAILED = "failed",
    PENDING = "pending",
    REFUNDED = "refunded",
    VOIDED = "voided",
}

export enum BillPaymentType {
    CI = "ci",
    WBCI = "wbci",
    RCI = "rci",
    I = "i",
    RI = "ri",
    WBI = "wbi",
}

export enum BillPaymentMethod {
    CARD = "card",
    CHEQUE = "cheque",
    OTHER = "other",
}

interface IBillTypeLabels {
    [key: string]: string;
}

export const BillStatusLabels: IBillTypeLabels = {
    open: "Open",
    closed: "Closed",
    pending_resolution: "Pending Session Fee",
    pending_patient_resolution: "Pending PT INS Resolution",
    pending_insurance_resolution: "Pending Insurance Resolution",
};

export interface IBillingResponse {
    date_of_service: string;
    client: {
        client_id: string;
        first_name: string;
        last_name: string;
    };
    provider: {
        first_name: string;
        last_name: string;
    };
    insurance_provider: string;
    invoice_status: BillStatus;
    no_of_outstanding_bills: number;
    insurance_amount: number;
    insurance_amount_collected: number;
    coinsurance_amount: number;
    coinsurance_amount_collected: number;
    chart_number: string;
    self_pay: boolean;
    note_id: string;
    note_type?: string;
    invoice_id: string;
    cpt_code?: string;
    pos_code?: string;
    accounting_coinsurance: number;
    accounting_insurance: number;
    issue_category?: string;
    issue_notes?: string;
    issue_notes_preview?: string;
    patient_insurance_pending_settlement?: number;
}

export interface IPaymentBreakdown {
    payment_type: string;
    payment_date: string;
    amount: number;
    date_of_service: string;
    payment_method: string;
    payment_description: string;
    posted_by: {
        first_name: string;
        last_name: string;
    };
    date_posted: string;
    payment_notes: string;
    payment_status: string;
    writeoff_code: string;
    auth_code?: string;
    payment_id: string;
    transaction_id?: string;
    processor_payment_status?: string;
    payment_info: {
        refund_reason: string;
        cheque_number: string;
        authorization_number: string;
    };
    has_split_payments?: boolean;
    parent_payment_id?: string;
    remark_codes?: string[];
    eft_number?: string;
    remittance_id?: string;
    refundable_amount?: number;
    payment_channel?: string;
    invoice_id?: string;
}
export interface PaymentBreakdownItem {
    payment_breakdown: IPaymentBreakdown[];
}

export interface IGetPaymentResponse extends PaymentBreakdownItem {
    message: string;
    charged_amount: number;
    total_collected_amount: number;
    accounting: number;
    total_credit: number;
    total_refund: number;
    total_writeoff: number;
    coinsurance_amount: number;
    insurance_amount: number;
    invoice_id: string;
}

export interface IAddPayment {
    payment_type: string | undefined;
    payment_method: string;
    payment_date: string;
    payment_notes: string;
    writeoff_code: string;
    remark_code?: string;
    write_off_comments: string;
    auth_code: string;
    payment_info: {
        refund_reason: string;
        payor: string;
        cheque_number: string;
        authorization_number: string;
    };
    transaction_id?: string;
    refund_payment_externally?: boolean;
}

export interface IBillingSummaryFilters {
    from_date?: string | null;
    to_date?: string | null;
    view?: string;
    insurance_provider_id?: string;
    provider_name?: string;
    provider_names?: string[];
    search_strings?: string[];
    client_name?: string;
    type?: string;
    invoice_ids?: string[];
    custom_date?: string;
    sort_attribute?: string;
    sort_order?: string;
    aging_day_intervals?: string[];
    invoice_status?: string[];
}
export interface IBillingFilterTypes {
    search_string?: string;
    chart_number?: string;
    client_name?: string;
    client_id?: string;
    insurance_provider?: string;
    provider_name?: string;
    invoice_status?: string[];
    cpt_code?: string;
    pending_resolution_category?: string;
    from_date?: null | string;
    to_date?: null | string;
    page?: number;
    limit?: number;
    sort_attribute?: string;
    sort_order?: string;
    invoice_id?: string;
    payment_view?: boolean;
    has_remits?: boolean;
}

export interface IUpdateInvoice {
    invoice_ids: string[];
    status: string;
    issue_category?: string;
    issue_notes?: string;
}

export interface IRefreshPaymentStatus {
    payment_ids: string[];
}

export interface IAddPaymentToMatch {
    unmatched_payment_matches: {
        [invoiceId: string]: string[];
    };
}

export interface IGetPayment {
    invoice_id: string;
    payment_type?: string;
}

export interface IUnmatchedPaymentFilterTypes {
    payment_status: string;
    page: number;
    limit?: number;
    client_id?: string;
}

export interface BillingOverviewFilters {
    search_string?: string;
    custom_date?: string;
    from_date?: string;
    to_date?: string;
    page?: number;
    limit?: number;
    insurance_provider_id?: string;
}

export interface IAddPaymentResponse {
    message: string;
    payment_id: string;
}

export enum PaymentMethod {
    CHEQUE = "cheque",
    CARD = "card",
    OTHER = "other",
}

export enum PaymentType {
    CREDIT = "credit",
    WRITEOFF = "write_off",
    REFUND = "refund",
}

export enum RefundReason {
    VOID_PAYMENT = "void_payment",
    DUPLICATE_PAYMENT = "duplicate_payment",
    OVER_PAYMENT = "over_payment",
    BILLING_ERROR = "billing_error",
    OTHERS = "others",
}

export enum WriteOffCodes {
    FAP = "fap",
    WOBAD = "wobad",
    WOBAL = "wobal",
    WOBANK = "wobank",
    WOCOLL = "wocoll",
    WOCOUR = "wocour",
    WOHRDSHIP = "wohrdship",
    WOINS = "woins",
    WOMISC = "womisc",
    WONOSHOW = "wonoshow",
    WOREV = "worev",
    WOSINTK = "wosintk",
    WODED = "woded",
}

export enum RemarkCodes {
    PR100 = "pr100",
    Code1806 = "1806",
    E07 = "e07",
    OA18 = "oa18",
    PR27 = "pr27",
    PR200 = "pr200",
    PR204 = "pr204",
    PR227 = "pr227",
    PR45 = "pr45",
    PR242 = "pr242",
    PR119 = "pr119",
    PR97 = "pr97",
    CO16 = "co16",
    PR22 = "pr22",
    PR109 = "pr109",
    PR29 = "pr29",
    CO193 = "co193",
    CO252 = "co252",
    PI242 = "pi242",
    PR1 = "pr1",
    PR2 = "pr2",
    N655 = "n655",
    CO147 = "co147",
    PR3 = "pr3",
}

export const RemarkCodeDescription: { [key: string]: string } = {
    pr100: "PR100: PAYMENT SENT TO PATIENT",
    pr1: "PR1: DEDUCTIBLE",
    pr2: "PR2: Co-Insurance",
    "1806": "1806: ZELIS PAYMENT",
    e07: "E07: DATA-I-SIGHT PAYMENT",
    oa18: "0A18: EXACT DUPLICATE CLAIM/SERVICE",
    pr27: "PR27: EXPENSES INCURRED AFTER COVERAGE TERMINATED",
    pr200: "PR200: EXPENSES INCURRED DURING LAPSE IN COVERAGE",
    pr204: "PR204: THE SERVICE/EQUIPMENT/DRUG IS NOT COVERED UNDER THE PATIENT'S CURRENT BENEFIT PLAN",
    pr227: "PR227: INFORMATION REQUESTED FROM THE PATIENT WAS NOT PROVIDED OR WAS INSUFFICIENT/INCOMPLETE",
    pr45: "PR45: CHARGES EXCEED YOUR CONTRACTED/LEGISLATED FEE ARRANGEMENT",
    pr242: "PR242: SERVICES NOT PROVIDED BY NETWORK/PRIMARY CARE PROVIDERS",
    pr119: "PR119: BENEFIT MAXIMUM FOR THIS TIME PERIOD OCCURRENCE HAS BEEN REACHED",
    pr97: "PR97: THE BENEFIT FOR THIS SERVICE IS INCLUDED IN THE PAYMENT/ALLOWANCE FOR ANOTHER SERVICE/PROCEDURE THAT HAS ALREADY BEEN ADJUDICATED",
    co16: "CO16: CLAIM/SERVICE LACKS INFORMATION OR HAS SUBMISSION/BILLING ERROR(S)",
    pr22: "PR22: THIS CARE MAY BE COVERED BY ANOTHER PAYER PER COORDINATION OF BENEFITS",
    pr109: "PR109: CLAIM/SERVICE NOT COVERED BY THIS PAYER/CONTRACTOR. YOU MUST SEND THE CLAIM/SERVICE TO THE CORRECT PAYER/CONTRACTOR",
    pr29: "PR29: THE TIME LIMIT FOR FILING HAS EXPIRED",
    co193: "CO193: ORIGINAL PAYMENT DECISION IS BEING MAINTAINED. UPON REVIEW, IT WAS DETERMINED THAT THIS CLAIM WAS PROCESSED PROPERLY",
    co252: "CO252: AN ATTACHMENT/OTHER DOCUMENTATION IS REQUIRED TO ADJUDICATE THIS CLAIM/SERVICE. AT LEAST ONE REMARK CODE MUST BE PROVIDED (MAY BE COMPRISED OF EITHER THE NCPDP REJECT REASON CODE, OR REMITTANCE ADVICE REMARK CODE THAT IS NOT AN ALERT)",
    pi242: "PI242: SERVICES NOT PROVIDED BY NETWORK/PRIMARY CARE PROVIDERS",
    n655: "N655: PAYMENT BASED ON PROVIDER'S GEOGRAPHIC REGION",
    co147: "CO-147: CONTRACTUAL OBLIGATIONS. THE PATIENT MAY NOT BE BILLED FOR THIS AMOUNT. PROVIDER CONTRACTED/NEGOTIATED RATE EXPIRED OR NOT ON FILE",
    pr3: "PR-3: CO-PAYMENT AMOUNT",
};

export enum WriteOffCodesDecription {
    fap = "FAP",
    wobad = "WOBAD: WRITE OFF - BAD DEBT",
    wobal = "WOBAL: WRITE OFF - BALANCE (INSADJ)",
    wobank = "WOBANK: WRITE OFF - BANKRUPTCY",
    wocoll = "WOCOLL: WRITE OFF - COLLECTIONS",
    wocour = "WOCOUR: WRITE OFF - COURTESY",
    wohrdship = "WOHRDSHIP: W/O - FINANCIAL HARDSHIP",
    woins = "WOINS: WRITE OFF - INSURANCE ",
    womisc = "WOMISC: WRITE OFF - MISC ",
    wonoshow = "WONOSHOW: WRITE OFF - THERAPIST WAIVED NO ",
    worev = "WOREV: WRITE OFF - REBILLED ",
    wosintk = "WOSINTK: WRITE OFF - SUPERVISOR INTAKE ",
    woded = "WODED: WRITE OFF - DEDUCTIBLE",
}

export enum WriteOffSource {
    INSURANCE = "insurance",
    CO_INSURANCE = "co_insurance",
}

export interface IUnmatchPaymentResponse {
    successful_payments: string[];
    failed_payments: string[];
}

export interface IBillingDashboardInvoiceBreakdown {
    date_of_service: string;
    payment_date: string;
    collected: number;
    amount_outstanding: number;
    charge_amount: number;
    insurance_amount: number;
    coinsurance_amount: number;
    note_id: string;
    pending_settlement: number;
    invoice_status: BillStatus;
    provider: {
        user_id: string;
        first_name: string;
        last_name: string;
        provider_id: string;
        npi: string;
    };
    client: {
        user_id: string;
        client_id: string;
        first_name: string;
        last_name: string;
        date_of_birth: string;
    };
    claim_external_reference_id: string;
    cpt_code: string;
    diagnosis_codes: string[];
}

export interface IAddBulkInvoicePayment {
    invoices: {
        invoice_id: string;
        payment_amount: number;
        payment_info?: Record<string, string>;
    }[];
    payment_type: BillPaymentType;
    payment_method?: BillPaymentMethod;
    payment_date: string;
    payment_notes?: string;
    writeoff_code?: WriteOffCodes;
    remark_codes?: RemarkCodes;
    writeoff_comments?: string;
    auth_code?: string;
    payment_description: string | null;
    close_invoice?: boolean;
    move_remaining?: boolean;
    // used for invoices payment info and not sent as a key to the backend
    cheque_number?: string;
}

export interface IAddBulkInvoicePaymentResponse {
    added_payments: { invoice_id: string; reason: string }[];
    unadded_payments: { invoice_id: string; reason: string }[];
}
