import axios from "../../../utils/axios";
import { ISubmitCalculateClientFee } from "../types/tools.types";

// Endpoint to calculate client fees
export async function calculateClientFees(data: ISubmitCalculateClientFee) {
    const token = import.meta.env.VITE_CALCULATE_FEE_TOKEN;
    const response = await axios.post(`/tools/calculate-fees`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
