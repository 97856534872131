import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Select } from "@jhool-io/fe-components";
import { useFetchSessionNote } from "../../../../hooks/queries/note";
import { useEditDraftNote } from "../../../../hooks/mutations/note";
import useToast from "../../../../hooks/useToast";
import { useFetchDiagnosisCodes } from "../../../../hooks/queries";

interface EditNoteDiagnosisCodesFormProps {
    onFormSubmit(): void;
}

interface EditDiagnosisCodesPayload {
    diagnosis_codes: string[];
}

export default function EditNoteDiagnosisCodesForm({
    onFormSubmit,
}: EditNoteDiagnosisCodesFormProps) {
    const params = useParams();
    const noteId = params.noteId as string;
    const clientId = params.clientId as string;

    const { data } = useFetchSessionNote(
        clientId,
        noteId,
        Boolean(clientId) && Boolean(noteId)
    );

    const { data: diagnosisCodes, isLoading, error } = useFetchDiagnosisCodes();

    const {
        handleSubmit,
        control,
        watch,
        formState: { errors },
    } = useForm<EditDiagnosisCodesPayload>({
        resolver: yupResolver(
            yup.object({
                diagnosis_codes: yup
                    .array()
                    .max(3, "You can only select 3 diagnosis")
                    .min(1, "Diagnosis is required")
                    .required("Diagnosis is required"),
            })
        ),
        mode: "onChange",
        defaultValues: data?.data.note_content?.diagnosis_codes
            ? {
                  diagnosis_codes: data?.data.note_content?.diagnosis_codes,
              }
            : undefined,
    });

    const editNote = useEditDraftNote();

    const { toast } = useToast();

    const queryClient = useQueryClient();

    const diagnosisCodesFromForm = watch("diagnosis_codes");

    const DiagnosisCodesForSelect = diagnosisCodes?.data.map((diagnosis) => ({
        label: `${diagnosis.code} ${diagnosis.description}`,
        value: diagnosis.code,
    }));

    const onSubmit = (payload: EditDiagnosisCodesPayload) => {
        const dataToSend = {
            noteId: data?.data.note_id || "",
            data: {
                note_content: {
                    ...data?.data.note_content,
                    diagnosis_codes: payload.diagnosis_codes,
                },
            },
        };

        editNote.mutate(dataToSend, {
            onSuccess: async (res) => {
                await queryClient.invalidateQueries({
                    queryKey: [clientId, `session-note`, noteId],
                });

                toast({
                    mode: "success",
                    message:
                        res.message ||
                        "Note diagnosis codes edited successfully",
                });

                onFormSubmit();
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not edit disgnosis codes at this time",
                });
            },
        });
    };

    return (
        <form id="edit-diagnosis-codes" onSubmit={handleSubmit(onSubmit)}>
            <Controller
                name="diagnosis_codes"
                control={control}
                render={({ field }) => (
                    <Select
                        label="Diagnosis codes"
                        placeholder={
                            (!data && isLoading && "Loading...") ||
                            (error &&
                                !isLoading &&
                                "Error loading diagnosis") ||
                            (data && !isLoading && "Diagnosis codes")
                        }
                        isSearchable
                        onChange={(val) => {
                            field.onChange(
                                (val as { label: string; value: string }[]).map(
                                    (code) => code.value
                                )
                            );
                        }}
                        options={DiagnosisCodesForSelect}
                        isDisabled={isLoading || Boolean(error)}
                        hasError={!!errors.diagnosis_codes}
                        value={
                            DiagnosisCodesForSelect?.filter((diagnosis) =>
                                field.value?.includes(diagnosis.value)
                            ) || null
                        }
                        errorText={
                            errors.diagnosis_codes?.type === "typeError"
                                ? "Diagnosis codes are required"
                                : errors.diagnosis_codes?.message
                        }
                        isMulti
                        multiHasValues={
                            diagnosisCodesFromForm &&
                            diagnosisCodesFromForm.length > 0
                        }
                        closeMenuOnSelect
                        isLongListInDialog
                    />
                )}
            />
        </form>
    );
}
