import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { Tabs } from "@jhool-io/fe-components";
import BillsClientInfo from "../../../../components/BillsClientInfo/BillsClientInfo";
import { cn } from "../../../../utils/helpers";
import NoteItemsWrapper from "../NoteItemsWrapper/NoteItemsWrapper";
import SingleNoteActions from "../SingleNoteActions/SingleNoteActions";

export default function SingleNoteView() {
    const [searchParams, setSearchParams] = useSearchParams();

    const tabInUrl = searchParams.get("tab") || "note";

    const handleSetTabInUrl = (tab: string) => {
        searchParams.set("tab", tab);
        setSearchParams(searchParams);
    };

    const tabItems = [
        {
            label: "Session Note",
            key: "note",
            children: (
                <div className="mt-24">
                    <NoteItemsWrapper />
                </div>
            ),
            onClick: () => handleSetTabInUrl("note"),
        },
        {
            label: "Claims",
            key: "claims",
            children: null,
            disabled: true,
        },
        {
            label: "Payments History",
            key: "payments",
            children: null,
            disabled: true,
        },
        {
            key: "remit",
            label: "Remits",
            children: null,
            disabled: true,
        },
    ];

    return (
        <>
            <div>
                <BillsClientInfo topRightAction={<SingleNoteActions />} />
            </div>
            <div className="mt-32">
                <Tabs
                    items={tabItems}
                    className={cn("!w-[100%] !mb-0 !items-end")}
                    defaultActiveKey={tabInUrl}
                    size="small"
                />
            </div>
        </>
    );
}
