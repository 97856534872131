import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useParams, useSearchParams } from "react-router-dom";
import { ContentState } from "draft-js";
import { Input, Select } from "@jhool-io/fe-components";

import styles from "./AddAddendumForm.module.scss";
import {
    FamilyIntakeNotePayload,
    INewAddendum,
} from "../../../../utils/types/notes";
import { useFetchSessionNote } from "../../../../hooks/queries/note";
import { useAddNewAddendum } from "../../../../hooks/mutations/note";
import useToast from "../../../../hooks/useToast";
import {
    CANCELLATION_NOTE_LABELS,
    FAMILY_INTAKE_FAMILY_NOTE_DETAILS,
    FAMILY_INTAKE_NOTE_DETAILS,
    INDIVIDUAL_INTAKE_NOTE_DETAILS,
    RECORD_OF_DISCLOSURE_NOTE_LABELS,
    SAFETY_PLAN_NOTE_LABELS,
    SOAP_PLAN_NOTE_LABELS,
    SUPERVISION_NOTE_LABELS,
    TERMINATION_NOTE_LABELS,
    TREATMENT_REVIEW_NOTE_LABELS,
} from "../../../../utils/constants";
import { removeEnumUnderscore } from "../../../../utils/helpers";
import { NoteTypes } from "../../types/notes.types";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import NoteDetails from "../../../../components/Notes/NotesDetails/NoteDetails";
import { AddTextEditor } from "../../../../components/TextEditor/AddTextEditor/AddTextEditor";

interface AddAddendumProps {
    // Function to call when form submit button is clicked
    onFormSubmit(): void;
}

type Option = {
    label: string;
    value: string;
};

export default function AddAddendum({ onFormSubmit }: AddAddendumProps) {
    const [isAdditionalInfoProvided, setIsAdditionalInfoProvided] =
        React.useState(true);

    const [getCurrentContent, setGetCurrentContent] =
        React.useState<ContentState>();

    const [searchParams] = useSearchParams();

    const fieldId = searchParams.get("field_id");

    const params = useParams();
    const noteId = params.noteId as string;
    const clientId = params.clientId as string;

    const [additonalInformation, setAdditionalInformation] = React.useState("");

    const {
        data: sessionNote,
        error,
        isLoading,
    } = useFetchSessionNote(
        clientId,
        noteId,
        Boolean(clientId) && Boolean(noteId)
    );

    const {
        handleSubmit,
        register,
        formState: { errors },
        control,
    } = useForm<INewAddendum>({
        resolver: yupResolver(
            yup.object({
                title: yup.string().required("Title is required"),
                signature: yup.string().required("Signature is required"),
                field_id: yup.string().when([], {
                    is: () => fieldId,
                    then: yup.string().required("Content title is required"),
                    otherwise: yup.string(),
                }),
            })
        ),
        mode: "onChange",
    });

    const { mutate } = useAddNewAddendum(noteId);

    // Query client
    const queryClient = useQueryClient();

    const { toast } = useToast();

    const extractArrayOfFieldIdsAndFieldTitles = (
        noteLabelObj: {
            [key: string]: string;
        },
        fieldsToIgnore = ["duration"]
    ) => {
        return Object.keys(noteLabelObj)
            .filter((key) => !fieldsToIgnore.includes(key))
            .map((key) => {
                return {
                    label: noteLabelObj[key as keyof typeof noteLabelObj],
                    value: key,
                };
            });
    };

    const extractFamilyIntakeFieldsAndTitles = (
        fieldsToIgnore = ["duration", "isClientPresent"]
    ) => {
        let finalResult = [
            ...Object.keys(FAMILY_INTAKE_NOTE_DETAILS)
                .filter((key) => !fieldsToIgnore.includes(key))
                .map((key) => {
                    return {
                        label: FAMILY_INTAKE_NOTE_DETAILS[
                            key as keyof typeof FAMILY_INTAKE_NOTE_DETAILS
                        ],
                        value: key,
                    };
                }),
        ];

        const familyItems: { name: string; family_id: string }[] =
            sessionNote?.data.note_content.family?.map(
                (family: FamilyIntakeNotePayload) => ({
                    family_id: family.family_id,
                    name: family.name,
                })
            ) || [];

        familyItems.forEach((key) => {
            Object.keys(FAMILY_INTAKE_FAMILY_NOTE_DETAILS).forEach(
                (detail, i) => {
                    finalResult = [
                        ...finalResult,
                        {
                            label: `${removeEnumUnderscore(detail)}-${
                                key?.name || `Client -${i}`
                            }`,

                            value: `${detail}-${key.family_id}`,
                        },
                    ];
                }
            );
        });

        return finalResult;
    };

    const getFieldIdsForSelect = (noteType: NoteTypes) => {
        switch (noteType) {
            case NoteTypes.CANCELLATION_NOTE:
                return extractArrayOfFieldIdsAndFieldTitles(
                    CANCELLATION_NOTE_LABELS
                );

            case NoteTypes.SUPERVISION_NOTE:
                return extractArrayOfFieldIdsAndFieldTitles(
                    SUPERVISION_NOTE_LABELS
                );

            case NoteTypes.TERMINATION_NOTE:
                return extractArrayOfFieldIdsAndFieldTitles(
                    TERMINATION_NOTE_LABELS
                );

            case NoteTypes.SAFETY_PLAN:
                return extractArrayOfFieldIdsAndFieldTitles(
                    SAFETY_PLAN_NOTE_LABELS
                );

            case NoteTypes.RECORD_OF_DISCLOSURE:
                return extractArrayOfFieldIdsAndFieldTitles(
                    RECORD_OF_DISCLOSURE_NOTE_LABELS
                );

            case NoteTypes.FAMILY_TREATMENT_REVIEW:
                return extractArrayOfFieldIdsAndFieldTitles(
                    TREATMENT_REVIEW_NOTE_LABELS
                );

            case NoteTypes.INDIVIDUAL_TREATMENT_REVIEW:
                return extractArrayOfFieldIdsAndFieldTitles(
                    TREATMENT_REVIEW_NOTE_LABELS,
                    [TREATMENT_REVIEW_NOTE_LABELS.isClientPresent]
                );

            case NoteTypes.INDIVIDUAL_SOAP_NOTE:
            case NoteTypes.FAMILY_SOAP_NOTE:
                return extractArrayOfFieldIdsAndFieldTitles(
                    SOAP_PLAN_NOTE_LABELS
                );

            case NoteTypes.INDIVIDUAL_INTAKE_NOTE:
                return extractArrayOfFieldIdsAndFieldTitles(
                    INDIVIDUAL_INTAKE_NOTE_DETAILS,
                    [
                        INDIVIDUAL_INTAKE_NOTE_DETAILS.contact_information_for_both_parents,
                        INDIVIDUAL_INTAKE_NOTE_DETAILS.name_address_of_personal_physician,
                        INDIVIDUAL_INTAKE_NOTE_DETAILS.date_of_last_exam,
                    ]
                );

            case NoteTypes.MINOR_INTAKE_NOTE:
                return extractArrayOfFieldIdsAndFieldTitles(
                    INDIVIDUAL_INTAKE_NOTE_DETAILS
                );

            case NoteTypes.FAMILY_INTAKE_NOTE:
                return extractFamilyIntakeFieldsAndTitles();

            default:
                return [];
        }
    };

    const onSubmit = (data: INewAddendum) => {
        const currentTime = Date.now();
        if (!getCurrentContent?.hasText()) {
            // Additional info is empty
            setIsAdditionalInfoProvided(false);
            return;
        }
        setIsAdditionalInfoProvided(true);
        const dataToSend = {
            ...data,
            content: additonalInformation,
            field_id: data.field_id,
            field_title: getFieldIdsForSelect(
                sessionNote?.data?.type as NoteTypes
            ).find((item) => item.value === data.field_id)?.label as string,
            signature_datetime: new Date(currentTime).toISOString(),
        };

        mutate(dataToSend, {
            onSuccess: (res) => {
                toast({
                    mode: "success",
                    message: res.message || "Addendum added successfully",
                });
                queryClient.invalidateQueries({
                    queryKey: [clientId, "session-note", noteId],
                });
                queryClient.invalidateQueries({
                    queryKey: [noteId, "addendum"],
                });
                queryClient.invalidateQueries({
                    queryKey: [noteId, `activity`],
                });

                onFormSubmit();
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not add addendum at this time",
                });
            },
        });
    };

    if (isLoading) {
        return (
            <div className={styles.skeletons}>
                <Skeleton count={8} />
            </div>
        );
    }

    if (error) {
        return (
            <ListState
                isError
                errorMsg="Cannot display form at this time"
                stateHelperText="Try reloading this page to solve this issue"
            />
        );
    }

    return (
        <>
            <NoteDetails />
            <form
                id="add-addendum"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
                className="mt-32"
            >
                <div className="fg">
                    <Controller
                        name="field_id"
                        control={control}
                        defaultValue={
                            getFieldIdsForSelect(sessionNote.data.type).find(
                                (item) => item.value === fieldId
                            )?.value
                        }
                        render={({ field }) => (
                            <Select
                                wrapperClass={styles.fieldidselect}
                                label="Content title"
                                placeholder="Content title"
                                options={getFieldIdsForSelect(
                                    sessionNote.data.type
                                )}
                                onChange={(val) =>
                                    field.onChange((val as Option).value)
                                }
                                defaultValue={getFieldIdsForSelect(
                                    sessionNote.data.type
                                ).find((item) => item.value === fieldId)}
                                hasError={!!errors.field_id}
                                errorText={errors.field_id?.message}
                            />
                        )}
                    />
                </div>
                <div className="fg">
                    <Input
                        {...register("title")}
                        label="Title"
                        placeholder="Title"
                        hasError={!!errors.title}
                        errorText={errors.title?.message}
                    />
                </div>
                <div className="fg">
                    <AddTextEditor
                        title="Add additional Information"
                        onEditorTextChange={setAdditionalInformation}
                        setIsRequiredFieldProvided={setGetCurrentContent}
                        isRequiredFieldProvided={
                            isAdditionalInfoProvided ||
                            getCurrentContent?.hasText()
                        }
                        titleClass="font-medium text-xs text-black leading-none"
                    />
                    {!isAdditionalInfoProvided &&
                        !getCurrentContent?.hasText() && (
                            <div className={styles.error}>
                                Additional Information is required
                            </div>
                        )}
                </div>
                <div>
                    <Input
                        {...register("signature")}
                        label="Provider's Initials"
                        placeholder="Provider's Initials"
                        hasError={!!errors.signature}
                        errorText={errors.signature?.message}
                    />
                </div>
            </form>
        </>
    );
}
