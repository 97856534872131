import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
    IGetPayment,
    IGetPaymentResponse,
    IUnmatchedPaymentFilterTypes,
    IUnmatchedPaymentResponse,
    IMatchedPaymentFilterTypes,
    IGetMatchedPaymentResponse,
    IAllUnmatchedPaymentFilterTypes,
    IUnMatchedInvoiceResponse,
    IBillingAdminDashboardResponse,
    IBillingAdminDashboardFilters,
    IInvoiceNotesFilters,
    IInvoiceNotesResponse,
} from "../../utils/types/billing";
import { handleRemoveEmptyParamFromQueryParams } from "../../utils/helpers";
import {
    IApiResponse,
    IPaginatedApiResponse,
} from "../../utils/types/api-response";
import {
    downloadStatementOfAccount,
    fetchAllUnMatchedPayments,
    fetchBillingAdminDashboardList,
    fetchClientUnmatchedPayments,
    fetchMatchedPayments,
    getInvoiceNotes,
    getInvoicePayment,
} from "../../services/billing";

//  get invoice payment
export function useGetInvoicePayment(
    invoiceId: string,
    payload: IGetPayment,
    enabled?: boolean
) {
    return useQuery<IGetPaymentResponse, AxiosError<IGetPaymentResponse>>({
        queryKey: [
            "get-payment",
            handleRemoveEmptyParamFromQueryParams(payload),
        ],
        queryFn: () => getInvoicePayment(invoiceId, payload),
        enabled,
    });
}

//  get matched payment
export function useFetchMatchedPayment(filters: IMatchedPaymentFilterTypes) {
    return useQuery<
        IPaginatedApiResponse<IGetMatchedPaymentResponse[]>,
        AxiosError<IApiResponse<IGetMatchedPaymentResponse[]>>
    >({
        queryKey: ["matched", handleRemoveEmptyParamFromQueryParams(filters)],
        queryFn: () => fetchMatchedPayments(filters),
    });
}

//  get unmatched payment
export function useFetchUnMatchedPayment(
    filters: IAllUnmatchedPaymentFilterTypes
) {
    return useQuery<
        IPaginatedApiResponse<IUnMatchedInvoiceResponse[]>,
        AxiosError<IApiResponse<IUnMatchedInvoiceResponse[]>>
    >({
        queryKey: ["unmatched", handleRemoveEmptyParamFromQueryParams(filters)],
        queryFn: () => fetchAllUnMatchedPayments(filters),
    });
}

// get unmatched payment
export function useFetchClientUnmatchedPayment(
    filters: IUnmatchedPaymentFilterTypes
) {
    return useQuery<
        IPaginatedApiResponse<IUnmatchedPaymentResponse[]>,
        AxiosError<IApiResponse<IUnmatchedPaymentResponse[]>>
    >({
        queryKey: [
            "client-unmatched",
            handleRemoveEmptyParamFromQueryParams(filters),
        ],
        queryFn: () => fetchClientUnmatchedPayments(filters),
    });
}

export function useFetchBillingAdminDashboardList(
    filters: IBillingAdminDashboardFilters,
    enabled: boolean
) {
    return useQuery<
        IApiResponse<IBillingAdminDashboardResponse>,
        AxiosError<IApiResponse<IBillingAdminDashboardResponse[]>>
    >({
        queryKey: [
            "billing-admin-dashboard",
            handleRemoveEmptyParamFromQueryParams(filters),
        ],
        queryFn: () => fetchBillingAdminDashboardList(filters),
        enabled,
    });
}

export function useDownloadStatementOfAccount(
    pdfType: string,
    jobId: string,
    isEnabled: boolean
) {
    return useQuery<void, AxiosError<string>>({
        queryKey: ["download-statement-of-account", jobId],
        queryFn: () => downloadStatementOfAccount(pdfType, jobId),
        enabled: isEnabled,
    });
}

// get invoice notes
export function useFetchInvoiceNotes(
    invoice_id: string,
    filters: IInvoiceNotesFilters,
    isEnabled: boolean
) {
    return useQuery<
        IPaginatedApiResponse<IInvoiceNotesResponse[]>,
        AxiosError<IPaginatedApiResponse<IInvoiceNotesResponse[]>>
    >({
        queryKey: [
            "invoice-notes",
            invoice_id,
            handleRemoveEmptyParamFromQueryParams(filters),
        ],
        queryFn: () => getInvoiceNotes(invoice_id, filters),
        cacheTime: 0,
        enabled: isEnabled,
    });
}
