import * as React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { parseISO } from "date-fns";
import {
    DatePicker,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Input,
    RadioInput,
    TextArea,
} from "@jhool-io/fe-components";
import {
    IGetClientNotes,
    INewNoteAppoinmentDetails,
} from "../../../../utils/types/notes";
import {
    formatDate,
    getTimeDuration,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../../utils/helpers";
import NoteFormInfoBox from "../../NoteFormInfoBox/NoteFormInfoBox";
import { useFetchClientNotesList } from "../../../../hooks/queries/note";
import LoadPreviousSessionNotificationModal from "../../LoadPreviousSessionNotificatiModal/LoadPreviousSessionNotificationModal";
import { SAFETY_PLAN_NOTE_LABELS } from "../../../../utils/constants";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";

export const formatDateString = (value: string) => {
    const date = new Date(value);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    return value ? `${month}/${day}/${year}` : undefined;
};

export interface ICreateSafetyPlanPayload {
    duration: string;
    warning_signs: string;
    internal_coping_strategies: string;
    people_and_social_settings_that_provide_distraction: string;
    people_whom_i_can_ask_for_help: string;
    professionals_or_agencies_i_can_contact_during_a_crisis: string;
    making_the_environment_safe: string;
    the_one_thing_that_is_important_to_me_and_worth_living_for_is: string;
    how_likely_is_the_client_to_use_this_safety_plan: string;
    how_likely_is_the_client_to_adhere_to_this_safety_plan: string;
    detail_hloc_options_and_next_steps: string;
    where_will_the_client_keep_the_safety_plan_and_how_will_it_be_located_during_a_crisis: string;
    is_the_format_appropriate_for_client_capacity_and_circumstances: string;
    when_should_follow_up_be_done_for_this_client: string;
    frequency_of_sessions: string;
    other_frequency_of_sessions: string;
    date_of_next_session: string;
    signature: string;
}

interface SafetyPlanProps {
    appointmentDetails?: INewNoteAppoinmentDetails | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formValues?: { [key: string]: any };
    currentAction?: string | null;
    onSubmit?: (data: ICreateSafetyPlanPayload) => void;
}

export default function SafetyPlan({
    appointmentDetails,
    formValues,
    currentAction,
    onSubmit,
}: SafetyPlanProps) {
    // Local component state
    const [localDateOfNextSession, setLocalDateOfNextSession] =
        React.useState<Date | null>(
            formValues?.date_of_next_session
                ? parseISO(formValues?.date_of_next_session)
                : null
        );
    const [localDate, setLocalDate] = React.useState<Date | null>(
        formValues?.when_should_follow_up_be_done_for_this_client
            ? parseISO(
                  formValues?.when_should_follow_up_be_done_for_this_client
              )
            : null
    );
    const [showPrompt, setShowPrompt] = React.useState(false);
    const [selectedNote, setSelectedNote] = React.useState(
        {} as IGetClientNotes
    );
    const clientNoteParams = {
        type: appointmentDetails?.note_type as string,
        load_previous_notes: true,
        provider_id: appointmentDetails?.provider_id as string,
    };

    const { data: notes } = useFetchClientNotesList(
        appointmentDetails?.client_id as string,
        clientNoteParams
    );

    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm<ICreateSafetyPlanPayload>({
        resolver: yupResolver(
            yup.object({
                duration: yup.string(),
                warning_signs: yup.string(),
                internal_coping_strategies: yup.string(),
                people_and_social_settings_that_provideprofessionals_or_agencies_i_can_contact_during_a_crisis:
                    yup.string(),
                professionals_or_agencies_i_can_contact_during_a_crisis:
                    yup.string(),
                making_the_environment_safe: yup.string(),
                the_one_thing_that_is_important_to_me_and_worth_living_for_is:
                    yup.string(),
                how_likely_is_the_client_to_use_this_safety_plan: yup
                    .string()
                    .nullable(),
                how_likely_is_the_client_to_adhere_to_this_safety_plan: yup
                    .string()
                    .nullable(),
                detail_hloc_options_and_next_steps: yup.string(),
                where_will_the_client_keep_the_safety_plan_and_how_will_it_be_located_during_a_crisis:
                    yup.string(),
                is_the_format_appropriate_for_client_capacity_and_circumstances:
                    yup.string().nullable(),
                when_should_follow_up_be_done_for_this_client: yup
                    .date()
                    .nullable(),
                frequency_of_sessions: yup.string().nullable(),
                other_frequency_of_sessions: yup
                    .string()
                    .when(
                        "frequency_of_sessions",
                        (frequency_of_sessions, field) =>
                            frequency_of_sessions === "Other" &&
                            currentAction === "with_signature"
                                ? field.required(
                                      "Other frequency of sessions is required"
                                  )
                                : field
                    ),
                date_of_next_session: yup.date().nullable(),
                signature: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup.string().required("Signature is required"),
                    otherwise: yup.string(),
                }),
            })
        ),
        mode: "onChange",
        defaultValues: formValues || undefined,
    });

    // Function to handle item click and set the selected note content
    const handleLoadPreviousSession = (note: IGetClientNotes) => {
        setValue(
            "detail_hloc_options_and_next_steps",
            note.note_content.detail_hloc_options_and_next_steps
        );
        setValue(
            "duration",
            appointmentDetails
                ? getTimeDuration(
                      formatDate(
                          appointmentDetails?.session_start_time || "",
                          false,
                          "HH:mm"
                      ),
                      formatDate(
                          appointmentDetails?.session_end_time || "",
                          false,
                          "HH:mm"
                      )
                  )
                : ""
        );
        setValue(
            "how_likely_is_the_client_to_adhere_to_this_safety_plan",
            note.note_content
                .how_likely_is_the_client_to_adhere_to_this_safety_plan
        );
        setValue(
            "how_likely_is_the_client_to_use_this_safety_plan",
            note.note_content.how_likely_is_the_client_to_use_this_safety_plan
        );
        setValue(
            "internal_coping_strategies",
            note.note_content.internal_coping_strategies
        );
        setValue(
            "is_the_format_appropriate_for_client_capacity_and_circumstances",
            note.note_content
                .is_the_format_appropriate_for_client_capacity_and_circumstances
        );
        setValue(
            "making_the_environment_safe",
            note.note_content.making_the_environment_safe
        );
        setValue(
            "people_and_social_settings_that_provide_distraction",
            note.note_content
                .people_and_social_settings_that_provide_distraction
        );
        setValue(
            "people_whom_i_can_ask_for_help",
            note.note_content.people_whom_i_can_ask_for_help
        );
        setValue(
            "professionals_or_agencies_i_can_contact_during_a_crisis",
            note.note_content
                .professionals_or_agencies_i_can_contact_during_a_crisis
        );
        setValue(
            "the_one_thing_that_is_important_to_me_and_worth_living_for_is",
            note.note_content
                .the_one_thing_that_is_important_to_me_and_worth_living_for_is
        );
        setValue("warning_signs", note.note_content.warning_signs);
        setValue(
            "when_should_follow_up_be_done_for_this_client",
            note.note_content.when_should_follow_up_be_done_for_this_client
        );
        setValue(
            "where_will_the_client_keep_the_safety_plan_and_how_will_it_be_located_during_a_crisis",
            note.note_content
                .where_will_the_client_keep_the_safety_plan_and_how_will_it_be_located_during_a_crisis
        );
        setValue(
            "frequency_of_sessions",
            note.note_content.frequency_of_sessions
        );
        setValue(
            "other_frequency_of_sessions",
            note.note_content.other_frequency_of_sessions
        );
        setValue(
            "date_of_next_session",
            note.note_content.date_of_next_session
        );
        setShowPrompt(false);
    };

    return (
        <>
            <LoadPreviousSessionNotificationModal
                showPrompt={showPrompt}
                setShowPrompt={setShowPrompt}
                handleLoadPreviousSession={() =>
                    handleLoadPreviousSession(selectedNote)
                }
            />
            {notes && notes.data.length > 0 && (
                <div className="flex justify-end mb-24">
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <FilterButton text="Load previous session note" />
                        </DropdownTrigger>
                        <DropdownContent width="auto" align="end">
                            {notes &&
                                notes?.data?.map((note) => (
                                    <DropdownItem
                                        key={note.note_id}
                                        onClick={() => {
                                            setShowPrompt(true);
                                            setSelectedNote(note);
                                        }}
                                    >
                                        <div>
                                            {makeStringFirstLetterCapital(
                                                removeEnumUnderscore(
                                                    note.note_type
                                                )
                                            )}{" "}
                                            - Note content Details -{" "}
                                            {formatDate(note.date_of_service)}
                                        </div>
                                    </DropdownItem>
                                ))}
                        </DropdownContent>
                    </Dropdown>
                </div>
            )}
            <form
                className="pb-[60px] md:pb-[150px]"
                aria-label="safety plan form"
                onSubmit={onSubmit && handleSubmit(onSubmit)}
                id="create-note-form"
            >
                <div className="mb-24">
                    <NoteFormInfoBox />
                </div>

                <div className="fg">
                    <Input
                        {...register("duration")}
                        label={SAFETY_PLAN_NOTE_LABELS.duration}
                        placeholder={SAFETY_PLAN_NOTE_LABELS.duration}
                        disabled
                        defaultValue={
                            appointmentDetails
                                ? getTimeDuration(
                                      formatDate(
                                          appointmentDetails?.session_start_time ||
                                              "",
                                          false,
                                          "HH:mm"
                                      ),
                                      formatDate(
                                          appointmentDetails?.session_end_time ||
                                              "",
                                          false,
                                          "HH:mm"
                                      )
                                  )
                                : undefined
                        }
                    />
                </div>

                <p className="note-label">
                    {`1. ${SAFETY_PLAN_NOTE_LABELS.warning_signs}`}
                </p>
                <div className="fg">
                    <TextArea
                        {...register("warning_signs")}
                        defaultValue={formValues?.warning_signs}
                        onBlur={onSubmit && handleSubmit(onSubmit)}
                    />
                </div>
                <p className="note-label">
                    {`2. ${SAFETY_PLAN_NOTE_LABELS.internal_coping_strategies}`}
                </p>
                <div className="fg">
                    <TextArea
                        {...register("internal_coping_strategies")}
                        defaultValue={formValues?.internal_coping_strategies}
                        onBlur={onSubmit && handleSubmit(onSubmit)}
                    />
                </div>
                <p className="note-label">
                    {`3. ${SAFETY_PLAN_NOTE_LABELS.people_and_social_settings_that_provide_distraction}`}
                </p>
                <div className="fg">
                    <TextArea
                        {...register(
                            "people_and_social_settings_that_provide_distraction"
                        )}
                        defaultValue={
                            formValues?.people_and_social_settings_that_provide_distraction
                        }
                        onBlur={onSubmit && handleSubmit(onSubmit)}
                    />
                </div>
                <p className="note-label">
                    {`4. ${SAFETY_PLAN_NOTE_LABELS.people_whom_i_can_ask_for_help}`}
                </p>
                <div className="fg">
                    <TextArea
                        {...register("people_whom_i_can_ask_for_help")}
                        defaultValue={
                            formValues?.people_whom_i_can_ask_for_help
                        }
                        onBlur={onSubmit && handleSubmit(onSubmit)}
                    />
                </div>
                <p className="note-label">
                    {`5. ${SAFETY_PLAN_NOTE_LABELS.professionals_or_agencies_i_can_contact_during_a_crisis}`}
                </p>
                <div className="fg">
                    <TextArea
                        {...register(
                            "professionals_or_agencies_i_can_contact_during_a_crisis"
                        )}
                        defaultValue={
                            formValues?.professionals_or_agencies_i_can_contact_during_a_crisis
                        }
                        onBlur={onSubmit && handleSubmit(onSubmit)}
                    />
                </div>
                <p className="note-label">
                    {`6. ${SAFETY_PLAN_NOTE_LABELS.making_the_environment_safe}`}
                </p>
                <div className="fg">
                    <TextArea
                        {...register("making_the_environment_safe")}
                        defaultValue={formValues?.making_the_environment_safe}
                        onBlur={onSubmit && handleSubmit(onSubmit)}
                    />
                </div>
                <p className="note-label">
                    {
                        SAFETY_PLAN_NOTE_LABELS.the_one_thing_that_is_important_to_me_and_worth_living_for_is
                    }
                </p>
                <div className="fg">
                    <TextArea
                        {...register(
                            "the_one_thing_that_is_important_to_me_and_worth_living_for_is"
                        )}
                        defaultValue={
                            formValues?.the_one_thing_that_is_important_to_me_and_worth_living_for_is
                        }
                        onBlur={onSubmit && handleSubmit(onSubmit)}
                    />
                </div>
                <p className="note-label">
                    {
                        SAFETY_PLAN_NOTE_LABELS.how_likely_is_the_client_to_use_this_safety_plan
                    }
                </p>
                <div className="fg fg-space-between three flex">
                    <RadioInput
                        {...register(
                            "how_likely_is_the_client_to_use_this_safety_plan"
                        )}
                        label="Very likely"
                        value="Very likely"
                        defaultChecked={
                            formValues?.how_likely_is_the_client_to_use_this_safety_plan ===
                            "Very likely"
                        }
                        onBlur={onSubmit && handleSubmit(onSubmit)}
                    />

                    <RadioInput
                        {...register(
                            "how_likely_is_the_client_to_use_this_safety_plan"
                        )}
                        label="Likely"
                        value="Likely"
                        defaultChecked={
                            formValues?.how_likely_is_the_client_to_use_this_safety_plan ===
                            "Likely"
                        }
                        onBlur={onSubmit && handleSubmit(onSubmit)}
                    />
                    <RadioInput
                        {...register(
                            "how_likely_is_the_client_to_use_this_safety_plan"
                        )}
                        label="Unlikely"
                        value="Unlikely"
                        defaultChecked={
                            formValues?.how_likely_is_the_client_to_use_this_safety_plan ===
                            "Unlikely"
                        }
                        onBlur={onSubmit && handleSubmit(onSubmit)}
                    />
                </div>
                {errors.how_likely_is_the_client_to_use_this_safety_plan ? (
                    <div className="note-error">
                        <p>Please select an option</p>
                    </div>
                ) : null}
                <p className="note-label">
                    {
                        SAFETY_PLAN_NOTE_LABELS.how_likely_is_the_client_to_adhere_to_this_safety_plan
                    }
                </p>
                <div className="fg fg-space-between three flex">
                    <RadioInput
                        {...register(
                            "how_likely_is_the_client_to_adhere_to_this_safety_plan"
                        )}
                        label="Very likely"
                        value="Very likely"
                        defaultChecked={
                            formValues?.how_likely_is_the_client_to_adhere_to_this_safety_plan ===
                            "Very likely"
                        }
                        onBlur={onSubmit && handleSubmit(onSubmit)}
                    />

                    <RadioInput
                        {...register(
                            "how_likely_is_the_client_to_adhere_to_this_safety_plan"
                        )}
                        label="Likely"
                        value="Likely"
                        defaultChecked={
                            formValues?.how_likely_is_the_client_to_adhere_to_this_safety_plan ===
                            "Likely"
                        }
                        onBlur={onSubmit && handleSubmit(onSubmit)}
                    />
                    <RadioInput
                        {...register(
                            "how_likely_is_the_client_to_adhere_to_this_safety_plan"
                        )}
                        label="Unlikely"
                        value="Unlikely"
                        defaultChecked={
                            formValues?.how_likely_is_the_client_to_adhere_to_this_safety_plan ===
                            "Unlikely"
                        }
                        onBlur={onSubmit && handleSubmit(onSubmit)}
                    />
                </div>
                {errors.how_likely_is_the_client_to_adhere_to_this_safety_plan ? (
                    <div className="note-error">
                        <p>Please select an option</p>
                    </div>
                ) : null}
                <p className="note-label">
                    {SAFETY_PLAN_NOTE_LABELS.detail_hloc_options_and_next_steps}
                </p>
                <div className="fg">
                    <TextArea
                        {...register("detail_hloc_options_and_next_steps")}
                        defaultValue={
                            formValues?.detail_hloc_options_and_next_steps
                        }
                        onBlur={onSubmit && handleSubmit(onSubmit)}
                    />
                </div>
                <p className="note-label">
                    {
                        SAFETY_PLAN_NOTE_LABELS.where_will_the_client_keep_the_safety_plan_and_how_will_it_be_located_during_a_crisis
                    }
                </p>
                <div className="fg">
                    <TextArea
                        {...register(
                            "where_will_the_client_keep_the_safety_plan_and_how_will_it_be_located_during_a_crisis"
                        )}
                        defaultValue={
                            formValues?.where_will_the_client_keep_the_safety_plan_and_how_will_it_be_located_during_a_crisis
                        }
                        onBlur={onSubmit && handleSubmit(onSubmit)}
                    />
                </div>
                <p className="note-label">
                    {
                        SAFETY_PLAN_NOTE_LABELS.is_the_format_appropriate_for_client_capacity_and_circumstances
                    }
                </p>
                <div className="fg fg-space-between two flex">
                    <RadioInput
                        {...register(
                            "is_the_format_appropriate_for_client_capacity_and_circumstances"
                        )}
                        label="Yes"
                        value="Yes"
                        defaultChecked={
                            formValues?.is_the_format_appropriate_for_client_capacity_and_circumstances ===
                            "Yes"
                        }
                        onBlur={onSubmit && handleSubmit(onSubmit)}
                    />
                    <RadioInput
                        {...register(
                            "is_the_format_appropriate_for_client_capacity_and_circumstances"
                        )}
                        label="No"
                        value="No"
                        defaultChecked={
                            formValues?.is_the_format_appropriate_for_client_capacity_and_circumstances ===
                            "No"
                        }
                        onBlur={onSubmit && handleSubmit(onSubmit)}
                    />
                </div>
                {errors.is_the_format_appropriate_for_client_capacity_and_circumstances ? (
                    <div className="note-error">
                        <p>Please select an option</p>
                    </div>
                ) : null}
                <p className="note-label">
                    {
                        SAFETY_PLAN_NOTE_LABELS.when_should_follow_up_be_done_for_this_client
                    }
                </p>
                <div className="fg">
                    <Controller
                        name="when_should_follow_up_be_done_for_this_client"
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                label="Select"
                                onChange={(date) => {
                                    field.onChange(date);
                                    setLocalDate(date);
                                }}
                                selected={localDate}
                                value={formatDateString(field.value)}
                                minDate={new Date(Date.now())}
                                hasError={
                                    !!errors.when_should_follow_up_be_done_for_this_client
                                }
                                errorText={
                                    errors
                                        .when_should_follow_up_be_done_for_this_client
                                        ?.type === "typeError"
                                        ? "Invalid date value"
                                        : errors
                                              .when_should_follow_up_be_done_for_this_client
                                              ?.message
                                }
                                onBlur={onSubmit && handleSubmit(onSubmit)}
                                shouldCloseOnSelect
                                onCalendarClose={
                                    onSubmit && handleSubmit(onSubmit)
                                }
                            />
                        )}
                    />
                </div>
                <h3 className="note-label">
                    {SAFETY_PLAN_NOTE_LABELS.frequency_of_sessions}
                </h3>
                <div className="note-radios">
                    <RadioInput
                        {...register("frequency_of_sessions")}
                        label="Weekly"
                        value="Weekly"
                        defaultChecked={
                            formValues?.frequency_of_sessions === "Weekly"
                        }
                        onBlur={onSubmit && handleSubmit(onSubmit)}
                    />
                    <RadioInput
                        {...register("frequency_of_sessions")}
                        label="Bi-Weekly"
                        value="Bi-Weekly"
                        defaultChecked={
                            formValues?.frequency_of_sessions === "Bi-Weekly"
                        }
                        onBlur={onSubmit && handleSubmit(onSubmit)}
                    />
                    <RadioInput
                        {...register("frequency_of_sessions")}
                        label="Monthly"
                        value="Monthly"
                        defaultChecked={
                            formValues?.frequency_of_sessions === "Monthly"
                        }
                        onBlur={onSubmit && handleSubmit(onSubmit)}
                    />
                    <RadioInput
                        {...register("frequency_of_sessions")}
                        label="Other"
                        value="Other"
                        defaultChecked={
                            formValues?.frequency_of_sessions === "Other"
                        }
                        onBlur={onSubmit && handleSubmit(onSubmit)}
                    />
                </div>
                {watch("frequency_of_sessions") === "Other" && (
                    <div className="fg">
                        <Input
                            {...register("other_frequency_of_sessions")}
                            label={
                                SAFETY_PLAN_NOTE_LABELS.other_frequency_of_sessions
                            }
                            placeholder={
                                SAFETY_PLAN_NOTE_LABELS.other_frequency_of_sessions
                            }
                            hasError={!!errors.other_frequency_of_sessions}
                            errorText={
                                errors.other_frequency_of_sessions?.message
                            }
                            defaultValue={
                                formValues?.other_frequency_of_sessions
                            }
                            onBlur={onSubmit && handleSubmit(onSubmit)}
                        />
                    </div>
                )}
                <div className="fg">
                    <Controller
                        name="date_of_next_session"
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                label={
                                    SAFETY_PLAN_NOTE_LABELS.date_of_next_session
                                }
                                onChange={(date) => {
                                    field.onChange(date);
                                    setLocalDateOfNextSession(date);
                                }}
                                selected={localDateOfNextSession}
                                value={
                                    field.value
                                        ? formatDate(field.value, true)
                                        : undefined
                                }
                                minDate={new Date(Date.now())}
                                hasError={!!errors.date_of_next_session}
                                errorText={
                                    errors.date_of_next_session?.type ===
                                    "typeError"
                                        ? "Invalid date value"
                                        : errors.date_of_next_session?.message
                                }
                                onBlur={onSubmit && handleSubmit(onSubmit)}
                                shouldCloseOnSelect
                                onCalendarClose={
                                    onSubmit && handleSubmit(onSubmit)
                                }
                            />
                        )}
                    />
                </div>
                <div className="fg-info fg-line">
                    <p>Sign note here</p>
                    <div className="fg">
                        <Input
                            {...register("signature")}
                            hasError={!!errors.signature}
                            errorText={errors.signature?.message}
                            label="Provider's Initials"
                            placeholder="Provider's Initials"
                            autoComplete="off"
                            isRequired
                        />
                    </div>
                </div>
            </form>
        </>
    );
}
