import { Input, RadioInput } from "@jhool-io/fe-components";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";

import { IPayer } from "../../types/payers.types";
import { useCreatePayer } from "../../hooks/payers.mutations";
import useToast from "../../../../hooks/useToast";

interface Props {
    onFormSubmit: () => void;
}

export default function AddNewPayer({ onFormSubmit }: Props) {
    const addNewPayer = useCreatePayer();
    const queryClient = useQueryClient();
    const { toast } = useToast();

    // form validation schema
    const schema = yup.object().shape({
        name: yup.string().required("Provider Name is required"),
        payer_id: yup.string(),
        payer_name: yup.string(),
        payer_address_1: yup.string(),
        payer_address_2: yup.string(),
        payer_city: yup.string(),
        payer_state: yup.string(),
        payer_zipcode: yup.string(),
        payer_enrolled: yup.boolean().default(false),
    });

    // react hook form setup
    const {
        register,
        formState: { errors },
        control,
        setValue,
        handleSubmit,
    } = useForm<IPayer>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    const onSubmit = (data: IPayer) => {
        addNewPayer.mutate(data, {
            onSuccess(res) {
                queryClient.invalidateQueries({ queryKey: ["payers"] });
                toast({
                    mode: "success",
                    message: res.message || "Payer created successfully",
                });

                // close modal
                onFormSubmit();
            },
            onError(err) {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not create payer, please try again",
                });
            },
        });
    };

    return (
        <form id="add-payer-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-32 px-24 py-12">
                <Input
                    label="Insurance Provider Name"
                    {...register("name")}
                    hasError={!!errors.name}
                    errorText={errors.name?.message}
                />

                <Input
                    label="Payer's ID"
                    {...register("payer_id")}
                    hasError={!!errors.payer_id}
                    errorText={errors.payer_id?.message}
                />

                <Input
                    label="Payer's Name"
                    {...register("payer_name")}
                    hasError={!!errors.payer_name}
                    errorText={errors.payer_name?.message}
                />

                <Input
                    label="Payer's Address 1"
                    {...register("payer_address_1")}
                    hasError={!!errors.payer_address_1}
                    errorText={errors.payer_address_1?.message}
                />

                <Input
                    label="Payer's Address 2"
                    {...register("payer_address_2")}
                    hasError={!!errors.payer_address_2}
                    errorText={errors.payer_address_2?.message}
                />

                <Input
                    label="Payer's City"
                    {...register("payer_city")}
                    hasError={!!errors.payer_city}
                    errorText={errors.payer_city?.message}
                />

                <Input
                    label="Payer's State"
                    {...register("payer_state")}
                    hasError={!!errors.payer_state}
                    errorText={errors.payer_state?.message}
                />

                <Input
                    label="Payer's ZipCode"
                    {...register("payer_zipcode")}
                    hasError={!!errors.payer_zipcode}
                    errorText={errors.payer_zipcode?.message}
                />

                <div className="w-fit">
                    <span className="font-medium text-xs">
                        Has Payer Enrolled
                    </span>
                    <div className="flex gap-12 items-center">
                        <Controller
                            control={control}
                            name="payer_enrolled"
                            render={({ field }) => (
                                <RadioInput
                                    label="Yes"
                                    checked={field.value}
                                    onChange={() =>
                                        setValue("payer_enrolled", true)
                                    }
                                    wrapperClass="w-[70px]"
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name="payer_enrolled"
                            render={({ field }) => (
                                <RadioInput
                                    label="No"
                                    checked={!field.value}
                                    onChange={() =>
                                        setValue("payer_enrolled", false)
                                    }
                                    wrapperClass="w-[70px]"
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
        </form>
    );
}
