import * as React from "react";
import cn from "classnames";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ColumnDef, Row } from "@tanstack/react-table";
import { format } from "date-fns";
import {
    Button,
    Card,
    Checkbox,
    DatePicker,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Select,
    SelectElement,
    Table,
} from "@jhool-io/fe-components";
import { IStatementOfAccount } from "../../types/reports.types";
import {
    getTotalAmountOfTableRowsSelected,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    showMoneyInAppFormat,
    truncateString,
} from "../../../../utils/helpers";
import { useFetchInsuranceProviders } from "../../../clients/hooks/clients.queries";
import { IAppCustomDates } from "../../../../utils/types";
import { momentNotTz } from "../../../../utils/moment";
import { useDebounce } from "../../../../hooks/helpers";
import {
    useFetchStatementOfAccount,
    useGetDownloadJobId,
} from "../../hooks/queries/reports.queries";
import { useFetchClientList } from "../../../../hooks/queries/client";
import useToast from "../../../../hooks/useToast";
import { useExportPdfs } from "../../../../hooks/queries";
import CalendarDaysIcon from "../../../../components/Icons/SideNav/CalendarDays";
import ChevronDownIcon from "../../../../components/Icons/ChevronDown";
import { APP_COLORS } from "../../../../utils/constants";
import FormCheckbox from "../../../../components/FormCheckbox/FormCheckbox";
import CheckPrimaryColorIcon from "../../../../components/Icons/CheckPrimaryColor";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import { useSendStatementOfAccount } from "../../hooks/mutations/reports.mutations";
import usePractice from "../../../../hooks/usePractice";

type Options = {
    label: React.ReactNode;
    value: string;
};

const amountDue = ["0", "20000", "40000", "60000", "80000", "100000"];

const columns: ColumnDef<IStatementOfAccount>[] = [
    {
        id: "select",
        header: ({ table }) => (
            <Checkbox
                handleChange={table.getToggleAllRowsSelectedHandler()}
                indeterminate={table.getIsSomeRowsSelected()}
                label=""
                name="row"
                value="row"
                isChecked={table.getIsAllRowsSelected()}
            />
        ),
        cell: ({ row }) => (
            <Checkbox
                handleChange={row.getToggleSelectedHandler()}
                indeterminate={row.getIsSomeSelected()}
                label=""
                name="row"
                value="row"
                isChecked={row.getIsSelected()}
            />
        ),
    },
    {
        accessorKey: "client name",
        header: "Client name",
        cell: ({ row }) => row.original.statement_summary.client_name,
    },
    {
        accessorKey: "Total Amount Outstanding",
        header: "Total Amount Due",
        cell: ({ row }) =>
            row.original.statement_summary.total_amount_outstanding
                ? showMoneyInAppFormat(
                      row.original.statement_summary.total_amount_outstanding
                  )
                : "--",
    },
    {
        accessorKey: "Amount Outstanding Insurance",
        header: "Insurance Due",
        cell: ({ row }) =>
            row.original.statement_summary.amount_outstanding_ins
                ? showMoneyInAppFormat(
                      row.original.statement_summary.amount_outstanding_ins
                  )
                : "--",
    },
    {
        accessorKey: "Amount Outstanding Co-Insurance",
        header: "Co-Insurance Due",
        cell: ({ row }) =>
            row.original.statement_summary.amount_outstanding_coins
                ? showMoneyInAppFormat(
                      row.original.statement_summary.amount_outstanding_coins
                  )
                : "--",
    },
    {
        accessorKey: "Insurance Provider",
        header: "Insurance Provider",
        cell: ({ row }) => (
            <span className="uppercase">
                {row.original.statement_breakdown[0]?.insurance_provider ||
                    "--"}
            </span>
        ),
    },
];

export default function StatementOfAccountPreview() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchValue, setSearchValue] = React.useState("");
    const [showDefault, setShowDefault] = React.useState(false);
    const [showOptions, setShowOptions] = React.useState(false);
    const [isDownloading, setIsDownloading] = React.useState(false);
    const [jobId, setJobId] = React.useState<string | null>(null);
    const [showLoader, setShowLoader] = React.useState(false);
    const [allTimeCoInsurance, setAllTimeCoInsurance] = React.useState(false);
    const [allTimeInsurance, setAllTimeInsurance] = React.useState(false);
    const [showEmailDialog, setShowEmailDialog] = React.useState(false);

    const [rowSelection, setRowSelection] = React.useState({});
    const [getSelectedData, setGetSelectedData] =
        React.useState<Row<IStatementOfAccount>[]>();

    const navigate = useNavigate();

    const selectRef = React.useRef<SelectElement | null>(null);

    // Hook for fetching insurance providers
    const insuranceProviders = useFetchInsuranceProviders();

    const insuranceOptions =
        insuranceProviders.data?.data.map((provider) => ({
            label: removeEnumUnderscore(provider.name).toUpperCase(),
            value: provider.insurance_provider_id,
        })) || [];

    const providersOptionsforSelect = [
        {
            label: "Self-pay",
            value: "self_pay",
        },
        ...insuranceOptions,
    ];

    providersOptionsforSelect?.sort((a, b) => {
        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();

        if (labelA < labelB) {
            return -1;
        }
        if (labelA > labelB) {
            return 1;
        }

        return 0;
    });

    const DurationTypesForSelect: Options[] = [
        ...Object.values(IAppCustomDates).map((item) => ({
            value: item,
            label: (
                <>{makeStringFirstLetterCapital(removeEnumUnderscore(item))} </>
            ),
        })),
    ];

    const clientsFilter =
        searchParams.getAll("clients[]").length > 0
            ? searchParams.getAll("clients[]")
            : [];
    const insuranceFilter = searchParams.get("insurance_provider") || undefined;
    const coInsuranceFromFilter =
        searchParams.get("coInsurance_from") || undefined;
    const coInsuranceToFilter = searchParams.get("coInsurance_to") || undefined;
    const insuranceFromFilter = searchParams.get("insurance_from") || undefined;
    const insuranceToFilter = searchParams.get("insurance_to") || undefined;
    const customDateFilter =
        (searchParams.get("custom_date") as IAppCustomDates) || undefined;
    const fromDateFilter = searchParams.get("from_date")
        ? momentNotTz(searchParams.get("from_date")).toDate()
        : null;
    const toDateFilter = searchParams.get("to_date")
        ? momentNotTz(searchParams.get("to_date")).toDate()
        : null;

    const { practice } = usePractice();

    const debouncedValue = useDebounce(searchValue, 500);

    const shouldFetchStatementPreview =
        clientsFilter.length > 0 ||
        Boolean(insuranceFilter) ||
        Boolean(customDateFilter) ||
        Boolean(fromDateFilter) ||
        Boolean(toDateFilter) ||
        Boolean(coInsuranceFromFilter) ||
        Boolean(coInsuranceToFilter) ||
        Boolean(insuranceFromFilter) ||
        Boolean(insuranceToFilter) ||
        Boolean(allTimeCoInsurance) ||
        Boolean(allTimeInsurance);

    const statementPreview = useFetchStatementOfAccount(
        {
            action: "preview",
            client_ids: clientsFilter,
            insurance_provider_id: insuranceFilter,
            custom_date: customDateFilter,
            from_date: fromDateFilter
                ? format(fromDateFilter, "yyyy-MM-dd")
                : null,
            to_date: toDateFilter ? format(toDateFilter, "yyyy-MM-dd") : null,
            from_amount_outstanding_coins: allTimeCoInsurance
                ? "0"
                : coInsuranceFromFilter,
            to_amount_outstanding_coins: allTimeCoInsurance
                ? "100000"
                : coInsuranceToFilter,
            from_amount_outstanding_ins: allTimeInsurance
                ? "0"
                : insuranceFromFilter,
            to_amount_outstanding_ins: allTimeInsurance
                ? "100000"
                : insuranceToFilter,
        },
        shouldFetchStatementPreview
    );

    const sendStatement = useSendStatementOfAccount({
        action: "email",
        client_ids: getSelectedData
            ? getSelectedData?.map(
                  (item) => item.original.statement_summary.client_id
              )
            : [],
        insurance_provider_id:
            searchParams.get("insurance_provider") || undefined,
        custom_date:
            (searchParams.get("custom_date") as IAppCustomDates) || undefined,
        from_date: searchParams.get("from_date")
            ? format(
                  new Date(searchParams.get("from_date") as string),
                  "yyyy-MM-dd"
              )
            : null,
        to_date: searchParams.get("to_date")
            ? format(
                  new Date(searchParams.get("to_date") as string),
                  "yyyy-MM-dd"
              )
            : null,
        send_to_client_email: true,
    });

    const { data, isLoading } = useFetchClientList(
        {
            page: 1,
            search_string: debouncedValue.toLowerCase(),
            provider_id: practice?.provider_id || "",
            sort_attribute: "client_name",
            sort_order: "ASC",
        },
        (showOptions && searchValue.length > 0) || clientsFilter.length > 0
    );

    const statementDownload = useGetDownloadJobId(
        {
            action: "download",
            client_ids: clientsFilter,
            insurance_provider_id: insuranceFilter,
            custom_date: customDateFilter,
            from_date: fromDateFilter
                ? format(fromDateFilter, "yyyy-MM-dd")
                : null,
            to_date: toDateFilter ? format(toDateFilter, "yyyy-MM-dd") : null,
            from_amount_outstanding_coins: allTimeCoInsurance
                ? "0"
                : coInsuranceFromFilter,
            to_amount_outstanding_coins: allTimeCoInsurance
                ? "100000"
                : coInsuranceToFilter,
            from_amount_outstanding_ins: allTimeInsurance
                ? "0"
                : insuranceFromFilter,
            to_amount_outstanding_ins: allTimeInsurance
                ? "100000"
                : insuranceToFilter,
        },
        shouldFetchStatementPreview && isDownloading
    );

    const toast = useToast();

    const exportPdf = useExportPdfs(
        { export_job_id: jobId as string, pdf_type: "statement_of_account" },
        Boolean(jobId) && showLoader
    );

    const ClientNamesForSelect = data?.data.map((client) => ({
        label: `${client.first_name} ${client.last_name}`,
        value: client.client_id,
    }));

    // Get filtered options for clients list
    const filteredClients = ClientNamesForSelect?.filter((option) =>
        option.label.toLowerCase().includes(searchValue.toLowerCase())
    );

    const handleShowEmailDialog = () => {
        setShowEmailDialog((state) => !state);
    };

    const handleClientsChange = (values: unknown) => {
        // Clear array values so we don't have duplicates
        // or values that have been removed from the array

        searchParams.delete("clients[]");
        if ((values as Options[]).length === 0) {
            searchParams.delete("clients[]");
        } else {
            (values as Options[]).forEach((option) => {
                searchParams.append("clients[]", option.value);
            });
        }

        setSearchParams(searchParams);
    };

    const handleCustomDateChange = (value: unknown) => {
        // Clear value if no value was selected

        if ((value as Options).value === "") searchParams.delete("custom_date");
        else searchParams.set("custom_date", (value as Options).value);

        searchParams.set(
            "custom_date",
            (value as Options).value as IAppCustomDates
        );

        setSearchParams(searchParams);
    };

    const handleInsuranceProviderChange = (value: unknown) => {
        // Clear value if no value was selected
        if ((value as Options).value === "")
            searchParams.delete("insurance_provider");
        else searchParams.set("insurance_provider", (value as Options).value);

        searchParams.set(
            "insurance_provider",
            (value as Options).value as IAppCustomDates
        );

        setSearchParams(searchParams);
    };

    const handleCoInsuranceFromChange = (value: string) => {
        if (value === "") searchParams.delete("coInsurance_from");
        else searchParams.set("coInsurance_from", value);
        setSearchParams(searchParams);
    };

    const handleCoInsuranceToChange = (value: string) => {
        if (value === "") searchParams.delete("coInsurance_to");
        else searchParams.set("coInsurance_to", value);
        setSearchParams(searchParams);
    };

    const handleInsuranceFromChange = (value: string) => {
        if (value === "") searchParams.delete("insurance_from");
        else searchParams.set("insurance_from", value);
        setSearchParams(searchParams);
    };

    const handleInsuranceToChange = (value: string) => {
        if (value === "") searchParams.delete("insurance_to");
        else searchParams.set("insurance_to", value);
        setSearchParams(searchParams);
    };

    const handleFromDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("from_date");
        else searchParams.set("from_date", format(date, "yyyy-MM-dd"));

        setSearchParams(searchParams);
    };

    const handleToDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("to_date");
        else searchParams.set("to_date", format(date, "yyyy-MM-dd"));

        setSearchParams(searchParams);
    };

    const handleRowClick = (row: Row<IStatementOfAccount>) => {
        navigate(
            `/reports/billing-reports/${
                row.original.statement_summary.client_id
            }?${searchParams.toString()}`
        );
    };

    const handleReset = () => {
        searchParams.delete("clients[]");
        searchParams.delete("custom_date");
        searchParams.delete("insurance_provider");
        searchParams.delete("from_date");
        searchParams.delete("to_date");
        searchParams.delete("coInsurance_from");
        searchParams.delete("coInsurance_to");
        searchParams.delete("insurance_from");
        searchParams.delete("insurance_to");
        setAllTimeCoInsurance(false);
        setAllTimeInsurance(false);
        selectRef.current?.clearValue();
        setSearchParams(searchParams);
    };

    const onSubmit = () => {
        sendStatement.mutate(
            {},
            {
                onSuccess: (res) => {
                    toast.toast({
                        mode: "success",
                        message:
                            res.message ||
                            "Statement of account sent successfully",
                    });
                    handleShowEmailDialog();
                    setRowSelection({});
                },
                onError: (err) => {
                    toast.toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Could not send statement of account at this time",
                    });
                },
            }
        );
    };

    const handleDownload = async () => {
        setIsDownloading(true);

        if (statementDownload.error) {
            toast.toast({
                mode: "error",
                message:
                    (statementDownload.error.response?.data
                        .message as string) ||
                    "Could not download statement of account.",
            });
        }
        setShowLoader(true);
    };

    React.useEffect(() => {
        setShowDefault(true);
    }, []);

    React.useEffect(() => {
        // Check if the exportPdf query is currently loading
        const isExportPdfLoading = exportPdf.isLoading;

        if (statementDownload.isSuccess) {
            setIsDownloading(false);
        }

        // Check if there's a job ID and the exportPdf query is not loading
        if (jobId && !isExportPdfLoading) {
            setShowLoader(false);
            setJobId(null);
            setRowSelection({});
        }
    }, [statementDownload.isSuccess, jobId, exportPdf.isLoading]);

    React.useEffect(() => {
        if (statementDownload.data?.data.export_job_id) {
            const timeoutId = setTimeout(() => {
                setJobId(statementDownload.data?.data.export_job_id);
            }, 4000);

            return () => {
                clearTimeout(timeoutId);
            };
        }

        return () => {};
    }, [statementDownload.data?.data.export_job_id, statementDownload.isStale]);

    return (
        <>
            <Dialog open={showEmailDialog}>
                <DialogContent
                    title="Send statement to mail"
                    handleCloseDialog={handleShowEmailDialog}
                    saveText="Yes, confirm"
                    isCancelBtnDisabled={sendStatement.isLoading}
                    isSubmitBtnDisabled={sendStatement.isLoading}
                    variant="center"
                    onSaveClick={onSubmit}
                >
                    <div>
                        <h3 className="text-xl mb-8 font-medium">
                            Are you sure you want to send statement to mail?
                        </h3>
                        <p className="text-base text-gray font-normal">
                            By confirming, please note that this action is
                            irreversible
                        </p>
                    </div>
                </DialogContent>
            </Dialog>
            <Card classNames="border border-strokedark p-0 mb-32">
                <div>
                    <div className="flex border-b border-b-strokedark p-24 justify-between items-center">
                        <p className="font-medium text-base">Set the details</p>
                        <div>
                            <Button
                                className="h-32 ml-12"
                                variant="secondary"
                                mode="filled"
                                type="button"
                                onClick={handleReset}
                            >
                                Reset
                            </Button>
                        </div>
                    </div>
                    <div className="p-24">
                        <form id="statement-preview">
                            <div className="fg">
                                <Select
                                    ref={selectRef}
                                    isSearchable
                                    filterOption={() => true}
                                    isMulti
                                    placeholder="Client name(Type multiple)"
                                    value={
                                        showDefault
                                            ? ClientNamesForSelect?.filter(
                                                  (client) =>
                                                      searchParams
                                                          .getAll("clients[]")
                                                          .includes(
                                                              client.value
                                                          )
                                              ) || []
                                            : undefined
                                    }
                                    aria-label="Client name"
                                    label="Client name"
                                    options={filteredClients}
                                    onChange={handleClientsChange}
                                    onInputChange={(value) => {
                                        setShowOptions(true);
                                        setSearchValue(value);
                                        setShowDefault(false);
                                    }}
                                    onBlur={() => {
                                        setShowOptions(false);
                                    }}
                                    defaultValue={
                                        !showDefault
                                            ? ClientNamesForSelect?.filter(
                                                  (client) =>
                                                      searchParams
                                                          .getAll("clients[]")
                                                          .includes(
                                                              client.value
                                                          )
                                              ) || []
                                            : undefined
                                    }
                                    openMenuOnClick={false}
                                    menuIsOpen={searchValue.length > 0}
                                    noOptionsMessage={({ inputValue }) =>
                                        isLoading && inputValue
                                            ? "Fetching clients..."
                                            : "No options"
                                    }
                                />
                            </div>

                            <div className="fg">
                                <Select
                                    placeholder="Select insurance provider"
                                    aria-label="insurance"
                                    label="Insurance provider"
                                    options={providersOptionsforSelect || []}
                                    value={
                                        providersOptionsforSelect?.find(
                                            (provider) => {
                                                if (insuranceFilter)
                                                    return (
                                                        provider.value ===
                                                        insuranceFilter
                                                    );
                                                return undefined;
                                            }
                                        ) || null
                                    }
                                    hideSelectedOptions
                                    onChange={handleInsuranceProviderChange}
                                    isSearchable
                                />
                            </div>

                            <div className="fg fg-space-between two flex">
                                <div>
                                    <Select
                                        label="Date range"
                                        options={DurationTypesForSelect}
                                        placeholder="Select date range"
                                        onChange={handleCustomDateChange}
                                        value={
                                            DurationTypesForSelect.find(
                                                (option) => {
                                                    if (customDateFilter)
                                                        return (
                                                            option.value ===
                                                            customDateFilter
                                                        );
                                                    return undefined;
                                                }
                                            ) || null
                                        }
                                    />
                                </div>
                                <div className="-mt-[6px]">
                                    <p className="text-xs font-medium pb-[2px]">
                                        {" "}
                                        Date
                                    </p>
                                    <Popover>
                                        <PopoverTrigger asChild>
                                            <div className="flex justify-between h-[41px] border-[1.5px] border-[#E7E7E7] rounded-r8 items-center">
                                                <Button
                                                    className="w-full text-xs justify-start font-normal text-gray data-[state=open]:border-primary "
                                                    variant="normal"
                                                    mode="outline"
                                                    aria-label="request-changes"
                                                    type="button"
                                                >
                                                    {fromDateFilter &&
                                                    toDateFilter
                                                        ? `${format(
                                                              fromDateFilter as Date,
                                                              "MM/dd/yyyy"
                                                          )} - ${format(
                                                              toDateFilter as Date,
                                                              "MM/dd/yyyy"
                                                          )}`
                                                        : "Select date"}
                                                </Button>
                                                <div className="pr-8">
                                                    {" "}
                                                    <CalendarDaysIcon />
                                                </div>
                                            </div>
                                        </PopoverTrigger>
                                        <PopoverContent
                                            align="start"
                                            className="h-auto w-96 px-16 pt-6 z-20"
                                        >
                                            <DatePicker
                                                label="From"
                                                placeholderText="MM/DD/YYYY"
                                                selected={fromDateFilter}
                                                onChange={
                                                    handleFromDateFilterChange
                                                }
                                                className="hover:border-primary"
                                                maxDate={
                                                    toDateFilter ||
                                                    new Date(Date.now())
                                                }
                                                isClearable
                                            />
                                            <div className="mt-24 mb-24 hover:border-primary">
                                                <DatePicker
                                                    label="To"
                                                    placeholderText="MM/DD/YYYY"
                                                    selected={toDateFilter}
                                                    onChange={
                                                        handleToDateFilterChange
                                                    }
                                                    className="hover:border-primary"
                                                    minDate={fromDateFilter}
                                                    maxDate={
                                                        new Date(Date.now())
                                                    }
                                                    isClearable
                                                />
                                            </div>
                                        </PopoverContent>
                                    </Popover>
                                </div>
                            </div>
                            <div className="fg fg-space-between two flex">
                                <div>
                                    <Popover>
                                        <PopoverTrigger asChild>
                                            <div>
                                                <p className="text-xs font-medium pb-4">
                                                    {" "}
                                                    Co-insurance Due
                                                </p>
                                                <Button
                                                    size="auto"
                                                    variant="normal"
                                                    className="w-full justify-between h-[4rem] px-16 shadow-active gap-x-8 text-xs text-gray data-[state=open]:border-primary font-normal self-start md:self-center"
                                                >
                                                    Select range
                                                    <ChevronDownIcon
                                                        stroke={
                                                            APP_COLORS.COLOR_BLACK
                                                        }
                                                    />
                                                </Button>
                                                <div className="pt-8">
                                                    <FormCheckbox
                                                        label="All time co-insurance due"
                                                        value="All time co-insurance due"
                                                        labelClass="text-sm font-normal"
                                                        checked={
                                                            allTimeCoInsurance
                                                        }
                                                        onChange={(e) =>
                                                            setAllTimeCoInsurance(
                                                                e.currentTarget
                                                                    .checked
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </PopoverTrigger>
                                        <PopoverContent
                                            align="start"
                                            className="h-auto w-[396px] px-16 pt-6 z-20"
                                        >
                                            <div className="flex flex-col gap-y-[14px]">
                                                <div>
                                                    <p className="pb-4 text-gray">
                                                        From
                                                    </p>
                                                    <Dropdown>
                                                        <DropdownTrigger
                                                            asChild
                                                        >
                                                            <Button
                                                                size="auto"
                                                                variant="normal"
                                                                className={cn(
                                                                    "px-16 h-32 w-full justify-between shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize"
                                                                )}
                                                            >
                                                                {amountDue && (
                                                                    <>
                                                                        {truncateString(
                                                                            showMoneyInAppFormat(
                                                                                coInsuranceFromFilter as string
                                                                            )
                                                                        )}
                                                                        <ChevronDownIcon
                                                                            stroke={
                                                                                APP_COLORS.COLOR_BLACK
                                                                            }
                                                                        />
                                                                    </>
                                                                )}
                                                            </Button>
                                                        </DropdownTrigger>
                                                        <DropdownContent
                                                            align="start"
                                                            width="auto"
                                                            maxHeight={216}
                                                            className="overflow-y-auto"
                                                        >
                                                            {amountDue.map(
                                                                (amount) => (
                                                                    <DropdownItem
                                                                        className={cn(
                                                                            "flex gap-x-8 items-center uppercase text-xs font-medium",
                                                                            {
                                                                                "text-primary":
                                                                                    coInsuranceFromFilter ===
                                                                                    amount,
                                                                            }
                                                                        )}
                                                                        key={
                                                                            amount
                                                                        }
                                                                        onClick={() =>
                                                                            handleCoInsuranceFromChange(
                                                                                amount
                                                                            )
                                                                        }
                                                                    >
                                                                        {showMoneyInAppFormat(
                                                                            amount
                                                                        )}
                                                                        {coInsuranceFromFilter ===
                                                                        amount ? (
                                                                            <CheckPrimaryColorIcon />
                                                                        ) : null}
                                                                    </DropdownItem>
                                                                )
                                                            )}
                                                        </DropdownContent>
                                                    </Dropdown>
                                                </div>
                                                <div>
                                                    <p className="pb-4 text-gray">
                                                        To
                                                    </p>
                                                    <Dropdown>
                                                        <DropdownTrigger
                                                            asChild
                                                        >
                                                            <Button
                                                                size="auto"
                                                                variant="normal"
                                                                className={cn(
                                                                    "px-16 w-full h-32 justify-between  shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize"
                                                                )}
                                                            >
                                                                {amountDue && (
                                                                    <>
                                                                        {truncateString(
                                                                            showMoneyInAppFormat(
                                                                                coInsuranceToFilter as string
                                                                            )
                                                                        )}
                                                                        <ChevronDownIcon
                                                                            stroke={
                                                                                APP_COLORS.COLOR_BLACK
                                                                            }
                                                                        />
                                                                    </>
                                                                )}
                                                            </Button>
                                                        </DropdownTrigger>
                                                        <DropdownContent
                                                            align="start"
                                                            width="auto"
                                                            maxHeight={216}
                                                            className="overflow-y-auto"
                                                        >
                                                            {amountDue.map(
                                                                (amount) => (
                                                                    <DropdownItem
                                                                        className={cn(
                                                                            "flex gap-x-8 items-center uppercase text-xs font-medium",
                                                                            {
                                                                                "text-primary":
                                                                                    coInsuranceToFilter ===
                                                                                    amount,
                                                                            }
                                                                        )}
                                                                        key={
                                                                            amount
                                                                        }
                                                                        onClick={() =>
                                                                            handleCoInsuranceToChange(
                                                                                amount
                                                                            )
                                                                        }
                                                                    >
                                                                        {showMoneyInAppFormat(
                                                                            amount
                                                                        )}
                                                                        {coInsuranceToFilter ===
                                                                        amount ? (
                                                                            <CheckPrimaryColorIcon />
                                                                        ) : null}
                                                                    </DropdownItem>
                                                                )
                                                            )}
                                                        </DropdownContent>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </PopoverContent>
                                    </Popover>
                                </div>
                                <div>
                                    <Popover>
                                        <PopoverTrigger
                                            asChild
                                            className="w-full"
                                        >
                                            <div>
                                                <p className="text-xs font-medium pb-4">
                                                    {" "}
                                                    Insurance Due
                                                </p>
                                                <Button
                                                    size="auto"
                                                    variant="normal"
                                                    className="w-full justify-between px-16 h-[4rem] shadow-active gap-x-8 text-xs text-gray data-[state=open]:border-primary font-normal self-start md:self-center"
                                                >
                                                    Select range
                                                    <ChevronDownIcon
                                                        stroke={
                                                            APP_COLORS.COLOR_BLACK
                                                        }
                                                    />
                                                </Button>
                                                <div className="pt-8">
                                                    <FormCheckbox
                                                        label="All time insurance due"
                                                        value="All time insurance due"
                                                        checked={
                                                            allTimeInsurance
                                                        }
                                                        labelClass="text-sm font-normal"
                                                        onChange={(e) =>
                                                            setAllTimeInsurance(
                                                                e.currentTarget
                                                                    .checked
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </PopoverTrigger>
                                        <PopoverContent
                                            align="start"
                                            className="h-auto w-[396px] px-16 pt-6 z-20"
                                        >
                                            <div className="flex flex-col gap-y-[14px] ">
                                                <div>
                                                    <p className="pb-4 text-gray">
                                                        From
                                                    </p>
                                                    <Dropdown>
                                                        <DropdownTrigger
                                                            asChild
                                                        >
                                                            <Button
                                                                size="auto"
                                                                variant="normal"
                                                                className={cn(
                                                                    "px-16 w-full justify-between h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize"
                                                                )}
                                                            >
                                                                {amountDue && (
                                                                    <>
                                                                        {truncateString(
                                                                            showMoneyInAppFormat(
                                                                                insuranceFromFilter as string
                                                                            )
                                                                        )}
                                                                        <ChevronDownIcon
                                                                            stroke={
                                                                                APP_COLORS.COLOR_BLACK
                                                                            }
                                                                        />
                                                                    </>
                                                                )}
                                                            </Button>
                                                        </DropdownTrigger>
                                                        <DropdownContent
                                                            align="start"
                                                            width="auto"
                                                            maxHeight={216}
                                                            className="overflow-y-auto"
                                                        >
                                                            {amountDue.map(
                                                                (amount) => (
                                                                    <DropdownItem
                                                                        className={cn(
                                                                            "flex gap-x-8 items-center uppercase text-xs font-medium",
                                                                            {
                                                                                "text-primary":
                                                                                    insuranceFromFilter ===
                                                                                    amount,
                                                                            }
                                                                        )}
                                                                        key={
                                                                            amount
                                                                        }
                                                                        onClick={() =>
                                                                            handleInsuranceFromChange(
                                                                                amount
                                                                            )
                                                                        }
                                                                    >
                                                                        {showMoneyInAppFormat(
                                                                            amount
                                                                        )}
                                                                        {insuranceFromFilter ===
                                                                        amount ? (
                                                                            <CheckPrimaryColorIcon />
                                                                        ) : null}
                                                                    </DropdownItem>
                                                                )
                                                            )}
                                                        </DropdownContent>
                                                    </Dropdown>
                                                </div>
                                                <div>
                                                    <p className="pb-4 text-gray">
                                                        To
                                                    </p>
                                                    <Dropdown>
                                                        <DropdownTrigger
                                                            asChild
                                                        >
                                                            <Button
                                                                size="auto"
                                                                variant="normal"
                                                                className={cn(
                                                                    "px-16 h-32 w-full justify-between shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize"
                                                                )}
                                                            >
                                                                {amountDue && (
                                                                    <>
                                                                        {truncateString(
                                                                            showMoneyInAppFormat(
                                                                                insuranceToFilter as string
                                                                            )
                                                                        )}
                                                                        <ChevronDownIcon
                                                                            stroke={
                                                                                APP_COLORS.COLOR_BLACK
                                                                            }
                                                                        />
                                                                    </>
                                                                )}
                                                            </Button>
                                                        </DropdownTrigger>
                                                        <DropdownContent
                                                            align="start"
                                                            width="auto"
                                                            maxHeight={216}
                                                            className="overflow-y-auto"
                                                        >
                                                            {amountDue.map(
                                                                (amount) => (
                                                                    <DropdownItem
                                                                        className={cn(
                                                                            "flex gap-x-8 items-center uppercase text-xs font-medium",
                                                                            {
                                                                                "text-primary":
                                                                                    insuranceToFilter ===
                                                                                    amount,
                                                                            }
                                                                        )}
                                                                        key={
                                                                            amount
                                                                        }
                                                                        onClick={() =>
                                                                            handleInsuranceToChange(
                                                                                amount
                                                                            )
                                                                        }
                                                                    >
                                                                        {showMoneyInAppFormat(
                                                                            amount
                                                                        )}
                                                                        {insuranceToFilter ===
                                                                        amount ? (
                                                                            <CheckPrimaryColorIcon />
                                                                        ) : null}
                                                                    </DropdownItem>
                                                                )
                                                            )}
                                                        </DropdownContent>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </PopoverContent>
                                    </Popover>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Card>

            {statementPreview.isLoading && shouldFetchStatementPreview && (
                <Skeleton
                    type="table"
                    containerTestId="statement-list-loader"
                    count={3}
                />
            )}

            {statementPreview.error &&
                statementPreview.error.response?.status !== 404 && (
                    <ListState
                        isError
                        stateHelperText="Try reloading this page to solve this issue"
                        errorMsg={
                            statementPreview.error?.response?.data.message ||
                            `Cannot display preview at this time please try again later`
                        }
                    />
                )}

            {statementPreview.isSuccess &&
                Object.values(statementPreview.data?.data[0]?.client_statements)
                    .length === 0 && (
                    <ListState
                        stateHelperText="Statement of account for the applied filters will appear here once they are added"
                        isError={false}
                        emptyMessage={statementPreview.data.message}
                    />
                )}

            {statementPreview.isSuccess &&
                Object.values(statementPreview.data?.data[0]?.client_statements)
                    .length > 0 && (
                    <>
                        <Dialog open={showLoader}>
                            <DialogContent
                                title="Download in progress"
                                variant="center"
                                handleCloseDialog={() => setShowLoader(false)}
                                showFooter={false}
                            >
                                <div className="loader">
                                    <div className="loader-icon" />
                                    <div className="loader-text">
                                        Please wait while the download is in
                                        progress.
                                    </div>
                                </div>
                            </DialogContent>
                        </Dialog>
                        {getTotalAmountOfTableRowsSelected(rowSelection)
                            .amount > 0 && (
                            <div className="flex items-center mb-[1.6rem]">
                                <div className="font-semibold text-base">
                                    {
                                        getTotalAmountOfTableRowsSelected(
                                            rowSelection
                                        ).amountString
                                    }
                                    :
                                </div>
                                <Button
                                    className="ml-12"
                                    variant="secondary"
                                    mode="outline"
                                    type="button"
                                    onClick={handleDownload}
                                >
                                    Download statement
                                </Button>
                                <Button
                                    className="ml-12"
                                    variant="secondary"
                                    mode="outline"
                                    type="button"
                                    onClick={handleShowEmailDialog}
                                >
                                    Send to mail
                                </Button>
                            </div>
                        )}

                        <Table
                            columns={columns}
                            data={Object.values(
                                statementPreview.data?.data[0].client_statements
                            )}
                            handleRowClick={handleRowClick}
                            setRowSelection={setRowSelection}
                            rowSelection={rowSelection}
                            setGetSelectedData={setGetSelectedData}
                        />
                    </>
                )}
        </>
    );
}
