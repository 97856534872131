/* eslint-disable react/no-unstable-nested-components */
import {
    Button,
    Card,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
} from "@jhool-io/fe-components";
import { ColumnDef, Row } from "@tanstack/react-table";
import {
    Link,
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
    ClaimRelationshipToSubscriber,
    ClaimsServiceDetails,
    ClaimStatus,
} from "../../types/claims.types";
import {
    cn,
    formatDate,
    removeEnumUnderscore,
    showMoneyInAppFormat,
    userInitials,
} from "../../../../utils/helpers";
import Table from "../../../../components/Table/Table";
import styles from "./SingleClaim.module.scss";
import ChevronDownIcon from "../../../../components/Icons/ChevronDown";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import { useFetchDiagnosisCodes } from "../../../../hooks/queries";
import { ModifierTypes } from "../../../../utils/types/notes";
import {
    useCreateDraftClaim,
    useUpdateClaimStatus,
} from "../../hooks/claims.mutations";
import useToast from "../../../../hooks/useToast";
import ListState from "../../../../components/ListState/ListState";
import { useGetClaim } from "../../hooks/claims.queries";
import { getStateInShortForm } from "../../../../utils/helpers/us-states/us-states";
import InfoDangerIcon from "../../../../components/Icons/InfoDanger";
import { useFetchSessionNote } from "../../../../hooks/queries/note";

interface SingleClaimsProps {
    claim_ID?: string;
    claim_status?: string;
}
export default function SingleClaim({
    claim_ID,
    claim_status,
}: SingleClaimsProps) {
    const [searchParams, setSearchParams] = useSearchParams();
    const status = searchParams.get("status") || claim_status;
    const params = useParams();
    const { claimId, clientId, noteId } = params;
    const { data, isLoading, isSuccess, error } = useGetClaim(
        { claim_id: claimId || claim_ID || "" },
        Boolean(claimId || claim_ID)
    );
    const navigate = useNavigate();
    const location = useLocation();
    const [showPrompt, setShowPrompt] = useState(false);
    const queryClient = useQueryClient();
    // Toast for success and error states
    const { toast } = useToast();
    const diagnosisCodes = useFetchDiagnosisCodes();
    const regenerateDraftClaim = useCreateDraftClaim();

    const claimNoteDetails = useFetchSessionNote(
        clientId || "",
        noteId || "",
        Boolean(clientId) && Boolean(noteId)
    );

    const columns: ColumnDef<ClaimsServiceDetails>[] = React.useMemo(() => {
        // columns items for Procedures, Services or Supplies

        const procedureColumns = [
            {
                accessorKey: "cpt",
                header: "CPT/HCPCS",
                // eslint-disable-next-line react/no-unused-prop-types
                cell: ({ row }: { row: Row<ClaimsServiceDetails> }) => (
                    <div>{row.original.procedureCode || "--"}</div>
                ),
            },
        ];

        const modifiers =
            data?.data?.claim_content?.serviceDetails?.map(
                (item) => item.modifiers
            ) || [];

        // get the modifiers for each row, create a new column and also get the modifier decsription
        modifiers.forEach((modifierList, index) => {
            modifierList.forEach((modifier, innerIndex) => {
                const modifierDescription = Object.values(ModifierTypes).find(
                    (mod) => mod.startsWith(modifier)
                );
                procedureColumns.push({
                    accessorKey: `modifier_${index}_${innerIndex}`,
                    header: `Modifier ${String.fromCharCode(65 + index)}${
                        innerIndex + 1
                    }`,
                    cell: () => (
                        <div className="w-[200px]">
                            {modifierDescription || "--"}
                        </div>
                    ),
                });
            });
        });

        return [
            {
                header: "Date(s) of service:",
                columns: [
                    {
                        accessorKey: "from",
                        header: "FROM",
                        cell: () => (
                            <div className="w-[100px]">
                                {claimNoteDetails.data?.data
                                    ? formatDate(
                                          claimNoteDetails.data?.data
                                              .date_of_service
                                      )
                                    : "--"}
                            </div>
                        ),
                    },
                    {
                        accessorKey: "collected",
                        header: "TO",
                        cell: () => (
                            <div className="w-[100px]">
                                {claimNoteDetails.data?.data
                                    ? formatDate(
                                          claimNoteDetails.data?.data
                                              .date_of_service
                                      )
                                    : "--"}
                            </div>
                        ),
                    },
                ],
            },
            {
                header: " ",
                columns: [
                    {
                        accessorKey: "pos",
                        header: "Place of service code",
                        cell: ({ row }) => (
                            <div className="w-[150px]">
                                {row.original.placeOfServiceCode || "--"}
                            </div>
                        ),
                    },
                ],
            },
            {
                header: " ",
                columns: [
                    {
                        accessorKey: "emg",
                        header: "EMG",
                        cell: ({ row }) =>
                            row.original.isEmergency === "Y" ? "Yes" : "No",
                    },
                ],
            },
            {
                header: "Procedures, Services or Supplies",
                columns: procedureColumns,
            },
            {
                header: " ",
                columns: [
                    {
                        accessorKey: "diagnosis",
                        header: "Diagnosis Pointer",
                        cell: ({ row }) =>
                            row.original.diagnosisPointers
                                .map((e: string) => e)
                                .join(", ") || "--",
                    },
                ],
            },
            {
                header: " ",
                columns: [
                    {
                        accessorKey: "charges",
                        header: "Charges",
                        cell: ({ row }) =>
                            showMoneyInAppFormat(row.original.charge) || "--",
                    },
                ],
            },
            {
                header: " ",
                columns: [
                    {
                        accessorKey: "units",
                        header: "Days or Units",
                        cell: ({ row }) => row.original.units || "--",
                    },
                ],
            },
            {
                header: " ",
                columns: [
                    {
                        accessorKey: "family_plan",
                        header: "EPSDT Family Plan",
                        cell: ({ row }) => row.original.isFamilyPlan || "--",
                    },
                ],
            },
            {
                header: " ",
                columns: [
                    {
                        accessorKey: "id",
                        header: "ID Qual.",
                        cell: ({ row }) =>
                            row.original.renderingProviderNumQualifier || "--",
                    },
                ],
            },
            {
                header: " ",
                columns: [
                    {
                        accessorKey: "provider_id",
                        header: "Rendering Provider ID #",
                        cell: ({ row }) =>
                            row.original.renderingProviderNPI || "--",
                    },
                ],
            },
        ];
    }, [
        claimNoteDetails.data?.data,
        data?.data?.claim_content?.serviceDetails,
    ]);

    // show action buttons for only these status
    const statusToShowActionsFor = [
        ClaimStatus.CODED,
        ClaimStatus.READY_FOR_SUBMISSION,
        ClaimStatus.REJECTED,
        ClaimStatus.DENIED,
        ClaimStatus.CLOSED,
    ];

    const claimsStatusForUpdateList = [
        ClaimStatus.READY_FOR_SUBMISSION,
        ClaimStatus.DENIED,
        ClaimStatus.REJECTED,
    ];

    const handleEditClick = () => {
        searchParams.set("claims_view", "edit");
        searchParams.set("claim_id", claimId || claim_ID || "");
        setSearchParams(searchParams);
    };

    const handleViewClaim = () => {
        if (claimId || claim_ID) {
            navigate(`/claim/${claimId || claim_ID}/print`);
        }
    };

    // Mapping object for relationship display
    const relationshipMapping: { [key: string]: string } = {
        [ClaimRelationshipToSubscriber.Self]: "Self",
        [ClaimRelationshipToSubscriber.Spouse]: "Spouse",
        [ClaimRelationshipToSubscriber.Child]: "Child",
        [ClaimRelationshipToSubscriber.Other]: "Other",
    };

    // get all the claims diagnosis and add them in an array return only the ones that are not empty, null or undefined
    const claimsDiagnosis = [
        { code: data?.data?.claim_content?.diagnosisCode1, pointer: "A" },
        { code: data?.data?.claim_content?.diagnosisCode2, pointer: "B" },
        { code: data?.data?.claim_content?.diagnosisCode3, pointer: "C" },
        { code: data?.data?.claim_content?.diagnosisCode4, pointer: "D" },
        { code: data?.data?.claim_content?.diagnosisCode5, pointer: "E" },
        { code: data?.data?.claim_content?.diagnosisCode6, pointer: "F" },
        { code: data?.data?.claim_content?.diagnosisCode7, pointer: "G" },
        { code: data?.data?.claim_content?.diagnosisCode8, pointer: "H" },
        { code: data?.data?.claim_content?.diagnosisCode9, pointer: "I" },
        { code: data?.data?.claim_content?.diagnosisCode10, pointer: "J" },
        { code: data?.data?.claim_content?.diagnosisCode11, pointer: "K" },
        { code: data?.data?.claim_content?.diagnosisCode12, pointer: "L" },
    ].filter(
        (diagnosis) =>
            diagnosis.code !== undefined &&
            diagnosis.code !== null &&
            diagnosis.code !== ""
    );

    // get diagnosis and its decription
    const getDiagnosisCodes = () => {
        if (
            claimsDiagnosis &&
            claimsDiagnosis.length > 0 &&
            diagnosisCodes &&
            diagnosisCodes.data
        ) {
            return claimsDiagnosis
                .map((diagnosis) => {
                    const matchingCode = diagnosisCodes?.data?.data?.find(
                        (code) => code.code === diagnosis.code
                    );
                    if (matchingCode) {
                        return {
                            code: matchingCode.code,
                            description: matchingCode.description,
                            pointer: diagnosis.pointer,
                        };
                    }
                    return null;
                })
                .filter(Boolean); // Remove any null values from the array
        }
        return [];
    };

    // hook for updating claim status
    const patchClaimStatus = useUpdateClaimStatus();

    // function to change claim status
    const handleChangeStatus = (claimStatus: ClaimStatus) => {
        if (claimStatus === ClaimStatus.SUBMITTED) {
            let errorMessage = "";

            if (data?.data.claim_content?.patientState.length !== 2) {
                errorMessage = `Invalid patient state`;
            }

            if (!data?.data.claim_content.subscriberCity) {
                errorMessage = errorMessage
                    ? `${errorMessage}, Subscriber city is missing`
                    : `Subscriber city is missing`;
            }

            if (!data?.data.claim_content.patientCity) {
                errorMessage = errorMessage
                    ? `${errorMessage}, Patient city is missing`
                    : `Patient city is missing`;
            }

            if (
                !data?.data.claim_content.referringProviderNPI ||
                data?.data.claim_content.referringProviderNPI?.length !== 10
            ) {
                errorMessage = errorMessage
                    ? `${errorMessage}, Provider NPI should be 10 digits`
                    : `Provider NPI should be 10 digits`;
            }

            if (
                data?.data?.claim_content?.patientRelationshipToSubscriber ===
                "18"
            ) {
                if (
                    `${data.data.claim_content.subscriberFirstName} ${data.data.claim_content.subscriberLastName}`.toLowerCase() !==
                    `${data.data.claim_content?.patientFirstName} ${data.data.claim_content?.patientLastName}`.toLowerCase()
                ) {
                    errorMessage = errorMessage
                        ? `${errorMessage}, Patient name must be the same as subscriber name when patient relationship to insured is "SELF."`
                        : `Patient name must be the same as subscriber name when patient relationship to insured is "SELF.`;
                }
            }

            if (errorMessage) {
                toast({
                    mode: "error",
                    message: (
                        <>
                            {errorMessage.split(",").map((msg) => (
                                <span
                                    key={msg}
                                    className="block leading-loose font-medium"
                                >
                                    {msg}
                                </span>
                            ))}
                        </>
                    ),
                    duration: 10000,
                });
                return;
            }
        }

        const changeStatusPayload = {
            claim_id: claimId || claim_ID || "",
            claim_status: claimStatus,
        };
        patchClaimStatus.mutate(changeStatusPayload, {
            onSuccess: (response) => {
                queryClient.invalidateQueries(["get-claim", claimId]);
                toast({
                    mode: "success",
                    message:
                        response?.message ||
                        "Claim status updated successfully",
                });
                searchParams.set("status", claimStatus);
                setSearchParams(searchParams);
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not update claim status at this time",
                });
            },
        });
    };

    // function to change claim status
    const handleRegenerateClaims = () => {
        const changeStatusPayload = {
            note_id: noteId || "",
            overwrite_existing: true,
        };
        regenerateDraftClaim.mutate(changeStatusPayload, {
            onSuccess: async (response) => {
                const newClaimId = response.data.claim.claim_id;

                // Get the current URL
                let currentUrl = location.pathname;

                searchParams.set("status", response.data.claim.claim_status);

                // Update the claim ID in the URL
                currentUrl = currentUrl.replace(
                    /\/claims\/([^/]+)/,
                    `/claims/${newClaimId}`
                );

                // Navigate to the updated URL
                toast({
                    mode: "success",
                    message: `Claim updated successfully`,
                });

                queryClient.removeQueries({ queryKey: ["claims"] });
                queryClient.removeQueries({ queryKey: ["get-claim"] });
                setShowPrompt(false);
                navigate(`${currentUrl}?${searchParams.toString()}`, {
                    replace: true,
                });
            },
            onError: () => {
                toast({
                    mode: "error",
                    message: "Cannot update claim at this time",
                });
            },
        });
    };

    return (
        <div>
            <Dialog open={showPrompt}>
                <DialogContent
                    variant="center"
                    title="Update claim"
                    showFooter
                    cancelText="Cancel"
                    onCancelClick={() => setShowPrompt(false)}
                    saveText="Yes, Update Claim"
                    onSaveClick={handleRegenerateClaims}
                    handleCloseDialog={() => setShowPrompt(false)}
                    isSubmitBtnDisabled={regenerateDraftClaim.isLoading}
                    isCancelBtnDisabled={regenerateDraftClaim.isLoading}
                    classNames="pt-24 pb-48"
                >
                    <div>
                        <h3 className="font-medium text-xl mb-8">
                            Are you sure you want to update claim?
                        </h3>
                        <p className="text-gray font-normal text-base">
                            This will update claim with recent changes to the
                            client, provider and practice? This action will
                            update the data on the claim.
                        </p>
                    </div>
                </DialogContent>
            </Dialog>
            {isLoading && (
                <Skeleton
                    type="table"
                    containerTestId="bills-claims-loader"
                    count={6}
                />
            )}
            {((error && error?.response?.status !== 404) ||
                error?.response?.status === 404) && (
                <ListState
                    isError
                    stateHelperText="Try reloading this page to solve this issue"
                    errorMsg={
                        error?.response?.data.message ||
                        `Cannot display claims at this time please try again later`
                    }
                />
            )}
            {isSuccess && data?.data?.claim_content && (
                <Card classNames="bg-secondary-light p-12">
                    {data.data.claim_status === ClaimStatus.DENIED ||
                    data.data.claim_status === ClaimStatus.REJECTED ? (
                        <div className="mb-24 bg-[#FFF6FB] border-[1.5px] border-danger px-16 py-12 rounded-r8 flex items-center gap-x-12">
                            <div className="bg-white flex items-center justify-center rounded-r12 size-40">
                                <InfoDangerIcon />
                            </div>
                            <div className="flex flex-col justify-center">
                                <span className="text-danger text-sm font-semibold">
                                    Reason for rejection
                                </span>
                                <span className="text-xs font-medium">
                                    {data.data.status_notes || ""}
                                </span>
                            </div>
                        </div>
                    ) : null}
                    <Card classNames="uppercase">
                        <div className="flex pt-8 border-b border-strokelight mb-24 justify-between items-center pb-24 max-[678px]:flex-col max-[678px]:items-start">
                            <h2 className="flex gap-8 font-bold text-sm items-center">
                                Personal & Insured Info
                                <Link
                                    to={`/clients/${data?.data?.client_id}`}
                                    className="text-primary text-xs underline ml font-semibold lowercase"
                                    target="_blank"
                                >
                                    view client chart
                                </Link>
                            </h2>

                            <div
                                className={cn(
                                    "flex justify-end max-[678px]:mt-10 gap-x-20"
                                )}
                            >
                                {status !== ClaimStatus.CLOSED && (
                                    <div className="max-[678px]:ml-0">
                                        <Button
                                            variant="secondary"
                                            onClick={handleViewClaim}
                                        >
                                            View claim
                                        </Button>
                                    </div>
                                )}
                                {statusToShowActionsFor.includes(
                                    status as ClaimStatus
                                ) && (
                                    <div>
                                        <div>
                                            <Dropdown>
                                                <DropdownTrigger asChild>
                                                    <Button
                                                        aria-label="claim-action-button"
                                                        variant="primary"
                                                        type="button"
                                                        iconRight={
                                                            <ChevronDownIcon />
                                                        }
                                                    >
                                                        Actions
                                                    </Button>
                                                </DropdownTrigger>
                                                <DropdownContent
                                                    width="auto"
                                                    align="end"
                                                >
                                                    <>
                                                        {status ===
                                                            ClaimStatus.CLOSED && (
                                                            <DropdownItem
                                                                onClick={() =>
                                                                    handleChangeStatus(
                                                                        ClaimStatus.PENDING
                                                                    )
                                                                }
                                                                disabled={
                                                                    patchClaimStatus.isLoading
                                                                }
                                                            >
                                                                Mark as Pending
                                                            </DropdownItem>
                                                        )}
                                                        {statusToShowActionsFor.includes(
                                                            status as ClaimStatus
                                                        ) &&
                                                        status !==
                                                            ClaimStatus.CLOSED ? (
                                                            <DropdownItem
                                                                onClick={
                                                                    handleEditClick
                                                                }
                                                            >
                                                                Edit
                                                            </DropdownItem>
                                                        ) : null}

                                                        {claimsStatusForUpdateList.includes(
                                                            status as ClaimStatus
                                                        ) ? (
                                                            <DropdownItem
                                                                onClick={() =>
                                                                    setShowPrompt(
                                                                        true
                                                                    )
                                                                }
                                                            >
                                                                Update
                                                            </DropdownItem>
                                                        ) : null}
                                                        {/* <DropdownItem>
                                                                Delete
                                                            </DropdownItem> */}
                                                    </>

                                                    {status ===
                                                        ClaimStatus.READY_FOR_SUBMISSION && (
                                                        <>
                                                            {/* <DropdownItem
                                                                onClick={
                                                                    handleEditClick
                                                                }
                                                            >
                                                                Edit
                                                            </DropdownItem> */}
                                                            <DropdownItem
                                                                onClick={() =>
                                                                    handleChangeStatus(
                                                                        ClaimStatus.SUBMITTED
                                                                    )
                                                                }
                                                            >
                                                                Submit
                                                            </DropdownItem>
                                                            {/* <DropdownItem>
                                                                Delete
                                                            </DropdownItem> */}
                                                        </>
                                                    )}
                                                    {/* to be uncommented when backend provides endpoint */}
                                                    {/* {(status ===
                                                        ClaimStatus.RECEIVED ||
                                                        status ===
                                                            ClaimStatus.SUBMITTED ||
                                                        status ===
                                                            ClaimStatus.ACKNOWLEDGED ||
                                                        status ===
                                                            ClaimStatus.PENDING) &&
                                                        status && (
                                                            <DropdownItem>
                                                                Check status
                                                            </DropdownItem>
                                                        )} */}
                                                </DropdownContent>
                                            </Dropdown>
                                        </div>
                                    </div>
                                )}
                                {/* {status === ClaimStatus.DENIED ||
                                    status === ClaimStatus.REJECTED ? (
                                        <Button
                                            aria-label="resubmit"
                                            variant="primary"
                                            type="button"
                                            onClick={() => setShowPrompt(true)}
                                        >
                                            Resubmit claim
                                        </Button>
                                    ) : null} */}
                            </div>
                        </div>
                        <div className="grid grid-cols-3 gap-y-6 max-[1000px]:grid-cols-1">
                            <div>
                                <p className="text-gray text-xs font-semibold ">
                                    insurance type
                                </p>
                                <p className=" text-sm font-medium uppercase">
                                    {data.data.claim_content.insuranceType
                                        ? removeEnumUnderscore(
                                              data.data.claim_content
                                                  .insuranceType
                                          )
                                        : "--"}
                                </p>
                            </div>
                            <div>
                                <p className="text-gray text-xs font-semibold ">
                                    INSURED’s date of birth
                                </p>
                                <p className=" text-sm font-medium ">
                                    {`${formatDate(
                                        data?.data.claim_content
                                            .subscriberBirthdate as string,
                                        true
                                    )}` || "--"}
                                </p>
                            </div>
                            <div>
                                <p className="text-gray text-xs font-semibold pb-8 ">
                                    patient RELATIONSHIP TO INSURED
                                </p>
                                <p className=" text-sm font-medium ">
                                    {relationshipMapping[
                                        data?.data.claim_content
                                            .patientRelationshipToSubscriber as unknown as string
                                    ] || "--"}
                                </p>
                            </div>
                            <div>
                                <p className="text-gray text-xs font-semibold ">
                                    INSURED’s ID NUMBER
                                </p>
                                <p className=" text-sm font-medium ">
                                    {data?.data.claim_content.subscriberId}
                                </p>
                            </div>
                            <div>
                                <p className="text-gray text-xs font-semibold  ">
                                    INSURED’s SEX
                                </p>
                                <p className=" text-sm font-medium ">
                                    {(data?.data.claim_content.subscriberSex ===
                                        "M" &&
                                        "Male") ||
                                        (data?.data.claim_content
                                            .subscriberSex === "F" &&
                                            "Female") ||
                                        (data?.data.claim_content
                                            .subscriberSex === "U" &&
                                            "Unknown")}
                                </p>
                            </div>
                            <div>
                                <p className="text-gray text-xs font-semibold ">
                                    patient’s condition related to:
                                </p>
                                <div className="grid grid-cols-4 gap-y-6">
                                    <div>
                                        <p className="text-gray text-xs font-semibold ">
                                            Employment
                                        </p>

                                        <p className=" text-sm font-medium ">
                                            {data?.data.claim_content
                                                .isConditionRelatedToEmployment ===
                                            "Y"
                                                ? "Yes"
                                                : "No"}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-gray text-xs font-semibold ">
                                            Auto Accident
                                        </p>

                                        <p className=" text-sm font-medium ">
                                            {data?.data.claim_content
                                                .isConditionAutoAccidentRelated ===
                                            "Y"
                                                ? "Yes"
                                                : "No"}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-gray text-xs font-semibold ">
                                            Other Accident
                                        </p>

                                        <p className=" text-sm font-medium ">
                                            {data?.data.claim_content
                                                .isConditionOtherAccidentRelated ===
                                            "Y"
                                                ? "Yes"
                                                : "No"}
                                        </p>
                                    </div>
                                    {data?.data.claim_content
                                        .autoAccidentState && (
                                        <div>
                                            <p className="text-gray text-xs font-semibold ">
                                                Other Description
                                            </p>
                                            <p className=" text-sm font-medium ">
                                                {
                                                    data?.data.claim_content
                                                        .autoAccidentState
                                                }
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div>
                                <p className="text-gray text-xs font-semibold  ">
                                    INSURED’s NAME
                                </p>
                                <p className=" text-sm font-medium ">
                                    {`${
                                        data?.data?.claim_content
                                            .subscriberFirstName || ""
                                    } ${
                                        data?.data?.claim_content
                                            .subscriberLastName || ""
                                    } ${userInitials(
                                        data?.data?.claim_content
                                            ?.subscriberMiddleName || "",
                                        ""
                                    )}`}
                                </p>
                            </div>
                            <div>
                                <p className="text-gray text-xs font-semibold  ">
                                    PATIENT’s NAME
                                </p>
                                <p className=" text-sm font-medium ">
                                    {`${
                                        data?.data?.claim_content
                                            .patientFirstName || ""
                                    } ${
                                        data?.data?.claim_content
                                            .patientLastName || ""
                                    } ${userInitials(
                                        data?.data?.claim_content
                                            ?.patientMiddleName || "",
                                        ""
                                    )}`}
                                </p>
                            </div>
                            <div>
                                <p className="text-gray text-xs font-semibold ">
                                    Is there another health benefit plan
                                </p>
                                <p className=" text-sm font-medium ">
                                    {data.data.claim_content
                                        .otherInsurancePlanProgram === "" &&
                                    data.data.claim_content
                                        .otherSubscriberId === "" &&
                                    data.data.claim_content
                                        .otherSubscriberFirstName === "" &&
                                    data.data.claim_content
                                        .otherSubscriberLastName === ""
                                        ? "No"
                                        : "Yes"}
                                </p>
                            </div>
                            <div>
                                <p className="text-gray text-xs font-semibold  ">
                                    INSURED’s Address
                                </p>
                                <div className=" text-sm font-medium   flex flex-wrap">
                                    <div>
                                        <p className="w-[80%]">
                                            {data.data.claim_content
                                                .subscriberAddress || "--"}
                                        </p>
                                        <div className="grid grid-cols-2 gap-y-6 mt-[6px]">
                                            <div>
                                                <p className="text-gray text-xs font-semibold ">
                                                    CITY
                                                </p>
                                                <p className=" text-sm font-medium ">
                                                    {data.data.claim_content
                                                        .subscriberCity || "--"}
                                                </p>
                                            </div>
                                            <div>
                                                <p className="text-gray text-xs font-semibold ">
                                                    STATE
                                                </p>
                                                <p className=" text-sm font-medium ">
                                                    {getStateInShortForm(
                                                        data.data.claim_content
                                                            .subscriberState
                                                    )}
                                                </p>
                                            </div>
                                            <div>
                                                <p className="text-gray text-xs font-semibold  ">
                                                    ZIPCODE
                                                </p>
                                                <p className=" text-sm font-medium ">
                                                    {data.data.claim_content
                                                        .subscriberZip || "--"}
                                                </p>
                                            </div>
                                            <div>
                                                <p className="text-gray text-xs font-semibold  ">
                                                    TELEPHONE
                                                </p>
                                                <p className=" text-sm font-medium ">
                                                    {data.data.claim_content
                                                        .subscriberPhone ||
                                                        "--"}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <p className="text-gray text-xs font-semibold  ">
                                        Insured&apos;s Policy Group or FECA
                                        number
                                    </p>
                                    <p className=" text-sm font-medium ">
                                        {data.data.claim_content
                                            .subscriberPolicyGroup || "--"}
                                    </p>
                                </div>
                                <div className="mt-6">
                                    <p className="text-gray text-xs font-semibold ">
                                        Insurance Plan Name or Program Name
                                    </p>
                                    <div className=" text-sm font-medium ">
                                        {data.data.claim_content
                                            .insuranceGroupName || "--"}
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <p className="text-gray text-xs font-semibold ">
                                        Other claim Id
                                        <span className="text-xss ">
                                            (Designated by Nucc)
                                        </span>
                                    </p>
                                    <div className=" text-sm font-medium ">
                                        {data.data.claim_content.otherClaimId ||
                                            "--"}
                                    </div>
                                </div>
                            </div>

                            <div>
                                <p className="text-gray text-xs font-semibold  ">
                                    PATIENT’s Address
                                </p>
                                <div className=" text-sm font-medium   flex flex-wrap">
                                    <div>
                                        <p className="w-[80%]">
                                            {data.data.claim_content
                                                .patientAddress || "--"}
                                        </p>
                                        <div className="grid grid-cols-2 gap-x-10 gap-y-6 mt-[6px]">
                                            <div>
                                                <p className="text-gray text-xs font-semibold ">
                                                    CITY
                                                </p>
                                                <p className=" text-sm font-medium ">
                                                    {data.data.claim_content
                                                        .patientCity || "--"}
                                                </p>
                                            </div>
                                            <div>
                                                <p className="text-gray text-xs font-semibold  ">
                                                    STATE
                                                </p>
                                                <p className=" text-sm font-medium ">
                                                    {getStateInShortForm(
                                                        data.data.claim_content
                                                            .patientState
                                                    )}
                                                </p>
                                            </div>
                                            <div>
                                                <p className="text-gray text-xs font-semibold  ">
                                                    ZIPCODE
                                                </p>
                                                <p className=" text-sm font-medium ">
                                                    {data.data.claim_content
                                                        .patientZip || "--"}
                                                </p>
                                            </div>
                                            <div>
                                                <p className="text-gray text-xs font-semibold  ">
                                                    TELEPHONE
                                                </p>
                                                <p className=" text-sm font-medium ">
                                                    {data.data.claim_content
                                                        .patientPhone || "--"}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <p className="text-gray text-xs font-semibold  ">
                                        PATIENT’s date of birth
                                    </p>
                                    <p className=" text-sm font-medium ">
                                        {`${formatDate(
                                            data?.data.claim_content
                                                .patientBirthdate as string,
                                            true
                                        )}` || "--"}
                                    </p>
                                </div>
                                <div className="mt-6">
                                    <p className="text-gray text-xs font-semibold ">
                                        PATIENT’s SEX
                                    </p>
                                    <div className=" text-sm font-medium ">
                                        {(data?.data.claim_content
                                            .patientSex === "M" &&
                                            "Male") ||
                                            (data?.data.claim_content
                                                .patientSex === "F" &&
                                                "Female") ||
                                            (data?.data.claim_content
                                                .patientSex === "U" &&
                                                "Unknown")}
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <p className="text-gray text-xs font-semibold ">
                                        Claims codes
                                        <span className="text-xss ">
                                            (Designated by NUCC)
                                        </span>
                                    </p>
                                    <div className=" text-sm font-medium ">
                                        {data.data.claim_content.claimCodes ||
                                            "--"}
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <p className="text-gray text-xs font-semibold ">
                                        Reserved for nucc use
                                    </p>
                                    <div className=" text-sm font-medium ">
                                        {data.data.claim_content
                                            .reservedForNUCCUse || "--"}
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-row-2">
                                <div>
                                    <p className="text-gray text-xs font-semibold  ">
                                        Patient’s or authorized person’s
                                        signature
                                    </p>
                                    <p className=" text-sm font-medium ">
                                        {`${
                                            data.data.claim_content
                                                .patientSignature
                                        } ${formatDate(
                                            data.data.claim_content
                                                .patientSignatureDate as string
                                        )}`}
                                    </p>
                                    <div className="mt-6">
                                        <p className="text-gray text-xs font-semibold  ">
                                            Insured’s or authorized person’s
                                            signature
                                        </p>
                                        <p className=" text-sm font-medium ">
                                            {data.data.claim_content
                                                .subscriberSignature || ""}
                                        </p>
                                    </div>
                                    <div className="mt-6">
                                        <p className="text-gray text-xs font-semibold  ">
                                            Other Insured&apos;s Name
                                        </p>
                                        <p className=" text-sm font-medium ">
                                            {`${
                                                data?.data?.claim_content
                                                    .otherSubscriberFirstName ||
                                                "--"
                                            } ${
                                                data?.data?.claim_content
                                                    .otherSubscriberLastName ||
                                                ""
                                            } ${userInitials(
                                                data?.data?.claim_content
                                                    ?.otherSubscriberMiddleName ||
                                                    "",
                                                ""
                                            )}`}
                                        </p>
                                    </div>
                                    <div className="mt-6">
                                        <p className="text-gray text-xs font-semibold  ">
                                            Other Insured Policy OR groUP nUMBER
                                        </p>
                                        <p className=" text-sm font-medium ">
                                            {data.data.claim_content
                                                .otherSubscriberId || "--"}
                                        </p>
                                    </div>
                                    <div className="mt-6">
                                        <p className="text-gray text-xs font-semibold  ">
                                            Other Inusrance Plan Name or Program
                                        </p>
                                        <p className=" text-sm font-medium ">
                                            {data.data.claim_content
                                                .otherInsurancePlanProgram ||
                                                "--"}
                                        </p>
                                    </div>
                                    <div className="mt-6">
                                        <p className="text-gray text-xs font-semibold  ">
                                            Reserved for Nucc use
                                        </p>
                                        <p className=" text-sm font-medium ">
                                            {data.data.claim_content
                                                .otherSubscriberReserved ||
                                                "--"}
                                        </p>
                                    </div>
                                    <div className="mt-6">
                                        <p className="text-gray text-xs font-semibold  ">
                                            Reserved for Nucc use
                                        </p>
                                        <p className=" text-sm font-medium ">
                                            {data.data.claim_content
                                                .otherSubscriberReserved2 ||
                                                "--"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                    <Card classNames="p-24 mt-24 uppercase">
                        <div className="grid grid-cols-3 gap-y-4 max-[750px]:grid-cols-1">
                            <div className="text-xs font-semibold ">
                                <p className=" text-gray ">
                                    Date of current illness, injury or pregnancy
                                    (LMP)
                                </p>
                                <div className="grid grid-cols-3 gap-y-4">
                                    <div>
                                        <span className="text-gray">Date</span>
                                        <p className=" text-sm font-medium ">
                                            {data.data.claim_content
                                                .illnessDate || "--"}
                                        </p>
                                    </div>
                                    <div className="text-gray text-xs font-semibold">
                                        <span className="text-gray"> Qual</span>
                                        <p className=" text-sm font-medium ">
                                            {data.data.claim_content
                                                .illnessDateQualifier || "--"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className=" text-xs font-semibold ">
                                <span className="text-gray"> OTHER DATE</span>
                                <div className="grid grid-cols-3 gap-y-4">
                                    <div className="text-xs font-semibold">
                                        <span className="text-gray ">
                                            {" "}
                                            Qual
                                        </span>
                                        <p className=" text-sm font-medium ">
                                            {data.data.claim_content
                                                .otherDateQualifier || "--"}
                                        </p>
                                    </div>
                                    <div>
                                        <span className="text-gray"> Date</span>
                                        <p className=" text-sm font-medium ">
                                            {data.data.claim_content
                                                .otherDate || "--"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="text-xs font-semibold ">
                                <p className=" text-gray ">
                                    Dates Patient unable to work in current
                                    occupation
                                </p>
                                <div className="grid grid-cols-3 gap-y-4">
                                    <div className=" text-xs font-semibold">
                                        <span className="text-gray">From</span>
                                        <p className=" text-sm font-medium ">
                                            {data.data.claim_content
                                                .unableToWorkFromDate || "--"}
                                        </p>
                                    </div>
                                    <div>
                                        <span className="text-gray"> To</span>
                                        <p className=" text-sm font-medium ">
                                            {data.data.claim_content
                                                .unableToWorkToDate || "--"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="text-xs font-semibold">
                                <p className=" text-gray">
                                    Hospitalization dates related to current
                                    service
                                </p>
                                <div className="grid grid-cols-3 gap-y-4">
                                    <div className="text-xs font-semibold">
                                        <span className="text-gray"> From</span>
                                        <p className="text-sm font-medium ">
                                            {data.data.claim_content
                                                .admissionFromDate || "--"}
                                        </p>
                                    </div>
                                    <div>
                                        <span className="text-gray"> To</span>
                                        <p className=" text-sm font-medium ">
                                            {data.data.claim_content
                                                .admissionToDate || "--"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="text-xs font-semibold ">
                                <p className=" text-gray">
                                    Name of referring Provider or other source
                                </p>
                                <p className="text-sm font-medium ">
                                    {`${
                                        data?.data?.claim_content
                                            .referringProviderFirstName || ""
                                    } ${
                                        data?.data?.claim_content
                                            .referringProviderLastName || ""
                                    } ${userInitials(
                                        data?.data?.claim_content
                                            ?.referringProviderMiddleName || "",
                                        ""
                                    )}`}
                                </p>
                            </div>
                            <div className=" text-xs font-semibold ">
                                <p className=" text-gray">
                                    Referring Provider Additional Details
                                </p>
                                <div className="grid grid-cols-3 gap-y-4">
                                    <div className=" text-xs font-semibold">
                                        <span className="text-gray">
                                            {" "}
                                            Additional Id
                                        </span>
                                        <p className="text-sm font-medium ">
                                            {data.data.claim_content
                                                .referringProviderAdditionalId ||
                                                "--"}
                                        </p>
                                    </div>
                                    <div>
                                        <span className="text-gray">
                                            {" "}
                                            Additional Id Qual
                                        </span>
                                        <p className=" text-sm font-medium ">
                                            {data.data.claim_content
                                                .referringProviderAdditionalIdQual ||
                                                "--"}
                                        </p>
                                    </div>
                                    <div>
                                        <span className="text-gray"> Npi</span>
                                        <p className=" text-sm font-medium ">
                                            {data.data.claim_content
                                                .referringProviderNPI || "--"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className=" text-xs font-semibold ">
                                <p className="text-gray">
                                    {" "}
                                    Additional Claim info{" "}
                                    <span className="text-xss ">
                                        (Designated by NUCC)
                                    </span>
                                </p>
                                <p className=" text-sm font-medium ">
                                    {data.data.claim_content
                                        .additionalClaimInfo || "--"}
                                </p>
                            </div>
                            <div className=" text-xs font-semibold ">
                                <p className=" text-gray">
                                    Resubmission details
                                </p>
                                <div className="grid grid-cols-3 gap-y-4">
                                    <div className="text-xs font-semibold ">
                                        <span className="text-gray ">
                                            {" "}
                                            Resubmission Code
                                        </span>
                                        <p className=" text-sm font-medium ">
                                            {data.data.claim_content
                                                .resubmissionCode || "--"}
                                        </p>
                                    </div>
                                    <div className="">
                                        <span className="text-gray">
                                            {" "}
                                            Original ref no
                                        </span>
                                        <p className=" text-sm font-medium ">
                                            {data.data.claim_content
                                                .originalClaimReferenceNumber ||
                                                "--"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="text-xs font-semibold ">
                                <span className="text-gray ">
                                    {" "}
                                    Prior Authorization code
                                </span>

                                <p className=" text-sm font-medium ">
                                    {data.data.claim_content
                                        .priorAuthorizationNumber || "--"}
                                </p>
                            </div>
                        </div>
                    </Card>
                    <Card classNames="p-24 mt-24 uppercase">
                        <h2 className="font-bold  text-sm flex items-center gap-8 pb-24">
                            DIAGNOSIS INFO
                        </h2>
                        <div className="grid grid-cols-3 gap-y-4">
                            <div className=" text-xs font-semibold ">
                                <span className="text-gray"> Outside lab?</span>
                                <p className=" text-sm font-medium ">
                                    {data.data.claim_content
                                        .outsideLabCharge === ""
                                        ? "No"
                                        : "Yes"}
                                </p>
                            </div>
                            {data.data.claim_content.outsideLabCharge ===
                            "" ? null : (
                                <div className=" text-xs font-semibold ">
                                    <span className="text-gray">
                                        Dollar charges
                                    </span>
                                    <p className=" text-sm font-medium ">
                                        {showMoneyInAppFormat(
                                            data.data.claim_content
                                                .outsideLabCharge || "0"
                                        )}
                                    </p>
                                </div>
                            )}
                            <div className=" text-xs font-semibold ">
                                <span className="text-gray">
                                    {" "}
                                    Diagnosis or Nature of Illness or Injury
                                </span>
                                {getDiagnosisCodes()?.map((diagnosis) => (
                                    <p
                                        className="text-sm font-medium "
                                        key={diagnosis?.code}
                                    >
                                        {`${diagnosis?.code} ${diagnosis?.description} `}
                                    </p>
                                )) || "--"}
                            </div>
                            <div className=" text-xs font-semibold ">
                                <span className="text-gray">
                                    {" "}
                                    Diagnosis Pointer
                                </span>
                                {getDiagnosisCodes()?.map((diagnosis) => (
                                    <p
                                        className="text-sm font-medium "
                                        key={diagnosis?.code}
                                    >
                                        {diagnosis?.pointer}
                                    </p>
                                )) || "--"}
                            </div>
                        </div>
                    </Card>
                    <Card classNames="p-24 mt-24 uppercase">
                        <h2 className="font-bold  text-sm flex items-center gap-8 pb-24">
                            SERVICES
                        </h2>
                        <Table
                            columns={columns}
                            data={data?.data?.claim_content?.serviceDetails}
                            cardClass="!border-none !pl-0 !pt-12 !shadow-none"
                            className={styles.table}
                        />
                    </Card>

                    <Card classNames="p-24 mt-24 uppercase">
                        <div className="grid grid-cols-3 gap-y-6 max-[750px]:grid-cols-1">
                            <div>
                                <p className="text-gray text-xs font-semibold ">
                                    Federal Tax I.D Number
                                </p>
                                <p className=" text-sm font-medium ">
                                    {data.data.claim_content.federalTaxId ||
                                        "--"}
                                </p>
                            </div>
                            <div>
                                <p className="text-gray text-xs font-semibold ">
                                    Total charge
                                </p>
                                <p className=" text-sm font-medium ">
                                    {showMoneyInAppFormat(
                                        data.data.claim_content.totalCharge || 0
                                    )}
                                </p>
                            </div>
                            <div>
                                <p className="text-gray text-xs font-semibold ">
                                    Service Facility Location Information
                                </p>
                                <p className=" text-sm font-medium ">
                                    {data.data.claim_content.facilityAddress1 ||
                                        "--"}
                                    {data.data.claim_content.facilityAddress2 ||
                                        ""}
                                    {data.data.claim_content.facilityCity || ""}
                                    {data.data.claim_content.facilityState ||
                                        ""}
                                </p>
                            </div>
                            <div>
                                <p className="text-gray text-xs font-semibold ">
                                    Service Facility NPI
                                </p>
                                <p className=" text-sm font-medium ">
                                    {data.data.claim_content.facilityNPI ||
                                        "--"}
                                </p>
                            </div>
                            <div>
                                <p className="text-gray text-xs font-semibold ">
                                    Service Facility Provider Number
                                </p>
                                <p className=" text-sm font-medium ">
                                    {data.data.claim_content
                                        .facilityProviderNumber || "--"}
                                </p>
                            </div>
                            <div>
                                <p className="text-gray text-xs font-semibold ">
                                    Billing provider info & PH#
                                </p>
                                <p className=" text-sm font-medium ">
                                    {`${
                                        data.data.claim_content
                                            .billingProviderPhone || "--"
                                    } ${
                                        data.data.claim_content
                                            .billingProviderAddress1 || " "
                                    } ${
                                        data.data.claim_content
                                            .billingProviderAddress2 || " "
                                    } ${
                                        data.data.claim_content
                                            .billingProviderCity || " "
                                    } ${
                                        data.data.claim_content
                                            .billingProviderState || " "
                                    } `}
                                </p>
                            </div>
                            <div>
                                <p className="text-gray text-xs font-semibold ">
                                    Billing provider Npi
                                </p>
                                <p className=" text-sm font-medium ">
                                    {data.data.claim_content
                                        .billingProviderNPI || "--"}
                                </p>
                            </div>
                            <div>
                                <p className="text-gray text-xs font-semibold ">
                                    Billing Provider Number
                                </p>
                                <p className=" text-sm font-medium ">
                                    {data.data.claim_content
                                        .billingProviderNumber || "--"}
                                </p>
                            </div>
                            <div>
                                <p className="text-gray text-xs font-semibold ">
                                    Patient’s account number
                                </p>
                                <p className=" text-sm font-medium ">
                                    {data.data.claim_content
                                        .claimReferenceOrPatientAcctNo || "--"}
                                </p>
                            </div>
                            <div>
                                <p className="text-gray text-xs font-semibold ">
                                    amount paid
                                </p>
                                <p className=" text-sm font-medium ">
                                    {showMoneyInAppFormat(
                                        data.data.claim_content.amountPaid || 0
                                    )}
                                </p>
                            </div>
                            <div>
                                <p className="text-gray text-xs font-semibold ">
                                    assignment AcceptED
                                </p>
                                <p className=" text-sm font-medium ">
                                    {data.data.claim_content
                                        .acceptAssignment === "Y"
                                        ? "Yes"
                                        : "No"}
                                </p>
                            </div>
                            <div>
                                <p className="text-gray text-xs font-semibold ">
                                    Signature Of Physician and supplier
                                </p>
                                <p className=" text-sm font-medium ">
                                    {`${
                                        data.data.claim_content
                                            .referringProviderFirstName || ""
                                    } ${
                                        data.data.claim_content
                                            .referringProviderLastName || ""
                                    } ${
                                        data.data.claim_content
                                            .renderingProviderSignatureDate
                                    }`}
                                </p>
                            </div>
                        </div>
                    </Card>
                </Card>
            )}
        </div>
    );
}
