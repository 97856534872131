import { useParams, useSearchParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Card } from "@jhool-io/fe-components";
import { ICreateDraftResponse } from "../../types/claims.types";
import useToast from "../../../../hooks/useToast";
import { useCreateDraftClaim } from "../../hooks/claims.mutations";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import SingleClaim from "../Claim/SingleClaim";
import EditClaims from "../EditClaims/EditClaims";
import { useGetClaim } from "../../hooks/claims.queries";

export default function ClaimsWrapper() {
    const [searchParams, setSearchParams] = useSearchParams();

    const params = useParams();
    const { noteId } = params;
    const { data, isLoading, error } = useGetClaim(
        { note_id: noteId },
        Boolean(noteId)
    );
    const view = searchParams.get("claims_view");

    const queryClient = useQueryClient();
    // Toast for success and error states
    const { toast } = useToast();
    const [claimCreated, setClaimCreated] = useState(false);
    const [createdClaimData, setClaimCreatedData] =
        useState<ICreateDraftResponse>();

    // hook for updating claim status
    const createADraftClaim = useCreateDraftClaim();

    // function to change claim status
    const handleGenerateClaim = () => {
        const createDraftPayload = {
            note_id: noteId as string,
        };
        createADraftClaim.mutate(createDraftPayload, {
            onSuccess: (response) => {
                queryClient.invalidateQueries([
                    "get-claim",
                    response.data.claim.claim_id,
                ]);
                toast({
                    mode: "success",
                    message: `Claim created successfully`,
                });
                searchParams.set("status", response.data.claim.claim_status);
                searchParams.set("claim_id", response.data.claim.claim_id);
                setClaimCreated(true);
                setClaimCreatedData(response.data);
                setSearchParams(searchParams);
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not generate claim at this time",
                });
            },
        });
    };

    return (
        <div>
            {isLoading && (
                <Card classNames="flex flex-col gap-y-12 h-[300px] justify-center items-center">
                    <Skeleton height={30} width="50%" />
                    <Skeleton height={30} width="50%" className="!mt-0" />
                    <Skeleton className="h-[50px] w-[20%]" />
                </Card>
            )}
            {(claimCreated && createdClaimData?.claim?.claim_id) || data ? (
                <div>
                    {view === "edit" ? (
                        <EditClaims />
                    ) : (
                        <SingleClaim
                            claim_status={data?.data?.claim_status as string}
                            claim_ID={
                                data?.data?.claim_id ||
                                createdClaimData?.claim.claim_id
                            }
                        />
                    )}
                </div>
            ) : (
                <div>
                    {error?.response?.status === 404 && (
                        <ListState
                            isError={false}
                            stateHelperText="Click the button to generate a new claim"
                            emptyMessage="No claim has been generated for this bill"
                            emptyBtnProps={{
                                showButton: true,
                                buttonText: "Generate Claims",
                                onButtonClick: handleGenerateClaim,
                                disabled: createADraftClaim.isLoading,
                            }}
                        />
                    )}
                </div>
            )}
        </div>
    );
}
