import { format } from "date-fns";
import axios from "../../../utils/axios";
import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";
import {
    IAddNoteMessage,
    IDeleteNoteMessage,
    IFlagNote,
    INotesListFilterTypes,
    ISuperviseeNotesListFilters,
} from "../types/notes.types";

// Endpoint to fetch notes list
export async function fetchNotesList(
    filters: INotesListFilterTypes,
    providerId: string
) {
    const response = await axios.get(`/notes`, {
        params: handleRemoveEmptyParamFromQueryParams({
            provider_id: providerId,
            ...filters,
            from_date: filters.from_date
                ? format(filters.from_date, "yyyy-MM-dd")
                : null,
            to_date: filters.to_date
                ? format(filters.to_date, "yyyy-MM-dd")
                : null,
        }),
    });
    return response.data;
}

// Endpoint to supervisee notes list
export async function fetchSuperviseeNotesList(
    providerId: string,
    filters: ISuperviseeNotesListFilters
) {
    const response = await axios.get(
        `/session-note/${providerId}/supervisees`,
        {
            params: handleRemoveEmptyParamFromQueryParams({
                ...filters,
                from_date: filters.from_date
                    ? format(filters.from_date, "yyyy-MM-dd")
                    : null,
                to_date: filters.to_date
                    ? format(filters.to_date, "yyyy-MM-dd")
                    : null,
            }),
        }
    );
    return response.data;
}

// Endpoint to delete note message
export async function deleteNoteMessage(
    noteId: string,
    data: IDeleteNoteMessage
) {
    const response = await axios.delete(`note/${noteId}/messages`, {
        data,
    });
    return response.data;
}

// Endpoint to add message to notes
export async function addNoteMessages(noteId: string, data: IAddNoteMessage) {
    const response = await axios.post(`/note/${noteId}/messages`, data);
    return response.data;
}

export async function fetchMessages(noteId: string, search_keyword?: string) {
    const response = await axios.get(`note/${noteId}/messages`, {
        params: {
            search_keyword,
        },
    });
    return response.data;
}

// Endpoint to fetch note audit dashboard list
export async function fetchNoteAuditDashboardList(
    custom_date: string,
    sort_attribute?: string,
    sort_order?: string
) {
    const response = await axios.get(`/dashboard/note-audit`, {
        params: handleRemoveEmptyParamFromQueryParams({
            custom_date,
            sort_attribute,
            sort_order,
        }),
    });
    return response.data;
}

export async function fetchNotesChangesRequest(
    noteId: string,
    limit: number,
    page: number
) {
    const response = await axios.get(
        `/client/session-note/${noteId}/change-request`,
        {
            params: {
                limit,
                page,
            },
        }
    );
    return response.data;
}

// Endpoint to flag note
export async function flagNote(data: IFlagNote) {
    const response = await axios.post(
        `/client/session-note/flag-for-audit`,
        data
    );
    return response.data;
}
