import { useIsMutating } from "@tanstack/react-query";
import cn from "classnames";
import * as React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Button, Dialog, DialogContent } from "@jhool-io/fe-components";
import {
    useFetchClientFees,
    useFetchClientInsuranceHistory,
} from "../../../../hooks/queries/client";
import FeeStructure from "../../../FeeStructure/FeeStructure";
import ListState from "../../../ListState/ListState";
import Skeleton from "../../../Skeleton/Skeleton";
import AddFees from "./AddFees/AddFees";
import styles from "./Fees.module.scss";
import { formatDate } from "../../../../utils/helpers";
import AddIcon from "../../../Icons/AddIcon";
import CalculateFees from "./CalculateFees/CalculateFees";
import usePractice from "../../../../hooks/usePractice";
import { UserRole } from "../../../../modules/user-management/types/user-management.types";

export default function Fees() {
    // Local component states
    const [searchParams, setSearchParams] = useSearchParams();
    const [showAddFeesModal, setShowAddFeesModal] = React.useState(false);
    const [showCalculateFeesModal, setShowCalculateFeesModal] =
        React.useState(false);

    // Get id from url params
    const params = useParams();
    const clientId = params.clientId as string;
    const currentLimit = Number(searchParams.get("limit")) || 3;
    const { practice } = usePractice();

    const canAddFees = () => {
        if (
            practice?.roles.includes(UserRole.SUPER_ADMIN) ||
            practice?.roles.includes(UserRole.BILLER_ADMIN)
        ) {
            return true;
        }

        return false;
    };
    // Fetch client fees
    const { data, isLoading, isSuccess, error, isRefetching } =
        useFetchClientFees(clientId, currentLimit);

    // To determine if mutation is currently running, returns 0 || 1
    const isMutating = useIsMutating(["add-fee", clientId]);
    const isCalculateMutating = useIsMutating(["calculate-fee"]);

    const handleToggleShowAddFeesModal = () => {
        setShowAddFeesModal((state) => !state);
    };

    const handleToggleShowCalculateFeesModal = () => {
        setShowCalculateFeesModal((state) => !state);
    };

    const handleLimitChange = () => {
        if (data?.total_pages === data?.current_page) return;

        searchParams.set("limit", String(currentLimit + 2));
        setSearchParams(searchParams);
    };

    // Fetch client insurance history
    useFetchClientInsuranceHistory(clientId, 1, 50);

    return (
        <>
            <Dialog open={showAddFeesModal}>
                <DialogContent
                    title="Add fees"
                    variant="center"
                    handleCloseDialog={handleToggleShowAddFeesModal}
                    saveText="Add fees"
                    showFooter
                    submitBtnFormValue="add-fees"
                    isBackBtnDisabled={isMutating > 0}
                    isSubmitBtnDisabled={isMutating > 0}
                >
                    <AddFees onFormSubmit={() => setShowAddFeesModal(false)} />
                </DialogContent>
            </Dialog>

            <Dialog open={showCalculateFeesModal}>
                <DialogContent
                    title="Calculate fees"
                    variant="center"
                    handleCloseDialog={handleToggleShowCalculateFeesModal}
                    saveText="Calculate"
                    showFooter
                    submitBtnFormValue="calculate-fees"
                    isBackBtnDisabled={isCalculateMutating > 0}
                    isSubmitBtnDisabled={isCalculateMutating > 0}
                >
                    <CalculateFees />
                </DialogContent>
            </Dialog>

            {(isLoading || isRefetching) && (
                <div className={styles.skeleton}>
                    <div className={styles.skeleton_header}>
                        <Skeleton containerTestId="fees-loader" />
                        <Skeleton containerTestId="fees-loader" />
                    </div>
                    <div className={styles.skeleton_header}>
                        <Skeleton containerTestId="fees-loader" />
                        <Skeleton containerTestId="fees-loader" />
                    </div>
                    <div className={styles.skeleton_main}>
                        <Skeleton containerTestId="fees-loader" />
                        <Skeleton containerTestId="fees-loader" />
                        <Skeleton containerTestId="fees-loader" />
                        <Skeleton containerTestId="fees-loader" />
                    </div>
                    <div className={styles.skeleton_footer}>
                        <Skeleton containerTestId="fees-loader" />
                        <Skeleton containerTestId="fees-loader" />
                    </div>
                </div>
            )}
            {error && error?.response?.status !== 404 && (
                <>
                    <div className={styles.headerwrapper}>
                        <h3 className={styles.header}>FEES HISTORY</h3>
                    </div>
                    <ListState
                        errorMsg={
                            error?.response?.data.message ||
                            `Cannot display client fees at this time please try again later`
                        }
                        stateHelperText="Try reloading this page to solve this issue"
                        isError
                    />
                </>
            )}
            {data && isSuccess && data.data.length === 0 && (
                <>
                    <div className={styles.headerwrapper}>
                        <h3 className={cn(styles.header, "fs-exclude")}>
                            FEES HISTORY
                        </h3>
                    </div>
                    <ListState
                        stateHelperText="Client fees will appear here when added"
                        isError={false}
                        emptyMessage="No fee history yet"
                        emptyBtnProps={{
                            showButton: canAddFees(),
                            buttonText: "Add fee",
                            onButtonClick: handleToggleShowAddFeesModal,
                        }}
                    />
                </>
            )}
            {isSuccess && data && data.data.length > 0 && (
                <div className={styles.fees}>
                    <div className="rounded-r8 bg-white border border-stroke-divider px-12 py-24 md:p-24 flex justify-between gap-x-8 items-center mb-16">
                        <h3 className="text-base font-bold leading-normal">
                            FEES HISTORY
                        </h3>
                        <div className="flex gap-x-8">
                            {/* <Button
                                onClick={handleToggleShowCalculateFeesModal}
                            >
                                Calculate fees
                            </Button> */}
                            {canAddFees() && (
                                <>
                                    <Button
                                        className="hidden md:flex"
                                        onClick={handleToggleShowAddFeesModal}
                                    >
                                        Add fees
                                    </Button>
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        onClick={handleToggleShowAddFeesModal}
                                        className="flex md:hidden justify-content items-center rounded-full size-32 bg-[#576a81c]"
                                    >
                                        <AddIcon className="size-[15px]" />
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>
                    <FeeStructure
                        fee={data.data[0]}
                        defaultIsMaximizedValue
                        showMaximizeButton={false}
                        isCurrentFee
                        titleText="CURRENT FEES"
                        titleDate={`CAL. YR ${data.data[0].cal_year}`}
                        showEditButton={canAddFees()}
                    />

                    <div className={styles.fees_history}>
                        {data.data.slice(1, currentLimit).map((fee, i) => (
                            <FeeStructure
                                key={fee.fee_id}
                                fee={fee}
                                defaultIsMaximizedValue={false}
                                showMaximizeButton
                                titleText="Date Calculated"
                                titleDate={formatDate(
                                    data.data[i].created_at || "--"
                                )}
                                showEditButton={false}
                            />
                        ))}
                        {data.data.length > 1 && (
                            <div
                                className={cn(styles.pagination, "fs-exclude")}
                            >
                                <div className={styles.pagination_text}>
                                    Showing <span>{data.count - 1}</span> OF{" "}
                                    {data.total_count - 1} ROWS
                                </div>
                                <Button
                                    variant="secondary"
                                    disabled={
                                        data?.total_pages ===
                                            data?.current_page || isLoading
                                    }
                                    onClick={handleLimitChange}
                                >
                                    Load more
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
