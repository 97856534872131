import * as React from "react";
import cn from "classnames";
import usePagination from "../../hooks/usePagination/usePagination";
import Button from "../Button/Button";
import ArrowDownIcon from "../Icons/ArrowDownIcon";
import ChevronLeftIcon from "../Icons/ChevronLeft";
import ChevronRightIcon from "../Icons/ChevronRight";
import DotsHorizontalIcon from "../Icons/DotsHorizontal";
import styles from "./Pagination.module.scss";
import DropdownMenu, {
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "../DropdownMenu/DropdownMenu";

interface PaginationProps {
    /** Total amount of data */
    totalCount: number;
    /** Total amount of pages */
    totalPages: number;
    /** Current page in view */
    currentPage: number;
    /** Function to change page in view */
    onPageChange(page: number): void;
    /** Page size */
    pageSize?: number;
    /** Should pagination be centered */
    isCentered?: boolean;
    /** Function to change list limit */
    onLimitChange?(limit: number): void;
    /** Page limit */
    limit?: number;
    /** Limit dropdown zindex */
    zIndex?: number;
}

export default function Pagination({
    totalCount,
    totalPages,
    currentPage,
    onPageChange,
    pageSize,
    onLimitChange,
    limit = 20,
    isCentered = false,
    zIndex,
}: PaginationProps) {
    const paginationRange = usePagination(
        totalCount,
        pageSize || limit,
        currentPage,
        1
    );

    const handleGoToPrevPage = () => {
        if (currentPage <= 1) return;
        onPageChange(currentPage - 1);
    };

    const handleGoToNextPage = () => {
        if (totalPages === currentPage) return;
        onPageChange(currentPage + 1);
    };

    const handleGoToPage = (page: number) => {
        if (page > totalPages || page < 1) return;
        onPageChange(page);
    };

    const handleSetLimit = (limitValue: number) => {
        onLimitChange?.(limitValue);
    };

    return (
        <div
            className={cn(styles.pg, "fs-exclude", {
                [styles.pg_centered]: isCentered,
            })}
            aria-label="pagination"
        >
            <div
                className={cn(styles.pg_left, {
                    [styles.pg_left_center]: isCentered,
                })}
            >
                <span className={styles.pg_text}>Showing</span>
                <DropdownMenu>
                    <DropdownMenuTrigger
                        aria-label="trigger"
                        className={styles.pg_currentcount}
                    >
                        {limit} <ArrowDownIcon />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent style={{ zIndex }}>
                        <div className={styles.pg_counts}>
                            {[20, 50, 100].map((value) => (
                                <DropdownMenuItem key={value}>
                                    <Button
                                        mode="transparent"
                                        size="auto"
                                        type="button"
                                        onClick={() => {
                                            handleSetLimit(value);
                                        }}
                                        className={styles.pg_count}
                                    >
                                        {value}
                                    </Button>
                                </DropdownMenuItem>
                            ))}
                        </div>
                    </DropdownMenuContent>
                </DropdownMenu>
                <span className={styles.pg_text}>rows per page</span>
            </div>
            <div className={styles.pg_right}>
                <Button
                    className={cn(styles.pg_navbtn, styles.pg_prevbtn, {
                        [styles.pg_prevbtn_addmargin]: totalPages < 3,
                    })}
                    mode="transparent"
                    size="auto"
                    type="button"
                    disabled={currentPage === 1}
                    onClick={handleGoToPrevPage}
                    ariaLabel="previous button"
                >
                    <ChevronLeftIcon />
                    prev
                </Button>
                {totalPages >= 3 && (
                    <div className={styles.pg_pagebtns}>
                        {paginationRange &&
                            paginationRange.map((pageNumber, i) => {
                                // If the pageItem is a DOT, render the dots icon
                                if (pageNumber === "DOTS") {
                                    return (
                                        <DotsHorizontalIcon
                                            key={`${pageNumber}-${i + 1}`}
                                        />
                                    );
                                }

                                // Render our page pills
                                return (
                                    <Button
                                        key={pageNumber}
                                        className={cn(styles.pg_pagebtn, {
                                            [styles.pg_pageactive]:
                                                pageNumber === currentPage,
                                        })}
                                        mode="transparent"
                                        size="auto"
                                        type="button"
                                        onClick={() =>
                                            handleGoToPage(Number(pageNumber))
                                        }
                                        ariaLabel="page button"
                                    >
                                        {pageNumber}
                                    </Button>
                                );
                            })}
                    </div>
                )}
                <Button
                    className={cn(styles.pg_navbtn, styles.pg_nextbtn)}
                    mode="transparent"
                    size="auto"
                    type="button"
                    onClick={handleGoToNextPage}
                    disabled={currentPage === totalPages}
                    ariaLabel="next button"
                >
                    next
                    <ChevronRightIcon />
                </Button>
            </div>
        </div>
    );
}
