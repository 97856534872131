export enum NoteTypes {
    INDIVIDUAL_INTAKE_NOTE = "individual_intake_note",
    MINOR_INTAKE_NOTE = "minor_intake_note",
    FAMILY_INTAKE_NOTE = "family_intake_note",
    FAMILY_TREATMENT_REVIEW = "family_treatment_review",
    INDIVIDUAL_TREATMENT_REVIEW = "individual_treatment_review",
    INDIVIDUAL_SOAP_NOTE = "individual_soap_note",
    FAMILY_SOAP_NOTE = "family_soap_note",
    RECORD_OF_DISCLOSURE = "record_of_disclosure",
    CANCELLATION_NOTE = "cancellation_note",
    SUPERVISION_NOTE = "supervision_note",
    TERMINATION_NOTE = "termination_note",
    SAFETY_PLAN = "safety_plan",
    GENERAL_UPDATE_NOTE = "general_update_note",
    SUPERVISOR_CHECK_IN = "supervisor_check_in",
}

interface INoteLabels {
    [key: string]: string;
}

export const NoteLabels: INoteLabels = {
    individual_intake_note: "Individual intake note",
    family_intake_note: "Family intake note",
    // Leaving this for old notes currently on the app
    treatment_review: "Individual treatment review",
    individual_treatment_review: "Individual treatment review",
    family_treatment_review: "Family treatment review",
    individual_soap_note: "Individual soap note",
    family_soap_note: "Family soap note",
    record_of_disclosure: "Record of disclosure",
    cancellation_note: "No show/late cancel",
    supervision_note: "Supervision note",
    termination_note: "Termination note",
    safety_plan: "Safety plan",
    general_update_note: "General update note",
    supervisor_check_in: "Supervisor check-in note",
    minor_intake_note: "Minor intake note",
};

export enum NoteStatus {
    CODED = "coded",
    DRAFT = "draft",
    PENDING_SUPERVISOR_REVIEW = "pending_supervisor_review",
    CHANGES_REQUESTED = "changes_requested",
    PENDING_CODE_REVIEW = "pending_code_review",
}

export const CPT_CODES = [
    "90847",
    "99449",
    "90837",
    "90834",
    "90846",
    "90832",
    "90791",
    "99448",
];

export enum SessionServiceType {
    CHILD_THERAPY = "child_therapy",
    FAMILY_THERAPY = "family_therapy",
    INDIVIDUAL_THERAPY = "individual_therapy",
    INTUITIVE_EATING = "intuitive_eating",
    MEDICATION_MANAGEMENT = "medication_management",
    PSYCHIATRIC_COUNSELING = "psychiatric_counseling",
    MENTAL_HEALTH_ASSESSMENT = "mental_health_assessment",
    TELETHERAPY = "teletherapy",
}

export const noteTypesForExport = [
    NoteTypes.INDIVIDUAL_SOAP_NOTE,
    NoteTypes.INDIVIDUAL_INTAKE_NOTE,
    NoteTypes.FAMILY_INTAKE_NOTE,
    NoteTypes.SAFETY_PLAN,
];

export interface INote {
    note_id: string;
    provider?: {
        first_name: string;
        last_name: string;
        user_id?: string;
    };
    date_of_service: string | null;
    note_type: NoteTypes;
    note_url?: string;
    client: {
        client_id: string;
        first_name: string;
        last_name: string;
        insurance_provider_name?: string;
        self_pay?: boolean;
    };
    cpt_code: string;
    status: NoteStatus;
    modifier_codes?: string[];
    is_archived?: boolean;
    session_history_id: string;
    appointment_type: SessionServiceType;
    created_at: string;
}

export interface ISuperviseeNote {
    note_id: string;
    date_of_service: string | null;
    note_type: NoteTypes;
    cpt_code: string;
    status: NoteStatus;
    provider: {
        first_name: string;
        last_name: string;
        user_id: string;
    };
    client: {
        client_id: string;
        first_name: string;
        last_name: string;
        insurance_provider_name?: string;
        self_pay?: boolean;
    };
    is_archived?: boolean;
    session_history_id: string;
    appointment_type: SessionServiceType;
    created_at: string;
}

export interface INotesListFilterTypes {
    client_name?: string;
    status?: string[];
    type?: string[];
    cpt_code?: string;
    from_date?: null | Date;
    to_date?: null | Date;
    page?: number;
    provider_name?: string;
    limit?: number;
    show_archived?: string;
    sort_attribute?: string;
    sort_order?: string;
    client_id?: string;
    insurance_provider_id?: string;
    is_flagged_for_audit?: boolean;
}

export interface ISuperviseeNotesListFilters {
    provider_name: string;
    cpt_code?: string;
    type: string[];
    from_date: null | Date;
    to_date: null | Date;
    page: number;
    status: string[];
    limit: number;
    show_archived?: string;
}
export interface IGetMessages {
    message_id: string;
    message: string;
    sender_user_id: string;
    sender_first_name: string;
    sender_last_name: string;
    is_public: boolean;
    sent_time: string;
    recipient_user_id: string;
    recipient_first_name: string;
    recipient_last_name: string;
}

export interface IDeleteNoteMessage {
    message_id: string;
}
export interface IDeleteNoteMessageResponse {
    deleted: boolean;
}
export interface IAddNoteMessage {
    message: string;
    is_public: boolean;
    recipient_user_id?: string;
}
export interface IAddNoteMessageResponse {
    message_id: string;
    message: string;
    sender_user_id: string;
    sender_first_name: string;
    sender_last_name: string;
    is_public: boolean;
    sent_time: string;
}

export interface INoteAuditDashboardList {
    date_of_service: string;
    overdue_period_secs: number;
    session_service: SessionServiceType;
    note_status: NoteStatus;
    note_id: string;
    provider: {
        id: string;
        first_name: string;
        last_name: string;
        credential: string;
    };
    supervisor: {
        id: string;
        first_name: string;
        last_name: string;
    };
    client: {
        id: string;
        first_name: string;
        last_name: string;
    };
    author: {
        id: string;
        first_name: string;
        last_name: string;
    };
}

export interface INoteAuditDashboardResponse {
    from_date: string;
    to_date: string;
    summary: {
        overdue_notes_count: number;
        average_time_to_sign_note_secs: number;
        average_time_to_complete_first_review_secs: number;
    };
    overdue_notes: INoteAuditDashboardList[];
}

export interface IRequestDetails {
    request_id: string;
    request_details: string;
    request_reason: string;
    request_datetime: string;
    requester: {
        id: string;
        first_name: string;
        last_name: string;
    };
}

export interface IFlagNote {
    note_ids_to_flag_for_audit: string[];
    flag_for_audit_status: boolean;
    flag_for_audit_notes?: string;
}
