import moment from "../moment";
import {
    DateFormats,
    IAvailabilitySchedule,
    IClientBooking,
    IReservedSchedule,
    RecurrenceRule,
} from "../types/availiability";

export const getNowEquivalent = (date: moment.Moment): moment.Moment => {
    return moment().day(date.day()).hour(date.hour()).minute(0).second(0);
};

export const getStringFromScheduleDate = (date?: string | Date): string => {
    if (!date) {
        return "";
    }
    try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (date as any).toUTCString();
    } catch {
        return date as string;
    }
};

export const findAvailabilityConflicts = (
    availabilitySchedules: IAvailabilitySchedule[],
    heldBookingSchedules: IAvailabilitySchedule[],
    confirmedBookingSchedules: IAvailabilitySchedule[],
    schedule: IAvailabilitySchedule | IReservedSchedule
) => {
    let potentialConflicts = [
        ...availabilitySchedules,
        ...heldBookingSchedules,
        ...confirmedBookingSchedules,
    ].filter((a) => {
        const availableWindowStart = getNowEquivalent(
            moment(getStringFromScheduleDate(a.start))
        );
        const scheduleWindowStart = getNowEquivalent(
            moment(getStringFromScheduleDate(schedule.start))
        );
        return (
            a.id &&
            schedule.id &&
            a.id !== schedule.id &&
            availableWindowStart.day() === scheduleWindowStart.day() &&
            availableWindowStart.hour() === scheduleWindowStart.hour() &&
            (schedule.recurrenceRule === RecurrenceRule.BIWEEKLY
                ? moment(a.start).format(DateFormats.DAY_MONTH_FORMAT) ===
                  moment(schedule.start).format(DateFormats.DAY_MONTH_FORMAT)
                : true)
        );
    });

    // Check for one-off appointments.
    const scheduleStartDate = schedule.starting_date || schedule.start;
    const scheduleStartDateMoment = moment(scheduleStartDate).startOf("day");
    potentialConflicts = potentialConflicts.filter((c) => {
        const conflictStartDate = c.starting_date || c.start;
        if (
            c.recurrenceRule === RecurrenceRule.ONE_OFF &&
            conflictStartDate &&
            scheduleStartDate
        ) {
            const conflictStartDateMoment =
                moment(conflictStartDate).startOf("day");
            if (schedule.recurrenceRule === RecurrenceRule.ONE_OFF) {
                return scheduleStartDateMoment.isSame(
                    conflictStartDateMoment,
                    "dates"
                );
            }
            return scheduleStartDateMoment.isSameOrBefore(
                conflictStartDateMoment,
                "dates"
            );
        }
        return true;
    });
    const hasConflicts = potentialConflicts.some(
        (c) => !(c as IClientBooking).is_paused
    );
    return hasConflicts;
};

export const getMatchingOfficeWindow = (
    officeWindowSchedules: IAvailabilitySchedule[],
    schedule: IAvailabilitySchedule | IReservedSchedule
) => {
    return officeWindowSchedules.find((w) => {
        const windowStart = moment(getStringFromScheduleDate(w.start));
        const windowEnd = moment(getStringFromScheduleDate(w.end));
        const relativeDateStart = getNowEquivalent(
            moment(getStringFromScheduleDate(schedule.start))
        );
        const relativeDateEnd = getNowEquivalent(
            moment(getStringFromScheduleDate(schedule.end))
        );
        const start = getNowEquivalent(windowStart);
        const end = getNowEquivalent(windowEnd);

        return (
            relativeDateStart.isBetween(start, end, "hours", "[]") &&
            relativeDateEnd.isBetween(start, end, "hours", "[]")
        );
    });
};
