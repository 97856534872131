import * as React from "react";
import { UserPracticeContext } from "../context/user-practice/UserPracticeContext";

export default function usePractice() {
    const practiceContext = React.useContext(UserPracticeContext);

    const practice = practiceContext?.userCurrentPractice;
    const handleChangeUserPractice = practiceContext?.handleChangeUserPractice;

    return {
        practice,
        handleChangeUserPractice,
    };
}
