import { Dialog, DialogContent, Button } from "@jhool-io/fe-components";
import * as React from "react";
import { useParams } from "react-router-dom";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import EditInsuranceDetails from "./EditInsuranceDetails/EditInsuranceDetails";
import { SupportedUploadMethod } from "../../../../../utils/types/filepicker";
import useToast from "../../../../../hooks/useToast";
import { formatDate, removeEnumUnderscore } from "../../../../../utils/helpers";
import InsuranceDocument from "./InsuranceDocument/InsuranceDocument";
import { getStateInShortForm } from "../../../../../utils/helpers/us-states/us-states";
import Progress from "../../../../../components/Progress/Progress";
import FilePicker from "../../../../../components/FilePicker/FilePicker";
import TrashIcon from "../../../../../components/Icons/Trash";
import {
    IClientInsuranceAttachment,
    IClientInsuranceDetails,
} from "../../../types/clients.types";
import { useDeleteClientInsuranceAttachment } from "../../../hooks/clients.mutations";
import ZoomInIcon from "../../../../../components/Icons/ZoomIn";
import ZoomOutIcon from "../../../../../components/Icons/ZoomOut";

interface InsuranceDetailsProps {
    insuranceDetail: IClientInsuranceDetails;
}

enum FormTypes {
    EDIT_INSURANCE = "edit-insurance",
    ADD_DOCUMENT = "add-document",
}

type SubmitTypes = "save" | "save-continue";

export default function InsuranceDetails({
    insuranceDetail,
}: InsuranceDetailsProps) {
    // Local component states
    const [formInView, setFormInView] = React.useState<FormTypes | null>(null);
    const [progressStep, setProgressStep] = React.useState(
        FormTypes.EDIT_INSURANCE
    );
    const [submitType, setSubmitType] = React.useState<SubmitTypes | null>(
        null
    );
    const [documentToDeleteId, setDocumentToDeleteId] = React.useState<
        string | null
    >(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] =
        React.useState(false);
    const [attachmentInView, setAttachmentInView] =
        React.useState<IClientInsuranceAttachment | null>(null);

    const [scale, setScale] = React.useState(1.2);

    const zoomIn = () => {
        if (scale < 3) {
            setScale((prevScale) => prevScale + 0.2);
        }
    };

    const zoomOut = () => {
        if (scale > 1) {
            setScale((prevScale) => prevScale - 0.2);
        }
    };

    // Get id from url params
    const params = useParams();
    const clientId = params.clientId as string;

    // Query client
    const queryClient = useQueryClient();

    // Hook for toast
    const { toast } = useToast();

    // To determine if mutation is currently running, returns 0 || 1
    const isMutating = useIsMutating(["edit-insurance", clientId]);

    // Delete client document hook
    const deleteDocument = useDeleteClientInsuranceAttachment(
        clientId,
        documentToDeleteId || ""
    );

    const handleDocumentinView = (
        attachment: IClientInsuranceAttachment | null
    ) => {
        setAttachmentInView(attachment);
    };

    const handleSetFormInView = (form: FormTypes | null) => {
        setFormInView(form);
    };

    // Function for deleting document
    const handleDeleteDocument = () => {
        deleteDocument.mutate(null, {
            onSuccess: (res) => {
                queryClient.invalidateQueries({
                    queryKey: [clientId, `insurance`],
                });

                toast({
                    mode: "success",
                    message:
                        res.message ||
                        "Insurance attachment deleted successfully!",
                });
                setDocumentToDeleteId(null);
                setShowDeleteConfirmation(false);
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not delete insurance attachment at this time",
                });
            },
        });
    };

    return (
        <>
            <Dialog
                open={
                    formInView === FormTypes.EDIT_INSURANCE ||
                    formInView === FormTypes.ADD_DOCUMENT
                }
            >
                <DialogContent
                    showFooter={formInView === FormTypes.EDIT_INSURANCE}
                    variant="center"
                    title={
                        formInView === FormTypes.EDIT_INSURANCE
                            ? "Edit insurance"
                            : "Add Document"
                    }
                    submitBtnFormValue="edit-insurance"
                    cancelBtnFormValue="edit-insurance"
                    saveText="Save and continue"
                    cancelText="Save changes"
                    isCancelBtnDisabled={isMutating > 0}
                    isSubmitBtnDisabled={isMutating > 0}
                    handleCloseDialog={() => handleSetFormInView(null)}
                    onSaveClick={() => {
                        setSubmitType("save-continue");
                    }}
                    canCancelButtonSubmit
                    onCancelClick={() => setSubmitType("save")}
                >
                    <div>
                        <Progress
                            steps={Object.values(FormTypes)}
                            currentStep={progressStep}
                            className="top-[75px]"
                        />
                        <div className="pt-20">
                            {formInView === FormTypes.EDIT_INSURANCE && (
                                <EditInsuranceDetails
                                    insuranceDetail={insuranceDetail}
                                    onFormSubmit={() => {
                                        if (submitType === "save-continue") {
                                            setProgressStep(
                                                FormTypes.ADD_DOCUMENT
                                            );
                                            handleSetFormInView(
                                                FormTypes.ADD_DOCUMENT
                                            );
                                        } else setFormInView(null);
                                    }}
                                />
                            )}
                            {formInView === FormTypes.ADD_DOCUMENT && (
                                <FilePicker
                                    name="filepicker"
                                    uploadMethods={[
                                        {
                                            method: SupportedUploadMethod.manualupload,
                                        },
                                        {
                                            method: SupportedUploadMethod.googledrive,
                                        },
                                        {
                                            method: SupportedUploadMethod.dropbox,
                                        },
                                    ]}
                                    uploadType="insurance-file"
                                    insuranceId={insuranceDetail.insurance_id}
                                    successCallback={() => {
                                        handleSetFormInView(null);
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog open={showDeleteConfirmation}>
                <DialogContent
                    title="Delete attachment"
                    saveText="Delete"
                    cancelText="Cancel"
                    onSaveClick={handleDeleteDocument}
                    isSubmitBtnDisabled={deleteDocument.isLoading}
                    isCancelBtnDisabled={deleteDocument.isLoading}
                    variant="center"
                    handleCloseDialog={() => {
                        setDocumentToDeleteId(null);
                        setShowDeleteConfirmation(false);
                    }}
                    isDeleting
                >
                    <div>
                        <h3 className="text-xl font-medium mb-8">
                            Are you sure you want to delete attachment?
                        </h3>
                        <p className="text-base text-gray font-normal">
                            Are you sure you want to delete this attachment?
                            This action cannot be reversed.
                        </p>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={Boolean(attachmentInView) && attachmentInView !== null}
            >
                <DialogContent
                    handleCloseDialog={() => {
                        handleDocumentinView(null);
                        setScale(1.2);
                    }}
                    variant="center"
                    showFooter={false}
                    wrapperClass="h-[90dvh] w-[900px]"
                >
                    {attachmentInView &&
                    attachmentInView?.metadata &&
                    /^image/.test(attachmentInView?.metadata?.content_type) ? (
                        <>
                            <div className="w-full h-4/5 overflow-scroll flex justify-center items-center">
                                <div
                                    className="transition-transform duration-300 ease-in-out"
                                    style={{
                                        transform: `scale(${scale})`,
                                    }}
                                >
                                    <InsuranceDocument
                                        attachmentInView={
                                            attachmentInView || undefined
                                        }
                                        attachmentUrl={
                                            attachmentInView?.url || ""
                                        }
                                    />
                                </div>
                            </div>
                            <div className="flex justify-center items-center mt-6">
                                <Button
                                    variant="normal"
                                    onClick={zoomIn}
                                    disabled={scale >= 3}
                                >
                                    <ZoomInIcon />
                                </Button>

                                <Button
                                    variant="normal"
                                    onClick={zoomOut}
                                    disabled={scale <= 1.2}
                                >
                                    <ZoomOutIcon />
                                </Button>
                            </div>
                        </>
                    ) : (
                        <InsuranceDocument
                            attachmentInView={attachmentInView || undefined}
                            attachmentUrl={attachmentInView?.url || ""}
                        />
                    )}
                </DialogContent>
            </Dialog>
            <div>
                <div className="flex items-center justify-between mb-28 py-24 px-32 border-b border-b-strokedark">
                    <h2 className="text-xxl font-bold">Insurance Details</h2>
                    <Button
                        aria-label="Edit Button"
                        onClick={() => {
                            handleSetFormInView(FormTypes.EDIT_INSURANCE);
                        }}
                    >
                        Edit
                    </Button>
                </div>
                <div className=" mx-32">
                    <div className="bg-secondary-light p-12 rounded-[16px]">
                        <div className="grid grid-cols-[repeat(auto-fit,minmax(20rem,1fr))] gap-x-[64px] gap-y-24 bg-white p-16 rounded-[16px] uppercase">
                            <div>
                                <div className="section-info">
                                    PROVIDER&rsquo;s NAME
                                </div>
                                <div className="section-value">
                                    {insuranceDetail.name}
                                </div>
                            </div>
                            <div>
                                <div className="section-info">Is Cal Year</div>
                                <div className="section-value">
                                    {insuranceDetail.is_cal_year ? "Yes" : "No"}
                                </div>
                            </div>
                            <div>
                                <div className="section-info">start date</div>
                                <div className="section-value">
                                    {insuranceDetail.start_date
                                        ? formatDate(
                                              insuranceDetail.start_date,
                                              true
                                          )
                                        : "--"}
                                </div>
                            </div>
                            <div>
                                <div className="section-info">end date</div>
                                <div className="section-value">
                                    {insuranceDetail.end_date
                                        ? formatDate(
                                              insuranceDetail.end_date,
                                              true
                                          )
                                        : "--"}
                                </div>
                            </div>
                            <div>
                                <span className="section-info block">
                                    Insurance type
                                </span>
                                <span className="section-value block">
                                    {insuranceDetail.insurance_type
                                        ? removeEnumUnderscore(
                                              insuranceDetail.insurance_type
                                          )
                                        : "--"}
                                </span>
                            </div>
                            <div>
                                <div className="section-info">
                                    Employer name
                                </div>
                                <div className="section-value">
                                    {insuranceDetail.employer_name}
                                </div>
                            </div>
                            <div>
                                <div className="section-info">
                                    INSURANCE MEMBER ID
                                </div>
                                <div className="section-value">
                                    {insuranceDetail.insurance_member_id}
                                </div>
                            </div>
                            <div>
                                <div className="section-info">Status</div>
                                <div className="section-value">
                                    {insuranceDetail.status || "--"}
                                </div>
                            </div>
                            <div>
                                <div className="section-info">
                                    Insurance accepted
                                </div>
                                <div className="section-value">
                                    {insuranceDetail.insurance_accepted
                                        ? "Yes"
                                        : "No"}
                                </div>
                            </div>
                            <div>
                                <div className="section-info">
                                    NON-CLIENT PRIMARY INSURED NAME
                                </div>
                                <div className="section-value">
                                    {insuranceDetail.non_client_primary_insured_name
                                        ? insuranceDetail.non_client_primary_insured_name
                                        : "--"}
                                </div>
                            </div>
                            <div>
                                <div className="section-info">
                                    NON-CLIENT PRIMARY INSURED DOB
                                </div>
                                <div className="section-value">
                                    {insuranceDetail.non_client_primary_insured_dob
                                        ? formatDate(
                                              insuranceDetail.non_client_primary_insured_dob,
                                              true
                                          )
                                        : "--"}
                                </div>
                            </div>
                            <div>
                                <div className="section-info">
                                    PRIMARY INSURED address
                                </div>
                                <div className="section-value">
                                    {insuranceDetail.primary_insured_address}
                                </div>
                            </div>
                            <div>
                                <div className="section-info">LAST VERIFED</div>
                                <div className="section-value">
                                    {formatDate(
                                        insuranceDetail.last_verified || "",
                                        true
                                    )}
                                </div>
                            </div>
                            <div>
                                <span className="section-info block">
                                    Relationship to primary insured
                                </span>
                                <span className="section-value block">
                                    {insuranceDetail.relationship_to_primary_insured ||
                                        "--"}
                                </span>
                            </div>
                            <div>
                                <span className="section-info block">
                                    Primary insured city
                                </span>
                                <span className="section-value block">
                                    {insuranceDetail.primary_insured_city ||
                                        "--"}
                                </span>
                            </div>
                            <div>
                                <span className="section-info block">
                                    Primary insured phone no
                                </span>
                                <span className="section-value block">
                                    {insuranceDetail.primary_insured_phone_no ||
                                        "--"}
                                </span>
                            </div>
                            <div>
                                <span className="section-info block">
                                    Primary insured sex
                                </span>
                                <span className="section-value block">
                                    {insuranceDetail.primary_insured_sex ||
                                        "--"}
                                </span>
                            </div>
                            <div>
                                <span className="section-info block">
                                    Primary insured dob
                                </span>
                                <span className="section-value block">
                                    {insuranceDetail.primary_insured_dob
                                        ? formatDate(
                                              insuranceDetail.primary_insured_dob,
                                              true
                                          )
                                        : "--"}
                                </span>
                            </div>
                            <div>
                                <span className="section-info block">
                                    Insured policy group
                                </span>
                                <span className="section-value block">
                                    {insuranceDetail.insured_policy_group ||
                                        "--"}
                                </span>
                            </div>
                            <div>
                                <span className="section-info block">
                                    Primary insured state
                                </span>
                                <span className="section-value block">
                                    {getStateInShortForm(
                                        insuranceDetail.primary_insured_state
                                    )}
                                </span>
                            </div>
                            <div>
                                <span className="section-info block">
                                    Primary insured zipcode
                                </span>
                                <span className="section-value block">
                                    {insuranceDetail.primary_insured_zipcode ||
                                        "--"}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="mt-24">
                        <div className="text-base font-bold mb-12">
                            ATTACHED FILES(
                            {`${
                                insuranceDetail.attached_files
                                    ? insuranceDetail.attached_files.length
                                    : 0
                            }`}
                            )
                        </div>
                        <div className="grid grid-cols-[repeat(auto-fit,minmax(262px,1fr))] gap-8 ">
                            {insuranceDetail.attached_files &&
                                insuranceDetail.attached_files.map(
                                    (attached_file) => {
                                        return (
                                            <div
                                                key={attached_file.id}
                                                className="flex flex-col gap-y-24 bg-card-bg rounded-r8 p-16"
                                            >
                                                <span className="text-sm text-black font-medium text-left">
                                                    {`${attached_file.name}`}
                                                </span>
                                                <div className="flex justify-between items-center font-normal text-xs text-black">
                                                    <Button
                                                        variant="normal"
                                                        onClick={() => {
                                                            handleDocumentinView(
                                                                attached_file
                                                            );
                                                        }}
                                                        className="p-0 text-xs font-semibold text-primary"
                                                    >
                                                        View doc
                                                    </Button>

                                                    <Button
                                                        variant="normal"
                                                        onClick={() => {
                                                            setShowDeleteConfirmation(
                                                                true
                                                            );
                                                            setDocumentToDeleteId(
                                                                attached_file.id
                                                            );
                                                        }}
                                                        className="p-0 text-xs font-semibold text-primary"
                                                    >
                                                        <TrashIcon />
                                                    </Button>
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
