import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
    addClient,
    editClientCurrentFee,
    addClientFees,
    addClientInsuranceDetails,
    editClientBasicInfo,
    editClientPersonalInfo,
    editClientInsuranceDetails,
    deleteInsuranceAttachment,
    deleteClientDocument,
    addRelationInfo,
    addPaymentCard,
    validatePaymentCard,
    deleteClientMemo,
    addPaymentCardLabel,
    editClientMemo,
} from "../../services/client";
import {
    IClient,
    IClientPersonalInfo,
    INewClientDetails,
    IClientNewInsuranceDetails,
    IClientFee,
    IEditClientFee,
    INewClientFee,
    EditClientBasicInfo,
    IClientBasicInfo,
    IClientEditPersonalInfo,
    IClientEditInsuranceDetails,
    IClientInsuranceDetails,
    IClientInsuranceAttachment,
    IClientDocument,
    IClientRelation,
    IAddRelationInfo,
    IClientCreditCardDetails,
    INewCreditCardDetails,
    IEditMemo,
    IEditMemoResponse,
} from "../../utils/types/client";
import { IApiResponse } from "../../utils/types/api-response";

// Hook for creating new client
export function useAddClient() {
    return useMutation<
        { message: string; client: IClient },
        AxiosError<IApiResponse<IClient>>,
        INewClientDetails
    >({ mutationFn: (data: INewClientDetails) => addClient(data) });
}

export function useAddClientInsurance(clientId: string) {
    return useMutation<
        IApiResponse<IClientInsuranceDetails>,
        AxiosError<IApiResponse<IClientInsuranceDetails>>,
        IClientNewInsuranceDetails
    >({
        mutationKey: ["add-insurance", clientId],
        mutationFn: (data: IClientNewInsuranceDetails) =>
            addClientInsuranceDetails(clientId, data),
    });
}

// Hook for editing client current fee
export function useEditClientCurrentFee(clientId: string, feeId: string) {
    return useMutation<
        IApiResponse<IClientFee>,
        AxiosError<IApiResponse<IClientFee>>,
        IEditClientFee
    >({
        mutationKey: ["edit-fee", clientId],
        mutationFn: (data: IEditClientFee) =>
            editClientCurrentFee(clientId, feeId, data),
    });
}

// Hook for adding new client fees
export function useAddClientFees(clientId: string) {
    return useMutation<
        IApiResponse<IClientFee>,
        AxiosError<IApiResponse<IClientFee>>,
        INewClientFee
    >({
        mutationKey: ["add-fee", clientId],
        mutationFn: (data: INewClientFee) => addClientFees(clientId, data),
    });
}

// Hook for editing client basic info
export function useEditClientBasicInfo(clientId: string) {
    return useMutation<
        IApiResponse<IClientPersonalInfo>,
        AxiosError<IApiResponse<IClientPersonalInfo>>,
        EditClientBasicInfo
    >({
        mutationKey: ["edit-personal-info", clientId],
        mutationFn: (data: EditClientBasicInfo) =>
            editClientBasicInfo(clientId, data),
    });
}

// Hook for editing client personal info
export function useEditClientPersonalInfo(clientId: string) {
    return useMutation<
        IApiResponse<IClientEditPersonalInfo>,
        AxiosError<IApiResponse<IClientBasicInfo>>,
        IClientEditPersonalInfo
    >({
        mutationKey: ["edit-personal-info", clientId],
        mutationFn: (data: IClientEditPersonalInfo) =>
            editClientPersonalInfo(clientId, data),
    });
}

// Hook for editing client insurance details
export function useEditClientInsuranceDetails(
    clientId: string,
    insuranceId: string
) {
    return useMutation<
        IApiResponse<IClientInsuranceDetails>,
        AxiosError<IApiResponse<IClientInsuranceDetails>>,
        IClientEditInsuranceDetails
    >({
        mutationKey: ["edit-insurance", clientId],
        mutationFn: (data: IClientEditInsuranceDetails) =>
            editClientInsuranceDetails(clientId, insuranceId, data),
    });
}

// Hook for deleting client insurance attachment
export function useDeleteClientInsuranceAttachment(
    clientId: string,
    documentId: string
) {
    interface IApiResponse {
        message: string;
        attachments: IClientInsuranceAttachment[];
    }
    return useMutation<IApiResponse, AxiosError<IApiResponse>, null>({
        mutationKey: ["delete-document", clientId],
        mutationFn: () => deleteInsuranceAttachment(clientId, documentId),
    });
}

// Hook for deleting client document
export function useDeleteClientDocument(clientId: string, documentId: string) {
    interface IApiResponse {
        message: string;
        documents: IClientDocument[];
    }
    return useMutation<IApiResponse, AxiosError<IApiResponse>, null>({
        mutationKey: ["delete-document", clientId],
        mutationFn: () => deleteClientDocument(clientId, documentId),
    });
}

// Hook for addng client relation info
export function useAddRelationInfo() {
    interface ApiResponseData {
        relation_info: IClientRelation[];
    }

    return useMutation<
        IApiResponse<ApiResponseData>,
        AxiosError<IApiResponse<IClientRelation[]>>,
        IAddRelationInfo
    >({
        mutationFn: (data: IAddRelationInfo) => addRelationInfo(data),
    });
}

export function useAddPaymentCard(clientId: string) {
    return useMutation<
        IApiResponse<IClientCreditCardDetails>,
        AxiosError<IApiResponse<IClientCreditCardDetails>>,
        INewCreditCardDetails
    >({
        mutationKey: ["add-card", clientId],
        mutationFn: (data: INewCreditCardDetails) =>
            addPaymentCard(clientId, data),
    });
}

// Hook to validate credit card
export function useValidateCard(clientId: string, paymentCardId: string) {
    interface ApiResponseData {
        message: string;
        data: Record<string, string>;
    }
    return useMutation<ApiResponseData, AxiosError<ApiResponseData>, null>({
        mutationFn: () => validatePaymentCard(clientId, paymentCardId),
        mutationKey: ["validate-card", paymentCardId],
    });
}

// Hook to add card labels
export function useAddCardLabel(clientId: string, paymentCardId: string) {
    interface ApiResponseData {
        message: string;
    }
    interface Payload {
        card_label: string;
    }
    return useMutation<
        IApiResponse<ApiResponseData>,
        AxiosError<IApiResponse<ApiResponseData>>,
        Payload
    >({
        mutationKey: ["add-card-label", paymentCardId],
        mutationFn: (payload: Payload) =>
            addPaymentCardLabel(clientId, paymentCardId, payload),
    });
}

export function useDeleteMemos() {
    interface IDeleteMemoResponse {
        deleted_memos: string[];
        undeleted_memos: string[];
    }

    return useMutation<
        IApiResponse<IDeleteMemoResponse>,
        AxiosError<IApiResponse<IDeleteMemoResponse>>,
        string[]
    >({
        mutationKey: ["delete-memo"],
        mutationFn: (memoIds: string[]) => deleteClientMemo(memoIds),
    });
}

export function useEditMemos() {
    return useMutation<
        IApiResponse<IEditMemoResponse>,
        AxiosError<IApiResponse<IEditMemoResponse>>,
        IEditMemo
    >({
        mutationKey: ["edit-memo"],
        mutationFn: (data: IEditMemo) => editClientMemo(data),
    });
}
