import React from "react";
import ReactDOM from "react-dom/client";
import {
    BrowserRouter,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import * as FullStory from "@fullstory/browser";
import { YbugProvider } from "ybug-react";
import { ErrorBoundary } from "react-error-boundary";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { logEvent } from "firebase/analytics";
import { Provider } from "react-redux";

import { Toaster } from "react-hot-toast";
import "react-big-calendar/lib/css/react-big-calendar.css";
import App from "./App";
import "./global-styles/main.scss";
// Import externals so it can be built too.
import "./externals";
import { analytics } from "./firebase";
import AppFallback from "./components/ErrorBoundaryFallback/AppFallback/AppFallback";
import AuthProvider from "./context/auth/AuthProvider";
import NotificationProvider from "./context/notification/NotificationProvider";
import PinnedClientsProvider from "./context/pinned-clients/PinnedClientsProvider";
import UserPracticeProvider from "./context/user-practice/UserPracticeProvider";
import AnnouncementProvider from "./context/announcement/AnnouncementProvider";
import { store } from "./redux/store";

logEvent(analytics, "frontend-analytics");

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    ignoreErrors: ["Permission denied"],
    replaysSessionSampleRate: import.meta.env.VITE_NODE_ENV !== "dev" ? 0.1 : 1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            ),
        }),
        new Sentry.Replay(),
    ],
    environment: import.meta.env.VITE_NODE_ENV,
    tracesSampleRate: 0.5,
    enabled: import.meta.env.VITE_NODE_ENV !== "dev",
});

// Create a client
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnMount: import.meta.env.VITE_NODE_ENV !== "dev",
            refetchOnWindowFocus: import.meta.env.VITE_NODE_ENV !== "dev",
            refetchOnReconnect: import.meta.env.VITE_NODE_ENV !== "dev",
            retry: 1,
            // set staletime of all fetched data to 20 seconds
            staleTime:
                import.meta.env.VITE_NODE_ENV !== "dev" ? 1000 * 20 : 1000,
        },
    },
});

// Initialize fullstory
FullStory.init({ orgId: import.meta.env.VITE_FULLSTORY_ID });

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <ErrorBoundary
            fallback={<AppFallback />}
            onError={(err) => {
                Sentry.captureException(err);
            }}
        >
            <AuthProvider>
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <YbugProvider
                            ybugId={import.meta.env.VITE_YBUG_ID}
                            settings={{ launcher_position: "bottom-left" }}
                        >
                            <UserPracticeProvider>
                                <AnnouncementProvider>
                                    <NotificationProvider>
                                        <PinnedClientsProvider>
                                            <Provider store={store}>
                                                <App />
                                            </Provider>
                                        </PinnedClientsProvider>
                                    </NotificationProvider>
                                </AnnouncementProvider>
                            </UserPracticeProvider>
                        </YbugProvider>
                        <Toaster />
                    </BrowserRouter>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </AuthProvider>
        </ErrorBoundary>
    </React.StrictMode>
);
