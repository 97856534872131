import * as React from "react";
import {
    Button,
    DatePicker,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Popover,
    PopoverContent,
    PopoverTrigger,
    SearchInput,
    Tabs,
} from "@jhool-io/fe-components";
import { useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import ReadyForSubmissionList from "./ReadyForSubmissionList/ReadyForSubmissionList";
import AwaitingResponseList from "./AwaitingResponseList/AwaitingResponseList";
import RequiresActionList from "./RequiresActionList/RequiresActionList";
import ClosedClaimsList from "./ClosedClaimsList/ClosedClaimsList";
import ChevronDownIcon from "../../../../components/Icons/ChevronDown";
import { APP_COLORS } from "../../../../utils/constants";
import { useFetchInsuranceProviders } from "../../../../hooks/queries/client";
import { ClaimStatus, ClaimsHasRemit } from "../../types/claims.types";
import {
    cn,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    truncateString,
} from "../../../../utils/helpers";
import { useFetchClaims } from "../../hooks/claims.queries";
import { IAppCustomDates } from "../../../../utils/types";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import { useDebounce } from "../../../../hooks/helpers";
import { momentNotTz } from "../../../../utils/moment";
import CheckPrimaryColorIcon from "../../../../components/Icons/CheckPrimaryColor";
import RemitReceivedlClaimsList from "./RemitReceivedClaimsList/RemitReceivedClaimsList";

export default function ClaimsLists() {
    const [searchParams, setSearchParams] = useSearchParams();

    const tabInUrl = searchParams.get("tab");

    const handleSetTabInUrl = (tab: string) => {
        searchParams.set("tab", tab);
        localStorage.setItem("claimslist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const getTabStatuses = (tab: string) => {
        if (tab === "submission_ready") {
            return [ClaimStatus.READY_FOR_SUBMISSION];
        }

        if (tab === "awaiting_response") {
            return [
                ClaimStatus.SUBMITTED,
                ClaimStatus.RECEIVED,
                ClaimStatus.ACKNOWLEDGED,
                ClaimStatus.PENDING,
            ];
        }

        if (tab === "remit_received") {
            return [
                ClaimStatus.ACKNOWLEDGED,
                ClaimStatus.APPEALED,
                ClaimStatus.DENIED,
                ClaimStatus.PAID,
                ClaimStatus.PATIENT_PAYMENT_COLLECTION,
                ClaimStatus.PENDING,
                ClaimStatus.RECEIVED,
                ClaimStatus.REJECTED,
                ClaimStatus.REVERSED,
                ClaimStatus.SUBMITTED,
            ];
        }

        if (tab === "requires_action") {
            return [ClaimStatus.REJECTED, ClaimStatus.DENIED];
        }

        if (tab === "closed") {
            return [ClaimStatus.CLOSED];
        }

        return [""];
    };

    const searchString = searchParams.get("search") || "";

    const searchStringFilter = useDebounce(searchString, 500);

    const fromDateFilter = searchParams.get("note_dos_from")
        ? momentNotTz(searchParams.get("note_dos_from")).toDate()
        : null;
    const toDateFilter = searchParams.get("note_dos_to")
        ? momentNotTz(searchParams.get("note_dos_to")).toDate()
        : null;
    const insuranceProviderFilter =
        searchParams.get("insurance_provider") || "";
    const claimsStatusFilter = searchParams.get("status");
    const hasRemitStatusFilter = searchParams.get("has_remits") || "";
    const customDateFilter =
        (searchParams.get("date_range") as IAppCustomDates) ||
        IAppCustomDates.LAST_90_DAYS;

    const getStatusFilter = (tab: string) => {
        if (claimsStatusFilter) {
            if (getTabStatuses(tab).includes(claimsStatusFilter)) {
                return [claimsStatusFilter];
            }

            return [""];
        }

        return getTabStatuses(tab);
    };

    const getHasRemitFilter = () => {
        if (hasRemitStatusFilter === ClaimsHasRemit.SHOW_CLAIMS_WITH_REMIT) {
            return "true";
        }
        if (hasRemitStatusFilter === ClaimsHasRemit.HIDE_CLAIMS_WITH_REMIT) {
            return "false";
        }
        return "";
    };

    const {
        data: awaitingResponseClaimsData,
        isLoading: awaitingResponseDataLoading,
    } = useFetchClaims({
        claim_statuses: getStatusFilter("awaiting_response"),
        custom_date: customDateFilter,
        search_string: searchStringFilter,
        note_dos_from: fromDateFilter
            ? format(fromDateFilter, "yyyy-MM-dd")
            : null,
        has_remits: getHasRemitFilter(),
        note_dos_to: toDateFilter ? format(toDateFilter, "yyyy-MM-dd") : null,
        insurance_provider_id: insuranceProviderFilter,
        page: Number(searchParams.get("awaiting_res_page") || 1),
        limit: 20,
        tab_name: "awaiting_response",
    });

    const {
        data: readyForSubmissionClaimsData,
        isLoading: readyForSubmissionDataLoading,
    } = useFetchClaims({
        claim_statuses: getStatusFilter("submission_ready"),
        custom_date: customDateFilter,
        search_string: searchStringFilter,
        note_dos_from: fromDateFilter
            ? format(fromDateFilter, "yyyy-MM-dd")
            : null,
        has_remits: getHasRemitFilter(),
        note_dos_to: toDateFilter ? format(toDateFilter, "yyyy-MM-dd") : null,
        insurance_provider_id: insuranceProviderFilter,
        page: Number(searchParams.get("ready_for_sub_page") || 1),
        limit: 20,
    });

    const {
        data: remitReceivedClaimsData,
        isLoading: remitReceivedDataLoading,
    } = useFetchClaims({
        claim_statuses: getStatusFilter("remit_received"),
        custom_date: customDateFilter,
        search_string: searchStringFilter,
        note_dos_from: fromDateFilter
            ? format(fromDateFilter, "yyyy-MM-dd")
            : null,
        has_remits:
            getHasRemitFilter() || ClaimsHasRemit.SHOW_CLAIMS_WITH_REMIT,
        note_dos_to: toDateFilter ? format(toDateFilter, "yyyy-MM-dd") : null,
        insurance_provider_id: insuranceProviderFilter,
        page: Number(searchParams.get("remit_received_page") || 1),
        limit: 20,
        tab_name: "received_remit",
    });

    const {
        data: requiresActionClaimsData,
        isLoading: requiresActionDataLoading,
    } = useFetchClaims({
        claim_statuses: getStatusFilter("requires_action"),
        custom_date: customDateFilter,
        search_string: searchStringFilter,
        note_dos_from: fromDateFilter
            ? format(fromDateFilter, "yyyy-MM-dd")
            : null,
        has_remits: getHasRemitFilter(),
        note_dos_to: toDateFilter ? format(toDateFilter, "yyyy-MM-dd") : null,
        insurance_provider_id: insuranceProviderFilter,
        page: Number(searchParams.get("req_action_page") || 1),
        limit: 20,
    });

    const { data: closedClaimsData, isLoading: closedClaimsDataLoading } =
        useFetchClaims({
            claim_statuses: getStatusFilter("closed"),
            custom_date: customDateFilter,
            search_string: searchStringFilter,
            note_dos_from: fromDateFilter
                ? format(fromDateFilter, "yyyy-MM-dd")
                : null,
            note_dos_to: toDateFilter
                ? format(toDateFilter, "yyyy-MM-dd")
                : null,
            has_remits: getHasRemitFilter(),
            insurance_provider_id: insuranceProviderFilter,
            page: Number(searchParams.get("closed_claims_page") || 1),
            limit: 20,
        });

    const insuranceProviders = useFetchInsuranceProviders();

    const tabItems = [
        {
            key: "submission_ready",
            label: "Ready for submission",
            children: (
                <ReadyForSubmissionList searchString={searchStringFilter} />
            ),
            onClick: () => handleSetTabInUrl("submission_ready"),
            count: readyForSubmissionDataLoading ? (
                <Skeleton className=" w-20 h-20 rounded-full" />
            ) : (
                readyForSubmissionClaimsData?.total_count
            ),
        },
        {
            key: "awaiting_response",
            label: "Awaiting response",
            children: (
                <AwaitingResponseList searchString={searchStringFilter} />
            ),
            onClick: () => handleSetTabInUrl("awaiting_response"),
            count: awaitingResponseDataLoading ? (
                <Skeleton className=" w-20 h-20 rounded-full" />
            ) : (
                awaitingResponseClaimsData?.total_count
            ),
        },
        {
            key: "remit_received",
            label: "Remit received",
            children: (
                <RemitReceivedlClaimsList searchString={searchStringFilter} />
            ),
            onClick: () => handleSetTabInUrl("remit_received"),
            count: remitReceivedDataLoading ? (
                <Skeleton className=" w-20 h-20 rounded-full" />
            ) : (
                remitReceivedClaimsData?.total_count
            ),
        },
        {
            key: "requires_action",
            label: "Requires action",
            children: <RequiresActionList searchString={searchStringFilter} />,
            onClick: () => handleSetTabInUrl("requires_action"),
            count: requiresActionDataLoading ? (
                <Skeleton className=" w-20 h-20 rounded-full" />
            ) : (
                requiresActionClaimsData?.total_count
            ),
        },
        {
            key: "closed",
            label: "Closed",
            children: <ClosedClaimsList searchString={searchStringFilter} />,
            onClick: () => handleSetTabInUrl("closed"),
            count: closedClaimsDataLoading ? (
                <Skeleton className=" w-20 h-20 rounded-full" />
            ) : (
                closedClaimsData?.total_count
            ),
        },
    ];

    // onChange handler for search input
    const handleSetSearchString = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") searchParams.delete("search");
        else searchParams.set("search", e.target.value);
        localStorage.setItem("claimslist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const statusOptionsForSelect = [
        ...Object.values(ClaimStatus).map((status) => {
            return status;
        }),
    ];

    const customDateForSelect = [
        ...Object.values(IAppCustomDates).map((item) => {
            return item;
        }),
    ];

    const handleFromDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("note_dos_from");
        else searchParams.set("note_dos_from", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        localStorage.setItem("claimslist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleToDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("note_dos_to");
        else searchParams.set("note_dos_to", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        localStorage.setItem("claimslist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleInsuranceProviderChange = (value: string) => {
        if (value === "") searchParams.delete("insurance_provider");
        else searchParams.set("insurance_provider", value);
        searchParams.set("page", "1");
        localStorage.setItem("claimslist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleStatusChange = (value: string) => {
        if (value === "") searchParams.delete("status");
        else searchParams.set("status", value);
        searchParams.set("page", "1");
        localStorage.setItem("claimslist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleHasRemitChange = (value: string) => {
        if (value === "") searchParams.delete("has_remits");
        else searchParams.set("has_remits", value);
        searchParams.set("page", "1");
        localStorage.setItem("claimslist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };
    const handleCustomDateChange = (value: IAppCustomDates) => {
        searchParams.set("date_range", value);
        searchParams.set("page", "1");
        localStorage.setItem("claimslist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const getInsuranceTextToDisplay = React.useMemo(
        () =>
            insuranceProviders?.data?.data.find(
                (provider) =>
                    provider.insurance_provider_id === insuranceProviderFilter
            )?.name,
        [insuranceProviderFilter, insuranceProviders?.data?.data]
    );

    return (
        <>
            <div className="flex flex-col gap-y-24 mb-32 bg-white p-16 rounded-r8">
                <SearchInput
                    containerClass="w-2/5"
                    placeholder="Search by client, claim no or provider"
                    onChange={handleSetSearchString}
                    defaultValue={searchString}
                />
                <div className="flex gap-x-12">
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <Button
                                size="auto"
                                variant="normal"
                                disabled={
                                    Boolean(insuranceProviders.error) ||
                                    insuranceProviders.isLoading
                                }
                                className={cn(
                                    "px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize",
                                    {
                                        uppercase: insuranceProviderFilter,
                                    }
                                )}
                            >
                                {insuranceProviders.isLoading && "Loading.."}
                                {insuranceProviders.error &&
                                    "Error loading providers"}
                                {insuranceProviders?.data?.data && (
                                    <>
                                        {getInsuranceTextToDisplay
                                            ? truncateString(
                                                  removeEnumUnderscore(
                                                      getInsuranceTextToDisplay
                                                  )
                                              )
                                            : "Insurance"}
                                        <ChevronDownIcon
                                            stroke={APP_COLORS.COLOR_BLACK}
                                        />
                                    </>
                                )}
                            </Button>
                        </DropdownTrigger>
                        <DropdownContent
                            align="start"
                            width="auto"
                            maxHeight={216}
                            className="overflow-y-auto"
                        >
                            <DropdownItem
                                className={cn(
                                    "flex gap-x-8 items-center text-xs font-medium",
                                    {
                                        "text-primary":
                                            insuranceProviderFilter === "",
                                    }
                                )}
                                onClick={() =>
                                    handleInsuranceProviderChange("")
                                }
                            >
                                All
                                {insuranceProviderFilter === "" ? (
                                    <CheckPrimaryColorIcon />
                                ) : null}
                            </DropdownItem>
                            {insuranceProviders?.data?.data
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((provider) => (
                                    <DropdownItem
                                        className={cn(
                                            "flex gap-x-8 items-center uppercase text-xs font-medium",
                                            {
                                                "text-primary":
                                                    insuranceProviderFilter ===
                                                    provider.insurance_provider_id,
                                            }
                                        )}
                                        key={provider.insurance_provider_id}
                                        onClick={() =>
                                            handleInsuranceProviderChange(
                                                provider.insurance_provider_id
                                            )
                                        }
                                    >
                                        {provider.name}
                                        {insuranceProviderFilter ===
                                        provider.insurance_provider_id ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>
                                ))}
                        </DropdownContent>
                    </Dropdown>
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <Button
                                size="auto"
                                variant="normal"
                                className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize"
                            >
                                <>
                                    {claimsStatusFilter
                                        ? truncateString(
                                              removeEnumUnderscore(
                                                  claimsStatusFilter
                                              )
                                          )
                                        : "Status"}
                                    <ChevronDownIcon
                                        stroke={APP_COLORS.COLOR_BLACK}
                                    />
                                </>
                            </Button>
                        </DropdownTrigger>
                        <DropdownContent
                            align="start"
                            width="auto"
                            maxHeight={216}
                            className="overflow-y-auto"
                        >
                            <DropdownItem
                                className={cn(
                                    "flex gap-x-8 items-center text-xs font-medium",
                                    {
                                        "text-primary":
                                            claimsStatusFilter === "",
                                    }
                                )}
                                onClick={() => handleStatusChange("")}
                            >
                                All
                                {!claimsStatusFilter ? (
                                    <CheckPrimaryColorIcon />
                                ) : null}
                            </DropdownItem>
                            {statusOptionsForSelect.map((status) => (
                                <DropdownItem
                                    className={cn(
                                        "flex gap-x-8 items-center capitalize text-xs font-medium",
                                        {
                                            "text-primary":
                                                claimsStatusFilter === status,
                                        }
                                    )}
                                    key={status}
                                    onClick={() => handleStatusChange(status)}
                                >
                                    {removeEnumUnderscore(status)}
                                    {claimsStatusFilter === status ? (
                                        <CheckPrimaryColorIcon />
                                    ) : null}
                                </DropdownItem>
                            ))}
                        </DropdownContent>
                    </Dropdown>
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <Button
                                size="auto"
                                variant="normal"
                                className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize"
                            >
                                {customDateFilter
                                    ? truncateString(
                                          removeEnumUnderscore(customDateFilter)
                                      )
                                    : "Date range"}
                                <ChevronDownIcon
                                    stroke={APP_COLORS.COLOR_BLACK}
                                />
                            </Button>
                        </DropdownTrigger>
                        <DropdownContent
                            align="start"
                            width={200}
                            maxHeight={216}
                            className="overflow-y-auto"
                        >
                            {customDateForSelect.map((range) => (
                                <DropdownItem
                                    className={cn(
                                        "flex gap-x-8 items-center capitalize text-xs font-medium",
                                        {
                                            "text-primary":
                                                customDateFilter === range,
                                        }
                                    )}
                                    key={range}
                                    onClick={() =>
                                        handleCustomDateChange(range)
                                    }
                                >
                                    {removeEnumUnderscore(range)}{" "}
                                    {customDateFilter === range ? (
                                        <CheckPrimaryColorIcon />
                                    ) : null}
                                </DropdownItem>
                            ))}
                        </DropdownContent>
                    </Dropdown>
                    <Popover>
                        <PopoverTrigger asChild>
                            <Button
                                size="auto"
                                variant="normal"
                                className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium self-start md:self-center"
                            >
                                Date
                                <ChevronDownIcon
                                    stroke={APP_COLORS.COLOR_BLACK}
                                />
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent
                            align="end"
                            className="h-auto w-96 px-16 pt-6 z-20"
                        >
                            <DatePicker
                                label="From"
                                placeholderText="MM/DD/YYYY"
                                selected={fromDateFilter}
                                onChange={handleFromDateFilterChange}
                                className="hover:border-primary"
                                maxDate={toDateFilter || new Date(Date.now())}
                                isClearable
                            />
                            <div className="mt-24 mb-24 hover:border-primary">
                                <DatePicker
                                    label="To"
                                    placeholderText="MM/DD/YYYY"
                                    selected={toDateFilter}
                                    onChange={handleToDateFilterChange}
                                    className="hover:border-primary"
                                    minDate={fromDateFilter}
                                    maxDate={new Date(Date.now())}
                                    isClearable
                                />
                            </div>
                        </PopoverContent>
                    </Popover>
                    {/* <Button>Export</Button> */}
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <Button
                                size="auto"
                                variant="normal"
                                className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize"
                            >
                                <>
                                    {hasRemitStatusFilter
                                        ? truncateString(
                                              removeEnumUnderscore(
                                                  hasRemitStatusFilter
                                              )
                                          )
                                        : "Has Remit"}
                                    <ChevronDownIcon
                                        stroke={APP_COLORS.COLOR_BLACK}
                                    />
                                </>
                            </Button>
                        </DropdownTrigger>
                        <DropdownContent
                            align="start"
                            width="auto"
                            maxHeight={216}
                            className="overflow-y-auto"
                        >
                            <DropdownItem
                                className={cn(
                                    "flex gap-x-8 items-center text-xs font-medium",
                                    {
                                        "text-primary":
                                            hasRemitStatusFilter === "",
                                    }
                                )}
                                onClick={() => handleHasRemitChange("")}
                            >
                                Show All Claims
                                {!hasRemitStatusFilter ? (
                                    <CheckPrimaryColorIcon />
                                ) : null}
                            </DropdownItem>
                            <DropdownItem
                                className={cn(
                                    "flex gap-x-8 items-center text-xs font-medium",
                                    {
                                        "text-primary":
                                            hasRemitStatusFilter ===
                                            ClaimsHasRemit.SHOW_CLAIMS_WITH_REMIT,
                                    }
                                )}
                                onClick={() =>
                                    handleHasRemitChange(
                                        ClaimsHasRemit.SHOW_CLAIMS_WITH_REMIT
                                    )
                                }
                            >
                                {makeStringFirstLetterCapital(
                                    removeEnumUnderscore(
                                        ClaimsHasRemit.SHOW_CLAIMS_WITH_REMIT
                                    )
                                )}
                                {hasRemitStatusFilter ===
                                ClaimsHasRemit.SHOW_CLAIMS_WITH_REMIT ? (
                                    <CheckPrimaryColorIcon />
                                ) : null}
                            </DropdownItem>
                            <DropdownItem
                                className={cn(
                                    "flex gap-x-8 items-center text-xs font-medium",
                                    {
                                        "text-primary":
                                            hasRemitStatusFilter ===
                                            ClaimsHasRemit.HIDE_CLAIMS_WITH_REMIT,
                                    }
                                )}
                                onClick={() =>
                                    handleHasRemitChange(
                                        ClaimsHasRemit.HIDE_CLAIMS_WITH_REMIT
                                    )
                                }
                            >
                                {makeStringFirstLetterCapital(
                                    removeEnumUnderscore(
                                        ClaimsHasRemit.HIDE_CLAIMS_WITH_REMIT
                                    )
                                )}
                                {hasRemitStatusFilter ===
                                ClaimsHasRemit.HIDE_CLAIMS_WITH_REMIT ? (
                                    <CheckPrimaryColorIcon />
                                ) : null}
                            </DropdownItem>
                        </DropdownContent>
                    </Dropdown>
                </div>
            </div>
            <Tabs
                items={tabItems}
                size="medium"
                className="w-full rounded-b-none"
                defaultActiveKey={tabInUrl || tabItems[0].key}
            />
        </>
    );
}
