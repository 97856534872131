/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Calendar,
    Components,
    View,
    momentLocalizer,
} from "react-big-calendar";
import moment from "moment";
import { CalenderEvents } from "../../utils/types/availiability";

interface BigCalenderProps {
    /* signifies which view to show on the calendar either day, week, or month */
    views: View[];

    /* a single item on the calendar known as events */
    events: CalenderEvents[];

    /* this is used to modify the way the default day, week, or month components are displayed */
    components?: Components<CalenderEvents, object> | undefined;

    /* this gets the details of a particular event you interacted with */
    setEventDetails?: (value: React.SetStateAction<CalenderEvents>) => void;

    /* used to style an event */
    eventStyleGetter: any;
}

export default function BigCalendar({
    views,
    events,
    components,
    setEventDetails,
    eventStyleGetter,
}: BigCalenderProps) {
    const localizer = momentLocalizer(moment);

    return (
        <div>
            <Calendar
                localizer={localizer}
                events={events}
                eventPropGetter={(event: CalenderEvents) =>
                    eventStyleGetter(event)
                }
                startAccessor="start"
                endAccessor="end"
                defaultView="day"
                onSelectEvent={(event: CalenderEvents) => {
                    if (setEventDetails) setEventDetails(event);
                }}
                views={views}
                style={{ height: "100vh", fontSize: 14 }}
                components={components}
            />
        </div>
    );
}
