export default function InfoIcon() {
    return (
        <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_6623_810)">
                <path
                    opacity="0.2"
                    d="M7 0.71875C3.25391 0.71875 0.21875 3.75609 0.21875 7.5C0.21875 11.2439 3.25391 14.2812 7 14.2812C10.7461 14.2812 13.7812 11.2461 13.7812 7.5C13.7812 3.75391 10.7461 0.71875 7 0.71875ZM7 3.72656C7.22714 3.72656 7.44918 3.79392 7.63804 3.92011C7.8269 4.0463 7.9741 4.22566 8.06102 4.43551C8.14794 4.64536 8.17068 4.87627 8.12637 5.09905C8.08206 5.32182 7.97268 5.52646 7.81207 5.68707C7.65146 5.84768 7.44682 5.95706 7.22405 6.00137C7.00127 6.04568 6.77036 6.02294 6.56051 5.93602C6.35066 5.8491 6.1713 5.7019 6.04511 5.51304C5.91892 5.32418 5.85156 5.10214 5.85156 4.875C5.85156 4.57042 5.97256 4.27831 6.18793 4.06293C6.40331 3.84756 6.69542 3.72656 7 3.72656ZM8.53125 10.6719C8.53125 10.7589 8.49668 10.8424 8.43514 10.9039C8.37361 10.9654 8.29015 11 8.20312 11H5.79688C5.70985 11 5.62639 10.9654 5.56486 10.9039C5.50332 10.8424 5.46875 10.7589 5.46875 10.6719V10.0156C5.46875 9.9286 5.50332 9.84514 5.56486 9.78361C5.62639 9.72207 5.70985 9.6875 5.79688 9.6875H6.125V7.9375H5.79688C5.70985 7.9375 5.62639 7.90293 5.56486 7.84139C5.50332 7.77986 5.46875 7.6964 5.46875 7.60938V6.95312C5.46875 6.8661 5.50332 6.78264 5.56486 6.72111C5.62639 6.65957 5.70985 6.625 5.79688 6.625H7.54688C7.6339 6.625 7.71736 6.65957 7.77889 6.72111C7.84043 6.78264 7.875 6.8661 7.875 6.95312V9.6875H8.20312C8.29015 9.6875 8.37361 9.72207 8.43514 9.78361C8.49668 9.84514 8.53125 9.9286 8.53125 10.0156V10.6719Z"
                    fill="#576A81"
                />
                <path
                    d="M7 6.02344C7.22714 6.02344 7.44918 5.95608 7.63804 5.82989C7.8269 5.7037 7.9741 5.52434 8.06102 5.31449C8.14794 5.10464 8.17068 4.87373 8.12637 4.65095C8.08206 4.42818 7.97268 4.22354 7.81207 4.06293C7.65146 3.90232 7.44682 3.79294 7.22405 3.74863C7.00127 3.70432 6.77036 3.72706 6.56051 3.81398C6.35066 3.90091 6.1713 4.0481 6.04511 4.23696C5.91892 4.42582 5.85156 4.64786 5.85156 4.875C5.85156 5.17959 5.97256 5.47169 6.18793 5.68707C6.40331 5.90244 6.69542 6.02344 7 6.02344ZM8.20312 9.6875H7.875V6.95313C7.875 6.8661 7.84043 6.78264 7.77889 6.72111C7.71736 6.65957 7.6339 6.625 7.54688 6.625H5.79688C5.70985 6.625 5.62639 6.65957 5.56486 6.72111C5.50332 6.78264 5.46875 6.8661 5.46875 6.95313V7.60938C5.46875 7.6964 5.50332 7.77986 5.56486 7.84139C5.62639 7.90293 5.70985 7.9375 5.79688 7.9375H6.125V9.6875H5.79688C5.70985 9.6875 5.62639 9.72207 5.56486 9.78361C5.50332 9.84514 5.46875 9.9286 5.46875 10.0156V10.6719C5.46875 10.7589 5.50332 10.8424 5.56486 10.9039C5.62639 10.9654 5.70985 11 5.79688 11H8.20312C8.29015 11 8.37361 10.9654 8.43514 10.9039C8.49668 10.8424 8.53125 10.7589 8.53125 10.6719V10.0156C8.53125 9.9286 8.49668 9.84514 8.43514 9.78361C8.37361 9.72207 8.29015 9.6875 8.20312 9.6875Z"
                    fill="#576A81"
                />
            </g>
            <defs>
                <clipPath id="clip0_6623_810">
                    <rect
                        width="14"
                        height="14"
                        fill="white"
                        transform="translate(0 0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
