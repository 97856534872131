import cn from "classnames";
import { useGetDiagnosisCodesDisplay } from "../../../../hooks/helpers";
import styles from "../../../../modules/notes/components/NoteContent/NoteContent.module.scss";
import CheckIcon from "../../../Icons/Check";
import { formatDate, removeEnumUnderscore } from "../../../../utils/helpers";
import {
    INDIVIDUAL_INTAKE_NOTE_DETAILS,
    INTAKE_SECTIONS,
} from "../../../../utils/constants";
import { IAddendumDetails } from "../../../../utils/types/notes";
import NoteContentItem from "../NoteContentItem/NoteContentItem";
import { AppointmentLocationTeletherapy } from "../../../../utils/types/session";

interface ViewTreatmentReviewNoteProps {
    noteContent: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
    };
    fieldAddendums: {
        [key: string]: IAddendumDetails[];
    } | null;
    isMinor: boolean;
}

export default function ViewIndividualIntakeNote({
    noteContent,
    fieldAddendums,
    isMinor,
}: ViewTreatmentReviewNoteProps) {
    const diagonosisCodes = useGetDiagnosisCodesDisplay(
        noteContent.diagnosis_codes
    );

    const isCheckboxChecked = (checkboxValue: string, key: string) => {
        return Boolean(
            noteContent?.[key]?.find(
                (value: string) => value.trim() === checkboxValue.trim()
            )
        );
    };

    return (
        <>
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.appointment_location}
                fieldId="appointment_location"
                addendums={fieldAddendums?.appointment_location}
                content={
                    removeEnumUnderscore(noteContent.appointment_location) ||
                    "--"
                }
            />
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.delivery_option}
                fieldId="delivery_option"
                addendums={fieldAddendums?.delivery_option}
                content={noteContent.delivery_option || "--"}
            />
            {noteContent.other_delivery_option &&
            noteContent.delivery_option ===
                AppointmentLocationTeletherapy.OTHER ? (
                <NoteContentItem
                    headerText={
                        INDIVIDUAL_INTAKE_NOTE_DETAILS.other_delivery_option
                    }
                    fieldId="other_delivery_option"
                    addendums={fieldAddendums?.other_delivery_option}
                    content={noteContent.other_delivery_option || "--"}
                />
            ) : null}
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.provider_location}
                fieldId="provider_location"
                addendums={fieldAddendums?.provider_location}
                content={noteContent.provider_location || "--"}
            />

            {noteContent.other_provider_location &&
            noteContent.provider_location ===
                AppointmentLocationTeletherapy.OTHER ? (
                <NoteContentItem
                    headerText={
                        INDIVIDUAL_INTAKE_NOTE_DETAILS.other_provider_location
                    }
                    fieldId="other_provider_location"
                    addendums={fieldAddendums?.other_provider_location}
                    content={noteContent.other_provider_location || "--"}
                />
            ) : null}
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.consent_was_received_for_the_teletherapy_session
                }
                fieldId="consent_was_received_for_the_teletherapy_session"
                addendums={
                    fieldAddendums?.consent_was_received_for_the_teletherapy_session
                }
                content={
                    <div className={styles.checkbox}>
                        <span
                            className={cn(styles.checkbox_wrap, {
                                [styles.checked]:
                                    noteContent.consent_was_received_for_the_teletherapy_session,
                            })}
                        >
                            <CheckIcon />
                        </span>
                        {
                            INDIVIDUAL_INTAKE_NOTE_DETAILS.consent_was_received_for_the_teletherapy_session
                        }
                    </div>
                }
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.the_teletherapy_session_was_conducted_via_video
                }
                fieldId="the_teletherapy_session_was_conducted_via_video"
                addendums={
                    fieldAddendums?.the_teletherapy_session_was_conducted_via_video
                }
                content={
                    <div className={styles.checkbox}>
                        <span
                            className={cn(styles.checkbox_wrap, {
                                [styles.checked]:
                                    noteContent.the_teletherapy_session_was_conducted_via_video,
                            })}
                        >
                            <CheckIcon />
                        </span>
                        {
                            INDIVIDUAL_INTAKE_NOTE_DETAILS.the_teletherapy_session_was_conducted_via_video
                        }
                    </div>
                }
            />
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.diagnosis_codes}
                fieldId="diagnosis_codes"
                addendums={fieldAddendums?.diagnosis_codes}
                content={
                    diagonosisCodes && Array.isArray(diagonosisCodes) ? (
                        <div className={styles.checkboxes}>
                            {diagonosisCodes.map((code) => (
                                <div className={styles.checkbox} key={code}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: true,
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    {code}
                                </div>
                            ))}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />

            <p className={styles.sectionheader} id={INTAKE_SECTIONS.identity}>
                Identity
            </p>
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.gender}
                fieldId="gender"
                addendums={fieldAddendums?.gender}
                content={noteContent.gender || "--"}
            />
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.religion}
                fieldId="religion"
                addendums={fieldAddendums?.religion}
                content={noteContent.religion || "--"}
            />
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.education}
                fieldId="education"
                addendums={fieldAddendums?.education}
                content={noteContent.education || "--"}
            />
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.race}
                fieldId="race"
                addendums={fieldAddendums?.race}
                content={noteContent.race || "--"}
            />
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.ethinicity}
                fieldId="ethinicity"
                addendums={fieldAddendums?.ethinicity}
                content={noteContent.ethinicity || "--"}
            />
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.sexual_orientation}
                fieldId="sexual_orientation"
                addendums={fieldAddendums?.sexual_orientation}
                content={noteContent.sexual_orientation || "--"}
            />
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.current_employment}
                fieldId="current_employment"
                addendums={fieldAddendums?.current_employment}
                content={noteContent.current_employment || "--"}
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.current_employment_details
                }
                fieldId="current_employment_details"
                addendums={fieldAddendums?.current_employment_details}
                content={noteContent.current_employment_details || "--"}
            />
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.relationship_status}
                fieldId="relationship_status"
                addendums={fieldAddendums?.relationship_status}
                content={noteContent.relationship_status || "--"}
            />
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.name_of_partner}
                fieldId="name_of_partner"
                addendums={fieldAddendums?.name_of_partner}
                content={noteContent.name_of_partner || "--"}
            />
            {isMinor && (
                <NoteContentItem
                    headerText={
                        INDIVIDUAL_INTAKE_NOTE_DETAILS.contact_information_for_both_parents
                    }
                    fieldId="contact_information_for_both_parents"
                    addendums={
                        fieldAddendums?.contact_information_for_both_parents
                    }
                    content={
                        noteContent.contact_information_for_both_parents || "--"
                    }
                />
            )}
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.emergency_contact_information
                }
                fieldId="emergency_contact_information"
                addendums={fieldAddendums?.emergency_contact_information}
                content={noteContent.emergency_contact_information || "--"}
            />
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.legal_problems}
                fieldId="legal_problems"
                addendums={fieldAddendums?.legal_problems}
                content={noteContent.legal_problems || "--"}
            />
            {isMinor && (
                <>
                    <NoteContentItem
                        headerText={
                            INDIVIDUAL_INTAKE_NOTE_DETAILS.name_address_of_personal_physician
                        }
                        fieldId="name_address_of_personal_physician"
                        addendums={
                            fieldAddendums?.name_address_of_personal_physician
                        }
                        content={
                            noteContent.name_address_of_personal_physician ||
                            "--"
                        }
                    />
                    <NoteContentItem
                        headerText={
                            INDIVIDUAL_INTAKE_NOTE_DETAILS.date_of_last_exam
                        }
                        fieldId="date_of_last_exam"
                        addendums={fieldAddendums?.date_of_last_exam}
                        content={
                            noteContent.date_of_last_exam
                                ? formatDate(
                                      noteContent.date_of_last_exam,
                                      true
                                  )
                                : "--"
                        }
                    />
                </>
            )}

            <p
                className={styles.sectionheader}
                id={INTAKE_SECTIONS["presenting concerns"]}
            >
                Presenting Concerns
            </p>
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.what_are_the_problem_you_are_seeking_help_for
                }
                fieldId="what_are_the_problem_you_are_seeking_help_for"
                addendums={
                    fieldAddendums?.what_are_the_problem_you_are_seeking_help_for
                }
                content={
                    noteContent.what_are_the_problem_you_are_seeking_help_for ||
                    "--"
                }
            />
            <p className={styles.sectionheader} id={INTAKE_SECTIONS.symptoms}>
                Symptoms
            </p>
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.anxiety}
                fieldId="anxiety"
                addendums={fieldAddendums?.anxiety}
                content={
                    <div className={styles.items}>
                        <div className={styles.checkbox}>
                            <span
                                className={cn(styles.checkbox_wrap, {
                                    [styles.checked]: noteContent.anxiety,
                                })}
                            >
                                <CheckIcon />
                            </span>
                            {INDIVIDUAL_INTAKE_NOTE_DETAILS.anxiety}
                        </div>
                        {noteContent.anxiety_frequency && (
                            <p>{`Frequency : ${noteContent.anxiety_frequency}`}</p>
                        )}
                        {noteContent.anxiety_details && (
                            <p>{`Details : ${noteContent.anxiety_details}`}</p>
                        )}
                    </div>
                }
            />
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.panic_attacks}
                fieldId="panic_attacks"
                addendums={fieldAddendums?.panic_attacks}
                content={
                    <div className={styles.items}>
                        <div className={styles.checkbox}>
                            <span
                                className={cn(styles.checkbox_wrap, {
                                    [styles.checked]: noteContent.panic_attacks,
                                })}
                            >
                                <CheckIcon />
                            </span>
                            {INDIVIDUAL_INTAKE_NOTE_DETAILS.panic_attacks}
                        </div>
                        {noteContent.panic_attacks_freqency && (
                            <p>{`Frequency : ${noteContent.panic_attacks_freqency}`}</p>
                        )}
                        {noteContent.panic_attacks_details && (
                            <p>{`Details : ${noteContent.panic_attacks_details}`}</p>
                        )}
                    </div>
                }
            />
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.depression}
                fieldId="depression"
                addendums={fieldAddendums?.depression}
                content={
                    <div className={styles.items}>
                        <div className={styles.checkbox}>
                            <span
                                className={cn(styles.checkbox_wrap, {
                                    [styles.checked]: noteContent.depression,
                                })}
                            >
                                <CheckIcon />
                            </span>
                            {INDIVIDUAL_INTAKE_NOTE_DETAILS.depression}
                        </div>
                        {noteContent.depression_freqency && (
                            <p>{`Frequency : ${noteContent.depression_freqency}`}</p>
                        )}
                        {noteContent.depression_details && (
                            <p>{`Details : ${noteContent.depression_details}`}</p>
                        )}
                    </div>
                }
            />
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.selected_symptoms}
                fieldId="selected_symptoms"
                addendums={fieldAddendums?.selected_symptoms}
                content={
                    noteContent?.other_symptoms?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                "Easily distracted",
                                "selected_symptoms"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Easily distracted
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Impulsive",
                                "selected_symptoms"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Impulsive
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Paranoia",
                                "selected_symptoms"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Paranoia
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Alcohol",
                                "selected_symptoms"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Alcohol
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Anger outburst",
                                "selected_symptoms"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Anger outburst
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Unable to feel pleasure",
                                "selected_symptoms"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Unable to feel pleasure
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Excessive energy",
                                "selected_symptoms"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Excessive energy
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Recreational drug use",
                                "selected_symptoms"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Recreational drug use
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Tobacco",
                                "selected_symptoms"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Tobacco
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Racing thoughts",
                                "selected_symptoms"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Racing thoughts
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.other_symptoms}
                fieldId="other_symptoms"
                addendums={fieldAddendums?.other_symptoms}
                content={noteContent.other_symptoms || "--"}
            />
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.physical_symptoms}
                fieldId="physical_symptoms"
                addendums={fieldAddendums?.physical_symptoms}
                content={noteContent.physical_symptoms || "--"}
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.medication_physical_psychiatric
                }
                fieldId="medication_physical_psychiatric"
                addendums={fieldAddendums?.medication_physical_psychiatric}
                content={noteContent.medication_physical_psychiatric || "--"}
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.sleeping_habits_and_concerns
                }
                fieldId="sleeping_habits_and_concerns"
                addendums={fieldAddendums?.sleeping_habits_and_concerns}
                content={noteContent.sleeping_habits_and_concerns || "--"}
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.difficulties_with_appetite_or_eating_patterns
                }
                fieldId="difficulties_with_appetite_or_eating_patterns"
                addendums={
                    fieldAddendums?.difficulties_with_appetite_or_eating_patterns
                }
                content={
                    noteContent.difficulties_with_appetite_or_eating_patterns ||
                    "--"
                }
            />

            <p
                className={styles.sectionheader}
                id={INTAKE_SECTIONS["family history"]}
            >
                Family history(Describe family history)
            </p>
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.parents_stepparents_history
                }
                fieldId="parents_stepparents_history"
                addendums={fieldAddendums?.parents_stepparents_history}
                content={noteContent.parents_stepparents_history || "--"}
            />
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.siblings_history}
                fieldId="siblings_history"
                addendums={fieldAddendums?.siblings_history}
                content={noteContent.siblings_history || "--"}
            />
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.grandparents_history}
                fieldId="grandparents_history"
                addendums={fieldAddendums?.grandparents_history}
                content={noteContent.grandparents_history || "--"}
            />
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.children_history}
                fieldId="children_history"
                addendums={fieldAddendums?.children_history}
                content={noteContent.children_history || "--"}
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.other_family_members_history
                }
                fieldId="other_family_members_history"
                addendums={fieldAddendums?.other_family_members_history}
                content={noteContent.other_family_members_history || "--"}
            />

            <p
                className={styles.sectionheader}
                id={INTAKE_SECTIONS["family psychiatric history"]}
            >
                Family Psychiatric History
            </p>
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.family_members_alcohol_abuse
                }
                fieldId="family_members_alcohol_abuse"
                addendums={fieldAddendums?.family_members_alcohol_abuse}
                content={
                    noteContent?.parents_stepparents_history?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                "All",
                                "family_members_alcohol_abuse"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    All
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Father",
                                "family_members_alcohol_abuse"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Father
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Mother",
                                "family_members_alcohol_abuse"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Mother
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Child/Children",
                                "family_members_alcohol_abuse"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Child/Children
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Brother",
                                "family_members_alcohol_abuse"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Brother
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Sister",
                                "family_members_alcohol_abuse"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Sister
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Other",
                                "family_members_alcohol_abuse"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Other
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.family_members_anxiety
                }
                fieldId="family_members_anxiety"
                addendums={fieldAddendums?.family_members_anxiety}
                content={
                    noteContent?.family_members_anxiety?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                "All",
                                "family_members_anxiety"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    All
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Father",
                                "family_members_anxiety"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Father
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Mother",
                                "family_members_anxiety"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Mother
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Child/Children",
                                "family_members_anxiety"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Child/Children
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Brother",
                                "family_members_anxiety"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Brother
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Sister",
                                "family_members_anxiety"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Sister
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Other",
                                "family_members_anxiety"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Other
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.family_members_bipolar_disorder
                }
                fieldId="family_members_bipolar_disorder"
                addendums={fieldAddendums?.family_members_bipolar_disorder}
                content={
                    noteContent?.family_members_bipolar_disorder?.length ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                "All",
                                "family_members_bipolar_disorder"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    All
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Father",
                                "family_members_bipolar_disorder"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Father
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Mother",
                                "family_members_bipolar_disorder"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Mother
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Child/Children",
                                "family_members_bipolar_disorder"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Child/Children
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Brother",
                                "family_members_bipolar_disorder"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Brother
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Sister",
                                "family_members_bipolar_disorder"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Sister
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Other",
                                "family_members_bipolar_disorder"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Other
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.family_members_depression
                }
                fieldId="family_members_depression"
                addendums={fieldAddendums?.family_members_depression}
                content={
                    noteContent?.family_members_depression?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                "All",
                                "family_members_depression"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    All
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Father",
                                "family_members_depression"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Father
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Mother",
                                "family_members_depression"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Mother
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Child/Children",
                                "family_members_depression"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Child/Children
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Brother",
                                "family_members_depression"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Brother
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Sister",
                                "family_members_depression"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Sister
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Other",
                                "family_members_depression"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Other
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.family_members_ptsd}
                fieldId="family_members_ptsd"
                addendums={fieldAddendums?.family_members_ptsd}
                content={
                    noteContent?.family_members_ptsd?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                "All",
                                "family_members_ptsd"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    All
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Father",
                                "family_members_ptsd"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Father
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Mother",
                                "family_members_ptsd"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Mother
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Child/Children",
                                "family_members_ptsd"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Child/Children
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Brother",
                                "family_members_ptsd"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Brother
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Sister",
                                "family_members_ptsd"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Sister",
                                                "family_members_ptsd"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Sister
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Other",
                                "family_members_ptsd"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Other",
                                                "family_members_ptsd"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Other
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.family_members_schizophrenia
                }
                fieldId="family_members_schizophrenia"
                addendums={fieldAddendums?.family_members_schizophrenia}
                content={
                    noteContent?.family_members_schizophrenia?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                "All",
                                "family_members_schizophrenia"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "All",
                                                "family_members_schizophrenia"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    All
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Father",
                                "family_members_schizophrenia"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Father",
                                                "family_members_schizophrenia"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Father
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Mother",
                                "family_members_schizophrenia"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Mother",
                                                "family_members_schizophrenia"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Mother
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Child/Children",
                                "family_members_schizophrenia"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Child/Children",
                                                "family_members_schizophrenia"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Child/Children
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Brother",
                                "family_members_schizophrenia"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Brother",
                                                "family_members_schizophrenia"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Brother
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Sister",
                                "family_members_schizophrenia"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Sister",
                                                "family_members_schizophrenia"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Sister
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Other",
                                "family_members_schizophrenia"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Other",
                                                "family_members_schizophrenia"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Other
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.family_members_substance_abuse
                }
                fieldId="family_members_substance_abuse"
                addendums={fieldAddendums?.family_members_substance_abuse}
                content={
                    noteContent?.family_members_substance_abuse?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                "All",
                                "family_members_substance_abuse"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "All",
                                                "family_members_substance_abuse"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    All
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Father",
                                "family_members_substance_abuse"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Father",
                                                "family_members_substance_abuse"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Father
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Mother",
                                "family_members_substance_abuse"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Mother",
                                                "family_members_substance_abuse"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Mother
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Child/Children",
                                "family_members_substance_abuse"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Child/Children",
                                                "family_members_substance_abuse"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Child/Children
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Brother",
                                "family_members_substance_abuse"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Brother",
                                                "family_members_substance_abuse"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Brother
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Sister",
                                "family_members_substance_abuse"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Sister",
                                                "family_members_substance_abuse"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Sister
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Other",
                                "family_members_substance_abuse"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Other",
                                                "family_members_substance_abuse"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Other
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />

            <p
                className={styles.sectionheader}
                id={INTAKE_SECTIONS["mental health history"]}
            >
                Mental health history
            </p>
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.trauma_abuse_history}
                fieldId="trauma_abuse_history"
                addendums={fieldAddendums?.trauma_abuse_history}
                content={noteContent.trauma_abuse_history || "--"}
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.previous_mental_health_treatment_oupatient
                }
                fieldId="previous_mental_health_treatment_oupatient"
                addendums={
                    fieldAddendums?.previous_mental_health_treatment_oupatient
                }
                content={
                    noteContent.previous_mental_health_treatment_oupatient ||
                    "--"
                }
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.impatient_psychiatric_hospitalization
                }
                fieldId="impatient_psychiatric_hospitalization"
                addendums={
                    fieldAddendums?.impatient_psychiatric_hospitalization
                }
                content={
                    noteContent.impatient_psychiatric_hospitalization || "--"
                }
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.relevant_medical_history
                }
                fieldId="relevant_medical_history"
                addendums={fieldAddendums?.relevant_medical_history}
                content={noteContent.relevant_medical_history || "--"}
            />

            <p
                className={styles.sectionheader}
                id={INTAKE_SECTIONS["suicide risk assessment"]}
            >
                Suicide risk assessment
            </p>
            <NoteContentItem
                headerText={`1. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.been_so_distressed_you_seriously_wished_to_end_your_life}`}
                fieldId="been_so_distressed_you_seriously_wished_to_end_your_life"
                addendums={
                    fieldAddendums?.been_so_distressed_you_seriously_wished_to_end_your_life
                }
                content={
                    noteContent.been_so_distressed_you_seriously_wished_to_end_your_life ||
                    "--"
                }
            />
            <NoteContentItem
                headerText={`2. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live}`}
                fieldId="has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live"
                addendums={
                    fieldAddendums?.has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live
                }
                content={
                    noteContent.has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live ||
                    "--"
                }
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live
                }
                fieldId="describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live"
                addendums={
                    fieldAddendums?.describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live
                }
                content={
                    noteContent.describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live ||
                    "--"
                }
            />
            <NoteContentItem
                headerText={`3. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.a_specific_plan_of_how_you_would_kill_yourself}`}
                fieldId="a_specific_plan_of_how_you_would_kill_yourself"
                addendums={
                    fieldAddendums?.a_specific_plan_of_how_you_would_kill_yourself
                }
                content={
                    noteContent.a_specific_plan_of_how_you_would_kill_yourself ||
                    "--"
                }
            />

            <NoteContentItem
                headerText={`4. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.access_to_weapons_or_means_of_hurting_self}`}
                fieldId="access_to_weapons_or_means_of_hurting_self"
                addendums={
                    fieldAddendums?.access_to_weapons_or_means_of_hurting_self
                }
                content={
                    noteContent.access_to_weapons_or_means_of_hurting_self ||
                    "--"
                }
            />
            <NoteContentItem
                headerText={`5. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.made_serious_suicide_attempts}`}
                fieldId="made_serious_suicide_attempts"
                addendums={fieldAddendums?.made_serious_suicide_attempts}
                content={noteContent.made_serious_suicide_attempts || "--"}
            />
            <NoteContentItem
                headerText={`6. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.purposely_done_something_to_hurt_yourself}`}
                fieldId="purposely_done_something_to_hurt_yourself"
                addendums={
                    fieldAddendums?.purposely_done_something_to_hurt_yourself
                }
                content={
                    noteContent.purposely_done_something_to_hurt_yourself ||
                    "--"
                }
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.describe_done_something_to_hurt_yourself
                }
                fieldId="describe_done_something_to_hurt_yourself"
                addendums={
                    fieldAddendums?.describe_done_something_to_hurt_yourself
                }
                content={
                    noteContent.describe_done_something_to_hurt_yourself || "--"
                }
            />
            <NoteContentItem
                headerText={`7. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.heard_voices_telling_you_to_hurt_yourself}`}
                fieldId="heard_voices_telling_you_to_hurt_yourself"
                addendums={
                    fieldAddendums?.heard_voices_telling_you_to_hurt_yourself
                }
                content={
                    noteContent.heard_voices_telling_you_to_hurt_yourself ||
                    "--"
                }
            />
            <NoteContentItem
                headerText={`8. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.had_relatives_who_attempted_or_commited_sucide}`}
                fieldId="had_relatives_who_attempted_or_commited_sucide"
                addendums={
                    fieldAddendums?.had_relatives_who_attempted_or_commited_sucide
                }
                content={
                    noteContent.had_relatives_who_attempted_or_commited_sucide ||
                    "--"
                }
            />
            <NoteContentItem
                headerText={`9. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.had_thoughts_of_killing_or_seriously_hurting_someone}`}
                fieldId="had_thoughts_of_killing_or_seriously_hurting_someone"
                addendums={
                    fieldAddendums?.had_thoughts_of_killing_or_seriously_hurting_someone
                }
                content={
                    noteContent.had_thoughts_of_killing_or_seriously_hurting_someone ||
                    "--"
                }
            />

            <NoteContentItem
                headerText={`10. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.heard_voices_telling_you_to_hurt_others}`}
                fieldId="heard_voices_telling_you_to_hurt_others"
                addendums={
                    fieldAddendums?.heard_voices_telling_you_to_hurt_others
                }
                content={
                    noteContent.heard_voices_telling_you_to_hurt_others || "--"
                }
            />
            <NoteContentItem
                headerText={`11. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.hurt_someone_or_destroyed_property_on_purpose}`}
                fieldId="hurt_someone_or_destroyed_property_on_purpose"
                addendums={
                    fieldAddendums?.hurt_someone_or_destroyed_property_on_purpose
                }
                content={
                    noteContent.hurt_someone_or_destroyed_property_on_purpose ||
                    "--"
                }
            />

            <NoteContentItem
                headerText={`12. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.slapped_kicked_punched_someone_with_intent_to_harm}`}
                fieldId="slapped_kicked_punched_someone_with_intent_to_harm"
                addendums={
                    fieldAddendums?.slapped_kicked_punched_someone_with_intent_to_harm
                }
                content={
                    noteContent.slapped_kicked_punched_someone_with_intent_to_harm ||
                    "--"
                }
            />
            <NoteContentItem
                headerText={`13. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.been_arrested_or_detained_for_violent_behavior}`}
                fieldId="been_arrested_or_detained_for_violent_behavior"
                addendums={
                    fieldAddendums?.been_arrested_or_detained_for_violent_behavior
                }
                content={
                    noteContent.been_arrested_or_detained_for_violent_behavior ||
                    "--"
                }
            />
            <NoteContentItem
                headerText={`14. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.been_to_jail_for_any_reason}`}
                fieldId="been_to_jail_for_any_reason"
                addendums={fieldAddendums?.been_to_jail_for_any_reason}
                content={noteContent.been_to_jail_for_any_reason || "--"}
            />
            <NoteContentItem
                headerText={`15. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.been_on_probation_for_any_reason}`}
                fieldId="been_on_probation_for_any_reason"
                addendums={fieldAddendums?.been_on_probation_for_any_reason}
                content={noteContent.been_on_probation_for_any_reason || "--"}
            />
            <NoteContentItem
                headerText={`16. ${INDIVIDUAL_INTAKE_NOTE_DETAILS.do_you_have_access_to_guns}`}
                fieldId="do_you_have_access_to_guns"
                addendums={fieldAddendums?.do_you_have_access_to_guns}
                content={noteContent.do_you_have_access_to_guns || "--"}
            />

            <p
                className={styles.sectionheader}
                id={INTAKE_SECTIONS["mental status exam"]}
            >
                Mental status exam
            </p>
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.mental_health_appearance
                }
                fieldId="mental_health_appearance"
                addendums={fieldAddendums?.mental_health_appearance}
                content={
                    noteContent?.mental_health_appearance?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                "Neat",
                                "mental_health_appearance"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Neat",
                                                "mental_health_appearance"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Neat
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Unkempt",
                                "mental_health_appearance"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Unkempt",
                                                "mental_health_appearance"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Unkempt
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Thin",
                                "mental_health_appearance"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Thin",
                                                "mental_health_appearance"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Thin
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Average",
                                "mental_health_appearance"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Average",
                                                "mental_health_appearance"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Average
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Overweight",
                                "mental_health_appearance"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Overweight",
                                                "mental_health_appearance"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Overweight
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Pale",
                                "mental_health_appearance"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Pale",
                                                "mental_health_appearance"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Pale
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Tanned",
                                "mental_health_appearance"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Tanned",
                                                "mental_health_appearance"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Tanned
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.mental_health_movement
                }
                fieldId="mental_health_movement"
                addendums={fieldAddendums?.mental_health_movement}
                content={
                    noteContent?.mental_health_movement?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                "Coordinated",
                                "mental_health_movement"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Coordinated",
                                                "mental_health_movement"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Coordinated
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Uncoordinated",
                                "mental_health_movement"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Uncoordinated",
                                                "mental_health_movement"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Uncoordinated
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Mannerisms/oddities",
                                "mental_health_movement"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Mannerisms/oddities",
                                                "mental_health_movement"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Mannerisms/oddities
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Good eye contact",
                                "mental_health_movement"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Good eye contact",
                                                "mental_health_movement"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Good eye contact
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Kept eyes downcast",
                                "mental_health_movement"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Kept eyes downcast",
                                                "mental_health_movement"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Kept eyes downcast
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Stared into space",
                                "mental_health_movement"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Stared into space",
                                                "mental_health_movement"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Stared into space
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.mental_health_speech_behaviour
                }
                fieldId="mental_health_speech_behaviour"
                addendums={fieldAddendums?.mental_health_speech_behaviour}
                content={
                    noteContent?.mental_health_speech_behaviour?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                "Unintelligible",
                                "mental_health_speech_behaviour"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Unintelligible",
                                                "mental_health_speech_behaviour"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Unintelligible
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Stammer/stutter",
                                "mental_health_speech_behaviour"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Stammer/stutter",
                                                "mental_health_speech_behaviour"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Stammer/stutter
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Mumbles",
                                "mental_health_speech_behaviour"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Mumbles",
                                                "mental_health_speech_behaviour"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Mumbles
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Clear",
                                "mental_health_speech_behaviour"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Clear",
                                                "mental_health_speech_behaviour"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Clear
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Precise",
                                "mental_health_speech_behaviour"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Precise",
                                                "mental_health_speech_behaviour"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Precise
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Strident",
                                "mental_health_speech_behaviour"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Strident",
                                                "mental_health_speech_behaviour"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Strident
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Distant",
                                "mental_health_speech_behaviour"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Distant",
                                                "mental_health_speech_behaviour"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Distant
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Squeaky",
                                "mental_health_speech_behaviour"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Squeaky",
                                                "mental_health_speech_behaviour"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Squeaky
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Soft",
                                "mental_health_speech_behaviour"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Soft",
                                                "mental_health_speech_behaviour"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Soft
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Weak",
                                "mental_health_speech_behaviour"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Weak",
                                                "mental_health_speech_behaviour"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Weak
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Normal",
                                "mental_health_speech_behaviour"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Normal",
                                                "mental_health_speech_behaviour"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Normal
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Hurried",
                                "mental_health_speech_behaviour"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Hurried",
                                                "mental_health_speech_behaviour"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Hurried
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Loud",
                                "mental_health_speech_behaviour"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Loud",
                                                "mental_health_speech_behaviour"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Loud
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.mental_health_affect_mood
                }
                fieldId="mental_health_affect_mood"
                addendums={fieldAddendums?.mental_health_affect_mood}
                content={
                    noteContent?.mental_health_affect_mood?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                "Blunted",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Blunted",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Blunted
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Constricted",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Constricted",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Constricted
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Normal",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Normal",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Normal
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Broad",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Broad",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Broad
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Inappropriate",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Inappropriate",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Inappropriate
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Indifferent to problems",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Indifferent to problems",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Indifferent to problems
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Congruent",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Congruent",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Congruent
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Irritated",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Irritated",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Irritated
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Hostile",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Hostile",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Hostile
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Flushing",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Flushing",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Flushing
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Dizziness",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Dizziness",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Dizziness
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Can't sit still",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Can't sit still",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Can&apos;t sit still
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Panicked",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Panicked",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Panicked
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Fearful",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Fearful",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Fearful
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Wretched",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Wretched",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Wretched
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Melancholy",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Melancholy",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Melancholy
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Sad",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Sad",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Sad
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Hopeless",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Hopeless",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Hopeless
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Bored",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Bored",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Bored
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Sorrow",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Sorrow",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Sorrow
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Grief",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Grief",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Grief
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Flight of ideas",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Flight of ideas",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Flight of ideas
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Manic",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Manic",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Manic
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Hypomanic",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Hypomanic",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Hypomanic
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Ashamed",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Ashamed",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Ashamed
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Embarrassed",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Embarrassed",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Embarrassed
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Apologetic",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Apologetic",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Apologetic
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Calm",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Calm",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Calm
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Guilty",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Guilty",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Guilty
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Flat",
                                "mental_health_affect_mood"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Flat",
                                                "mental_health_affect_mood"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Flat
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />

            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.mental_health_attention_concentration_effort
                }
                fieldId="mental_health_attention_concentration_effort"
                addendums={
                    fieldAddendums?.mental_health_attention_concentration_effort
                }
                content={
                    noteContent?.mental_health_attention_concentration_effort
                        ?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                "Sluggish",
                                "mental_health_attention_concentration_effort"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Sluggish",
                                                "mental_health_attention_concentration_effort"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Sluggish
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Flat",
                                "mental_health_attention_concentration_effort"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Flat",
                                                "mental_health_attention_concentration_effort"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Flat
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Distractible",
                                "mental_health_attention_concentration_effort"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Distractible",
                                                "mental_health_attention_concentration_effort"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Distractible
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Normal energy",
                                "mental_health_attention_concentration_effort"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Normal energy",
                                                "mental_health_attention_concentration_effort"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Normal energy
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Eager",
                                "mental_health_attention_concentration_effort"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Eager",
                                                "mental_health_attention_concentration_effort"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Eager
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Indifferent",
                                "mental_health_attention_concentration_effort"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Indifferent",
                                                "mental_health_attention_concentration_effort"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Indifferent
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Scattered",
                                "mental_health_attention_concentration_effort"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Scattered",
                                                "mental_health_attention_concentration_effort"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Scattered
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Baffled",
                                "mental_health_attention_concentration_effort"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Baffled",
                                                "mental_health_attention_concentration_effort"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Baffled
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Perplexed",
                                "mental_health_attention_concentration_effort"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Perplexed",
                                                "mental_health_attention_concentration_effort"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Perplexed
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Hurried",
                                "mental_health_attention_concentration_effort"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Hurried",
                                                "mental_health_attention_concentration_effort"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Hurried
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Organized",
                                "mental_health_attention_concentration_effort"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Organized",
                                                "mental_health_attention_concentration_effort"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Organized
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Rigid",
                                "mental_health_attention_concentration_effort"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Rigid",
                                                "mental_health_attention_concentration_effort"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Rigid
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Pleasant",
                                "mental_health_attention_concentration_effort"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Pleasant",
                                                "mental_health_attention_concentration_effort"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Pleasant
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Cooperative",
                                "mental_health_attention_concentration_effort"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Cooperative",
                                                "mental_health_attention_concentration_effort"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Cooperative
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Dependent",
                                "mental_health_attention_concentration_effort"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Dependent",
                                                "mental_health_attention_concentration_effort"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Dependent
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Abusive",
                                "mental_health_attention_concentration_effort"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Abusive",
                                                "mental_health_attention_concentration_effort"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Abusive
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Superior",
                                "mental_health_attention_concentration_effort"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Superior",
                                                "mental_health_attention_concentration_effort"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Superior
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Stubborn",
                                "mental_health_attention_concentration_effort"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Stubborn",
                                                "mental_health_attention_concentration_effort"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Stubborn
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Belligerent",
                                "mental_health_attention_concentration_effort"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Belligerent",
                                                "mental_health_attention_concentration_effort"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Belligerent
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Argumentative",
                                "mental_health_attention_concentration_effort"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Argumentative",
                                                "mental_health_attention_concentration_effort"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Argumentative
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Hostile",
                                "mental_health_attention_concentration_effort"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Hostile",
                                                "mental_health_attention_concentration_effort"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Hostile
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Demanding",
                                "mental_health_attention_concentration_effort"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Demanding",
                                                "mental_health_attention_concentration_effort"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Demanding
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Resentful",
                                "mental_health_attention_concentration_effort"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Resentful",
                                                "mental_health_attention_concentration_effort"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Resentful
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Surly",
                                "mental_health_attention_concentration_effort"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Surly",
                                                "mental_health_attention_concentration_effort"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Surly
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Guarded",
                                "mental_health_attention_concentration_effort"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Guarded",
                                                "mental_health_attention_concentration_effort"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Guarded
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Indifferent",
                                "mental_health_attention_concentration_effort"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Indifferent",
                                                "mental_health_attention_concentration_effort"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Indifferent
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.mental_health_presentation_of_self
                }
                fieldId="mental_health_presentation_of_self"
                addendums={fieldAddendums?.mental_health_presentation_of_self}
                content={
                    noteContent?.mental_health_presentation_of_self?.length >
                    0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                "Withdrawn",
                                "mental_health_presentation_of_self"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Withdrawn",
                                                "mental_health_presentation_of_self"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Withdrawn
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Threatened",
                                "mental_health_presentation_of_self"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Threatened",
                                                "mental_health_presentation_of_self"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Threatened
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Vulnerable",
                                "mental_health_presentation_of_self"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Vulnerable",
                                                "mental_health_presentation_of_self"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Vulnerable
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Shy",
                                "mental_health_presentation_of_self"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Shy",
                                                "mental_health_presentation_of_self"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Shy
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Friendly",
                                "mental_health_presentation_of_self"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Friendly",
                                                "mental_health_presentation_of_self"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Friendly
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Confident",
                                "mental_health_presentation_of_self"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Confident",
                                                "mental_health_presentation_of_self"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Confident
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Grandiose",
                                "mental_health_presentation_of_self"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Grandiose",
                                                "mental_health_presentation_of_self"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Grandiose
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Humble",
                                "mental_health_presentation_of_self"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Humble",
                                                "mental_health_presentation_of_self"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Humble
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Self-doubting",
                                "mental_health_presentation_of_self"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Self-doubting",
                                                "mental_health_presentation_of_self"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Self-doubting
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Hopeful",
                                "mental_health_presentation_of_self"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Hopeful",
                                                "mental_health_presentation_of_self"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Hopeful
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Pessimistic",
                                "mental_health_presentation_of_self"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Pessimistic",
                                                "mental_health_presentation_of_self"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Pessimistic
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Overindulgent",
                                "mental_health_presentation_of_self"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Overindulgent",
                                                "mental_health_presentation_of_self"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Overindulgent
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Warm hearted",
                                "mental_health_presentation_of_self"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Warm hearted",
                                                "mental_health_presentation_of_self"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Warm hearted
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Distant",
                                "mental_health_presentation_of_self"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Distant",
                                                "mental_health_presentation_of_self"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Distant
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Harsh",
                                "mental_health_presentation_of_self"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Harsh",
                                                "mental_health_presentation_of_self"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Harsh
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.mental_health_cognition
                }
                fieldId="mental_health_cognition"
                addendums={fieldAddendums?.mental_health_cognition}
                content={
                    noteContent?.mental_health_cognition?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                "Indecisive",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Indecisive",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Indecisive
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Decisive",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Decisive",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Decisive
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Some insight",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Some insight",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Some insight
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Sluggish recall",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Sluggish recall",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Sluggish recall
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Normal memory",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Normal memory",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Normal memory
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Low intelligent",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Low intelligent",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Low intelligent
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Good orientation",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Good orientation",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Good orientation
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Defective reality",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Defective reality",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Defective reality
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Flight of ideas",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Flight of ideas",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Flight of ideas
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Incoherent thought process",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Incoherent thought process",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Incoherent thought process
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Procrastinates",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Procrastinates",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Procrastinates
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Follows through",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Follows through",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Follows through
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Full insight",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Full insight",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Full insight
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Amnesia",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Amnesia",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Amnesia
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Intelligent",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Intelligent",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Intelligent
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Normal judgment",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Normal judgment",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Normal judgment
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Intact reality",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Intact reality",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Intact reality
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Normal abstraction",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Normal abstraction",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Normal abstraction
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Loose thought process",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Loose thought process",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Loose thought process
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Clear thought process",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Clear thought process",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Clear thought process
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Easily confused",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Easily confused",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Easily confused
                                </div>
                            )}
                            {isCheckboxChecked(
                                "No insight",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "No insight",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    No insight
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Forgetful",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Forgetful",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Forgetful
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Confuses time frames/sequence",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Confuses time frames/sequence",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Confuses time frames/sequence
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Lack of common sense",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Lack of common sense",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Lack of common sense
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Victimization",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Victimization",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Victimization
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Confused present location/date/persons, places",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Confused present location/date/persons, places",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Confused present location/date/persons,
                                    places
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Faulty reasoning",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Faulty reasoning",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Faulty reasoning
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Impoverished stream of thoughts",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Impoverished stream of thoughts",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Impoverished stream of thoughts
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Normal stream of thoughts",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Normal stream of thoughts",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Normal stream of thoughts
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Normal stream of thoughts",
                                "mental_health_cognition"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Normal stream of thoughts",
                                                "mental_health_cognition"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Normal stream of thoughts
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.patients_ability_and_capability_to_respond_to_treatment
                }
                fieldId="patients_ability_and_capability_to_respond_to_treatment"
                addendums={
                    fieldAddendums?.patients_ability_and_capability_to_respond_to_treatment
                }
                content={
                    noteContent
                        ?.patients_ability_and_capability_to_respond_to_treatment
                        ?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                "Consistent, good-quality patient-oriented evidence",
                                "patients_ability_and_capability_to_respond_to_treatment"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Consistent, good-quality patient-oriented evidence",
                                                "patients_ability_and_capability_to_respond_to_treatment"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Consistent, good-quality patient-oriented
                                    evidence
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Diminished/poor or limited-quality patient-oriented evidence",
                                "patients_ability_and_capability_to_respond_to_treatment"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Diminished/poor or limited-quality patient-oriented evidence",
                                                "patients_ability_and_capability_to_respond_to_treatment"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Diminished/poor or limited-quality
                                    patient-oriented evidence
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.details_for_diminished_poor_limited_quality_patient_oriented_evidence
                }
                fieldId="details_for_diminished_poor_limited_quality_patient_oriented_evidence"
                addendums={
                    fieldAddendums?.details_for_diminished_poor_limited_quality_patient_oriented_evidence
                }
                content={
                    noteContent.details_for_diminished_poor_limited_quality_patient_oriented_evidence ||
                    "--"
                }
            />
            <p className={styles.sectionheader} id={INTAKE_SECTIONS.summary}>
                Summary
            </p>
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.people_present_at_session
                }
                fieldId="people_present_at_session"
                addendums={fieldAddendums?.people_present_at_session}
                content={
                    noteContent?.people_present_at_session?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                "Patient",
                                "people_present_at_session"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Patient",
                                                "people_present_at_session"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Patient
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Mother",
                                "people_present_at_session"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Mother",
                                                "people_present_at_session"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Mother
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Stepfather",
                                "people_present_at_session"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Stepfather",
                                                "people_present_at_session"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Stepfather
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Spouse",
                                "people_present_at_session"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Spouse",
                                                "people_present_at_session"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Spouse
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Father",
                                "people_present_at_session"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Father",
                                                "people_present_at_session"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Father
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Stepmother",
                                "people_present_at_session"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Stepmother",
                                                "people_present_at_session"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Stepmother
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Partner",
                                "people_present_at_session"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Partner",
                                                "people_present_at_session"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Partner
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Guardian",
                                "people_present_at_session"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Guardian",
                                                "people_present_at_session"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Guardian
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Other",
                                "people_present_at_session"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Other",
                                                "people_present_at_session"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Other
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.other_person_present_at_session
                }
                fieldId="other_person_present_at_session"
                addendums={fieldAddendums?.other_person_present_at_session}
                content={noteContent.other_person_present_at_session || "--"}
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.type_of_treatment_recommended
                }
                fieldId="type_of_treatment_recommended"
                addendums={fieldAddendums?.type_of_treatment_recommended}
                content={
                    noteContent?.type_of_treatment_recommended?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                "Individual",
                                "type_of_treatment_recommended"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Individual",
                                                "type_of_treatment_recommended"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Individual
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Family",
                                "type_of_treatment_recommended"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Family",
                                                "type_of_treatment_recommended"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Family
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Group",
                                "type_of_treatment_recommended"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Group",
                                                "type_of_treatment_recommended"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Group
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Collateral/Parenting",
                                "type_of_treatment_recommended"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Collateral/Parenting",
                                                "type_of_treatment_recommended"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Collateral/Parenting
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.treatment_modality_intervention
                }
                fieldId="treatment_modality_intervention"
                addendums={fieldAddendums?.treatment_modality_intervention}
                content={
                    noteContent?.treatment_modality_intervention?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                "Cognitive/Behavioral",
                                "treatment_modality_intervention"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Cognitive/Behavioral",
                                                "treatment_modality_intervention"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Cognitive/Behavioral
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Behavioral Modification",
                                "treatment_modality_intervention"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Behavioral Modification",
                                                "treatment_modality_intervention"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Behavioral Modification
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Supportive",
                                "treatment_modality_intervention"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Supportive",
                                                "treatment_modality_intervention"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Supportive
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Marital/Couples Therapy",
                                "treatment_modality_intervention"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Marital/Couples Therapy",
                                                "treatment_modality_intervention"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Marital/Couples Therapy
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Family Therapy",
                                "treatment_modality_intervention"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Family Therapy",
                                                "treatment_modality_intervention"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Family Therapy
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Stress Management",
                                "treatment_modality_intervention"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Stress Management",
                                                "treatment_modality_intervention"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Stress Management
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Psychodynamic",
                                "treatment_modality_intervention"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Psychodynamic",
                                                "treatment_modality_intervention"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Psychodynamic
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Parent Training",
                                "treatment_modality_intervention"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Parent Training",
                                                "treatment_modality_intervention"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Parent Training
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Crisis Intervention",
                                "treatment_modality_intervention"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Crisis Intervention",
                                                "treatment_modality_intervention"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Crisis Intervention
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Other",
                                "treatment_modality_intervention"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Other",
                                                "treatment_modality_intervention"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Other
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.treatment_modality_intervention
                }
                fieldId="treatment_modality_intervention"
                addendums={fieldAddendums?.treatment_modality_intervention}
                content={noteContent.treatment_modality_intervention || "--"}
            />

            <p
                className={styles.sectionheader}
                id={INTAKE_SECTIONS["treatment goals"]}
            >
                Treatment goals
            </p>
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.treatment_goal_one}
                fieldId="treatment_goal_one"
                addendums={fieldAddendums?.treatment_goal_one}
                content={noteContent.treatment_goal_one || "--"}
            />
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.treatment_goal_two}
                fieldId="treatment_goal_two"
                addendums={fieldAddendums?.treatment_goal_two}
                content={noteContent.treatment_goal_two || "--"}
            />
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.treatment_goal_three}
                fieldId="treatment_goal_three"
                addendums={fieldAddendums?.treatment_goal_three}
                content={noteContent.treatment_goal_three || "--"}
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.frequency_of_sessions
                }
                fieldId="frequency_of_sessions"
                addendums={fieldAddendums?.frequency_of_sessions}
                content={noteContent.frequency_of_sessions || "--"}
            />

            {noteContent.other_frequency_of_sessions ? (
                <NoteContentItem
                    headerText={
                        INDIVIDUAL_INTAKE_NOTE_DETAILS.other_frequency_of_sessions
                    }
                    fieldId="other_frequency_of_sessions"
                    addendums={fieldAddendums?.other_frequency_of_sessions}
                    content={noteContent.other_frequency_of_sessions || "--"}
                />
            ) : null}
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.date_of_next_session}
                fieldId="date_of_next_session"
                addendums={fieldAddendums?.date_of_next_session}
                content={
                    noteContent.date_of_next_session
                        ? formatDate(noteContent.date_of_next_session, true)
                        : "--"
                }
            />
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.subjective_report}
                fieldId="subjective_report"
                addendums={fieldAddendums?.subjective_report}
                content={noteContent.subjective_report || "--"}
            />
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.summary}
                fieldId="summary"
                addendums={fieldAddendums?.summary}
                content={noteContent.summary || "--"}
            />
            <NoteContentItem
                headerText={INDIVIDUAL_INTAKE_NOTE_DETAILS.prognosis}
                fieldId="prognosis"
                addendums={fieldAddendums?.prognosis}
                content={noteContent.prognosis || "--"}
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.communication_factors_impacting_treatment
                }
                fieldId="communication_factors_impacting_treatment"
                addendums={
                    fieldAddendums?.communication_factors_impacting_treatment
                }
                content={
                    noteContent?.communication_factors_impacting_treatment
                        ?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                "Need to manage maladaptive communication (e.g., related to high anxiety, high reactivity repeated questions, or disagreement) among participants",
                                "communication_factors_impacting_treatment"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Need to manage maladaptive communication (e.g., related to high anxiety, high reactivity repeated questions, or disagreement) among participants",
                                                "communication_factors_impacting_treatment"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Need to manage maladaptive communication
                                    (e.g., related to high anxiety, high
                                    reactivity repeated questions, or
                                    disagreement) among participants
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Caregiver emotions or behaviors interfered with the caregiver's understanding and ability",
                                "communication_factors_impacting_treatment"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Caregiver emotions or behaviors interfered with the caregiver's understanding and ability",
                                                "communication_factors_impacting_treatment"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Caregiver emotions or behaviors interfered
                                    with the caregiver&apos;s understanding and
                                    ability
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Evidence or disclosure of a sentinel event and mandated reporting to a third party",
                                "communication_factors_impacting_treatment"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Evidence or disclosure of a sentinel event and mandated reporting to a third party",
                                                "communication_factors_impacting_treatment"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Evidence or disclosure of a sentinel event
                                    and mandated reporting to a third party
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Use of play equipment or other physical devices to communicate with the client to overcome barriers to therapeutic or diagnostic interaction",
                                "communication_factors_impacting_treatment"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Use of play equipment or other physical devices to communicate with the client to overcome barriers to therapeutic or diagnostic interaction",
                                                "communication_factors_impacting_treatment"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Use of play equipment or other physical
                                    devices to communicate with the client to
                                    overcome barriers to therapeutic or
                                    diagnostic interaction
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Client has not developed, or lost, either the expressive language communication skills to explain their symptoms and respond to treatment",
                                "communication_factors_impacting_treatment"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: isCheckboxChecked(
                                                "Client has not developed, or lost, either the expressive language communication skills to explain their symptoms and respond to treatment",
                                                "communication_factors_impacting_treatment"
                                            ),
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Client has not developed, or lost, either
                                    the expressive language communication skills
                                    to explain their symptoms and respond to
                                    treatment
                                </div>
                            )}
                            <div className={styles.checkbox}>
                                <span
                                    className={cn(styles.checkbox_wrap, {
                                        [styles.checked]: isCheckboxChecked(
                                            "Client lacks the receptive communication skills to understand the therapist when using typical language for communication",
                                            "communication_factors_impacting_treatment"
                                        ),
                                    })}
                                >
                                    <CheckIcon />
                                </span>
                                Client lacks the receptive communication skills
                                to understand the therapist when using typical
                                language for communication
                            </div>
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={
                    INDIVIDUAL_INTAKE_NOTE_DETAILS.details_around_communication_factors_impacting_treatment
                }
                fieldId="details_around_communication_factors_impacting_treatment"
                addendums={
                    fieldAddendums?.details_around_communication_factors_impacting_treatment
                }
                content={
                    noteContent.details_around_communication_factors_impacting_treatment ||
                    "--"
                }
            />
        </>
    );
}
