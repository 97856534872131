/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import { ColumnDef, Row } from "@tanstack/react-table";
import cn from "classnames";
import {
    Link,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import { format } from "date-fns";
import {
    Button,
    Checkbox,
    DatePicker,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Pagination,
    Popover,
    PopoverContent,
    PopoverTrigger,
    SearchInput,
    Table,
} from "@jhool-io/fe-components";
import {
    formatDate,
    getTotalAmountOfTableRowsSelected,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../../utils/helpers";
import { useDebounce } from "../../../../hooks/helpers";
import { useExportNotes } from "../../../../hooks/mutations/note";
import useToast from "../../../../hooks/useToast";
import { momentNotTz } from "../../../../utils/moment";
import { useExportPdfs } from "../../../../hooks/queries";
import { useFetchClientNotes } from "../../hooks/clients.queries";
import {
    NoteLabels,
    NoteTypes,
    noteTypesForExport,
} from "../../../notes/types/notes.types";
import MoreButton from "../../../../shared-ui/Buttons/MoreButton/MoreButton";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";
import CheckPrimaryColorIcon from "../../../../components/Icons/CheckPrimaryColor";
import TableFiltersDisplay from "../../../../shared-ui/TableFiltersDisplay/TableFiltersDisplay";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import { APP_COLORS, LISTS_DEFAULT_LIMIT } from "../../../../utils/constants";
import { IClientNote } from "../../types/clients.types";
import MobileListItem from "../../../../shared-ui/MobileListItem/MobileListItem";
import { getNoteStatusTag } from "../../../notes/helpers/notes.helpers";
import FileExportIcon from "../../../../components/Icons/FileExport";

export default function ClientNotesList() {
    const [jobId, setJobId] = React.useState<string | null>(null);
    const [showLoader, setShowLoader] = React.useState(false);
    const [rowSelection, setRowSelection] = React.useState({});
    const [selectedNotesId, setSelectedNotesId] = React.useState<string[]>([]);
    const [pdfCount, setPdfCount] = React.useState(0);

    const [searchParams, setSearchParams] = useSearchParams();

    // Get filters from params
    const pageFilter = Number(searchParams.get("page")) || 1;
    const limitFilter =
        Number(searchParams.get("limit")) || LISTS_DEFAULT_LIMIT;
    const fromDateFilter = searchParams.get("from_date")
        ? momentNotTz(searchParams.get("from_date")).toDate()
        : null;
    const toDateFilter = searchParams.get("to_date")
        ? momentNotTz(searchParams.get("to_date")).toDate()
        : null;
    const providerNameFilter = searchParams.get("provider_name") || "";
    const typeFilter = searchParams.getAll("type[]");
    const showArchivedFilter = searchParams.get("show_archived") || "false";

    const navigate = useNavigate();

    // Get id from url params
    const params = useParams();
    const clientId = params.clientId as string;

    // Use debounce hook for provider's name filter input
    const debouncedProviderNameFilter = useDebounce(
        providerNameFilter || "",
        500
    );

    const showArchivedFilterForApi =
        showArchivedFilter === "all" ? "" : showArchivedFilter;

    const screenIsGreaterThan450 =
        window.matchMedia("(min-width: 450px)").matches;

    // Hook for fetching notes
    const { data, error, isLoading, isSuccess } = useFetchClientNotes(
        clientId,
        {
            provider_name: debouncedProviderNameFilter,
            to_date: toDateFilter,
            from_date: fromDateFilter,
            type: typeFilter,
            page: pageFilter,
            limit: limitFilter,
            show_archived: showArchivedFilterForApi,
        }
    );

    // Function to determine if export button should be shown or hidden
    const handleShouldExportButtonBeVisible = () => {
        if (data) {
            const selectionObjectKeys = Object.keys(rowSelection);
            const draftNotesPresent = data?.data.filter((_, i) =>
                selectionObjectKeys.includes(String(i))
            );
            return draftNotesPresent.length === 0;
        }

        return false;
    };

    /** FILTER INPUTS ONCHANGE FUNCTIONS */
    const handleSetProviderNameFilter = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (e.target.value === "") searchParams.delete("provider_name");
        else searchParams.set("provider_name", e.target.value);
        searchParams.set("page", "1");

        setSearchParams(searchParams);
    };

    const handleSetTypeFilter = (type: string) => {
        if (typeFilter.includes(type)) {
            searchParams.delete("type[]", type);
        } else {
            searchParams.append("type[]", type);
        }
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleSetArchivedNotesFilter = (archived: string) => {
        if (archived === "") searchParams.delete("show_archived");
        else searchParams.set("show_archived", archived);
        setSearchParams(searchParams);
    };

    const handleFromDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("from_date");
        else searchParams.set("from_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleToDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("to_date");
        else searchParams.set("to_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleSetPageFilter = (page: number) => {
        searchParams.set("page", String(page));
        setSearchParams(searchParams);
    };

    const handleSetLimitFilter = (limit: number) => {
        searchParams.set("limit", String(limit));
        setSearchParams(searchParams);
    };

    const getArchivedNoteFilterToDisplay = () => {
        if (showArchivedFilter === "true") {
            return "Archived notes";
        }
        if (showArchivedFilter === "false") {
            return "Unarchived notes";
        }

        return "Show all notes";
    };

    const exportNotes = useExportNotes();

    const { toast } = useToast();

    const handleExportNotes = (noteId?: string) => {
        let dataToSend;
        if (noteId) {
            dataToSend = {
                session_note_ids: [noteId],
            };
        } else {
            const notesId = data?.data
                .filter((note) => {
                    return selectedNotesId.includes(note.note_id);
                })
                .map((note) => note.note_id);
            dataToSend = {
                session_note_ids: notesId as string[],
            };
        }

        exportNotes.mutate(dataToSend, {
            onSuccess: async (res) => {
                setShowLoader(true);
                setSelectedNotesId([]);
                await new Promise<void>((resolve) => {
                    const timer = setTimeout(() => {
                        clearTimeout(timer);
                        setJobId(res.data.export_job_id);
                        setPdfCount(res.data.expected_pdf_count);
                        resolve();
                    }, 6000);
                });
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.message || "Could not export notes at this time",
                });
            },
        });
    };

    const exportNote = useExportPdfs(
        {
            export_job_id: jobId as string,
            pdf_type: "session_note",
            expected_pdf_count: pdfCount,
        },
        Boolean(jobId)
    );

    if (jobId && !exportNote.isLoading) {
        setJobId(null);
        setShowLoader(false);
    }

    const handleRowClick = (row: Row<IClientNote>) => {
        searchParams.set("clientId", clientId);
        navigate(`/notes/${clientId}/${row.original.note_id}`);
    };

    // Table columns
    const columns: ColumnDef<IClientNote>[] = [
        {
            id: "select",
            header: ({ table }) => (
                <Checkbox
                    handleChange={table.getToggleAllRowsSelectedHandler()}
                    indeterminate={table.getIsSomeRowsSelected()}
                    label=""
                    name="row"
                    value="row"
                    isChecked={table.getIsAllRowsSelected()}
                />
            ),
            cell: ({ row }) => (
                <Checkbox
                    handleChange={row.getToggleSelectedHandler()}
                    indeterminate={row.getIsSomeSelected()}
                    label=""
                    name="row"
                    value="row"
                    isChecked={row.getIsSelected()}
                />
            ),
        },
        {
            accessorKey: "date_of_service",
            header: "DATE OF SERVICE",
            cell: ({ row }) => formatDate(row.original.date_of_service),
        },
        {
            accessorKey: "note_type",
            header: "Note Type",
            cell: ({ row }) => NoteLabels[row.original.note_type],
        },
        {
            header: "Provider",
            cell: ({ row }) =>
                `${row.original.provider.first_name} ${row.original.provider.last_name}`,
        },
        {
            accessorKey: "created_at",
            header: "CREATED ON",
            cell: ({ row }) => formatDate(row.original.created_at),
        },
        {
            accessorKey: "note_id",
            header: "",
            cell: ({ row }) =>
                noteTypesForExport.includes(row.original.note_type) && (
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <MoreButton onClick={(e) => e.stopPropagation()} />
                        </DropdownTrigger>
                        <DropdownContent width="auto" align="end">
                            <DropdownItem
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleExportNotes(row.original.note_id);
                                }}
                            >
                                Export to pdf
                            </DropdownItem>
                        </DropdownContent>
                    </Dropdown>
                ),
        },
    ];

    // Toggles note selection for mobile
    const toggleNoteSelection = (noteId: string) => {
        if (selectedNotesId.includes(noteId)) {
            setSelectedNotesId(selectedNotesId.filter((id) => id !== noteId));
        } else {
            setSelectedNotesId([...selectedNotesId, noteId]);
        }
    };

    return (
        <>
            <Dialog open={showLoader}>
                <DialogContent
                    title="Export in progress"
                    variant="center"
                    handleCloseDialog={() => setShowLoader(false)}
                    showFooter={false}
                >
                    <div className="loader">
                        <div className="loader-icon" />
                        <div className="loader-text">
                            Please wait while the export is in progress.
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            <div>
                <div className="rounded-r8 bg-white p-16 mb-32">
                    <div className="flex flex-col gap-y-24">
                        <SearchInput
                            onChange={handleSetProviderNameFilter}
                            defaultValue={providerNameFilter || ""}
                            containerClass="w-[400px] max-w-full"
                            placeholder="Search by provider name"
                        />
                        <div className="flex items-center gap-x-12 flex-wrap gap-y-12">
                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <FilterButton text="Note type" />
                                </DropdownTrigger>
                                <DropdownContent
                                    align="end"
                                    width="auto"
                                    maxHeight={216}
                                    className="overflow-y-auto"
                                >
                                    {Object.values(NoteTypes).map((type) => (
                                        <DropdownItem
                                            key={type}
                                            onClick={() =>
                                                handleSetTypeFilter(type)
                                            }
                                            isMulti
                                            itemValue={type}
                                            values={typeFilter}
                                        >
                                            {makeStringFirstLetterCapital(
                                                removeEnumUnderscore(
                                                    NoteLabels[type]
                                                )
                                            )}
                                        </DropdownItem>
                                    ))}
                                </DropdownContent>
                            </Dropdown>
                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <FilterButton
                                        classNames="normal-case"
                                        text={getArchivedNoteFilterToDisplay()}
                                    />
                                </DropdownTrigger>
                                <DropdownContent>
                                    <DropdownItem
                                        className={cn(
                                            "flex gap-x-8 items-center text-xs font-medium",
                                            {
                                                "text-primary":
                                                    showArchivedFilter ===
                                                    "all",
                                            }
                                        )}
                                        onClick={() =>
                                            handleSetArchivedNotesFilter("all")
                                        }
                                    >
                                        All notes
                                        {!showArchivedFilter ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>
                                    <DropdownItem
                                        className={cn(
                                            "flex gap-x-8 items-center text-xs font-medium",
                                            {
                                                "text-primary":
                                                    showArchivedFilter ===
                                                    "true",
                                            }
                                        )}
                                        onClick={() =>
                                            handleSetArchivedNotesFilter("true")
                                        }
                                    >
                                        Archived notes
                                        {showArchivedFilter === "true" ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>
                                    <DropdownItem
                                        className={cn(
                                            "flex gap-x-8 items-center text-xs font-medium",
                                            {
                                                "text-primary":
                                                    showArchivedFilter ===
                                                    "false",
                                            }
                                        )}
                                        onClick={() =>
                                            handleSetArchivedNotesFilter(
                                                "false"
                                            )
                                        }
                                    >
                                        Unarchived notes
                                        {showArchivedFilter === "false" ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>
                                </DropdownContent>
                            </Dropdown>
                            <Popover>
                                <PopoverTrigger asChild>
                                    <FilterButton text="Date" />
                                </PopoverTrigger>
                                <PopoverContent
                                    align={
                                        screenIsGreaterThan450 ? "end" : "start"
                                    }
                                    sideOffset={20}
                                    className="h-auto w-96 p-16 z-20 gap-y-12"
                                >
                                    <DatePicker
                                        label="From"
                                        placeholderText="MM/DD/YYYY"
                                        selected={fromDateFilter}
                                        onChange={handleFromDateFilterChange}
                                        className="hover:border-primary"
                                        maxDate={
                                            toDateFilter || new Date(Date.now())
                                        }
                                        isClearable
                                    />
                                    <DatePicker
                                        label="To"
                                        placeholderText="MM/DD/YYYY"
                                        selected={toDateFilter}
                                        onChange={handleToDateFilterChange}
                                        className="hover:border-primary"
                                        minDate={fromDateFilter}
                                        maxDate={new Date(Date.now())}
                                        isClearable
                                    />
                                </PopoverContent>
                            </Popover>
                        </div>
                    </div>

                    <TableFiltersDisplay
                        appliedFilters={[
                            {
                                key: "type[]",
                                values: typeFilter.map(
                                    (type) => NoteLabels[type]
                                ),
                            },
                        ]}
                        wrapperClass="mt-12"
                    />
                </div>
                {isLoading && (
                    <Skeleton
                        type="table"
                        containerTestId="notes-loader"
                        count={6}
                    />
                )}
                {error && (
                    <ListState
                        isError
                        stateHelperText="Try reloading this page to solve this issue"
                        errorMsg={
                            error?.response?.data.message ||
                            `Cannot display client notes at this time please try again later`
                        }
                    />
                )}
                {data && isSuccess && data.data.length === 0 && (
                    <ListState
                        isError={false}
                        stateHelperText="Client notes will appear here when added"
                    />
                )}
                {data && isSuccess && data.data.length > 0 && (
                    <>
                        {getTotalAmountOfTableRowsSelected(rowSelection)
                            .amount > 0 ? (
                            <div className="flex items-center gap-x-8 text-sm mb-16">
                                <span className="font-semibold">
                                    {
                                        getTotalAmountOfTableRowsSelected(
                                            rowSelection
                                        ).amountString
                                    }
                                </span>
                                {handleShouldExportButtonBeVisible() && (
                                    <Button variant="secondary">Export</Button>
                                )}
                            </div>
                        ) : null}
                        {selectedNotesId.length > 0 && (
                            <div className="mb-16 flex items-center gap-x-8 text-sm">
                                <span>
                                    {`${selectedNotesId.length}  selected`} :
                                </span>
                                <Dropdown>
                                    <DropdownTrigger asChild>
                                        <FilterButton
                                            classNames="bg-primary normal-case text-white *:stroke-white"
                                            text="Actions"
                                            stroke={APP_COLORS.COLOR_WHITE}
                                        />
                                    </DropdownTrigger>
                                    <DropdownContent width="auto">
                                        <DropdownItem
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleExportNotes();
                                            }}
                                        >
                                            Export to pdf
                                        </DropdownItem>
                                    </DropdownContent>
                                </Dropdown>
                            </div>
                        )}
                        <div className="hidden md:block">
                            <Table
                                data={data.data}
                                columns={columns}
                                rowSelection={rowSelection}
                                setRowSelection={setRowSelection}
                                handleRowClick={handleRowClick}
                                hasPagination={data.total_count > 20}
                                pagination={
                                    <Pagination
                                        totalCount={data.total_count}
                                        totalPages={data.total_pages}
                                        currentPage={pageFilter}
                                        onPageChange={handleSetPageFilter}
                                        limit={limitFilter}
                                        onLimitChange={handleSetLimitFilter}
                                    />
                                }
                            />
                        </div>
                        <div className="block md:hidden" role="table">
                            <div className="flex flex-col gap-y-12">
                                {data.data.map((note) => (
                                    <MobileListItem
                                        key={note.note_id}
                                        onClickCheckButton={() =>
                                            toggleNoteSelection(note.note_id)
                                        }
                                        isSelected={selectedNotesId.includes(
                                            note.note_id
                                        )}
                                        showCheckButton
                                        topChildren={
                                            <div className="flex items-center gap-x-8 text-base font-semibold capitalize">
                                                <span>
                                                    {getNoteStatusTag(
                                                        note.status
                                                    )}
                                                </span>
                                                {noteTypesForExport.includes(
                                                    note.note_type
                                                ) && (
                                                    <Button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleExportNotes(
                                                                note.note_id
                                                            );
                                                        }}
                                                        size="auto"
                                                        variant="normal"
                                                        className="!rounded-full size-[30px] bg-card-bg"
                                                    >
                                                        <FileExportIcon className="size-16" />
                                                    </Button>
                                                )}
                                            </div>
                                        }
                                        bottomChildren={
                                            <Link
                                                className="flex flex-col gap-y-8 w-full items-start"
                                                to={`/notes/${clientId}/${note.note_id}`}
                                            >
                                                <div className="flex items-center w-full text-left gap-x-4">
                                                    <span className="basis-1/2 text-gray font-semibold text-xs">
                                                        {formatDate(
                                                            note.date_of_service
                                                        )}
                                                    </span>
                                                    <span className="basis-1/2 font-semibold text-xs">
                                                        {
                                                            NoteLabels[
                                                                note.note_type
                                                            ]
                                                        }
                                                    </span>
                                                </div>
                                                <div className="flex items-center w-full text-left gap-x-4">
                                                    <span className="w-1/2 text-gray font-semibold text-xs">
                                                        Provider:
                                                    </span>
                                                    <span className="w-1/2 font-semibold text-xs">
                                                        {`${note.provider?.first_name} ${note.provider?.last_name}`}
                                                    </span>
                                                </div>
                                                <div className="flex items-center w-full text-left gap-x-4">
                                                    <span className="w-1/2 text-gray font-semibold text-xs">
                                                        Created on:
                                                    </span>
                                                    <span className="w-1/2 font-semibold text-xs">
                                                        {formatDate(
                                                            note.created_at
                                                        )}
                                                    </span>
                                                </div>
                                            </Link>
                                        }
                                    />
                                ))}
                            </div>
                            {data.total_count > 20 && (
                                <div className="bg-white rounde-r8 p-12">
                                    <Pagination
                                        totalCount={data.total_count}
                                        totalPages={data.total_pages}
                                        currentPage={pageFilter}
                                        onPageChange={handleSetPageFilter}
                                        limit={limitFilter}
                                        onLimitChange={handleSetLimitFilter}
                                    />
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
