import {
    Button,
    Card,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
} from "@jhool-io/fe-components";
import React from "react";
import { useSearchParams } from "react-router-dom";
import Navbar from "../../../components/Navbar/Navbar";
import { useFetchInsuranceProviders } from "../../../hooks/queries/client";
import ChevronDownIcon from "../../../components/Icons/ChevronDown";
import { APP_COLORS } from "../../../utils/constants";
import {
    cn,
    removeEnumUnderscore,
    truncateString,
} from "../../../utils/helpers";
import PendingTasks from "../components/PendingTasks/PendingTasks";
import BillingStatCards from "../components/BillingStatCards/BillingStatCards";
import { useFetchBillingOverview } from "../hooks/dashboard.queries";
import ListState from "../../../components/ListState/ListState";
import CheckPrimaryColorIcon from "../../../components/Icons/CheckPrimaryColor";

export default function BillersDashboard() {
    const insuranceProviders = useFetchInsuranceProviders();

    const [searchParams, setSearchParams] = useSearchParams();

    const handleInsuranceProviderChange = (value: string) => {
        if (value === "") searchParams.delete("insurance_provider");
        else searchParams.set("insurance_provider", value);
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const insuranceProviderFilter =
        searchParams.get("insurance_provider") || "";

    const { data, isLoading, error } = useFetchBillingOverview({
        insurance_provider_id: insuranceProviderFilter,
    });

    const getInsuranceTextToDisplay = React.useMemo(
        () =>
            insuranceProviders?.data?.data.find(
                (provider) =>
                    provider.insurance_provider_id === insuranceProviderFilter
            )?.name,
        [insuranceProviderFilter, insuranceProviders?.data?.data]
    );

    let isSmallerScreen = false;

    if (window) {
        isSmallerScreen =
            window && window.matchMedia("(max-width: 768px)").matches;
    }

    return (
        <>
            <Navbar />
            <div className="page">
                {error && error?.response?.status !== 404 && (
                    <ListState
                        isError
                        stateHelperText="Try reloading this page to solve this issue"
                        errorMsg={
                            error?.response?.data.message ||
                            `Cannot display expiring policies at this time please try again later`
                        }
                    />
                )}
                {!error && (
                    <Card classNames="border border-strokedark p-16 md:py-32 md:px-24">
                        <h2 className="text-xl md:text-xxl font-bold mb-8 md:mb-12">
                            Overview
                        </h2>
                        <div className="flex flex-col md:flex-row gap-y-24 md:justify-between md:items-center ">
                            <p className="text-sm font-normal text-graydark">
                                Manage your billing tasks and stay updated with
                                key metrics.
                            </p>
                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        disabled={
                                            Boolean(insuranceProviders.error) ||
                                            insuranceProviders.isLoading
                                        }
                                        className={cn(
                                            "px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize max-md:self-start",
                                            {
                                                uppercase:
                                                    insuranceProviderFilter,
                                            }
                                        )}
                                    >
                                        {insuranceProviders.isLoading &&
                                            "Loading.."}
                                        {insuranceProviders.error &&
                                            "Error loading providers"}
                                        {insuranceProviders?.data?.data && (
                                            <>
                                                {getInsuranceTextToDisplay
                                                    ? truncateString(
                                                          removeEnumUnderscore(
                                                              getInsuranceTextToDisplay
                                                          )
                                                      )
                                                    : "Insurance"}
                                                <ChevronDownIcon
                                                    stroke={
                                                        APP_COLORS.COLOR_BLACK
                                                    }
                                                />
                                            </>
                                        )}
                                    </Button>
                                </DropdownTrigger>
                                <DropdownContent
                                    align={isSmallerScreen ? "start" : "end"}
                                    width={isSmallerScreen ? "60%" : "auto"}
                                    maxHeight={216}
                                    className="overflow-y-auto"
                                >
                                    <DropdownItem
                                        className={cn(
                                            "flex justify-between items-center text-xs font-medium",
                                            {
                                                "text-primary":
                                                    insuranceProviderFilter ===
                                                    "",
                                            }
                                        )}
                                        onClick={() =>
                                            handleInsuranceProviderChange("")
                                        }
                                    >
                                        All
                                        {insuranceProviderFilter === "" ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>
                                    {insuranceProviders?.data?.data
                                        .sort((a, b) =>
                                            a.name.localeCompare(b.name)
                                        )
                                        .map((provider) => (
                                            <DropdownItem
                                                className={cn(
                                                    "flex justify-between items-center uppercase text-xs font-medium",
                                                    {
                                                        "text-primary":
                                                            insuranceProviderFilter ===
                                                            provider.insurance_provider_id,
                                                    }
                                                )}
                                                key={
                                                    provider.insurance_provider_id
                                                }
                                                onClick={() =>
                                                    handleInsuranceProviderChange(
                                                        provider.insurance_provider_id
                                                    )
                                                }
                                            >
                                                {provider.name}
                                                {insuranceProviderFilter ===
                                                provider.insurance_provider_id ? (
                                                    <CheckPrimaryColorIcon />
                                                ) : null}
                                            </DropdownItem>
                                        ))}
                                </DropdownContent>
                            </Dropdown>
                        </div>
                        <div className="mt-24">
                            <BillingStatCards
                                billingStats={data}
                                isLoading={isLoading}
                            />
                        </div>
                    </Card>
                )}
                <div className="mt-16 md:mt-32">
                    <Card classNames="border border-strokedark p-16 md:py-32 md:px-24">
                        <h2 className="text-xl md:text-xxl font-bold mb-8 md:mb-12">
                            Tasks
                        </h2>
                        <p className="text-sm font-normal text-graydark mb-24">
                            You can manage your task activities here
                        </p>
                        <PendingTasks />
                    </Card>
                </div>
            </div>
        </>
    );
}
