import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { parseISO } from "date-fns";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { DatePicker, Input, Select } from "@jhool-io/fe-components";
import { APP_COLORS } from "../../../../../utils/constants";
import { IClientFee, IEditClientFee } from "../../../../../utils/types/client";
import { useFetchUserDetails } from "../../../../../hooks/queries/user";
import { useEditClientCurrentFee } from "../../../../../hooks/mutations/client";
import useToast from "../../../../../hooks/useToast";
import {
    cn,
    handleFormatDatePickerValue,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../../../utils/helpers";
import { calculateFees } from "../../../../../utils/helpers/fees/fees";
import useShowInsuranceOverSelfPay from "../../../../../modules/practice-settings/hooks/useShowInsuranceOverSelfPay";

interface FeeFormProps {
    // Current fee in view
    clientFee: IClientFee;
    // Function to call when form submit button is clicked
    onFormSubmit(): void;
}

// Option tyoe for select
type Option = {
    label: React.ReactNode;
    value: boolean;
};

// Select options for boolean select fields
const options: Option[] = [
    {
        value: true,
        label: <span style={{ color: APP_COLORS.COLOR_PRIMARY }}>Yes</span>,
    },
    {
        value: false,
        label: <span style={{ color: APP_COLORS.COLOR_DANGER }}>No</span>,
    },
];

const schema = yup.object({
    cal_year: yup.date().required("Calender Year is required"),
    start_date: yup.date().required("Start Date is required"),
    end_date: yup
        .date()
        .required("End Date is required")
        .min(
            yup.ref("start_date"),
            "The end date cannot be earlier than the start date"
        ),
    self_pay: yup.boolean(),
    accepts_assignment: yup.boolean(),
    alt_email_sent: yup.boolean(),
    INNDed: yup.string(),
    INNOOP: yup.string(),
    INNCo: yup.string(),
    INNCoins: yup.string(),
    OONDed: yup.string(),
    OONOOP: yup.string(),
    OONCo: yup.string(),
    OONCoins: yup.string(),
    UCR: yup.string(),
    fee_values: yup.array().of(
        yup.object().shape({
            value: yup.string().test({
                test: (val) =>
                    val ? Boolean(calculateFees(val as string)) : true,
                message: "Invalid fee format",
            }),
        })
    ),
});

export default function EditFees({ clientFee, onFormSubmit }: FeeFormProps) {
    // Local component state
    const [startDate, setStartDate] = React.useState<Date | null>(
        parseISO(clientFee.start_date)
    );
    const [endDate, setEndDate] = React.useState<Date | null>(
        parseISO(clientFee.end_date)
    );

    // Query client
    const queryClient = useQueryClient();

    // Get id from url params
    const params = useParams();
    const clientId = params.clientId as string;

    const showInsOverSelfPay = useShowInsuranceOverSelfPay();

    // Get logged user details
    const loggedInUser = useFetchUserDetails();

    // Hook for toast
    const { toast } = useToast();

    // Hook for editing current fee
    const { mutate } = useEditClientCurrentFee(clientId, clientFee.fee_id);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<IEditClientFee>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    // Form submission function
    const onSubmit = (payload: IEditClientFee) => {
        const dataToSend = {
            ...payload,
            author: loggedInUser.data?.user_id,
            start_date: handleFormatDatePickerValue(payload.start_date),
            end_date: handleFormatDatePickerValue(payload.end_date),
            fee_id: clientFee.fee_id,
            fee_values: clientFee.fee_values.map((fee, i) => ({
                type: fee.type,
                value: payload.fee_values[i].value,
                fee_value_id: fee.fee_value_id,
            })),
        };

        if (clientFee.cal_year !== payload.cal_year)
            dataToSend.cal_year = new Date(payload.cal_year).getFullYear();

        mutate(dataToSend, {
            onSuccess: (res) => {
                queryClient.invalidateQueries({
                    queryKey: [clientId, "fees"],
                });
                toast({
                    mode: "success",
                    message: res.message || "Current fee edited successfully",
                });
                onFormSubmit();
            },
            onError: (error) => {
                toast({
                    mode: "error",
                    message:
                        error.response?.data.message ||
                        "Could not edit current fee at this time",
                });
            },
        });
    };

    return (
        <form id="edit-fees" onSubmit={handleSubmit(onSubmit)}>
            <div className="fg">
                <Input
                    {...register("cal_year")}
                    defaultValue={clientFee.cal_year}
                    label="Calender Year"
                    placeholder="Calender Year"
                    hasError={!!errors.cal_year}
                    errorText={
                        errors.cal_year?.message?.includes(
                            "cal_year must be a `date` type, but the final value was"
                        )
                            ? "Invalid date"
                            : errors.cal_year?.message
                    }
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Controller
                    name="start_date"
                    control={control}
                    defaultValue={clientFee.start_date}
                    render={({ field }) => (
                        <DatePicker
                            label="Start Date"
                            onChange={(date) => {
                                field.onChange(date);
                                setStartDate(date);
                            }}
                            selected={startDate}
                            hasError={!!errors.start_date}
                            errorText={
                                errors.start_date?.type === "typeError"
                                    ? "Invalid date value"
                                    : errors.start_date?.message
                            }
                        />
                    )}
                />
                <Controller
                    name="end_date"
                    control={control}
                    defaultValue={clientFee.end_date}
                    render={({ field }) => (
                        <DatePicker
                            label="End Date"
                            onChange={(date) => {
                                field.onChange(date);
                                setEndDate(date);
                            }}
                            selected={endDate}
                            hasError={!!errors.end_date}
                            errorText={
                                errors.start_date?.type === "typeError"
                                    ? "Invalid date value"
                                    : errors.end_date?.message
                            }
                        />
                    )}
                />
            </div>
            <div
                className={cn("fg", {
                    "fg-space-between two flex": !showInsOverSelfPay,
                })}
            >
                {!showInsOverSelfPay && (
                    <Controller
                        name="self_pay"
                        control={control}
                        defaultValue={
                            options.find(
                                (option) => option.value === clientFee.self_pay
                            )?.value
                        }
                        render={({ field }) => (
                            <Select
                                defaultValue={options.find(
                                    (option) =>
                                        option.value === clientFee.self_pay
                                )}
                                label="Self Pay"
                                options={options}
                                onChange={(val) =>
                                    field.onChange((val as Option).value)
                                }
                                hasError={!!errors.self_pay}
                                errorText={errors.self_pay?.message}
                            />
                        )}
                    />
                )}
                <Controller
                    name="accepts_assignment"
                    control={control}
                    defaultValue={
                        options.find(
                            (option) =>
                                option.value === clientFee.accepts_assignment
                        )?.value
                    }
                    render={({ field }) => (
                        <Select
                            defaultValue={options.find(
                                (option) =>
                                    option.value ===
                                    clientFee.accepts_assignment
                            )}
                            label="Accepts Assignment"
                            options={options}
                            onChange={(val) =>
                                field.onChange((val as Option).value)
                            }
                            hasError={!!errors.accepts_assignment}
                            errorText={errors.accepts_assignment?.message}
                        />
                    )}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Controller
                    name="alt_email_sent"
                    control={control}
                    defaultValue={
                        options.find(
                            (option) =>
                                option.value === clientFee.alt_email_sent
                        )?.value
                    }
                    render={({ field }) => (
                        <Select
                            defaultValue={options.find(
                                (option) =>
                                    option.value === clientFee.alt_email_sent
                            )}
                            label="Alt Email Sent"
                            options={options}
                            onChange={(val) =>
                                field.onChange((val as Option).value)
                            }
                            hasError={!!errors.alt_email_sent}
                            errorText={errors.alt_email_sent?.message}
                        />
                    )}
                />

                <Input
                    {...register("INNDed")}
                    defaultValue={clientFee.innded || ""}
                    label="INNDed"
                    placeholder="INNDed"
                    hasError={!!errors.INNDed}
                    errorText={errors.INNDed?.message}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Input
                    {...register("INNCo")}
                    defaultValue={clientFee.innco || ""}
                    label="INNCo"
                    placeholder="INNCo"
                    hasError={!!errors.INNCo}
                    errorText={errors.INNCo?.message}
                />
                <Input
                    {...register("INNOOP")}
                    defaultValue={clientFee.innoop || ""}
                    label="INNOOP"
                    placeholder="INNOOP"
                    hasError={!!errors.INNOOP}
                    errorText={errors.INNOOP?.message}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Input
                    {...register("INNCoins")}
                    defaultValue={clientFee.inncoins || ""}
                    label="INNCoins"
                    placeholder="INNCoins"
                    hasError={!!errors.INNCoins}
                    errorText={errors.INNCoins?.message}
                />

                <Input
                    {...register("OONDed")}
                    defaultValue={clientFee.oonded || ""}
                    label="OONDed"
                    placeholder="OONDed"
                    hasError={!!errors.OONDed}
                    errorText={errors.OONDed?.message}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Input
                    {...register("OONCo")}
                    defaultValue={clientFee.oonco || ""}
                    label="OONCo"
                    placeholder="OONCo"
                    hasError={!!errors.OONCo}
                    errorText={errors.OONCo?.message}
                />
                <Input
                    {...register("OONOOP")}
                    defaultValue={clientFee.oonoop || ""}
                    label="OONOOP"
                    placeholder="OONOOP"
                    hasError={!!errors.OONOOP}
                    errorText={errors.OONOOP?.message}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Input
                    {...register("OONCoins")}
                    defaultValue={clientFee.ooncoins || ""}
                    label="OONCoins"
                    placeholder="OONCoins"
                    hasError={!!errors.OONCoins}
                    errorText={errors.OONCoins?.message}
                />

                <Input
                    {...register("UCR")}
                    defaultValue={clientFee.ucr || ""}
                    label="UCR"
                    placeholder="UCR"
                    hasError={!!errors.UCR}
                    errorText={errors.UCR?.message}
                />
            </div>
            <div className="fg-wrap">
                {clientFee.fee_values.map((fee_values, i) => (
                    <Input
                        {...register(`fee_values.${i}.value`)}
                        defaultValue={fee_values.value}
                        label={makeStringFirstLetterCapital(
                            removeEnumUnderscore(fee_values.type)
                        )}
                        placeholder={makeStringFirstLetterCapital(
                            removeEnumUnderscore(fee_values.type)
                        )}
                        hasError={!!errors?.fee_values?.[i]?.value}
                        key={fee_values.type}
                        errorText={errors?.fee_values?.[i]?.value?.message}
                    />
                ))}{" "}
            </div>
        </form>
    );
}
