import * as React from "react";
import cn from "classnames";
import styles from "./MobileListItem.module.scss";
import CheckIcon from "../Icons/Check";
import DropdownMenu, {
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "../DropdownMenu/DropdownMenu";
import Button from "../Button/Button";
import MoreIcon from "../Icons/More";

interface MobileListItemProps {
    /** determines if button is selected or not */
    isSelected?: boolean;
    /** onClick handler for list item */
    onClickListItem?(): void;
    /** onClick handler for check button */
    onClickCheckButton?(): void;
    /** handler for dropdown items */
    createDropDownItems?: React.ReactNode;
    /** List item children */
    children: React.ReactNode;
    /** determines if check button  */
    showCheckButton?: boolean;
    /** determines if ellipsis  */
    showEllipsis?: boolean;
    /** optional additional styles */
    className?: string;
    /** Optional style object to add more styling */
    style?: Record<string, string>;
}

export default function MobileListItem({
    isSelected,
    children,
    onClickListItem,
    onClickCheckButton,
    createDropDownItems,
    showCheckButton = true,
    showEllipsis = false,
    className,
    style,
}: MobileListItemProps) {
    return (
        <div
            className={cn(styles.item, className, {
                [styles.item_selected]: isSelected,
            })}
            style={style}
        >
            {showCheckButton && (
                <button
                    className={cn(styles.checkbox, {
                        [styles.checkbox_checked]: isSelected,
                    })}
                    onClick={onClickCheckButton}
                    aria-label="check button"
                >
                    <CheckIcon />
                </button>
            )}
            {showEllipsis && (
                <DropdownMenu>
                    <DropdownMenuTrigger aria-label="trigger">
                        <Button
                            type="button"
                            mode="transparent"
                            size="auto"
                            className={styles.elipsis}
                        >
                            <MoreIcon />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent contentClass={styles.marksdd}>
                        {createDropDownItems}
                    </DropdownMenuContent>
                </DropdownMenu>
            )}
            <button
                aria-label="list item"
                className={styles.main}
                onClick={onClickListItem}
            >
                {children}
            </button>
        </div>
    );
}
