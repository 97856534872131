import * as React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Input,
    TextArea,
} from "@jhool-io/fe-components";
import {
    IGetClientNotes,
    INewNoteAppoinmentDetails,
} from "../../../../utils/types/notes";
import { useFetchClientNotesList } from "../../../../hooks/queries/note";
import {
    formatDate,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../../utils/helpers";
import LoadPreviousSessionNotificationModal from "../../LoadPreviousSessionNotificatiModal/LoadPreviousSessionNotificationModal";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";

export interface ICreateGeneralUpdateNote {
    update_note_content: string;
    signature?: string;
}

interface GeneralUpdateProps {
    appointmentDetails?: INewNoteAppoinmentDetails | null;
    onSubmit: (data: ICreateGeneralUpdateNote) => void;
    currentAction?: string | null;
}

export default function GeneralUpdateNote({
    onSubmit,
    currentAction,
    appointmentDetails,
}: GeneralUpdateProps) {
    const clientNoteParams = {
        type: appointmentDetails?.note_type as string,
        load_previous_notes: true,
        provider_id: appointmentDetails?.provider_id as string,
    };

    const { data: notes } = useFetchClientNotesList(
        appointmentDetails?.client_id as string,
        clientNoteParams
    );

    const [showPrompt, setShowPrompt] = React.useState(false);
    const [selectedNote, setSelectedNote] = React.useState(
        {} as IGetClientNotes
    );

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<ICreateGeneralUpdateNote>({
        resolver: yupResolver(
            yup.object({
                update_note_content: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup.string().required("This field is required"),
                    otherwise: yup.string(),
                }),
                signature: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup.string().required("This field is required"),
                    otherwise: yup.string(),
                }),
            })
        ),
        mode: "onChange",
    });

    const handleLoadPreviousSession = (note: IGetClientNotes) => {
        setValue("update_note_content", note.note_content.update_note_content);
        setShowPrompt(false);
    };

    return (
        <>
            <LoadPreviousSessionNotificationModal
                showPrompt={showPrompt}
                setShowPrompt={setShowPrompt}
                handleLoadPreviousSession={() =>
                    handleLoadPreviousSession(selectedNote)
                }
            />
            {notes && notes.data.length > 0 && (
                <div className="flex justify-end mb-24">
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <FilterButton text="Load previous session note" />
                        </DropdownTrigger>
                        <DropdownContent width="auto" align="end">
                            {notes.data.map((note) => (
                                <DropdownItem
                                    key={note.note_id}
                                    onClick={() => {
                                        setShowPrompt(true);
                                        setSelectedNote(note);
                                    }}
                                >
                                    <div>
                                        {makeStringFirstLetterCapital(
                                            removeEnumUnderscore(note.note_type)
                                        )}{" "}
                                        - Note content Details -{" "}
                                        {formatDate(note.date_of_service)}
                                    </div>
                                </DropdownItem>
                            ))}
                        </DropdownContent>
                    </Dropdown>
                </div>
            )}

            <form
                onSubmit={handleSubmit(onSubmit)}
                aria-label="general update form"
                id="create-note-form"
            >
                <div className="fg">
                    <TextArea
                        {...register("update_note_content")}
                        label="Current Client Status (Document updates on the client current state and follow up)"
                        errorText={errors.update_note_content?.message}
                        hasError={!!errors.update_note_content}
                        onBlur={onSubmit && handleSubmit(onSubmit)}
                    />
                </div>

                <div className="fg-info fg-line">
                    <p>Sign note here</p>
                    <div className="fg">
                        <Input
                            {...register("signature")}
                            hasError={!!errors.signature}
                            errorText={errors.signature?.message}
                            label="Provider's Initials"
                            placeholder="Provider's Initials"
                            autoComplete="off"
                            isRequired
                        />
                    </div>
                </div>
            </form>
        </>
    );
}
