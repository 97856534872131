import { Tag } from "@jhool-io/fe-components";
import { removeEnumUnderscore } from "../../../utils/helpers";
import { PaymentStatus } from "../types/payments.types";

export const getPaymentStatusTag = (status: PaymentStatus) => {
    let bgColor: string;
    let textColor: string;

    switch (status) {
        case PaymentStatus.COMPLETED:
            bgColor = "#CCFAE9";
            textColor = "#00563E";
            break;
        case PaymentStatus.PENDING:
            bgColor = "#F7E5A4";
            textColor = "#634D17";
            break;
        case PaymentStatus.FAILED:
            bgColor = "#FBC7C6";
            textColor = "#981F41";
            break;
        case PaymentStatus.REFUNDED:
            bgColor = "rgba(68, 133, 229, 0.1)";
            textColor = "1px solid #4485e5";
            break;
        case PaymentStatus.VOIDED:
            bgColor = "#FBC7C6";
            textColor = "#981F41";
            break;
        default:
            bgColor = "#ACDEFA";
            textColor = "#165574";
    }

    return (
        <Tag
            bgColor={bgColor}
            textColor={textColor}
            title={removeEnumUnderscore(status)}
            className="capitalize w-auto h-auto text-nowrap px-8 py-4 rounded-r4"
        />
    );
};
