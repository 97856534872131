import {
    Button,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Tag,
} from "@jhool-io/fe-components";
import React from "react";
import moment from "../../../utils/moment";
import { CalenderEvents } from "../../../utils/types/availiability";
import MoreIcon from "../../Icons/More";
import { getColorStylesForCalendarId } from "../../../utils/constants";
import {
    doesClientRequireConsultCall,
    getNextDateOfOccurrence,
    sortSchedules,
} from "../../../utils/helpers";
import { getStringFromScheduleDate } from "../../../utils/helpers/availaibility";
import styles from "./WeekView.module.scss";

interface CalenderBoxViewProps {
    schedules?: CalenderEvents[];
}

// single event in the calendar
function SingleSchedule({ schedule }: { schedule: CalenderEvents }) {
    // background color of the schedule
    const { recurrenceRule } = schedule;
    const colorStyles = getColorStylesForCalendarId(recurrenceRule as string);
    const style = {
        background: colorStyles.backgroundColor,
        height: "100%",
        borderLeft: colorStyles.color,
    };

    return (
        <div
            className={styles["single-item"]}
            style={{
                background: style.background,
                borderLeft: `2px solid ${style.borderLeft}`,
                height: style.height,
                paddingRight: "8px",
            }}
        >
            <div className="rbc-display_name">
                {schedule.client_display_name}
            </div>
            <div className="rbc-requested-service">
                {schedule.requested_service}
            </div>
            <div className="rbc-wrapper">
                <div style={{ marginRight: 4 }}>
                    <Tag
                        bgColor={
                            getColorStylesForCalendarId(
                                schedule.recurrenceRule as string
                            ).tagBgColor
                        }
                        textColor={
                            getColorStylesForCalendarId(
                                schedule.recurrenceRule as string
                            ).textColor
                        }
                        title={schedule.recurrenceRule as string}
                        className="text-[8px]"
                    />
                </div>
                {(schedule.clinician_contacted_client ||
                    doesClientRequireConsultCall(schedule)) && (
                    <div style={{ margin: "0 4px 0 0" }}>
                        <Tag
                            bgColor="#FFDAD9"
                            textColor="#981F41"
                            title="Pending Task"
                            className="text-[8px]"
                        />
                    </div>
                )}
                {schedule.is_paused && (
                    <div>
                        <Tag
                            bgColor="#FFF3C7"
                            textColor="#926B0F"
                            title="Paused"
                            className="text-[8px]"
                        />
                    </div>
                )}
            </div>
            <div
                className="fg fg-space-between two flex"
                style={{ marginTop: 12 }}
            >
                <div>
                    <div
                        className="rbc-requested-service"
                        style={{ fontSize: "0.8rem" }}
                    >
                        {getNextDateOfOccurrence(
                            schedule,
                            moment(schedule.start).day()
                        )}
                    </div>
                </div>
                <p>
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <Button size="auto" variant="normal">
                                <MoreIcon />
                            </Button>
                        </DropdownTrigger>
                        <DropdownContent width={180} align="end">
                            <DropdownItem>Change appointment</DropdownItem>
                            <DropdownItem>Cancel next session</DropdownItem>
                            <DropdownItem>Change appointment</DropdownItem>
                        </DropdownContent>
                    </Dropdown>
                </p>
            </div>
        </div>
    );
}

export default function DashboardWeekView({ schedules }: CalenderBoxViewProps) {
    const allSchedulesByDay: { [key: string]: CalenderEvents[] } = {
        sun: [],
        mon: [],
        tue: [],
        wed: [],
        thur: [],
        fri: [],
        sat: [],
    };
    const daysOfWeek = Object.keys(allSchedulesByDay);

    // Add schedules for the rest of the month.
    schedules?.forEach((s) => {
        const start = moment(getStringFromScheduleDate(s.start));
        try {
            allSchedulesByDay[daysOfWeek[start.day()]].push(s);
        } catch (e) {
            return [];
        }
        return null;
    });

    const timeSlots = Array.from({ length: 24 }).map((_, index) => {
        const time = moment().startOf("day").add(index, "hours");
        return {
            label: time.format("LT"),
            value: time,
        };
    });

    return (
        <div className={styles["week-calendar"]}>
            <div className={styles.time}>
                <div />
                {timeSlots.map((timeSlot, index) => {
                    return (
                        <div
                            key={`${index + 1}`}
                            className={styles["single-time"]}
                        >
                            {timeSlot.label}
                        </div>
                    );
                })}
            </div>
            <div className={styles.days}>
                {Object.keys(allSchedulesByDay).map((day, dayIndex) => (
                    <div key={day}>
                        <div className={styles.weekdays}>
                            {moment().day(dayIndex).format("ddd MMM D, YYYY")}
                        </div>
                        <div className={styles["single-day"]}>
                            {timeSlots.map((timeSlot, index) => (
                                <div
                                    key={`${index + 1}`}
                                    className={styles["schedule-wrap"]}
                                >
                                    {sortSchedules(allSchedulesByDay[day])
                                        .filter(
                                            (s) =>
                                                moment(s.start).hour() === index
                                        )
                                        .map((s) => (
                                            <div
                                                key={s.id}
                                                style={{ height: "100%" }}
                                            >
                                                <SingleSchedule schedule={s} />
                                            </div>
                                        ))}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
