import * as React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Input } from "@jhool-io/fe-components";
import useToast from "../../../../hooks/useToast";
import usePractice from "../../../../hooks/usePractice";
import { getNoteListNavigateUrl } from "../../helpers/notes.helpers";
import { IFlagNote } from "../../types/notes.types";
import { useFlagNote } from "../../hooks/notes.mutations";

interface FlagNoteProps {
    // Function to call when form submit button is clicked
    onFormSubmit(): void;
    /** Id of single note */
    noteId?: string;
    /** Ids of selected notes */
    selectedIds?: string[];
}

// For form validation purposes
const schema = yup.object({
    flag_for_audit_notes: yup.string().required("Reason is required"),
});

export default function FlagNoteForm({
    onFormSubmit,
    noteId,
    selectedIds,
}: FlagNoteProps) {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IFlagNote>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    const params = useParams();
    const clientId = params.clientId as string;

    const { practice } = usePractice();

    const navigate = useNavigate();
    const location = useLocation();

    const flagNote = useFlagNote();

    const { toast } = useToast();

    // Query client
    const queryClient = useQueryClient();

    const isSingleNoteView =
        location.pathname.includes("/notes") && clientId && noteId;

    const noteListNavigateUrl = getNoteListNavigateUrl(practice?.roles || []);

    const onSubmit = (data: IFlagNote) => {
        const payload = {
            ...data,
            note_ids_to_flag_for_audit:
                selectedIds && selectedIds.length > 0
                    ? selectedIds
                    : [noteId as string],
            flag_for_audit_status: true,
        };

        flagNote.mutate(payload, {
            onSuccess: async (res) => {
                await queryClient.invalidateQueries({
                    queryKey: ["notes"],
                });

                await queryClient.invalidateQueries({
                    queryKey: ["supervisee"],
                });
                if (isSingleNoteView) {
                    await queryClient.invalidateQueries({
                        queryKey: [clientId, "session-note", noteId],
                    });
                }

                toast({
                    mode: "success",
                    message: res.message || "Note flagged successfully",
                });
                // Redirect back to notes list if current view is note content
                if (isSingleNoteView) {
                    navigate(noteListNavigateUrl);
                }

                onFormSubmit();
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not flag note at this time",
                });
            },
        });
    };

    return (
        <form id="flag-note" onSubmit={handleSubmit(onSubmit)}>
            <div className="fg">
                <Input
                    {...register("flag_for_audit_notes")}
                    label="Reason for flagging"
                    placeholder="Reason for flagging"
                    hasError={!!errors.flag_for_audit_notes}
                    errorText={errors.flag_for_audit_notes?.message}
                />
            </div>
        </form>
    );
}
