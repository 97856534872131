export enum TaskStatus {
    NOT_STARTED = "not_started",
    COMPLETED = "completed",
    IN_PROGRESS = "in_progress",
}

export enum TaskCategory {
    WRITE_SESSION_NOTE = "write_session_note",
    REVIEW_SUPERVISEE_NOTE = "review_supervisee_note",
    MAKE_REQUESTED_CHANGE = "make_requested_change",
    REVIEW_SUPERVISEE_ADDENDUM = "review_supervisee_addendum",
    USER_CREATED = "user_created_todo",
    WRITE_FOLLOW_UP_SAFETY_NOTE = "write_follow_up_safety_plan_note",
}

export enum TaskListSortAttribute {
    DUE_DATE = "due_date",
    CLIENT_NAME = "client_name",
    CREATED_BY = "created_by",
    ASSIGNED_TO = "assigned_to",
    TASK_SUBJECT = "task_subject",
}

export interface TaskMetadata {
    session_id?: string;
    note_id?: string;
    client_id?: string;
    session_history_id: string;
    provider_id?: string;
    supervisor_id?: string;
}

export interface ITask {
    todo_id: string;
    title: string;
    description?: string;
    description_preview?: string;
    status: TaskStatus;
    category: TaskCategory;
    is_system_created: boolean;
    due_date: string;
    creator: {
        user_id: string;
        first_name: string;
        last_name: string;
    };
    client?: {
        client_id: string;
        first_name: string;
        last_name: string;
    };
    assignee?: {
        user_id: string;
        first_name: string;
        last_name: string;
    };
    metadata?: TaskMetadata;
    created_at?: string;
}

export interface IUpdateTask {
    todo_id: string;
    status?: TaskStatus;
    title?: string;
    description?: string;
    due_date?: string;
    assignee_user_id?: string;
}

export interface IUpdateTaskPayload {
    todos_to_update: IUpdateTask[];
}

export interface INewTask {
    title: string;
    description?: string;
    due_date: Date;
    client_id?: string;
    assignee_user_id?: string;
}

export interface ITaskListFilters {
    page?: number;
    limit?: number;
    client_id?: string;
    status?: string[];
    assignee_user_id?: string;
    creator_user_id?: string;
    category?: string[];
    todo_id?: string;
    from_date?: Date;
    to_date?: Date;
    sort_attribute?: TaskListSortAttribute;
    sort_order?: string;
    search_string?: string;
}
