/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { format } from "date-fns";
import {
    DatePicker,
    Pagination,
    Popover,
    PopoverContent,
    PopoverTrigger,
    SearchInput,
    Table,
} from "@jhool-io/fe-components";
import { formatDate } from "../../../../utils/helpers";
import { useFetchInvoiceNotes } from "../../../../hooks/queries/billing";
import Skeleton from "../../../Skeleton/Skeleton";
import ListState from "../../../ListState/ListState";
import { IInvoiceNotesResponse } from "../../../../utils/types/billing";
import { ViewEditorText } from "../../../TextEditor/ViewEditorText/ViewEditorText";
import { momentNotTz } from "../../../../utils/moment";
import { useDebounce } from "../../../../hooks/helpers";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";

interface InvoiceNotesProps {
    invoiceId: string;
}

function InvoiceNotesList({ invoiceId }: InvoiceNotesProps) {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { clientId, noteId } = useParams();

    const [limit, setLimit] = React.useState(20);
    const params = new URLSearchParams(window.location.search);

    // Get filters from params
    const authorFilter = params.get("author") || "";
    const pageFilter = Number(params.get("page")) || 1;
    const fromDateFilter = params.get("from_date")
        ? momentNotTz(params.get("from_date")).toDate()
        : null;
    const toDateFilter = params.get("to_date")
        ? momentNotTz(params.get("to_date")).toDate()
        : null;

    // use debounce hook for author's name filter
    const authorName = useDebounce(authorFilter || "", 500);

    // filter author onchange fn
    const handleAuthorSearchChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (e.target.value === "") searchParams.delete("author");
        else searchParams.set("author", e.target.value);

        setSearchParams(searchParams);
    };

    // onChange handler for page
    const handlePageChange = (page: number) => {
        searchParams.set("page", String(page));
        setSearchParams(params);
        navigate(
            `/billing-and-claims/${clientId}/${noteId}?${params.toString()}`
        );
    };

    // onChange handler for from date
    const handleFromDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("from_date");
        else searchParams.set("from_date", format(date, "yyyy-MM-dd"));

        setSearchParams(searchParams);
    };

    // onCHange handler for to date
    const handleToDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("to_date");
        else searchParams.set("to_date", format(date, "yyyy-MM-dd"));

        setSearchParams(searchParams);
    };

    // fetch invoice notes
    const { data, isLoading, error, isSuccess } = useFetchInvoiceNotes(
        invoiceId,
        {
            author_name: authorName,
            invoice_id: invoiceId,
            page: pageFilter,
            from_date: fromDateFilter
                ? format(fromDateFilter, "yyyy-MM-dd")
                : null,
            to_date: toDateFilter ? format(toDateFilter, "yyyy-MM-dd") : null,
            limit,
        },
        Boolean(invoiceId)
    );

    const columns: ColumnDef<IInvoiceNotesResponse>[] = [
        {
            accessorKey: "date_created",
            header: "DATE CREATED",
            cell: ({ row }) => `${formatDate(row.original.date_created)}`,
        },
        {
            accessorKey: "author",
            header: "AUTHOR",
            cell: ({ row }) =>
                `${row.original.author.first_name} ${row.original.author.last_name}`,
        },
        {
            accessorKey: "description",
            header: "NOTE CONTENT",
            cell: ({ row }) => (
                <ViewEditorText text={row.original.description} />
            ),
        },
    ];

    return (
        <>
            <div className="rounded-r8 bg-white p-16 mb-32">
                <div className="flex justify-between gap-24 flex-wrap">
                    <SearchInput
                        onChange={handleAuthorSearchChange}
                        value={authorFilter}
                        containerClass="w-[300px] max-w-full"
                        placeholder="Search by Author"
                    />
                    <Popover>
                        <PopoverTrigger asChild>
                            <FilterButton text="Date" />
                        </PopoverTrigger>
                        <PopoverContent
                            align="end"
                            sideOffset={20}
                            className="h-auto gap-y-12"
                        >
                            <DatePicker
                                className="hover:border-primary"
                                label="From date:mm/dd/yy"
                                onChange={handleFromDateFilterChange}
                                selected={fromDateFilter}
                                disabled={isLoading}
                                isClearable
                            />

                            <DatePicker
                                className="hover:border-primary"
                                label="To date:mm/dd/yy"
                                onChange={handleToDateFilterChange}
                                selected={toDateFilter}
                                disabled={isLoading}
                                isClearable
                            />
                        </PopoverContent>
                    </Popover>
                </div>
            </div>

            <div>
                {isLoading ? (
                    <Skeleton
                        type="table"
                        containerTestId="internallist-loader"
                        count={6}
                    />
                ) : null}

                {error && error?.response?.status !== 404 && (
                    <ListState
                        isError
                        stateHelperText="Try reloading this page to solve this issue"
                        errorMsg={
                            error?.response?.data.message ||
                            `Cannot display internal notes at this time. Please try again later`
                        }
                    />
                )}

                {data && isSuccess && data.data.length === 0 && (
                    <ListState
                        stateHelperText="Internal notes will appear here once they are added"
                        isError={false}
                        emptyMessage="No internal note added yet"
                    />
                )}

                {isSuccess && data && data.data.length > 0 ? (
                    <div>
                        <div>
                            <Table
                                columns={columns}
                                data={data.data}
                                hasPagination={data.total_count > 20}
                                pagination={
                                    <Pagination
                                        totalCount={data.total_count}
                                        totalPages={data.total_pages}
                                        currentPage={pageFilter}
                                        onPageChange={handlePageChange}
                                        onLimitChange={(l) => setLimit(l)}
                                        limit={limit}
                                    />
                                }
                            />
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    );
}

export default InvoiceNotesList;
