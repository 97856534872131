export enum ProviderStatus {
    ACTIVE = "active",
    INACTIVE = "inactive",
}

export enum SessionRecurrenceRule {
    WEEKLY = "Weekly",
    BI_WEEKLY = "Bi-Weekly",
    TRI_WEEKKLY = "Tri-Weekly",
    MONTHLY = "Monthly",
    ONE_OFF = "One-Off",
}

export enum SessionDeliveryOption {
    IN_PERSON = "in_person",
    REMOTE = "remote",
}

export enum SessionPlaceOfServiceCode {
    PHARMACY = "01 - Pharmacy",
    TELEHEALTH_PROVIDED_OTHER_THAN_IN_PATIENTS_HOME = "02 - Telehealth Provided Other than in Patient's Home",
    SCHOOL = "03 - School",
    HOMELESS_SHELTER = "04 - Homeless Shelter",
    INDIAN_HEALTH_SERVICE_FREE_STANDING_FACILITY = "05 - Indian Health Service Free-standing Facility",
    INDIAN_HEALTH_SERVICE_PROVIDER_BASED_FACILITY = "06 - Indian Health Service Provider-based Facility",
    TRIBAL_638_FREE_STANDING_FACILITY = "07 - Tribal 638 Free-standing Facility",
    TRIBAL_638_PROVIDER_BASED_FACILITY = "08 - Tribal 638 Provider-based Facility",
    PRISON_CORRECTIONAL_FACILITY = "09 - Prison/Correctional Facility",
    TELEHEALTH_PROVIDED_IN_PATIENTS_HOME = "10 - Telehealth Provided in Patient's Home",
    OFFICE = "11 - Office",
    HOME = "12 - Home",
    ASSISTED_LIVING_FACILITY = "13 - Assisted Living Facility",
    GROUP_HOME = "14 - Group Home",
    MOBILE_UNIT = "15 - Mobile Unit",
    TEMPORARY_LODGING = "16 - Temporary Lodging",
    WALK_IN_RETAIL_HEALTH_CLINIC = "17 - Walk-in Retail Health Clinic",
    PLACE_OF_EMPLOYMENT_WORKSITE = "18 - Place of Employment-Worksite",
    OFF_CAMPUS_OUTPATIENT_HOSPITAL = "19 - Off Campus-Outpatient Hospital",
    URGENT_CARE_FACILITY = "20 - Urgent Care Facility",
    INPATIENT_HOSPITAL = "21 - Inpatient Hospital",
    ON_CAMPUS_OUTPATIENT_HOSPITAL = "22 - On Campus-Outpatient Hospital",
    EMERGENCY_ROOM_HOSPITAL = "23 - Emergency Room - Hospital",
    AMBULATORY_SURGICAL_CENTER = "24 - Ambulatory Surgical Center",
    BIRTHING_CENTER = "25 - Birthing Center",
    MILITARY_TREATMENT_FACILITY = "26 - Military Treatment Facility",
    SKILLED_NURSING_FACILITY = "31 - Skilled Nursing Facility",
    NURSING_FACILITY = "32 - Nursing Facility",
    CUSTODIAL_CARE_FACILITY = "33 - Custodial Care Facility",
    HOSPICE = "34 - Hospice",
    AMBULANCE_LAND = "41 - Ambulance - Land",
    AMBULANCE_AIR_OR_WATER = "42 - Ambulance - Air or Water",
    INDEPENDENT_CLINIC = "49 - Independent Clinic",
    FEDERALLY_QUALIFIED_HEALTH_CENTER = "50 - Federally Qualified Health Center",
    INPATIENT_PSCHIATRIC_FACILITY = "51 - Inpatient Psychiatric Facility",
    PSCHIATRIC_FACILITY_PARTIAL_HOSPITALIZATION = "52 - Psychiatric Facility - Partial Hospitalization",
    COMMUNITY_MENTAL_HEALTH_CENTER = "53 - Community Mental Health Center",
    INTERMEDIATE_CARE_FACILITY_INDIVIDUALS_WITH_INTELLECTUAL_DISABILITIES = "54 - Intermediate Care Facility/Individuals with Intellectual Disabilities",
    RESIDENTIAL_SUBSTANCE_ABUSE_TREATMENT_FACILITY = "55 - Residential Substance Abuse Treatment Facility",
    PSYCHATRIC_RESIDENTIAL_TREATMENT_CENTER = "56 - Psychiatric Residential Treatment Center",
    NON_RESIDENTIAL_SUBSTANCE_ABUSE_TREATMENT_FACILITY = "57 - Non-residential Substance Abuse Treatment Facility",
    NON_RESIDENTIAL_OPIOID_TREATMENT_FACILITY = "58 - Non-residential Opioid Treatment Facility",
    MASS_IMMUNIZATION_CENTER = "60 - Mass Immunization Center",
    COMPREHENSIVE_INPATIENT_REHABILITATION_FACILITY = "61 - Comprehensive Inpatient Rehabilitation Facility",
    COMPREHENSIVE_OUTPATIENT_REHABILITATION_FACILITY = "62 - Comprehensive Outpatient Rehabilitation Facility",
    END_STAGE_RENAL_DISEASE_TREATMENT_FACILITY = "65 - End-Stage Renal Disease Treatment Facility",
    PUBLIC_HEALTH_CLINIC = "71 - Public Health Clinic",
    RURAL_HEALTH_CLINIC = "72 - Rural Health Clinic",
    INDEPENDENT_LABORATORY = "81 - Independent Laboratory",
    OTHER_PLACE_OF_SERVICE = "99 - Other Place of Service",
}

export enum AppointmentLocation {
    IN_PERSON = "in_person",
    TELETHERAPY = "teletherapy",
}

export enum AppointmentLocationTeletherapy {
    OFFICE = "Office",
    HOME = "Home",
    OTHER = "Other",
}

export enum SessionServiceType {
    CHILD_THERAPY = "child_therapy",
    FAMILY_THERAPY = "family_therapy",
    INDIVIDUAL_THERAPY = "individual_therapy",
    INTUITIVE_EATING = "intuitive_eating",
    MEDICATION_MANAGEMENT = "medication_management",
    PSYCHIATRIC_COUNSELING = "psychiatric_counseling",
    MENTAL_HEALTH_ASSESSMENT = "mental_health_assessment",
    TELETHERAPY = "teletherapy",
}

export const SessionLabels: { [key: string]: string } = {
    [SessionServiceType.CHILD_THERAPY]: "Child Therapy",
    [SessionServiceType.FAMILY_THERAPY]: "Family Therapy",
    [SessionServiceType.INDIVIDUAL_THERAPY]: "Individual Therapy",
    [SessionServiceType.INTUITIVE_EATING]: "Intuitive Eating",
    [SessionServiceType.MEDICATION_MANAGEMENT]: "Medication Management",
    [SessionServiceType.PSYCHIATRIC_COUNSELING]: "Psychiatric Counseling",
    [SessionServiceType.MENTAL_HEALTH_ASSESSMENT]: "Mental Health Assessment",
    [SessionServiceType.TELETHERAPY]: "Teletherapy",
};

// Provider's session
export interface IProviderSession {
    session_id: string;
    session_history_id: string;
    status: ProviderStatus;
    service: SessionServiceType;
    start_date_time: string;
    end_date_time: string;
    recurrence_rule: SessionRecurrenceRule;
    delivery_option: SessionDeliveryOption;
    is_cancelled: boolean;
    no_of_sessions: number;
    first_session_date: string | null;
    session_day: string;
    client: {
        client_id: string;
        first_name: string;
        last_name: string;
    };
    provider: ISessionProvider;
}

export interface ISessionProvider {
    provider_id: string;
    first_name: string;
    last_name: string;
}
